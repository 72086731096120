import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Card, Dropdown, PageTitle, Spacer, Typography } from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import Loader from '../../../components/Loader'
import { Batch, Pagination, ProductBatchs, SendMail, Stop, Tour } from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../services/carrier/tour'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierTourSendMail.styles'
import {
  MAIL_OPTIONS,
  MAIL_TYPES,
  MailEnums,
  MailOptionEnums,
  PROPOSITION_CLIENTS_MAIL_TEMPLATE_OPTIONS,
  PropositionClientsMailTemplateEnums,
} from '../../../../enums/mail'
import TourMailHistory from '../../../modules/Tour/TourMailHistory'
import GeneralUtils from '../../../utilities/utils/general'
import BatchMailCard from './components/BatchMailCard'
import PaginationBar from '../../../components/PaginationBar'
import BatchCarrierService from '../../../services/carrier/batch'
import StorageUtil from '../../../utilities/storage/storage'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import { StyledButtonText } from '../CarrierAddUser/CarrierAddUser.styles'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { RightKeyEnum } from '../../../../enums'
import { View } from 'react-native'

const CarrierTourSendMailScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourSendMail'>>()
  const [pageSizeBatchs, setPageSizeBatchs] = useState(10)
  const [pageNumberBatchs, setPageNumberBatchs] = useState(1)
  const { id, type } = route?.params ?? { id: '', type: null }
  const [isLoading, setIsLoading] = useState(false)
  const [selectedBatchs, setSelectedBatchs] = useState<string[]>()
  const [selectedAll, setSelectedAll] = useState<boolean>(false)
  const [displayMailList, setDisplayMailList] = useState<boolean>(false)

  const [selectedMailOption, setSelectedMailOption] = useState<any | undefined>({
    label: 'Choisir une option',
    value: undefined,
  })
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [selectMail, setSelectedMail] = useState<string>('')
  const [selectedClientsMails, setSelectedClientsMails] = useState<string[] | undefined>(undefined)
  const [isCustomMail, setIsCustomMail] = useState(false)
  const [customMail, setCustomMail] = useState<SendMail>({
    mailContent: undefined,
    mailSubject: undefined,
  })
  const [selectedMailTemplate, setSelectedMailTemplate] = useState<any | undefined>(undefined)

  const { carrierHasRight } = useAuthenticationContext()

  const theme = useTheme()

  const {
    data: tour,
    refetch: refetchOldTour,
    isInitialLoading: tourLoading,
  } = TourCarrierService.getOne.query(id)

  const {
    data: productsBatchs,
    refetch: refetchProductBatchs,
    isRefetching: productBatchsRefetching,
    isLoading: productBatchsLoading,
  } = useQuery<{ data: ProductBatchs[]; pagination: Pagination }, Error>(
    ['sf_product_batchs', id],
    () =>
      BatchCarrierService.getAllBatchsTourComputed(
        id,
        undefined,
        undefined,
        undefined,
        [],
        pageSizeBatchs,
        pageNumberBatchs,
        'PRODUCT_TYPE',
      ),
    {
      keepPreviousData: true,
    },
  )

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in customMail)) return null

    const customMailTemp = Object.assign({}, customMail)

    customMailTemp[param as keyof typeof customMail] = value
    setCustomMail(customMailTemp)
  }

  const storeSelectedBatchs = async (selectedBatchs: string[]) => {
    await StorageUtil.setItem('selectedBatchs', selectedBatchs)
  }

  const getSelectedBatchs = async (): Promise<string[]> => {
    const selectedBatchs = await StorageUtil.getItem('selectedBatchs')
    return selectedBatchs && JSON.parse(selectedBatchs)
  }

  const emptySelectedBatchs = async () => {
    await StorageUtil.setItem('selectedBatchs', [])
  }

  const productBatchsIds = productsBatchs?.data?.map((pbs) => pbs.batchs).flatMap((b) => b![0].id)

  useEffect(() => {
    emptySelectedBatchs()
    const initializeSelectedBatchs = async () => {
      const selectedBatchsStorage = await getSelectedBatchs()
      const batchsToStore = productBatchsIds?.filter((b) => !selectedBatchsStorage?.includes(b))

      if (selectedBatchsStorage?.length > 0) {
        setSelectedBatchs(selectedBatchsStorage)
      } else {
        setSelectedBatchs([])
        await storeSelectedBatchs([])
      }
      batchsToStore && batchsToStore?.length > 0 ? setSelectedAll(false) : setSelectedAll(true)
    }
    initializeSelectedBatchs()
    // setSelectedMailOption(MAIL_OPTIONS.find((m) => m.value === MailOptionEnums.ALL_CLIENTS))
  }, [])

  const onChangePage = async (pageNumber: number) => {
    await setPageNumberBatchs(pageNumber)
    refetchProductBatchs()
  }

  const onChangeMailOption = (value: any) => {
    setSelectedMailOption(value)
    setSelectedMail('')
    setSelectedClientsMails(undefined)
  }

  const onChangeMailTemplate = (selectedOption: any) => {
    if (
      selectedOption.value === PropositionClientsMailTemplateEnums.PROPOSITION_CLIENTS_V2 ||
      selectedOption.value === PropositionClientsMailTemplateEnums.PROPOSITION_CLIENTS_V3
    ) {
      setIsCustomMail(true)
    }
    setSelectedMailTemplate(selectedOption)
  }

  const onChangeSelectedClientsMails = (values: any) => {
    if (values && values.length > 0) {
      const clientMails: string[] = []
      values.map((item: any) => {
        if (item.value) {
          clientMails.push(item?.value)
        }
      })
      setSelectedClientsMails(clientMails)
    } else {
      setSelectedClientsMails([])
    }
  }

  const onToggleSelectAllBatchs = async () => {
    const selectedBatchsStorage = await getSelectedBatchs()
    const batchsToStore = productBatchsIds?.filter((b) => !selectedBatchsStorage?.includes(b))
    if (batchsToStore && batchsToStore.length > 0) {
      const newSelectedBatchs = [...selectedBatchsStorage, ...batchsToStore]
      await storeSelectedBatchs(newSelectedBatchs)
      setSelectedBatchs(newSelectedBatchs)
      setSelectedAll(true)
    } else {
      const newSelectedBatchs = selectedBatchsStorage?.filter(
        (b: string) => !productBatchsIds?.includes(b),
      )
      await storeSelectedBatchs(newSelectedBatchs)
      setSelectedBatchs(newSelectedBatchs)
      setSelectedAll(false)
    }
  }

  const onToggleSelectBatch = async (batch: Batch) => {
    if (selectedBatchs?.includes(batch.id)) {
      const newSelectedBatchs = selectedBatchs?.filter((b) => b !== batch.id)
      setSelectedBatchs(newSelectedBatchs)
      await storeSelectedBatchs(newSelectedBatchs)

      setSelectedAll(false)
    } else {
      const newSelectedBatchs = selectedBatchs ? [...selectedBatchs, batch.id] : [batch.id]
      setSelectedBatchs(newSelectedBatchs)
      await storeSelectedBatchs(newSelectedBatchs)

      const currentlySelectedBatchs = newSelectedBatchs.filter((b) => productBatchsIds?.includes(b))
      if (currentlySelectedBatchs.length === productBatchsIds?.length) {
        setSelectedAll(true)
      }
    }
  }

  if (tourLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour || !type) return null
  const producersTour = tour.producerTour || []

  const validateStopEmails = (
    tourStops: Stop[] | undefined,
  ): { errorMessage: string; invalidEmails: string[] } => {
    let errorMessage = ''
    const invalidEmails: string[] = []
    tourStops &&
      tourStops.forEach((tourStop) => {
        if (tourStop.client.mail && !GeneralUtils.isEmail(tourStop.client.mail)) {
          invalidEmails.push(tourStop.client.mail)
        }
      })
    if (invalidEmails.length) {
      errorMessage = `Le format des emails suivants sont invalides : ${invalidEmails.join(', ')}`
    }
    return { errorMessage, invalidEmails }
  }

  const onSendMail = async () => {
    setErrorMessage('')
    setIsLoading(true)

    let mails: string[] | undefined = []

    if (isCustomMail && (!customMail.mailSubject || !customMail.mailContent)) {
      setErrorMessage(`Veuillez compléter l'objet et le contenu du mail`)
      setIsLoading(false)
      return
    }

    if (selectedMailOption?.value === MailOptionEnums.ALL_CLIENTS) {
      const emailValidation = validateStopEmails(tour.stops)

      if (emailValidation.errorMessage) {
        setErrorMessage(emailValidation.errorMessage)
        setIsLoading(false)
        return
      }
      mails = undefined
    } else if (selectedMailOption?.value === MailOptionEnums.SELECTED_CLIENTS) {
      if (!selectedClientsMails || selectedClientsMails.length < 1) {
        setErrorMessage(`Vous devez sélectionner au moins un email`)
        setIsLoading(false)
        return
      }

      if (selectedClientsMails.find((mail) => !GeneralUtils.isEmail(mail))) {
        setErrorMessage(`Un des emails selectionnés n'est pas valide`)
        setIsLoading(false)
        return
      }

      mails = selectedClientsMails
    } else if (selectedMailOption?.value === MailOptionEnums.CUSTOM_MAIL) {
      if (!selectMail || !GeneralUtils.isEmail(selectMail)) {
        setErrorMessage(`Le format de l'email saisi est invalide`)
        setIsLoading(false)
        return
      }

      mails = [selectMail]
    } else {
      setErrorMessage(`Veuillez sélectionner un mode d'envoie`)
      setIsLoading(false)
      return
    }

    const dto = {
      mailType: type,
      mails: mails,
      batchsIds: selectedBatchs,
      mailContent: isCustomMail ? GeneralUtils.textToHtml(customMail.mailContent) : undefined,
      mailSubject: isCustomMail ? customMail.mailSubject : undefined,
      mailTemplate: selectedMailTemplate ? selectedMailTemplate.value : undefined,
    }

    const sendedMailTour = await TourCarrierService.sendMail(tour.id, dto)
    if (sendedMailTour && sendedMailTour.id) {
      TourCarrierService.getOne.invalidate(id)
      setSelectedBatchs([])
      await storeSelectedBatchs([])
      setSelectedAll(false)
      navigation.navigate('CarrierTour', { id: tour.id })
    } else {
      setErrorMessage(`Un problème est survenu lors de l'envoie du mail pour cette tournée`)
    }
    setIsLoading(false)
  }

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  const mailSend = (mailType: MailEnums): boolean => {
    let isSend = false
    const history = tour.mailsStatus?.history
    if (history && history.length) {
      const foundHistory = history.find((h) => h.type === mailType)
      isSend = foundHistory !== null
    }

    return isSend
  }

  const hasSuperAdminAccesRight = carrierHasRight(RightKeyEnum.C_SUPER_ADMIN)

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title={`Mail pour la tournée #${getTourIdFormatted()}`} />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Typography.Body bold>Envoyer à : </Typography.Body>
          <Spacer size={0.6} axis={Spacer.AxisEnum.VERTICAL} />
          <Dropdown
            label={'Destinaires'}
            onChange={onChangeMailOption}
            multiple={false}
            defaultValue={selectedMailOption?.value}
            optionsDefault={
              !MAIL_OPTIONS
                ? []
                : MAIL_OPTIONS.map((option) => ({
                    ...option,
                  }))
            }
            zIndex={1000}
            zIndexInverse={4000}
            itemKey="MailOption"
          />

          {selectedMailOption?.value !== undefined && (
            <>
              <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
              {selectedMailOption?.value === MailOptionEnums.ALL_CLIENTS && (
                <>
                  <Typography.Body bold>
                    Ce mail sera envoyé à {tour.stops ? tour.stops.length : 0} destinataires :{' '}
                  </Typography.Body>
                  <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                  {displayMailList && (
                    <Typography.Body>
                      {selectMail ? selectMail : tour.stops?.map((s) => `${s.client.mail}, `)}
                    </Typography.Body>
                  )}

                  <StyledButtonText>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <Button.Text
                      label={displayMailList ? 'Cacher la liste' : `Afficher la liste`}
                      onPress={() => {
                        setDisplayMailList(!displayMailList)
                      }}
                    />
                  </StyledButtonText>
                </>
              )}

              {selectedMailOption?.value === MailOptionEnums.SELECTED_CLIENTS && (
                <>
                  <Typography.Body bold>Sélectionnez des clients dans la liste : </Typography.Body>
                  <Spacer size={0.6} axis={Spacer.AxisEnum.VERTICAL} />
                  <Dropdown
                    label={'Clients'}
                    onChange={onChangeSelectedClientsMails}
                    multiple={true}
                    defaultValue={selectedClientsMails}
                    optionsDefault={
                      !tour.stops
                        ? []
                        : tour.stops?.map((stop) => ({
                            value: stop.client.mail,
                            label: `${stop.client.label} (${stop.client.mail})`,
                          }))
                    }
                    zIndex={1000}
                    zIndexInverse={4000}
                    itemKey="Client"
                    isSearchable
                  />
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <Typography.Body bold>
                    Ce mail sera envoyé à {selectedClientsMails ? selectedClientsMails.length : 0}{' '}
                    destinataires:{' '}
                  </Typography.Body>
                  <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                  {displayMailList && (
                    <Typography.Body>
                      {(selectedClientsMails || []).map((mail) => `${mail}, `)}
                    </Typography.Body>
                  )}

                  <StyledButtonText>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <Button.Text
                      label={displayMailList ? 'Cacher la liste' : `Afficher la liste`}
                      onPress={() => {
                        setDisplayMailList(!displayMailList)
                      }}
                    />
                  </StyledButtonText>
                </>
              )}

              {selectedMailOption?.value === MailOptionEnums.CUSTOM_MAIL && (
                <TextInput
                  value={selectMail}
                  label="Email"
                  field="text"
                  onChangeText={(text) => setSelectedMail(text)}
                  autoFocus
                />
              )}

              {(selectedMailOption?.value === MailOptionEnums.CUSTOM_MAIL && selectMail) ||
              selectedMailOption?.value === MailOptionEnums.ALL_CLIENTS ||
              (selectedClientsMails && selectedClientsMails.length > 0) ? (
                <>
                  <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
                  <Typography.Body bold>
                    Sélectionnez les produits que vous souhaitez afficher dans le mail :
                  </Typography.Body>
                  <Typography.BodySmall colorName="text-dark-3">
                    Vous pouvez sélectionner jusqu'à 10 lots à afficher dans votre email.
                  </Typography.BodySmall>

                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  {productBatchsLoading ? (
                    <Loader isLoading />
                  ) : (
                    productsBatchs?.data?.map((productBatch) => {
                      return (
                        <>
                          <BatchMailCard
                            productBatchs={productBatch}
                            selectedBatchs={selectedBatchs}
                            onToggleSelectBatch={onToggleSelectBatch}
                            key={productBatch?.product?.id}
                          />
                          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                        </>
                      )
                    })
                  )}

                  <PaginationBar
                    data={productsBatchs?.data?.map((pbs) => pbs.batchs).flat()}
                    pagination={productBatchsRefetching ? undefined : productsBatchs?.pagination}
                    onChangePage={onChangePage}
                  />
                </>
              ) : undefined}

              <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
              {hasSuperAdminAccesRight && selectedBatchs && selectedBatchs.length > 0 && (
                <View style={{ zIndex: 100 }}>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                  <Dropdown
                    label={"Template d'email"}
                    onChange={onChangeMailTemplate}
                    multiple={false}
                    defaultValue={selectedMailTemplate?.value}
                    optionsDefault={
                      !PROPOSITION_CLIENTS_MAIL_TEMPLATE_OPTIONS
                        ? []
                        : PROPOSITION_CLIENTS_MAIL_TEMPLATE_OPTIONS.map((option) => ({
                            ...option,
                          }))
                    }
                    zIndex={1000}
                    zIndexInverse={4000}
                    itemKey="MailOption"
                  />
                </View>
              )}

              {((hasSuperAdminAccesRight && selectedMailTemplate?.value) ||
                !hasSuperAdminAccesRight) &&
              selectedBatchs &&
              selectedBatchs.length > 0 ? (
                <>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                  {isCustomMail ? (
                    <>
                      <Typography.Body bold>
                        Vous pouvez personnaliser l'objet et le contenu du mail :
                      </Typography.Body>
                      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                      <StyledButtonText>
                        <Button.Text
                          label={'Annuler la personnalisation'}
                          colorName="color-grey"
                          onPress={() => {
                            setIsCustomMail(false)
                          }}
                        />
                      </StyledButtonText>
                    </>
                  ) : (
                    <>
                      <Typography.Body bold>Vous souhaitez personnaliser l'email ?</Typography.Body>
                      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                      <StyledButtonText>
                        <Button.Text
                          label={`Personnaliser le contenu du mail de notification client`}
                          onPress={() => {
                            setIsCustomMail(true)
                          }}
                        />
                      </StyledButtonText>
                    </>
                  )}
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

                  {isCustomMail && (
                    <>
                      <TextInput
                        value={customMail.mailSubject}
                        label="Objet du mail"
                        field="text"
                        onChangeText={(text) => setValue(text, 'mailSubject')}
                      />
                      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                      <TextInput
                        value={customMail.mailContent}
                        label="Contenu du mail"
                        field="text"
                        multiline
                        onChangeText={(text) => setValue(text, 'mailContent')}
                      />
                      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
                    </>
                  )}

                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />

                  <StyledButtonWrapper>
                    {errorMessage ? (
                      <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                    ) : null}
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                    {mailSend(type) && (
                      <>
                        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                        <Typography.Body colorName="color-danger">
                          Ce mail à déjà été envoyé une fois
                        </Typography.Body>
                        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                      </>
                    )}
                    {selectedBatchs && selectedBatchs.length > 10 && (
                      <>
                        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                        <Typography.Body colorName="color-danger">
                          Vous ne pouvez pas sélectionner plus de 10 lots
                        </Typography.Body>
                        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                      </>
                    )}
                    {(!selectedBatchs || selectedBatchs.length < 1) && (
                      <>
                        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                        <Typography.Body colorName="color-danger">
                          Veuillez sélectionner au moins un lot
                        </Typography.Body>
                        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                      </>
                    )}
                    <Button
                      label={mailSend(type) ? 'Renvoyer e-mail(s)' : 'Envoyer e-mail(s)'}
                      onPress={() => onSendMail()}
                      loading={isLoading}
                      danger={mailSend(type)}
                      hasDoubleValidation
                      confirmationLabel="Confirmer l'envoie"
                      disabled={
                        !selectedBatchs || selectedBatchs.length < 1 || selectedBatchs.length > 10
                      }
                    />
                  </StyledButtonWrapper>
                  <Spacer size={5} axis={Spacer.AxisEnum.VERTICAL} />
                  <TourMailHistory tour={tour} />
                </>
              ) : undefined}
            </>
          )}

          <Spacer size={20} axis={Spacer.AxisEnum.VERTICAL} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourSendMailScreen
