import ApiService from '../../api.service'
import { Payment } from '../../../domain/Payment'

const update = async (paymentId: string, payment: any): Promise<Payment> => {
  const response = await ApiService.patch(`/payment/${paymentId}`, payment).catch((error) => {
    throw error
  })
  return response
}

const PaymentCarrierService = {
  update,
}

export default PaymentCarrierService
