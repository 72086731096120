import React from 'react'
import { useTheme } from 'styled-components/native'
import { CarrierTourClientsTopBoxProps } from './CarrierTourClientsTopBox.model'
import { CardParam } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { StyledTopBox } from './CarrierTourClientsTopBox.styles'

function CarrierTourClientsTopBox({ tour }: CarrierTourClientsTopBoxProps) {
  const theme = useTheme()

  return (
    <>
      <StyledTopBox>
        <CardParam label={'Colis'} value={tour.nbCrates || 'NA'} flex={7} isStart />
        <CardParam label={'Commandes'} value={tour.nbOrders || 'NA'} flex={7} />
        <CardParam
          label={'CA Total HT'}
          value={FormatUtils.formatPrice(tour.totalIncome) || 'NA'}
          flex={7}
          isEnd
        />
      </StyledTopBox>
    </>
  )
}

export default CarrierTourClientsTopBox
