import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
`

const StatsWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledDateSwitcherWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ArrowBoxWrapperLeft = styled.View`
  width: 60px;
  text-align: right;
`

const ArrowBoxWrapperRight = styled.View`
  width: 60px;
  align-items: left;
`

const MiddleBox = styled.View`
  width: 120px;
`

const SwitchModeButton = styled.View`
  justify-content: flex-end;
  align-items: flex-end;
`

export {
  StyledCardWrapper,
  StatsWrapper,
  StyledDateSwitcherWrapper,
  ArrowBoxWrapperRight,
  ArrowBoxWrapperLeft,
  MiddleBox,
  SwitchModeButton,
}
