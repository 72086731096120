import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const ToursContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: 0;
`

const StyledButtonWrapper = styled.View`
  justify-content: center;
`

const StyledBox = styled.View`
  flex-direction: row;
`

const StyledLeftBox = styled.View`
  flex: 6;
  margin-right: ${({ theme }) => theme.spacingUnit * 1}px;
`

const StyledRightBox = styled.View`
  flex: 6;
  margin-left: ${({ theme }) => theme.spacingUnit * 1}px;
`

const BackToListWrapper = styled.TouchableOpacity`
  width: 100%;
  flex-direction: row;
`

const StyledCardWrapper = styled.View`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContentWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const LeftCardContentWrapper = styled.View``

export {
  ToursContentWrapper,
  StyledButtonWrapper,
  StyledBox,
  StyledLeftBox,
  StyledRightBox,
  BackToListWrapper,
  StyledCardWrapper,
  StyledCardContentWrapper,
  LeftCardContentWrapper,
}
