import styled, { css } from 'styled-components/native'

const StyledImage = styled.ImageBackground<{ isActive?: boolean }>`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  width: 100%;
  height: 100%;
  padding-top: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 3}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 3}px;
  background-color: rgba(255, 255, 255, 1);
  min-width: 80px;
  border: 1px solid ${({ theme }) => theme.colors['color-primary']};
  align-items: center;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors['color-primary']};
    `}
`

const CategoryWrapper = styled.TouchableOpacity``

export { StyledImage, CategoryWrapper }
