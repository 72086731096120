import { useQuery } from '@tanstack/react-query'
import {
  Button,
  CardPageLink,
  PageTitle,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { Group, Producer } from '../../../domain'
import GroupProducerService from '../../../services/producer/group'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import Loader from '../../../components/Loader'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import CardList from '../../../components/CardList'
import { StyledButtonWrapper } from './ProducerGroups.styles'
import ProducerProducerService from '../../../services/producer/producer'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import { GroupEnums } from '../../../../enums/group'

const ProducerGroupsScreen = () => {
  const { getAccessInfos, hasCarrierRole } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const {
    data: groups,
    refetch: refetchGroups,
    isInitialLoading: groupsLoading,
  } = useQuery<Group[], Error>(
    ['p_groups'],
    () => GroupProducerService.getAllGroupsByProducerId(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: groupsMatching,
    refetch: refetchGroupsMatching,
    isInitialLoading: groupsMatchingLoading,
  } = useQuery<Group[], Error>(
    ['p_groups_matching'],
    () => GroupProducerService.getAllGroupsMatchingByProducerId(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: producerData,
    refetch: refetchProducer,
    isInitialLoading: producerLoading,
  } = useQuery<{ producer: Producer; stepsData: TutorialStepData[] }, Error>(
    ['p_producer', getAccessInfos().producerId],
    () => ProducerProducerService.getOne(getAccessInfos().producerId),
    {
      keepPreviousData: true,
      enabled: getAccessInfos().producerId !== undefined,
    },
  )

  if (groupsLoading) {
    return <Loader isLoading pageLoading />
  }

  const getNbClientsAndProducers = (group: any) => {
    const nbClients = group.clientGroups ? group.clientGroups.length : 0
    const nbProducers = group.producerGroups ? group.producerGroups.length : 0

    return `${nbClients} clients, ${nbProducers} producteurs`
  }

  const isSuperFarmerGroupType = groups?.find(
    (g) => g.type === GroupEnums.GroupTypeEnum.SUPER_PRODUCER,
  )

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Groupes" />
        <SectionTitle title="Mes groupes" />
        {groups?.length === 0 && (
          <>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall>
              Vous n'êtes membre d'aucun groupe pour le moment.
            </Typography.BodySmall>
          </>
        )}
        {groups &&
          groups.map((group) => (
            <CardPageLink
              key={group.id}
              label={group.label}
              icon="arrow-right"
              subtitle={getNbClientsAndProducers(group)}
              onClick={() => navigation.navigate('ProducerGroup', { groupId: group.id })}
            />
          ))}
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <SectionTitle title="Groupes disponibles à proximité" />
        {(groupsMatching && groupsMatching.length > 0) || producerData?.producer.address ? (
          <>
            <CardList
              data={groupsMatching}
              element={function (group: any) {
                return (
                  <CardPageLink
                    key={group.id}
                    label={group.label}
                    icon="arrow-right"
                    subtitle={getNbClientsAndProducers(group)}
                    onClick={() => navigation.navigate('ProducerGroup', { groupId: group.id })}
                  />
                )
              }}
              isLoading={groupsMatchingLoading}
              emptyMessage="Aucun groupe disponible actuellement"
            />
          </>
        ) : undefined}
        {!hasCarrierRole() && (
          <>
            {!producerData?.producer.address && (
              <>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body colorName="text-dark-3">
                  Afin de vous afficher les groupes disponibles à proximité, veuillez renseigner
                  l'adresse de votre exploitation.
                </Typography.Body>
                <Spacer size={1.5} axis={Spacer.AxisEnum.VERTICAL} />
                <StyledButtonWrapper>
                  <Button
                    label="Renseigner mon adresse"
                    onPress={() =>
                      navigation.navigate('ProfileProducerNav', {
                        screen: 'ProducerAddAddress',
                        params: {},
                      })
                    }
                  />
                </StyledButtonWrapper>
              </>
            )}
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            {!isSuperFarmerGroupType && producerData?.producer.address && (
              <>
                <SectionTitle title="Vous souhaitez créer votre groupe ?" />
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body colorName="text-dark-3">
                  Aucun groupe n'est disponible à proximité et vous effectuez déjà des livraisons
                  seul ou avec vos collègues producteurs ?
                </Typography.Body>
                <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body colorName="text-dark-3">
                  Vous pouvez créer votre propre groupe et organiser vos propres tournées
                  mutualisées.
                </Typography.Body>
                <Spacer size={1.5} axis={Spacer.AxisEnum.VERTICAL} />
                <StyledButtonWrapper>
                  <Button
                    label="Créer mon groupe"
                    onPress={() => navigation.navigate('ProducerAddGroupLanding', {})}
                  />
                </StyledButtonWrapper>
              </>
            )}
          </>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerGroupsScreen
