import { LinearGradient } from 'expo-linear-gradient'
import styled from 'styled-components/native'

const borderSize = 2
const AvatarGradient = styled(LinearGradient)<{ boxSize: number }>`
  flex-direction: row;
  height: ${({ boxSize }) => boxSize}px;
  width: ${({ boxSize }) => boxSize}px;
  border-radius: ${({ boxSize }) => boxSize / 2}px;
  justify-content: center;
  align-items: center;
`

const StyledAvatarBox = styled.View<{ boxSize: number }>`
  height: ${({ boxSize }) => boxSize - borderSize * 2}px;
  width: ${({ boxSize }) => boxSize - borderSize * 2}px;
  background-color: ${({ theme }) => theme.colors['color-secondary']};
  border-radius: ${({ boxSize }) => boxSize / 2}px;
  align-items: center;
  justify-content: center;
`

const StyledAvatarImage = styled.Image<{ boxSize: number }>`
  height: 100%;
  width: 100%;
  border-radius: ${({ boxSize }) => boxSize / 2}px;
`
export { AvatarGradient, StyledAvatarBox, StyledAvatarImage }
