import { Alert, TouchableOpacity } from 'react-native'
import { Divider, Typography, Card, Icon, Icons, Row, Col, Spacer } from '../../../../../components'
import DashboardStat from '../DashboardStat'
import { CarrierBillingDeliveryNotesMetricsProps } from './CarrierBillingDeliveryNotesMetrics.model'
import {
  StyledCardWrapper,
  StyledMetricsWrapper,
} from './CarrierBillingDeliveryNotesMetrics.styles'
import FormatUtils from '../../../../../utilities/utils/format'
import { useTheme } from 'styled-components/native'
import { useEffect, useState } from 'react'

const CarrierBillingDeliveryNotesMetrics = ({
  metrics,
  cartBatchsStats,
}: CarrierBillingDeliveryNotesMetricsProps) => {
  const theme = useTheme()

  return (
    <Row>
      <Col xs={6}>
        <StyledCardWrapper onPress={() => null}>
          <Card>
            <Typography.Body colorName="text-dark-1" align="center" bold>
              Marchandises
            </Typography.Body>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <StyledMetricsWrapper>
              <Row>
                <Col xs={8} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center" bold>
                    Total HT Achats producteur
                  </Typography.BodySmall>
                </Col>
                <Col xs={4} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center" bold>
                    {FormatUtils.formatPrice(cartBatchsStats?.totalHTProducer)}
                  </Typography.BodySmall>
                </Col>
              </Row>
              <Row>
                <Col xs={8} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center" bold>
                    Total HT Ventes producteur
                  </Typography.BodySmall>
                </Col>
                <Col xs={4} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center" bold>
                    {FormatUtils.formatPrice(cartBatchsStats?.totalHT)} (
                    {FormatUtils.formatPrice(cartBatchsStats?.totalHTCheck)})
                  </Typography.BodySmall>
                </Col>
              </Row>
            </StyledMetricsWrapper>
          </Card>
        </StyledCardWrapper>
      </Col>
      <Col xs={6}>
        <StyledCardWrapper onPress={() => null}>
          <Card>
            <Typography.Body colorName="text-dark-1" align="center" bold>
              Bons de livraisons
            </Typography.Body>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <StyledMetricsWrapper>
              <Row>
                <Col xs={8} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center" bold>
                    Total HT
                  </Typography.BodySmall>
                </Col>
                <Col xs={4} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center" bold>
                    {FormatUtils.formatPrice(metrics?.totalHT)}
                  </Typography.BodySmall>
                </Col>
              </Row>
              <Row>
                <Col xs={8} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center">
                    TVA 5,5%
                  </Typography.BodySmall>
                </Col>
                <Col xs={4} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center">
                    {FormatUtils.formatPrice(metrics?.totalVAT55)}
                  </Typography.BodySmall>
                </Col>
              </Row>
              <Row>
                <Col xs={8} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center">
                    TVA 10%
                  </Typography.BodySmall>
                </Col>
                <Col xs={4} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center">
                    {FormatUtils.formatPrice(metrics?.totalVAT10)}
                  </Typography.BodySmall>
                </Col>
              </Row>
              <Row>
                <Col xs={8} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center">
                    TVA 20%
                  </Typography.BodySmall>
                </Col>
                <Col xs={4} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center">
                    {FormatUtils.formatPrice(metrics?.totalVAT20)}
                  </Typography.BodySmall>
                </Col>
              </Row>
              <Row>
                <Col xs={8} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center">
                    Total TTC
                  </Typography.BodySmall>
                </Col>
                <Col xs={4} alignItems="flex-end">
                  <Typography.BodySmall colorName="text-dark-1" align="center">
                    {FormatUtils.formatPrice(metrics?.totalTTC)}
                  </Typography.BodySmall>
                </Col>
              </Row>
            </StyledMetricsWrapper>
          </Card>
        </StyledCardWrapper>
      </Col>
    </Row>
  )
}

export default CarrierBillingDeliveryNotesMetrics
