import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../../utilities/styling/wrappers'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { ProducerStackParamList } from '../../../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { Tour } from '../../../../../domain'
import { useQuery } from '@tanstack/react-query'
import TourProducerService from '../../../../../services/producer/tour'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import TourTopBox from '../../../../../components/TourTopBox'
import { Button, SectionTitle, Spacer, Typography } from '../../../../../components'
import CartBatchCard from '../../../../../modules/Batch/CartBatchCard'
import Loader from '../../../../../components/Loader'
import { UserEnums } from '../../../../../../enums'
import { StackNavigationProp } from '@react-navigation/stack'
import CardSettings from '../../../../../components/CardSettings'
import StorageUtil from '../../../../../utilities/storage/storage'
import CartProducerService from '../../../../../services/producer/cart'
import OrderProducerService from '../../../../../services/producer/order'
import { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { View } from 'react-native'
import { queryClient } from '../../../../../utilities/queryClient'
import { GroupEnums } from '../../../../../../enums/group'
import CartBatchCardNew from '../../../../../components/CartBatchCardNew'

const ProducerTourOrderScreen = () => {
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerTourOrder'>>()
  const { cartId, tourId } = route?.params ?? { cartId: '', tourId: '' }
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [loading, setLoading] = useState(false)
  const [mailSent, setMailSent] = useState(false)
  const theme = useTheme()

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = useQuery<Tour, Error>(
    ['p_tour_orders'],
    () => TourProducerService.getOneTourProducerOrders(tourId || '', getAccessInfos().producerId),
    {},
  )

  const cart = tour?.carts?.find((cart) => cart?.id === cartId)

  if (!cart) {
    return <Loader isLoading pageLoading />
  }

  const onClickCart = async () => {
    if (!cart?.id || !tourId) return
    const newCart = await CartProducerService.createUpdateOrder(cart?.client?.id!, cart?.id)
    if (newCart && newCart.id) {
      await StorageUtil.setItem('p_cart_id', newCart.id)
      const now = new Date()
      now.setMinutes(now.getMinutes() + 60)
      await StorageUtil.setItem('p_cart_id_expiration_date', now)
      navigation.navigate('ProducerUpdateCart', { cartId: newCart.id, tourId: tourId })

      return
    }
  }

  const onDeleteOrders = async () => {
    if (!cart.order?.id || !tourId) return
    await OrderProducerService.deleteOrder(getAccessInfos().producerId, cart.order.id)
    queryClient.invalidateQueries(['p_tour_orders'])
    navigation.navigate('ProducerTour', { id: tourId })
  }

  const onSendMail = async () => {
    if (!cart || !cart.id || !cart?.client?.mail) return
    setLoading(true)
    await OrderProducerService.sendMail(cart?.id, [cart?.client?.mail]).then(() => {
      setLoading(false)
      setMailSent(true)
    })
  }

  const isTourClosed = () => {
    if (new Date() > new Date(tour?.close ?? '')) {
      return true
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <TourTopBox tour={tour} cart={cart} userRole={UserEnums.RoleEnums.PRODUCER} />
      <ContentWrapper marginTopLarge>
        <Spacer fixedSize={50} axis={Spacer.AxisEnum.VERTICAL} />
        <SectionTitle title={'Contenu de la commande'} />
        {cart?.cartBatchs?.map((cartBatch) => (
          <CartBatchCardNew
            cartBatch={cartBatch}
            cart={cart}
            noButton
            key={cartBatch.id}
            displayProducerPrice
            displayClientPrice
            displayMinPrice
            displayGain
          />
        ))}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
        {tour?.group?.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS && (
          <>
            <SectionTitle title={`Actions`} />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <CardSettings
              title="Un changement sur la commande ?"
              description={
                isTourClosed() ? 'Les commandes sont fermées' : 'Vous pouvez modifier la commande'
              }
              children={
                <Button
                  fullWidth={true}
                  colorName="color-grey"
                  label={'Modifier'}
                  onPress={() => onClickCart()}
                  disabled={isTourClosed()}
                />
              }
            />

            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <CardSettings
              title="Une annulation ?"
              description={`Le client souhaite annuler sa commande`}
              children={
                <Button
                  fullWidth={true}
                  colorName="color-grey"
                  label={'Supprimer'}
                  onPress={() => onDeleteOrders()}
                  disabled={false}
                  hasDoubleValidation
                  confirmationLabel="Êtes-vous sûr de vouloir supprimer cette commande ?"
                />
              }
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <CardSettings
              title="Notification de commande"
              description={`Le client souhaite recevoir un nouveau mail`}
              children={
                <View style={{ width: '100%' }}>
                  <Typography.BodySmall semiBold colorName={'text-dark-3'} align="center">
                    {mailSent && 'Mail envoyé'}
                  </Typography.BodySmall>
                  <Button
                    fullWidth={true}
                    colorName="color-grey"
                    label={'Confirmer'}
                    onPress={() => onSendMail()}
                    hasDoubleValidation
                    confirmationLabel="Êtes-vous sûr de vouloir renvoyer un mail de confirmation ?"
                    disabled={false}
                    loading={loading}
                  />
                </View>
              }
            />
          </>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerTourOrderScreen
