import { Cart, DeliveryNotesCartBatchs, Pagination } from '../../../domain'
import ApiService from '../../api.service'

const getAllCartBatchs = async (
  groupId?: string,
  tourId?: string,
  clientId?: string,
  producerId?: string,
  before?: Date,
  after?: Date,
  limit: number = 30,
  pageNumber: number = 1,
): Promise<{ data: DeliveryNotesCartBatchs[]; pagination: Pagination; stats: any }> => {
  const response = await ApiService.get(`/cart/producer/${producerId}/cartBatchs`, {
    clientId: clientId || undefined,
    producerId: producerId || undefined,
    groupId: groupId || undefined,
    before: before || undefined,
    after: after || undefined,
    limit,
    page: pageNumber,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })

  return response
}
const create = async (clientId: string, producerId: string, dto: any): Promise<Cart> => {
  const response = await ApiService.post(
    `/cart/producer/${producerId}/client/${clientId}`,
    dto,
  ).catch((error) => {
    throw error
  })
  return response
}

const createUpdateOrder = async (clientId: string, idCart: string): Promise<Cart> => {
  const response = await ApiService.post(`/cart/${idCart}/client/${clientId}`, {}).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const getOneCart = async (cartId?: string): Promise<Cart | null> => {
  if (!cartId) return null
  const response = await ApiService.get(`/cart/${cartId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const CartProducerService = {
  getAllCartBatchs,
  createUpdateOrder,
  getOneCart,
  create,
}

export default CartProducerService
