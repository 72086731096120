import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, Spacer, Typography } from '../../../components'
import { Pagination, Tour } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierAddTour.styles'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'

const CarrierAddTourScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()
  const sixMonthsAgo = new Date()
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6)

  const onMonthAfter = new Date()
  onMonthAfter.setMonth(onMonthAfter.getMonth() + 1)

  const {
    data: tours,
    refetch: refetchNextTours,
    isInitialLoading: nextToursLoading,
  } = useQuery<{ data: Tour[]; pagination: Pagination }, Error>(
    ['sf_all_tours_check'],
    () =>
      TourCarrierService.getAllTours(
        getAccessInfos().carrierId,
        undefined,
        undefined,
        5,
        undefined,
        undefined,
        undefined,
        sixMonthsAgo,
        onMonthAfter,
      ),
    {
      keepPreviousData: true,
    },
  )

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title={FormatUtils.capitalize(i18n.t('pageTitleAddTour'))} />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={6} />

          <StyledButtonWrapper>
            {(tours?.data.length || 0) > 0 && (
              <>
                <Button
                  label="Dupliquer une tournée"
                  onPress={() => navigation.navigate('CarrierDuplicateNewTour', {})}
                />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                <Typography.Body align="center" bold>
                  OU
                </Typography.Body>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              </>
            )}
            <Button
              label="Créer une nouvelle tournée"
              onPress={() => navigation.navigate('CarrierAddNewTour', {})}
            />
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierAddTourScreen
