import styled from 'styled-components/native'

const FormContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 4}px;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

export { FormContentWrapper, StyledButtonWrapper }
