import React from 'react'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'

function UserNotifications() {
  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper noPaddingTop />
    </ScreenSafeAreaWrapper>
  )
}

export default UserNotifications
