import React from 'react'
import { useTheme } from 'styled-components/native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ListRenderItem, Switch, TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import { Icon, Icons, Divider, Typography } from '../../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'
import {
  StyledLeftView,
  StyledRightView,
  StyledElementsWrapper,
  StyledFlatList,
} from './UserLoginSecurity.styles'
import useUserContext from '../../../../utilities/hook/useUserContext'
import { UserLoginSecurityItem } from './UserLoginSecurity.model'

function UserLoginSecurity() {
  const navigation = useNavigation<StackNavigationProp<any>>()
  const theme = useTheme()
  const { user, biometricAuthenticationEnabled, setBiometricAuthenticationEnabled } =
    useUserContext()

  const items = [
    {
      label: 'Email',
      value: user?.email,
      onPress: () => {
        navigation.navigate('UserConfigurationNav', { screen: 'UserEmail', params: {} })
      },
    },
    {
      label: 'Mot de passe',
      value: '*****************',
      onPress: () => {
        navigation.navigate('UserUpdatePassword', {
          screen: 'UserPassword',
          params: {
            email: user?.email,
          },
        })
      },
    },
    /* {
      label: 'Phone Number',
      value: user?.phone?.formatInternational,
      onPress: () => {
        navigation.navigate('UserConfigurationNav', {
          screen: 'UserPhone',
          params: {
            email: user?.email,
          },
        })
      },
    },
    {
      label: 'Enable Biometric Authentication',
      value: biometricAuthenticationEnabled,
      onPress: (value: boolean) => {
        setBiometricAuthenticationEnabled(value)
      },
      type: 'switch',
    }, */
  ]

  const renderItem: ListRenderItem<UserLoginSecurityItem> = ({ item }) => {
    if (item.type === 'switch') {
      return (
        <TouchableWithoutFeedback onPress={() => item.onPress(!item.value)}>
          <StyledElementsWrapper>
            <StyledLeftView>
              <Typography.InputLabel gutterBottom>{item.label}</Typography.InputLabel>
            </StyledLeftView>
            <StyledRightView>
              <Switch
                value={!!item.value}
                onValueChange={item.onPress}
                thumbColor={theme.colors['text-light-1']}
                trackColor={{ true: theme.colors['color-primary'] }}
              />
            </StyledRightView>
          </StyledElementsWrapper>
        </TouchableWithoutFeedback>
      )
    }

    return (
      <TouchableOpacity activeOpacity={theme.activeOpacity} onPress={item.onPress}>
        <StyledElementsWrapper>
          <StyledLeftView>
            <Typography.InputLabel gutterBottom>{item.label}</Typography.InputLabel>
            <Typography.Body>{item.value}</Typography.Body>
          </StyledLeftView>
          <StyledRightView>
            <Icon
              type={Icons.Ionicons}
              name="pencil-outline"
              color={theme.colors['color-primary']}
            />
          </StyledRightView>
        </StyledElementsWrapper>
      </TouchableOpacity>
    )
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper noPaddingTop noDrawer>
        <StyledFlatList
          bounces={false}
          data={items}
          renderItem={renderItem}
          ItemSeparatorComponent={Divider}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default UserLoginSecurity
