import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Icon,
  Icons,
  Spacer,
  TitleWithTag,
  TouchableCard,
  Typography,
} from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { BatchCartBatchsCardProps } from './BatchCartBatchsCard.model'
import {
  CancelButtonWrapper,
  StyledCardContent,
  StyledCardWrapper,
  StyledCrateBox,
  StyledCratesBox,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRightBox,
  StyledValueBox,
  StyledValuesWrapper,
} from './BatchCartBatchsCard.styles'
import BatchCarrierService from '../../../services/carrier/batch'
import { queryClient } from '../../../utilities/queryClient'
import { CartBatch } from '../../../domain'
import { UserEnums } from '../../../../enums'
import { CartBatchCarrierStatusEnum } from '../../../../enums/cartBatch'
import { View } from 'react-native'

const BatchCartBatchsCard = ({
  cartBatchs,
  onClick,
  batch,
  userRole,
}: BatchCartBatchsCardProps) => {
  const theme = useTheme()
  const [isIdentificationsExpanded, setIsIdentificationsExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const OnClickBatch = () => {
    onClick(batch)
  }

  const isStatusBatch = (status: CartBatchCarrierStatusEnum, cartBatchs: CartBatch[]): boolean => {
    if (
      cartBatchs.find(
        (cartBatch) => cartBatch.carrierStatus === CartBatchCarrierStatusEnum.PREPARED,
      ) !== undefined
    ) {
      return true
    }
    if (cartBatchs.find((cartBatch) => !cartBatch.carrierStatus)) {
      return false
    }
    return cartBatchs.every((cartBatch) => cartBatch.carrierStatus === status)
  }

  let identifications: string[] = []
  let totalCrates = 0
  let totalQuantity = 0
  let packings: number[] = []

  cartBatchs.map((cartBatch) => {
    identifications = identifications.concat(cartBatch.identifications || [])
    totalCrates += cartBatch.packingQuantity || 0
    totalQuantity += cartBatch.totalQuantity || 0
    if (!packings.find((pack) => pack === cartBatch.packing)) {
      packings.push(cartBatch.packing || 0)
    }
  })

  if (!batch) return null
  if (!userRole) return null

  let displayedIdentifications = [...identifications]

  if (identifications.length > 12 && !isIdentificationsExpanded) {
    displayedIdentifications = displayedIdentifications.splice(0, 10)
  }

  const updateCartBatchsStatus = async (status: CartBatchCarrierStatusEnum | null) => {
    setIsLoading(true)
    const cartBatchsToUpdate = cartBatchs.map((cartBatch) => ({
      id: cartBatch.id,
      carrierStatus: status,
    }))
    await BatchCarrierService.updateCartBatchs(batch.id, cartBatchsToUpdate)
      .then(() => {
        queryClient.invalidateQueries(['sf_tour_batchs'])
        queryClient.invalidateQueries(['sf_tour_cart_batchs'])
      })
      .catch((error) => {
        console.error(error)
      })
    setIsLoading(false)
  }

  return (
    <StyledCardWrapper onPress={OnClickBatch} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.Body colorName="text-dark-1" bold>
              {totalCrates} colis
            </Typography.Body>
            <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />

            {!identifications || identifications?.length < 1 ? (
              <View>
                <View>
                  <Typography.BodySmall colorName="text-dark-3">
                    Identification
                  </Typography.BodySmall>
                </View>

                <Typography.BodySmall colorName="text-dark-3">à générer</Typography.BodySmall>
              </View>
            ) : null}
            <StyledCratesBox>
              {displayedIdentifications?.map((identification) => (
                <StyledCrateBox key={identification} activeOpacity={1}>
                  <Typography.Body colorName="text-light-1">{identification}</Typography.Body>
                </StyledCrateBox>
              ))}
              {identifications.length > 12 && (
                <StyledCrateBox
                  key={'more'}
                  noBackground
                  onPress={() => setIsIdentificationsExpanded(!isIdentificationsExpanded)}
                >
                  <Typography.Body colorName="text-light-1">
                    {!isIdentificationsExpanded ? '...' : ' - '}
                  </Typography.Body>
                </StyledCrateBox>
              )}
            </StyledCratesBox>
          </StyledLeftBox>
          <StyledMiddleBox>
            <TitleWithTag tag={batch.product?.internalReference}>
              <Typography.Body bold ellipsis>
                {batch.product?.label}
              </Typography.Body>
            </TitleWithTag>

            <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />

            <StyledValuesWrapper>
              <StyledValueBox>
                <Typography.BodySmall colorName="text-dark-3">Colis de</Typography.BodySmall>
                <Typography.Body semiBold>
                  {packings[0] &&
                    FormatUtils.formatQuantity(packings[0], batch.product?.mesureType)}
                  {packings[1] &&
                    `
                    (ou ${FormatUtils.formatQuantity(packings[1], batch.product?.mesureType)})
                  `}
                </Typography.Body>
              </StyledValueBox>
              <StyledValueBox>
                <Typography.BodySmall colorName="text-dark-3">Quantité</Typography.BodySmall>
                <Typography.Body semiBold>
                  {FormatUtils.formatQuantity(totalQuantity, batch.product?.mesureType)}
                </Typography.Body>
              </StyledValueBox>
            </StyledValuesWrapper>
          </StyledMiddleBox>
          {userRole === UserEnums.RoleEnums.CARRIER && (
            <StyledRightBox>
              <StyledValuesWrapper>
                <StyledValueBox>
                  {isStatusBatch(CartBatchCarrierStatusEnum.PREPARED, cartBatchs) ||
                  isStatusBatch(CartBatchCarrierStatusEnum.RECEIVED, cartBatchs) ? (
                    <>
                      <Icon
                        type={Icons.Ionicons}
                        name="checkmark-circle-outline"
                        color={theme.colors['color-primary']}
                      />
                      <Typography.BodySmall colorName="text-dark-3">Reçu</Typography.BodySmall>
                      <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
                      <CancelButtonWrapper onPress={() => updateCartBatchsStatus(null)}>
                        <Typography.BodyExtraSmall semiBold colorName="text-light-1">
                          Annuler
                        </Typography.BodyExtraSmall>
                      </CancelButtonWrapper>
                    </>
                  ) : (
                    <Button
                      small
                      lowPadding
                      label={'Reçu'}
                      onPress={() => updateCartBatchsStatus(CartBatchCarrierStatusEnum.RECEIVED)}
                      loading={isLoading}
                    />
                  )}
                </StyledValueBox>
              </StyledValuesWrapper>
            </StyledRightBox>
          )}
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default BatchCartBatchsCard
