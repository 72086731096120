import { Cart, CartBatch } from '../../../domain'
import ApiService from '../../api.service'

const updateCartBatch = async (
  cartId: string,
  batchId: string,
  cartBatch: any,
): Promise<CartBatch> => {
  const response = await ApiService.patch(`/cart/${cartId}/batch/${batchId}`, cartBatch).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const create = async (clientId: string, carrierId: string, dto: any): Promise<Cart> => {
  const response = await ApiService.post(
    `/cart/carrier/${carrierId}/client/${clientId}`,
    dto,
  ).catch((error) => {
    throw error
  })
  return response
}

const createUpdateOrder = async (clientId: string, idCart: string): Promise<Cart> => {
  const response = await ApiService.post(`/cart/${idCart}/client/${clientId}`, {}).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const getOneCart = async (cartId?: string): Promise<Cart | null> => {
  if (!cartId) return null
  const response = await ApiService.get(`/cart/${cartId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const CartCarrierService = {
  updateCartBatch,
  create,
  createUpdateOrder,
  getOneCart,
}

export default CartCarrierService
