import React, { useState } from 'react'
import { StaticCardProps } from './InfoCard.model'
import Typography from '../Typography'
import {
  IconBox,
  InfoBoxContentRight,
  InfoBoxTopContentWrapper,
  InfoBoxTopWrapper,
  InfoBoxWrapper,
} from './InfoCard.styles'
import Icon, { Icons } from '../Icon'
import { useTheme } from 'styled-components/native'
import Spacer from '../Spacer'

function InfoBox({ title, content }: StaticCardProps) {
  const theme = useTheme()
  const [toggle, setToggle] = useState(false)
  const handleToggle = () => {
    setToggle(!toggle)
  }
  return (
    <InfoBoxWrapper onPress={handleToggle}>
      <InfoBoxTopWrapper>
        <InfoBoxTopContentWrapper>
          <IconBox>
            <Typography.Body bold align="center">
              ?
            </Typography.Body>
          </IconBox>
          <Spacer size={0.8} axis={Spacer.AxisEnum.HORIZONTAL} />
          <Typography.Body bold>{title}</Typography.Body>
        </InfoBoxTopContentWrapper>
        <Icon
          type={Icons.Ionicons}
          name={toggle ? 'chevron-up-outline' : 'chevron-down-outline'}
          size={theme.spacingUnit * 2.5}
          color={theme.colors['color-primary']}
        />
      </InfoBoxTopWrapper>
      {toggle && (
        <InfoBoxContentRight>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.Body colorName="text-dark-3">{content}</Typography.Body>
        </InfoBoxContentRight>
      )}
    </InfoBoxWrapper>
  )
}

export default InfoBox
