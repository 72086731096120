import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardAddress = styled.View`
  width: 100%;
  max-width: 300px;
  margin: ${({ theme }) => theme.spacingUnit * 1}px;
`

const StyledContentCard = styled.View`
  flex-direction: row;
  justify-content: space-between;
`

const StyledButtonWrapper = styled.View``

export { StyledCardAddress, StyledButtonWrapper, StyledContentCard }
