import { RouteProp, useRoute } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import {
  Button,
  Card,
  DynamicImage,
  PageTitle,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import Loader from '../../../components/Loader'
import { Producer, Document } from '../../../domain'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import ProducerClientService from '../../../services/client/producer'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import DirectusUtil from '../../../utilities/utils/directus'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import DocumentClientService from '../../../services/client/document'

import {
  BottomBoxWrapper,
  BoxWrapperProfile,
  CertificatesWrapper,
  DynamicImageWrapper,
  LeftBox,
  RightBox,
  StyledCardWrapper,
  TopBoxWrapper,
} from './ClientProducer.styles'
import ClientProducerCertificateCard from './components/ClientProducerCertificateCard'
import ProducerGallery from '../../Producer/ProducerPublicProfile/components/ProducerGallery'

const ClientProducerScreen = () => {
  const route = useRoute<RouteProp<ClientStackParamList, 'ClientOrder'>>()
  const { getAccessInfos } = useAuthenticationContext()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isMailSent, setIsMailSent] = useState(false)

  const { id } = route?.params ?? {
    id: '',
  }

  const {
    data: producer,
    refetch: refetchProducer,
    isInitialLoading: producerLoading,
  } = useQuery<Producer, Error>(
    ['c_producer', id],
    () => ProducerClientService.getOneProducer(id, getAccessInfos().clientId),
    {
      keepPreviousData: true,
      enabled: id !== '',
    },
  )

  const {
    data: producerDocuments,
    refetch: refetchDocuments,
    isInitialLoading: producerDocumentsLoading,
  } = useQuery<Document[], Error>(
    ['c_producer_documents', id],
    () => DocumentClientService.getAllDocumentsProducer(producer?.id, getAccessInfos().clientId),
    {
      enabled: producer !== undefined,
    },
  )

  // const requestSentLocalStorage = localStorage.getItem('documentRequestSent')

  const onSendDocumentRequest = async () => {
    if (!producer || isMailSent) return

    await ProducerClientService.sendDocumentRequest(producer?.id, getAccessInfos().clientId)
      .then((response) => {
        setIsLoading(false)

        if (response) {
          setIsMailSent(true)
          // localStorage.setItem('documentRequestSent', 'true')
        }
      })
      .catch((error) => {
        setIsLoading(false)
        setErrorMessage(`Une erreur s'est produite pendant l'envoi de la requête`)
      })
  }

  if (producerLoading || producerDocumentsLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!producer) return null

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Informations sur le producteur" />
        <StyledCardWrapper>
          <Card>
            <BoxWrapperProfile>
              <TopBoxWrapper>
                <LeftBox>
                  <DynamicImageWrapper>
                    <DynamicImage
                      togglesModal
                      imageUri={DirectusUtil.getProducerImageUrlFromId(producer as any as Producer)}
                    />
                  </DynamicImageWrapper>
                </LeftBox>
                <RightBox>
                  <Typography.CardTitle>{producer.label}</Typography.CardTitle>
                  <Typography.BodySmall bold colorName="text-dark-3">
                    {producer.owners} - {producer.location}
                  </Typography.BodySmall>
                </RightBox>
              </TopBoxWrapper>
              <BottomBoxWrapper>
                <Typography.BodySmall colorName="text-dark-3">
                  {producer.description}
                </Typography.BodySmall>
              </BottomBoxWrapper>
            </BoxWrapperProfile>
          </Card>
        </StyledCardWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
        {producer?.galleryImagesIds && producer?.galleryImagesIds?.length > 0 && (
          <ProducerGallery
            mode="view"
            galleryImagesIds={DirectusUtil.getProducerGalleryImageUrlFromId(
              producer as any as Producer,
            )}
          />
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <SectionTitle title={`Documents et certificats`} />
        <CertificatesWrapper>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          {producerDocuments && producerDocuments.length > 0 ? (
            producerDocuments.map((document) => (
              <ClientProducerCertificateCard key={document.id} document={document} />
            ))
          ) : (
            <View style={{ alignItems: 'center' }}>
              <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall>Aucun document</Typography.BodySmall>
              <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
              {!isMailSent && (
                <>
                  <Typography.BodySmall colorName="color-primary">
                    Vous pouvez envoyer une demande de certificat BIO au producteur
                  </Typography.BodySmall>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                </>
              )}
              <Button
                label="Envoyer une demande"
                onPress={() => onSendDocumentRequest()}
                disabled={isMailSent}
              />
              {isMailSent && (
                <>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <Typography.BodySmall colorName="color-primary">
                    Votre demande par mail a été envoyée au producteur
                  </Typography.BodySmall>
                </>
              )}
            </View>
          )}
        </CertificatesWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientProducerScreen
