import { StyleSheet } from 'react-native'
import { DefaultTheme } from 'styled-components/native'

const animatedStyles = (theme: DefaultTheme, textColor?: string) =>
  StyleSheet.create({
    animatedTextContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: theme.spacingUnit * 2,
      zIndex: 1,
    },
    animatedText: {
      fontFamily: 'Inter',
      fontSize: theme.fontSizes.INPUT_LABEL,
      color: textColor,
    },
  })

export default animatedStyles
