import { useState } from 'react'
import { Button, TextInput } from '../../../components'
import { TextTag } from '../../../components/Text/Text.model'
import { View } from 'react-native'

export interface InternalReferenceInputProps {
  value?: string
  onChange: (value: string) => void
}

export function InternalReferenceInput({ value, onChange }: InternalReferenceInputProps) {
  const [isInternalReferenceInputDisplayed, setInternalReferenceInputDisplayed] = useState(!!value)

  return (
    <View
      style={{
        paddingTop: 0,
      }}
    >
      {!isInternalReferenceInputDisplayed && (
        <Button.Text
          label={`Renseigner une référence interne`}
          onPress={() => setInternalReferenceInputDisplayed(!isInternalReferenceInputDisplayed)}
          colorName="text-dark-3"
          textTag={TextTag.BODY_SMALL}
          style={{ alignSelf: 'flex-end' }}
        />
      )}

      {isInternalReferenceInputDisplayed && (
        <>
          <TextInput
            value={value}
            label={`Référence interne`}
            field="text"
            onChangeText={(text) => onChange(text)}
          />
          <Button.Text
            label={`Retirer référence interne`}
            onPress={() => {
              setInternalReferenceInputDisplayed(!isInternalReferenceInputDisplayed)
              onChange('')
            }}
            colorName="text-dark-3"
            textTag={TextTag.BODY_SMALL}
            style={{ alignSelf: 'flex-end' }}
          />
        </>
      )}
    </View>
  )
}
