import { useQuery } from '@tanstack/react-query'
import {
  Button,
  Card,
  CardPageLink,
  PageTitle,
  SectionTitle,
  Spacer,
  TouchableCard,
  Typography,
} from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { Group, GroupRequest, GroupResponse } from '../../../domain'
import GroupProducerService from '../../../services/producer/group'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { RouteProp, useRoute } from '@react-navigation/native'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import InfoCard from '../../../components/InfoCard'
import TourCard from '../../../modules/Tour/TourCard'
import Loader from '../../../components/Loader'
import CardList from '../../../components/CardList'
import { StyledButtonWrapper, StyledStatsWrapper } from './ProducerGroup.styles'
import GroupStats from './components/GroupStats'
import FormatUtils from '../../../utilities/utils/format'
import { useState } from 'react'
import { set } from 'date-fns'

const ProducerGroupScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerGroup'>>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [confirmationMessage, setConfirmationMessage] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { groupId } = route?.params ?? { groupId: '' }

  const {
    data: groupProfile,
    refetch: refetchGroupProfile,
    isInitialLoading: groupProfileLoading,
  } = useQuery<GroupResponse, Error>(
    ['p_group_profile', groupId],
    () => GroupProducerService.findOneByProducerId(groupId, getAccessInfos().producerId),
    {},
  )

  const existingGroupMember = () => {
    if (groupProfile?.producerGroup !== undefined) {
      return true
    }
    return false
  }

  const existingGroupRequest = () => {
    if (
      groupProfile?.group?.id === groupId &&
      groupProfile?.groupRequest?.producer?.id === getAccessInfos().producerId
    ) {
      return true
    }
  }

  const onSendRequest = async () => {
    setErrorMessage('')
    if (!groupId || !getAccessInfos().producerId) {
      setErrorMessage('Une erreur est survenue')
      return
    }
    setIsLoading(true)

    const request = {
      message: '',
      responseMessage: '',
      groupId: groupId,
      producerId: getAccessInfos().producerId,
    }

    const createdGroupRequest = await GroupProducerService.sendGroupRequest(
      groupId,
      getAccessInfos().producerId,
      request,
    )
      .then((res) => {
        refetchGroupProfile()
        setIsLoading(false)
        setConfirmationMessage(true)
        return true
      })
      .catch((error) => {
        setErrorMessage('Une erreur est survenue lors de la demande')
        setIsLoading(false)
        return false
      })
    return
  }

  if (groupProfileLoading) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title={groupProfile?.group.label} />
        <SectionTitle title="Mon groupe" />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <InfoCard leftIcon="location-outline">
          <Typography.BodySmall colorName="text-dark-3">Lieu de dépôt</Typography.BodySmall>
          <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.BodySmall colorName="text-dark-3" bold>
            {FormatUtils.formatAddress(groupProfile?.group.address)}
          </Typography.BodySmall>
        </InfoCard>
        <SectionTitle title="Les dernières tournées du groupe" />
        <CardList
          data={groupProfile?.tours}
          element={function (tour: any) {
            return <TourCard key={tour.id} tour={tour} displayType="tourCardLight" />
          }}
          isLoading={groupProfileLoading}
          emptyMessage="Aucune tournée à venir"
        />
        <SectionTitle title="Statistiques" />
        <StyledStatsWrapper>
          <GroupStats
            value={
              groupProfile?.stats?.sumCA
                ? FormatUtils.formatPrice(groupProfile?.stats?.sumCA, 'ht', undefined, true)
                : '-'
            }
            label={"Chiffre d'affaire"}
            isLoading={groupProfileLoading}
          />
          <GroupStats
            value={groupProfile?.stats?.nbProducts || '-'}
            label={'Nombre de produits'}
            isLoading={groupProfileLoading}
          />
          <GroupStats
            value={FormatUtils.formatPercentage(
              parseFloat(groupProfile?.group?.carrierFees || '') / 100,
            )}
            label={'Frais logistique'}
            isLoading={groupProfileLoading}
          />
        </StyledStatsWrapper>
        <SectionTitle title="Participants" />
        <CardPageLink
          label="Producteurs"
          amount={groupProfile?.group?.producerGroups?.length}
          hasAmountBox
        />
        <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
        <CardPageLink
          label="Clients professionnels"
          amount={groupProfile?.group?.clientGroups?.length}
          hasAmountBox
        />
        <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
        <StyledButtonWrapper>
          {existingGroupMember() && (
            <Typography.Body colorName="color-primary">
              Membre du groupe depuis le{' '}
              {FormatUtils.formatDate(groupProfile?.producerGroup?.createdDate?.toString(), 'Date')}
            </Typography.Body>
          )}
          {!existingGroupMember() && !existingGroupRequest() ? (
            <Button label="Rejoindre" onPress={() => onSendRequest()} loading={isLoading} />
          ) : undefined}
          {confirmationMessage || existingGroupRequest() ? (
            <>
              <Typography.Body colorName="color-primary">
                Demande envoyée le{' '}
                {FormatUtils.formatDate(groupProfile?.groupRequest?.createdDate, 'Date+StartTime')}
              </Typography.Body>
              <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.Body colorName="text-dark-3" align="center">
                Vous recevrez une notification quand les producteurs auront accepté votre demande.
              </Typography.Body>
            </>
          ) : undefined}
        </StyledButtonWrapper>
        {errorMessage ? (
          <>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Typography.Body colorName="color-danger" align="center">
              {errorMessage}
            </Typography.Body>
          </>
        ) : null}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerGroupScreen
