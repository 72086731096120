import styled, { css } from 'styled-components/native'

const BoxWrapperProfile = styled.View`
  width: 100%;
`
const TopBoxWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 0.5}px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
const BottomBoxWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 2}px;
`

const LeftBox = styled.View`
  flex-direction: row;
`

const DynamicImageWrapper = styled.View`
  width: 60px;
  height: 60px;
`

const RightBox = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 1.5}px;
  flex: 6;
`

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const CertificatesWrapper = styled.View`
  width: 100%;
`

export {
  BottomBoxWrapper,
  BoxWrapperProfile,
  CertificatesWrapper,
  LeftBox,
  RightBox,
  StyledCardWrapper,
  TopBoxWrapper,
  DynamicImageWrapper,
}
