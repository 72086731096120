import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert } from 'react-native'
import { useQuery } from '@tanstack/react-query'
import { User } from '../../../domain/User'
import UserService from '../../../services/user'
import useAuthenticationContext from '../../hook/useAuthenticationContext'
import StorageUtil from '../../storage/storage'
import { UserContextData } from './userContext.model'
import AuthenticationService from '../../../services/authentication'
import { UserEnums } from '../../../../enums'

const UserContext = createContext<UserContextData>({} as UserContextData)

const UserProvider: React.FC<any> = ({ children }) => {
  const [biometricAuthenticationEnabled, setBiometricAuthenticationEnabled] =
    useState<boolean>(false)
  const { authenticationData } = useAuthenticationContext()

  const [user, setUser] = useState<Partial<User> | undefined>(undefined)

  useEffect(() => {
    setUser(authenticationData?.access?.user)
  }, [authenticationData])

  useEffect(() => {
    ;(async () => {
      const isBiometricAuthenticationEnabled = await StorageUtil.getItem(
        'user-biometric-authentication-enabled',
      )

      if (isBiometricAuthenticationEnabled === null) {
        Alert.alert('Do you want to allow "Harvy" to use biometric authentication?', '', [
          {
            text: "Don't Allow",
            onPress: async () => {
              setBiometricAuthenticationEnabled(false)
            },
            style: 'cancel',
          },
          {
            text: 'OK',
            onPress: async () => {
              setBiometricAuthenticationEnabled(true)
            },
            style: 'default',
          },
        ])
      }

      if (isBiometricAuthenticationEnabled === 'true') {
        setBiometricAuthenticationEnabled(true)
      }

      if (isBiometricAuthenticationEnabled === 'false') {
        setBiometricAuthenticationEnabled(false)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      await StorageUtil.setItem(
        'user-biometric-authentication-enabled',
        biometricAuthenticationEnabled.toString(),
      )

      /*
      await SecureStore.setItemAsync(
        'user-biometric-authentication-enabled',
        biometricAuthenticationEnabled.toString(),
      )*/
    })()
  }, [biometricAuthenticationEnabled])

  const updatePassword = useCallback(
    async (idUser: string, oldPassword: string, newPassword: string) => {
      const response = await UserService.updatePassword(idUser, oldPassword, newPassword)
      return response
    },
    [],
  )

  const loadUser = () => {
    return
  }

  const value: UserContextData = useMemo(
    () => ({
      user,
      updatePassword,
      loadUser,
      biometricAuthenticationEnabled,
      setBiometricAuthenticationEnabled,
    }),
    [user, loadUser, biometricAuthenticationEnabled, setBiometricAuthenticationEnabled],
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export { UserProvider, UserContext, UserContextData }
