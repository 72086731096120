import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import HeaderHome from '../../components/HeaderHome'
import ProducerToursScreen from '../../../screens/Producer/ProducerTours'
import ProducerTourScreen from '../../../screens/Producer/ProducerTour'
import { Dimensions } from 'react-native'
import Header from '../../components/Header'
import ProducerCatalogScreen from '../../../screens/Producer/ProducerCatalog'
import ProducerAddProductScreen from '../../../screens/Producer/ProducerAddProduct'
import ProducerUpdatePoductScreen from '../../../screens/Producer/ProducerUpdateProduct'

const Stack = createStackNavigator()

const ProductProducerNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="ProducerCatalog"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ProducerCatalog"
        component={ProducerCatalogScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Catalogue',
        }}
      />
      <Stack.Screen
        name="ProducerAddProduct"
        component={ProducerAddProductScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerCatalog" leftButton={<></>} />,
          title: 'Ajouter un produit',
        }}
      />
      <Stack.Screen
        name="ProducerUpdateProduct"
        component={ProducerUpdatePoductScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" leftButton={<></>} />,
          title: 'Modifier un produit',
        }}
      />
    </Stack.Navigator>
  )
}

export default ProductProducerNavigation
