import styled from 'styled-components/native'

const CardContent = styled.View`
  flex-direction: row;
  gap: ${({ theme }) => theme.spacingUnit * 1}px;
  justify-content: space-between;
`

const CardLeftContent = styled.View``

const CardRightContent = styled.View`
  justify-content: center;
`

export { CardContent, CardLeftContent, CardRightContent }
