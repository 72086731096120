import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardParam = styled.View<{ flex?: number; isStart?: boolean; isEnd?: boolean }>`
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  border-radius: 10px;
  width: 100%;
  margin: ${({ theme }) => theme.spacingUnit * 0.5}px;
  margin-left: ${({ theme }) => theme.spacingUnit * 1}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 1}px;

  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}

  ${({ isStart }) =>
    isStart &&
    css`
      margin-left: 0;
    `}

  ${({ isEnd }) =>
    isEnd &&
    css`
      margin-right: 0;
    `}
`

const StyledCardContent = styled.TouchableOpacity`
  flex-direction: row;
`

const StyledLeft = styled.View`
  align-items: left;
  justify-content: flex-start;
  flex: 5;
`

const StyledRight = styled.View`
  align-items: flex-end;
  justify-content: center;
  flex: 2;
`

const StyledBox = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const StyledLeftBox = styled.View`
  flex: 1;
`

const StyledRightBox = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 0.9}px;
`

export {
  StyledCardParam,
  StyledCardContent,
  StyledLeft,
  StyledRight,
  StyledBox,
  StyledLeftBox,
  StyledRightBox,
}
