import AsyncStorage from '@react-native-async-storage/async-storage'

interface StorageUtil {
  getItem(key: string): Promise<string | null>
  setItem(key: string, data: any): Promise<void | string>
  deleteItem(key: string): Promise<void | string>
  clearStorage(): Promise<void>
}

const Platform = {
  OS: typeof window !== 'undefined' ? 'web' : 'test',
}

const StorageUtil: StorageUtil = {
  getItem: async (key: string): Promise<string | null> => {
    if (!key || key === null) return null
    if (Platform.OS === 'web') {
      return localStorage.getItem(key)
    }
    return AsyncStorage.getItem(key)
  },
  setItem: async (key: string, data: any): Promise<void | string> => {
    if (!key || key === null) return ''
    if (Platform.OS === 'web') {
      return localStorage.setItem(key, JSON.stringify(data))
    }
    return AsyncStorage.setItem(key, JSON.stringify(data))
  },
  deleteItem: async (key: string): Promise<void | string> => {
    if (!key || key === null) return ''
    if (Platform.OS === 'web') {
      localStorage.removeItem(key)
    }
    return AsyncStorage.removeItem(key)
  },
  clearStorage: async (): Promise<void> => {
    if (Platform.OS === 'web') {
      localStorage.clear()
    }
    return AsyncStorage.clear()
  },
}

export default StorageUtil
