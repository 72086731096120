export namespace NotificationEnums {
  export enum TYPE {
    FAKE_1 = 'FAKE_1',
  }

  export enum ProducerNotificationSettingsClientOrderEnum {
    NO_MAIL = 0,
    ON_EVERY_ORDER = 1,
    ON_FIRST_ORDER = 2,
  }

  export const PRODUCER_NOTIFICATION_SETTINGS_CLIENT_ORDER = [
    {
      label: 'Pas de mail',
      value: ProducerNotificationSettingsClientOrderEnum.NO_MAIL,
    },
    {
      label: 'À chaque commande',
      value: ProducerNotificationSettingsClientOrderEnum.ON_EVERY_ORDER,
    },
    {
      label: 'Seulement pour la première commande',
      value: ProducerNotificationSettingsClientOrderEnum.ON_FIRST_ORDER,
    },
  ]
}
