import styled from 'styled-components/native'

const StyledTopBox = styled.View`
  flex-direction: row;
`

const GalleryImageWrapper = styled.View`
  flex-direction: row;
`

const TopBoxWrapper = styled.View`
  padding-left: ${({ theme }) => theme.spacingUnit * 4}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 4}px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.spacingUnit * 6}px;
  row-gap: ${({ theme }) => theme.spacingUnit * 3}px;
`
const GalleryWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 2}px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  width: 100%;
`
const BottomBoxWrapper = styled.View`
  padding-top: ${({ theme }) => theme.spacingUnit * 2}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 4}px;
  align-items: center;
  flex: 1;
`

const DynamicImageWrapper = styled.View`
  width: 90px;
  height: 90px;
`
export {
  StyledTopBox,
  GalleryImageWrapper,
  TopBoxWrapper,
  GalleryWrapper,
  BottomBoxWrapper,
  DynamicImageWrapper,
}
