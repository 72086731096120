import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import {
  DeliveryNoteTypeEnum,
  MailEnums,
  ProducerBillingTypeEnum,
  RightKeyEnum,
  TourEnums,
} from '../../../../enums'
import { Button, Icon, Icons, Spacer, Typography } from '../../../components'
import { DeliveryNote, Producer } from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../services/carrier/tour'
import { queryClient } from '../../../utilities/queryClient'
import { InterfaceColors } from '../../../utilities/styling/theme/theme'
import FormatUtils from '../../../utilities/utils/format'
import GeneralUtils from '../../../utilities/utils/general'
import { TourStepperProps } from './TourStepper.model'
import { RightBoxValueWrapper, StyledTourStepperWrapper } from './TourStepper.styles'
import { useQuery } from '@tanstack/react-query'
import DeliveryNoteCarrierService from '../../../services/carrier/deliveryNote'
import TourStepCard from '../../../components/TourStepCard'
import {
  StyledRightBox,
  StyledTourStepCardRightBox,
} from '../../../components/TourStepCard/TourStepCard.styles'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { View } from 'react-native'
import TourUtil from '../../../utilities/utils/tour'

const TourStepper = ({ tour, tourBatchs, onClick, onCardClick }: TourStepperProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [isLoadingAssign, setIsLoadingAssign] = useState(false)
  const { carrierHasRight } = useAuthenticationContext()

  const OnClickTour = () => {
    if (onClick) {
      onClick(tour)
    }
  }

  const {
    data: deliveryNotes,
    refetch: refetchDeliveryNotes,
    isInitialLoading: deliveryNotesLoading,
  } = useQuery<DeliveryNote[], Error>(
    ['sf_tour_delivery_notes', tour.id],
    () => DeliveryNoteCarrierService.getAllTour(tour.id, DeliveryNoteTypeEnum.PRODUCER_CLIENT),
    {
      keepPreviousData: true,
    },
  )

  const mailSend = (mailType: MailEnums): boolean => {
    let isSend = false
    const history = tour.mailsStatus?.history
    if (history && history.length) {
      const foundHistory = history.find((h) => h.type === mailType)
      isSend = foundHistory !== null
    }

    return isSend
  }

  const getLabelFromStatus = () => {
    const status = TourUtil.getTourStatus(tour)
    let label = 'Commandes ouvertes'
    if (tour.close) {
      label = `Ouvert jusqu'au ${FormatUtils.formatDate(
        tour.close,
        'Date',
      )} à ${FormatUtils.formatDate(tour.close, 'Time')}`
    }
    if (status === TourEnums.STATUS.PENDING) {
      label = `${FormatUtils.formatDate(tour.open, 'Date')} à ${FormatUtils.formatDate(
        tour.open,
        'Time',
      )}`
    }
    if (status === TourEnums.STATUS.CLOSED) {
      label = 'Commandes fermées'
    }
    return label
  }

  const getColorFromStatus = (): keyof InterfaceColors => {
    const status = TourUtil.getTourStatus(tour)
    let color: keyof InterfaceColors = 'color-primary'
    if (status === TourEnums.STATUS.PENDING) {
      color = 'color-warning'
    }
    if (status === TourEnums.STATUS.CLOSED) {
      color = 'color-grey'
    }
    return color
  }

  const assignCartBatchs = async () => {
    setIsLoadingAssign(true)
    const res = await TourCarrierService.assignCartBatchs(tour.id)
    if (res && res.id) {
      await queryClient.cancelQueries(['sf_tour', tour.id])
      await queryClient.fetchQuery(['sf_tour', tour.id])
      setIsLoadingAssign(false)
    }
  }

  const onOpenTour = async () => {
    if (!tour || !tour.id) return
    const UpdatedTour = {
      open: new Date(),
    }

    const updatedTourTemp = await TourCarrierService.update(tour.id, UpdatedTour)
    if (updatedTourTemp && updatedTourTemp.id) {
      TourCarrierService.getOne.invalidate(tour.id)
    }
  }

  const onCloseTour = async () => {
    if (!tour || !tour.id) return
    const UpdatedTour = {
      close: new Date(),
    }

    const updatedTourTemp = await TourCarrierService.update(tour.id, UpdatedTour)
    if (updatedTourTemp && updatedTourTemp.id) {
      TourCarrierService.getOne.invalidate(tour.id)
    }
  }

  const getEmptyPropositionCount = () => {
    if (!tour) return 0
    if (!tourBatchs || tourBatchs.length < 1) return tour?.producerTour?.length

    let producersInBatchs: Producer[] = []
    tourBatchs.map((batch) => {
      if (!producersInBatchs.find((producer) => producer.id === batch?.product?.producer?.id)) {
        if (batch?.product?.producer) {
          producersInBatchs.push(batch?.product?.producer)
        }
      }
    })
    const emptyPropositions = (tour?.producerTour?.length || 0) - producersInBatchs.length
    return emptyPropositions >= 0 ? emptyPropositions : 0
  }

  if (!tour) return null

  const producersTour = tour.producerTour || []
  const carts = tour.carts || []

  const nbPossibleDeliveryNotes = () => {
    let nbDeliveryNotes = 0
    producersTour.map((producerTour) => {
      const producerGroup = producerTour.producer.producerGroups?.find(
        (producerGroup) => producerGroup?.group?.id === tour.group?.id,
      )
      if (!producerGroup?.billingType || producerGroup.billingType === 1) {
        nbDeliveryNotes += producerTour.nbOrders || 0
      }
    })
    return nbDeliveryNotes
  }

  const onClickEditTour = () => {
    tour && navigation.navigate('CarrierUpdateTour', { id: tour.id })
  }
  const hasUpdateRight = carrierHasRight(RightKeyEnum.C_TOUR_UPDATE)

  const onNavigateSendMail = () => {
    navigation.navigate('CarrierTourSendMail', { id: tour.id, type: MailEnums.PROPOSITION_CLIENTS })
  }

  const isOrdersClosed = GeneralUtils.isDateBefore(new Date(tour.close), new Date())

  type stepProps = {
    isActive: boolean
    isFinished: boolean
    number: number
    label: string
    description: string
    rightBox: any
    displayMinInfos?: boolean
    rightBoxOnFinished?: any
    display?: boolean
    onPress?: () => void
  }

  const getStep1 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: true,
      isFinished: TourUtil.getTourStatus(tour) !== TourEnums.STATUS.PENDING,
      number: 1,
      label: 'Mercuriales producteurs',
      description: 'Actualisation des propositions de produits',
      rightBox: (
        <StyledTourStepCardRightBox align="flex-end">
          <StyledRightBox>
            <View>
              <RightBoxValueWrapper style={{ justifyContent: 'flex-end' }}>
                <Typography.BodySmall semiBold colorName="color-primary">
                  {FormatUtils.pluralizeAmountLabel('produit', tourBatchs?.length || 0)}
                </Typography.BodySmall>
                <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.5} />
              </RightBoxValueWrapper>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              <Typography.BodySmall semiBold colorName="color-danger">
                {`${FormatUtils.pluralizeAmountLabel(
                  'mercuriale',
                  getEmptyPropositionCount() || 0,
                )} ${FormatUtils.pluralizeAmountLabel(
                  'vide',
                  getEmptyPropositionCount() || 0,
                  true,
                )}`}
              </Typography.BodySmall>
            </View>
          </StyledRightBox>
        </StyledTourStepCardRightBox>
      ),
      onPress: () => navigation.navigate('CarrierTourBatchs', { id: tour.id }),
    }
    return stepPropsValues
  }

  const getStep2 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: true,
      isFinished: TourUtil.getTourStatus(tour) !== TourEnums.STATUS.PENDING,
      number: 2,
      label: 'Ouverture des commandes',
      description: '',
      rightBox:
        TourUtil.getTourStatus(tour) !== TourEnums.STATUS.PENDING ? (
          <StyledTourStepCardRightBox align="flex-end" style={{ paddingRight: '12px' }}>
            <Typography.BodySmall semiBold colorName="color-primary">
              Ouvert
            </Typography.BodySmall>
          </StyledTourStepCardRightBox>
        ) : (
          <StyledTourStepCardRightBox align="center">
            <StyledRightBox>
              <Typography.BodySmall bold colorName={getColorFromStatus()} align="center">
                {TourUtil.getTourStatus(tour) === TourEnums.STATUS.OPEN
                  ? 'Ouvert'
                  : getLabelFromStatus()}
              </Typography.BodySmall>
              <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.1} />
              {TourUtil.getTourStatus(tour) === TourEnums.STATUS.PENDING ? (
                <>
                  <Button
                    small
                    fullWidth={false}
                    lowPadding
                    label={'Ouvrir'}
                    onPress={() => onOpenTour()}
                    hasDoubleValidation
                    confirmationLabel="Êtes-vous sûr ?"
                  />
                </>
              ) : null}
            </StyledRightBox>
          </StyledTourStepCardRightBox>
        ),
    }
    return stepPropsValues
  }
  const getStep3 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: (tour.open && GeneralUtils.isDateBefore(new Date(tour.open), new Date())) || false,
      isFinished: mailSend(MailEnums.PROPOSITION_CLIENTS),
      number: 3,
      label: 'Notification des clients',
      description: 'Envoyer la proposition par mail',
      rightBox:
        TourUtil.getTourStatus(tour) !== TourEnums.STATUS.OPEN ? null : (
          <StyledTourStepCardRightBox align="flex-end">
            <StyledRightBox>
              <Typography.BodySmall
                semiBold
                colorName={
                  mailSend(MailEnums.PROPOSITION_CLIENTS) ? 'color-primary' : 'color-warning'
                }
              >
                {mailSend(MailEnums.PROPOSITION_CLIENTS) ? 'Envoyé' : 'À envoyer'}
              </Typography.BodySmall>
            </StyledRightBox>
          </StyledTourStepCardRightBox>
        ),
      onPress: () => onNavigateSendMail(),
    }
    return stepPropsValues
  }
  const getStep4 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: TourUtil.getTourStatus(tour) !== TourEnums.STATUS.PENDING,
      isFinished: TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED,
      displayMinInfos: true,
      number: 4,
      label: 'Fermeture des commandes',
      description: '',
      rightBox: (
        <StyledTourStepCardRightBox align="flex-end">
          <StyledRightBox>
            <Typography.BodySmall
              bold
              colorName={
                TourUtil.getTourStatus(tour) === TourEnums.STATUS.PENDING
                  ? 'text-dark-3'
                  : getColorFromStatus()
              }
              align="right"
            >
              {TourUtil.getTourStatus(tour) !== TourEnums.STATUS.CLOSED
                ? `${FormatUtils.formatDate(tour.close, 'WeekDay')} ${FormatUtils.formatDate(
                    tour.close,
                    'DateShort',
                  )} à ${FormatUtils.formatDate(tour.close, 'Time')}`
                : 'Fermé'}
            </Typography.BodySmall>
            <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.1} />
            {TourUtil.getTourStatus(tour) === TourEnums.STATUS.OPEN ? (
              <>
                <Button
                  small
                  fullWidth={false}
                  lowPadding
                  label={'Fermer'}
                  hasDoubleValidation
                  confirmationLabel="Êtes-vous sûr ?"
                  colorName="color-grey"
                  onPress={() => onCloseTour()}
                />
              </>
            ) : null}
          </StyledRightBox>
        </StyledTourStepCardRightBox>
      ),
    }
    return stepPropsValues
  }

  const getStep5 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED,
      isFinished:
        TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED &&
        tour.areAllCartBatchIdentified === true,
      number: 5,
      label: 'Identification des colis',
      description: 'Avant réception de la marchandise',
      display: isOrdersClosed,
      rightBox:
        TourUtil.getTourStatus(tour) !== TourEnums.STATUS.CLOSED ? (
          <StyledTourStepCardRightBox align="center"></StyledTourStepCardRightBox>
        ) : (
          <StyledTourStepCardRightBox align="flex-end">
            <StyledRightBox>
              <Typography.BodySmall
                semiBold
                colorName={tour.areAllCartBatchIdentified ? 'color-primary' : 'color-danger'}
              >
                {tour.areAllCartBatchIdentified ? 'Identifiés' : 'À identifier'}
              </Typography.BodySmall>
              {true ? (
                <Button
                  small
                  lowPadding
                  fullWidth={false}
                  colorName={tour.areAllCartBatchIdentified ? 'color-grey' : 'color-secondary'}
                  disabled={isLoadingAssign}
                  label={tour.areAllCartBatchIdentified ? 'Relancer' : 'Lancer'}
                  onPress={() => assignCartBatchs()}
                />
              ) : null}
            </StyledRightBox>
          </StyledTourStepCardRightBox>
        ),
    }
    return stepPropsValues
  }

  const getStep6 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED,
      isFinished:
        nbPossibleDeliveryNotes() === (deliveryNotes || []).length &&
        TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED,
      number: 6,
      label: `Bons de livraison`,
      description: 'Édition des bons de livraison producteurs et clients',
      display: isOrdersClosed,
      onPress: () => (onCardClick ? onCardClick('DOCUMENTS') : null),
      rightBox: <></>,
      /*
      rightBox:
        TourUtil.getTourStatus(tour) !== TourEnums.STATUS.CLOSED ? (
          <StyledTourStepCardRightBox align="center"></StyledTourStepCardRightBox>
        ) : (
          <StyledTourStepCardRightBox align="flex-end">
            <RightBoxValueWrapper>
              <TouchableOpacity onPress={() => (onCardClick ? onCardClick('DOCUMENTS') : null)}>
                <Icon
                  type={Icons.Ionicons}
                  name="chevron-forward"
                  size={theme.spacingUnit * 3}
                  color={theme.colors['color-grey']}
                />
              </TouchableOpacity>
            </RightBoxValueWrapper>
          </StyledTourStepCardRightBox>
        ),
        */
    }
    return stepPropsValues
  }

  const getStep7 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED,
      isFinished: GeneralUtils.isDateBefore(new Date(tour.deposit), new Date()),
      number: 7,
      label: 'Réception et préparation de la marchandise',
      description: 'Consultez le récapitulatif de réception et de chargement de la marchandise',
      display: isOrdersClosed,
      rightBox:
        TourUtil.getTourStatus(tour) !== TourEnums.STATUS.CLOSED ? (
          <StyledTourStepCardRightBox align="center"></StyledTourStepCardRightBox>
        ) : (
          <StyledTourStepCardRightBox align="flex-end">
            <RightBoxValueWrapper>
              <StyledRightBox>
                <Typography.Body semiBold colorName="color-primary">
                  {tour.nbCrates || 0} colis
                </Typography.Body>
              </StyledRightBox>
            </RightBoxValueWrapper>
          </StyledTourStepCardRightBox>
        ),
      onPress: () => navigation.navigate('CarrierTourCrates', { id: tour.id }),
    }
    return stepPropsValues
  }

  const getStep8 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED,
      isFinished: GeneralUtils.isDateBefore(new Date(tour.end), new Date()),
      number: 8,
      label: `Livraison`,
      description: '',
      display: isOrdersClosed,
      rightBox:
        TourUtil.getTourStatus(tour) !== TourEnums.STATUS.CLOSED ? (
          <StyledTourStepCardRightBox align="center"></StyledTourStepCardRightBox>
        ) : (
          <StyledTourStepCardRightBox align="flex-end">
            <RightBoxValueWrapper>
              {carrierHasRight(RightKeyEnum.C_TOUR_DELIVERY) && (
                <Button
                  small
                  label={'Mode livraison'}
                  onPress={() => navigation.navigate('CarrierTourDelivery', { id: tour.id })}
                />
              )}
            </RightBoxValueWrapper>
          </StyledTourStepCardRightBox>
        ),
    }
    return stepPropsValues
  }

  const getStep9 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: GeneralUtils.isDateBefore(new Date(tour.end), new Date()),
      isFinished: tour.status === TourEnums.TourStatusEnum.COMPLETED,
      number: 9,
      label: 'Clôturer la tournée',
      display: isOrdersClosed,
      description: '',
      rightBox:
        tour.status === TourEnums.TourStatusEnum.COMPLETED ? (
          <StyledTourStepCardRightBox align="flex-end">
            <Typography.BodySmall bold colorName="text-dark-3" align="center">
              Clôturé
            </Typography.BodySmall>
          </StyledTourStepCardRightBox>
        ) : (
          <StyledTourStepCardRightBox align="flex-end">
            <StyledRightBox>
              <View style={{ alignItems: 'center' }}>
                <Typography.BodySmall colorName="text-dark-3">Status</Typography.BodySmall>
                <Typography.BodySmall bold colorName="color-warning" align="center">
                  À clôturer
                </Typography.BodySmall>
              </View>
              <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.5} />
              <Button
                small
                fullWidth={false}
                lowPadding
                label={'Clôturer'}
                onPress={() => navigation.navigate('CarrierConcludeTour', { id: tour.id })}
              />
            </StyledRightBox>
          </StyledTourStepCardRightBox>
        ),
    }
    return stepPropsValues
  }

  const renderStep = (step: stepProps) => {
    if (step.display !== undefined && step.display === false) {
      return <></>
    }
    return <TourStepCard step={step} onPress={step.onPress} />
  }

  return (
    <StyledTourStepperWrapper>
      {renderStep(getStep1())}
      {renderStep(getStep2())}
      {renderStep(getStep3())}
      {renderStep(getStep4())}
      {renderStep(getStep5())}
      {renderStep(getStep6())}
      {renderStep(getStep7())}
      {renderStep(getStep8())}
      {renderStep(getStep9())}
    </StyledTourStepperWrapper>
  )
}

export default TourStepper
