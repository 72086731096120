import styled, { css } from 'styled-components/native'

const StyledTouchableHighlight = styled.TouchableHighlight<{
  isSelected: boolean
  withDivider?: 'top' | 'bottom' | 'both' | 'none'
  zIndex?: number
}>`
  flex-direction: row;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacingUnit * 2}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 2}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 2}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 2}px;
  background-color: transparent;
  border-width: 0px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors['color-border']};
    `}

  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `}

    

  ${({ withDivider }) => {
    switch (withDivider) {
      case 'top':
        return css`
          border-top-width: 1px;
          border-top-color: ${({ theme }) => theme.colors['color-grey']};
        `
      case 'bottom':
        return css`
          border-bottom-width: 1px;
          border-bottom-color: ${({ theme }) => theme.colors['color-grey']};
        `
      case 'both':
        return css`
          border-top-width: 1px;
          border-top-color: ${({ theme }) => theme.colors['color-grey']};
          border-bottom-width: 1px;
          border-bottom-color: ${({ theme }) => theme.colors['color-grey']};
        `
      default:
        return ''
    }
  }}
`
const StyledTickIconWrapper = styled.View`
  margin-left: auto;
`

export { StyledTouchableHighlight, StyledTickIconWrapper }
