import { useNavigation } from '@react-navigation/native'
import { StackHeaderProps } from '@react-navigation/stack'
import React, { useCallback, useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useTheme } from 'styled-components/native'
import { Button, Spacer, TextInput, DocumentPickerInput } from '../../../../components'
import { User } from '../../../../domain'
import i18n from '../../../../i18n'
import UserAvatar from '../../../../modules/User/UserAvatar'
import Header from '../../../../navigation/components/Header'
import UserService from '../../../../services/user'
import useUserContext from '../../../../utilities/hook/useUserContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'
import FormatUtils from '../../../../utilities/utils/format'
import {
  StyledAvatarUpdateWrapper,
  StyledButtonWrapper,
  StyledUserContentWrapper,
} from './UserProfile.styles'

function UserProfile() {
  const theme = useTheme()
  const nav = useNavigation()
  const { user, loadUser } = useUserContext()
  const [userName, setUserName] = React.useState(user?.name)
  const [description, setDescription] = React.useState('')

  const [userPhotoUrl, setUserPhotoUrl] = React.useState(user?.photoUrl)

  /*
  const { mutate: updateUser } = useMutation(
    (userUpdated: User): Promise<void> => UserService.update(userUpdated),
    {
      onSuccess: () => {
        nav.goBack()
        loadUser()
      },
    },
  )
  */

  /*
  const { mutate: uploadImages } = useMutation(
    (documents: File[]) => UserService.uploadManyDocumentsPublic(documents),
    {
      onSuccess: (images) => {
        setUserPhotoUrl(images[0].url)
      },
    },
  )
  */

  const saveProfile = useCallback(() => {
    const updatedUser = {
      ...user,
      name: userName,
      photoUrl: userPhotoUrl,
    } as User

    // updateUser(updatedUser)
  }, [user, userPhotoUrl, userName])

  useEffect(() => {
    nav.setOptions({
      headerShown: true,
      header: ({ navigation }: StackHeaderProps) => (
        <Header
          leftButton={
            <Button.Text
              label="Cancel"
              onPress={navigation.goBack}
              style={{ flex: 1, textAlign: 'right' }}
              textStyle={{ color: theme.colors['text-dark-1'] }}
            />
          }
          rightButton={
            <Button.Text
              label="Enregistrer"
              onPress={saveProfile}
              style={{ flex: 1, textAlign: 'right' }}
            />
          }
          title="Edit Profile"
        />
      ),
    })
  }, [nav, saveProfile, theme.colors])

  const updateUserAvatar = useCallback((images: any) => {
    // uploadImages(images)
  }, [])

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <StyledUserContentWrapper>
          <StyledAvatarUpdateWrapper>
            <UserAvatar user={{ ...user, photoUrl: userPhotoUrl } as User} large />
            <DocumentPickerInput
              imagesOnly
              buttonOnly
              customButtonText={(props) => (
                <Button.Text
                  {...props}
                  label="Modifier la photo"
                  iconName=""
                  style={{ justifyContent: 'center' }}
                />
              )}
              onAddFiles={updateUserAvatar}
            />
          </StyledAvatarUpdateWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
          <TextInput
            field="name"
            label="Nom de la ferme / exploitation"
            value={userName}
            onChangeText={setUserName}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
          <TextInput
            field="description"
            label="Courte Description"
            multiline
            value={description}
            onChangeText={setDescription}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />

          <StyledButtonWrapper>
            <Button label={FormatUtils.capitalize(i18n.t('save'))} onPress={() => saveProfile()} />
          </StyledButtonWrapper>
        </StyledUserContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default UserProfile
