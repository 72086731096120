import styled, { css } from 'styled-components/native'

const StyledLogoBox = styled.View`
  width: 100%;
  height: ${({ theme }) => theme.spacingUnit * 5}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 0.3}px;
  align-items: center;
`

const StyledImage = styled.Image`
  width: 50%;
  max-width: 150px;
  height: 100%;
`

const StyledWhiteBox = styled.View`
  width: 90%;
  background-color: ${({ theme }) => theme.colors['color-white']};
  padding: ${({ theme }) => theme.spacingUnit * 2.5}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;

  min-height: 100px;
`

const DynamicImageWrapper = styled.View`
  width: 100%;
  height: 200px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
`

const StyledProductLabels = styled.View`
  flex-direction: row;
`

const StyledProductLabel = styled.View`
  justify-content: center;
  align-items: center;
  min-height: 17px;
  margin-right: ${({ theme }) => theme.spacingUnit * 0.6}px;
  width: fit-content;
  padding: ${({ theme }) => theme.spacingUnit * 0.3}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
  background-color: ${({ theme }) => theme.colors['color-primary']};
  border-radius: ${({ theme }) => theme.borderRadius * 0.5}px;
`

const GalleryImageWrapper = styled.View<{ isMobile?: boolean }>`
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacingUnit * 0.5}px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      justify-content: center;
    `}
`

const DynamicImageGalleryWrapper = styled.View`
  width: 48%;
  max-width: 100px;
  height: 80px;
`

export {
  StyledLogoBox,
  StyledImage,
  StyledWhiteBox,
  DynamicImageWrapper,
  StyledProductLabels,
  StyledProductLabel,
  GalleryImageWrapper,
  DynamicImageGalleryWrapper,
}
