import { PageTitle, SectionTitle, Spacer } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { Client, ClientGroup, Pagination } from '../../../domain'
import { useQuery } from '@tanstack/react-query'
import ProducerProducerService from '../../../services/producer/producer'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import ClientOrderCard from '../../../modules/Client/ClientOrderCard'
import { UserEnums } from '../../../../enums'
import { useState } from 'react'
import PaginationBar from '../../../components/PaginationBar'
import ClientCard from '../../../modules/Client/ClientCard'

const ProducerClientsScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [pageSizeClientsWithoutOrder, setPageSizeClientsWithoutOrder] = useState(4)
  const [pageSizeClientsWithOrder, setPageSizeClientsWithOrder] = useState(4)
  const [pageNumberClientWithOrder, setPageNumberClientsWithOrder] = useState(1)
  const [pageNumberClientWithoutOrder, setPageNumberClientsWithoutOrder] = useState(1)

  const {
    data: clientsGroupWithOrders,
    refetch: refetchClientsWithOrders,
    isInitialLoading: clientsWithOrdersLoading,
  } = useQuery<{ data: Client[]; pagination: Pagination }, Error>(
    ['p_clients_with_orders'],
    () =>
      ProducerProducerService.getAllClients(
        getAccessInfos().producerId,
        true,
        pageSizeClientsWithOrder,
        pageNumberClientWithOrder,
      ),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: clientsGroupWithoutOrder,
    refetch: refetchClientsWithoutOrder,
    isInitialLoading: clientsLoadingWithoutOrder,
  } = useQuery<{ data: Client[]; pagination: Pagination }, Error>(
    ['p_clients_without_order'],
    () =>
      ProducerProducerService.getAllClients(
        getAccessInfos().producerId,
        false,
        pageSizeClientsWithoutOrder,
        pageNumberClientWithoutOrder,
      ),
    {
      keepPreviousData: true,
    },
  )

  const onChangePage = async (pageNumber: number) => {
    await setPageNumberClientsWithOrder(pageNumber)
    refetchClientsWithOrders()
  }
  const onChangePageWithoutOrder = async (pageNumber: number) => {
    await setPageNumberClientsWithoutOrder(pageNumber)
    refetchClientsWithoutOrder()
  }

  const onClickCard = (clientId: string) => {
    navigation.navigate('ProducerClient', { clientId: clientId })
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Clients" />
        <SectionTitle title={`Actifs (${clientsGroupWithOrders?.pagination.totalCount})`} />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {clientsGroupWithOrders?.data.map((cg) => (
          <ClientCard
            key={cg.id}
            client={cg || ({} as Client)}
            minInfos
            lowMargin
            onClick={() => onClickCard(cg.id)}
          />
        ))}
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <PaginationBar
          data={clientsGroupWithOrders?.data}
          pagination={clientsWithOrdersLoading ? undefined : clientsGroupWithOrders?.pagination}
          onChangePage={onChangePage}
        />
        <SectionTitle title={`Inactifs (${clientsGroupWithoutOrder?.pagination.totalCount})`} />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {clientsGroupWithoutOrder?.data.map((cg) => (
          <ClientCard
            key={cg.id}
            client={cg || ({} as Client)}
            minInfos
            lowMargin
            onClick={() => onClickCard(cg.id)}
          />
        ))}
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <PaginationBar
          data={clientsGroupWithoutOrder?.data}
          pagination={clientsLoadingWithoutOrder ? undefined : clientsGroupWithoutOrder?.pagination}
          onChangePage={onChangePageWithoutOrder}
        />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerClientsScreen
