import { Button, PageTitle, Spacer, Typography } from '../../../../../components'
import { LandingPageProps } from './LandingPage.model'
import {
  LandingPageWrapper,
  LandingTextContentWrapper,
  PageTitleWrapper,
  StyledButtonWrapper,
  StyledImageLogo,
  StyledLogoBox,
} from './LandingPage.styles'

const CarrierTourLogisticsLandingPage = ({ onClickNext }: LandingPageProps) => {
  return (
    <LandingPageWrapper>
      <StyledLogoBox>
        <StyledImageLogo
          source={require('../../../../../../assets/drawings/two-producers.png')}
          resizeMode="contain"
        />
      </StyledLogoBox>
      <LandingTextContentWrapper>
        <PageTitleWrapper>
          <PageTitle title="Économisez en vous mutualisant" />
        </PageTitleWrapper>
        <Typography.Body align="center">
          Proposez à vos collègues producteurs de rejoindre vos tournées de livraisons. Augmentez
          vos chances de remplir votre camion en vendant ensemble !
        </Typography.Body>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <Typography.Body align="center" bold>
          Économisez jusqu’à 60% sur vos frais de livraison
        </Typography.Body>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
        <PageTitleWrapper>
          <PageTitle title="Définissez vos conditions" />
        </PageTitleWrapper>
        <Typography.Body align="center">
          Nous allons vous aider à définir des conditions afin que les producteurs qui vendent et
          livrent avec vous, vous dédommagent une partie des frais de livraisons.
        </Typography.Body>
      </LandingTextContentWrapper>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
      <StyledButtonWrapper>
        <Button label={'Suivant'} onPress={onClickNext} />
      </StyledButtonWrapper>
    </LandingPageWrapper>
  )
}

export default CarrierTourLogisticsLandingPage
