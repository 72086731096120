import { useNavigation } from '@react-navigation/native'
import { CarrierArrivalPreparationFiltersProps } from './CarrierArrivalPreparationFilters.model'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import DateSwitcher from '../../../../../components/DateSwitcher'
import FormatUtils from '../../../../../utilities/utils/format'

const CarrierArrivalPreparationFilters = ({
  propositionsFilters,
  onChangeFilters,
  nextTours,
}: CarrierArrivalPreparationFiltersProps) => {
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const onChangeDates = (dates: { start: Date; end: Date }) => {
    onChangeFilters({
      ...propositionsFilters,
      start: dates.start,
      end: dates.end,
    })
    return
  }

  return (
    <DateSwitcher
      mode={propositionsFilters.mode}
      dates={{ start: propositionsFilters.start, end: propositionsFilters.end }}
      onChangeDates={onChangeDates}
      label={`${FormatUtils.pluralizeAmountLabel('tournée', nextTours?.length ?? 0)}`}
    />
  )
}

export default CarrierArrivalPreparationFilters
