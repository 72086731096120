import React from 'react'
import { useTheme } from 'styled-components/native'
import { Card, Icon, Icons, Typography } from '../../../../../../components'
import {
  StyledBox,
  StyledCardContent,
  StyledCardParam,
  StyledLeftBox,
  StyledRightBox,
} from './ClientCatalogNavBarDeliveryInfo.styles'
import FormatUtils from '../../../../../../utilities/utils/format'
import { ClientCatalogNavBarDeliveryInfoProps } from './ClientCatalogNavBarDeliveryInfo.model'

function ClientCatalogNavBarDeliveryInfo({ onClick, tour }: ClientCatalogNavBarDeliveryInfoProps) {
  const theme = useTheme()

  const onClickCard = () => {
    if (onClick) {
      onClick()
    }
  }

  if (!tour) return null
  return (
    <StyledCardParam>
      <Card>
        <StyledCardContent onPress={onClickCard}>
          <StyledBox>
            <StyledLeftBox>
              <Icon
                type={Icons.MaterialCommunityIcons}
                name="truck-delivery"
                color={theme.colors['color-primary']}
              />
              <Typography.CardTitle
                style={{ lineHeight: `${theme.fontSizes.CARD_TITLE + 2}px`, alignSelf: 'flex-end' }}
              >
                {FormatUtils.capitalize(
                  FormatUtils.formatDate(tour.start, 'PartialDate', tour.end),
                )}
              </Typography.CardTitle>
              <Typography.BodySmall>
                {`de ${FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end)}`}
              </Typography.BodySmall>
            </StyledLeftBox>
            <StyledRightBox>
              {onClick ? (
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name="square-edit-outline"
                  size={theme.spacingUnit * 2.5}
                  color={theme.colors['color-primary']}
                />
              ) : null}
            </StyledRightBox>
          </StyledBox>
          <Typography.BodySmall colorName="text-dark-3">
            Fin des commandes {FormatUtils.formatDate(tour.close, 'FullDate+StartTime')}
          </Typography.BodySmall>
        </StyledCardContent>
      </Card>
    </StyledCardParam>
  )
}

export default ClientCatalogNavBarDeliveryInfo
