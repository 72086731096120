import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import Typography from '../../../components/Typography'
import { RoleFormProps } from './RoleForm.model'
import { StyledHistoryWrapper } from './RoleForm.styles'
import { Button, Col, Dropdown, Icon, Icons, Row, Spacer } from '../../../components'
import { View } from 'react-native'
import { TouchableHighlight } from 'react-native-gesture-handler'
import { Role } from '../../../domain/Role'
import { RightKeyEnum, RoleProfileTypeEnum } from '../../../../enums'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'

function RoleForm({ roles, userRoles, onChangeRole, dropDownDirection }: RoleFormProps) {
  const theme = useTheme()
  const [addMode, setAddMode] = useState(false)
  const { carrierHasRight } = useAuthenticationContext()

  const onChangeRoleSelect = (role: any) => {
    const tempUserRoles = [...userRoles] || []
    tempUserRoles.push(role)
    onChangeRole(tempUserRoles)
    setAddMode(false)
  }

  const removeRole = (role: Role) => {
    const tempUserRoles = [...userRoles] || []
    const foundLabelIndex = tempUserRoles?.findIndex((l) => l.id === role.id)
    if (foundLabelIndex > -1) {
      tempUserRoles.splice(foundLabelIndex, 1)
    }
    onChangeRole(tempUserRoles)
    setAddMode(false)
  }

  const getAvailableRolesList = () => {
    let filteredRoles = roles

    if (!filteredRoles) return []

    const hasSuperAdminAccesRight = carrierHasRight(RightKeyEnum.C_SUPER_ADMIN)
    if (!hasSuperAdminAccesRight) {
      filteredRoles = filteredRoles.filter((role) => role.accountType !== RoleProfileTypeEnum.ADMIN)
    }

    filteredRoles = filteredRoles.filter((lt) => !userRoles.find((vl) => vl.id === lt.id))

    return filteredRoles.map((role) => ({
      ...role,
      label: role.label,
      value: role.id,
    }))
  }

  return (
    <StyledHistoryWrapper>
      <Typography.Body>Roles</Typography.Body>
      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      {userRoles?.map((role) => {
        return (
          <View key={role.id}>
            <Row>
              <Col xs={8}>
                <Typography.Body>- {role?.label}</Typography.Body>
              </Col>
              <Col xs={4}>
                <TouchableHighlight
                  onPress={() => removeRole(role)}
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Icon
                      type={Icons.Ionicons}
                      name="trash"
                      size={theme.spacingUnit * 2}
                      color={theme.colors['text-dark-3']}
                    />
                  </View>
                </TouchableHighlight>
              </Col>
            </Row>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          </View>
        )
      })}

      {!addMode ? (
        <View style={{ maxWidth: '150px' }}>
          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          <Button.Text
            colorName="text-dark-3"
            label={'Ajouter un role'}
            onPress={() => setAddMode(true)}
          />
        </View>
      ) : (
        <>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Dropdown
            label={'Nouveau Role'}
            onChange={onChangeRoleSelect}
            multiple={false}
            optionsDefault={getAvailableRolesList()}
            zIndex={200}
            itemKey="role"
            dropDownDirection={dropDownDirection}
          />
          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          <View style={{ maxWidth: '150px' }}>
            <Button.Text
              colorName="text-dark-3"
              label={'Annuler'}
              onPress={() => setAddMode(false)}
            />
          </View>
        </>
      )}
    </StyledHistoryWrapper>
  )
}

export default RoleForm
