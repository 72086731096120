// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createNavigationContainerRef } from '@react-navigation/native'

export const navigationRef = createNavigationContainerRef()

export function navigate(name, params?: object) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params)
  }
}

export function getCurrentRouteName(): string {
  return navigationRef.current?.getCurrentRoute()?.name
}
