import React from 'react'
import { useTheme } from 'styled-components/native'
import { stringToKey } from '../../utilities/helpers'
import Icon, { Icons } from '../Icon'
import Typography from '../Typography'
import { BadgeStatusProps } from './BadgeStatus.model'
import StyledView from './BadgeStatus.styles'

const BadgeStatus = ({ label, onPress, color, backgroundColor, extraSmall }: BadgeStatusProps) => {
  const theme = useTheme()

  const onClick = () => {
    if (onPress) {
      onPress()
    }
  }
  return (
    <StyledView backgroundColor={backgroundColor || undefined} extraSmall={extraSmall}>
      {extraSmall ? (
        <Typography.BodyExtraSmall colorName={color} bold>
          {label}
        </Typography.BodyExtraSmall>
      ) : (
        <Typography.BodySmall colorName={color}>{label}</Typography.BodySmall>
      )}
    </StyledView>
  )
}

export default BadgeStatus
