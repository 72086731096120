import { useQuery } from '@tanstack/react-query'
import {
  Cart,
  CartValidationFailure,
  CartValidationRuleName,
  UdpateCartAdmin,
  UdpateCartBatch,
} from '../../../domain'
import ApiService from '../../api.service'

const getAllCarts = async (clientId: string): Promise<Cart[]> => {
  const response = await ApiService.get(`/cart/client/${clientId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOneCart = async (cartId?: string): Promise<Cart | null> => {
  if (!cartId) return null
  const response = await ApiService.get(`/cart/${cartId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const create = async (clientId: string, dto: any): Promise<Cart> => {
  const response = await ApiService.post(`/cart/client/${clientId}`, dto).catch((error) => {
    throw error
  })
  return response
}

const createUpdateOrder = async (clientId: string, idCart: string): Promise<Cart> => {
  const response = await ApiService.post(`/cart/${idCart}/client/${clientId}`, {}).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const updateCartBatch = async (cartId: string, dto: UdpateCartBatch): Promise<Cart> => {
  const response = await ApiService.post(`/cart/${cartId}/cartBatch`, dto).catch((error) => {
    throw error
  })
  return response
}

const updateCartAdmin = async (cartId: string, dto: UdpateCartAdmin): Promise<Cart> => {
  const response = await ApiService.patch(`/cart/${cartId}/admin`, dto).catch((error) => {
    throw error
  })
  return response
}

const validateCart = async (
  idCart?: string,
  rule?: CartValidationRuleName,
): Promise<CartValidationFailure[] | true> => {
  if (!idCart) {
    return []
  }
  const response = await ApiService.get(`/cart/${idCart}/validate`, { rule })
  return response
}

function useValidateCart(idCart?: string, rule?: CartValidationRuleName) {
  function getQueryKey() {
    return ['c_cart_validate', idCart, rule]
  }

  const query = useQuery<CartValidationFailure[] | true, Error>({
    queryKey: getQueryKey(),
    queryFn: () => validateCart(idCart, rule),
  })

  return {
    getQueryKey,
    query,
  }
}

function useGetOneCart(idCart?: string) {
  function getQueryKey() {
    return ['c_cart', idCart]
  }

  function query() {
    return useQuery<Cart | null, Error>({
      queryKey: getQueryKey(),
      queryFn: () => getOneCart(idCart),
    })
  }

  return {
    getQueryKey,
    query,
  }
}

const CartClientService = {
  getAllCarts,
  getOneCart,
  validateCart,
  create,
  createUpdateOrder,
  updateCartBatch,
  updateCartAdmin,
  useValidateCart,
  useGetOneCart,
}

export default CartClientService
