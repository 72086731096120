import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { PublicStackParamList } from '../../../navigation/PublicNavigationStack/PublicNavigationStack.model'
import { Button, DynamicImage, PageTitle, Spacer, Typography } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import {
  DynamicImageGalleryWrapper,
  DynamicImageWrapper,
  GalleryImageWrapper,
  StyledImage,
  StyledLogoBox,
  StyledProductLabel,
  StyledProductLabels,
  StyledWhiteBox,
} from './ShelfLabelInfos.styles'
import ShelfLabelPublicService from '../../../services/public/shelfLabel'
import { useQuery } from '@tanstack/react-query'
import { ShelfLabel } from '../../../domain/ShelfLabel'
import Loader from '../../../components/Loader'
import DirectusUtil from '../../../utilities/utils/directus'
import { Producer, Product } from '../../../domain'
import FormatUtils from '../../../utilities/utils/format'
import GeneralUtils from '../../../utilities/utils/general'
import DeviceUtil from '../../../utilities/utils/device'
import { Linking } from 'react-native'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'

const ShelfLabelInfosScreen = () => {
  const navigation = useNavigation<StackNavigationProp<PublicStackParamList>>()
  const route = useRoute<RouteProp<PublicStackParamList, 'ShelfLabelInfos'>>()
  const { getAccessInfos } = useAuthenticationContext()

  const { id } = route?.params ?? { id: '' }

  const isMobileScreen = DeviceUtil.isMobileApp()

  const {
    data: shelfLabel,
    refetch: refetchShelfLabel,
    isInitialLoading: shelfLabelLoading,
  } = useQuery<ShelfLabel, Error>(
    ['pu_shelf_label'],
    () =>
      ShelfLabelPublicService.getOne(id, getAccessInfos() && getAccessInfos().userId !== undefined),
    {
      keepPreviousData: true,
    },
  )

  if (shelfLabelLoading) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  if (!shelfLabel) {
    return (
      <ScreenSafeAreaWrapper noPaddingLeft>
        <ContentWrapper alignCenter noDrawer noHeader>
          <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.Body colorName="text-dark-3">Informations produit à venir</Typography.Body>
        </ContentWrapper>
      </ScreenSafeAreaWrapper>
    )
  }

  const batch = shelfLabel.cartBatch?.batch

  const product = batch?.product

  const producer = product?.producer

  const order = shelfLabel?.order

  const cart = order?.cart

  const tour = cart?.tour

  const galleryImagesIds =
    DirectusUtil.getProducerGalleryImageUrlFromId(producer as any as Producer) || []

  const onClickLanding = async () => {
    Linking.openURL('https://harvy.tech')
  }

  const harvestDelay = tour?.deposit
    ? GeneralUtils.diffDays(new Date(tour?.deposit), new Date())
    : -1

  return (
    <ScreenSafeAreaWrapper noPaddingLeft>
      <ContentWrapper alignCenter noDrawer noHeader paddingBottomLarge>
        <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.BodySmall colorName="text-dark-3" align="center">
          Vous avez scanné un produit acheté en direct producteur dont la logistique a été géré par
        </Typography.BodySmall>
        <StyledLogoBox>
          <StyledImage
            source={require('../../../../assets/logos/logo_harvy.png')}
            resizeMode="contain"
          />
        </StyledLogoBox>
        <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
        <StyledWhiteBox>
          <DynamicImageWrapper>
            <DynamicImage
              imageUri={DirectusUtil.getProductImageUrlFromId(product as any as Product)?.uri}
              togglesModal
            />
          </DynamicImageWrapper>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.CardTitle>{shelfLabel?.title}</Typography.CardTitle>
          <Typography.BodySmall ellipsis colorName="text-dark-3" bold>
            {shelfLabel?.subTitle}
          </Typography.BodySmall>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />

          {product?.productLabels && product?.productLabels.length > 0 ? (
            <StyledProductLabels>
              {product.productLabels.map((productLabel) => (
                <StyledProductLabel>
                  <Typography.BodySmall colorName="text-light-1" align="center" bold>
                    {productLabel.label}
                  </Typography.BodySmall>
                </StyledProductLabel>
              ))}
            </StyledProductLabels>
          ) : undefined}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
          {tour?.deposit && harvestDelay > 0 && (
            <Typography.BodySmall ellipsis colorName="text-dark-1" bold>
              Récolté le {FormatUtils.formatDate(tour?.deposit, 'FullDate')} (il y a{' '}
              {harvestDelay > 1
                ? `${Math.round(harvestDelay)} ${FormatUtils.pluralizeLabel('jour')}`
                : 'quelques heures'}
              )
            </Typography.BodySmall>
          )}

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          {product?.description && (
            <>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Typography.Body style={{ wordBreak: 'break-word' }}>
                {product?.description}
              </Typography.Body>
            </>
          )}
        </StyledWhiteBox>
        <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
        <StyledWhiteBox>
          <DynamicImageWrapper>
            <DynamicImage
              imageUri={DirectusUtil.getProducerImageUrlFromId(producer as any as Producer)}
              togglesModal
            />
          </DynamicImageWrapper>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.CardTitle>{producer?.label}</Typography.CardTitle>
          <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.BodyExtraSmall colorName="text-dark-3">
            {producer?.location}
          </Typography.BodyExtraSmall>
          <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.Body>{producer?.description}</Typography.Body>
          <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />

          {galleryImagesIds.length > 0 && (
            <GalleryImageWrapper isMobile={isMobileScreen}>
              {producer?.galleryImagesIds?.map((imageUri, index) => (
                <DynamicImageGalleryWrapper>
                  <DynamicImage key={imageUri} imageUri={galleryImagesIds[index]} togglesModal />
                </DynamicImageGalleryWrapper>
              ))}
            </GalleryImageWrapper>
          )}
        </StyledWhiteBox>
        <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
        <StyledWhiteBox>
          <Typography.Body colorName="text-dark-3" bold>
            Qu'est ce qu'Harvy ?
          </Typography.Body>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.Body colorName="text-dark-3">
            Harvy accompagne les producteurs dans la mise en place de leur propre logistique
            mutualisée, sans passer par des intermédiaires. Grâce à un outil dédié et à une mise en
            relation avec des transporteurs adaptés, les producteurs peuvent vendre directement
            leurs produits à des clients professionnels.
          </Typography.Body>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <Button
            onPress={() => onClickLanding()}
            label={`En savoir plus`}
            colorName="color-secondary"
            fullWidth
          />
        </StyledWhiteBox>
        <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ShelfLabelInfosScreen
