import styled from 'styled-components/native'

const BatchContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  align-items: flex-start;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
  /* text-align: center;
  flex-direction: row; */
`

const TextInputWrapper = styled.View`
  width: 65%;
  align-self: center;
`
export { BatchContentWrapper, StyledButtonWrapper, TextInputWrapper }
