import { Cart } from '../Cart'
import { Tour, ToursFake } from '../Tour'

export type UpdatesHistory = {
  carrierUpdates: object[]
  clientUpdates: object[]
  producerUpdates: object[]
}
export type Order = {
  id?: string
  ref?: string
  cart?: Cart
  date?: string
  total?: number
  createdDate?: string
  totalHTBatchs?: number
  totalVAT55?: number
  totalVAT10?: number
  totalVAT20?: number
  totalTTC?: number
  deliveryFeesHT?: number
  updatesHistory?: UpdatesHistory
}

export const OrdersFake: Order[] = []
