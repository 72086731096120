export interface MobileApplicationConfiguration {
  softUpgrade: boolean
  forceUpgrade: boolean
  updateServices: any
  currentVersion: string
  message: string
  link: string
}

export const MobileApplicationConfigurationFake: MobileApplicationConfiguration = {
  softUpgrade: false,
  forceUpgrade: false,
  updateServices: [],
  currentVersion: '1',
  message: 'NO WAY TEST',
  link: 'https://harvy.tech',
}
