import { TourDestinationSlideProps } from './TourDestinationSlide.model'
import { Button, Spacer, Typography } from '../../../../../components'
import { View } from 'react-native'
import { ButtonWrapper } from './TourDestinationSlide.styles'
import { AddTour, City } from '../../../../../domain'
import { useState } from 'react'
import CityForm from '../../../../../modules/City/CityForm'
import { useQuery } from '@tanstack/react-query'
import CityProducerService from '../../../../../services/producer/city'
import AddressForm from '../../../../../modules/Address/AddressForm'

const TourDestinationSlide = ({ onClick, producer, tour }: TourDestinationSlideProps) => {
  const [selectedCity, setSelectedCity] = useState<any>()

  const [newTour, setNewTour] = useState<Partial<AddTour>>({
    cityId: '',
  })

  const {
    data: cities,
    refetch: refetchCities,
    isInitialLoading: citiesLoading,
  } = useQuery<City[], Error>(['cities'], () => CityProducerService.getAll(), {
    keepPreviousData: true,
  })

  const onChangeCity = (value: any) => {
    setSelectedCity(value)
    setNewTour({ ...newTour, cityId: value.id })
  }

  return (
    <>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <View style={{ width: '100%', alignItems: 'center' }}>
        <Typography.PageTitle>{'Point de départ'}</Typography.PageTitle>
      </View>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <AddressForm
        onChangeAddress={(address) => {}}
        address={producer.address}
        producer={producer}
        canEdit={false}
      />
      <View style={{ width: '100%', alignItems: 'center' }}>
        <Typography.PageTitle>{'Destination'}</Typography.PageTitle>
      </View>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <CityForm cities={cities || []} onChangeCity={onChangeCity} selectedCity={selectedCity} />
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      {selectedCity && (
        <ButtonWrapper>
          <Button
            onPress={() => onClick({ ...newTour })}
            label="Valider"
            colorName="color-secondary"
          />
        </ButtonWrapper>
      )}
    </>
  )
}

export default TourDestinationSlide
