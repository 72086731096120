import { Pagination } from '../../../domain'
import { ShelfLabel } from '../../../domain/ShelfLabel'
import ApiService from '../../api.service'

const getAll = async (
  orderId: string,
  limit?: number,
  pageNumber: number = 1,
): Promise<{ data: ShelfLabel[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/shelf-label/order/${orderId}`, {
    limit,
    page: pageNumber,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const update = async (id: string, dto: Partial<ShelfLabel>): Promise<ShelfLabel> => {
  const response = await ApiService.patch(`/shelf-label/${id}`, dto).catch((error) => {
    throw error
  })
  return response
}

const deleteFromOrder = async (orderId: string): Promise<ShelfLabel> => {
  const response = await ApiService.remove(`/shelf-label/order/${orderId}`).catch((error) => {
    throw error
  })
  return response
}

const deleteOne = async (shelfLabelId: string): Promise<ShelfLabel> => {
  const response = await ApiService.remove(`/shelf-label/${shelfLabelId}`).catch((error) => {
    throw error
  })
  return response
}

const getPdf = async (
  orderId: string,
  showPrice: boolean,
  shelfLabelIds?: string[],
): Promise<any> => {
  const response = await ApiService.get(`/shelf-label/order/${orderId}/pdf`, {
    showPrice: showPrice.toString(),
    shelfLabelIds: shelfLabelIds,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOne = async (shelfLabelId: string): Promise<any> => {
  const response = await ApiService.get(`/shelf-label/${shelfLabelId}`)
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const ShelfLabelClientService = {
  getAll,
  update,
  deleteFromOrder,
  deleteOne,
  getPdf,
  getOne,
}

export default ShelfLabelClientService
