import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardWrapperUnclickable = styled.View`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View<{ isTourCardLight?: boolean }>`
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.4}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1}px;

  ${({ isTourCardLight }) =>
    isTourCardLight
      ? css`
          flex-direction: column;
        `
      : css`
          flex-direction: row;
          min-height: 55px;
        `}
`

const StyledLeftBox = styled.View``

const StyledTourDateTimeWrapper = styled.View`
  flex-direction: row;
  align-items: flex-end;
`

const StyledRightBox = styled.View<{ isTourToConcludeCard?: boolean }>`
  flex: 3;
  align-items: flex-end;

  ${({ isTourToConcludeCard }) =>
    isTourToConcludeCard &&
    css`
      gap: ${({ theme }) => theme.spacingUnit * 0.3}px;
    `}
`

const StyledTimeBox = styled.View`
  flex-direction: row;
  align-items: flex-start;
  justify-items: flex-start;
`

const StyledArrowBox = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: ${({ theme }) => theme.spacingUnit * -0.5}px;
`

const StyledTimeBoxLeft = styled.View`
  justify-content: flex-start;
`

const StyledTimeBoxRight = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 0.5}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 0.2}px;
`

const StyledTourInfosBox = styled.View<{ isLargeScreen?: boolean }>`
  align-items: flex-end;
  ${({ isLargeScreen }) =>
    isLargeScreen &&
    css`
      // flex-direction: row;
    `}
`

// <======= displayType === 'tourToConcludeCard' =======>
const StyledRightBoxCapacity = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacingUnit * 0.5}px;
`

export {
  StyledTourDateTimeWrapper,
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledRightBox,
  StyledTimeBox,
  StyledTimeBoxLeft,
  StyledTimeBoxRight,
  StyledArrowBox,
  StyledTourInfosBox,
  StyledCardWrapperUnclickable,
  StyledRightBoxCapacity,
}
