import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Spacer, Typography, PageTitle, Icon, Icons, Dropdown } from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { Document } from '../../../domain'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import {
  StyledButtonWrapper,
  LeftBox,
  RightBox,
  TopBoxWrapper,
  BottomBoxWrapper,
  StyledButtonsWrapper,
  StyledCardWrapper,
  BoxWrapperProfile,
  StyledImageLeft,
  StyledDownloadButtonWrapper,
} from './ProducerDetailsCertificates.styles'
import DirectusUtil from '../../../utilities/utils/directus'
import Loader from '../../../components/Loader'
import DocumentProducerService from '../../../services/producer/document'
import { DocumentEnums } from '../../../../enums/document'
import FormatUtils from '../../../utilities/utils/format'
import * as Linking from 'expo-linking'

const ProducerDetailsCertificatesScreen = () => {
  const theme = useTheme()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedDocumentStatus, setSelectedDocumentStatus] = useState<any | undefined>(undefined)

  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerDetailsCertificates'>>()

  if (!route.params.documentId) return null

  const {
    data: documentData,
    refetch: refetchProducer,
    isInitialLoading: documentLoading,
  } = useQuery<Document, Error>(
    ['p_document', route.params.documentId],
    () =>
      DocumentProducerService.getOneDocumentProducer(
        route.params.documentId,
        getAccessInfos().producerId,
      ),
    {
      keepPreviousData: true,
      enabled: getAccessInfos().producerId !== undefined,
    },
  )

  const onUpdateProducerDocument = async () => {
    if (!selectedDocumentStatus || !documentData || !documentData.id) return
    setErrorMessage('')
    setIsLoading(true)
    const document = {
      status: selectedDocumentStatus.value,
    }
    await DocumentProducerService.updateDocumentProducer(
      document,
      documentData.id,
      getAccessInfos().producerId,
    )
      .then((updatedDocument) => {
        queryClient.invalidateQueries(['p_document', route.params.documentId])
        queryClient.invalidateQueries(['p_documents'])
        navigation.navigate('ProfileProducerNav', {
          screen: 'ProducerCertificates',
          params: {},
        })
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la mise à jour de votre certificat')
      })
    setIsLoading(false)
  }
  const onChangeDocumentStatus = (value: any) => {
    setSelectedDocumentStatus(value)
  }

  const onDeleteDocument = async () => {
    if (!documentData) return
    setIsLoading(true)
    await DocumentProducerService.deleteOneDocumentProducer(
      documentData.id,
      getAccessInfos().producerId,
    )
      .then(() => {
        queryClient.invalidateQueries(['p_document', route.params.documentId])
        queryClient.invalidateQueries(['p_documents'])
        navigation.navigate('ProfileProducerNav', {
          screen: 'ProducerCertificates',
          params: {},
        })
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la suppression de votre certificat')
      })
    setIsLoading(false)
  }

  const onClickDownloadDocument = async (document: Document) => {
    if (!document.id) return

    const documentWithAccess = await DocumentProducerService.getOne(document.id)
    if (!documentWithAccess.documentId || !documentWithAccess.accessToken) return

    Linking.openURL(
      DirectusUtil.getDocumentDownloadUrlFromId(
        documentWithAccess.documentId,
        documentWithAccess.accessToken,
      ),
    )
  }
  if (!documentData) return null
  if (documentLoading) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ScreenSafeAreaWrapper>
      <ContentWrapper noPaddingLeft>
        <PageTitle title={'Modifier un certificat'} />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <ScrollableFormWrapper>
          <StyledCardWrapper>
            <BoxWrapperProfile>
              <TopBoxWrapper>
                <LeftBox>
                  <StyledImageLeft>
                    <Icon
                      type={Icons.MaterialCommunityIcons}
                      name={
                        documentData.documentId ? 'file-document-outline' : 'camera-off-outline'
                      }
                      size={theme.spacingUnit * 4.5}
                      color={theme.colors['color-grey']}
                    />
                  </StyledImageLeft>
                </LeftBox>
                <RightBox>
                  <Typography.CardTitle>{'Votre certificat'}</Typography.CardTitle>
                  <BottomBoxWrapper>
                    <Typography.BodySmall colorName="text-dark-3">
                      {
                        'Vous pouvez uniquement modifier la visibilité de votre certificat. Pour toute autre modification, veuillez supprimer le certificat et en ajouter un nouveau.'
                      }
                    </Typography.BodySmall>
                  </BottomBoxWrapper>
                  <StyledDownloadButtonWrapper>
                    <Button
                      fullWidth
                      label="Télécharger"
                      onPress={() => onClickDownloadDocument(documentData)}
                      loading={isLoading}
                    />
                  </StyledDownloadButtonWrapper>
                  <Typography.BodySmall bold colorName="text-dark-3"></Typography.BodySmall>
                </RightBox>
              </TopBoxWrapper>
            </BoxWrapperProfile>
          </StyledCardWrapper>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <TextInput
            value={
              DocumentEnums.DocumentTypes.find(
                (documentType) => documentType.value === documentData.type,
              )?.label
            }
            label="Type de certificat"
            field="text"
            onChangeText={() => {
              return
            }}
            editable={false}
          />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <Dropdown
            label={'Visibilité'}
            onChange={onChangeDocumentStatus}
            multiple={false}
            defaultValue={documentData.status}
            optionsDefault={DocumentEnums.DocumentStatuses.map((documentStatus) => ({
              ...documentStatus,
            }))}
            itemKey="documentStatus"
          />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <TextInput
            value={FormatUtils.formatDate(documentData.expiration.toString(), 'DateFullYear')}
            label="Date d'expiration"
            field="text"
            onChangeText={() => () => {
              return
            }}
            editable={false}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {errorMessage ? (
            <>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            </>
          ) : null}
          <StyledButtonsWrapper>
            <StyledButtonWrapper>
              <Button
                label="Supprimer"
                onPress={() => onDeleteDocument()}
                colorName="color-danger"
                loading={isLoading}
                hasDoubleValidation
                confirmationLabel="Êtes-vous sûr de vouloir supprimer ce certificat ?"
              />
            </StyledButtonWrapper>
            <StyledButtonWrapper>
              <Button
                label="Modifier"
                onPress={() => onUpdateProducerDocument()}
                loading={isLoading}
                disabled={!selectedDocumentStatus}
                hasDoubleValidation
                confirmationLabel="Êtes-vous sûr de vouloir modifier ce certificat ?"
              />
            </StyledButtonWrapper>
          </StyledButtonsWrapper>
        </ScrollableFormWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerDetailsCertificatesScreen
