import { StyleSheet } from 'react-native'
import { DefaultTheme } from 'styled-components/native'

type Insets = {
  top: number
  right: number
  bottom: number
  left: number
}

const styles = (theme: DefaultTheme, insets: Insets) =>
  StyleSheet.create({
    tabBarStyle: {
      height: 'auto',
      backgroundColor: theme.colors['background-3'],
      paddingTop: theme.spacingUnit * 1.5,
      paddingBottom: insets.bottom || theme.spacingUnit * 1.5,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      // borderLeftWidth: 1,
      // borderRightWidth: 1,
      borderTopWidth: 1,
      borderTopColor: '#f0f0f0',
      borderTopStartRadius: 0,
      borderTopEndRadius: 0,
    },
  })

export default styles
