import { RouteProp, useRoute } from '@react-navigation/native'
import React from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, Spacer, Typography } from '../../../components'
import { Address, Order, Stop, Tour } from '../../../domain'
import BatchCartCard from '../../../modules/Batch/BatchCartCard'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import CarrierTourDeliveryStopTopBox from './components/CarrierTourDeliveryStopTopBox'
import { ToursContentWrapper } from './CarrierTourDeliveryStop.styles'
import StopCarrierService from '../../../services/carrier/stop'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import FormatUtils from '../../../utilities/utils/format'
import { queryClient } from '../../../utilities/queryClient'
import { StyledButtonWrapper } from './CarrierTourDeliveryStop.styles'
import { View } from 'react-native'
import Loader from '../../../components/Loader'
import { UserEnums } from '../../../../enums'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import AddressService from '../../../services/common/address'

const CarrierTourDeliveryStopScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourDeliveryStop'>>()

  const { id, tourId } = route?.params ?? { id: '', tourId: '' }

  const {
    data: stop,
    refetch: refetchStop,
    isInitialLoading: stopLoading,
  } = useQuery<Stop, Error>(['sf_stop', id], () => StopCarrierService.getOneStop(tourId, id), {
    keepPreviousData: true,
    select: FormatUtils.stopRankingFormatter,
  })

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = TourCarrierService.getOne.query(tourId)

  const getOrderIdFormatted = (order?: Order) => {
    if (order && order.id && order.id.length > 5) {
      return order.id.slice(-5)
    }
    return 'NA'
  }

  if (tourLoading || stopLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!stop || !tour) return null

  const onStopDelivered = async () => {
    let tempStop = {
      delivered: new Date(),
    }

    await StopCarrierService.update(tour.id, stop.id, tempStop)
    refetchStop()
    refetchTour()
  }

  const carts = stop.carts
  const renderCarts = () => {
    if (!carts || carts.length < 1) return null
    return carts.map((cart) => {
      return (
        <View key={cart.id}>
          <Typography.SectionTitle>
            Commande #{getOrderIdFormatted(cart.order)}
          </Typography.SectionTitle>
          {cart.cartBatchs?.map((cartBatch) => (
            <BatchCartCard
              key={cartBatch.id}
              cartBatch={cartBatch}
              onClick={() => null}
              withProducer
              userRole={UserEnums.RoleEnums.CARRIER}
            />
          ))}
        </View>
      )
    })
  }
  const handleUpdateAddress = async (addressId: string, addressToUpdate: Partial<Address>) => {
    await AddressService.update(addressId, addressToUpdate)
    queryClient.invalidateQueries(['sf_stop', id])
  }
  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Etape ${stop.formattedRanking}`}
            buttonRight={
              <>
                <Typography.BodySmall>
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                  {'\n'}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </>
            }
          />
          <CarrierTourDeliveryStopTopBox stop={stop} onUpdateAddress={handleUpdateAddress} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <StyledButtonWrapper>
            {stop.delivered ? (
              <Typography.Body colorName="color-primary">
                Arrêt livré le {FormatUtils.formatDate(stop.delivered, 'Date')} à{' '}
                {FormatUtils.formatDate(stop.delivered, 'Time')}
              </Typography.Body>
            ) : (
              <Button label={'Livré'} onPress={() => onStopDelivered()} />
            )}
          </StyledButtonWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          {carts && carts.length > 0 ? (
            renderCarts()
          ) : (
            <Typography.Body colorName="color-danger">
              Aucune commande à livrer à ce {FormatUtils.getLabelFromClientType(1)}
            </Typography.Body>
          )}

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourDeliveryStopScreen
