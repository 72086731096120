import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import Loader from '../../../components/Loader'
import { Pagination, Tour } from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../services/carrier/tour'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { PageTitle, Spacer, Typography } from '../../../components'
import TourCard from '../../../modules/Tour/TourCard'

const CarrierConcludeToursScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: toursToConclude,
    refetch: refetchtoursToConclude,
    isInitialLoading: toursToConcludeLoading,
  } = useQuery<{ data: Tour[]; pagination: Pagination }, Error>(
    ['sf_tours_to_conclude'],
    () =>
      TourCarrierService.getAllTours(getAccessInfos().carrierId, false, true, 50, 1, false, true),
    {
      keepPreviousData: true,
    },
  )

  if (toursToConcludeLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!toursToConclude) return <></>
  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title={`Tournées à clôturer`} />
        {toursToConclude?.data.map((tour) => (
          <TourCard
            key={tour.id}
            tour={tour}
            displayType="tourToConcludeCard"
            isCarrier
            onClick={() =>
              navigation.navigate('CarrierTours', {
                screen: 'CarrierConcludeTour',
                params: { id: tour.id, origin: 'CarrierConcludeTours' },
              })
            }
          />
        ))}
        {toursToConclude?.data.length === 0 && (
          <>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3" style={{ width: '100%' }} align="center">
              Aucune tournée à clôturer
            </Typography.BodySmall>
          </>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierConcludeToursScreen
