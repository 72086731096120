import Constants from 'expo-constants'

function getApiUrl() {
  const API_URL =
    Constants?.manifest?.extra?.eas?.API_URL ??
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.API_URL

  if (!API_URL) {
    throw new Error('API_URL is missing.')
  }

  return API_URL
}

function getDirectusUrl() {
  const DIRECTUS_URL =
    Constants?.manifest?.extra?.eas?.DIRECTUS_URL ??
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.DIRECTUS_URL

  if (!DIRECTUS_URL) {
    throw new Error('DIRECTUS_URL is missing.')
  }

  return DIRECTUS_URL
}

function getStripePublishableKey() {
  const STRIPE_PUBLISHABLE_KEY =
    Constants?.manifest?.extra?.eas?.STRIPE_PUBLISHABLE_KEY ??
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.STRIPE_PUBLISHABLE_KEY

  return STRIPE_PUBLISHABLE_KEY
}

function getCiCommitRef() {
  const CI_COMMIT_REF =
    Constants?.manifest?.extra?.eas?.CI_COMMIT_REF ??
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.CI_COMMIT_REF

  return CI_COMMIT_REF
}

const Env = {
  CI_COMMIT_REF: getCiCommitRef(),
  API_URL: getApiUrl(),
  DIRECTUS_URL: getDirectusUrl(),
  STRIPE_PUBLISHABLE_KEY: getStripePublishableKey(),
}

export default Env
