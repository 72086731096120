import { InvoiceStatusEnum } from '../../../enums/invoice'

export interface Filter {
  key: InvoiceFilterEnum
  active: boolean
  label: string
  options: { label: string; value: any }[]
}

export interface FilterModuleProps {
  filters: Filter[]
  currentlyUpdating: InvoiceFilterEnum | null
  onFilterUpdate: (filterOption: InvoiceFilterEnum, selectedValue?: any) => void
  onFilterDelete: (filterOption: InvoiceFilterEnum) => void
}

export type SelectedValues = {
  [InvoiceFilterEnum.CLIENT]?: string
  [InvoiceFilterEnum.STATUS]?: InvoiceStatusEnum
}

export enum InvoiceFilterEnum {
  CLIENT = 1,
  STATUS = 5,
}
