import { useWindowDimensions } from 'react-native'
import * as Device from 'expo-device'

const isMobileApp = () => {
  const dimensions = useWindowDimensions()

  if (dimensions.width < 768) {
    return true
  }
  return false
}

const isMobileDevice = () => {
  if (Device.isDevice && Device.osInternalBuildId !== null) {
    return true
  }
  return false
}

const DeviceUtil = {
  isMobileApp,
  isMobileDevice,
}

export default DeviceUtil
