import styled from 'styled-components/native'

const MailFormWrapper = styled.View``

const StyledCardWrapper = styled.View`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContentWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const LeftCardContentWrapper = styled.View`
  width: 70%;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  justify-content: center;
  align-items: center;
`

const StyledLinkButtonWrapper = styled.View`
  width: auto;
  justify-content: center;
  align-items: center;
`

const BackToListWrapper = styled.TouchableOpacity`
  width: 100%;
  flex-direction: row;
`

export {
  StyledCardWrapper,
  StyledCardContentWrapper,
  LeftCardContentWrapper,
  StyledButtonWrapper,
  BackToListWrapper,
  MailFormWrapper,
  StyledLinkButtonWrapper,
}
