import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  TextInput as RNTextInput,
  TouchableWithoutFeedback,
  View,
  StyleSheet,
  Platform,
} from 'react-native'

import { useTheme } from 'styled-components/native'
import DeviceUtil from '../../../utilities/utils/device'
import Icon, { Icons } from '../../Icon'
import Spacer from '../../Spacer'
import Typography from '../../Typography'
import StyledInputWrapper from '../inputs.styles'
import InputUtils from '../inputs.utils'
import TextInputIndication from '../TextInputIndication'
import TextInputLabel from '../TextInputLabel'
import { TextInputProps } from './TextInput.model'
import {
  StyledInlineWrapper,
  StyledPrefixWrapper,
  StyledSecureTextEntryIconWrapper,
  StyledSuffixWrapper,
  StyledTextInput,
} from './TextInput.styles'

const styles = StyleSheet.create({
  textInput: {
    flex: 1,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
})

const TextInput = ({
  value,
  defaultValue,
  field,
  onChangeText,
  onBlurCallBack,
  onKeyPressCallBack,
  label,
  keyboardType,
  secureTextEntry: secureTextEntryProp,
  autoCorrect,
  hasError,
  editable,
  autoFocus,
  multiline,
  required,
  prefix,
  suffix,
  placeholder,
  indication,
  hidden,
  small,
  extraMargin,
  boldInputLabel,
}: TextInputProps) => {
  const theme = useTheme()
  const [secureTextEntry, setSecureTextEntry] = useState(secureTextEntryProp)
  const placeholderTextColor = theme.colors['text-dark-3']
  const textInputRef = useRef<RNTextInput>(null)
  const isFocused = textInputRef.current?.isFocused() ?? false
  const isActive = !!(isFocused || value || defaultValue)
  const { backgroundColor, borderColor, onBlur, onFocus } = InputUtils.useInputWrapperTheme({
    hasError,
    disabled: !editable,
  })
  const [isScrollEnabled, setIsScrollEnabled] = useState(false)

  // ?: to fix scrolling issue when keyboard is shown and text input is multinlined
  useEffect(() => {
    if (isFocused) {
      setTimeout(() => {
        setIsScrollEnabled(true)
      }, 500)
    } else {
      setIsScrollEnabled(false)
    }
  }, [isFocused])

  const handleInputFocus = () => {
    textInputRef.current?.focus()
  }

  const toggleSecureTextEntry = () => {
    setSecureTextEntry(!secureTextEntry)
  }

  const autoCapitalize = keyboardType === 'email-address' ? 'none' : 'sentences'
  const accessibilityLabel = `text-input-${field}`

  const isLabelToLong = label.length > 40
  const isMultiline = multiline || isLabelToLong

  const renderTextInputLabel = useCallback(() => {
    if (small && isActive) {
      return null
    }

    return (
      <TextInputLabel
        label={label}
        editable={editable ?? false}
        isActive={isActive}
        required={required}
        multiline={multiline}
        boldInputLabel={boldInputLabel}
      />
    )
  }, [label, editable, isActive, required, isMultiline])

  const renderMultilineLabel = useCallback(() => {
    if (!isMultiline) return null

    return (
      <>
        <Typography.FormItem>
          {label}
          {required ? '*' : ''}
        </Typography.FormItem>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      </>
    )
  }, [isMultiline, label, required])

  const placeHolderValue = useMemo(() => {
    if (isMultiline) return `Description`
    if (isActive) return placeholder

    return ''
  }, [isActive, isMultiline, placeholder])

  if (hidden) return null

  const onBlurAction = () => {
    if (onBlurCallBack) {
      onBlurCallBack()
    }
    onBlur()
  }

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter' && onKeyPressCallBack) {
      onKeyPressCallBack()
    }
  }

  return (
    <TouchableWithoutFeedback onPress={handleInputFocus} testID={`${field}-input-pressable`}>
      <>
        {renderMultilineLabel() && false}
        <StyledInputWrapper
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          multiline={isMultiline}
          small={small}
          extraMargin={extraMargin}
        >
          <StyledInlineWrapper>
            {prefix ? (
              <StyledPrefixWrapper>
                <Typography.Body>{prefix}</Typography.Body>
              </StyledPrefixWrapper>
            ) : null}
            <View style={{ flex: 1, height: theme.inputHeight / (small ? 2 : 1) }}>
              {renderTextInputLabel()}
              <StyledTextInput
                ref={textInputRef as any}
                accessible
                accessibilityLabel={accessibilityLabel}
                testID={accessibilityLabel}
                value={value}
                multiline={isMultiline}
                numberOfLines={3}
                style={{ borderColor, ...styles.textInput }}
                onChangeText={onChangeText}
                onFocus={onFocus}
                onBlur={onBlurAction}
                onKeyPress={onKeyPress}
                placeholderTextColor={placeholderTextColor}
                selectionColor={placeholderTextColor}
                keyboardType={keyboardType ?? 'default'}
                secureTextEntry={secureTextEntry}
                autoCapitalize={autoCapitalize}
                autoCorrect={autoCorrect}
                // defaultValue={defaultValue}
                editable={editable}
                autoFocus={autoFocus}
                keyboardAppearance="light"
                // placeholder={placeHolderValue}
                scrollEnabled={isScrollEnabled}
                small={small}
              />
              {suffix ? (
                <StyledSuffixWrapper small={small} noLabel={!label || label === ''}>
                  <Typography.BodySmall colorName="text-dark-3">{suffix}</Typography.BodySmall>
                </StyledSuffixWrapper>
              ) : null}
            </View>
          </StyledInlineWrapper>
          {secureTextEntryProp ? (
            <StyledSecureTextEntryIconWrapper>
              <TouchableWithoutFeedback onPress={toggleSecureTextEntry}>
                <View>
                  <Icon
                    type={Icons.Ionicons}
                    name={secureTextEntry ? 'ios-eye-outline' : 'ios-eye-off-outline'}
                    size={24}
                    color={theme.colors['text-dark-1']}
                  />
                </View>
              </TouchableWithoutFeedback>
            </StyledSecureTextEntryIconWrapper>
          ) : null}
        </StyledInputWrapper>
        <TextInputIndication content={indication} />
      </>
    </TouchableWithoutFeedback>
  )
}

TextInput.defaultProps = {
  value: null,
  label: '',
  placeholder: '',
  keyboardType: 'default',
  secureTextEntry: false,
  autoCorrect: false,
  defaultValue: '',
  hasError: false,
  editable: true,
  multiline: false,
}

export default TextInput
