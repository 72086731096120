import styled from 'styled-components/native'

const StyledPickerWrapper = styled.View<{ borderColor: string; backgroundColor: string }>`
  position: relative;
  height: ${({ theme }) => theme.inputHeight}px;
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-width: 1px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border-color: ${({ borderColor }) => borderColor};
`

const StyledDateValue = styled.View`
  flex: 1;
  justify-content: center;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 2}px;
  z-index: 1;
`

export { StyledPickerWrapper, StyledDateValue }
