import styled, { css } from 'styled-components/native'

const TourTopBoxWrapper = styled.View<{ isDesktop: boolean }>`
  position: fixed;
  width: 100%;
  align-items: center;
  margin-top: 0;
  z-index: 500;
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      padding-left: 245px;
    `}
`

const StyledTopBox = styled.View`
  flex-direction: row;
  padding-left: ${({ theme }) => theme.spacingUnit * 1.8}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1.8}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1}px;
  z-index: 10;
  max-width: 300px;
`
const StyledLeftBox = styled.View`
  align-items: center;
  justify-content: center;
  flex: 1;
`

const StyledRightBox = styled.View`
  padding-top: ${({ theme }) => theme.spacingUnit * 0.5}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.7}px;
  align-items: flex-end;
  // flex: 1;
  position: absolute;
  top: 0;
  right: 0;
`

const CardWrapper = styled.View`
  min-width: ${({ theme }) => theme.spacingUnit * 35}px;
`
export { TourTopBoxWrapper, StyledTopBox, CardWrapper, StyledLeftBox, StyledRightBox }
