import React from 'react'
import { View } from 'react-native'
import { Card, Typography, Spacer, Button } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledRightBox,
  StyledButtonWrapper,
} from './CarrierFeesCard.styles'
import { CarrierFeesCardProps } from './CarrierFeesCard.model'
import { CarrierStackParamList } from '../../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
const CarrierFeesCard = ({ statsCarrier }: CarrierFeesCardProps) => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  return (
    <StyledCardWrapper
      activeOpacity={0.8}
      onPress={() =>
        navigation.navigate('CarrierBillingProducers', {
          carrierId: statsCarrier.carrierId,
        })
      }
    >
      <Card>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.CardTitle>{statsCarrier.carrierName}</Typography.CardTitle>
            <Typography.BodySmall colorName="color-primary" bold></Typography.BodySmall>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3">
              {FormatUtils.pluralizeAmountLabel('tournée', statsCarrier.nbTours || 0)}
              {` - ${FormatUtils.formatPrice(
                statsCarrier.totalPendingSalesRevenue,
              )}HT transportées`}
            </Typography.BodySmall>
          </StyledLeftBox>
          <StyledRightBox>
            <View style={{ alignItems: 'flex-end' }}>
              <Typography.BodySmall colorName="text-dark-3" style={{ alignSelf: 'flex-end' }}>
                À facturer
              </Typography.BodySmall>
              <Typography.BodySmall bold colorName={'color-primary'}>
                {FormatUtils.formatPrice(statsCarrier.totalPendingCarrierFeesCharged, 'ht')}
              </Typography.BodySmall>
            </View>
            <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />

            <StyledButtonWrapper>
              <Button
                small
                label={'Facturer'}
                onPress={() =>
                  navigation.navigate('CarrierBillingProducers', {
                    carrierId: statsCarrier.carrierId,
                  })
                }
              />
            </StyledButtonWrapper>
          </StyledRightBox>
        </StyledCardContent>
      </Card>
    </StyledCardWrapper>
  )
}

export default CarrierFeesCard
