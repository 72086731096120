import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { GroupRequestCardProps } from './GroupRequestCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
  StyledValueBox,
  StyledValuesWrapper,
} from './GroupRequestCard.styles'
import { GroupEnums } from '../../../../enums/group'

const GroupRequestCard = ({ groupRequest, onChangeStatus }: GroupRequestCardProps) => {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(false)

  if (!groupRequest) return <></>

  const updateGroupRequestStatus = async (status: GroupEnums.GroupRequestStatusEnum) => {
    if (isLoading || !groupRequest.id) return

    setIsLoading(true)
    await onChangeStatus(groupRequest.id, status)
    setIsLoading(false)
  }

  const isStatusGroupRequest = (status: GroupEnums.GroupRequestStatusEnum) => {
    return status === groupRequest.status
  }

  return (
    <StyledCardWrapper>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.Body colorName="text-dark-1" bold>
              {groupRequest.producer?.label}
            </Typography.Body>
            <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-1">
              {groupRequest.producer?.mail} - {groupRequest.producer?.phone1}
            </Typography.BodySmall>
            <Typography.BodySmall colorName="text-dark-1" bold>
              {FormatUtils.formatAddress(groupRequest.producer?.address)}
            </Typography.BodySmall>
          </StyledLeftBox>
          <StyledRightBox>
            {isStatusGroupRequest(GroupEnums.GroupRequestStatusEnum.PENDING) ? (
              <StyledValuesWrapper>
                <Button
                  small
                  lowPadding
                  label={'Rejeter'}
                  colorName="color-grey"
                  onPress={() =>
                    updateGroupRequestStatus(GroupEnums.GroupRequestStatusEnum.REJECTED)
                  }
                  loading={isLoading}
                />
                <Spacer size={0.6} axis={Spacer.AxisEnum.HORIZONTAL} />

                <Button
                  small
                  lowPadding
                  label={'Approuver'}
                  onPress={() =>
                    updateGroupRequestStatus(GroupEnums.GroupRequestStatusEnum.APPROVED)
                  }
                  loading={isLoading}
                />
              </StyledValuesWrapper>
            ) : (
              <StyledValueBox>
                <Icon
                  type={Icons.Ionicons}
                  name={
                    isStatusGroupRequest(GroupEnums.GroupRequestStatusEnum.APPROVED)
                      ? 'checkmark-circle-outline'
                      : 'close-circle-outline'
                  }
                  color={theme.colors['color-primary']}
                />
                <Typography.BodySmall colorName="text-dark-3" semiBold>
                  {isStatusGroupRequest(GroupEnums.GroupRequestStatusEnum.APPROVED)
                    ? 'Approuvé'
                    : 'Rejeté'}
                </Typography.BodySmall>
              </StyledValueBox>
            )}
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default GroupRequestCard
