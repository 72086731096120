import React from 'react'
import { View } from 'react-native'
import { Card, Typography, Spacer } from '..'
import FormatUtils from '../../utilities/utils/format'
import {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledRightBox,
} from './CarrierFeesCard.styles'
import { CarrierFeesCardProps } from './CarrierFeesCard.model'

const CarrierFeesCard = ({ carrierFeesRecord }: CarrierFeesCardProps) => {
  return (
    <StyledCardWrapper activeOpacity={0.8}>
      <Card>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.CardTitle>{`Tournée du ${FormatUtils.formatDate(
              carrierFeesRecord?.tour.start,
              'Date',
            )}`}</Typography.CardTitle>
            <Typography.BodySmall colorName="color-primary" bold></Typography.BodySmall>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3">
              {`${FormatUtils.formatPrice(
                carrierFeesRecord?.salesRevenue,
              )}HT de marchandises transportées`}
            </Typography.BodySmall>
          </StyledLeftBox>
          <StyledRightBox>
            <View style={{ alignItems: 'flex-end' }}>
              <Typography.BodySmall colorName="text-dark-3" style={{ alignSelf: 'flex-end' }}>
                Com. logistique
              </Typography.BodySmall>
              <Typography.BodySmall bold colorName="color-primary">
                {FormatUtils.formatPrice(carrierFeesRecord?.carrierFeesCharged)}
              </Typography.BodySmall>
            </View>
          </StyledRightBox>
        </StyledCardContent>
      </Card>
    </StyledCardWrapper>
  )
}

export default CarrierFeesCard
