import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, PageTitle, Spacer, Typography } from '../../../components'
import CardList from '../../../components/CardList'
import Loader from '../../../components/Loader'
import { Pagination, Tour, ToursFake } from '../../../domain'
import i18n from '../../../i18n'
import TourCard from '../../../modules/Tour/TourCard'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import TourProducerService from '../../../services/producer/tour'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { CaptionWrapper } from './ProducerTours.styles'
import CardListPaginated from '../../../components/CardListPaginated'

const ProducerToursScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()
  const [isLoading, setIsLoading] = useState(false)
  const [pageSizePreviousTours, setPageSizePreviousTours] = useState(4)
  const [pageNumberPreviousTours, setPageNumberPreviousTours] = useState(1)

  const {
    data: nextTours,
    refetch: refetchNextTours,
    isInitialLoading: nextToursLoading,
  } = useQuery<{ data: Tour[]; pagination: Pagination }, Error>(
    ['p_next_tours'],
    () =>
      TourProducerService.getAllTours(
        getAccessInfos().producerId,
        new Date(),
        undefined,
        false,
        50,
      ),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: availableTours,
    refetch: refetchAvailableTours,
    isInitialLoading: availableToursLoading,
  } = useQuery<{ data: Tour[]; pagination: Pagination }, Error>(
    ['p_available_tours'],
    () =>
      TourProducerService.getAllTours(getAccessInfos().producerId, new Date(), undefined, true, 50),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: previousTours,
    refetch: refetchPreviousTours,
    isInitialLoading: previousToursLoading,
  } = useQuery<{ data: Tour[]; pagination: Pagination }, Error>(
    ['p_previous_tours'],
    () =>
      TourProducerService.getAllTours(
        getAccessInfos().producerId,
        undefined,
        new Date(),
        false,
        pageSizePreviousTours,
        pageNumberPreviousTours,
      ),
    {
      keepPreviousData: true,
    },
  )

  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const OnClickTour = (tour: Tour) => {
    navigation.navigate('ProducerTour', { id: tour.id })
  }

  const OnClickAvailableTour = async (tour: Tour) => {
    if (isLoading) return
    setIsLoading(true)
    await TourProducerService.join(getAccessInfos().producerId, tour.id)
    refetchAvailableTours()
    refetchNextTours()
    setIsLoading(false)
    queryClient.invalidateQueries(['p_producer', getAccessInfos().producerId])
    navigation.navigate('ProducerTour', { id: tour.id })
  }

  const onChangePagePreviousTours = async (pageNumber: number) => {
    await setPageNumberPreviousTours(pageNumber)
    refetchPreviousTours()
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper withoutScroll>
        <PageTitle title={FormatUtils.capitalize(i18n.t('pageTitleToursProducer'))} />
        <Typography.SectionTitle>
          {FormatUtils.capitalize(i18n.t('participantTours'))} ({nextTours?.data?.length || 0})
        </Typography.SectionTitle>
        <CardList
          data={nextTours?.data}
          element={function (tour: any) {
            return <TourCard key={tour.id} tour={tour} onClick={OnClickTour} />
          }}
          isLoading={nextToursLoading}
          emptyMessage="Aucune tournée à venir"
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <Typography.SectionTitle>
          {FormatUtils.capitalize(i18n.t('joinableTours'))} ({availableTours?.data?.length || 0})
        </Typography.SectionTitle>
        <CaptionWrapper>
          <Typography.BodySmall colorName="text-dark-3">
            Cliquez sur une tournée pour la rejoindre
          </Typography.BodySmall>
        </CaptionWrapper>
        <CardList
          data={availableTours?.data}
          element={function (tour: any) {
            return (
              <TourCard
                key={tour.id}
                tour={tour}
                onClick={OnClickAvailableTour}
                rightIcon={
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {isLoading ? (
                      <Loader isLoading noMargin size={2} flex />
                    ) : (
                      <Typography.BodySmall colorName="color-primary" bold>
                        Rejoindre
                      </Typography.BodySmall>
                    )}

                    <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.5} />

                    <Icon
                      type={Icons.Ionicons}
                      name="chevron-forward-outline"
                      size={theme.spacingUnit * 2}
                      color={theme.colors['text-dark-3']}
                    />
                  </View>
                }
              />
            )
          }}
          isLoading={availableToursLoading}
          emptyMessage="Aucune tournée à rejoindre"
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <Typography.SectionTitle>
          {FormatUtils.capitalize(i18n.t('finishedTours'))} (
          {previousTours?.pagination?.totalCount || 0})
        </Typography.SectionTitle>
        <CardListPaginated
          data={previousTours?.data}
          pagination={previousTours?.pagination}
          element={function (tour: any) {
            return <TourCard key={tour.id} tour={tour} onClick={OnClickTour} isCarrier />
          }}
          isLoading={previousToursLoading}
          emptyMessage="Aucune tournée passée"
          onChangePage={onChangePagePreviousTours}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerToursScreen
