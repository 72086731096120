import { useState } from 'react'
import { Spacer, PageTitle } from '../../../components'
import { Address, Producer, UpdateProducer } from '../../../domain'
import AddressForm from '../../../modules/Address/AddressForm'
import { ScreenSafeAreaWrapper, ContentWrapper } from '../../../utilities/styling/wrappers'
import { useQuery } from '@tanstack/react-query'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import ProducerProducerService from '../../../services/producer/producer'

const ProducerAddAddressScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: producerData,
    refetch: refetchProducer,
    isInitialLoading: producerLoading,
  } = useQuery<{ producer: Producer; stepsData: TutorialStepData[] }, Error>(
    ['p_producer', getAccessInfos().producerId],
    () => ProducerProducerService.getOne(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )
  const [updatedProducer, setUpdatedProducer] = useState<UpdateProducer>()

  const onChangeAddress = (newAddress: Address) => {
    if (newAddress && newAddress.id) {
      const producerTemp = Object.assign({}, updatedProducer)

      producerTemp['address'] = newAddress
      setUpdatedProducer(producerTemp)
    }
  }

  return (
    <ScreenSafeAreaWrapper>
      <ContentWrapper noPaddingLeft>
        <PageTitle title="Renseigner mon adresse" />
        <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
        <AddressForm
          onChangeAddress={onChangeAddress}
          address={updatedProducer?.address}
          producer={producerData?.producer}
          autoDisplayForm={true}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerAddAddressScreen
