import styled from 'styled-components/native'

const CardContent = styled.View`
  padding: ${({ theme }) => theme.spacingUnit * 1}px;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacingUnit * 1.5}px;
  justify-content: space-between;
`

const CardLeftContent = styled.View`
  gap: ${({ theme }) => theme.spacingUnit * 0.4}px;
`

const CardRightContent = styled.View``

export { CardContent, CardLeftContent, CardRightContent }
