import React, { useMemo } from 'react'
import { Typography } from '../../../../../components'
import { UserAvatarProps } from '../../UserAvatar.model'

const UserInitialsAvatar = ({ user, large }: UserAvatarProps) => {
  const userInitials = useMemo(() => {
    if (!user || !user.name) return ''
    return user?.name
      .split(' ')
      .map((word) => word[0])
      .join('')
  }, [user?.name])

  const TextComponent = large ? Typography.Title : Typography.PageTitle

  return (
    <TextComponent numberOfLines={1} style={{ textTransform: 'uppercase' }}>
      {userInitials || 'FA'}
    </TextComponent>
  )
}

export default UserInitialsAvatar
