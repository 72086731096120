import { Group, GroupRequest, GroupResponse } from '../../../domain'
import ApiService from '../../api.service'

const getAllGroupsByProducerId = async (idProducer: string): Promise<Group[]> => {
  const response = await ApiService.get(`/group/producer/${idProducer}`).catch((error) => {
    throw error
  })
  return response
}

const getAllGroupsMatchingByProducerId = async (idProducer: string): Promise<Group[]> => {
  const response = await ApiService.get(`/group/producer/${idProducer}/matching`).catch((error) => {
    throw error
  })
  return response
}

const findOneByProducerId = async (idGroup: string, idProducer: string): Promise<GroupResponse> => {
  const response = await ApiService.get(`/group/${idGroup}/profile/producer/${idProducer}`).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const sendGroupRequest = async (
  idGroup: string,
  idProducer: string,
  request: any,
): Promise<GroupRequest> => {
  const response = await ApiService.post(
    `/group-request/${idGroup}/producer/${idProducer}/send-group-request`,
    request,
  ).catch((error) => {
    throw error
  })
  return response
}

const GroupProducerService = {
  getAllGroupsByProducerId,
  getAllGroupsMatchingByProducerId,
  findOneByProducerId,
  sendGroupRequest,
}

export default GroupProducerService
