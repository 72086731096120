import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import Typography from '../../../components/Typography'
import { LabelFormProps } from './LabelForm.model'
import { StyledHistoryWrapper } from './LabelForm.styles'
import { Button, Col, Dropdown, Icon, Icons, Row, SectionTitle, Spacer } from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { View } from 'react-native'
import { LABELS_TYPES } from '../../../../enums/label'
import { TouchableHighlight } from 'react-native-gesture-handler'

function LabelForm({ title, type, labels, validLabels, onChangeLabel }: LabelFormProps) {
  const theme = useTheme()
  const [addMode, setAddMode] = useState(false)

  const onChangeLabelSelect = (labelType: any) => {
    const tempLabels = labels || []
    tempLabels.push(labelType.value)
    onChangeLabel(tempLabels)
    setAddMode(false)
  }

  const removeLabel = (label: string) => {
    const tempLabels = labels || []
    const foundLabelIndex = tempLabels?.findIndex((l) => l === label)
    if (foundLabelIndex > -1) {
      tempLabels.splice(foundLabelIndex, 1)
    }
    onChangeLabel(tempLabels)
    setAddMode(false)
  }

  return (
    <StyledHistoryWrapper>
      <SectionTitle
        title={
          title
            ? title
            : type === 1
            ? `Labels du ${FormatUtils.getLabelFromClientType(1)}`
            : 'Labels du producteur'
        }
        buttonRight={
          <Button small label={addMode ? 'Annuler' : 'Ajouter'} onPress={() => setAddMode(true)} />
        }
      />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
      <Typography.BodyExtraSmall colorName="color-grey">
        Le label Biocoop permet aux clients labélisés Biocoop d'identifier les producteurs
        référencés par l'enseigne sur le catalogue
      </Typography.BodyExtraSmall>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
      {labels?.map((label) => {
        return (
          <>
            <Row>
              <Col xs={8}>
                <Typography.Body>
                  - {LABELS_TYPES.find((labelType) => labelType.value === label)?.label}
                </Typography.Body>
              </Col>
              <Col xs={4}>
                <TouchableHighlight
                  onPress={() => removeLabel(label)}
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Icon
                      type={Icons.Ionicons}
                      name="trash"
                      size={theme.spacingUnit * 2}
                      color={theme.colors['text-dark-3']}
                    />
                  </View>
                </TouchableHighlight>
              </Col>
            </Row>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          </>
        )
      })}

      {addMode && (
        <>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Dropdown
            label={'Nouveau label'}
            onChange={onChangeLabelSelect}
            multiple={false}
            optionsDefault={LABELS_TYPES.filter(
              (lt) => !validLabels || validLabels.find((vl) => vl === lt.value),
            ).map((labelType) => ({
              ...labelType,
            }))}
            zIndex={200}
            itemKey="labelType"
            dropDownDirection="TOP"
          />
          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
        </>
      )}
    </StyledHistoryWrapper>
  )
}

export default LabelForm
