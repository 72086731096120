import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { Fragment, useEffect, useState } from 'react'
import { Alert } from 'react-native'
import { Button, Col, Row, SectionTitle, Spacer, Typography } from '../../../components'
import Loader from '../../../components/Loader'
import {
  Cart,
  CartBatch,
  CartValidationFailure,
  CartValidationRuleName,
  ProducerWithProducerTourData,
} from '../../../domain'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import BatchClientService from '../../../services/client/batch'
import CartClientService from '../../../services/client/cart'
import OrderClientService from '../../../services/client/order'
import { queryClient } from '../../../utilities/queryClient'
import StorageUtil from '../../../utilities/storage/storage'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import ClientCartBatchs from './components/ClientCartBatchs'
import ClientCartSummary from './components/ClientCartSummary'
import FormatUtils from '../../../utilities/utils/format'
import { SubTotalWrapper } from './ClientCart.styles'
import CartUtil from '../../../utilities/utils/cart'
import CarrierProducerTourService, {
  ProducerTourExtended,
} from '../../../services/carrier/producerTour'
import { CartCreatorTypeEnum } from '../../../../enums'
import { TextTag } from '../../../components/Text/Text.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'

export interface ProducerAndCartBatch {
  producer: ProducerWithProducerTourData
  cartBatchs: CartBatch[]
}

function formatProducerAndCartBatch(cart: Cart, producersTourData: ProducerTourExtended[]) {
  const cartBatchByProducer: Record<string, ProducerAndCartBatch> = {}

  const sortedCartBatch =
    cart.cartBatchs?.sort((a, b) =>
      a.batch!.product!.producer!.label.localeCompare(b.batch!.product!.producer!.label),
    ) ?? []

  sortedCartBatch.reduce((acc, cartBatch) => {
    if (!acc[cartBatch.batch!.product!.producer!.id]) {
      const producer = cartBatch.batch!.product!.producer! as ProducerWithProducerTourData
      producer.producerTourData = producersTourData.find(
        (pt) => pt.producerId === producer.id,
      ) as ProducerTourExtended

      acc[producer.id] = {
        producer,
        cartBatchs: [],
      }
    }
    acc[cartBatch.batch!.product!.producer!.id].cartBatchs.push(cartBatch)
    return acc
  }, cartBatchByProducer)

  return cartBatchByProducer
}

const ClientCartScreen = () => {
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const [cartId, setCartId] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [cartValidationErrors, setCartValidationErrors] = useState<CartValidationFailure[]>([])

  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: cart,
    refetch: refetchCart,
    isInitialLoading: cartLoading,
  } = useQuery<Cart | null, Error>(['c_cart', cartId], () => CartClientService.getOneCart(cartId), {
    keepPreviousData: true,
    enabled: cartId !== undefined,
  })

  const { data: catalog } = BatchClientService.catalogExtended.get(
    { tourId: cart?.tour?.id!, cartId: cart?.id! },
    cart?.id !== undefined,
  )

  const {
    data: cartValidationResults,
    refetch: refetchCartValidationResults,
    isInitialLoading: cartValidationResultsLoading,
  } = useQuery<CartValidationFailure[] | boolean, Error>(
    ['c_cart_validate', cartId],
    () => CartClientService.validateCart(cartId, CartValidationRuleName.MinOrder),
    {
      enabled: cartId !== undefined,
    },
  )

  const { data: producersTourData } = CarrierProducerTourService.useGetFromTour({
    tourId: cart?.tour?.id!,
  }).query()

  useEffect(() => {
    const getStoredCart = async () => {
      const storedCartId = await StorageUtil.getItem('c_cart_id')

      if (storedCartId) {
        await setCartId(JSON.parse(storedCartId))
        refetchCart()
        refetchCartValidationResults()
      } else {
        navigation.navigate('ClientCatalog', {})
      }
    }
    getStoredCart()
  }, [])

  useEffect(() => {
    refetchCartValidationResults()
  }, [cart])

  useEffect(() => {
    const results = cartValidationResults as CartValidationFailure[]
    if (results?.length > 0) {
      setCartValidationErrors(results)
    } else {
      setCartValidationErrors([])
    }
  }, [cartValidationResults])

  const onValidateOrder = async () => {
    if (!cart || !cart.id) return
    setIsLoading(true)
    const isUpdatedOrder = cart.targetCart
    let order = null
    if (isUpdatedOrder) {
      order = await OrderClientService.update({ cartId: cart.id })
    } else {
      order = await OrderClientService.create({ cartId: cart.id })
    }
    if (order && order.id) {
      StorageUtil.deleteItem('c_cart_id')
      queryClient.setQueryData(['c_cart', cart.id], null)
      queryClient.setQueryData(['c_catalog'], undefined)
      navigation.navigate('ClientOrders', {
        screen: 'ClientOrder',
        params: isUpdatedOrder
          ? { id: order.id, isUpdatedOrder: true }
          : { id: order.id, isNewOrder: true },
      })
      setIsLoading(false)
      return
    }
    Alert.alert('Erreur lors de la création de la commande')

    setIsLoading(false)
  }

  if (cartLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!cart || !catalog || !cart.cartBatchs || !producersTourData) return null

  const cartBatchByProducer = formatProducerAndCartBatch(cart, producersTourData)

  const getOrderConditions = () => {
    let minOrderConditions = 'Aucun minimum de commande'
    let deliveryFeeConditions = 'pas de frais de livraison'
    let freeDeliveryonditions = 'aucun Franco'

    const tour = cart?.tour

    if (tour?.minOrderValueHT && tour?.minOrderValueHT > 0) {
      minOrderConditions = `Minimum de commande de ${FormatUtils.formatPrice(
        tour?.minOrderValueHT,
      )}`
    }

    if (tour?.deliveryFeesHT && tour?.deliveryFeesHT > 0) {
      deliveryFeeConditions = `frais de livraison de ${FormatUtils.formatPrice(
        tour?.deliveryFeesHT,
      )}`
    }

    if (tour?.freeDeliveryOrderValueHT && tour?.freeDeliveryOrderValueHT > 0) {
      freeDeliveryonditions = `franco de ${FormatUtils.formatPrice(tour?.freeDeliveryOrderValueHT)}`
    }

    return `${minOrderConditions}, ${deliveryFeeConditions}, ${freeDeliveryonditions}`
  }

  const onChangeCreatorType = async () => {
    await CartClientService.updateCartAdmin(cart.id, {
      creatorType:
        cart.creatorType === CartCreatorTypeEnum.CARRIER
          ? CartCreatorTypeEnum.CLIENT
          : CartCreatorTypeEnum.CARRIER,
    })

    refetchCart()
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper fullWidth>
        <SectionTitle
          title={`Finalisez votre${cart?.targetCart ? ' modification de' : ''} commande`}
        />
        <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.BodySmall colorName="color-danger">
          Cette commande n'est pas encore finalisée. Pour la terminer, cliquez sur "valider la
          commande".
        </Typography.BodySmall>
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {cart?.targetCart && (
          <>
            <Typography.Body>
              Ceci est une modification de la commande #{FormatUtils.formatId(cart.order?.id)}
            </Typography.Body>
            <Typography.BodySmall colorName="text-dark-3">
              Validée initialement le{' '}
              {FormatUtils.capitalize(
                FormatUtils.formatDate(cart.order?.createdDate, 'FullDate+StartTime'),
              )}
            </Typography.BodySmall>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          </>
        )}
        <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.BodySmall colorName="text-dark-3">{getOrderConditions()}</Typography.BodySmall>
        <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />

        <Row>
          <Col xs={12} sm={12} md={12} lg={7}>
            {Object.values(cartBatchByProducer).map(({ producer, cartBatchs }) => {
              return (
                <Fragment key={producer.id}>
                  <ClientCartBatchs
                    producer={producer}
                    catalog={catalog}
                    cartBatchs={cartBatchs}
                    cart={cart}
                  />
                  <SubTotalWrapper>
                    <Typography.BodyExtraSmall bold colorName="text-dark-3">
                      Sous-total:{' '}
                      {FormatUtils.formatPrice(CartUtil.calculateSubtotal(cartBatchs), 'ht')}
                    </Typography.BodyExtraSmall>
                  </SubTotalWrapper>
                </Fragment>
              )
            })}
          </Col>
          <Col xs={12} sm={12} md={12} lg={5}>
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <ClientCartSummary
              cart={cart}
              loading={isLoading}
              onValidateOrder={onValidateOrder}
              cartErrors={cartValidationErrors}
            />
          </Col>
        </Row>

        <Spacer size={5} axis={Spacer.AxisEnum.VERTICAL} />
        {getAccessInfos().clientIsCarrier && (
          <>
            <Typography.BodyExtraSmall bold colorName="text-dark-3" align="center">
              {cart.creatorType === CartCreatorTypeEnum.CARRIER
                ? 'Ce panier est privée, il ne sera pas accessible par le client'
                : 'Ce panier est publique et visible par les clients'}
            </Typography.BodyExtraSmall>
            <Button.Text
              label={
                cart.creatorType === CartCreatorTypeEnum.CARRIER
                  ? `Rendre visible au client`
                  : 'Cacher le panier'
              }
              onPress={() => onChangeCreatorType()}
              colorName="text-dark-3"
              small
              textTag={TextTag.BODY_SMALL}
            />
          </>
        )}
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientCartScreen
