import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import useAuthenticationContext from '../../hook/useAuthenticationContext'
import { GroupContextData } from './groupContext.model'
import { Group } from '../../../domain'
import GroupCarrierService from '../../../services/carrier/group'

const GroupContext = createContext<GroupContextData>({} as GroupContextData)

const GroupProvider: React.FC<any> = ({ children }) => {
  useState<boolean>(false)
  const { authenticationData } = useAuthenticationContext()

  const [group, setGroup] = useState<Partial<Group>>()

  useEffect(() => {
    if (authenticationData?.access?.carrierGroupId && authenticationData?.access?.carrierId) {
      getCurrentGroup(
        authenticationData?.access?.carrierGroupId,
        authenticationData?.access?.carrierId,
      ).then((response) => {
        setGroup(response.group)
      })
    }
  }, [authenticationData])

  const getCurrentGroup = useCallback(async (groupId: string, carrierId: string) => {
    const response = await GroupCarrierService.getOneGroup(groupId, carrierId)
    return response
  }, [])

  const updateGroup = useCallback(async () => {
    if (authenticationData?.access?.carrierGroupId && authenticationData?.access?.carrierId) {
      getCurrentGroup(
        authenticationData?.access?.carrierGroupId,
        authenticationData?.access?.carrierId,
      ).then((response) => {
        setGroup(response.group)
      })
    }
  }, [authenticationData, getCurrentGroup])

  const value: GroupContextData = useMemo(
    () => ({
      group,
      getCurrentGroup,
      updateGroup,
    }),
    [group, getCurrentGroup, updateGroup],
  )

  return <GroupContext.Provider value={value}>{children}</GroupContext.Provider>
}

export { GroupProvider, GroupContext, GroupContextData }
