import React from 'react'
import { FlatList } from 'react-native'
import styled from 'styled-components/native'
import { UserLoginSecurityItem } from './UserLoginSecurity.model'

const StyledFlatList = styled(({ children, ...props }) => (
  <FlatList<UserLoginSecurityItem> {...props}>{children}</FlatList>
))`
  flex: 1;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacingUnit * 2}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledElementsWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacingUnit * 2}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledLeftView = styled.View`
  flex: 1;
  justify-content: center;
`

const StyledRightView = styled.View`
  justify-content: center;
  align-items: center;
`

export { StyledFlatList, StyledElementsWrapper, StyledLeftView, StyledRightView }
