import styled from 'styled-components/native'

const SLIDER_HEIGHT = 34
const StyledDateSwitcherWrapper = styled.View`
  /* margin-bottom: ${({ theme }) => theme.spacingUnit * 2}px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors['background-3']};
  padding: ${({ theme }) => theme.spacingUnit * 0.7}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1}px;

  border-radius: 8px;
  */

  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const LeftWhitespaceWrapper = styled.View`
  flex: 1;
`

const RightWhitespaceWrapper = styled.View`
  flex: 1;
  align-items: flex-end;
`
const ButtonWrapper = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 1}px;
  max-width: 50px;
`
const ArrowBoxWrapperLeft = styled.View`
  width: 40px;
  text-align: right;
`

const ArrowBoxWrapperRight = styled.View`
  width: 40px;
  align-items: left;
`

const MiddleBox = styled.View`
  min-width: 160px;
`

export {
  StyledDateSwitcherWrapper,
  ArrowBoxWrapperLeft,
  ArrowBoxWrapperRight,
  MiddleBox,
  LeftWhitespaceWrapper,
  RightWhitespaceWrapper,
  ButtonWrapper,
}
