import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Col,
  Dropdown,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../../components'
import DatePicker from '../../../../components/DatePicker'
import TextInput from '../../../../components/inputs/TextInput'
import { AddTour, CarrierGroup, City, Group, Pagination } from '../../../../domain'
import i18n from '../../../../i18n'
import { CarrierStackParamList } from '../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../../services/carrier/tour'
import useAuthenticationContext from '../../../../utilities/hook/useAuthenticationContext'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../../utilities/styling/wrappers'
import FormatUtils from '../../../../utilities/utils/format'
import {
  ToursContentWrapper,
  StyledButtonWrapper,
  StyledModeSwitchWrapper,
} from './CarrierAddTour.styles'
import { queryClient } from '../../../../utilities/queryClient'
import GeneralUtils from '../../../../utilities/utils/general'
import { useQuery } from '@tanstack/react-query'
import CityProducerService from '../../../../services/producer/city'
import { Switch } from 'react-native-gesture-handler'
import GroupCarrierService from '../../../../services/carrier/group'
import { GroupEnums } from '../../../../../enums/group'
import { TutorialStepData } from '../../../../../enums/tutorialStep'
import { CarrierFeesEnums } from '../../../../../enums/carrierFees'
import EnumLabelUtil from '../../../../utilities/utils/enumLabel'
import CityForm from '../../../../modules/City/CityForm'
import { View } from 'react-native'
import useGroupContext from '../../../../utilities/hook/useGroupContext'

const CarrierAddNewTourScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()
  const { group } = useGroupContext()

  const [isLoading, setIsLoading] = useState(false)
  const [selectedCity, setSelectedCity] = useState<any>()
  const [selectedDeliveryProducer, setSelectedDeliveryProducer] = useState<any>()
  const [selectedCarrierFeesSharingRule, setSelectedCarrierFeesSharingRule] = useState<any>()
  const [showDateDepositErrorMessage, setShowDateDepositErrorMessage] = useState(false)

  const {
    data: cities,
    refetch: refetchCities,
    isInitialLoading: citiesLoading,
  } = useQuery<City[], Error>(['cities'], () => CityProducerService.getAll(), {
    keepPreviousData: true,
  })

  const {
    data: carrierGroups,
    refetch: refetchCarrierGroups,
    isLoading: isCarrierGroupsLoading,
  } = useQuery<{ data: CarrierGroup[]; pagination: Pagination }, Error>(
    ['sf_group_carriers', 500, 1],
    () =>
      GroupCarrierService.getOneGroupCarriers(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        500,
        1,
      ),
    {
      keepPreviousData: true,
      staleTime: 600000, // 10min
    },
  )

  useEffect(() => {
    if (carrierGroups?.data.length === 1 && !selectedDeliveryProducer) {
      setSelectedDeliveryProducer({
        label: carrierGroups.data[0]?.carrier?.name,
        value: carrierGroups.data[0]?.carrier?.id,
      })
    }
  }, [carrierGroups])

  const defaultDateHour = new Date()
  defaultDateHour.setUTCHours(-2)
  defaultDateHour.setUTCMinutes(0)

  const theme = useTheme()
  const [newTour, setNewTour] = useState<AddTour>({
    target: '',
    start: defaultDateHour,
    end: defaultDateHour,
    deposit: defaultDateHour,
    open: defaultDateHour,
    close: defaultDateHour,
    capacity: '',
    carrierFees: '20',
    carrierExpenses: '0',
    palletQuantity: '1',
    defaultDateHour: defaultDateHour,
    cityId: undefined,
    carrierId: undefined,
  })
  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newTour)) return null

    const tourTemp = Object.assign({}, newTour)

    tourTemp[param as keyof typeof newTour] = value
    setNewTour(tourTemp)
  }

  const onChangeDate = (value: any) => {
    setErrorMessage('')

    const tourTemp = Object.assign({}, newTour)
    tourTemp.start = value
    tourTemp.end = value

    if (GeneralUtils.isDateBeforeCurrent(value)) {
      setErrorMessage('La date de la tournée doit être supérieure à la date actuelle.')
      return
    }

    setNewTour(tourTemp)
  }

  const onChangeTimeStart = (value: any) => {
    setValue(value, 'start')
  }

  const onChangeTimeEnd = (value: any) => {
    setValue(value, 'end')
  }

  const onChangeDateDeposit = (value: any) => {
    setValue(value, 'deposit')

    if (GeneralUtils.isDateBeforeCurrent(value)) {
      setErrorMessage('La date de dépôt de la marchandise doit être supérieure à la date actuelle.')
      return
    }

    const diffHours = newTour.start ? GeneralUtils.diffHours(new Date(value), newTour.start) : 0
    setShowDateDepositErrorMessage(diffHours > 24)
  }

  const onChangeDateClose = (value: any) => {
    setValue(value, 'close')

    if (GeneralUtils.isDateBeforeCurrent(value)) {
      setErrorMessage('La date de fermeture des commandes doit être supérieure à la date actuelle.')
      return
    }
  }

  const onChangeDateOpen = (value: any) => {
    setValue(value, 'open')

    if (GeneralUtils.isDateBeforeCurrent(value)) {
      setErrorMessage("La date d'ouverture des commandes doit être supérieure à la date actuelle.")
      return
    }
  }

  const onChangeIsPrivate = () => {
    if (newTour?.isPrivate) {
      setValue(false, 'isPrivate')
      return
    }
    setValue(true, 'isPrivate')
  }

  const onChangeCity = (value: any) => {
    setSelectedCity(value)
  }

  const onChangeDeliveryProducer = (value: any) => {
    setSelectedDeliveryProducer(value)
  }

  const onChangeCarrierFeesSharingRule = (value: any) => {
    setSelectedCarrierFeesSharingRule(value)
  }

  const currentCarrierFeesSharingRuleDescription = () => {
    const rule = CarrierFeesEnums.CARRIER_FEES_RULES.find(
      (cfr: any) => cfr.value === selectedCarrierFeesSharingRule?.value,
    )
    if (rule && rule.description) {
      return rule.description
    }
    return ''
  }

  const onAddTour = async () => {
    setErrorMessage('')
    if (GeneralUtils.isDateBefore(new Date(newTour.end || ''), new Date(newTour.start || ''), -1)) {
      setErrorMessage("L'heure de début de la tournée doit être avant celle de fin")
      return false
    }
    if (
      GeneralUtils.isDateBefore(new Date(newTour.start || ''), new Date(newTour.deposit || ''), -1)
    ) {
      setErrorMessage('La date de dépôt de la marchandise doit être avant celle de livraison')
      return false
    }
    if (
      GeneralUtils.isDateBefore(new Date(newTour.deposit || ''), new Date(newTour.close || ''), -1)
    ) {
      setErrorMessage(
        'La date de fermeture des commandes doit être avant la date de dépôt de la marchandise',
      )
      return false
    }
    if (
      GeneralUtils.isDateBefore(new Date(newTour.close || ''), new Date(newTour.open || ''), -1)
    ) {
      setErrorMessage("La date d'ouverture des commandes doit être avant celle de fermeture")
      return false
    }

    if (!selectedDeliveryProducer) {
      setErrorMessage('Veuillez sélectionner un producteur-livreur')
      return false
    }

    setIsLoading(true)
    const tour = {
      start: newTour.start,
      end: newTour.end,
      deposit: newTour.deposit,
      open: newTour.open,
      close: newTour.close,
      isPrivate: newTour.isPrivate,
      cityId: selectedCity.id,
      target: newTour.target,
      carrierId: selectedDeliveryProducer?.value,
    }

    await TourCarrierService.create(getAccessInfos().carrierId, tour)
      .then((createdTour) => {
        queryClient.fetchQuery(['sf_next_tours'])
        navigation.popToTop()
        navigation.navigate('CarrierTour', { id: createdTour.id })
        setIsLoading(false)
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la création de la tournée')
        setIsLoading(false)
      })
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Créer une tournée" />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper paddingBottom="100px">
            {group?.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS && (
              <View style={{ zIndex: 4000 }}>
                <Dropdown
                  label={'Producteur-livreur'}
                  onChange={onChangeDeliveryProducer}
                  multiple={false}
                  defaultValue={selectedDeliveryProducer?.value}
                  optionsDefault={
                    !carrierGroups?.data
                      ? []
                      : carrierGroups.data.map((cg) => ({
                          ...cg,
                          label: cg?.carrier?.name,
                          value: cg?.carrier?.id,
                        }))
                  }
                  zIndex={1000}
                  zIndexInverse={4000}
                  itemKey="city"
                />
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              </View>
            )}
            <CityForm
              cities={cities || []}
              onChangeCity={onChangeCity}
              selectedCity={selectedCity}
            />
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newTour.target}
              label="Etapes (autres villes)"
              field="text"
              onChangeText={(text) => setValue(text, 'target')}
              autoFocus
              hidden={!selectedCity}
            />
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            {selectedCity && <SectionTitle title={`Livraison`} />}
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <DatePicker
                  id="tourstartdate"
                  label="Date de la tournée"
                  mode="date"
                  onChange={onChangeDate}
                  value={newTour.start}
                  hidden={!selectedCity}
                  withDay
                />
              </Col>
              <Col xs={6} sm={6} md={3}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <DatePicker
                  id="heuredebut"
                  label="Début"
                  onChange={onChangeTimeStart}
                  value={newTour.start}
                  mode={'time'}
                  outputFormat="date"
                  hidden={!selectedCity}
                />
              </Col>
              <Col xs={6} sm={6} md={3}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <DatePicker
                  id="heurefin"
                  label="Fin"
                  onChange={onChangeTimeEnd}
                  value={newTour.end}
                  mode={'time'}
                  outputFormat="date"
                  hidden={!selectedCity}
                />
              </Col>
            </Row>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            {newTour.end !== newTour.defaultDateHour && (
              <SectionTitle title={`Limite de dépot de la marchandise`} />
            )}
            <Row>
              <Col xs={8}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <DatePicker
                  id="datedepot"
                  label="Limite de dépôt"
                  onChange={onChangeDateDeposit}
                  value={newTour.deposit}
                  mode={'date'}
                  outputFormat="date"
                  hidden={newTour.end === newTour.defaultDateHour}
                  withDay
                />
              </Col>
              <Col xs={4}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <DatePicker
                  id="datedepot"
                  label="Heure"
                  onChange={onChangeDateDeposit}
                  value={newTour.deposit}
                  mode={'time'}
                  outputFormat="date"
                  hidden={newTour.end === newTour.defaultDateHour}
                />
              </Col>
              {showDateDepositErrorMessage && (
                <Col xs={12}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <Typography.BodySmall colorName="color-danger" style={{ textAlign: 'center' }}>
                    Attention, la date de dépôt de la marchandise est supérieure à 24h par rapport à
                    la date de début de tournée.
                  </Typography.BodySmall>
                </Col>
              )}
            </Row>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            {newTour.deposit !== newTour.defaultDateHour && (
              <SectionTitle title={`Horaires des commandes`} />
            )}
            {!GeneralUtils.isDateBeforeCurrent(newTour.deposit) && (
              <>
                <Row>
                  <Col xs={8}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <DatePicker
                      id="datedebutcommandes"
                      label="Début des commandes"
                      onChange={onChangeDateOpen}
                      value={newTour.open}
                      mode={'date'}
                      outputFormat="date"
                      hidden={newTour.deposit === newTour.defaultDateHour}
                      withDay
                    />
                  </Col>
                  <Col xs={4}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <DatePicker
                      id="heuredebutcommandes"
                      label="Heure"
                      onChange={onChangeDateOpen}
                      value={newTour.open}
                      mode={'time'}
                      outputFormat="date"
                      hidden={newTour.deposit === newTour.defaultDateHour}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <DatePicker
                      id="datefincommandes"
                      label="Fin des commandes"
                      onChange={onChangeDateClose}
                      value={newTour.close}
                      mode={'date'}
                      outputFormat="date"
                      hidden={newTour.open === newTour.defaultDateHour}
                      withDay
                    />
                  </Col>
                  <Col xs={4}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <DatePicker
                      id="heurefincommandes"
                      label="Heure"
                      onChange={onChangeDateClose}
                      value={newTour.close}
                      mode={'time'}
                      outputFormat="date"
                      hidden={newTour.open === newTour.defaultDateHour}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

            {newTour.close !== newTour.defaultDateHour && (
              <>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                <StyledModeSwitchWrapper>
                  <Typography.Body>Tournée privée (invisible)</Typography.Body>

                  <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
                  <Switch
                    trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
                    onValueChange={() => onChangeIsPrivate()}
                    value={newTour.isPrivate}
                  />
                  <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
                </StyledModeSwitchWrapper>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
                <Typography.BodyExtraSmall colorName="text-dark-3">
                  Les producteurs ne peuvent pas rejoindre une tournée privée, vous devez les
                  ajouter manuellement.
                </Typography.BodyExtraSmall>
              </>
            )}

            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <StyledButtonWrapper>
              {errorMessage ? (
                <Typography.Body colorName="color-danger" style={{ textAlign: 'center' }}>
                  {errorMessage}
                </Typography.Body>
              ) : null}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              {newTour.close !== newTour.defaultDateHour ? (
                <Button
                  label={FormatUtils.capitalize(i18n.t('add'))}
                  onPress={() => onAddTour()}
                  loading={isLoading}
                />
              ) : (
                <Typography.BodySmall colorName="text-dark-3">
                  Veuillez compléter tous les champs
                </Typography.BodySmall>
              )}
            </StyledButtonWrapper>
          </ScrollableFormWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierAddNewTourScreen
