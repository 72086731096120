import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import Typography from '../Typography'
import { LoaderProps } from './Loader.model'
import { StyledLoaderWrapper, StyledImage } from './Loader.styles'
import DeviceUtil from '../../utilities/utils/device'
import { ContentWrapper } from '../../utilities/styling/wrappers'

function Loader({
  isLoading,
  withText,
  pageLoading,
  noMargin,
  size,
  flex,
  paddingLeft,
  noHeader,
}: LoaderProps) {
  const theme = useTheme()

  if (!isLoading) return <></>

  const renderWrapper = () => {
    return (
      <StyledLoaderWrapper
        noMargin={noMargin || false}
        flex={flex}
        paddingLeft={paddingLeft}
        isDesktop={!DeviceUtil.isMobileApp()}
        pageLoading={pageLoading}
      >
        <StyledImage
          source={require('../../../assets/loader.gif')}
          resizeMode="contain"
          size={size || 3}
        />
        {(withText || pageLoading) && (
          <Typography.BodySmall colorName="text-dark-3">chargement</Typography.BodySmall>
        )}
      </StyledLoaderWrapper>
    )
  }

  if (pageLoading) {
    return (
      <ContentWrapper
        noDrawer={paddingLeft === false}
        noHeader={noHeader}
        noPaddingLeft={paddingLeft === false}
      >
        {renderWrapper()}
      </ContentWrapper>
    )
  } else {
    return renderWrapper()
  }
}

export default Loader
