import { RouteProp, useRoute } from '@react-navigation/native'
import React, { useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { PageTitle, Spacer, Typography } from '../../../components'
import {
  Batch,
  CartBatch,
  Producer,
  ProducersFake,
  StepsFake,
  Tour,
  ToursFake,
} from '../../../domain'
import i18n from '../../../i18n'
import TourProducerCard from '../../../modules/Tour/TourProducerCard'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { StyledModeSwitchWrapper, ToursContentWrapper } from './ProducerTourCrates.styles'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import DeliveryNoteCarrierService from '../../../services/carrier/deliveryNote'
import BatchCard from '../../../modules/Batch/BatchCard'
import Loader from '../../../components/Loader'
import { Switch, View } from 'react-native'
import BatchCartCard from '../../../modules/Batch/BatchCartCard'
import BatchCartBatchsCard from '../../../modules/Batch/BatchCartBatchsCard'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import TourProducerService from '../../../services/producer/tour'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { UserEnums } from '../../../../enums'

const ProducerTourCratesScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerTourCrates'>>()

  const { id } = route?.params ?? { id: '' }
  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = useQuery<Tour, Error>(['sf_tour', id], () => TourProducerService.getOneTour(id), {
    keepPreviousData: true,
  })

  const {
    data: tourBatchs,
    refetch: refetchTourBatchs,
    isInitialLoading: tourBatchsLoading,
  } = useQuery<Tour, Error>(
    ['sf_tour_batchs', id],
    () => TourProducerService.getOneTourBatchs(id, getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  if (tourLoading || tourBatchsLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour) return null

  const renderCartBatchFromBatch = (batch: Batch) => {
    const cartBatchs = batch.cartBatch

    if (cartBatchs && cartBatchs.length > 0) {
      return (
        <BatchCartBatchsCard
          key={batch.id}
          cartBatchs={cartBatchs}
          tour={tour}
          batch={batch}
          onClick={() => null}
          userRole={UserEnums.RoleEnums.PRODUCER}
        />
      )
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Colis Tournée #${getTourIdFormatted()}`}
            buttonRight={
              <>
                <Typography.BodySmall>
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                  {'\n'}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </>
            }
          />

          {(tourBatchs?.batchs || []).length < 1 && (
            <Typography.BodySmall
              colorName="text-dark-3"
              style={{ marginLeft: theme.spacingUnit * 2 }}
            >
              Aucun colis pour cette tournée
            </Typography.BodySmall>
          )}

          {(tourBatchs?.batchs || []).map((batch) => renderCartBatchFromBatch(batch))}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerTourCratesScreen
