import { Spacer } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { RouteProp, useRoute } from '@react-navigation/native'
import CatalogClientNavigationStackParamList from '../../../navigation/ClientNavigationStack/CatalogClientNavigation/CatalogClientNavigation.model'
import ProductInfos from './components/ProductInfos'
import ProducerInfos from './components/ProducerInfos'
import { ClientBatchPageWrapper } from './ProductInfos.styles'
import { useEffect, useState } from 'react'
import Loader from '../../../components/Loader'
import { Batch, CatalogExtended } from '../../../domain'
import StorageUtil from '../../../utilities/storage/storage'
import CartClientService from '../../../services/client/cart'
import BatchClientService from '../../../services/client/batch'
import CartUtil from '../../../utilities/utils/cart'
import { queryClient } from '../../../utilities/queryClient'

const ClientBatchPage = () => {
  const route = useRoute<RouteProp<CatalogClientNavigationStackParamList, 'ClientBatchPage'>>()
  const { id } = route?.params ?? { id: '' }
  const [batch, setBatch] = useState<Batch | undefined>()
  const [cartId, setCartId] = useState<string>('')

  useEffect(() => {
    const getCartId = async () => {
      const id = await CartUtil.fetchCartId()
      setCartId(id)
    }

    getCartId()
  }, [])

  const { data: cart, isLoading: isCartLoading } = CartClientService.useGetOneCart(cartId).query()

  const catalog: CatalogExtended | undefined = queryClient.getQueryData(['c_catalog'])

  useEffect(() => {
    if (catalog?.batchs) {
      const foundBatch = catalog.batchs.find((b) => b?.id === id)
      if (foundBatch) {
        setBatch(foundBatch)
      }
    }
  }, [catalog, id])

  if (isCartLoading || !batch || !batch.product || !cart) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
        <ClientBatchPageWrapper>
          <ProductInfos id={batch.product.id} cart={cart} batch={batch} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ProducerInfos id={batch.product.id} />
        </ClientBatchPageWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientBatchPage
