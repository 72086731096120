import { TransitionPresets, createStackNavigator } from '@react-navigation/stack'
import ProducerGroupsScreen from '../../../screens/Producer/ProducerGroups'
import HeaderHome from '../../components/HeaderHome'
import { Dimensions } from 'react-native'
import Header from '../../components/Header'
import ProducerGroupScreen from '../../../screens/Producer/ProducerGroup'
import ProducerAddGroupLandingScreen from '../../../screens/Producer/ProducerAddGroupLanding'

const Stack = createStackNavigator()

const ProducerGroupsNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="ProducerGroups"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ProducerGroups"
        component={ProducerGroupsScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Groupes',
        }}
      />
      <Stack.Screen
        name="ProducerGroup"
        component={ProducerGroupScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
        }}
      />

      <Stack.Screen
        name="ProducerAddGroupLanding"
        component={ProducerAddGroupLandingScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
        }}
      />
    </Stack.Navigator>
  )
}

export default ProducerGroupsNavigation
