import React from 'react'
import { Card, Icon, Icons, Spacer, Typography } from '../../../../../components'
import { TouchableOpacity } from 'react-native'
import FormatUtils from '../../../../../utilities/utils/format'
import { useTheme } from 'styled-components/native'
import {
  BatchMailCardWrapper,
  BatchMailContentWrapper,
  LabelWrapperLeft,
  LabelWrapperRight,
  StyledCheckboxWrapper,
} from './BatchMailCard.styles'
import { BatchMailCardProps } from './BatchMailCard.model'
import BatchUtil from '../../../../../utilities/utils/batch'

const BatchMailCard = ({
  onToggleSelectBatch,
  productBatchs,
  selectedBatchs,
}: BatchMailCardProps) => {
  const theme = useTheme()

  const mainBatch =
    productBatchs.batchs && productBatchs.batchs[0] ? productBatchs.batchs[0] : undefined

  if (!mainBatch) {
    return <></>
  }

  const isSelected = selectedBatchs?.includes(mainBatch.id)

  const subBatchs = productBatchs.batchs || []

  const batchComputed = BatchUtil.getBatchComputed({
    ...mainBatch,
  })

  return (
    <BatchMailCardWrapper key={`batch-${mainBatch.id}`}>
      <Card>
        <BatchMailContentWrapper>
          <StyledCheckboxWrapper>
            {batchComputed.computed?.priceParams?.unitPricesFormatted &&
            batchComputed.availableQuantity &&
            batchComputed.availableQuantity > 0 ? (
              <TouchableOpacity onPress={() => onToggleSelectBatch(mainBatch)}>
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name={isSelected ? 'checkbox-marked' : 'checkbox-blank-outline'}
                  color={theme.colors[isSelected ? 'color-primary' : 'text-dark-3']}
                  size={theme.spacingUnit * 3}
                />
              </TouchableOpacity>
            ) : (
              <Typography.BodyExtraSmall colorName="color-danger">
                Rupture
              </Typography.BodyExtraSmall>
            )}
          </StyledCheckboxWrapper>

          <LabelWrapperLeft>
            <Typography.Body bold>
              {`${batchComputed.product?.label}`}
              {subBatchs.length > 1 &&
                ` (${FormatUtils.pluralizeAmountLabel('lot', subBatchs.length)})`}
            </Typography.Body>
            <Typography.BodySmall>{batchComputed.product?.producer?.label}</Typography.BodySmall>
            <Typography.BodySmall colorName={'text-dark-3'}>
              par{' '}
              {FormatUtils.formatQuantity(
                batchComputed.product?.packing,
                batchComputed.product?.mesureType,
              )}
            </Typography.BodySmall>
          </LabelWrapperLeft>
          <LabelWrapperRight>
            <Typography.BodyExtraSmall colorName="text-dark-3">
              Prix client
            </Typography.BodyExtraSmall>
            <Spacer size={0.15} axis={Spacer.AxisEnum.VERTICAL} />

            <Typography.BodySmall
              semiBold
              colorName={
                !batchComputed.computed?.priceParams?.unitPricesFormatted
                  ? 'color-danger'
                  : 'text-dark-1'
              }
            >
              {!batchComputed.computed?.priceParams?.unitPricesFormatted
                ? 'NA'
                : batchComputed.computed?.priceParams?.unitPricesFormatted}
            </Typography.BodySmall>
          </LabelWrapperRight>
        </BatchMailContentWrapper>
      </Card>
    </BatchMailCardWrapper>
  )
}

export default BatchMailCard
