import { Tour } from '../../../domain'
import ApiService from '../../api.service'

export type ProducerDashboardMetrics = {
  nbOrders: number
  totalIncome: number
  nbClients: number
  nbCrates: number
  totalHT: number
  nbDeliveryNotes: number
  marginGain: number
}

const getDashboardMetrics = async (
  producerId: string,
  start: Date,
  end: Date,
): Promise<ProducerDashboardMetrics> => {
  const response = await ApiService.get(`/metrics/dashboard/producer/${producerId}`, {
    start: start,
    end: end,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const MetricsProducerService = {
  getDashboardMetrics,
}

export default MetricsProducerService
