import React from 'react'
import { useTheme } from 'styled-components/native'
import { Card, Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import i18n from '../../../i18n'
import FormatUtils from '../../../utilities/utils/format'
import { BatchCardProps } from './BatchCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledCenterBox,
  StyledImage,
  StyledLeftBox,
  StyledRightBox,
  StyledValueBox,
  StyledValuesWrapper,
  TextInlineBox,
} from './BatchCard.styles'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { View } from 'react-native'
import DirectusUtil from '../../../utilities/utils/directus'
import { Product } from '../../../domain'
import { InterfaceColors } from '../../../utilities/styling/theme/theme'
import BatchUtil from '../../../utilities/utils/batch'

const BatchCard = ({
  batch,
  onClick,
  isProducer,
  priceStatements,
  displayProducer,
}: BatchCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const OnClickBatch = () => {
    onClick(batch)
  }

  const getAvailableQuantity = () => {
    return batch.availableQuantity || 0
  }

  const color = () => {
    if (getAvailableQuantity() / batch.totalQuantity < 0.4 || batch.totalQuantity === 0) {
      return 'color-danger'
    }
    return 'color-primary'
  }

  const priceStatementPrices = ():
    | {
        minPriceProducer: number | undefined
        minPrice: number | undefined
        minPriceProducerWholsalers: number | undefined
        minPriceWholsalers: number | undefined
      }
    | undefined => {
    if (!priceStatements || !batch) return undefined
    let priceStatement: {
      minPriceProducer: number | undefined
      minPrice: number | undefined
      minPriceProducerWholsalers: number | undefined
      minPriceWholsalers: number | undefined
    } = {
      minPriceProducer: undefined,
      minPrice: undefined,
      minPriceProducerWholsalers: undefined,
      minPriceWholsalers: undefined,
    }

    const priceStatementsFiltered = priceStatements.filter(
      (ps) => ps.productType.id === batch.product?.productType?.id,
    )

    const batchCarrierFees = FormatUtils.stringToFloat('20') // TODO
    const carrierFees =
      batchCarrierFees && batchCarrierFees > 0 ? batchCarrierFees : batch?.tour?.carrierFees || 1

    priceStatementsFiltered.map((ps) => {
      if (!priceStatement.minPrice || ps.minPrice < priceStatement.minPrice) {
        priceStatement.minPrice = ps.minPrice
        const unitPriceProducer = ps.minPrice / (1 + carrierFees / 100)
        priceStatement.minPriceProducer = unitPriceProducer
      }
    })
    return priceStatement
  }

  const batchComputed = BatchUtil.getBatchComputed({
    ...batch,
    priceReporting: priceStatementPrices(),
  })

  return (
    <StyledCardWrapper onPress={OnClickBatch} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <StyledImage
              source={DirectusUtil.getProductImageUrlFromId(batch.product as any as Product)}
              resizeMode="cover"
            />
          </StyledLeftBox>
          <StyledCenterBox>
            <Typography.CardTitle
              style={{
                lineHeight: theme.lineHeights.CARD_TITLE - (displayProducer ? 4 : 0),
              }}
              ellipsis={true}
            >
              {batch.product?.label}
            </Typography.CardTitle>
            {displayProducer && (
              <Typography.BodyExtraSmall>
                {batch.product?.producer?.label}
              </Typography.BodyExtraSmall>
            )}
            <TextInlineBox>
              <Typography.BodySmall colorName={'text-dark-3'}>
                par {FormatUtils.formatQuantity(batch.product?.packing, batch.product?.mesureType)}
              </Typography.BodySmall>
              {(batch.isPrivate || batch.carrierFees) && !isProducer ? (
                <Typography.BodySmall colorName={'color-danger'}>
                  {batch.carrierFees && `- Commission ${batch.carrierFees}%`}{' '}
                  {batch.isPrivate && '- Caché'}
                </Typography.BodySmall>
              ) : null}
            </TextInlineBox>
            <StyledValuesWrapper>
              <StyledValueBox>
                <Typography.Body semiBold>
                  {FormatUtils.formatQuantity(batch.totalQuantity, batch.product?.mesureType)}
                </Typography.Body>
              </StyledValueBox>
              <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
              <StyledValueBox>
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name="circle-multiple"
                  size={theme.spacingUnit * 2.5}
                  color={theme.colors[color()]}
                />
              </StyledValueBox>
              <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
              <StyledValueBox>
                <Typography.Body semiBold colorName={color()}>
                  {FormatUtils.formatQuantity(getAvailableQuantity(), batch.product?.mesureType)}
                </Typography.Body>
              </StyledValueBox>
              <Spacer size={0.2} axis={Spacer.AxisEnum.HORIZONTAL} />
              <View>
                <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall colorName={color()}>restant</Typography.BodySmall>
              </View>
            </StyledValuesWrapper>
          </StyledCenterBox>
          <StyledRightBox>
            <View style={{ alignItems: 'flex-end' }}>
              <Typography.BodyExtraSmall colorName="text-dark-3">
                Prix départ
              </Typography.BodyExtraSmall>
              <Typography.BodySmall semiBold>
                {batchComputed.computed?.priceParams?.producerPricesFormatted}
              </Typography.BodySmall>
              {batchComputed.computed?.pricesStats?.producerPriceBellow.min && (
                <Typography.BodyExtraSmall colorName={'color-warning'}>
                  Prix conseillé : {batchComputed.computed?.pricesStats?.producerPriceBellow.min}
                </Typography.BodyExtraSmall>
              )}
            </View>

            <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall>{batchComputed.computed?.periodLabel}</Typography.BodySmall>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default BatchCard
