import { useQuery } from '@tanstack/react-query'
import { Card, PageTitle, SectionTitle, Spacer, Typography } from '../../../components'
import { Client } from '../../../domain'
import ClientClientService from '../../../services/client/client'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import CopyPasteAddressCard from '../../../components/CopyPasteAddressCard'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { View } from 'react-native'
import CopyPasteCard from '../../../components/CopyPasteCard'
import { useState } from 'react'

const ProducerClientScreen = () => {
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerClient'>>()
  const [isCopied, setIsCopied] = useState(false)
  const { clientId } = route.params ?? { clientId: '' }

  const {
    data: client,
    refetch: refetchClient,
    isInitialLoading: isClientLoading,
  } = useQuery<Client, Error>(['p_client', clientId], () => ClientClientService.getOne(clientId), {
    keepPreviousData: true,
  })

  const handleCopyClientNumber = (isCopied: boolean) => {
    const clientNumberInfoCsvCopy = `${client?.phone1}`
    navigator.clipboard.writeText(clientNumberInfoCsvCopy)

    setIsCopied(true)
  }
  const handleCopyClientMail = (isCopied: boolean) => {
    const clientMailInfoCsvCopy = `${client?.mail}`
    navigator.clipboard.writeText(clientMailInfoCsvCopy)

    setIsCopied(true)
  }

  const handleCopyClientDeliveryAddress = (isCopied: boolean) => {
    const clientDeliveryAddressInfoCsvCopy = `${client?.addressLine1}\n${client?.addressLine2}\n${client?.postalCode} ${client?.city}`
    navigator.clipboard.writeText(clientDeliveryAddressInfoCsvCopy)

    setIsCopied(true)
  }

  const handleCopyClientBillingAddress = (isCopied: boolean) => {
    const clientBillingAddressInfoCsvCopy = `${client?.addressLine1}\n${client?.addressLine2}\n${client?.postalCode} ${client?.city}`
    navigator.clipboard.writeText(clientBillingAddressInfoCsvCopy)

    setIsCopied(true)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <View style={{ width: '100%' }}>
          <PageTitle title="Client" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Card isLowMargin>
            <Typography.CardTitle bold style={{ width: '100%' }}>
              Nom de l'enseigne
            </Typography.CardTitle>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.Body>{client?.label}</Typography.Body>
          </Card>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <CopyPasteCard
            data={{ label: 'N° de téléphone', value: client?.phone1 ?? '' }}
            onPress={handleCopyClientNumber}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <CopyPasteCard
            data={{ label: 'Email', value: client?.mail ?? '' }}
            onPress={handleCopyClientMail}
          />
        </View>
        <SectionTitle title="Adresse de livraison" />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {client?.deliveryAddress ? (
          <CopyPasteAddressCard
            clientAddress={client?.deliveryAddress}
            onPress={handleCopyClientDeliveryAddress}
          />
        ) : (
          <View style={{ width: '100%' }}>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.BodySmall colorName="text-dark-3" align="center">
              Aucune adresse de livraison renseignée
            </Typography.BodySmall>
          </View>
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <SectionTitle title="Adresse de facturation" />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {client?.billingAddress ? (
          <CopyPasteAddressCard
            clientAddress={client?.billingAddress}
            onPress={handleCopyClientBillingAddress}
          />
        ) : (
          <View style={{ width: '100%' }}>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.BodySmall colorName="text-dark-3" align="center">
              Aucune adresse de facturation renseignée
            </Typography.BodySmall>
          </View>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerClientScreen
