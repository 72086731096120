import { TouchableOpacity } from 'react-native-gesture-handler'
import Loader from '../Loader'
import Typography from '../Typography'
import { DashboardStatsProps } from './DashboardStats.model'
import {
  ArrowBoxWrapperLeft,
  StatCardWrapper,
  ArrowBoxWrapperRight,
  MiddleBox,
  StyledCardsWrapper,
  StyledDateSwitcherWrapper,
  StyledStatWrapper,
} from './DashboardStats.styles'
import Icon, { Icons } from '../Icon'
import FormatUtils from '../../utilities/utils/format'
import { useTheme } from 'styled-components/native'
import { useEffect, useState } from 'react'
import Spacer from '../Spacer'
import Card from '../Card'
import DropShadow from 'react-native-drop-shadow'
import { StyleSheet } from 'react-native'
import BadgeStatus from '../BadgeStatus'

const DashboardStats = ({
  metrics,
  metricsFilters,
  metricsType,
  onChangeDates,
  isLoading,
  hideDateSwitcher,
}: DashboardStatsProps) => {
  const theme = useTheme()
  const [currentDates, setCurrentDates] = useState<{ start: Date; end: Date }>(metricsFilters)

  useEffect(() => {
    setCurrentDates(metricsFilters)
  }, [metricsFilters])

  const handleDateChange = async (value?: number) => {
    let tempCurrentDates = { ...currentDates }
    let tempStartDate = new Date(tempCurrentDates.start)
    let tempEndDate = new Date(tempCurrentDates.end)

    if (metricsType === 'DAY') {
      tempStartDate.setDate(tempStartDate.getDate() + (value || 0))
      tempEndDate = new Date(tempStartDate)
    } else if (metricsType === 'WEEK') {
      tempStartDate.setDate(tempStartDate.getDate() + (value || 0) * 7)
      tempEndDate.setDate(tempEndDate.getDate() + (value || 0) * 7)
    } else if (metricsType === 'MONTH') {
      tempStartDate.setMonth(tempStartDate.getMonth() + (value || 0))
      tempEndDate.setMonth(tempEndDate.getMonth() + (value || 0))
    }

    // clean hours on start and end date
    tempStartDate.setHours(0)
    tempStartDate.setMinutes(0)
    tempStartDate.setSeconds(0)

    tempEndDate.setHours(23)
    tempEndDate.setMinutes(59)
    tempEndDate.setSeconds(59)

    tempCurrentDates = { start: tempStartDate, end: tempEndDate }

    await setCurrentDates(tempCurrentDates)
    await onChangeDates(tempCurrentDates)
  }

  const dropShadowStyle = StyleSheet.create({
    shadowProp: {
      shadowColor: '#171717',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.3,
      shadowRadius: 3,
      borderRadius: theme.borderRadius * 2,
      flex: 1,
      width: '100%',
    },
  })

  return (
    <>
      {!hideDateSwitcher && (
        <StyledDateSwitcherWrapper>
          <ArrowBoxWrapperLeft>
            <TouchableOpacity onPress={() => handleDateChange(-1)}>
              <Icon
                type={Icons.Ionicons}
                name="chevron-back-outline"
                size={theme.spacingUnit * 2}
                color={theme.colors['text-dark-3']}
              />
            </TouchableOpacity>
          </ArrowBoxWrapperLeft>
          <MiddleBox>
            <Typography.Body colorName="text-dark-3" align="center">
              {FormatUtils.capitalize(
                FormatUtils.formatDate(metricsFilters.start.toString(), 'ChoppedMonth'),
              )}
            </Typography.Body>
          </MiddleBox>
          <ArrowBoxWrapperRight>
            <TouchableOpacity onPress={() => handleDateChange(1)}>
              <Icon
                type={Icons.Ionicons}
                name="chevron-forward-outline"
                size={theme.spacingUnit * 2}
                color={theme.colors['text-dark-3']}
              />
            </TouchableOpacity>
          </ArrowBoxWrapperRight>
        </StyledDateSwitcherWrapper>
      )}
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      <StyledCardsWrapper>
        {metrics?.map((metric) => (
          <StatCardWrapper key={metric.label}>
            <DropShadow style={dropShadowStyle.shadowProp}>
              <StyledStatWrapper>
                <Typography.BodyExtraSmall colorName="text-dark-3" align="center">
                  {metric.label}
                </Typography.BodyExtraSmall>
                {isLoading ? (
                  <Loader isLoading noMargin size={2} flex />
                ) : (
                  <>
                    {metric.badgeColor ? (
                      <>
                        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                        <BadgeStatus
                          color={'text-dark-1'}
                          backgroundColor={metric.badgeColor}
                          label={metric.value || ''}
                        />
                      </>
                    ) : (
                      <Typography.BodySmall colorName={'text-dark-1'} bold align="center">
                        {metric.value}
                      </Typography.BodySmall>
                    )}
                  </>
                )}
              </StyledStatWrapper>
            </DropShadow>
          </StatCardWrapper>
        ))}
      </StyledCardsWrapper>
    </>
  )
}

export default DashboardStats
