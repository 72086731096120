import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import CustomDrawerContent from './CustomDrawerContent'
import { Icon } from '../../../components'
import { useTheme } from 'styled-components/native'
import DeviceUtil from '../../../utilities/utils/device'
import { NavigationItemType } from '../../Navigation.model'
import { View } from 'react-native'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'

const CustomDrawer = ({ items }: { items: NavigationItemType[] }) => {
  const Drawer = createDrawerNavigator()
  const { carrierHasRight } = useAuthenticationContext()

  const theme = useTheme()

  const isLargeScreen = !DeviceUtil.isMobileApp()

  const renderIcon = (icon?: any, type?: any, focused?: boolean) => {
    if (!type || !icon) {
      return null
    }
    return (
      <View style={{ marginRight: -22 }}>
        <Icon
          type={type}
          name={icon}
          color={focused ? theme.colors['color-primary'] : theme.colors['text-dark-3']}
          size={30}
        />
      </View>
    )
  }

  const rightFilteredItems = (): NavigationItemType[] => {
    const tempItems: NavigationItemType[] = []
    items.map((item) => {
      let itemRightValid = true
      if (item.rightKey) {
        if (!carrierHasRight(item.rightKey)) {
          itemRightValid = false
        }
      }
      if (itemRightValid) {
        tempItems.push(item)
      }
    })
    return tempItems
  }

  return (
    <Drawer.Navigator
      screenOptions={{
        headerShown: false,
        drawerType: isLargeScreen ? 'permanent' : 'back',
        drawerStyle: {
          borderRightWidth: 0,
          maxWidth: 240,
          backgroundColor: 'transparent',
          position: 'absolute',
          top: 0,
          left: 0,
        },
        swipeEnabled: false,
        swipeEdgeWidth: 0,
      }}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      {rightFilteredItems().map((item: NavigationItemType) => {
        return (
          <Drawer.Screen
            key={item.label}
            name={item.route}
            component={item.component}
            options={{
              ...item.screenOptions,
              unmountOnBlur: true,
              drawerIcon: ({ focused }) => renderIcon(item.icon, item.type, focused),
              drawerActiveTintColor: theme.colors['color-primary'],
              drawerInactiveTintColor: theme.colors['color-grey'],
              swipeEnabled: false,
              drawerLabel: item.label,
              title: item.label,
            }}
            listeners={({ navigation }) => ({
              blur: () => navigation.setParams({ screen: undefined }),
              drawerItemPress: () => {
                navigation.popToTop()
              },
            })}
          />
        )
      })}
    </Drawer.Navigator>
  )
}

export default CustomDrawer
