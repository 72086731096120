import { AuthenticationEnums } from '../../../enums'
import { UserFake } from '../../domain'

export const loginSeed = (): any => {
  return {
    token: 'clkgjlfkjgljglfdjgfdoi',
    code: AuthenticationEnums.CODE.SUCCESS,
    user: UserFake.find((user) => user.id === 'u1'),
  }
}

export const signupSeed = (): any => {
  return {
    token: 'clkgjlfkjgljglfdjgfdoi',
    code: AuthenticationEnums.CODE.SUCCESS,
    user: UserFake.find((user) => user.id === 'u1'),
  }
}

const AuthenticationSeed = {
  loginSeed,
  signupSeed,
}

export default AuthenticationSeed
