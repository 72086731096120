import { StyleSheet } from 'react-native'
import { css } from 'styled-components'
import styled, { DefaultTheme } from 'styled-components/native'

const BORDER_WIDTH = 1
const SHADOW_RADIUS = 8

const StyledDropdownWrapper = styled.View<{
  borderColor: string
  backgroundColor: string
  zIndex: number
  open: boolean
  small: boolean
}>`
  position: relative;
  min-height: ${({ theme }) => theme.inputHeight}px;
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-width: 1px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border-color: ${({ borderColor }) => borderColor};
  z-index: 200;

  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `}

  ${({ open }) =>
    open &&
    css`
      z-index: 1000;
    `}
  ${({ small }) =>
    small &&
    css`
        min-height: ${({ theme }) => theme.inputHeight * 0.5}px;
    `}
`

const getDropdownStyles = (theme: DefaultTheme, inputWidth: number, direction: string, small: boolean) =>
  StyleSheet.create({
    containerStyle: {
      backgroundColor: theme.colors.transparent,
      height: theme.inputHeight,
      minHeight: small ? theme.inputHeight * 0.5 : theme.inputHeight,
      maxHeight: small ? theme.inputHeight * 0.5 : theme.inputHeight,
      borderWidth: 0,
      paddingHorizontal: theme.spacingUnit * 2,
    },
    textStyle: {
      color: theme.colors['text-dark-1'],
      fontSize: theme.fontSizes.BODY,
    },
    textInputStyle: {
      width: '100%',
      marginTop: small ? 0 : theme.spacingUnit * 2,
    },
    dropdownContainerStyle: {
      width: inputWidth,
      marginLeft: -BORDER_WIDTH,
      marginTop: theme.spacingUnit,
      marginBottom: theme.spacingUnit,
      backgroundColor: theme.colors['color-input'],
      borderWidth: 0,
      // maxHeight: theme.inputHeight * 3.5,
      shadowOpacity: 0.3,
      shadowRadius: SHADOW_RADIUS * 2,
      shadowColor: '#8f8f8f',
      shadowOffset: {
        width: 3,
        // height: direction === 'top' ? SHADOW_RADIUS * 2 : -SHADOW_RADIUS * 2,
        height: SHADOW_RADIUS * 1.5,
      },
      // overflow: 'visible',
      // position: 'relative',
      // top: 0,
    },
    tickIconStyle: {
      tintColor: theme.colors['text-dark-1'],
    },
    arrowIconStyle: {
      marginRight: theme.spacingUnit,
    },
    scrollViewStyle: {
      //backgroundColor: theme.colors['color-input'],
      borderWidth: BORDER_WIDTH,
      borderColor: theme.colors['color-border'],
      borderTopLeftRadius: theme.borderRadius,
      borderTopRightRadius: theme.borderRadius,
      borderBottomLeftRadius: theme.borderRadius,
      borderBottomRightRadius: theme.borderRadius,
      // overflow: 'hidden',
    },
  })

export { StyledDropdownWrapper, getDropdownStyles }
