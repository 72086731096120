import styled from 'styled-components/native'

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const TextInputWrapper = styled.View`
  width: 65%;
`
export { StyledButtonWrapper, TextInputWrapper }
