import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, SectionTitle, Spacer, TextInput, Typography } from '../../../components'
import Loader from '../../../components/Loader'
import { Pagination, Product, ProductsFake } from '../../../domain'
import i18n from '../../../i18n'
import ProductCard from '../../../modules/Product/ProductCard'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import ProductProducerService from '../../../services/producer/product'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import CatalogTopBox from './components/CatalogTopBox'
import { StyledButtonWrapper, TextInputWrapper } from './ProducerCatalog.styles'
import PaginationBar from '../../../components/PaginationBar'

const ProducerCatalogScreen = () => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const { getAccessInfos } = useAuthenticationContext()
  const [pageSize, setPageSize] = useState(6)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchValue, setSearchValue] = useState('')

  const {
    data: products,
    refetch: refetchProducts,
    isInitialLoading: productsLoading,
  } = useQuery<{ data: Product[]; pagination: Pagination }, Error>(
    ['p_products'],
    () =>
      ProductProducerService.getAllProducts(
        getAccessInfos().producerId,
        pageSize,
        pageNumber,
        searchValue,
      ),
    {
      keepPreviousData: true,
      select: (data) => {
        data.data = data.data.map((product: Product) => {
          return {
            ...product,
            formattedProductLabels: FormatUtils.formatProductLabels(product.productLabels),
          }
        })
        return data
      },
    },
  )

  useEffect(() => {
    if (!searchValue || searchValue.length > 2) {
      refetchProducts()
    }
  }, [searchValue])

  const onChangePage = async (pageNumber: number) => {
    await setPageNumber(pageNumber)
    refetchProducts()
  }

  const OnClickAddProduct = () => {
    navigation.navigate('ProducerAddProduct', {})
  }

  const OnClickUpdateProduct = (product: Product) => {
    navigation.navigate('ProducerUpdateProduct', { id: product.id })
  }

  const onChangeSearchValue = async (newSearchValue: string) => {
    setSearchValue(newSearchValue)
    setPageNumber(1)
  }

  if (productsLoading) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper alignCenter>
        <PageTitle
          title={FormatUtils.capitalize(i18n.t('pageTitleCatalog'))}
          buttonRight={
            <Button small label={'Créer un produit'} onPress={() => OnClickAddProduct()} />
          }
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <CatalogTopBox products={products?.data} pagination={products?.pagination} />
        <Spacer size={1.5} axis={Spacer.AxisEnum.VERTICAL} />
        <TextInputWrapper>
          <TextInput
            field="text"
            label="Rechercher par nom"
            value={searchValue}
            onChangeText={onChangeSearchValue}
            small
          />
        </TextInputWrapper>
        <SectionTitle
          title={`${FormatUtils.capitalize(i18n.t('products'))} (${
            products?.pagination.totalCount || 0
          })`}
        />
        {(!products ? [] : products.data).map((product) => (
          <ProductCard key={product.id} product={product} onClick={OnClickUpdateProduct} />
        ))}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <PaginationBar
          data={products?.data}
          pagination={productsLoading ? undefined : products?.pagination}
          onChangePage={onChangePage}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerCatalogScreen
