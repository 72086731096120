import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, SectionTitle, Spacer, Typography } from '../../../components'
import { ClientsFake, ProducersFake, StepsFake, Stop, Tour, ToursFake } from '../../../domain'
import i18n from '../../../i18n'
import TourClientCard from '../../../modules/Tour/TourClientCard'
import TourProducerCard from '../../../modules/Tour/TourProducerCard'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import CarrierTourClientsTopBox from './components/CarrierTourClientsTopBox'
import { StyledButtonWrapper, ToursContentWrapper } from './CarrierTourClients.styles'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import StopCarrierService from '../../../services/carrier/stop'
import { queryClient } from '../../../utilities/queryClient'
import { StackNavigationProp } from '@react-navigation/stack'
import Loader from '../../../components/Loader'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierTourClientsScreen = () => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourClients'>>()
  const [changeRankingMode, setChangeRankingMode] = useState(false)
  const { id } = route?.params ?? { id: '' }

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = TourCarrierService.getOne.query(id)

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  if (!tour) return null

  const onChangeRanking = async (stop: Stop, value: number, fixedValue?: number) => {
    let tempStop = {
      ranking: stop.ranking,
    }

    if (fixedValue) {
      tempStop.ranking = fixedValue
    } else {
      if (!value) return

      if (!tempStop.ranking || tempStop.ranking === 0) {
        tempStop.ranking = 1
      }
    }
    tempStop.ranking = tempStop.ranking + value
    const updatedStopTemp = await StopCarrierService.update(tour.id, stop.id, tempStop)
    if (updatedStopTemp) {
      queryClient.invalidateQueries({ queryKey: ['sf_tour_clients'] })
    }
  }

  const OnClickAddStop = () => {
    navigation.navigate('CarrierTourAddClients', { id: tour.id })
  }

  const stops = tour.stops || []

  if (tourLoading) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Clients #${getTourIdFormatted()}`}
            buttonRight={
              <>
                <Typography.BodySmall align="right">
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                  {'\n'}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </>
            }
          />
          <CarrierTourClientsTopBox tour={tour} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          {/* TODO: suggest this title = ${FormatUtils.pluralizeAmountLabel(
                FormatUtils.getLabelFromClientType(1),
                stops.length | 0,
              )} dans cette tournée */}
          <SectionTitle
            title={`${FormatUtils.capitalize(
              FormatUtils.pluralizeAmountLabel(
                FormatUtils.getLabelFromClientType(1),
                stops.length | 0,
                true,
              ),
            )} dans cette tournée (${stops.length | 0})`}
            buttonRight={
              <Button
                small
                colorName={changeRankingMode ? 'color-grey' : 'color-secondary'}
                label={changeRankingMode ? 'Annuler' : 'Modifier ordre'}
                onPress={() => setChangeRankingMode(!changeRankingMode)}
              />
            }
          />
          {stops.map((stop) => (
            <TourClientCard
              key={stop.id}
              stop={stop}
              tour={tour}
              changeRankingMode={changeRankingMode}
              onChangeRanking={onChangeRanking}
            />
          ))}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          <StyledButtonWrapper>
            <Button label="Ajouter un arrêt" onPress={() => OnClickAddStop()} />
          </StyledButtonWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourClientsScreen
