import { Producer, Tour } from '../../../domain'
import ApiService from '../../api.service'

const create = async (groupId: string, newProducer: any): Promise<Tour> => {
  const response = await ApiService.post(`producer/group/${groupId}`, newProducer).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const update = async (producerId: string, producer: any): Promise<Tour> => {
  const response = await ApiService.patch(`/producer/${producerId}`, producer).catch((error) => {
    throw error
  })
  return response
}

const getOneProducer = async (
  carrierId: string,
  producerId?: string,
  mail?: string,
): Promise<Producer> => {
  const response = await ApiService.get(`/producer/carrier/${carrierId}`, {
    mail: mail,
    id: producerId,
  }).catch((error) => {
    throw error
  })
  return response
}

const linkProducer = async (producerId: string, groupId: string): Promise<Producer> => {
  const response = await ApiService.patch(
    `/producer/group/${groupId}/producer/${producerId}`,
    {},
  ).catch((error) => {
    throw error
  })
  return response
}

const deleteUserProducer = async (
  userId: string,
  producerId: string,
  groupId: string,
): Promise<Producer> => {
  const response = await ApiService.remove(
    `/producer/user/${userId}/producer/${producerId}/group/${groupId}/user-producer`,
  ).catch((error) => {
    throw error
  })
  return response
}

const ProducerCarrierService = {
  create,
  update,
  getOneProducer,
  linkProducer,
  deleteUserProducer,
}

export default ProducerCarrierService
