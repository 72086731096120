import { DeliveryExpensesFormProps } from './DeliveryExpensesForm.model'
import {
  Button,
  Col,
  Icon,
  Icons,
  Row,
  Spacer,
  TextInput,
  TouchableCard,
  Typography,
} from '../../../../../components'
import { RouteProp, useRoute } from '@react-navigation/native'
import { CarrierStackParamList } from '../../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { useEffect, useState } from 'react'
import {
  CardContent,
  CardContentLeft,
  CardContentRight,
  CardContentWrapper,
  CardWrapper,
  StyledButtonWrapper,
} from './DeliveryExpensesForm.styles'
import { useTheme } from 'styled-components/native'
import InfoBox from '../../../../../components/InfoBox'
import FormatUtils from '../../../../../utilities/utils/format'
import { View } from 'react-native'

const DeliveryExpensesForm = ({
  deliveryExpensesParameters,
  capacity,
  estimatedExpenses,
  onClickSubmit,
  onGoBack,
  carrierExpenses,
}: DeliveryExpensesFormProps) => {
  const theme = useTheme()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [updatedCarrierExpenses, setUpdatedCarrierExpenses] = useState<any>(
    FormatUtils.formatDouble(estimatedExpenses?.transportCost + estimatedExpenses?.logisticCost),
  )
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTour'>>()
  const { id } = route?.params ?? { id: '' }

  if (!id) return null
  const info = {
    title: 'Comment est calculée cette estimation',
    content:
      "Il s'agit d'une estimation haussière des coûts engagés lors de votre tournée, adapté selon les paramètres que vous avez saisis et certaines variables comme le prix de l'essence et la consommation du véhicule.",
  }

  const handleClickSubmit = () => {
    onClickSubmit(updatedCarrierExpenses)
  }

  return (
    <>
      <InfoBox title={info.title} content={info.content} />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />
      <CardWrapper>
        <TouchableCard>
          <CardContentWrapper>
            <Icon
              type={Icons.MaterialCommunityIcons}
              name={'truck'}
              color={theme.colors['color-primary']}
              size={28}
            />
            <CardContent>
              <CardContentLeft>
                <Typography.Body bold>Coût du transport</Typography.Body>
                <Typography.BodySmall colorName="text-dark-3">
                  Essence, peage, camion
                </Typography.BodySmall>
              </CardContentLeft>
              <CardContentRight>
                <Typography.Body bold>
                  {FormatUtils.formatPrice(estimatedExpenses?.transportCost)}
                </Typography.Body>
              </CardContentRight>
            </CardContent>
          </CardContentWrapper>
        </TouchableCard>
      </CardWrapper>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />
      <CardWrapper>
        <TouchableCard>
          <CardContentWrapper>
            <Icon
              type={Icons.Ionicons}
              name={'people'}
              color={theme.colors['color-primary']}
              size={28}
            />
            <CardContent>
              <CardContentLeft>
                <Typography.Body bold>Coût logistique</Typography.Body>
                <Typography.BodySmall colorName="text-dark-3">
                  Temps pour la préparation et la livraison
                </Typography.BodySmall>
              </CardContentLeft>
              <CardContentRight>
                <Typography.Body bold>
                  {FormatUtils.formatPrice(estimatedExpenses?.logisticCost)}
                </Typography.Body>
              </CardContentRight>
            </CardContent>
          </CardContentWrapper>
        </TouchableCard>
      </CardWrapper>

      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={8} />
      <Typography.Body colorName="text-dark-3" align="center">
        Vous pouvez ajuster cette estimation ci-dessous et vous aurez la possibilité de confirmer le
        coût réel une fois la livraison effectuée
      </Typography.Body>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      <Row rowStyles={{ justifyContent: 'center' }}>
        <Col xs={8} sm={8}>
          <TextInput
            label="Coût de livraison estimé (en €)"
            value={updatedCarrierExpenses.toString()}
            field="text"
            onChangeText={(text) => setUpdatedCarrierExpenses(text)}
          />
        </Col>
      </Row>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />

      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <StyledButtonWrapper>
          <Button label={'Retour'} onPress={() => onGoBack('LOGISTIC')} colorName="color-grey" />
        </StyledButtonWrapper>
        <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
        <StyledButtonWrapper>
          <Button label={'Valider cette estimation'} onPress={() => handleClickSubmit()} />
        </StyledButtonWrapper>
      </View>
    </>
  )
}

export default DeliveryExpensesForm
