import styled from 'styled-components/native'
import { css } from 'styled-components/native'

const StyledButtonWrapper = styled.View<{ isWrapped?: boolean }>`
  align-self: flex-end;
  margin-top: 0;

  ${({ isWrapped }) =>
    isWrapped &&
    css`
      margin-top: 6px;
      margin-bottom: 10px;
    `}
`

const StyledTopWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
`

const StyledPageTitleWrapper = styled.View`
  flex-direction: row;
  align-items: baseline;
`

const StyledInfoCardWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`

const StyledBottomWrapper = styled.View`
  z-index: 400;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export {
  StyledButtonWrapper,
  StyledBottomWrapper,
  StyledTopWrapper,
  StyledPageTitleWrapper,
  StyledInfoCardWrapper,
}
