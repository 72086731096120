import React from 'react'
import Text from '../Text'
import { TextProps } from '../Text/Text.model'

const Danger: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text tag={Text.TextTag.DANGER} {...rest}>
      {children}
    </Text>
  )
}

export default Danger
