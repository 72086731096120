import React, { useState } from 'react'
import {
  Button,
  Col,
  Dropdown,
  PageTitle,
  Row,
  Spacer,
  TextInput,
  Typography,
} from '../../../../components'

import { StyledButtonWrapper } from './AddContainerForm.styles'
import { CONTAINER_TYPES } from '../../../../../enums/container'
import { AddContainer } from '../../../../domain'
import { AddContainerFormProps } from './AddContainerForm.model'

const newContainerDefault = {
  type: undefined,
  length: undefined,
  width: undefined,
  height: undefined,
}

const AddContainerForm = ({ onAddContainer }: AddContainerFormProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [newContainer, setNewContainer] = useState<AddContainer>(newContainerDefault)
  const [selectedContainerType, setSelectedContainerType] = useState<any>(undefined)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')

    if (!param && !(param in newContainer)) return null
    const tempContainer = Object.assign({}, newContainer)

    tempContainer[param as keyof typeof newContainer] = value
    setNewContainer(tempContainer)
  }

  const handleAddContainer = async () => {
    setIsLoading(true)
    const newContainerToSave: AddContainer = {
      type: selectedContainerType.value,
      length: newContainer.length,
      width: newContainer.width,
      height: newContainer.height,
    }

    onAddContainer(newContainerToSave)
  }

  return (
    <>
      <PageTitle title="Ajouter un nouveau type de colis" />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      <Dropdown
        label={'Type de colis'}
        onChange={setSelectedContainerType}
        multiple={false}
        optionsDefault={CONTAINER_TYPES.map((type) => ({
          ...type,
        }))}
        itemKey="ContainerType"
      />
      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      {selectedContainerType && (
        <Row>
          <Col xs={12} md={4}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newContainer.length}
              label={'Longueur (en cm)'}
              field="text"
              onChangeText={(text) => setValue(text, 'length')}
            />
          </Col>
          <Col xs={12} md={4}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newContainer.width}
              label={'Largeur (en cm)'}
              field="text"
              onChangeText={(text) => setValue(text, 'width')}
            />
          </Col>

          <Col xs={12} md={4}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newContainer.height}
              label={'Hauteur (en cm)'}
              placeholder="6"
              field="text"
              onChangeText={(text) => setValue(text, 'height')}
            />
          </Col>
        </Row>
      )}
      <Typography.BodySmall colorName="color-danger">{errorMessage}</Typography.BodySmall>
      {newContainer.length && newContainer.width && newContainer.height && (
        <>
          <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.BodySmall colorName="text-dark-3" align="center">
            Les dimensions du contenant sont importantes, car elles nous permettent de calculer le
            taux de remplissage exact des camions.
          </Typography.BodySmall>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

          <StyledButtonWrapper>
            <Button label="Enregistrer" onPress={handleAddContainer} />
          </StyledButtonWrapper>
        </>
      )}
      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
    </>
  )
}

export default AddContainerForm
