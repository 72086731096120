import { ClientCatalogFilterProps } from './ClientCatalogFilter.model'
import { BoxWrapper } from './ClientCatalogFilter.styles'
import ClientCatalogFilterTab from './ClientCatalogFilterTab'

const ClientCatalogFilter = ({
  categories,
  selectedCategory,
  onChangeCategory,
}: ClientCatalogFilterProps) => {
  return (
    <BoxWrapper>
      <ClientCatalogFilterTab
        label="Tous"
        onChangeCategory={onChangeCategory}
        selectedCategory={selectedCategory}
      />
      {categories?.map((category) => {
        return (
          <ClientCatalogFilterTab
            key={category.id}
            category={category}
            label={category.label}
            onChangeCategory={onChangeCategory}
            selectedCategory={selectedCategory}
          />
        )
      })}
    </BoxWrapper>
  )
}

export default ClientCatalogFilter
