import DropShadow from 'react-native-drop-shadow'
import { Typography } from '../../../../../components'
import Loader from '../../../../../components/Loader'
import { GroupStatsProps } from './GroupStats.model'
import { StatWrapper, StyledStatWrapper } from './GroupStats.styles'
import { StyleSheet } from 'react-native'
import { useTheme } from 'styled-components/native'

const GroupStats = ({ value, label, isLoading }: GroupStatsProps) => {
  const theme = useTheme()

  const dropShadowStyle = StyleSheet.create({
    shadowProp: {
      shadowColor: '#171717',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.3,
      shadowRadius: 3,
      borderRadius: theme.borderRadius * 2,
      flex: 1,
      width: '100%',
    },
  })

  return (
    <StatWrapper>
      <DropShadow style={dropShadowStyle.shadowProp}>
        <StyledStatWrapper>
          <Typography.BodySmall colorName="text-dark-3">{label}</Typography.BodySmall>
          {isLoading ? (
            <Loader isLoading noMargin size={2} flex />
          ) : (
            <Typography.CardTitle colorName="text-dark-1" bold style={{ alignSelf: 'center' }}>
              {value}
            </Typography.CardTitle>
          )}
        </StyledStatWrapper>
      </DropShadow>
    </StatWrapper>
  )
}

export default GroupStats
