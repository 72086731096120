import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardCity = styled.View`
  width: 100%;
  z-index: 3000;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const CardContentWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export { StyledCardCity, StyledButtonWrapper, CardContentWrapper }
