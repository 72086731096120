import styled from 'styled-components/native'

const StyledStatWrapper = styled.View`
  width: 33%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  justify-content: top;
  align-items: left;
`

const StyledCard = styled.View``

export { StyledStatWrapper, StyledCard }
