import React from 'react'
import { useTheme } from 'styled-components/native'
import { CardSettingsProps } from './CardSettings.model'
import {
  LeftCardWrapper,
  RightCardWrapper,
  StyledCard,
  StyledCardContent,
} from './CardSettings.styles'
import Typography from '../Typography'
import Spacer from '../Spacer'

function CardSettings({ title, description, children, descriptionColor }: CardSettingsProps) {
  const theme = useTheme()

  return (
    <StyledCard>
      <StyledCardContent>
        <LeftCardWrapper>
          <Typography.Body colorName="color-grey" bold>
            {title}
          </Typography.Body>
          <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.BodySmall colorName={descriptionColor}>{description}</Typography.BodySmall>
        </LeftCardWrapper>
        <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
        <RightCardWrapper hasChildren={children !== undefined}>{children}</RightCardWrapper>
      </StyledCardContent>
    </StyledCard>
  )
}

export default CardSettings
