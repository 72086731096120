import React from 'react'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, TouchableCard, Typography } from '../../../../components'
import { ProducerCardProps } from './ProducerCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
} from './ProducerCard.styles'
import FormatUtils from '../../../../utilities/utils/format'

const ProducerCard = ({ producer, onClick }: ProducerCardProps) => {
  const theme = useTheme()

  const OnClickProducer = () => {
    onClick(producer)
  }

  if (!producer) return null

  return (
    <StyledCardWrapper onPress={OnClickProducer} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.CardTitle>{producer.label}</Typography.CardTitle>
            <Typography.BodySmall colorName={'text-dark-3'}>
              {FormatUtils.pluralizeAmountLabel('produit', producer.nbProducts)} à l'année
            </Typography.BodySmall>
          </StyledLeftBox>
          <StyledRightBox>
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              size={theme.spacingUnit * 2}
              color={theme.colors['text-dark-3']}
            />
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default ProducerCard
