import styled from 'styled-components/native'

const StyledWrapper = styled.View`
  // width: 100%;
`

const StyledButtonWrapper = styled.View`
  padding-top: ${({ theme }) => theme.spacingUnit * 0}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 2}px;
`

export { StyledWrapper, StyledButtonWrapper }
