import React from 'react'
import UserInitialsAvatar from './components/UserInitialsAvatar'
import { UserAvatarProps } from './UserAvatar.model'
import { AvatarGradient, StyledAvatarBox, StyledAvatarImage } from './UserAvatar.styles'

const UserAvatar = ({ user, large }: UserAvatarProps) => {
  const boxSize = large ? 90 : 60
  return (
    <AvatarGradient boxSize={boxSize} colors={['#1ca12b', '#1ca12c9b']}>
      <StyledAvatarBox boxSize={boxSize}>
        {user?.photoUrl ? (
          <StyledAvatarImage boxSize={boxSize} source={{ uri: user.photoUrl }} />
        ) : (
          <UserInitialsAvatar user={user} large={large} />
        )}
      </StyledAvatarBox>
    </AvatarGradient>
  )
}

export default UserAvatar
