import React, { useEffect, useCallback, useState } from 'react'
import { Linking, Platform } from 'react-native'
import Constants from 'expo-constants'
import { useQuery } from '@tanstack/react-query'
import { MobileApplicationConfiguration } from '../../domain'
import MobileApplicationConfigurationService from '../../services/mobileApplicationConfiguration'
import { VersionControllerProps } from './VersionController.model'
import SoftUpdateContent from './SoftUpdateContent'
import BottomSheet from '../../components/BottomSheet'

function VersionController({ navigationState, navigationRef }: VersionControllerProps) {
  const [isSoftModalOpen, setIsSoftModalOpen] = useState(false)
  const [isSoftModalAlreadyOpen, setIsSoftModalAlreadyOpen] = useState(false)

  const platform = Platform.select({
    android: 'ANDROID',
    ios: 'APPLE',
    default: 'NA',
  })
  const version = Constants?.manifest?.version || '0.0.1'
  const { data: mobileApplicationConfiguration } = useQuery<MobileApplicationConfiguration>(
    [MobileApplicationConfigurationService.QUERY_KEY],
    () => MobileApplicationConfigurationService.findOne(platform, version),
    {
      retryOnMount: false,
      cacheTime: 60 * 60 * 1000, // 1 hour
    },
  )

  const navigateToAppStore = useCallback(() => {
    const { link } = mobileApplicationConfiguration || { link: 'https://harvy.tech' }
    console.log('-------- navigateToAppStore')
    console.log(link)

    Linking.canOpenURL(link).then(
      (supported) => {
        if (supported) {
          Linking.openURL(link)
        }
      },
      (err) => console.log(err),
    )
  }, [mobileApplicationConfiguration])

  async function checkPage() {
    if (!navigationState || navigationState?.routes.length < 1 || !mobileApplicationConfiguration) {
      return
    }
    const currentRoute = navigationState?.routes[navigationState?.routes.length - 1].name
    const pageServiceCheck = mobileApplicationConfiguration?.updateServices[currentRoute]
    const pageHasServiceCheck = pageServiceCheck !== undefined

    if (pageHasServiceCheck && pageServiceCheck) {
      navigationRef.navigate('ForceUpgradeScreen', {
        storeUrl: mobileApplicationConfiguration?.link,
      })
    }
  }

  useEffect(() => {
    checkPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationState])

  useEffect(() => {
    if (mobileApplicationConfiguration?.forceUpgrade) {
      navigationRef.navigate('ForceUpgradeScreen', {
        storeUrl: mobileApplicationConfiguration?.link,
      })
    }

    if (mobileApplicationConfiguration?.softUpgrade && !isSoftModalAlreadyOpen) {
      setIsSoftModalAlreadyOpen(true)
      setIsSoftModalOpen(true)
    }
  }, [
    mobileApplicationConfiguration?.forceUpgrade,
    mobileApplicationConfiguration?.softUpgrade,
    mobileApplicationConfiguration?.link,
    navigationRef,
  ])

  const closeModal = () => {
    setIsSoftModalOpen(false)
  }

  const renderContent = () => {
    if (mobileApplicationConfiguration?.forceUpgrade) {
      return null
    }

    if (mobileApplicationConfiguration?.softUpgrade && isSoftModalOpen) {
      return (
        <BottomSheet isOpen={true} onClose={closeModal}>
          <SoftUpdateContent onButtonPress={navigateToAppStore} onCancelPress={closeModal} />
        </BottomSheet>
      )
    }
    return null
  }

  return renderContent()
}
export default VersionController
