import {
  Button,
  Col,
  Row,
  SectionTitle,
  Spacer,
  TextInput,
  Typography,
} from '../../../../../components'
import { CarrierTourClientsProps } from './CarrierTourClients.model'
import { Order, Stop } from '../../../../../domain'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { queryClient } from '../../../../../utilities/queryClient'
import StopCarrierService from '../../../../../services/carrier/stop'
import FormatUtils from '../../../../../utilities/utils/format'
import { useState } from 'react'
import TourClientCard from '../../../../../modules/Tour/TourClientCard'
import { StyledButtonWrapper, TextInputWrapper } from './CarrierTourClients.style'
import TourUtil from '../../../../../utilities/utils/tour'
import { View } from 'react-native'
import GeneralUtils from '../../../../../utilities/utils/general'
import Loader from '../../../../../components/Loader'

const CarrierTourClientsV2 = ({ tour }: CarrierTourClientsProps) => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [changeRankingMode, setChangeRankingMode] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const stops = tour.stops || []
  const emptyOrder: Order[] = []
  const clients = stops.map((s) => {
    return { ...s.client, orders: [...emptyOrder] }
  })

  const carts = tour.carts

  carts?.map((cart) => {
    const clientIndex = clients.findIndex((client) => client?.id === cart.client?.id)

    if (clientIndex > -1) {
      const cartWithoutOrder = { ...cart }
      delete cartWithoutOrder.order
      const order: Order = {
        ...cart.order,
        cart: cartWithoutOrder,
      }

      clients[clientIndex].orders.push(order)
    }
  })

  const onChangeRanking = async (stop: Stop, value: number, fixedValue?: number) => {
    let existingRanking

    let tempStop = {
      ranking: stop.ranking,
    }

    if (fixedValue) {
      existingRanking = stops.find((s) => s.ranking == fixedValue)
      tempStop.ranking = fixedValue
    } else {
      if (!value) return
      existingRanking = stops.find((s) => s.ranking == stop.ranking + value)

      if (!tempStop.ranking || tempStop.ranking === 0) {
        tempStop.ranking = 1
      }
    }
    tempStop.ranking = tempStop.ranking + value

    if (existingRanking) {
      await Promise.all([
        StopCarrierService.update(tour.id, stop.id, tempStop),
        StopCarrierService.update(tour.id, existingRanking.id, {
          ranking: stop.ranking,
        }),
      ])
    } else {
      await StopCarrierService.update(tour.id, stop.id, tempStop)
    }

    queryClient.invalidateQueries(['sf_tour', tour.id])
  }

  const OnClickAddStop = () => {
    navigation.navigate('CarrierTourAddClients', { id: tour.id })
  }

  const filterByName = (name: string) => {
    return GeneralUtils.stringContains(searchValue, name)
  }

  const handleReorganize = async () => {
    setIsLoading(true)
    const stopsWithCart = stops.filter((stop) => !stop.hasCartWithNoOrder)
    const stopsWithoutCart = stops.filter((stop) => stop.hasCartWithNoOrder)

    const updatedStops = stopsWithCart.map((stop, index) => {
      return {
        id: stop.id,
        ranking: index + 1,
      }
    })

    const updatedStopsWithoutCart = stopsWithoutCart.map((stop) => {
      return {
        id: stop.id,
        ranking: null,
      }
    })

    const stopsToUpdate = [...updatedStops, ...updatedStopsWithoutCart]

    try {
      await StopCarrierService.arrangeStops(tour.id, stopsToUpdate)
      queryClient.invalidateQueries(['sf_tour', tour.id])
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <SectionTitle
        title={`${FormatUtils.capitalize(
          FormatUtils.pluralizeAmountLabel(
            FormatUtils.getLabelFromClientType(1),
            stops.length | 0,
            true,
          ),
        )} (${stops.length | 0})`}
        buttonRight={
          <Button
            label="Ajouter un client"
            onPress={() => OnClickAddStop()}
            disabled={TourUtil.isTourCompleted(tour)}
            small
          />
        }
      />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2.5} />
      <Row>
        <Col xs={12} sm={12} md={6}>
          <View style={{ flexDirection: 'row' }}>
            <Button
              small
              label={'Ré-organiser'}
              onPress={() => handleReorganize()}
              hasDoubleValidation
              confirmationLabel="Ré-organiser ?"
            />
            <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
            <Button
              small
              colorName={changeRankingMode ? 'color-grey' : 'color-secondary'}
              label={changeRankingMode ? 'Annuler' : 'Ordonner'}
              onPress={() => setChangeRankingMode(!changeRankingMode)}
            />
            <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            {changeRankingMode && (
              <Button
                small
                colorName={'color-secondary'}
                label={'Confirmer'}
                onPress={() => setChangeRankingMode(!changeRankingMode)}
              />
            )}
          </View>

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
        </Col>
        <Col xs={12} sm={12} md={6} alignItems="flex-end">
          <TextInput
            field="text"
            label="Rechercher par nom"
            value={searchValue}
            onChangeText={setSearchValue}
            small
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
        </Col>
      </Row>

      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.2} />
      {isLoading ? (
        <Loader isLoading />
      ) : (
        stops
          .filter((stop) => filterByName(stop.client.label))
          .map((stop) => (
            <TourClientCard
              key={stop.id}
              stop={stop}
              tour={tour}
              changeRankingMode={changeRankingMode}
              onChangeRanking={onChangeRanking}
            />
          ))
      )}
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      {TourUtil.isTourCompleted(tour) && (
        <View style={{ width: '100%', alignItems: 'center' }}>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Typography.Body colorName="color-grey">
            Cette tournée est terminée, vous ne pouvez plus ajouter de client à cette tournée.
          </Typography.Body>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        </View>
      )}
      <StyledButtonWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      </StyledButtonWrapper>
    </>
  )
}

export default CarrierTourClientsV2
