import { Alert, TouchableOpacity } from 'react-native'
import {
  Divider,
  Typography,
  Card,
  Icon,
  Icons,
  Button,
  Row,
  Col,
  DatePicker,
  Spacer,
} from '../../../../../components'
import { ProducerBillingDateFilterProps } from './ProducerBillingDateFilter.model'
import {
  ArrowBoxWrapperLeft,
  ArrowBoxWrapperRight,
  MiddleBox,
  StatsWrapper,
  StyledCardWrapper,
  StyledDateSwitcherWrapper,
  SwitchModeButton,
} from './ProducerBillingDateFilter.styles'
import FormatUtils from '../../../../../utilities/utils/format'
import { useTheme } from 'styled-components/native'
import { useState } from 'react'

const ProducerBillingDateFilter = ({
  metrics,
  onClickMetricsChangeDate,
  metricsFilters,
  metricsLoading,
  refetchDeliveryNotes,
}: ProducerBillingDateFilterProps) => {
  const theme = useTheme()
  const [modeDateInput, setModeDateInput] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onValidateDates = () => {
    setErrorMessage('')

    if (metricsFilters.start > metricsFilters.end) {
      setErrorMessage('Dates invalides')
      return
    }
    refetchDeliveryNotes()
  }

  return (
    <StyledCardWrapper onPress={() => null}>
      <Card>
        {modeDateInput ? (
          <>
            <Row>
              <Col xs={5}>
                <DatePicker
                  id="from"
                  label="Du"
                  mode="date"
                  onChange={(value) => onClickMetricsChangeDate(undefined, value, 'start')}
                  value={metricsFilters.start}
                />
              </Col>
              <Col xs={5}>
                <DatePicker
                  id="from"
                  label="Au"
                  mode="date"
                  onChange={(value) => onClickMetricsChangeDate(undefined, value, 'end')}
                  value={metricsFilters.end}
                />
              </Col>
              <Col xs={2}>
                <Button small label={'Valider'} onPress={() => onValidateDates()} />
              </Col>
            </Row>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            {errorMessage && (
              <Typography.InputLabel colorName="color-danger">{errorMessage}</Typography.InputLabel>
            )}
          </>
        ) : (
          <StyledDateSwitcherWrapper>
            <ArrowBoxWrapperLeft>
              <TouchableOpacity onPress={() => onClickMetricsChangeDate(-1)}>
                <Icon
                  type={Icons.Ionicons}
                  name="chevron-back-outline"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['text-dark-3']}
                />
              </TouchableOpacity>
            </ArrowBoxWrapperLeft>
            <MiddleBox>
              <Typography.Body colorName="text-dark-1" align="center">
                {FormatUtils.formatDate(metricsFilters.start.toString(), 'MonthYear')}
              </Typography.Body>
            </MiddleBox>
            <ArrowBoxWrapperRight>
              <TouchableOpacity onPress={() => onClickMetricsChangeDate(1)}>
                <Icon
                  type={Icons.Ionicons}
                  name="chevron-forward-outline"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['text-dark-3']}
                />
              </TouchableOpacity>
            </ArrowBoxWrapperRight>
          </StyledDateSwitcherWrapper>
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <SwitchModeButton>
          <Button.Text
            colorName="color-grey"
            label={modeDateInput ? 'Filtrer par mois' : 'Filtrer par date'}
            onPress={() => {
              onClickMetricsChangeDate(0)
              setModeDateInput(!modeDateInput)
            }}
          />
        </SwitchModeButton>
      </Card>
    </StyledCardWrapper>
  )
}

export default ProducerBillingDateFilter
