import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const BoxWrapper = styled.View`
  width: 100%;
  min-height: 60px;
  align-items: center;
`

const StyledCardWrapperTour = styled.TouchableOpacity`
  width: 260px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledCardWrapperCart = styled.TouchableOpacity`
  align-items: center;
  width: 260px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 2}px;
`

const CartLine = styled.View`
  width: 100%;
  flex-direction: row;
`

const CartLineLabel = styled.View`
  flex: 1;
  flex-direction: row;
`

const CartLineValue = styled.View`
  align-items: flex-end;
`

const StyledButtonWrapper = styled.View``

export {
  StyledCardWrapperTour,
  BoxWrapper,
  StyledCardWrapperCart,
  StyledButtonWrapper,
  CartLine,
  CartLineLabel,
  CartLineValue,
}
