import { useHeaderHeight } from '@react-navigation/elements'
import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import styled from 'styled-components/native'
import { css } from 'styled-components/native'
import DeviceUtil from '../../utils/device'

const StyledSafeAreaViewWrapper = styled.View`
  width: 100%;
  height: 100%;
`

const ScreenSafeAreaWrapper = styled((props) => {
  const { withBottomNav, noPaddingTop, noPaddingBottom, noPaddingLeft = false } = props
  const headerHeight = useHeaderHeight()
  let edges = ['top', 'bottom', 'left', 'right']

  if (headerHeight || noPaddingTop) {
    // removes extra padding from top of screen when header is present
    edges = edges.filter((edge) => edge !== 'top')
  }

  if (withBottomNav || noPaddingBottom) {
    // removes extra padding from bottom of screen when bottom nav is present
    edges = edges.filter((edge) => edge !== 'bottom')
  }
  return (
    <StyledSafeAreaViewWrapper>
      <SafeAreaView edges={edges} {...props} />
    </StyledSafeAreaViewWrapper>
  )
})`
  flex: 1;
  width: 100%;
  max-width: 100%;
`
//  width: 400px;
// background-color: ${({ theme }) => theme.colors['background-1']};
export default ScreenSafeAreaWrapper
