import { CommonActions, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Card,
  Col,
  Icon,
  Icons,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { AddProducer, Producer } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import ProducerCarrierService from '../../../services/carrier/producer'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import {
  ToursContentWrapper,
  StyledButtonWrapper,
  BackToListWrapper,
  StyledCardWrapper,
  StyledCardContentWrapper,
  LeftCardContentWrapper,
} from './CarrierAddProducer.styles'
import GeneralUtils from '../../../utilities/utils/general'

const CarrierAddProducerScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierAddProducer'>>()
  const [isLoading, setIsLoading] = useState(false)

  const { getAccessInfos } = useAuthenticationContext()

  const { groupId } = route?.params ?? { groupId: '' }

  const theme = useTheme()
  const [newProducer, setNewProducer] = useState<AddProducer>({
    label: '',
    owners: '',
    description: '',
    location: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    city: '',
    phone1: '',
    phone2: '',
    mail: '',
    deliveryNoteStatment1: '',
    deliveryNoteStatment2: '',
    deliveryNoteBottomStatment: '',
    billingStatement: '',
    siret: '',
    apeCode: '',
    vatNumber: '',
  })
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [producerMailRequest, setProducerMailRequest] = useState<string>('')
  const [foundProducer, setFoundProducer] = useState<Producer>()
  const [displayNoProfile, setDisplayNoProfile] = useState<boolean>(false)
  const [formIsOpen, setFormIsOpen] = useState<boolean>(false)

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newProducer)) return null

    const clientTemp = Object.assign({}, newProducer)

    clientTemp[param as keyof typeof newProducer] = value
    setNewProducer(clientTemp)
  }

  const onAddProducer = async () => {
    setErrorMessage('')
    if (!groupId) {
      setErrorMessage('Erreur de chargement du groupe')
      return
    }
    if (newProducer.mail && !GeneralUtils.isEmail(newProducer.mail)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    const producer = {
      label: newProducer.label,
      owners: newProducer.owners,
      description: newProducer.description,
      location: newProducer.location,

      addressLine1: newProducer.addressLine1,
      addressLine2: newProducer.addressLine2,
      postalCode: newProducer.postalCode,
      city: newProducer.city,
      phone1: newProducer.phone1,
      phone2: newProducer.phone2,
      mail: newProducer.mail,
      deliveryNoteStatment1: newProducer.deliveryNoteStatment1,
      deliveryNoteStatment2: newProducer.deliveryNoteStatment2,
      deliveryNoteBottomStatment: newProducer.deliveryNoteBottomStatment,
      siret: newProducer.siret,
      apeCode: newProducer.apeCode,
      vatNumber: newProducer.vatNumber,
    }

    let createdProducer
    try {
      createdProducer = await ProducerCarrierService.create(groupId, producer)
    } catch (error) {
      setErrorMessage(
        `Un problème est survenu lors de la création du producteur, vérifiez qu'il n'existe pas déjà un producteur avec cet email`,
      )
      setIsLoading(false)
      return
    }

    if (createdProducer && createdProducer.id) {
      await queryClient.fetchQuery(['sf_group'])
      await queryClient.fetchQuery(['sf_group_producers'])

      navigation.dispatch(
        CommonActions.reset({
          index: 3,
          routes: [
            {
              name: 'CarrierGroup',
              params: {},
            },
            {
              name: 'CarrierGroupProducers',
              params: {},
            },
            {
              name: 'CarrierUpdateProducer',
              params: { id: createdProducer.id },
            },
            {
              name: 'CarrierAddUser',
              params: {
                groupId: groupId,
                producerId: createdProducer.id,
                producerLabel: producer.label,
                email: producer.mail,
              },
            },
          ],
        }),
      )
    } else {
      setErrorMessage('Un problème est survenu lors de la création du producteur')
    }
    setIsLoading(false)
  }

  const onRequestSearchMail = async () => {
    if (producerMailRequest && !GeneralUtils.isEmail(producerMailRequest)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }
    let foundExistingProducer

    try {
      foundExistingProducer = await ProducerCarrierService.getOneProducer(
        getAccessInfos().carrierId,
        undefined,
        producerMailRequest,
      )

      if (
        foundExistingProducer &&
        foundExistingProducer.producerGroups?.find((pg) => pg.group?.id === groupId)
      ) {
        setErrorMessage(`Un producteur avec cet email existe déjà dans ce groupe`)
        setIsLoading(false)
        return
      }

      if (foundExistingProducer) {
        setFoundProducer(foundExistingProducer)
      }
    } catch (error) {
      console.error(error)
    }

    if (!foundExistingProducer) {
      setDisplayNoProfile(true)
    }
  }

  const onValidateProducer = async (foundProducer: Producer) => {
    setErrorMessage('')
    if (!groupId) {
      setErrorMessage('Erreur de chargement du groupe')
      return
    }
    if (foundProducer) {
      try {
        setIsLoading(true)
        await ProducerCarrierService.linkProducer(foundProducer.id, groupId)
        navigation.navigate('CarrierUpdateProducer', { id: foundProducer.id })
        queryClient.invalidateQueries(['sf_group'])
        queryClient.invalidateQueries(['sf_group_producers'])
      } catch (error) {
        setErrorMessage('Un problème est survenu lors de la liaison du producteur')
        console.error(error)
      }
    }

    setDisplayNoProfile(false)
    setFormIsOpen(true)

    return
  }

  const handleBackToList = () => {
    setFormIsOpen(false)
    setFoundProducer(undefined)
    setProducerMailRequest('')
    setDisplayNoProfile(false)
    setErrorMessage('')
    setNewProducer({
      label: '',
      owners: '',
      description: '',
      location: '',
      addressLine1: '',
      addressLine2: '',
      postalCode: '',
      city: '',
      phone1: '',
      phone2: '',
      mail: '',
      deliveryNoteStatment1: '',
      deliveryNoteStatment2: '',
      deliveryNoteBottomStatment: '',
      billingStatement: '',
      siret: '',
      apeCode: '',
      vatNumber: '',
    })
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Ajouter un producteur" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
          {!formIsOpen && (
            <>
              {foundProducer && (
                <BackToListWrapper onPress={handleBackToList}>
                  <Icon
                    type={Icons.Ionicons}
                    name="chevron-back-outline"
                    color={theme.colors['color-primary']}
                  />
                  <Button.Text label={'Revenir en arrière'} onPress={handleBackToList} />
                </BackToListWrapper>
              )}
              <SectionTitle title="Saisir un email" />
              <Row rowStyles={{ justifyContent: 'center', alignItems: 'center' }}>
                <Col xs={9}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={producerMailRequest}
                    label="Email"
                    field="text"
                    onChangeText={(text) => {
                      setProducerMailRequest(text)
                      setFoundProducer(undefined)
                      setErrorMessage('')
                      setDisplayNoProfile(false)
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <StyledButtonWrapper>
                    {!foundProducer && !displayNoProfile && (
                      <Button
                        label="Vérifier"
                        onPress={() => onRequestSearchMail()}
                        loading={isLoading}
                      />
                    )}
                  </StyledButtonWrapper>
                </Col>
              </Row>
              <Row>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                {errorMessage ? (
                  <>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                    <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  </>
                ) : null}
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              </Row>
              {foundProducer && (
                <Typography.Body colorName="color-grey">
                  Nous avons trouvé le(s) compte(s) suivant(s) avec cette adresse email
                </Typography.Body>
              )}
              {foundProducer && (
                <>
                  <SectionTitle title="Producteur" />
                  <Row>
                    <Col xs={12}>
                      <StyledCardWrapper>
                        <Card>
                          <StyledCardContentWrapper>
                            <LeftCardContentWrapper>
                              <Typography.CardTitle>{foundProducer?.label}</Typography.CardTitle>
                              <Typography.BodySmall>{foundProducer?.owners}</Typography.BodySmall>
                              <Typography.BodySmall>
                                {foundProducer?.producerGroups
                                  ? foundProducer.producerGroups
                                      .map((pg) => pg.group?.label)
                                      .join(' & ')
                                  : null}
                              </Typography.BodySmall>
                            </LeftCardContentWrapper>
                            <StyledButtonWrapper>
                              <Button
                                label={'Lier'}
                                onPress={() => onValidateProducer(foundProducer)}
                                loading={isLoading}
                              />
                            </StyledButtonWrapper>
                          </StyledCardContentWrapper>
                        </Card>
                      </StyledCardWrapper>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          {displayNoProfile && (
            <>
              <Typography.Body colorName="color-grey">
                Cette adresse email n'est pas liée à un compte Producteur, vous pouvez en créer un.
              </Typography.Body>
              <SectionTitle title="Créer un nouveau compte" />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />
              <Row>
                <Col xs={12}>
                  <StyledButtonWrapper>
                    <Button
                      label={'Créer un compte'}
                      onPress={() => {
                        setFormIsOpen(true)
                        setDisplayNoProfile(false)
                        setValue(producerMailRequest, 'mail')
                      }}
                      loading={isLoading}
                    />
                  </StyledButtonWrapper>
                </Col>
              </Row>
            </>
          )}
          {formIsOpen && (
            <>
              <BackToListWrapper onPress={handleBackToList}>
                <Icon
                  type={Icons.Ionicons}
                  name="chevron-back-outline"
                  color={theme.colors['color-primary']}
                />
                <Button.Text label={'Revenir en arrière'} onPress={handleBackToList} />
              </BackToListWrapper>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newProducer.label}
                    label="Nom de l'exploitation"
                    field="text"
                    onChangeText={(text) => setValue(text, 'label')}
                    required
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newProducer.owners}
                    label="Gérant.e.s"
                    field="text"
                    onChangeText={(text) => setValue(text, 'owners')}
                    hidden={!newProducer.label}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newProducer.mail}
                    label="Email"
                    field="text"
                    onChangeText={(text) => setValue(text, 'mail')}
                    hidden={!newProducer.label}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newProducer.phone1}
                    label="Téléphone (0606...)"
                    field="text"
                    onChangeText={(text) => setValue(text, 'phone1')}
                    hidden={!newProducer.label}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newProducer.addressLine1}
                    label="Adresse (n°, rue)"
                    field="text"
                    onChangeText={(text) => setValue(text, 'addressLine1')}
                    hidden={!newProducer.label}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newProducer.addressLine2}
                    label="Complément d'adresse"
                    field="text"
                    onChangeText={(text) => setValue(text, 'addressLine2')}
                    hidden={!newProducer.label}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newProducer.postalCode}
                    label="Code postal"
                    field="text"
                    onChangeText={(text) => setValue(text, 'postalCode')}
                    hidden={!newProducer.label}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newProducer.city}
                    label="Ville"
                    field="text"
                    onChangeText={(text) => setValue(text, 'city')}
                    hidden={!newProducer.label}
                  />
                </Col>
              </Row>

              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <Row>
                <Col xs={12} alignItems="center">
                  {errorMessage ? (
                    <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                  ) : null}
                  <StyledButtonWrapper>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                    {newProducer.label && (
                      <Button
                        label={FormatUtils.capitalize(i18n.t('add'))}
                        onPress={() => onAddProducer()}
                        loading={isLoading}
                      />
                    )}
                  </StyledButtonWrapper>
                </Col>
              </Row>
            </>
          )}
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierAddProducerScreen
