import { useNavigation } from '@react-navigation/native'
import {
  createStackNavigator,
  StackNavigationProp,
  TransitionPresets,
} from '@react-navigation/stack'
import React from 'react'
import ProfileSwitcherScreen from '../../screens/Authentification/ProfileSwitcher'
import ForceUpgradeScreen from '../../screens/Authentification/ForceUpgrade'
import LoginScreen from '../../screens/Authentification/Login/Login'
import useAuthenticationContext from '../../utilities/hook/useAuthenticationContext'
import { AuthenticationStackParamList } from './AuthenticationNavigationStack.model'
import useCardTitleOptions from './AuthenticationNavigationStack.utils'
import ForgottenPasswordScreen from '../../screens/Authentification/ForgottenPassword'
import ResetPasswordScreen from '../../screens/Authentification/ResetPassword'
import RegistrationLandingScreen from '../../screens/Authentification/Registration/components/RegistrationLanding'
import { Dimensions } from 'react-native'
import RegistrationFormScreen from '../../screens/Authentification/Registration/components/RegistrationForm'
import Header from '../components/Header'
import RegisterLandingScreen from '../../screens/Authentification/Register/RegisterLanding'
import RegisterTutorialScreen from '../../screens/Authentification/Register/RegisterTutorial'
import RegisterProducerFormScreen from '../../screens/Authentification/Register/RegisterProducerForm'

const Stack = createStackNavigator<AuthenticationStackParamList>()

const AuthenticationNavigationStack = () => {
  const navigation = useNavigation<StackNavigationProp<AuthenticationStackParamList>>()
  const headerOptions = useCardTitleOptions()
  const { isUserAuthenticated } = useAuthenticationContext()

  return (
    <Stack.Navigator
      initialRouteName={isUserAuthenticated ? 'ProfileSwitcher' : 'Login'}
      screenOptions={{
        ...headerOptions,
        keyboardHandlingEnabled: false,
      }}
    >
      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={{
          gestureEnabled: false,
          headerShown: false,
          animationEnabled: false,
          title: 'Connexion',
        }}
      />
      <Stack.Screen
        name="ProfileSwitcher"
        component={ProfileSwitcherScreen}
        options={{
          gestureEnabled: false,
          headerShown: true,
          animationEnabled: false,
          title: 'Choix du compte',
          header: () => <Header title="" goBackFallback="Login" leftButton={<></>} />,
        }}
      />
      <Stack.Screen
        name="ForceUpgradeScreen"
        component={ForceUpgradeScreen}
        options={{
          gestureEnabled: false,
          headerShown: false,
          animationEnabled: false,
          title: 'Mise à jour',
        }}
      />
      <Stack.Screen
        name="ForgottenPassword"
        component={ForgottenPasswordScreen}
        options={{
          gestureEnabled: true,
          headerShown: true,
          animationEnabled: true,
          title: 'Mot de passe oublié',
        }}
      />
      <Stack.Screen
        name="ResetPassword"
        component={ResetPasswordScreen}
        options={{
          gestureEnabled: true,
          headerShown: true,
          animationEnabled: true,
          title: 'Réinitialiser le mot de passe',
        }}
      />
      <Stack.Screen
        name="RegistrationLanding"
        component={RegistrationLandingScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name="RegistrationForm"
        component={RegistrationFormScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: false,
        }}
      />

      <Stack.Screen
        name="RegisterLanding"
        component={RegisterLandingScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
        }}
      />

      <Stack.Screen
        name="RegisterTutorial"
        component={RegisterTutorialScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
        }}
      />

      <Stack.Screen
        name="RegisterProducerForm"
        component={RegisterProducerFormScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
        }}
      />
    </Stack.Navigator>
  )
}

export default AuthenticationNavigationStack
