import { Controller } from 'react-hook-form'
import { RhfDropdownModel } from './RhfDropdown.model'
import TextInput from '../inputs/TextInput'
import Spacer from '../Spacer'
import Typography from '../Typography'
import Dropdown from '../Dropdown'

const RhfDropdown = ({
  control,
  name,
  label,
  rules,
  optionsDefault,
  zIndex,
  zIndexInverse,
  hidden,
  multiple,
  itemKey,
}: RhfDropdownModel) => {
  return (
    <Controller
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Dropdown
            label={label}
            onChange={(valuesDropdown) => {
              if (valuesDropdown['value']) {
                onChange(valuesDropdown['value']) // Refacto Dropdown component to remove error
              } else {
                onChange(valuesDropdown)
              }
            }}
            multiple={multiple}
            defaultValue={value}
            optionsDefault={optionsDefault}
            zIndex={zIndex}
            zIndexInverse={zIndexInverse}
            itemKey={itemKey}
            hidden={hidden}
          />

          {error && (
            <>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              <Typography.Body colorName="color-danger">{error.message}</Typography.Body>
            </>
          )}
        </>
      )}
      name={name}
    />
  )
}

export default RhfDropdown
