import { Pagination } from '../../../domain'
import { ShelfLabel } from '../../../domain/ShelfLabel'
import ApiService from '../../api.service'

const getOne = async (id: string, isUser: boolean): Promise<ShelfLabel> => {
  const response = await ApiService.get(`/shelf-label/${id}`, { isUser })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const ShelfLabelPublicService = {
  getOne,
}

export default ShelfLabelPublicService
