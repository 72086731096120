import React from 'react'
import { useTheme } from 'styled-components/native'
import { ProducerTourOrdersTopBoxProps } from './ProducerTourOrdersTopBox.model'
import { CardParam } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { StyledTopBox } from './ProducerTourOrdersTopBox.styles'

function ProducerTourOrdersTopBox({ tour }: ProducerTourOrdersTopBoxProps) {
  const theme = useTheme()

  const getCarrierFeesPercentage = () => {
    const incomeProducer = parseFloat(tour.totalIncomeProducer || '0')
    const incomeClient = parseFloat(tour.totalIncome || '0')

    if (!incomeProducer || incomeProducer === 0) return 0
    return (incomeClient - incomeProducer) / incomeProducer
  }

  return (
    <>
      <StyledTopBox>
        <CardParam label={'Cagettes'} value={tour.nbCrates || 0} flex={4} isStart />
        <CardParam
          label={'Total prix producteur'}
          value={FormatUtils.formatPrice(tour.totalIncomeProducer) || 0}
          flex={4}
          isEnd
        />
        <CardParam
          label={'Commission Moyenne'}
          value={
            getCarrierFeesPercentage() > 0
              ? FormatUtils.formatPercentage(getCarrierFeesPercentage())
              : '-'
          }
          flex={4}
          isEnd
        />
        <CardParam
          label={'Total prix client'}
          value={FormatUtils.formatPrice(tour.totalIncome) || 0}
          flex={4}
          isEnd
        />
      </StyledTopBox>
    </>
  )
}

export default ProducerTourOrdersTopBox
