import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { CardPageLink, PageTitle } from '../../../components'

const ClientBillingScreen = () => {
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Gestion financière" />
        <CardPageLink
          label={`Relevés`}
          icon="arrow-right"
          onClick={() => navigation.navigate('InvoiceStatementList')}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientBillingScreen
