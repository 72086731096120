import { I18n } from 'i18n-js'
import { EN } from './en'
import { FR } from './fr'

const translations = {
  fr: FR,
  en: EN,
}

const i18n = new I18n(translations)

export default i18n
