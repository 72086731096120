import { SectionTitle, Spacer, Typography } from '../../../../../components'
import { ProducerTourOrdersProps } from './ProducerTourOrders.model'
import { Alert, View } from 'react-native'
import { Cart, Client, Order, Tour } from '../../../../../domain'
import { useNavigation } from '@react-navigation/native'
import { ProducerStackParamList } from '../../../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { useTheme } from 'styled-components/native'
import { StackNavigationProp } from '@react-navigation/stack'
import ClientOrderCard from '../../../../../modules/Client/ClientOrderCard'
import { UserEnums } from '../../../../../../enums'
import CartProducerService from '../../../../../services/producer/cart'
import StorageUtil from '../../../../../utilities/storage/storage'
import { useState } from 'react'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import { GroupEnums } from '../../../../../../enums/group'
import { queryClient } from '../../../../../utilities/queryClient'

const ProducerTourOrders = ({ tour }: ProducerTourOrdersProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()

  const stops = tour.stops || []
  const emptyOrder: Order[] = []
  const clients = stops.map((s) => {
    return { ...s.client, orders: [...emptyOrder] }
  })

  const carts = tour.carts

  carts?.map((cart) => {
    const clientIndex = clients.findIndex((client) => client?.id === cart.client?.id)

    if (clientIndex > -1) {
      const cartWithoutOrder = { ...cart }
      delete cartWithoutOrder.order
      const order: Order = {
        ...cart.order,
        cart: cartWithoutOrder,
      }

      clients[clientIndex].orders.push(order)
    }
  })

  const onPressOrder = (cart?: Cart) => {
    navigation.navigate('ProducerTourOrder', { cartId: cart?.id, tourId: tour.id })
  }

  const clientWithoutOrder = clients.filter((c) => c.orders.length < 1)

  const onCreateNewOrder = async (client: Client) => {
    if (!client.id) return
    const createdCart = await CartProducerService.create(client.id, getAccessInfos().producerId, {
      tourId: tour.id,
    })
    if (createdCart && createdCart.id) {
      await StorageUtil.setItem('p_cart_id', createdCart.id)
      await StorageUtil.setItem('p_cart_is_new_order', createdCart.id)
      const now = new Date()
      now.setMinutes(now.getMinutes() + 60)
      await StorageUtil.setItem('p_cart_id_expiration_date', now)
      navigation.navigate('ProducerUpdateCart', { cartId: createdCart.id, tourId: tour.id })
    } else {
      Alert.alert('Erreur lors de la création du panier')
    }
  }

  return (
    <>
      <SectionTitle title={`Commandes`} noMarginTop />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      {carts && carts?.length < 1 && (
        <View style={{ width: '100%', alignItems: 'center' }}>
          <Typography.BodySmall colorName="color-grey">
            Aucune commande pour le moment.
          </Typography.BodySmall>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        </View>
      )}
      {clients
        .filter((c) => c.orders && c.orders.length > 0)
        .map((c) => (
          <ClientOrderCard
            userRole={UserEnums.RoleEnums.PRODUCER}
            client={c}
            orders={c.orders}
            onPressOrder={onPressOrder}
            key={c.id}
          />
        ))}
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      <SectionTitle title={`Clients sans commande`} noMarginTop />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      {clientWithoutOrder.length < 1 && (
        <View style={{ width: '100%', alignItems: 'center' }}>
          <Typography.BodySmall colorName="color-grey">
            Aucun client sans commande.
          </Typography.BodySmall>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        </View>
      )}
      {clients
        .filter((c) => c.orders && c.orders.length < 1)
        .map((c) => (
          <ClientOrderCard
            userRole={UserEnums.RoleEnums.PRODUCER}
            client={c}
            key={c.id}
            onCreateNewOrder={onCreateNewOrder}
            canCreateNewOrder={
              tour?.group?.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS &&
              new Date() < new Date(tour?.close)
            }
          />
        ))}
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
    </>
  )
}

export default ProducerTourOrders
