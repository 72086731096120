import React from 'react'
import { useWindowDimensions, View } from 'react-native'
import useGridStyles from './Grid.styles'

interface IProps {
  children: React.ReactNode
  xs?: number
  sm?: number
  md?: number
  lg?: number
  colStyles?: any
  xsAlignItems?: 'flex-start' | 'center' | 'flex-end'
  smAlignItems?: 'flex-start' | 'center' | 'flex-end'
  mdAlignItems?: 'flex-start' | 'center' | 'flex-end'
  lgAlignItems?: 'flex-start' | 'center' | 'flex-end'
  alignItems?: 'flex-start' | 'center' | 'flex-end'
  justifyContent?: 'flex-start' | 'center' | 'flex-end'
}

const Col = ({
  children,
  xs,
  sm,
  md,
  lg,
  colStyles,
  xsAlignItems,
  smAlignItems,
  mdAlignItems,
  lgAlignItems,
  alignItems,
  justifyContent,
}: IProps) => {
  const columns_xs = 'col_' + xs
  const columns_sm = 'col_sm_' + (sm || xs)
  const columns_md = 'col_md_' + (md || sm || xs)
  const columns_lg = 'col_lg_' + (lg || md || sm || xs)

  const gridStyles = useGridStyles()

  const windowDimensions = useWindowDimensions()
  const windowWidth = windowDimensions.width

  const isXS = windowWidth > 0 && windowWidth < 500
  const isSM = windowWidth > 499 && windowWidth < 800
  const isMD = windowWidth > 799 && windowWidth < 1100
  const isLG = windowWidth > 1099

  let alignItemsValue = alignItems || 'flex-start'
  alignItemsValue = isXS && xsAlignItems ? xsAlignItems : alignItemsValue
  alignItemsValue = isSM && smAlignItems ? smAlignItems : alignItemsValue
  alignItemsValue = isMD && mdAlignItems ? mdAlignItems : alignItemsValue
  alignItemsValue = isLG && lgAlignItems ? lgAlignItems : alignItemsValue

  return (
    <View
      style={[
        gridStyles[columns_xs as keyof typeof gridStyles],
        gridStyles[columns_sm as keyof typeof gridStyles],
        gridStyles[columns_md as keyof typeof gridStyles],
        gridStyles[columns_lg as keyof typeof gridStyles],
        colStyles,
        {
          alignItems: alignItemsValue || 'flex-start',
          justifyContent: justifyContent || 'flex-start',
          zIndex: 'auto',
        },
      ]}
    >
      {children}
    </View>
  )
}

export default Col
