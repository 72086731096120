import { format } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { Keyboard, TouchableWithoutFeedback } from 'react-native'
import DateTimePickerModal from 'react-native-modal-datetime-picker'
import { useTheme } from 'styled-components/native'
import { stringToKey } from '../../../utilities/helpers'
import TextInputLabel from '../../inputs/TextInputLabel'
import Typography from '../../Typography'
import { DatePickerMobilePropTypes } from './DatePickerMobile.model'
import { StyledDateValue, StyledPickerWrapper } from './DatePickerMobile.styles'

function DatePickerMobile({
  label,
  onChange,
  value,
  required,
  hasError,
  onOpen,
  mode,
  outputFormat,
}: DatePickerMobilePropTypes) {
  const theme = useTheme()
  const [dateValue, setDateValue] = useState<Date>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [borderColor, setBorderColor] = useState(theme.colors['color-primary'])
  const [backgroundColor, setBackgroundColor] = useState(theme.colors['color-input'])
  const isActive = useMemo(() => {
    return !!value
  }, [value])

  useEffect(() => {
    if (hasError) {
      setBorderColor(theme.colors['color-danger'])
      setBackgroundColor(theme.colors['color-input'])
    } else {
      setBorderColor(theme.colors['color-primary'])
      setBackgroundColor(theme.colors['color-input'])
    }
  }, [hasError, theme])

  useEffect(() => {
    if (value) {
      setDateValue(new Date(value))
    } else {
      setDateValue(undefined)
    }
  }, [value])

  const openModal = () => {
    Keyboard.dismiss()
    setIsModalOpen(true)
    setBorderColor(theme.colors['color-primary'])
    setTimeout(() => {
      onOpen?.()
    }, 300)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    // setBorderColor(theme.colors.transparent)
  }

  const formatDate = 'yyyy-MM-dd HH:mm:ss'
  const formatView = (): string => {
    if (mode === 'datetime') {
      return 'dd/MM/yyyy HH:mm'
    }

    if (mode === 'time') {
      return 'HH:mm'
    }

    return 'dd/MM/yyyy'
  }

  const handleDateChange = (date: Date) => {
    if (outputFormat && outputFormat === 'date') {
      onChange(new Date(date))
      closeModal()
      return
    }
    const formattedDate = format(date, formatDate)

    onChange(formattedDate ?? null)
    closeModal()
  }

  const dateToPrint = useMemo(() => {
    if (dateValue) {
      return format(dateValue, formatView())
    }
    return ''
  }, [dateValue, formatView])

  return (
    <TouchableWithoutFeedback onPress={openModal} testID={`${stringToKey(label)}-date-picker`}>
      <StyledPickerWrapper borderColor={borderColor} backgroundColor={backgroundColor}>
        <TextInputLabel editable label={label} isActive={isActive} required={required} />
        {dateValue ? (
          <StyledDateValue>
            <Typography.Body>{dateToPrint}</Typography.Body>
          </StyledDateValue>
        ) : null}
        <DateTimePickerModal
          isVisible={isModalOpen}
          mode={mode || 'date'}
          date={dateValue}
          onConfirm={handleDateChange}
          onCancel={closeModal}
          themeVariant="light"
          buttonTextColorIOS={theme.colors['text-dark-1']}
          // backdropStyleIOS={{ opacity: 0 }}
        />
      </StyledPickerWrapper>
    </TouchableWithoutFeedback>
  )
}

export default DatePickerMobile
