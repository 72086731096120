import { AddBatch, Batch, Product, UpdateBatch, Group } from '../../domain'

import { BatchEnums } from '../../../enums'
import FormatUtils from './format'
import { InterfaceColors } from '../styling/theme/theme'

const getBatchComputed = (batch: Batch): Batch => {
  const periodLabel = getBatchPeriodLabel(batch)

  const tempBatch = { ...batch }
  if (tempBatch.computed) {
    tempBatch.computed.periodLabel = periodLabel
    tempBatch.computed.priceParams = getBatchPricesParams(batch)
  } else {
    tempBatch.computed = {
      periodLabel: periodLabel,
    }
  }

  return tempBatch
}

const getBatchPricesParams = (
  batch: Batch,
): {
  producerPriceWithPlatformFeesFormatted: string
  producerPricesFormatted: string
  producerPricesColor: keyof InterfaceColors
  producerPriceGainPercentage: string
  unitPricesFormatted: string
  carrierFeesFormatted: string
} => {
  const priceParams = {
    producerPriceWithPlatformFeesFormatted: '',
    producerPricesFormatted: '',
    producerPricesColor: 'text-dark-1' as keyof InterfaceColors,
    producerPriceGainPercentage: '',
    unitPricesFormatted: '',
    carrierFeesFormatted: '',
  }

  const unitPriceProducer = batch.unitPriceProducer
  if (unitPriceProducer) {
    if (batch.computed?.platformFeesPercentage) {
      priceParams.producerPriceWithPlatformFeesFormatted = FormatUtils.formatPrice(
        unitPriceProducer - (unitPriceProducer * batch.computed?.platformFeesPercentage) / 100,
      )
    }
    priceParams.producerPricesFormatted = FormatUtils.formatPrice(
      unitPriceProducer,
      undefined,
      batch.product?.producer,
    )
  }

  const minProducerPrice = batch.computed?.pricesStats?.producerPrice.min
  const maxProducerPrice = batch.computed?.pricesStats?.producerPrice.max
  if (minProducerPrice) {
    priceParams.producerPricesFormatted = `${FormatUtils.formatPrice(minProducerPrice)}${
      minProducerPrice !== maxProducerPrice ? ` à ${FormatUtils.formatPrice(maxProducerPrice)}` : ''
    }${FormatUtils.getPriceUnits(undefined, batch.product?.producer)}`

    const priceGainMin = 1 - unitPriceProducer / (minProducerPrice || 1)
    const priceGainMax = 1 - unitPriceProducer / (maxProducerPrice || 1)

    if (priceGainMin > 0 || priceGainMax > 0) {
      priceParams.producerPriceGainPercentage = `+${FormatUtils.formatPercentage(priceGainMin)}${
        priceGainMax && priceGainMin !== priceGainMax
          ? ` à +${FormatUtils.formatPercentage(priceGainMax)}`
          : ''
      }`
    }
  }

  const minUnitPrice = batch.computed?.pricesStats?.unitPrice.min
  const maxUnitPrice = batch.computed?.pricesStats?.unitPrice.max
  if (minUnitPrice) {
    priceParams.unitPricesFormatted = `${FormatUtils.formatPrice(minUnitPrice)}${
      minUnitPrice !== maxUnitPrice ? ` à ${FormatUtils.formatPrice(maxUnitPrice)}` : ''
    }${FormatUtils.getPriceUnits(undefined, batch.product?.producer)}`
  }

  if (batch.computed?.pricesStats?.producerPriceBellow.min) {
    priceParams.producerPricesColor = 'color-warning'
  }

  const minCarrierFees = batch.computed?.pricesStats?.carrierFees.min
  const maxCarrierFees = batch.computed?.pricesStats?.carrierFees.max
  if (minCarrierFees !== undefined) {
    priceParams.carrierFeesFormatted = `${FormatUtils.formatPercentage(minCarrierFees / 100)}${
      maxCarrierFees && minCarrierFees !== maxCarrierFees
        ? ` à ${FormatUtils.formatPercentage(maxCarrierFees / 100)}`
        : ''
    }`
  }

  return priceParams
}

const getBatchPeriodLabel = (batch: Batch) => {
  if (!batch.start || !batch.end) {
    return 'NA'
  }

  batch = FormatUtils.removeUTCFromBatchDate(batch)

  const daysChar = ['D', 'L', 'Ma', 'Me', 'J', 'Ve', 'Sa']
  const start = new Date(batch.start)
  const end = new Date(batch.end)
  const diff = end.getTime() - start.getTime()
  const diffDays = Math.ceil((diff / (1000 * 3600 * 24)) * 10) / 10

  let periodLabel = `du ${FormatUtils.formatDate(
    batch.start,
    'DateShort',
  )} au ${FormatUtils.formatDate(batch.end, 'DateShort')}`

  if (batch.type === BatchEnums.TYPE.DAY && diffDays <= 1) {
    periodLabel = FormatUtils.capitalize(FormatUtils.formatDate(batch.start, 'FullDate'))
  }

  if (
    batch.type === BatchEnums.TYPE.WEEK &&
    diffDays < 7 &&
    FormatUtils.startOfWeekDate(start) === FormatUtils.startOfWeekDate(end)
  ) {
    let daysLetters = ''
    const cursorDate = new Date(start)

    for (let i = 0; i < 7; i++) {
      if (cursorDate <= end) {
        const dateChar = cursorDate.getDay()
        daysLetters = `${daysLetters === '' ? daysLetters : `${daysLetters}-`}${
          daysChar[dateChar] || 'NA'
        }`
        cursorDate.setDate(cursorDate.getDate() + 1)
      }
    }
    periodLabel = daysLetters
  }

  return periodLabel
}

const getPriceInformation = (
  batchComputed: Batch,
  selectedProduct: Product,
  newBatch: AddBatch | UpdateBatch,
) => {
  if (
    !batchComputed ||
    !selectedProduct ||
    !newBatch.unitPriceProducer ||
    !(parseFloat(newBatch.unitPriceProducer) > 0)
  )
    return null

  const nbTours = batchComputed?.computed?.carriersFeesParams?.carrierFees?.length || 0

  const productHasPriceStatement = !batchComputed?.computed?.statusMessage?.find(
    (sm) => sm.type === BatchEnums.BatchStatusMessageTypeEnum.MISSING_PRICE_STATEMENT,
  )

  const priceGain = batchComputed.computed?.priceParams?.producerPriceGainPercentage

  let productMinPriceStatement = ''
  let description = ''
  let feesStatement = ''
  let clientPriceStatement = ''

  if (!productHasPriceStatement) {
    productMinPriceStatement = `Le produit "${
      selectedProduct?.label
    }" sera proposé lors de ${FormatUtils.pluralizeAmountLabel(
      'tournée',
      nbTours,
    )} à ${FormatUtils.formatPrice(newBatch.unitPriceProducer)}/${FormatUtils.formatUnity(
      selectedProduct?.mesureType,
    )} (prix producteur). `
    feesStatement = `Les producteurs livreurs de ces tournées pratiquent des frais de ${batchComputed.computed?.priceParams?.carrierFeesFormatted}. `

    clientPriceStatement = `Le prix client sera de ${batchComputed.computed?.priceParams?.unitPricesFormatted}. `
  } else {
    productMinPriceStatement = `Le produit "${
      selectedProduct?.label
    }" sera proposé à au moins ${FormatUtils.formatPrice(
      newBatch.unitPriceProducer,
    )}/${FormatUtils.formatUnity(
      selectedProduct?.mesureType,
    )} prix producteur (votre prix minimum) lors de ${FormatUtils.pluralizeAmountLabel(
      'tournée',
      nbTours,
    )} de livraison. `

    description = `Actuellement il est proposé à un prix de ${
      batchComputed.computed?.priceParams?.producerPricesFormatted
    }/${FormatUtils.formatUnity(selectedProduct?.mesureType)} (prix producteur)`

    if (priceGain) {
      description += `, représentant un gain de marge de ${batchComputed.computed?.priceParams?.producerPriceGainPercentage} pour vous.`
    } else {
      description += '.'
    }

    clientPriceStatement = `Le prix client sera de ${batchComputed.computed?.priceParams?.unitPricesFormatted}. `

    if (nbTours === 1) {
      feesStatement = `Le producteur-livreur de cette tournée pratique`
    } else if (nbTours > 1) {
      feesStatement = `Les producteur-livreurs de ces tournées pratiquent`
    }
    feesStatement += ` des frais de ${batchComputed.computed?.priceParams?.carrierFeesFormatted}.  `
  }

  return {
    productMinPriceStatement,
    description,
    feesStatement,
    clientPriceStatement,
  }
}

const displayCrossedOutPrice = (batch: Batch, group: Group) => {
  return (
    (batch?.crossedOutPrice || 0) > 0 &&
    (batch?.crossedOutPrice || 0) - batch.unitPrice > 0.01 &&
    group.hasCrossedPriceFeature === true
  )
}

const BatchUtil = {
  getBatchComputed,
  getBatchPeriodLabel,
  getPriceInformation,
  displayCrossedOutPrice,
}

export default BatchUtil
