import React, { useMemo, useState } from 'react'
import { useTheme } from 'styled-components/native'
import ButtonSeeMore from '../ButtonSeeMore'
import Spacer from '../Spacer'
import Typography from '../Typography'
import { BottomSheetProps } from './BottomSheet.model'
import { StyledContent } from './BottomSheet.styles'
import { BottomSheet as ReactBottomSheet } from 'react-native-btr'
import Button from '../Button'
import { TouchableOpacity } from 'react-native-gesture-handler'

function BottomSheet({
  isOpen,
  onClose,
  children,
  button,
  buttonLabel,
  message,
  featureUnvailable,
}: BottomSheetProps) {
  const theme = useTheme()
  const [visible, setVisible] = useState(false)

  const onNotify = () => {
    // send featureUnvailable and user infos
  }

  const openBottomSheet = () => {
    setVisible(true)
  }

  const isVisible = useMemo(() => {
    if (isOpen !== undefined) {
      return isOpen
    }
    return visible
  }, [isOpen])

  const handleOnClose = () => {
    setVisible(false)
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      {button ? (
        <TouchableOpacity onPress={() => openBottomSheet()}>{button}</TouchableOpacity>
      ) : (
        <Button small label={buttonLabel} onPress={() => openBottomSheet()} />
      )}
      <ReactBottomSheet
        visible={isVisible}
        //setting the visibility state of the bottom shee
        onBackButtonPress={() => handleOnClose()}
        //Toggling the visibility state on the click of the back botton
        onBackdropPress={() => handleOnClose()}
        //Toggling the visibility state on the clicking out side of the sheet
      >
        <StyledContent>
          {message ? <Typography.Body>{message}</Typography.Body> : null}
          {children}
          {featureUnvailable ? (
            <>
              <Typography.CardTitle>Fonctionnalité indisponible</Typography.CardTitle>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            </>
          ) : null}
          {featureUnvailable ? (
            <Typography.Body>
              Cette fonctionnalité n'est pas encore disponible, contactez l'équipe Harvy, vous
              souhaitez le faire vous même ? Cliquez sur le bouton ci-dessous :{' '}
            </Typography.Body>
          ) : null}
          {featureUnvailable ? (
            <>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
              <Button
                small
                label={`J'ai besoin de cette fonctionnalité`}
                onPress={() => onNotify()}
              />
            </>
          ) : null}
        </StyledContent>
      </ReactBottomSheet>
    </>
  )
}

export default BottomSheet
