import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Col, PageTitle, Row, Spacer, TextInput, Typography } from '../../../components'
import Loader from '../../../components/Loader'
import { Address, Group, UpdateGroup } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierUpdateGroup.styles'
import GroupCarrierService from '../../../services/carrier/group'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { GroupEnums } from '../../../../enums/group'
import AddressForm from '../../../modules/Address/AddressForm'
import useGroupContext from '../../../utilities/hook/useGroupContext'

const CarrierUpdateGroupScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdateGroup'>>()
  const [isLoading, setIsLoading] = useState(false)
  const [updatedGroup, setUpdatedGroup] = useState<UpdateGroup>({
    id: undefined,
    label: '',
    hasPricingModule: undefined,
    type: undefined,
    hasFeesSharingModule: undefined,
  })
  const [selectedGroupType, setSelectedGroupType] = useState<any>()

  const onChangeGroupType = (value: any) => {
    setSelectedGroupType(value)
  }

  const [errorMessage, setErrorMessage] = useState<string>('')

  const theme = useTheme()

  const { group, updateGroup } = useGroupContext()

  useEffect(() => {
    if (group?.id) {
      setUpdatedGroup({
        id: group.id,
        label: group.label,
        hasPricingModule: group.hasPricingModule,
        type: group.type,
        hasFeesSharingModule: group.hasFeesSharingModule,
        address: group.address,
      })

      const type = GroupEnums.GROUP_TYPES.find((gt: any) => gt.value === group?.type)

      if (type) {
        setSelectedGroupType({
          ...type,
        })
      } else {
        setSelectedGroupType({
          label: 'Choisir une option',
          value: 0,
        })
      }
    }
  }, [group])

  if (!group) {
    return <Loader isLoading pageLoading />
  }

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in updatedGroup)) return null

    const groupTemp = Object.assign({}, updatedGroup)

    groupTemp[param as keyof typeof updatedGroup] = value
    setUpdatedGroup(groupTemp)
  }

  const onChangeIsPrivate = () => {
    if (updatedGroup?.hasPricingModule) {
      setValue(false, 'hasPricingModule')
      return
    }
    setValue(true, 'hasPricingModule')
  }
  const onChangeHasSharingFees = () => {
    if (updatedGroup?.hasFeesSharingModule) {
      setValue(false, 'hasFeesSharingModule')
      return
    }
    setValue(true, 'hasFeesSharingModule')
  }

  const onUpdateGroup = async () => {
    setErrorMessage('')
    setIsLoading(true)

    const groupToUpdate = {
      label: updatedGroup.label,
      hasPricingModule: updatedGroup.hasPricingModule,
      type: selectedGroupType.value,
      hasFeesSharingModule: updatedGroup.hasFeesSharingModule,
    }
    if (!isGroupTypeEditable()) {
      groupToUpdate.type = group.type
    }

    const updatedGroupTemp = await GroupCarrierService.update(group.id ?? '', groupToUpdate)
    if (updatedGroupTemp && updatedGroupTemp.id) {
      queryClient.invalidateQueries({ queryKey: ['sf_group', getAccessInfos().carrierGroupId] })
      updateGroup()
      navigation.goBack()
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour du groupe')
    }
    setIsLoading(false)
  }

  const isGroupTypeEditable = () => {
    if (
      group &&
      group.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS &&
      group.nbCarriers &&
      group.nbCarriers > 1
    ) {
      return false
    }
    return true
  }

  const onChangeAddress = (newAddress: Address) => {
    if (newAddress && newAddress.id) {
      const groupTemp = Object.assign({}, updatedGroup)

      groupTemp['address'] = newAddress
      setUpdatedGroup(groupTemp)
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title={`Informations du groupe`} />
          <ScrollableFormWrapper>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedGroup.label}
                  label="Nom du groupe"
                  field="text"
                  onChangeText={(text) => setValue(text, 'label')}
                />
              </Col>
            </Row>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <AddressForm
              onChangeAddress={onChangeAddress}
              address={updatedGroup.address}
              group={group as Group}
            />
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Button
              label={FormatUtils.capitalize(i18n.t('update'))}
              onPress={() => onUpdateGroup()}
              loading={isLoading}
              hasDoubleValidation
              confirmationLabel="Êtes-vous sûr de vouloir modifier ce groupe ?"
            />
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierUpdateGroupScreen
