import React from 'react'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import BadgeStatus from '../../../components/BadgeStatus'
import { ActionCardProps } from './ActionCard.model'
import {
  StyledArrowBox,
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
} from './ActionCard.styles'

const ActionCard = ({ title, description, onClick, badgeLabel }: ActionCardProps) => {
  const theme = useTheme()

  const OnClickAction = () => {
    onClick()
  }

  return (
    <StyledCardWrapper onPress={OnClickAction} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.BodySmall semiBold>{title}</Typography.BodySmall>
            <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />

            <Typography.BodyExtraSmall>{description}</Typography.BodyExtraSmall>
          </StyledLeftBox>
          <StyledRightBox>
            <StyledArrowBox>
              <BadgeStatus
                label={badgeLabel ? badgeLabel : 'Compléter'}
                color={'color-danger'}
              ></BadgeStatus>
              <Spacer size={0.3} axis={Spacer.AxisEnum.HORIZONTAL} />
              <Icon
                type={Icons.Ionicons}
                name="chevron-forward-outline"
                size={theme.spacingUnit * 2}
                color={theme.colors['text-dark-3']}
              />
            </StyledArrowBox>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default ActionCard
