import { View } from 'react-native'
import styled, { css } from 'styled-components/native'
import DeviceUtil from '../../utils/device'
import { ScrollView } from 'react-native-gesture-handler'

interface ContentWrapperProps {
  alignCenter?: boolean
  paddingTopLarge?: boolean
  paddingBottomLarge?: boolean
  noPaddingTop?: boolean
  noSidePadding?: boolean
  largeSidePadding?: boolean
  fullWidth?: boolean
  children?: any
  noHeader?: boolean
  noPaddingLeft?: boolean
  isDesktop?: boolean
  noDrawer?: boolean
  marginTopLarge?: boolean
  withoutScroll?: boolean
}

const StyledContentWrapperBox = styled.View<ContentWrapperProps>`
  flex: 1;
  width: 100%;
  max-width: 500px;
  margin: auto;
  justify-content: top;
  align-items: flex-start;
  padding-left: ${({ theme }) => theme.spacingUnit * 2}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 2}px;
  min-height: -webkit-fill-available;

  ${({ alignCenter }) =>
    alignCenter &&
    css`
      align-items: center;
    `};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: 100%;
    `};
`

const StyledSafeContentWrapper = styled.View<ContentWrapperProps>`
  background-color: ${({ theme }) => theme.colors['background-1']};
  flex: 1;
  border-radius: ${({ theme }) => theme.spacingUnit * 2.5}px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  ${({ noHeader }) =>
    noHeader &&
    css`
      border-radius: 0;
    `};

  ${({ isDesktop, noDrawer }) =>
    isDesktop &&
    !noDrawer &&
    css`
      padding-left: 245px;
    `}

  ${({ marginTopLarge }) =>
    marginTopLarge &&
    css`
      margin-top: ${({ theme }) => theme.spacingUnit * 4}px;
    `};
`

const StyledContentWrapper = styled.View<ContentWrapperProps>`
  background-color: ${({ theme }) => theme.colors['color-primary']};
  padding-top: ${({ theme }) => theme.spacingUnit * 0.2}px;
  flex: 1;
  width: 100%;

  ${({ noHeader }) =>
    noHeader &&
    css`
      background-color: ${({ theme }) => theme.colors['background-1']};
      padding-top: 0;
    `};
`
function ContentWrapper(props: ContentWrapperProps) {
  const isDesktop = !DeviceUtil.isMobileApp() && !props.noPaddingLeft

  const safeContentWrapperProps = {
    ...props,
    isDesktop: isDesktop,
  }

  if (props.withoutScroll) {
    return (
      <StyledContentWrapper {...props}>
        <StyledSafeContentWrapper {...safeContentWrapperProps}>
          <StyledContentWrapperBox {...props}>{props.children}</StyledContentWrapperBox>
        </StyledSafeContentWrapper>
      </StyledContentWrapper>
    )
  }

  return (
    <StyledContentWrapper {...props}>
      <StyledSafeContentWrapper {...safeContentWrapperProps}>
        <ScrollView
          style={{
            width: '100%',
            minHeight: 'max-content',
          }}
          contentContainerStyle={{
            minHeight: '-webkit-fill-available',
          }}
          bounces={false}
        >
          <StyledContentWrapperBox {...props}>{props.children}</StyledContentWrapperBox>
        </ScrollView>
      </StyledSafeContentWrapper>
    </StyledContentWrapper>
  )
}

export default ContentWrapper
