import styled from 'styled-components/native'
import { css } from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View<{ hasAmountBox?: boolean }>`
  margin: ${({ theme }) => theme.spacingUnit * 1.5}px;
  flex-direction: row;

  ${({ hasAmountBox }) =>
    hasAmountBox &&
    css`
      margin: ${({ theme }) => theme.spacingUnit * 0.7}px;
    `}
`

const StyledLeftBox = styled.View`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  padding: ${({ theme }) => theme.spacingUnit * 1}px;
  width: ${({ theme }) => theme.spacingUnit * 5}px;
  height: ${({ theme }) => theme.spacingUnit * 5}px;
  background-color: ${({ theme }) => theme.colors['background-1']};
  align-items: center;
  justify-content: center;
`

const StyledMiddleBox = styled.View`
  justify-content: center;
`

const StyledRightBox = styled.View`
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
`

export { StyledCardWrapper, StyledCardContent, StyledMiddleBox, StyledRightBox, StyledLeftBox }
