import { useQuery } from '@tanstack/react-query'
import { DeliveryNote, Order, Tour } from '../../../domain'
import ApiService from '../../api.service'

const getAll = async (tourId?: string, clientId?: string): Promise<DeliveryNote[]> => {
  if (!tourId) return []
  const response = await ApiService.get(`/delivery-note/client/${clientId}`, {
    tourId: tourId || null,
  })
    .then((result) => result.data)
    .catch((error) => {
      throw error
    })
  return response
}

const getOne = async (deliveryNoteId: string): Promise<DeliveryNote> => {
  const response = await ApiService.get(`/delivery-note/${deliveryNoteId}`)
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

function useGetAllDeliveryNotes(tourId?: string, clientId?: string, orderId?: string) {
  function getQueryKey() {
    return ['c_order_delivery_notes', tourId, clientId]
  }
  function query() {
    return useQuery<DeliveryNote[], Error>({
      queryKey: getQueryKey(),
      queryFn: () => getAll(tourId, clientId),
      enabled: orderId !== undefined,
    })
  }

  return {
    getQueryKey,
    query,
  }
}

const DeliveryNoteClientService = {
  // getAll,
  getOne,
  useGetAllDeliveryNotes,
}

export default DeliveryNoteClientService
