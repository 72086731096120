import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import Typography from '../Typography'
import { DateSwitcherProps } from './DateSwitcher.model'
import {
  ArrowBoxWrapperLeft,
  ArrowBoxWrapperRight,
  ButtonWrapper,
  LeftWhitespaceWrapper,
  MiddleBox,
  RightWhitespaceWrapper,
  StyledDateSwitcherWrapper,
} from './DateSwitcher.styles'
import { TouchableOpacity } from 'react-native-gesture-handler'
import Icon, { Icons } from '../Icon'
import FormatUtils from '../../utilities/utils/format'
import Spacer from '../Spacer'
import Button from '../Button'

function DateSwitcher({
  mode,
  dates,
  label,
  onChangeDates,
  onClickTodayButton,
}: DateSwitcherProps) {
  const theme = useTheme()
  const [currentDates, setCurrentDates] = useState<{ start: Date; end: Date }>(dates)

  useEffect(() => {
    setCurrentDates(dates)
  }, [dates])

  const onClickMetricsChangeDate = async (value?: number) => {
    let tempCurrentDates = { ...currentDates }
    let tempStartDate = tempCurrentDates.start
    let tempEndDate = tempCurrentDates.end

    if (mode === 'DAY') {
      tempStartDate.setDate(tempStartDate.getDate() + (value || 0))
      tempEndDate = new Date(tempStartDate)
    } else if (mode === 'WEEK') {
      tempStartDate.setDate(tempStartDate.getDate() + (value || 0) * 7)
      tempEndDate.setDate(tempEndDate.getDate() + (value || 0) * 7)
    } else if (mode === 'MONTH') {
      tempStartDate.setMonth(tempStartDate.getMonth() + (value || 0))
      tempEndDate.setMonth(tempEndDate.getMonth() + (value || 0))
    }

    // clean hours on start and end date
    tempStartDate.setHours(0)
    tempStartDate.setMinutes(0)
    tempStartDate.setSeconds(0)

    tempEndDate.setHours(23)
    tempEndDate.setMinutes(59)
    tempEndDate.setSeconds(59)

    tempCurrentDates = { start: tempStartDate, end: tempEndDate }

    await setCurrentDates(tempCurrentDates)
    await onChangeDates(tempCurrentDates)
  }

  const getCurrentLabel = () => {
    const diff = currentDates.start.getTime() - new Date().getTime()
    const diffDays = Math.ceil((diff / (1000 * 3600 * 24)) * 10) / 10

    if (mode === 'DAY') {
      if (diffDays > -1 && diffDays < 1) {
        return diffDays < 0 ? `Aujourd'hui` : 'Demain'
      }
      return FormatUtils.formatDate(currentDates.start.toString(), 'FullDate')
    }
    if (mode === 'WEEK') {
      if (
        FormatUtils.formatDate(currentDates.start.toString(), 'Week') ===
        FormatUtils.formatDate(new Date().toString(), 'Week')
      ) {
        return `Cette semaine`
      }
      return `${FormatUtils.formatDate(
        currentDates.start.toString(),
        'Week',
      )} (${FormatUtils.formatDate(
        currentDates.start.toString(),
        'DateShort',
      )} au ${FormatUtils.formatDate(currentDates.end.toString(), 'DateShort')})`
    }
    if (mode === 'MONTH') {
      return FormatUtils.formatDate(currentDates.start.toString(), 'MonthYear')
    }
    return 'NA'
  }

  //TODO: create an utils function ?
  const isToday = (date: Date) => {
    const today = new Date()
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear() &&
      mode === 'DAY'
    )
  }

  return (
    <StyledDateSwitcherWrapper>
      <LeftWhitespaceWrapper />
      <ArrowBoxWrapperLeft>
        <TouchableOpacity onPress={() => onClickMetricsChangeDate(-1)}>
          <Icon
            type={Icons.Ionicons}
            name="chevron-back-outline"
            size={theme.spacingUnit * 4}
            color={theme.colors['text-dark-3']}
          />
        </TouchableOpacity>
      </ArrowBoxWrapperLeft>
      <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.5} />
      <MiddleBox>
        <Typography.Body colorName="text-dark-1" bold align="center">
          {getCurrentLabel()}
        </Typography.Body>
        {label && (
          <Typography.BodySmall colorName="text-dark-3" bold align="center">
            {label}
          </Typography.BodySmall>
        )}
      </MiddleBox>
      <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.5} />
      <ArrowBoxWrapperRight>
        <TouchableOpacity onPress={() => onClickMetricsChangeDate(1)}>
          <Icon
            type={Icons.Ionicons}
            name="chevron-forward-outline"
            size={theme.spacingUnit * 4}
            color={theme.colors['text-dark-3']}
          />
        </TouchableOpacity>
      </ArrowBoxWrapperRight>
      <RightWhitespaceWrapper>
        {onClickTodayButton && !isToday(currentDates.start) && (
          <ButtonWrapper>
            <Button small onPress={() => onClickTodayButton()} colorName="text-dark-3">
              <Icon
                type={Icons.MaterialCommunityIcons}
                name="calendar-today"
                size={theme.spacingUnit * 2}
                color={theme.colors['text-light-1']}
              />
            </Button>
          </ButtonWrapper>
        )}
      </RightWhitespaceWrapper>
    </StyledDateSwitcherWrapper>
  )
}

export default DateSwitcher
