import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Dimensions } from 'react-native'
import CarrierAddTourScreen from '../../../screens/Carrier/CarrierAddTour'
import CarrierAddNewTourScreen from '../../../screens/Carrier/CarrierAddTour/CarrierAddNewTour'
import CarrierDuplicateNewTourScreen from '../../../screens/Carrier/CarrierAddTour/CarrierDuplicateNewTour'
import CarrierTourScreen from '../../../screens/Carrier/CarrierTour'
import CarrierTourClientsScreen from '../../../screens/Carrier/CarrierTourClients'
import CarrierTourDeliveryScreen from '../../../screens/Carrier/CarrierTourDelivery'
import CarrierTourDeliveryStopScreen from '../../../screens/Carrier/CarrierTourDeliveryStop'
import CarrierTourProducerScreen from '../../../screens/Carrier/CarrierTourProducer'
import CarrierTourProducersScreen from '../../../screens/Carrier/CarrierTourProducers'
import Header from '../../components/Header'
import CarrierToursScreen from '../../../screens/Carrier/CarrierTours'
import HeaderHome from '../../components/HeaderHome'
import TourCarrierNavigationStackParamList from './TourCarrierNavigation.model'
import CarrierTourClientScreen from '../../../screens/Carrier/CarrierTourClient'
import CarrierUpdateTourScreen from '../../../screens/Carrier/CarrierUpdateTour'
import CarrierAddStopScreen from '../../../screens/Carrier/CarrierAddStop'
import CarrierTourBatchsScreen from '../../../screens/Carrier/CarrierTourBatchs'
import CarrierTourCratesScreen from '../../../screens/Carrier/CarrierTourCrates'
import CarrierTourDeliveryNotesScreen from '../../../screens/Carrier/CarrierTourDeliveryNotes'
import CarrierTourDeliveryNoteScreen from '../../../screens/Carrier/CarrierTourDeliveryNote'
import CarrierUpdateCartBatchScreen from '../../../screens/Carrier/CarrierUpdateCartBatch'
import CarrierAddCartBatchScreen from '../../../screens/Carrier/CarrierAddCartBatch'
import CarrierUpdateBatchScreen from '../../../screens/Carrier/CarrierUpdateBatch'
import CarrierTourSendMailScreen from '../../../screens/Carrier/CarrierTourSendMail'
import CarrierTourDeliveryNoteSendMailScreen from '../../../screens/Carrier/CarrierTourDeliveryNoteSendMail'
import CarrierTourAddProducerScreen from '../../../screens/Carrier/CarrierTourAddProducer'
import CarrierTourPrintStickerScreen from '../../../screens/Carrier/CarrierTourPrintSticker'
import CarrierArrivalPreparationScreen from '../../../screens/Carrier/CarrierArrivalPreparation'
import CarrierConcludeTourScreen from '../../../screens/Carrier/CarrierConcludeTour'
import CarrierUpdateCart from '../../../screens/Carrier/CarrierUpdateCart'
import CarrierAddCartBatchScreenV2 from '../../../screens/Carrier/CarrierAddCartBatchV2'
import CarrierConcludeToursScreen from '../../../screens/Carrier/CarrierConcludeTours'
import CarrierTourAddProducers from '../../../screens/Carrier/CarrierTourAddProducers'
import CarrierTourDeliveryExpenses from '../../../screens/Carrier/CarrierTourDeliveryExpenses'
import CarrierTourAddClients from '../../../screens/Carrier/CarrierTourAddClients'

const Stack = createStackNavigator<TourCarrierNavigationStackParamList>()

const TourCarrierNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="CarrierTours"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="CarrierTours"
        component={CarrierToursScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Tournées',
        }}
      />
      <Stack.Screen
        name="CarrierTour"
        component={CarrierTourScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Tournée',
        }}
      />
      <Stack.Screen
        name="CarrierTourProducers"
        component={CarrierTourProducersScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Tournée - producteurs',
        }}
      />
      <Stack.Screen
        name="CarrierTourBatchs"
        component={CarrierTourBatchsScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Tournée - lots',
        }}
      />
      <Stack.Screen
        name="CarrierTourDeliveryNotes"
        component={CarrierTourDeliveryNotesScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Tournée - bons de livraison',
        }}
      />
      <Stack.Screen
        name="CarrierTourDeliveryNote"
        component={CarrierTourDeliveryNoteScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Tournée - bon de livraison',
        }}
      />
      <Stack.Screen
        name="CarrierTourDeliveryNoteSendMail"
        component={CarrierTourDeliveryNoteSendMailScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Tournée - bon de livraison',
        }}
      />
      <Stack.Screen
        name="CarrierTourCrates"
        component={CarrierTourCratesScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Tournée - colis',
        }}
      />
      <Stack.Screen
        name="CarrierReceptionPreparation"
        component={CarrierArrivalPreparationScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Tournée - colis',
        }}
      />
      <Stack.Screen
        name="CarrierTourClients"
        component={CarrierTourClientsScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Tournée - clients',
        }}
      />
      <Stack.Screen
        name="CarrierTourClient"
        component={CarrierTourClientScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTourClients" />,
          title: 'Tournée - client',
        }}
      />
      <Stack.Screen
        name="CarrierTourProducer"
        component={CarrierTourProducerScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Tournée - producteur',
        }}
      />
      <Stack.Screen
        name="CarrierTourDelivery"
        component={CarrierTourDeliveryScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Tournée - livraison',
        }}
      />
      <Stack.Screen
        name="CarrierTourDeliveryStop"
        component={CarrierTourDeliveryStopScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Tournée - arrêt de livraison',
        }}
      />
      <Stack.Screen
        name="CarrierAddTour"
        component={CarrierAddTourScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Tournée - ajouter',
        }}
      />
      <Stack.Screen
        name="CarrierAddStop"
        component={CarrierAddStopScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTour" />,
          title: 'Tournée - ajouter un arrêt',
        }}
      />
      <Stack.Screen
        name="CarrierTourAddProducer"
        component={CarrierTourAddProducerScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTour" />,
          title: 'Tournée - ajouter un producteur',
        }}
      />
      <Stack.Screen
        name="CarrierAddNewTour"
        component={CarrierAddNewTourScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Tournée - nouvelle tournée',
        }}
      />
      <Stack.Screen
        name="CarrierDuplicateNewTour"
        component={CarrierDuplicateNewTourScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Tournée - dupliquer',
        }}
      />
      <Stack.Screen
        name="CarrierUpdateTour"
        component={CarrierUpdateTourScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Tournée - modifier',
        }}
      />
      <Stack.Screen
        name="CarrierConcludeTour"
        component={CarrierConcludeTourScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Tournée - Cloturer',
        }}
      />
      <Stack.Screen
        name="CarrierUpdateCartBatch"
        component={CarrierUpdateCartBatchScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Modifier Commande',
        }}
      />
      <Stack.Screen
        name="CarrierAddCartBatch"
        component={CarrierAddCartBatchScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Modifier Commande',
        }}
      />
      <Stack.Screen
        name="CarrierUpdateBatch"
        component={CarrierUpdateBatchScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Modifier un lot',
        }}
      />
      <Stack.Screen
        name="CarrierTourSendMail"
        component={CarrierTourSendMailScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Envoyer Email Tournée',
        }}
      />
      <Stack.Screen
        name="CarrierTourPrintSticker"
        component={CarrierTourPrintStickerScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Imprimer Sticker Tournée',
        }}
      />
      <Stack.Screen
        name="CarrierTourAddProducers"
        component={CarrierTourAddProducers}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Invitez des producteurs',
        }}
      />
      <Stack.Screen
        name="CarrierTourAddClients"
        component={CarrierTourAddClients}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Invitez vos clients',
        }}
      />
      <Stack.Screen
        name="CarrierUpdateCart"
        component={CarrierUpdateCart}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
        }}
      />
      <Stack.Screen
        name="CarrierAddCartBatchV2"
        component={CarrierAddCartBatchScreenV2}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
        }}
      />

      <Stack.Screen
        name="CarrierTourDeliveryExpenses"
        component={CarrierTourDeliveryExpenses}
        options={{
          headerShown: true,
          header: () => (
            <Header title="Estimer vos frais de livraison" goBackFallback="CarrierTours" />
          ),
          title: 'Estimer vos frais de livraison',
        }}
      />
    </Stack.Navigator>
  )
}

export default TourCarrierNavigation
