import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { Button, Spacer } from '../../../../../components'
import { AppStackParamList } from '../../../../../navigation/AppNavigationStack/AppNavigationStack.model'
import useUserContext from '../../../../../utilities/hook/useUserContext'
import {
  ContentWrapper,
  FormKeyboardWrappers,
  ScreenSafeAreaWrapper,
} from '../../../../../utilities/styling/wrappers'

function UserEmail() {
  const { user, loadUser } = useUserContext()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const redirectOnSuccess = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('UserLoginSecurity' as any, {})
    }
  }

  /*
  const { mutate: saveEmail } = useMutation(
    (userUpdated: User): Promise<void> => UserService.update(userUpdated),
    {
      onSuccess: () => {
        Toast.show({
          type: 'success',
          text1: 'Email updated successfully',
        })
        loadUser()
        redirectOnSuccess()
      },
      onError: (error) => {
        Toast.show({
          type: 'error',
          text1: 'Error updating email',
        })
      },
    },
  )
  */

  const save = async () => {
    if (!user) {
      return
    }

    const userUpdated = { ...user }

    // saveEmail(userUpdated)
  }

  return (
    <ScreenSafeAreaWrapper>
      <FormKeyboardWrappers>
        <ContentWrapper>
          <Button label="Save changes" onPress={save} fullWidth />
          <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        </ContentWrapper>
      </FormKeyboardWrappers>
    </ScreenSafeAreaWrapper>
  )
}

export default UserEmail
