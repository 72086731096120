import styled from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 1.3}px;
  flex-direction: row;
`

const StyledLeftBox = styled.View`
  // flex: 2;
  margin-right: ${({ theme }) => theme.spacingUnit * 1.3}px;
`

const StyledImage = styled.Image`
  width: ${({ theme }) => theme.spacingUnit * 7}px;
  height: ${({ theme }) => theme.spacingUnit * 7}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
`

const StyledCenterBox = styled.View`
  flex: 4;
`

const StyledRightBox = styled.View`
  flex: 2;
  align-items: flex-end;
  justify-content: space-between;
`

const StyledValuesWrapper = styled.View`
  flex-direction: row;
`

const StyledValueBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 0.3}px;
  justify-content: center;
`

const TextInlineBox = styled.View`
  flex-direction: row;
`

export {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledImage,
  StyledCenterBox,
  StyledRightBox,
  StyledValuesWrapper,
  StyledValueBox,
  TextInlineBox,
}
