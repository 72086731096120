import TourStepperProducer from '../../../../../modules/Tour/TourStepperProducer'
import { ProducerTourOrganizationProps } from './ProducerTourOrganization.model'
import { Button, SectionTitle, Spacer, Typography } from '../../../../../components'
import CardSettings from '../../../../../components/CardSettings'
import GeneralUtils from '../../../../../utilities/utils/general'
import TourCarrierService from '../../../../../services/carrier/tour'
import { queryClient } from '../../../../../utilities/queryClient'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import FormatUtils from '../../../../../utilities/utils/format'

const ProducerTourOrganization = ({
  tour,
  tourProducerBatchs,
  onCardClick,
}: ProducerTourOrganizationProps) => {
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()

  if (!tour) return null

  const producerId = getAccessInfos().producerId

  const onClickRemoveProducer = async () => {
    if (!tour || !tour.id) return
    await TourCarrierService.removeProducer(tour.id, producerId)
    await queryClient.invalidateQueries(['p_tour', tour.id])
    navigation.navigate('ProducerDashboard', {})
  }

  const canLeaveTour = () => {
    if (!tour) return false
    const tourOpen = tour.open && GeneralUtils.isDateBefore(new Date(tour.open), new Date())
    const hasOrders = tour.nbOrders

    if (!tourOpen && !hasOrders) {
      return true
    }

    return false
  }

  return (
    <>
      <TourStepperProducer tour={tour} tourBatchs={tourProducerBatchs} onCardClick={onCardClick} />
      <SectionTitle title="Actions" />
      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      <CardSettings
        title="Quitter la tournée"
        description={
          canLeaveTour()
            ? 'Seulement si les commandes ne sont pas encore ouvertes.'
            : `Vous ne pouvez pas quitter la tournée car ${
                tour.nbOrders && tour.nbOrders > 0
                  ? `vous avez déjà ${FormatUtils.pluralizeAmountLabel('commande', tour.nbOrders)}`
                  : 'les commandes sont ouvertes'
              }.`
        }
      >
        <Button
          label="Quitter"
          colorName="color-grey"
          onPress={() => onClickRemoveProducer()}
          disabled={!canLeaveTour()}
        />
      </CardSettings>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
    </>
  )
}

export default ProducerTourOrganization
