import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { ClientTypeEnum } from '../../../../enums'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import i18n from '../../../i18n'
import FormatUtils from '../../../utilities/utils/format'
import { CarrierCardProps } from './CarrierCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
} from './CarrierCard.styles'
import { CarrierGroup, User, UserCarrier } from '../../../domain'
import { LABELS_TYPES } from '../../../../enums/label'

const CarrierCard = ({ group, carrier, onClick }: CarrierCardProps) => {
  const theme = useTheme()

  const OnClickClient = () => {
    if (onClick) {
      onClick(carrier)
    }
  }

  const getLastUpdateUser = () => {
    const userCarrierDb = carrier?.userCarriers || []
    const users: User[] = []
    userCarrierDb.map((up) => {
      if (up.user) {
        users.push(up.user)
      }
    })

    if (users && users.length > 0 && users[0]) {
      users.sort((a, b) => (new Date(a.updatedDate || '') > new Date(b.updatedDate || '') ? -1 : 1))
      return users[0].updatedDate
    }
    return undefined
  }

  return (
    <StyledCardWrapper onPress={OnClickClient} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.CardTitle ellipsis>{carrier.name}</Typography.CardTitle>
            <Typography.BodySmall colorName="color-primary" bold></Typography.BodySmall>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3">
              {carrier.city && `${carrier.city} - `}
              {FormatUtils.pluralizeAmountLabel('utilisateur', carrier.userCarriers?.length || 0)}
            </Typography.BodySmall>
          </StyledLeftBox>
          <StyledRightBox>
            <View style={{ alignItems: 'flex-end' }}>
              <Typography.BodySmall colorName="text-dark-3" style={{ alignSelf: 'flex-end' }}>
                Dernière Connexion
              </Typography.BodySmall>
              <Typography.BodySmall>
                {FormatUtils.formatDate(getLastUpdateUser(), 'Date')}
              </Typography.BodySmall>
            </View>
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              size={theme.spacingUnit * 2}
              color={theme.colors['text-dark-3']}
            />
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default CarrierCard
