import {
  Button,
  DynamicImage,
  Icon,
  Icons,
  Spacer,
  TouchableCard,
  Typography,
} from '../../../components'
import { ClientOrderCardProps } from './ClientOrderCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledCardWrapperUnclickable,
  StyledContentBox,
  StyledMiddleBox,
  StyledLeftBox,
  StyledRightBox,
  StyledRightBoxDetails,
  StyledSubCard,
  StyledSubCardContent,
  StyledSubCardLeftContent,
  StyledSubCardRightContent,
} from './ClientOrderCard.style'
import FormatUtils from '../../../utilities/utils/format'
import { Cart, CartBatch, Client, Order } from '../../../domain'
import { useTheme } from 'styled-components/native'
import { UserEnums } from '../../../../enums'
import DeviceUtil from '../../../utilities/utils/device'

const ClientOrderCard = ({
  client,
  orders,
  onPressOrder,
  userRole,
  onCreateNewOrder,
  canCreateNewOrder,
}: ClientOrderCardProps) => {
  const theme = useTheme()

  const getStats = (client?: Client) => {
    let totalPackingQuantity = 0
    let totalHT = 0

    if (client && client.orders) {
      client.orders.forEach((order: Order | undefined) => {
        if (order?.cart && order?.cart.cartBatchs) {
          order?.cart.cartBatchs.forEach((cartBatch: CartBatch) => {
            totalPackingQuantity += cartBatch.packingQuantity || 0
            totalHT += (cartBatch.unitPriceProducer || 0) * (cartBatch.totalQuantity || 0)
          })
        }
      })
    }

    return {
      totalPackingQuantity,
      totalHT,
    }
  }

  const isSingleOrder = () => {
    if (orders && orders.length > 1) {
      return false
    }
    return true
  }

  const getOrderIdFormatted = (order?: Order) => {
    if (order && order.id && order.id.length > 5) {
      return order.id.slice(-5)
    }
    return 'NA'
  }

  const onClickOrder = (cart?: Cart) => {
    if (onPressOrder && cart) {
      onPressOrder(cart)
    }
  }

  const isDisabled = ((orders && orders.length < 1) || orders === undefined) && !canCreateNewOrder

  const isMobile = DeviceUtil.isMobileApp()

  const renderSubOrders = (orders: (Order | undefined)[] | undefined) => {
    if (orders && orders.length > 1) {
      return (
        <>
          {orders?.map((order) => (
            <>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              <StyledSubCard onPress={() => onClickOrder(order?.cart)}>
                <TouchableCard key={order?.id}>
                  <StyledSubCardContent>
                    <StyledSubCardLeftContent>
                      <Typography.Body bold>#{getOrderIdFormatted(order)}</Typography.Body>
                      <Typography.BodyExtraSmall colorName="text-dark-3">
                        Validée le {FormatUtils.formatDate(order?.createdDate, 'Date')} à{' '}
                        {FormatUtils.formatDate(order?.createdDate, 'Time')}
                      </Typography.BodyExtraSmall>
                    </StyledSubCardLeftContent>
                    <StyledSubCardRightContent>
                      <Typography.Body bold>{order?.totalHTBatchs}€</Typography.Body>
                      <Icon
                        type={Icons.Ionicons}
                        name={'chevron-forward-outline'}
                        size={theme.spacingUnit * 3}
                        color={theme.colors['text-dark-3']}
                      />
                    </StyledSubCardRightContent>
                  </StyledSubCardContent>
                </TouchableCard>
              </StyledSubCard>
            </>
          ))}
        </>
      )
    }
  }

  return isSingleOrder() ? (
    <>
      <StyledCardWrapper
        onPress={orders === undefined ? () => {} : () => onClickOrder(orders && orders[0]?.cart)}
        disabled={isDisabled}
      >
        <TouchableCard key={client?.id}>
          <StyledCardContent>
            <StyledLeftBox>
              <DynamicImage />
            </StyledLeftBox>
            <StyledContentBox>
              <StyledMiddleBox>
                <Typography.CardTitle ellipsis>{client?.label}</Typography.CardTitle>
                <Typography.BodyExtraSmall colorName="text-dark-3">
                  {FormatUtils.capitalize(FormatUtils.getLabelFromClientType(client?.clientType))}
                </Typography.BodyExtraSmall>
              </StyledMiddleBox>
              {orders ? (
                <StyledRightBox>
                  {orders.length === 1 && (
                    <Icon
                      type={Icons.Ionicons}
                      name={'chevron-forward-outline'}
                      size={theme.spacingUnit * 2.7}
                      color={theme.colors['text-dark-3']}
                    />
                  )}
                  <StyledRightBoxDetails>
                    {orders.length > 0 && (
                      <>
                        <Typography.BodySmall colorName="text-dark-3">
                          {getStats(client).totalPackingQuantity} colis /
                        </Typography.BodySmall>

                        <Typography.BodySmall colorName="text-dark-3">
                          {' '}
                          {FormatUtils.formatPrice(getStats(client).totalHT, 'ht')}
                        </Typography.BodySmall>
                      </>
                    )}
                  </StyledRightBoxDetails>
                </StyledRightBox>
              ) : (
                onCreateNewOrder &&
                canCreateNewOrder && (
                  <Button
                    label={isMobile ? 'Créer' : 'Créer une commande'}
                    onPress={() => onCreateNewOrder(client)}
                    small
                  />
                )
              )}
            </StyledContentBox>
          </StyledCardContent>
        </TouchableCard>
      </StyledCardWrapper>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
    </>
  ) : (
    <>
      <StyledCardWrapperUnclickable>
        <TouchableCard key={client?.id}>
          <StyledCardContent>
            <StyledLeftBox>
              {userRole === UserEnums.RoleEnums.CARRIER ? <></> : <DynamicImage />}
            </StyledLeftBox>
            <StyledContentBox>
              <StyledMiddleBox>
                <Typography.CardTitle>{client?.label}</Typography.CardTitle>
                <Typography.BodyExtraSmall colorName="text-dark-3">
                  {FormatUtils.capitalize(FormatUtils.getLabelFromClientType(client?.clientType))}
                </Typography.BodyExtraSmall>
              </StyledMiddleBox>
              <StyledRightBox>
                <StyledRightBoxDetails>
                  <Typography.BodySmall colorName="text-dark-3">
                    {orders?.length} commandes /{' '}
                  </Typography.BodySmall>
                  <Typography.BodySmall>
                    {getStats(client).totalPackingQuantity} colis /
                  </Typography.BodySmall>
                  <Typography.BodySmall colorName="text-dark-3">
                    {' '}
                    {FormatUtils.formatPrice(getStats(client).totalHT, 'ht')}€
                  </Typography.BodySmall>
                </StyledRightBoxDetails>
              </StyledRightBox>
            </StyledContentBox>
          </StyledCardContent>
        </TouchableCard>
      </StyledCardWrapperUnclickable>
      {renderSubOrders(orders)}
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />
    </>
  )
}

export default ClientOrderCard
