import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View<{ lowMargin?: boolean }>`
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.2}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.2}px;
  flex-direction: row;

  ${({ lowMargin }) =>
    lowMargin &&
    css`
      margin: ${({ theme }) => theme.spacingUnit * 1}px;
    `}
`

const StyledImageBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 1.3}px;
  width: ${({ theme }) => theme.spacingUnit * 4}px;
  height: ${({ theme }) => theme.spacingUnit * 4}px;
  border: ${({ theme }) => theme.spacingUnit * 0.1}px solid
    ${({ theme }) => theme.colors['color-grey']};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  align-self: center;
`

const StyledLeftBox = styled.View`
  flex: 7;
`

const StyledRightBox = styled.View`
  flex: 2;
  align-items: flex-end;
  justify-content: space-between;
`

export { StyledCardWrapper, StyledCardContent, StyledLeftBox, StyledRightBox, StyledImageBox }
