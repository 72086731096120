import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Button, SectionTitle, Spacer } from '../../../components'
import { Group, User } from '../../../domain'
import i18n from '../../../i18n'
import GroupCarrierService from '../../../services/carrier/group'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import Loader from '../../../components/Loader'
import UserAdminCard from '../../../modules/User/UserAdminCard'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierGroupAdminsScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const {
    data: groupData,
    refetch,
    isInitialLoading: feedLoading,
  } = useQuery<{ group: Group; stepsData: TutorialStepData[] }, Error>(
    ['sf_group'],
    () =>
      GroupCarrierService.getOneGroup(getAccessInfos().carrierGroupId, getAccessInfos().carrierId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: adminGroups,
    refetch: refetchAdminGroups,
    isInitialLoading: isAdminGroupsLoading,
  } = useQuery<User[], Error>(
    ['sf_group_admins'],
    () =>
      GroupCarrierService.getOneGroupAdmins(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
      ),
    {
      keepPreviousData: true,
    },
  )

  const OnClickAddAdmin = () => {
    if (!groupData?.group?.id) return
    navigation.navigate('CarrierAddAdminUser', { groupId: groupData?.group.id })
  }

  const onClickAdmin = (user: User) => {
    if (!groupData?.group?.id) return

    navigation.navigate('CarrierUpdateAdminUser', { groupId: groupData?.group.id, userId: user.id })
  }

  if (isAdminGroupsLoading || !groupData?.group) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <SectionTitle
          title={`Admins du groupe (${adminGroups?.length || 0})`}
          buttonRight={
            <Button
              small
              label={FormatUtils.capitalize(i18n.t('add'))}
              onPress={() => OnClickAddAdmin()}
            />
          }
        />
        {(adminGroups || []).map((user) => {
          if (user) {
            return (
              <UserAdminCard
                key={user.id}
                user={user}
                group={groupData?.group}
                onClick={onClickAdmin}
              />
            )
          }
        })}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierGroupAdminsScreen
