import styled from 'styled-components/native'

const StyledTopBox = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export { StyledTopBox }
