import { useState } from 'react'
import { AddClientToursProps } from './AddClientTours.model'
import { queryClient } from '../../../../../utilities/queryClient'
import TourCarrierService from '../../../../../services/carrier/tour'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { Button, Dropdown, SectionTitle, Spacer, Typography } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import {
  AddClientsTourWrapper,
  BottomContentWrapper,
  StyledButtonWrapper,
  StyledButtonsWrapper,
} from './AddClientTours.styles'
import { View } from 'react-native'
import ItemCard from '../../../../../modules/Common/ItemCard'
import InviteClientForm from './InviteClientForm'
import { ClientGroup, Pagination } from '../../../../../domain'
import { useQuery } from '@tanstack/react-query'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import GroupCarrierService from '../../../../../services/carrier/group'

const AddClientTours = ({ group, tour, onGoBack }: AddClientToursProps) => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [selectedClients, setSelectedClients] = useState<string[] | undefined>(undefined)
  const [newClients, setNewClients] = useState<any[]>([])
  const [showForm, setShowForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: clientGroups,
    refetch: refetchClientGroups,
    isLoading: isClientGroupsLoading,
  } = useQuery<{ data: ClientGroup[]; pagination: Pagination }, Error>(
    ['sf_group_clients', 500, 1],
    () =>
      GroupCarrierService.getOneGroupClients(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        500,
        1,
        undefined,
      ),
    {
      keepPreviousData: true,
      staleTime: 600000, // 10min
    },
  )

  const onChangeClients = (values: any) => {
    if (values && values.length > 0) {
      const clients: string[] = []
      values.map((item: any) => {
        if (item.value) {
          clients.push(item?.value)
        }
      })
      setSelectedClients(clients)
    } else {
      setSelectedClients([])
    }
  }

  const clientNotInTour = clientGroups?.data.filter(
    (clientGroup) => !tour?.stops?.find((stop) => stop?.client?.id === clientGroup?.client?.id),
  )

  const handleInviteClient = async (newClientData: any) => {
    if (!group?.id) return

    setShowForm(false)
    setNewClients((prevClients) => [...prevClients, newClientData])

    if (newClientData?.id) {
      queryClient.invalidateQueries(['sf_tour_clients'])
      queryClient.invalidateQueries(['sf_group_clients'])
    }
  }

  const itemCardDescription = (client: any) => {
    if (!client.firstName && !client.lastName) return client.email
    return `${client.firstName} ${client.lastName} - ${client.email}`
  }

  const removeClient = (clientToRemove: any) => {
    setNewClients((tempClients) =>
      tempClients.filter((client) => client.label !== clientToRemove.label),
    )
  }

  const onValidateClientsToTour = async () => {
    setIsLoading(true)

    if (!tour) return

    const selectedClientIds = selectedClients?.map((client) => client) ?? []

    if ((selectedClients && selectedClients?.length > 0) || newClients.length > 0) {
      await TourCarrierService.inviteClientTour(tour?.id, {
        clientIds: selectedClientIds,
        clientsToCreate: newClients || [],
      })
    }

    setIsLoading(false)
    queryClient.invalidateQueries(['sf_tour', tour?.id])
    navigation.navigate('CarrierTour', { id: tour?.id })
  }

  const handleGoBack = () => {
    setShowForm(false)
  }

  const renderClientsMessage = (selectedClients: string[], newClients: any[]) => {
    const hasSelectedClients = selectedClients && selectedClients.length > 0
    const hasNewClients = newClients.length > 0

    return (
      (hasSelectedClients || hasNewClients) && (
        <Typography.BodySmall align="center">
          {`Vous ajoutez ${selectedClients?.length || 0} ${FormatUtils.pluralizeAmountLabel(
            'client',
            selectedClients?.length || 0,
            true,
          )} à la tournée${
            hasNewClients
              ? `, et ${newClients.length} ${
                  newClients.length > 1 || newClients.length === 0
                    ? 'qui ne sont pas encore dans votre groupe'
                    : "qui n'est pas encore dans votre groupe"
                }`
              : '.'
          }`}
        </Typography.BodySmall>
      )
    )
  }

  return (
    <>
      {!showForm ? (
        <>
          <AddClientsTourWrapper>
            <View>
              <SectionTitle title="Sélectionner des clients de votre groupe :" />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Dropdown
                label={'Clients de votre groupe'}
                onChange={onChangeClients}
                multiple={true}
                defaultValue={selectedClients}
                optionsDefault={
                  !clientNotInTour
                    ? []
                    : clientNotInTour.map((client) => ({
                        value: client?.client?.id,
                        label: client?.client?.label,
                      }))
                }
                itemKey="Client"
                isSearchable
              />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
              <SectionTitle title="Invitez des nouveaux clients" />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <Typography.BodySmall>
                Vous souhaitez inviter un client qui n'est pas encore dans votre groupe ?
              </Typography.BodySmall>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              {newClients.map((client) => {
                return (
                  <ItemCard
                    key={client.label}
                    title={client.label}
                    description={itemCardDescription(client)}
                    iconType="DELETE"
                    onClick={() => removeClient(client)}
                  />
                )
              })}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <StyledButtonWrapper>
                <Button label="Inviter un nouveau client" onPress={() => setShowForm(true)} />
              </StyledButtonWrapper>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            </View>
            <BottomContentWrapper>
              <View>{renderClientsMessage(selectedClients ?? [], newClients ?? [])}</View>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
              <StyledButtonsWrapper>
                <StyledButtonWrapper>
                  <Button
                    label={'Retour'}
                    onPress={() => onGoBack('LANDING')}
                    colorName="color-grey"
                  />
                </StyledButtonWrapper>
                <StyledButtonWrapper>
                  <Button
                    label={'Terminer'}
                    onPress={() => onValidateClientsToTour()}
                    loading={isLoading}
                  />
                </StyledButtonWrapper>
              </StyledButtonsWrapper>
            </BottomContentWrapper>
          </AddClientsTourWrapper>
        </>
      ) : (
        <InviteClientForm
          group={group}
          onInviteClient={handleInviteClient}
          onGoBack={handleGoBack}
        />
      )}
    </>
  )
}

export default AddClientTours
