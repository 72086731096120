import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Col,
  Dropdown,
  Icon,
  Icons,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { UpdateProducer, Group, Producer, ProducerGroup, Address } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import GroupCarrierService from '../../../services/carrier/group'
import ProducerCarrierService from '../../../services/carrier/producer'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierUpdateProducer.styles'
import { PRODUCER_BILLING_TYPES, PRODUCER_VAT_TYPES } from '../../../../enums/producer'
import LabelForm from '../../../modules/Common/LabelForm'
import { TouchableOpacity } from 'react-native-gesture-handler'
import GeneralUtils from '../../../utilities/utils/general'
import AddressForm from '../../../modules/Address/AddressForm'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import useGroupContext from '../../../utilities/hook/useGroupContext'
import { GroupEnums } from '../../../../enums/group'
import { RightKeyEnum } from '../../../../enums'
import Loader from '../../../components/Loader'

const CarrierUpdateProducerScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdateProducer'>>()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedBillingType, setSelectedBillingType] = useState<any | undefined>(undefined)
  const [additionalCarrierFees, setAdditionalCarrierFees] = useState<string | undefined>(undefined)
  const [selectedVatType, setSelectedVatType] = useState<any | undefined>(undefined)
  const { carrierHasRight } = useAuthenticationContext()

  const { getAccessInfos } = useAuthenticationContext()
  const { group } = useGroupContext()

  const { id } = route?.params ?? { id: '' }

  const {
    data: producerGroup,
    refetch: refetchProducerGroup,
    isInitialLoading: isProducerGroupLoading,
  } = useQuery<ProducerGroup, Error>(
    ['sf_group_producer'],
    () =>
      GroupCarrierService.getOneProducerGroup(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        id,
      ),
    {
      keepPreviousData: true,
    },
  )

  const [updatedProducer, setUpdatedProducer] = useState<UpdateProducer>()
  const [producer, setProducer] = useState<Producer>()

  useEffect(() => {
    if (!group || !producerGroup) return

    setUpdatedProducer({
      ...producerGroup.producer,
    })
    setProducer(producerGroup.producer)

    const billingType = PRODUCER_BILLING_TYPES.find(
      (mt: any) => mt.value === producerGroup?.billingType,
    )

    if (billingType) {
      setSelectedBillingType({
        ...billingType,
      })
    } else {
      setSelectedBillingType({
        label: 'Choisir une option',
        value: 0,
      })
    }

    if (producerGroup.additionalCarrierFees) {
      setAdditionalCarrierFees(producerGroup.additionalCarrierFees.toString())
    }

    const vatType = PRODUCER_VAT_TYPES.find(
      (mt: any) => mt.value === producerGroup.producer?.account?.legalInfo.vatType,
    )

    if (vatType) {
      setSelectedVatType({
        ...vatType,
      })
    } else {
      setSelectedVatType({
        label: 'Choisir une option',
        value: 0,
      })
    }
  }, [id, setUpdatedProducer, group, producerGroup])

  const theme = useTheme()

  const [errorMessage, setErrorMessage] = useState<string>('')

  if (!updatedProducer || !producerGroup) return <View></View>

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in updatedProducer)) return null

    const producerTemp = Object.assign({}, updatedProducer)

    producerTemp[param as keyof typeof updatedProducer] = value
    setUpdatedProducer(producerTemp)
  }

  const onChangeBillingType = (value: any) => {
    setSelectedBillingType(value)
  }

  const onChangeVatType = (value: any) => {
    setSelectedVatType(value)
  }

  const onUpdateProducer = async () => {
    if (updatedProducer.mail && !GeneralUtils.isEmail(updatedProducer.mail)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }
    setErrorMessage('')
    setIsLoading(true)
    const producer = {
      label: updatedProducer.label,
      owners: updatedProducer.owners,
      description: updatedProducer.description,
      location: updatedProducer.location,

      addressLine1: updatedProducer.addressLine1,
      addressLine2: updatedProducer.addressLine2,
      postalCode: updatedProducer.postalCode,
      city: updatedProducer.city,
      phone1: updatedProducer.phone1,
      phone2: updatedProducer.phone2,
      mail: updatedProducer?.mail,
      deliveryNoteStatment1: updatedProducer.deliveryNoteStatment1,
      deliveryNoteStatment2: updatedProducer.deliveryNoteStatment2,
      deliveryNoteBottomStatment: updatedProducer.deliveryNoteBottomStatment,
      labels: updatedProducer.labels,
    }

    const updatedProducerTemp = await ProducerCarrierService.update(id, producer)
    const producerGroupTemp = {
      billingType: selectedBillingType.value,
      additionalCarrierFees: FormatUtils.stringToFloat(additionalCarrierFees),
    }

    await GroupCarrierService.updateProducerGroup(
      getAccessInfos().carrierGroupId,
      getAccessInfos().carrierId,
      id,
      producerGroupTemp,
    ).then(() => {
      queryClient.invalidateQueries(['sf_group_producer'])
    })
    if (updatedProducerTemp && updatedProducerTemp.id) {
      await queryClient.fetchQuery(['sf_group'])
      await queryClient.fetchQuery(['sf_group_producers'])

      navigation.navigate('CarrierGroupProducers', {})
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour du producteur')
    }
    setIsLoading(false)
  }

  const onChangeAddress = (newAddress: Address) => {
    if (newAddress && newAddress.id) {
      const producerTemp = Object.assign({}, updatedProducer)

      producerTemp['address'] = newAddress
      setUpdatedProducer(producerTemp)
    }
  }

  if (!group) {
    return <Loader isLoading pageLoading />
  }

  const onAddUser = () => {
    if (!producer || !group.id) return
    navigation.navigate('CarrierAddUser', {
      groupId: group.id,
      producerId: producer.id,
      producerLabel: producer.label,
    })
  }

  const onUpdateUser = (userId?: string) => {
    if (!producer || !group.id || !userId) return
    navigation.navigate('CarrierUpdateUser', {
      groupId: group.id,
      userId: userId,
      producerId: producer.id,
    })
  }

  const hasSuperAdminAccesRight = carrierHasRight(RightKeyEnum.C_SUPER_ADMIN)

  const groupTypeIsSuperProducer = group?.type === GroupEnums.GroupTypeEnum.SUPER_PRODUCER

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Modifier un producteur" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Typography.Body colorName="color-grey">Utilisateurs</Typography.Body>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

            <Row>
              <Col xs={7}>
                {(producer?.userProducers || []).map((userProducer) => (
                  <TouchableOpacity onPress={() => onUpdateUser(userProducer?.user?.id)}>
                    <View style={{ flexDirection: 'row' }}>
                      <Typography.Body bold>{userProducer.user?.email}</Typography.Body>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
                      <Icon
                        type={Icons.Ionicons}
                        name="pencil-outline"
                        color={theme.colors['color-primary']}
                        size={theme.spacingUnit * 1.5}
                      />
                    </View>

                    <Typography.BodySmall colorName="text-dark-3">
                      Dernière connexion :{' '}
                      {FormatUtils.formatDate(userProducer.user?.updatedDate, 'Date')}
                    </Typography.BodySmall>
                    <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                  </TouchableOpacity>
                ))}
                {(producer?.userProducers || []).length < 1 && (
                  <Typography.Body colorName="color-grey">Aucun</Typography.Body>
                )}
              </Col>
              <Col xs={5}>
                <Button
                  label={'Ajouter un utilisateur'}
                  small
                  onPress={() => onAddUser()}
                  disabled={isLoading}
                />
              </Col>
            </Row>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.label}
                  label="Nom du producteur"
                  field="text"
                  onChangeText={(text) => setValue(text, 'label')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.owners}
                  label="Gérant.e.s"
                  field="text"
                  onChangeText={(text) => setValue(text, 'owners')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.description}
                  label="Description (1 phrase)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'description')}
                />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.location}
                  label="Ville (code departement)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'location')}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={12}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer?.mail}
                  label="Email"
                  field="text"
                  onChangeText={(text) => setValue(text, 'mail')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.phone1}
                  label="Téléphone portable (0606...)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'phone1')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.phone2}
                  label="Téléphone Fixe (0505...)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'phone2')}
                />
              </Col>
            </Row>

            <AddressForm
              key={route?.params?.id}
              onChangeAddress={onChangeAddress}
              address={updatedProducer.address}
              producer={producerGroup.producer}
            />
            <SectionTitle title="Informations pour les bons de livraison" />
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.deliveryNoteStatment1}
                  label="BL ligne 1 (origine)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'deliveryNoteStatment1')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.deliveryNoteStatment2}
                  label="BL ligne 2 (certification)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'deliveryNoteStatment2')}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.deliveryNoteBottomStatment}
                  label="BL bas de page (siret ...)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'deliveryNoteBottomStatment')}
                />
              </Col>
              {/* <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.account?.billingStatement.billingStatement}
                  label="BL facturation (IBAN RIB)"
                  field="text"
                  onChangeText={(text) =>
                    setAccountValue(
                      { ...updatedProducer.account?.billingStatement, billingStatement: text },
                      'billingStatement',
                    )
                  }
                />
              </Col> */}
            </Row>

            {/* <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.account?.legalInfo.siret}
                  label="Siret"
                  field="text"
                  onChangeText={(text) =>
                    setAccountValue(
                      { ...updatedProducer.account?.legalInfo, siret: text },
                      'legalInfo',
                    )
                  }
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.account?.legalInfo.apeCode}
                  label="Code APE"
                  field="text"
                  onChangeText={(text) =>
                    setAccountValue(
                      { ...updatedProducer.account?.legalInfo, apeCode: text },
                      'legalInfo',
                    )
                  }
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.account?.legalInfo.vatNumber}
                  label="Numéro de TVA"
                  field="text"
                  onChangeText={(text) =>
                    setAccountValue(
                      { ...updatedProducer.account?.legalInfo, vatNumber: text },
                      'legalInfo',
                    )
                  }
                />
              </Col>
            </Row> */}

            {(groupTypeIsSuperProducer || hasSuperAdminAccesRight) && (
              <>
                <SectionTitle title="Paramètres du compte" />
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Dropdown
                  label={'Type de facturation'}
                  onChange={onChangeBillingType}
                  multiple={false}
                  optionsDefault={PRODUCER_BILLING_TYPES.map((billingType: any) => ({
                    ...billingType,
                  }))}
                  zIndex={200}
                  itemKey="billingType"
                  defaultValue={selectedBillingType.value}
                />
                <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodyExtraSmall colorName="color-grey">
                  En mode "via super-producteur", un seul BL sera émit entre le producteur et le
                  super producteur, regroupant toutes les commandes client ainsi qu'un seul BL entre
                  le super producteur et le client, regroupant tous les produits commandés aux
                  producteurs paramétré avec ce type de facturation.
                </Typography.BodyExtraSmall>
                <Spacer size={1.5} axis={Spacer.AxisEnum.VERTICAL} />
                <Dropdown
                  label={'Facturation de la TVA'}
                  onChange={onChangeVatType}
                  multiple={false}
                  optionsDefault={PRODUCER_VAT_TYPES.map((vatType: any) => ({
                    ...vatType,
                  }))}
                  zIndex={200}
                  itemKey="vatType"
                  defaultValue={selectedVatType.value}
                />
                <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodyExtraSmall colorName="color-grey">
                  Si le producteur ne re-facture pas la TVA (ex: micro-entreprise) les clients
                  verront une mention s'afficher sur le prix de ses produits et les prix seront
                  affichés directement en TTC sur le catalogue et dans ses BL.
                </Typography.BodyExtraSmall>
                <Spacer size={1.5} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={additionalCarrierFees}
                  label="Commission supplémentaire (en %)"
                  field="text"
                  onChangeText={(text) => setAdditionalCarrierFees(text)}
                />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                <LabelForm
                  labels={updatedProducer.labels}
                  type={2}
                  onChangeLabel={(labels) => setValue(labels, 'labels')}
                />
              </>
            )}

            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            {updatedProducer.label && (
              <Button
                label={FormatUtils.capitalize(i18n.t('save'))}
                onPress={() => onUpdateProducer()}
                loading={isLoading}
                hasDoubleValidation
                confirmationLabel="Êtes-vous sûr de vouloir mettre à jour ce producteur ?"
              />
            )}
          </StyledButtonWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierUpdateProducerScreen
