import { RouteProp, useRoute } from '@react-navigation/native'
import { useState } from 'react'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { useQuery } from '@tanstack/react-query'
import { Tour } from '../../../domain'
import TourCarrierService from '../../../services/carrier/tour'
import { PageTitle, Spacer } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import CarrierTourAddClientsLandingPage from './slides/LandingPage'
import useGroupContext from '../../../utilities/hook/useGroupContext'
import AddClientTours from './slides/AddClientTours'

const carrierTourAddClientsModes = [
  {
    label: 'Invitez des clients',
    value: 'LANDING',
  },
  {
    label: 'Ajoutez des clients à la tournée',
    value: 'ADD_CLIENTS_TOUR',
  },
]

const CarrierTourAddClients = () => {
  const [selectedMode, setSelectedMode] = useState(carrierTourAddClientsModes[0].value)

  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTour'>>()
  const { id } = route?.params ?? { id: '' }

  const { data: tour } = TourCarrierService.getOne.query(id)

  const { group } = useGroupContext()

  const onValidateStep1 = () => {
    setSelectedMode('ADD_CLIENTS_TOUR')
  }

  const handleGoBack = (selectedMode: 'LANDING' | 'ADD_CLIENTS_TOUR') => {
    setSelectedMode(selectedMode)
  }

  const renderSelectedComponent = () => {
    switch (selectedMode) {
      case 'LANDING':
        return (
          <>
            <PageTitle title="Invitez des clients" />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.6} />
            <CarrierTourAddClientsLandingPage onClickNext={onValidateStep1} />
          </>
        )
      case 'ADD_CLIENTS_TOUR':
        return (
          <>
            <PageTitle title="Ajoutez des clients à la tournée" />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.6} />
            <AddClientTours group={group} tour={tour} onGoBack={handleGoBack} />
          </>
        )
      default:
        return null
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>{renderSelectedComponent()}</ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourAddClients
