import { useContext } from 'react'
import { AuthenticationContext, AuthenticationContextData } from '../context/authenticationContext'

export default function useAuthenticationContext(): AuthenticationContextData {
  const context = useContext(AuthenticationContext)

  if (!context) {
    throw new Error('useAuthenticationContext must be used within an AuthenticationProvider')
  }

  return context
}
