import styled from 'styled-components/native'

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const TotalSummaryCard = styled.View`
  width: 100%;
  justify-content: center;
  align-items: center;
`
const TotalSummaryCardContent = styled.View`
  background-color: ${({ theme }) => theme.colors['color-quaternary']};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  padding: ${({ theme }) => theme.spacingUnit * 1.5}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 4}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 4}px;
  width: fit-content;
  flex-direction: row;
  justify-content: space-between;
`

const SummaryLeftBox = styled.View``
const SummaryRightBox = styled.View``

export {
  StyledButtonWrapper,
  TotalSummaryCard,
  TotalSummaryCardContent,
  SummaryLeftBox,
  SummaryRightBox,
}
