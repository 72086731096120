import React, { useState, useEffect, useRef } from 'react'
import { TouchableWithoutFeedback, TextInput as RNTextInput } from 'react-native'
// import PhoneInput from 'react-native-phone-number-input'
import { useTheme } from 'styled-components/native'
import Icon, { Icons } from '../../Icon'
import TextInputLabel from '../TextInputLabel'
import { PhoneNumberInputProps } from './PhoneNumberInput.model'
import StyledInputWrapper from '../inputs.styles'
import InputUtils from '../inputs.utils'

//const PhoneNumberInput = React.forwardRef<PhoneInput, PhoneNumberInputProps>(

const PhoneNumberInput = React.forwardRef<any, PhoneNumberInputProps>(
  ({ onChangeText, value, hasError, autoFocus, defaultCode }, ref) => {
    const theme = useTheme()
    const fontFamily = 'Euclid-Medium'
    const textColor = theme.colors['text-light-1']
    const placeholderTextColor = theme.colors['text-dark-3']
    const textInputRef = useRef<RNTextInput>(null)
    const { backgroundColor, borderColor, onBlur, onFocus } = InputUtils.useInputWrapperTheme({
      hasError,
    })

    const handleInputFocus = () => {
      textInputRef.current?.focus()
    }

    const containerStyle = {
      height: theme.inputHeight - 2, // 2px border
      width: '100%',
      backgroundColor,
      borderColor,
      borderRadius: theme.borderRadius,
      zIndex: 1,
      paddingTop: theme.spacingUnit * 2,
    }

    const textStyle = {
      fontFamily,
      color: textColor,
      fontSize: theme.fontSizes.BODY,
    }

    const textContainerStyle = {
      backgroundColor,
      paddingHorizontal: 0,
      borderTopRightRadius: theme.borderRadius,
      borderBottomRightRadius: theme.borderRadius,
    }

    return (
      <TouchableWithoutFeedback onPress={handleInputFocus} testID="phone-number-input">
        <StyledInputWrapper
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          multiline={false}
        >
          <TextInputLabel label="Phone number" editable isActive style={{ zIndex: 2 }} />
          {/*
          <PhoneInput
            ref={ref}
            withDarkTheme
            placeholder="775-553-3443"
            layout="second"
            autoFocus={autoFocus}
            value={value}
            defaultCode={(defaultCode || 'GB') as any}
            onChangeText={onChangeText}
            containerStyle={containerStyle}
            textInputProps={{
              ref: textInputRef as any,
              onFocus,
              onBlur,
              placeholderTextColor,
              selectionColor: placeholderTextColor,
            }}
            codeTextStyle={textStyle}
            textInputStyle={textStyle}
            textContainerStyle={textContainerStyle}
            renderDropdownImage={
              <Icon
                size={12}
                type={Icons.Ionicons}
                name="ios-caret-down"
                color={theme.colors['text-light-1']}
              />
            }
          />
          */}
        </StyledInputWrapper>
      </TouchableWithoutFeedback>
    )
  },
)

export default PhoneNumberInput
