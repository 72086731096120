import { DeliveryNoteTypeEnum } from '../../../enums'
import { DeliveryNote } from '../../domain'

type DeliveryNoteInfo = {
  profileType: string
  name: string
}

const getDeliveryPartyInfo = (
  deliveryNote: DeliveryNote,
): { issuer: DeliveryNoteInfo; recipient: DeliveryNoteInfo } => {
  let issuer: DeliveryNoteInfo = { profileType: '', name: '' }
  let recipient: DeliveryNoteInfo = { profileType: '', name: '' }

  switch (deliveryNote.type) {
    case DeliveryNoteTypeEnum.PRODUCER_CARRIER:
      issuer = { profileType: 'Producteur', name: deliveryNote.producer?.label || '' }
      recipient = { profileType: 'Super-producteur', name: deliveryNote.carrier?.name || '' }
      break
    case DeliveryNoteTypeEnum.PRODUCER_CLIENT:
      issuer = { profileType: 'Producteur', name: deliveryNote.producer?.label || '' }
      recipient = { profileType: 'Client', name: deliveryNote.order?.cart?.client?.label || '' }
      break
    case DeliveryNoteTypeEnum.CARRIER_CLIENT:
      issuer = { profileType: 'Super-producteur', name: deliveryNote.carrier?.name || '' }
      recipient = { profileType: 'Client', name: deliveryNote.order?.cart?.client?.label || '' }
      break
  }

  return { issuer, recipient }
}

const DeliveryNoteUtils = {
  getDeliveryPartyInfo,
}

export default DeliveryNoteUtils
