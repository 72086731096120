export enum RoleProfileTypeEnum {
  ADMIN = 0,
  CARRIER = 1,
  PRODUCER = 2,
  CLIENT = 3,
}

export enum RightKeyEnum {
  C_SUPER_ADMIN = 'C_SUPER_ADMIN',
  C_ADMIN = 'C_ADMIN',

  // Heading give access to the main page and visibility in menu
  C_HEADING_TOURS = 'C_HEADING_TOURS',
  C_HEADING_GROUP = 'C_HEADING_GROUP',
  C_HEADING_PRICE_REPORTING = 'C_HEADING_PRICE_REPORTING',
  C_HEADING_BILLING = 'C_HEADING_BILLING',

  // ============= API =============
  C_ACCOUNTS_ACCESS = 'C_ACCOUNTS_ACCESS',

  // ============= TOURS =============
  C_TOURS_DETAILS = 'C_TOURS_DETAILS',
  C_TOUR_CONTENT = 'C_TOUR_CONTENT',
  C_TOUR_DELIVERY = 'C_TOUR_DELIVERY',
  C_TOUR_CREATE = 'C_TOUR_CREATE',
  C_TOUR_UPDATE = 'C_TOUR_UPDATE',
  C_TOUR_PREPARATION = 'C_TOUR_PREPARATION',

  // ============= GROUP =============
  C_GROUP_SETTINGS_ACCESS = 'C_GROUP_SETTINGS_ACCESS',
  C_GROUP_CARRIERS_ACCESS = 'C_GROUP_CARRIERS_ACCESS',
  C_GROUP_CARRIER_CREATE = 'C_GROUP_CARRIER_CREATE',
  C_GROUP_CARRIER_UPDATE = 'C_GROUP_CARRIER_UPDATE',

  // ============= DASHBOARD =============
  C_DASHBOARD_STATS = 'C_DASHBOARD_STATS',
  C_DASHBOARD_URGENT_ACTIONS = 'C_DASHBOARD_URGENT_ACTIONS',
}
