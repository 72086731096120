import React from 'react'
import KeyboardAvoidingViewWrapper from './KeyboardAvoidingViewWrapper'
import KeyboardDismissWrapper from './KeyboardDismissWrapper'

const FormKeyboardWrappers: React.FC<{
  children: React.ReactNode
  modal?: boolean
  withKeyboardAvoidingView?: boolean
}> = ({ children, modal, withKeyboardAvoidingView }) => {
  return (
    <KeyboardAvoidingViewWrapper modal={modal} withKeyboardAvoidingView={withKeyboardAvoidingView}>
      <KeyboardDismissWrapper>{children}</KeyboardDismissWrapper>
    </KeyboardAvoidingViewWrapper>
  )
}

FormKeyboardWrappers.defaultProps = {
  modal: false,
  withKeyboardAvoidingView: false,
}

export default FormKeyboardWrappers
