import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { BatchEnums } from '../../../../enums'
import { Button, PageTitle, SectionTitle, Spacer, TextInput, Typography } from '../../../components'
import Loader from '../../../components/Loader'
import { Batch, Pagination, ProductBatchs, Tour } from '../../../domain'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import BatchProducerService from '../../../services/producer/batch'
import TourProducerService from '../../../services/producer/tour'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import ProducerPropositionsV2Filters from './components/ProducerPropositionsV2Filters'
import { View } from 'react-native'
import PaginationBar from '../../../components/PaginationBar'
import BatchsCard from '../../../modules/Batch/BatchsCard'
import CardList from '../../../components/CardList'
import TourCard from '../../../modules/Tour/TourCard'
import ProducerPropositionV2Duplicate from './components/ProducerPropositionV2Duplicate'
import { TextInputWrapper } from './ProducerPropositionsV2.styles'

export type PropositionsFiltersObject = {
  mode: 'DAY' | 'WEEK' | 'MONTH'
  start: Date
  end: Date
  selectedTour?: Tour
}

const ProducerPropositionsV2Screen = () => {
  let defaultStartDate = new Date()
  let defaultEndDate = new Date()

  defaultStartDate.setHours(0)
  defaultStartDate.setMinutes(0)
  defaultStartDate.setSeconds(0)

  defaultEndDate.setHours(23)
  defaultEndDate.setMinutes(59)
  defaultEndDate.setSeconds(59)

  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerPropositions'>>()
  const { tourId, mode, start } = route?.params ?? {
    tourId: '',
    mode: undefined,
    start: undefined,
  }
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()
  const [isLoadingDuplicate, setIsLoadingDuplicate] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isHistorySearch, setIsHistorySearch] = useState<boolean>(false)
  const [pageSizeBatchs, setPageSizeBatchs] = useState(5)
  const [pageNumberBatchs, setPageNumberBatchs] = useState(1)
  const [duplicateMode, setDuplicateMode] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const [propositionsFilters, setPropositionsFilter] = useState<PropositionsFiltersObject>()

  useEffect(() => {
    const fetchData = async () => {
      let tempStartDate = new Date()
      let tempEndDate = new Date()
      let tempMode: 'DAY' | 'WEEK' | 'MONTH' = 'DAY'

      if (mode && start) {
        tempStartDate = new Date(start.toUTCString())
        /*
        tempStartDate.setDate(start.getDate())
        tempStartDate.setMonth(start.getMonth())
        tempStartDate.setFullYear(start.getFullYear())
        */
        tempEndDate = new Date(tempStartDate)

        if (mode === 'WEEK') {
          tempMode = 'WEEK'
          tempStartDate.setDate(FormatUtils.startOfWeek(tempStartDate))
          tempEndDate = new Date(tempStartDate)
          tempEndDate.setDate(tempStartDate.getDate() + 6)
        }

        if (mode === 'MONTH') {
          tempMode = 'MONTH'
          tempStartDate = new Date(
            tempStartDate.getFullYear(),
            tempStartDate.getMonth(),
            1,
            0,
            0,
            0,
          )
          tempEndDate = new Date(
            tempStartDate.getFullYear(),
            tempStartDate.getMonth() + 1,
            0,
            0,
            0,
            0,
          )
        }
        // clean hours on start and end date
        tempStartDate.setHours(0)
        tempStartDate.setMinutes(0)
        tempStartDate.setSeconds(0)

        tempEndDate.setHours(23)
        tempEndDate.setMinutes(59)
        tempEndDate.setSeconds(59)
        await setPropositionsFilter({
          mode: tempMode,
          start: tempStartDate,
          end: tempEndDate,
          selectedTour: undefined,
        })
      } else if (!(mode && start)) {
        setPropositionsFilter({
          mode: 'DAY',
          start: new Date(defaultStartDate.toUTCString()),
          end: new Date(defaultEndDate.toUTCString()),
          selectedTour: undefined,
        })
      }
    }
    fetchData()
  }, [mode, start])

  useEffect(() => {
    if (propositionsFilters && propositionsFilters.start) {
      refetchNextTours()
      refetchProductBatchs()
    }
  }, [propositionsFilters, searchValue])

  const {
    data: tours,
    refetch: refetchNextTours,
    isInitialLoading: nextToursLoading,
  } = useQuery<{ data: Tour[]; pagination: Pagination }, Error>(
    ['p_next_tours_proposition', getAccessInfos().producerId, propositionsFilters],
    () =>
      TourProducerService.getAllTours(
        getAccessInfos().producerId,
        propositionsFilters?.start,
        propositionsFilters?.end,
        false,
        10,
        undefined,
        true,
      ),
    {
      keepPreviousData: true,
      enabled: propositionsFilters?.start !== undefined,
    },
  )

  const {
    data: productsBatchs,
    refetch: refetchProductBatchs,
    isRefetching: productBatchsRefetching,
    isLoading: productBatchsLoading,
  } = useQuery<{ data: ProductBatchs[]; pagination: Pagination }, Error>(
    [
      'p_batchs',
      getAccessInfos().producerId,
      propositionsFilters,
      pageSizeBatchs,
      pageNumberBatchs,
      searchValue,
    ],
    () =>
      BatchProducerService.getAllBatchsProducerComputed(
        getAccessInfos().producerId,
        propositionsFilters?.start,
        propositionsFilters?.end,
        propositionsFilters?.selectedTour?.id,
        propositionsFilters?.mode === 'MONTH' ? [BatchEnums.TYPE.MONTH] : [],
        pageSizeBatchs,
        pageNumberBatchs,
        searchValue,
      ),
    {},
  )

  const onChangePage = async (pageNumber: number) => {
    await setPageNumberBatchs(pageNumber)
    refetchProductBatchs()
  }

  const onChangeSearchValue = async (newSearchValue: string) => {
    setSearchValue(newSearchValue)
    setPageNumberBatchs(1)
  }

  if (!propositionsFilters) return <></>

  const OnClickAddBatch = () => {
    const batchType: BatchEnums.TYPE =
      propositionsFilters.mode === 'DAY'
        ? BatchEnums.TYPE.DAY
        : propositionsFilters.mode === 'WEEK'
        ? BatchEnums.TYPE.WEEK
        : BatchEnums.TYPE.MONTH

    const params = {
      type: batchType,
      start:
        propositionsFilters.mode === 'DAY' || propositionsFilters.mode === 'WEEK'
          ? propositionsFilters.start
          : undefined,
      end:
        propositionsFilters.mode === 'DAY' || propositionsFilters.mode === 'WEEK'
          ? propositionsFilters.end
          : undefined,
      tourId: propositionsFilters.selectedTour?.id || undefined,
    }
    navigation.navigate('ProducerAddBatch', params)
  }

  const onClickUpdateBatch = async (batch: Batch) => {
    /*
    if (getTourStatus() === TourEnums.STATUS.CLOSED && !getAccessInfos().carrierId) {
      return
    }
    */
    await queryClient.setQueryData(['p_batch'], null)
    await queryClient.invalidateQueries(['p_batch'])
    navigation.navigate('ProducerUpdateBatch', {
      batchId: batch.id,
      propositionsFilters: propositionsFilters,
    })
  }

  const onClickTour = (tour: Tour) => {
    navigation.navigate('ProducerTours', {
      screen: 'ProducerTour',
      params: { id: tour.id },
    })
  }

  /*
  const OnClickStopSalesBatch = async () => {
    if (!propositionsFilters.selectedTour?.id || !batchs || batchs.data.length < 1) return
    setIsLoading(true)
    for (let batch of batchs.data) {
      const batchTemp = {
        totalQuantity: batch.totalQuantity - (batch.availableQuantity || 0),
      }

      await BatchProducerService.update(batch.id, batchTemp)
    }
    await refetchProductBatchs()
    setIsLoading(false)
  }
  */

  if (nextToursLoading) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  const onChangeFilters = async (newFilters: PropositionsFiltersObject) => {
    await setDuplicateMode(false)
    await setPageNumberBatchs(1)
    await setPropositionsFilter(newFilters)
  }

  const renderBatchsCard = (productBatchs: ProductBatchs[]) => {
    return productBatchs.map((productBatch) => (
      <BatchsCard
        key={productBatch.product?.id}
        productBatchs={productBatch}
        onClick={onClickUpdateBatch}
      />
    ))
  }

  const renderBatchs = () => {
    if (isLoading || productBatchsLoading || productBatchsRefetching) {
      return <Loader isLoading />
    }

    const productsBatchsArray = productsBatchs?.data

    if (duplicateMode) {
      return (
        <ProducerPropositionV2Duplicate
          onDuplicate={() => {
            refetchProductBatchs()
            setDuplicateMode(false)
          }}
          onCancelDuplication={() => setDuplicateMode(false)}
          propositionsFilters={propositionsFilters}
        />
      )
    }

    if (!productsBatchsArray || productsBatchsArray.length < 1) {
      return (
        <View style={{ alignSelf: 'center' }}>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Typography.Body colorName="text-dark-3" align="center">
            {productsBatchs?.pagination?.page === 1 ? 'Aucun lot disponible' : ''}
          </Typography.Body>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
          {propositionsFilters.mode !== 'MONTH' &&
            (!productsBatchs?.pagination?.page || productsBatchs?.pagination?.page < 2) && (
              <View style={{ alignItems: 'center' }}>
                <Button
                  small
                  label="Dupliquer une mercuriale antérieur"
                  onPress={() => setDuplicateMode(true)}
                />
              </View>
            )}
        </View>
      )
    }

    // productBatchs with only one batch in day type
    const daysProductsBatchs = productsBatchsArray.filter(
      (pbs) => pbs.batchs && pbs.batchs.length === 1 && pbs.batchs[0].type === BatchEnums.TYPE.DAY,
    )
    // productBatchs with at least one batch in week type minus daysProductsBatchs
    const weekProductsBatchs = productsBatchsArray
      .filter((pbs) => !daysProductsBatchs.find((dpbs) => pbs.product?.id === dpbs.product?.id))
      .filter((pbs) => pbs.batchs && !pbs.batchs.find((b) => b.type === BatchEnums.TYPE.MONTH))
    // productBatchs with at least one batch in month type minus daysProductsBatchs and weekProductsBatchs
    const monthProductsBatchs = productsBatchsArray
      .filter((pbs) => !daysProductsBatchs.find((dpbs) => pbs.product?.id === dpbs.product?.id))
      .filter((pbs) => !weekProductsBatchs.find((wpbs) => pbs.product?.id === wpbs.product?.id))
      .filter((pbs) => pbs.batchs && pbs.batchs.find((b) => b.type === BatchEnums.TYPE.MONTH))

    return (
      <>
        {daysProductsBatchs.length < 1 &&
        weekProductsBatchs.length < 1 &&
        propositionsFilters.mode !== 'MONTH' ? (
          <View style={{ alignItems: 'center' }}>
            {(!productsBatchs?.pagination?.page || productsBatchs?.pagination?.page < 2) && (
              <>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
                <Button
                  small
                  label="Dupliquer une mercuriale antérieur"
                  onPress={() => setDuplicateMode(true)}
                />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
              </>
            )}
          </View>
        ) : null}
        {daysProductsBatchs.length > 0 && (
          <>
            {renderBatchsCard(daysProductsBatchs)}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          </>
        )}
        {weekProductsBatchs.length > 0 && (
          <>
            {renderBatchsCard(weekProductsBatchs)}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          </>
        )}
        {monthProductsBatchs.length > 0 && (
          <>
            <SectionTitle title={`Lots en stock`} />
            {renderBatchsCard(monthProductsBatchs)}
          </>
        )}
      </>
    )
  }
  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle
          title="Votre mercuriale"
          buttonRight={
            <Button
              small
              label="Ajouter un lot"
              onPress={() => OnClickAddBatch()}
              loading={isLoading}
            />
          }
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <ProducerPropositionsV2Filters
          propositionsFilters={propositionsFilters}
          onChangeFilters={onChangeFilters}
          nextTours={tours?.data}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <TextInputWrapper>
          <TextInput
            field="text"
            label="Rechercher un lot par nom"
            value={searchValue}
            onChangeText={onChangeSearchValue}
            small
          />
        </TextInputWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {renderBatchs()}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {!duplicateMode && (
          <PaginationBar
            data={productsBatchs?.data?.map((pbs) => pbs.batchs).flat()}
            pagination={productBatchsRefetching ? undefined : productsBatchs?.pagination}
            onChangePage={onChangePage}
          />
        )}

        <Spacer
          axis={Spacer.AxisEnum.VERTICAL}
          size={productsBatchs?.data && productsBatchs?.data?.length > 0 ? 2 : 10}
        />

        {tours?.data && tours?.data.length > 0 && (
          <>
            <SectionTitle
              title={`Les tournées à déposer ${
                propositionsFilters.mode === 'DAY'
                  ? `le ${FormatUtils.formatDate(propositionsFilters.start.toString(), 'FullDate')}`
                  : propositionsFilters.mode === 'WEEK'
                  ? `cette semaine`
                  : `ce mois`
              }`}
            />
            <CardList
              data={tours?.data}
              element={function (tour: any) {
                return <TourCard key={tour.id} tour={tour} onClick={onClickTour} />
              }}
              isLoading={nextToursLoading}
              emptyMessage="Aucune tournée à venir"
            />
          </>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerPropositionsV2Screen
