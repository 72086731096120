import { useNavigation } from '@react-navigation/native'
import { Button, Col, PageTitle, Spacer, TextInput, Typography } from '../../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'

import { useState } from 'react'
import FormatUtils from '../../../../utilities/utils/format'
import i18n from '../../../../i18n'
import { StyledButtonWrapper, ToursContentWrapper } from './UserUpdatePassword.styles'
import useUserContext from '../../../../utilities/hook/useUserContext'
import { StackNavigationProp } from '@react-navigation/stack'
import UserCarrierNavigationStackParamList from '../../../../navigation/CarrierAppNavigationStack/UserCarrierNavigation/UserCarrierNavigation.model'

const UserUpdatePasswordScreen = () => {
  const navigation = useNavigation<StackNavigationProp<UserCarrierNavigationStackParamList>>()
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { user, updatePassword } = useUserContext()

  const onUpdatePassword = async () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      setErrorMessage('Veuillez remplir tous les champs')
      return
    }

    if (newPassword.length < 8) {
      setErrorMessage('Le mot de passe doit contenir au moins 8 caractères')
      return
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage('Les mots de passe ne correspondent pas')
      return
    }

    if (!user?.id) {
      setErrorMessage('Une erreur est survenue')
      return
    }

    try {
      setErrorMessage('')
      setIsLoading(true)

      const response = await updatePassword(user?.id, oldPassword, newPassword)

      setIsLoading(false)
      if (response) {
        navigation.navigate('UserLoginSecurity')
      }
    } catch (error) {
      setIsLoading(false)
      setErrorMessage('Les données saisies sont incorrectes')
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Modifier mon mot de passe" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Col xs={14} sm={14} md={14}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={oldPassword}
              label="Mot de passe actuel"
              field="password"
              secureTextEntry={true}
              onChangeText={(text) => setOldPassword(text)}
            />
          </Col>
          <Col xs={14} sm={14} md={14}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newPassword}
              label="Nouveau mot de passe"
              field="password"
              secureTextEntry={true}
              onChangeText={(text) => setNewPassword(text)}
            />
          </Col>
          <Col xs={14} sm={14} md={14}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={confirmPassword}
              label="Confirmation nouveau mot de passe"
              field="password"
              secureTextEntry={true}
              onChangeText={(text) => setConfirmPassword(text)}
            />
          </Col>
          <StyledButtonWrapper>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <Button
              label={FormatUtils.capitalize(i18n.t('update'))}
              onPress={() => onUpdatePassword()}
              loading={isLoading}
            />
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default UserUpdatePasswordScreen
