import { User, UserFake } from '../../domain'

export const me = async (token: string): Promise<User> => {
  const user = UserFake.find((u) => u.token === token)
  if (user) {
    return user
  }
  throw new Error()
}

export const update = (user: User): User | undefined => {
  return UserFake.find((u) => u.id === user.id)
}

export const saveToken = (token: string): User | undefined => {
  return UserFake.find((user) => user.token === token)
}

export const removeToken = (token: string): User | undefined => {
  return UserFake.find((user) => user.token === token)
}

const UserSeeds = {
  me,
  update,
  saveToken,
  removeToken,
}

export default UserSeeds
