import { ca } from 'date-fns/locale'
import {
  CarrierFeesRecord,
  CarrierFeesStatsProducer,
  CarrierFeesStatsResponse,
} from '../../../domain/CarrierFees'
import ApiService from '../../api.service'
import { Invoice } from '../../../domain/Invoice'
import { Payment } from '../../../domain/Payment'

const getAllGroupStats = async (
  groupId: string,
  carrierId?: string,
  producerId?: string,
): Promise<CarrierFeesStatsResponse> => {
  const response = await ApiService.get(`/carrier-fees/group/${groupId}/stats`, {
    carrierId: carrierId,
    producerId: producerId,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getAllGroupProducerStats = async (
  groupId: string,
  carrierId?: string,
  producerId?: string,
): Promise<CarrierFeesStatsProducer[]> => {
  const response = await ApiService.get(`/carrier-fees/group/${groupId}/producer/stats`, {
    carrierId: carrierId,
    producerId: producerId,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getCarrierFeesRecords = async (
  groupId: string,
  carrierId: string,
  producerId: string,
): Promise<CarrierFeesRecord[]> => {
  const response = await ApiService.get(`/carrier-fees/group/${groupId}/records`, {
    carrierId: carrierId,
    producerId: producerId,
  }).catch((error) => {
    throw error
  })
  return response
}

const chargeProducer = async (
  groupId: string,
  carrierId: string,
  producerId: string,
  carrierFeesRecordIds: (string | undefined)[],
  customIdentifier?: string,
): Promise<{ createdInvoice: Invoice; createdPayment: Payment }> => {
  const response = await ApiService.post(
    `/carrier-fees/group/${groupId}/carrier/${carrierId}/producer/${producerId}`,
    { carrierFeesRecordIds: carrierFeesRecordIds, customIdentifier: customIdentifier },
  ).catch((error) => {
    throw error
  })
  return response
}

const CarrierFeesCarrierService = {
  getAllGroupStats,
  chargeProducer,
  getAllGroupProducerStats,
  getCarrierFeesRecords,
}

export default CarrierFeesCarrierService
