import React from 'react'
import { StyledTopBox } from './CarrierDeliveryFeesTopBox.styles'
import { CarrierDeliveryFeesTopBoxProps } from './CarrierDeliveryFeesTopBox.model'
import FormatUtils from '../../../../../utilities/utils/format'
import CardParam from '../CardParam'

function CarrierDeliveryFeesTopBox({ stats, group }: CarrierDeliveryFeesTopBoxProps) {
  const recommendedCarrierFees = Math.ceil(((stats?.realCarrierFees || 0) + 0.02) * 100) / 100

  return (
    <>
      <StyledTopBox>
        <CardParam
          label={'Dépensés'}
          value={FormatUtils.formatPrice(stats?.totalGroupCarrierExpenses, 'ht')}
          flex={4}
          isStart
        />
        <CardParam
          label={'Commissionnés'}
          value={FormatUtils.formatPrice(stats?.totalGroupCarrierFeesCharged, 'ht')}
          flex={4}
        />
        <CardParam
          label={'Résultat'}
          value={FormatUtils.formatPrice(stats?.balance, 'ht')}
          flex={4}
          isEnd
        />
      </StyledTopBox>
      <StyledTopBox>
        <CardParam
          label={'Com. logistique'}
          value={FormatUtils.formatPercentage(parseFloat(group?.carrierFees || '') / 100)}
          flex={3.1}
          isStart
        />
        <CardParam
          label={'Com. réelle'}
          value={FormatUtils.formatPercentage(stats?.realCarrierFees || 0)}
          flex={2.5}
        />

        <CardParam
          label={`Les producteurs paient ${
            stats?.balance || 0 > 0 ? 'plus' : 'moins'
          } de frais logistiques que prévu`}
          value={`Ajustez votre commission logistique à ${FormatUtils.formatPercentage(
            recommendedCarrierFees,
          )}`}
          onClick={() => {}}
          flex={6.4}
          isEnd
        />
      </StyledTopBox>
    </>
  )
}

export default CarrierDeliveryFeesTopBox
