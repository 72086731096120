import React from 'react'
import { useTheme } from 'styled-components/native'
import Typography from '../Typography'
import { SectionTitleProps } from './SectionTitle.model'
import { StyledTitleWrapper } from './SectionTitle.styles'

function SectionTitle({
  title,
  buttonRight,
  noMarginTop,
  colorName,
  noMarginLeft,
}: SectionTitleProps) {
  const theme = useTheme()

  return (
    <StyledTitleWrapper noMarginTop={noMarginTop}>
      <Typography.SectionTitle
        colorName={colorName || 'text-dark-3'}
        style={{
          ...(noMarginTop ? { marginTop: '0px' } : {}),
          ...(noMarginLeft ? { marginLeft: '0px' } : {}),
        }}
      >
        {title}
      </Typography.SectionTitle>
      {buttonRight && buttonRight}
    </StyledTitleWrapper>
  )
}

export default SectionTitle
