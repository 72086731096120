import React from 'react'
import { useTheme } from 'styled-components/native'
import { Card, Icon, Icons, Spacer, TouchableCard, Typography } from '../../../../../../components'
import i18n from '../../../../../../i18n'
import FormatUtils from '../../../../../../utilities/utils/format'
import { BatchToDuplicateCardProps } from './BatchToDuplicateCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledCenterBox,
  StyledImage,
  StyledLeftBox,
  StyledRightBox,
  StyledValueBox,
  StyledValuesWrapper,
  TextInlineBox,
} from './BatchToDuplicateCard.styles'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { View } from 'react-native'
import { Product } from '../../../../../../domain'
import { InterfaceColors } from '../../../../../../utilities/styling/theme/theme'
import BatchUtil from '../../../../../../utilities/utils/batch'
import { Switch } from 'react-native-gesture-handler'
import DirectusUtil from '../../../../../../utilities/utils/directus'

const BatchToDuplicateCard = ({ batch, selected, onClick }: BatchToDuplicateCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const OnClickBatch = () => {
    onClick(batch)
  }

  const batchComputed = BatchUtil.getBatchComputed({
    ...batch,
  })

  return (
    <StyledCardWrapper onPress={OnClickBatch} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <StyledImage
              source={DirectusUtil.getProductImageUrlFromId(batch.product as any as Product)}
              resizeMode="cover"
            />
          </StyledLeftBox>
          <StyledCenterBox>
            <Typography.CardTitle
              style={{
                lineHeight: theme.lineHeights.CARD_TITLE,
              }}
              ellipsis={true}
            >
              {batch.product?.label}
            </Typography.CardTitle>

            <TextInlineBox>
              <Typography.BodySmall colorName={'text-dark-3'}>
                par {FormatUtils.formatQuantity(batch.product?.packing, batch.product?.mesureType)}
              </Typography.BodySmall>
              <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
              <Typography.BodySmall colorName={'text-dark-3'}>
                Prix de départ : {FormatUtils.formatPrice(batch.unitPriceProducer)}
              </Typography.BodySmall>
            </TextInlineBox>
          </StyledCenterBox>
          <StyledRightBox>
            <Typography.BodySmall>{batchComputed.computed?.periodLabel}</Typography.BodySmall>
            <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
            <Switch
              trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
              onValueChange={() => onClick(batch)}
              value={selected}
            />
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default BatchToDuplicateCard
