import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCard = styled.View`
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  border-radius: 10px;
  width: auto;
`
const StyledCardContent = styled.View<{
  isFlexDirectionRow?: boolean
  isLowMargin?: boolean
  noMargin?: boolean
}>`
  margin: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.5}px;

  ${() =>
    Platform.OS === 'web' &&
    css`
      margin: ${({ theme }) => theme.spacingUnit * 1.8}px;
      margin-top: ${({ theme }) => theme.spacingUnit * 1.3}px;
      margin-bottom: ${({ theme }) => theme.spacingUnit * 1.3}px;
    `}

  ${({ isFlexDirectionRow }) =>
    isFlexDirectionRow &&
    css`
      flex-direction: row;
    `}

  ${({ isLowMargin }) =>
    isLowMargin &&
    css`
      margin-top: ${({ theme }) => theme.spacingUnit * 1}px;
      margin-bottom: ${({ theme }) => theme.spacingUnit * 1}px;
    `}

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `}
`

export { StyledCard, StyledCardContent }
