import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledList = styled.View`
  width: 100%;
`

const Styledloading = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 1.5}px;
  align-items: center;
`

const StyledButtonSeeMore = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export { StyledList, Styledloading, StyledButtonSeeMore }
