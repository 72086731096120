import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledPaginationWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 0.5}px;
  width: 100%;
`

const BoxWrapper = styled.View`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const Box = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 0.6}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 0.6}px;
  // min-width: 15px;
`

export { StyledPaginationWrapper, BoxWrapper, Box }
