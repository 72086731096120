import { BatchEnums } from '../../../../enums'
import {
  Batch,
  BatchComputed,
  Pagination,
  ProductBatchs,
  CartBatch,
  ProductType,
  Tour,
  Catalog,
} from '../../../domain'
import ApiService from '../../api.service'
import { PartialBatch } from '../../producer/batch'

const getOneBatch = async (batchId: string): Promise<Batch> => {
  const response = await ApiService.get(`/batch/${batchId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getProductTypesFromPropositionsBatchs = async (
  groupId: string,
  start?: Date,
  end?: Date,
): Promise<ProductType[]> => {
  const response = await ApiService.get(`/batch/propositions/group/${groupId}/productTypes`, {
    after: start ? new Date(start) : null,
    before: end ? new Date(end) : null,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOneTour = async (tourId: string): Promise<Tour> => {
  const response = await ApiService.get(`/tour/${tourId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}
const getAllBatchsTourComputed = async (
  tourId: string,
  start?: Date,
  end?: Date,
  producerId?: string,
  types?: BatchEnums.TYPE[],
  limit: number = 30,
  pageNumber: number = 1,
  orderBy?: 'PRODUCER' | 'PRODUCT_TYPE',
  selectedProducers?: string[],
  search?: string,
): Promise<{ data: ProductBatchs[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/batch/tour/${tourId}/computed`, {
    after: start ? new Date(start) : null,
    before: end ? new Date(end) : null,
    producerId: producerId ? producerId : null,
    types: types ? types : null,
    limit,
    page: pageNumber,
    orderBy: orderBy ? orderBy : null,
    selectedProducers: selectedProducers,
    search: search ? search : undefined,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

// const getAllBatchsByDateComputed = async (
//   start?: Date,
//   end?: Date,
//   tourId?: string,
//   types?: BatchEnums.TYPE[],
//   limit: number = 30,
//   pageNumber: number = 1,
// ): Promise<{ data: ProductBatchs[]; pagination: Pagination }> => {
//   const response = await ApiService.get(`/batch/producer/computed`, {
//     after: start ? new Date(start) : null,
//     before: end ? new Date(end) : null,
//     tourId: tourId ? tourId : null,
//     types: types ? types : null,
//     limit,
//     page: pageNumber,
//   })
//     .then((result) => result)
//     .catch((error) => {
//       throw error
//     })
//   return response
// }

const getComputed = async (
  productId?: string,
  partialBatch?: PartialBatch,
  tourId?: string,
): Promise<BatchComputed | null> => {
  if (!productId || !partialBatch || !partialBatch.unitPriceProducer) return null
  const response = await ApiService.get(`/batch/computed/product/${productId}`, {
    unitPriceProducer: partialBatch.unitPriceProducer,
    start: partialBatch.start,
    end: partialBatch.end,
    carrierFees: partialBatch.carrierFees,
    createdDate: partialBatch.createdDate,
    tourId: tourId,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getAllBatchs = async (
  tourId?: string,
  cartId?: string,
  limit: number = 30,
  pageNumber: number = 1,
  selectedCategories?: string[],
  selectedProducers?: string[],
  search?: string,
): Promise<Catalog> => {
  if (!tourId || !cartId) return {}

  const response = await ApiService.get(`/batch/tour/${tourId}/cart/${cartId}`, {
    limit,
    page: pageNumber,
    categories: selectedCategories,
    selectedProducers: selectedProducers,
    search,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const update = async (batchId: string, batch: any): Promise<Batch> => {
  const response = await ApiService.patch(`/batch/${batchId}/carrier`, batch).catch((error) => {
    throw error
  })
  return response
}

const updateCartBatchs = async (
  batchId: string,
  cartBatchs: Partial<CartBatch>[],
  tourId?: string,
): Promise<CartBatch[]> => {
  const body = {
    cartBatchs: cartBatchs,
  }
  const response = await ApiService.patch(`/batch/${batchId}/cartBatch`, body).catch((error) => {
    throw error
  })
  return response
}

const BatchCarrierService = {
  getOneBatch,
  getProductTypesFromPropositionsBatchs,
  update,
  updateCartBatchs,
  getAllBatchsTourComputed,
  getComputed,
  getOneTour,
  getAllBatchs,
}

export default BatchCarrierService
