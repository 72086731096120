/* eslint-disable global-require */
const harvyFonts = {
  Euclid: require('../../../../assets/fonts/EuclidCircularA-Regular.otf'),
  'Euclid-Medium': require('../../../../assets/fonts/EuclidCircularA-Medium.otf'),
  'Inter-ExtraLight': require('../../../../assets/fonts/Inter-ExtraLight-slnt.ttf'),
  Inter: require('../../../../assets/fonts/Inter-Regular-slnt.ttf'),
  'Inter-Medium': require('../../../../assets/fonts/Inter-Medium-slnt.ttf'),
  'Inter-SemiBold': require('../../../../assets/fonts/Inter-SemiBold-slnt.ttf'),
  'Inter-Bold': require('../../../../assets/fonts/Inter-Bold-slnt.ttf'),
  IcoMoon: require('../../../../assets/icons-fonts/icomoon.ttf'),
}

export default harvyFonts
