import styled from 'styled-components/native'

const StyledBottomButtonsWrapper = styled.View`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px ${({ theme }) => theme.spacingUnit * 2}px;
`

export default StyledBottomButtonsWrapper
