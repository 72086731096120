import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const CaptionWrapper = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 1}px;
`

const ToursWrapper = styled.View`
  width: 100%;
`

export { CaptionWrapper, ToursWrapper }
