import { createStackNavigator } from '@react-navigation/stack'
import HeaderHome from '../../components/HeaderHome'
import InvoiceStatementListScreen from '../../../screens/User/screens/UserListInvoiceStatement'
import ClientBillingScreen from '../../../screens/Client/ClientBilling'
import BillingClientNavigationStackParamList from './BillingClientNavigation.model'
import Header from '../../components/Header'

const Stack = createStackNavigator<BillingClientNavigationStackParamList>()

const BillingClientNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="ClientBilling"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ClientBilling"
        component={ClientBillingScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Gestion financière',
        }}
      />
      <Stack.Screen
        name="InvoiceStatementList"
        component={InvoiceStatementListScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ClientBilling" />,
          title: 'Relevés',
        }}
      />
    </Stack.Navigator>
  )
}

export default BillingClientNavigation
