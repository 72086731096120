import { DocumentEnums } from '../../../../enums/document'
import { Producer, Pagination } from '../../../domain'
import ApiService from '../../api.service'

const getAllProducers = async (clientId: string): Promise<Producer[]> => {
  const response = await ApiService.get(`/producer/client/${clientId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOneProducer = async (producerId: string, clientId: string): Promise<Producer> => {
  const response = await ApiService.get(`/producer/${producerId}/client/${clientId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const sendDocumentRequest = async (producerId: string, clientId: string) => {
  const reponse = await ApiService.post(
    `/producer/${producerId}/client/${clientId}/document-request/${DocumentEnums.DocumentTypeEnum.ORGANIC_CERTIFICATION}`,
    {},
  )
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return reponse
}

const ProducerClientService = {
  getAllProducers,
  getOneProducer,
  sendDocumentRequest,
}

export default ProducerClientService
