// eslint-disable-next-line import/prefer-default-export
export const getLoginDataInformations = (data: any) => {
  return {
    data: {
      token: data.token,
      email: data.email,
      user: data,
      access: data.access,
    },
    screenToRedirectTo: null,
  }
}
