import styled from 'styled-components/native'

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`
const ConditionsWrapper = styled.View`
  flex-direction: row;
  width: 100%;
  gap: 10px;
`
const DueDateWrapper = styled.View`
  flex: 5;
`
const InvoiceNumberWrapper = styled.View`
  flex: 3;
`

const ButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
`
export {
  StyledButtonWrapper,
  ConditionsWrapper,
  DueDateWrapper,
  InvoiceNumberWrapper,
  ButtonWrapper,
}
