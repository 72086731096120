import React from 'react'
import { TutorialStepCardsProps } from './TutorialStepCards.model'
import { StyledStepCardsWrapper } from './TutorialStepCards.styles'
import TutorialStepCard from '../TutorialStepCard'
import { TutorialStepsEnums } from '../../../enums/tutorialStep'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { CarrierStackParamList } from '../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'

const TutorialStepCards = ({
  tutorialSteps,
  tutorialStepsData,
  navigationType,
  onCardClick,
}: TutorialStepCardsProps) => {
  const navigationProducer = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const navigationCarrier = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const formattedStepsData: TutorialStepsEnums.TutorialStep[] = []
  tutorialSteps.forEach((step, i) => {
    const stepData = tutorialStepsData.find((data: any) => data.type === step.type)
    if (stepData) {
      formattedStepsData.push({
        ...step,
        completed: stepData.completed,
      })
    }
  })

  const onGoToStep = (step: TutorialStepsEnums.TutorialStep) => {
    if (navigationType == 'PRODUCER') {
      if (step.type == TutorialStepsEnums.ProducerTutorialStepTypeEnum.COMPLETE_PROPOSITION) {
        navigationProducer.navigate('PropositionProducerNavigationConfiguration', {
          screen: 'ProducerPropositions',
          params: {
            mode: 'DAY',
            start: new Date(
              tutorialStepsData.find(
                (tsd) =>
                  tsd.type === TutorialStepsEnums.ProducerTutorialStepTypeEnum.COMPLETE_PROPOSITION,
              )?.data?.nextTour?.deposit,
            ),
          },
        })
      }
      if (step.type == TutorialStepsEnums.ProducerTutorialStepTypeEnum.COMPLETE_ORDER) {
        navigationProducer.navigate('ProducerTours', {
          screen: 'ProducerTourOrders',
          params: {
            id: tutorialStepsData.find(
              (tsd) => tsd.type === TutorialStepsEnums.ProducerTutorialStepTypeEnum.COMPLETE_ORDER,
            )?.data?.nextTour?.id,
          },
        })
      }
      navigationProducer.navigate(step?.navigationParam?.navigation as any, {
        screen: step.navigationParam?.screen,
      })
      if (step.type == TutorialStepsEnums.ProducerTutorialStepTypeEnum.COMPLETE_HAS_GROUP) {
        navigationProducer.navigate('ProducerGroups', {
          screen: 'ProducerGroups',
          params: {},
        })
      } else {
        navigationProducer.navigate(step?.navigationParam?.navigation as any, {
          screen: step.navigationParam?.screen,
          params: step.navigationParam?.params || {},
        })
      }
    } else if (navigationType == 'CARRIER') {
      if (step.type == TutorialStepsEnums.GroupTutorialStepTypeEnum.COMPLETE_SHARED_TOUR) {
        navigationCarrier.navigate(step?.navigationParam?.navigation as any, {
          screen: step.navigationParam?.screen,
          params: {
            id: tutorialStepsData.find(
              (tsd) =>
                tsd.type === TutorialStepsEnums.GroupTutorialStepTypeEnum.COMPLETE_SHARED_TOUR,
            )?.data?.nextTours[0]?.id,
            mode: 'PRODUCERS',
          },
        })
      } else {
        navigationCarrier.navigate(step?.navigationParam?.navigation as any, {
          screen: step.navigationParam?.screen,
          params: step.navigationParam?.params || {},
        })
      }
    } else if (navigationType == 'TOUR') {
      if (
        step.type == TutorialStepsEnums.TourTutorialStepTypeEnum.COMPLETE_DELIVERY_EXPENSES_PARAMS
      ) {
        navigationCarrier.navigate(step.navigationParam?.screen as any, {
          id: tutorialStepsData.find(
            (tsd) =>
              tsd.type ===
              TutorialStepsEnums.TourTutorialStepTypeEnum.COMPLETE_DELIVERY_EXPENSES_PARAMS,
          )?.data?.tourId,
        })
      }
      if (step.type == TutorialStepsEnums.TourTutorialStepTypeEnum.COMPLETE_PRODUCER_TOUR) {
        // onCardClick?.('PRODUCERS')
        const tourId = tutorialStepsData.find(
          (tsd) =>
            tsd.type ===
            TutorialStepsEnums.TourTutorialStepTypeEnum.COMPLETE_DELIVERY_EXPENSES_PARAMS,
        )?.data?.tourId
        if (tourId) {
          navigationCarrier.navigate('CarrierTourAddProducers', { id: tourId })
        }
      }
      if (step.type == TutorialStepsEnums.TourTutorialStepTypeEnum.COMPLETE_CLIENT_TOUR) {
        // onCardClick?.('CLIENTS')
        const tourId = tutorialStepsData.find(
          (tsd) =>
            tsd.type ===
            TutorialStepsEnums.TourTutorialStepTypeEnum.COMPLETE_DELIVERY_EXPENSES_PARAMS,
        )?.data?.tourId
        if (tourId) {
          navigationCarrier.navigate('CarrierTourAddClients', { id: tourId })
        }
      }
    }
  }

  const renderSteps = () => {
    let firstStepCompleted: number | undefined = undefined
    let stepActive: number | undefined = undefined

    return formattedStepsData.map((step, index) => {
      let status: TutorialStepsEnums.TutorialStepStatusEnum =
        TutorialStepsEnums.TutorialStepStatusEnum.DISABLED
      if (step.completed && !stepActive) {
        firstStepCompleted = step.ranking
        status = TutorialStepsEnums.TutorialStepStatusEnum.COMPLETED
      } else if (!stepActive) {
        stepActive = step.ranking
        status = TutorialStepsEnums.TutorialStepStatusEnum.ACTIVE
      }

      return (
        <TutorialStepCard
          key={index}
          step={step}
          onPress={() => onGoToStep(step)}
          status={status}
        />
      )
    })
  }

  return <StyledStepCardsWrapper>{renderSteps()}</StyledStepCardsWrapper>
}

export default TutorialStepCards
