import { TruckInfosFormProps } from './TruckInfosForm.model'
import { Button, Col, Dropdown, PageTitle, Row, Spacer, TextInput } from '../../../../../components'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { MailEnums } from '../../../../../../enums'
import { useEffect, useState } from 'react'
import { CarrierFeesEnums } from '../../../../../../enums/carrierFees'
import { StyledButtonWrapper } from './TruckInfosForm.styles'
import InfoBox from '../../../../../components/InfoBox'

const TruckInfosForm = ({
  deliveryExpensesParameters,
  capacity,
  onClickNext,
}: TruckInfosFormProps) => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [selectedTruckType, setSelectedTruckType] = useState<any>()
  const [selectedFuelType, setSelectedFuelType] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [updatedCapacity, setUpdatedCapacity] = useState<any>((capacity || 0) / 1000000)
  const [updatedDistance, setUpdatedDistance] = useState<any>()
  const [updatedAnnexCosts, setUpdatedAnnexCosts] = useState<any>(undefined)

  useEffect(() => {
    setUpdatedAnnexCosts(deliveryExpensesParameters.annexCosts)
  }, [deliveryExpensesParameters])

  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTour'>>()
  const { id } = route?.params ?? { id: '' }
  useEffect(() => {
    setUpdatedCapacity(capacity / 1000000)
    setUpdatedDistance(deliveryExpensesParameters?.distance || 0)
    setSelectedTruckType(
      deliveryExpensesParameters?.truckType
        ? { value: deliveryExpensesParameters?.truckType }
        : undefined,
    )
    setSelectedFuelType(
      deliveryExpensesParameters?.fuelType
        ? { value: deliveryExpensesParameters?.fuelType }
        : undefined,
    )

    setUpdatedAnnexCosts(deliveryExpensesParameters?.annexCosts || 0)
  }, [deliveryExpensesParameters])

  if (!id) return null
  const info = {
    title: 'Pourquoi dois-je préciser la distance ? ',
    content: `Cela nous permettra de calculer votre coût de livraison.`,
  }

  const onChangeTruckType = (value: any) => {
    setSelectedTruckType(value)
  }

  const onChangeFuelType = (value: any) => {
    setSelectedFuelType(value)
  }

  const handleClickNext = () => {
    onClickNext(
      selectedTruckType.value,
      selectedFuelType.value,
      updatedCapacity * 1000000,
      parseFloat(updatedDistance),
      updatedAnnexCosts,
    )
  }

  return (
    <>
      <Row>
        <Col xs={7} sm={7}>
          <Dropdown
            label={'Type de camion'}
            onChange={onChangeTruckType}
            multiple={false}
            defaultValue={selectedTruckType?.value}
            optionsDefault={CarrierFeesEnums.TRUCK_TYPES.map((cfr) => ({
              label: cfr.label,
              value: cfr.value,
            }))}
            zIndex={1000}
            zIndexInverse={4000}
            itemKey="truckType"
          />
        </Col>
        <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
        <Col xs={4} sm={4}>
          <TextInput
            value={updatedCapacity?.toString()}
            label="Capacité (en m3)"
            field="text"
            onChangeText={(text) => setUpdatedCapacity(text)}
            hidden={selectedTruckType == undefined}
          />
        </Col>
      </Row>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      {selectedTruckType !== undefined && updatedCapacity > 0 && (
        <Row>
          <Col xs={6} sm={6}>
            <Dropdown
              label={'Type de carburant'}
              onChange={onChangeFuelType}
              multiple={false}
              defaultValue={selectedFuelType?.value}
              optionsDefault={CarrierFeesEnums.FUEL_TYPES.map((cfr) => ({
                label: cfr.label,
                value: cfr.value,
              }))}
              zIndex={1000}
              zIndexInverse={4000}
              itemKey="fuelType"
            />
          </Col>
        </Row>
      )}

      {selectedFuelType && (
        <>
          <PageTitle title="Distance" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <InfoBox title={info.title} content={info.content} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <TextInput
            label="Distance parcourue (en km)"
            field="text"
            value={updatedDistance?.toString()}
            onChangeText={(text) => setUpdatedDistance(text)}
          />
        </>
      )}

      {selectedFuelType && updatedDistance > 0 && (
        <>
          <PageTitle title="Frais annexes" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          <TextInput
            label="Coûts annexes (péages, camion ...)(en €)"
            value={updatedAnnexCosts?.toString()}
            field="text"
            onChangeText={(text) => setUpdatedAnnexCosts(text)}
          />
        </>
      )}
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
      {selectedFuelType && updatedDistance > 0 && (
        <StyledButtonWrapper>
          <Button label={'Suivant'} onPress={() => handleClickNext()} />
        </StyledButtonWrapper>
      )}
    </>
  )
}

export default TruckInfosForm
