import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { Switch } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import {
  Card,
  Col,
  Dropdown,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  TextInput,
  Typography,
} from '../../../components'
import { Batch, Pagination, Producer, ProducerGroup, ProductBatchs, Tour } from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import {
  StyledModeSwitchWrapper,
  TextInputWrapper,
  ToursContentWrapper,
} from './CarrierTourBatchs.styles'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import CarrierTourBatchsTopBox from './components/CarrierTourBatchsTopBox'
import Loader from '../../../components/Loader'
import { StackNavigationProp } from '@react-navigation/stack'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import BatchCarrierService from '../../../services/carrier/batch'
import BatchsCard from '../../../modules/Batch/BatchsCard'
import PaginationBar from '../../../components/PaginationBar'
import { UserEnums } from '../../../../enums'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import GroupCarrierService from '../../../services/carrier/group'

const CarrierTourBatchsScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()
  const [mode, setMode] = useState<number>(1)

  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourBatchs'>>()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [pageSizeBatchs, setPageSizeBatchs] = useState(20)
  const [pageNumberBatchs, setPageNumberBatchs] = useState(1)
  const [selectedProducer, setSelectedProducer] = useState<any>()
  const [searchValue, setSearchValue] = useState('')

  const { id } = route?.params ?? { id: '' }

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = TourCarrierService.getOne.query(id)

  const {
    data: tourBatchs,
    refetch: refetchTourBatchs,
    isInitialLoading: tourBatchsLoading,
  } = useQuery<Tour, Error>(['sf_tour_batchs', id], () => TourCarrierService.getOneTourBatchs(id), {
    keepPreviousData: true,
  })

  const {
    data: productsBatchs,
    refetch: refetchProductBatchs,
    isRefetching: productBatchsRefetching,
    isLoading: productBatchsLoading,
  } = useQuery<{ data: ProductBatchs[]; pagination: Pagination }, Error>(
    ['sf_product_batchs', id, searchValue],
    () =>
      BatchCarrierService.getAllBatchsTourComputed(
        id,
        undefined,
        undefined,
        undefined,
        [],
        pageSizeBatchs,
        pageNumberBatchs,
        'PRODUCT_TYPE',
        selectedProducer?.id ? [selectedProducer.id] : undefined,
        searchValue,
      ),
    {
      keepPreviousData: true,
    },
  )

  const { data: producerGroups } = GroupCarrierService.useGetOneGroupProducers({
    groupId: getAccessInfos().carrierGroupId,
    carrierId: getAccessInfos().carrierId,
    tourId: id,
    limit: 500,
    page: 1,
  })

  useEffect(() => {
    refetchProductBatchs()
  }, [searchValue, selectedProducer, pageNumberBatchs])

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  const getTourProducersWithoutBatchs = (): Producer[] => {
    if (!tour) return []
    if (!tour.producerTour) return []

    let producersWithoutBatchs: Producer[] = []
    tour.producerTour?.map((producerTour) => {
      const foundBatch = (tourBatchs?.batchs || []).find(
        (b) => b.product?.producer?.id === producerTour.producer.id,
      )
      if (!foundBatch) {
        producersWithoutBatchs.push(producerTour.producer)
      }
    })
    return producersWithoutBatchs
  }

  const onChangePage = async (pageNumber: number) => {
    await setPageNumberBatchs(pageNumber)
  }

  const onChangeSearchValue = async (newSearchValue: string) => {
    setSearchValue(newSearchValue)
    setPageNumberBatchs(1)
  }

  const onChangProducerValue = async (selectedProducer: any) => {
    setSelectedProducer(selectedProducer)
    setPageNumberBatchs(1)
  }

  if (tourLoading || productBatchsLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour) return null

  const OnClickBatch = (batch: Batch) => {
    navigation.navigate('CarrierUpdateBatch', { batchId: batch.id, tourId: tour.id })
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Lots Tournée #${getTourIdFormatted()}`}
            buttonRight={
              <>
                <Typography.BodySmall>
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                  {'\n'}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </>
            }
          />
          <CarrierTourBatchsTopBox
            tour={tour}
            tourBatchs={tourBatchs}
            emptyPropositions={getTourProducersWithoutBatchs().length} // getEmptyPropositionCount()}
          />

          <Row>
            <Col xs={12} md={7}>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Dropdown
                label={'Choisir un producteur'}
                onChange={(value) => onChangProducerValue(value)}
                multiple={false}
                defaultValue={selectedProducer?.id}
                optionsDefault={[
                  {
                    label: 'Tous',
                    value: undefined,
                  },
                  ...(producerGroups?.data || []).map((producerGroup) => ({
                    ...producerGroup.producer,
                    label: `${producerGroup.producer?.label}`,
                    value: producerGroup.producer?.id,
                  })),
                ]}
                zIndex={2000}
                itemKey="producers"
                small
                isSearchable
              />
            </Col>
            <Col xs={12} md={5}>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

              <TextInput
                field="text"
                label="Rechercher un lot par nom"
                value={searchValue}
                onChangeText={onChangeSearchValue}
                small
              />
            </Col>
          </Row>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          {productBatchsRefetching && <Loader isLoading />}

          {!productBatchsRefetching && !productsBatchs?.pagination.totalCount && (
            <Typography.BodySmall
              colorName="text-dark-3"
              style={{ marginLeft: theme.spacingUnit * 2 }}
            >
              Aucune proposition de lots dans cette tournée
            </Typography.BodySmall>
          )}

          {!productBatchsRefetching &&
            (productsBatchs?.data || []).map((productBatch) => {
              return (
                <BatchsCard
                  key={productBatch.product?.id}
                  productBatchs={productBatch}
                  onClick={OnClickBatch}
                  displayProducer
                  userRole={UserEnums.RoleEnums.CARRIER}
                />
              )
            })}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

          <PaginationBar
            data={productsBatchs?.data}
            pagination={productBatchsRefetching ? undefined : productsBatchs?.pagination}
            onChangePage={onChangePage}
          />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

          {mode === 1 && (
            <>
              <SectionTitle title={`Mercuriale(s) vide(s) `} />
              {getTourProducersWithoutBatchs().map((producer) => {
                return (
                  <>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                    <Typography.SectionTitle>{producer.label} (0 lots)</Typography.SectionTitle>
                  </>
                )
              })}
            </>
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={6} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourBatchsScreen
