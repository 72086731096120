import styled from 'styled-components/native'

const StyledStatsWrapper = styled.View`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  flex-direction: row;
`

const StyledButtonWrapper = styled.TouchableOpacity`
  width: 100%;
  align-items: center;
  justify-content: center;
`

export { StyledStatsWrapper, StyledButtonWrapper }
