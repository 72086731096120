import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  margin-left: auto;
  max-width: 200px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
`

const StyledMetricsWrapper = styled.View`
  width: 100%;
`

export { StyledCardWrapper, StyledMetricsWrapper }
