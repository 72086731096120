import styled, { css } from 'styled-components/native'

const StyledLogoBox = styled.View`
  width: 96%;
  height: ${({ theme }) => theme.spacingUnit * 8}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 10}px;
  align-items: center;
`

const StyledImage = styled.Image`
  width: 75%;
  height: 100%;
`
const SplashScreenWrapper = styled.View`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors['background-1']};
  justify-content: top;
  align-items: center;
`
const SplashScreenContent = styled.View`
  width: 100%;
  padding: ${({ theme }) => theme.spacingUnit * 2}px;
`

export { StyledImage, StyledLogoBox, SplashScreenWrapper, SplashScreenContent }
