import ApiService from '../../api.service'

export type ClientDashboardMetrics = {
  nbProducers: number
  nbProducts: number
  nbActualProducts: number
}

const getDashboardMetrics = async (
  clientId: string,
  start: Date,
  end: Date,
): Promise<ClientDashboardMetrics> => {
  const response = await ApiService.get(`/metrics/dashboard/client/${clientId}`, {
    start: start,
    end: end,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const MetricsClientService = {
  getDashboardMetrics,
}

export default MetricsClientService
