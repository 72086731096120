import { RouteProp, useRoute } from '@react-navigation/native'
import { useState } from 'react'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { useQuery } from '@tanstack/react-query'
import { Tour } from '../../../domain'
import TourCarrierService from '../../../services/carrier/tour'
import { PageTitle, Spacer } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import CarrierTourLogisticsLandingPage from './slides/LandingPage'
import useGroupContext from '../../../utilities/hook/useGroupContext'
import AddProducersTour from './slides/AddProducersTour'
import LogisticsCommissionForm from './slides/LogisticsCommissionForm'
import MarketValueForm from './slides/MarketValueForm'

const carrierTourAddProducersModes = [
  {
    label: 'Invitez des producteurs',
    value: 'LANDING',
  },
  {
    label: 'Valeur marchande',
    value: 'MARKET_VALUE',
  },
  {
    label: 'Commission logistique',
    value: 'LOGISTIC_COMMISSION',
  },
  {
    label: 'Ajoutez des producteurs à la tournée',
    value: 'ADD_PRODUCERS_TOUR',
  },
]

const CarrierTourAddProducers = () => {
  const [selectedMode, setSelectedMode] = useState<string | number>(
    carrierTourAddProducersModes[0].value,
  )
  const [marketValue, setMarketValue] = useState<number | undefined>(undefined)
  const [carrierFees, setCarrierFees] = useState<number | undefined>(undefined)
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTour'>>()
  const { id } = route?.params ?? { id: '' }
  const { data: tour } = TourCarrierService.getOne.query(id)

  const { group } = useGroupContext()

  const onValidateStep1 = () => {
    setSelectedMode('MARKET_VALUE')
  }

  const onValidateStep2 = async (value: number) => {
    setMarketValue(value)
    setSelectedMode('LOGISTIC_COMMISSION')
  }

  const onValidateStep3 = async (carrierFees: number) => {
    setCarrierFees(carrierFees)
    const updatedCarrierFees = {
      carrierFees: carrierFees,
    }
    await TourCarrierService.update(id, updatedCarrierFees)
    setSelectedMode('ADD_PRODUCERS_TOUR')
  }

  const handleGoBack = (
    selectedMode: 'LANDING' | 'MARKET_VALUE' | 'LOGISTIC_COMMISSION' | 'ADD_PRODUCERS_TOUR',
  ) => {
    setSelectedMode(selectedMode)
  }

  const renderSelectedComponent = () => {
    switch (selectedMode) {
      case 'LANDING':
        return (
          <>
            <PageTitle title="Invitez des producteurs" />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.6} />
            <CarrierTourLogisticsLandingPage onClickNext={onValidateStep1} />
          </>
        )
      case 'MARKET_VALUE':
        return (
          <>
            <PageTitle title="Valeur marchande" />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.6} />
            <MarketValueForm
              onClickNext={onValidateStep2}
              onGoBack={handleGoBack}
              currentMarketValue={marketValue}
            />
          </>
        )
      case 'LOGISTIC_COMMISSION':
        return (
          <>
            <PageTitle title="Commission logistique" />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.6} />
            <LogisticsCommissionForm
              carrierExpenses={tour?.carrierExpenses}
              onClickNext={onValidateStep3}
              onGoBack={handleGoBack}
              marketValue={marketValue}
              currentCarrierFees={carrierFees}
            />
          </>
        )
      case 'ADD_PRODUCERS_TOUR':
        return (
          <>
            <PageTitle title="Ajoutez des producteurs à la tournée" />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.6} />
            <AddProducersTour group={group} tour={tour} onGoBack={handleGoBack} />
          </>
        )
      default:
        return null
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>{renderSelectedComponent()}</ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourAddProducers
