import { Stop, Tour } from '../../../domain'
import ApiService from '../../api.service'

const getOneStop = async (tourId: string, stopId: string): Promise<Stop> => {
  const response = await ApiService.get(`/tour/${tourId}/stop/${stopId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const create = async (tourId: string, stop: any): Promise<Stop> => {
  const response = await ApiService.post(`/tour/${tourId}/stop`, stop).catch((error) => {
    throw error
  })
  return response
}

const update = async (tourId: string, stopId: string, stop: any): Promise<Stop> => {
  const response = await ApiService.patch(`/tour/${tourId}/stop/${stopId}`, stop).catch((error) => {
    throw error
  })
  return response
}

const remove = async (tourId: string, stopId: string): Promise<Stop> => {
  const response = await ApiService.remove(`/tour/${tourId}/stop/${stopId}`).catch((error) => {
    throw error
  })
  return response
}

const arrangeStops = async (
  tourId: string,
  stops: { id: string; ranking: number | null }[],
): Promise<Tour> => {
  const response = await ApiService.post(`/tour/${tourId}/arrange`, stops).catch((error) => {
    throw error
  })
  return response
}

const StopCarrierService = {
  getOneStop,
  create,
  update,
  remove,
  arrangeStops,
}

export default StopCarrierService
