import React from 'react'
import { useTheme } from 'styled-components/native'
import { Card, Icon, Icons, Spacer, Typography } from '../../../../../../components'
import { CardContentWrapper, StyledCardParam } from './ClientCatalogNavBarSwitcher.styles'
import { Switch } from 'react-native-gesture-handler'
import { ClientCatalogNavBarSwitcherProps } from './ClientCatalogNavBarSwitcher.model'

function ClientCatalogNavBarSwitcher({
  onChangeDisplayMode,
  displayMode,
}: ClientCatalogNavBarSwitcherProps) {
  const theme = useTheme()

  return (
    <StyledCardParam>
      <Card>
        <CardContentWrapper>
          <Typography.BodySmall colorName="text-dark-3">par produits</Typography.BodySmall>
          <Switch
            trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
            onValueChange={() => onChangeDisplayMode()}
            value={displayMode === 2}
          />
          <Typography.BodySmall colorName="text-dark-3">par producteurs</Typography.BodySmall>
        </CardContentWrapper>
      </Card>
    </StyledCardParam>
  )
}

export default ClientCatalogNavBarSwitcher
