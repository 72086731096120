import styled from 'styled-components/native'

const StyledLogoBox = styled.View`
  width: 100%;
  height: ${({ theme }) => theme.spacingUnit * 5}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 2}px;
  align-items: center;
`

const StyledImage = styled.Image`
  width: 50%;
  height: 100%;
`

export { StyledLogoBox, StyledImage }
