import styled, { css } from 'styled-components/native'
import { InterfaceColors } from '../../utilities/styling/theme/theme'

const StyledView = styled.View<{ backgroundColor?: keyof InterfaceColors; extraSmall?: boolean }>`
  flex-direction: row;
  width: fit-content;
  height: fit-content;

  ${({ theme, backgroundColor, extraSmall }) => {
    if (backgroundColor && backgroundColor !== undefined) {
      return css`
        background-color: ${theme.colors[backgroundColor]};
        border-radius: ${theme.borderRadius * (extraSmall ? 0.7 : 1)}px;
        padding: ${({ theme }) => theme.spacingUnit * (extraSmall ? 0.3 : 0.3)}px
          ${({ theme }) => theme.spacingUnit * (extraSmall ? 0.7 : 0.6)}px;
      `
    }
  }};
`

export default StyledView
