import { useQuery } from '@tanstack/react-query'
import { Batch, CartBatch, Pagination, Tour } from '../../../domain'
import ApiService from '../../api.service'
import { queryClient } from '../../../utilities/queryClient'

const getAllTours = async (
  carrierId: string,
  next?: boolean,
  previous?: boolean,
  limit: number = 30,
  pageNumber: number = 1,
  withPriceReportings?: boolean,
  toConclude?: boolean,
  after?: Date,
  before?: Date,
): Promise<{ data: Tour[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/tour/carrier/${carrierId}`, {
    after: next ? new Date() : after ? after : null,
    before: previous ? new Date() : before ? before : null,
    limit,
    page: pageNumber,
    withPriceReportings: withPriceReportings ? true : undefined,
    toConclude: toConclude ? true : undefined,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOneTourBatchs = async (tourId: string): Promise<Tour> => {
  const response = await ApiService.get(`/tour/${tourId}/batch`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const getOneTourCartBatchs = async (tourId: string): Promise<CartBatch[]> => {
  const response = await ApiService.get(`/tour/${tourId}/cart_batch_by_ranking`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const getOneTourProducer = async (tourId: string, producerId: string): Promise<Tour> => {
  const response = await ApiService.get(`/tour/${tourId}/producer/${producerId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const create = async (carrierId: string, newTour: any): Promise<Tour> => {
  const response = await ApiService.post(`/tour/carrier/${carrierId}`, newTour).catch((error) => {
    throw error
  })
  return response
}

const createAsProducer = async (
  groupId: string,
  carrierId: string,
  newTour: any,
): Promise<Tour> => {
  const response = await ApiService.post(
    `/tour/group/${groupId}/carrier/${carrierId}`,
    newTour,
  ).catch((error) => {
    throw error
  })
  return response
}
const duplicate = async (tourId: string, body: any): Promise<Tour> => {
  const response = await ApiService.post(`/tour/${tourId}/duplicate`, body).catch((error) => {
    throw error
  })
  return response
}

const update = async (tourId: string, tour: any): Promise<Tour> => {
  const response = await ApiService.patch(`/tour/${tourId}`, tour).catch((error) => {
    throw error
  })
  return response
}

const conclude = async (tourId: string, tour: any): Promise<Tour> => {
  const response = await ApiService.patch(`/tour/${tourId}/conclude`, tour).catch((error) => {
    throw error
  })
  return response
}

const assignCartBatchs = async (tourId: string): Promise<Tour> => {
  const response = await ApiService.post(`/tour/${tourId}/assign_cart_batchs`, {}).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const removeProducer = async (tourId: string, idProducer: any): Promise<Tour> => {
  const response = await ApiService.remove(`/tour/${tourId}/producer/${idProducer}`).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const deleteTour = async (tourId: string): Promise<string> => {
  const response = await ApiService.remove(`/tour/${tourId}`).catch((error) => {
    throw error
  })
  return response
}

const join = async (producerId: string, tourId: string): Promise<Tour> => {
  const response = await ApiService.post(
    `/tour/${tourId}/producer/${producerId}/join-carrier`,
    {},
  ).catch((error) => {
    throw error
  })
  return response
}

const sendMail = async (tourId: string, body: any): Promise<Tour> => {
  const response = await ApiService.post(`/tour/${tourId}/send-mail`, body).catch((error) => {
    throw error
  })
  return response
}

const getAllDayArrivalBatchs = async (
  idCarrier: string,
  start?: Date,
  end?: Date,
): Promise<{ batchs: Batch[]; tours: Tour[] }> => {
  const response = await ApiService.get(`/tour/arrival/carrier/${idCarrier}/batch`, {
    after: start ? new Date(start) : null,
    before: end ? new Date(end) : null,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const updateDeliveryExpenses = async (tourId: string, body: any): Promise<Tour> => {
  const response = await ApiService.patch(`/tour/${tourId}/update-delivery-expenses`, body).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const inviteProducerTour = async (tourId: string, body: any): Promise<any> => {
  const response = await ApiService.post(`/tour/${tourId}/add-producer-tour`, body).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const inviteClientTour = async (tourId: string, body: any): Promise<any> => {
  const response = await ApiService.post(`/tour/${tourId}/add-client-tour`, body).catch((error) => {
    throw error
  })
  return response
}

const TourCarrierService = {
  getAllTours,
  getOne: (() => {
    async function get(tourId: string): Promise<Tour> {
      //Warning, that endpoint is very slow
      return await ApiService.get(`/tour/${tourId}`, {})
    }
    function getQueryKey(tourId: string) {
      return ['sf_tour', tourId]
    }
    function query(tourId: string) {
      return useQuery<Tour, Error>({
        queryKey: getQueryKey(tourId),
        queryFn: () => get(tourId),
        cacheTime: 5 * 60 * 1000,
        staleTime: 2 * 60 * 1000,
      })
    }
    function invalidate(tourId: string) {
      queryClient.invalidateQueries(getQueryKey(tourId))
    }

    return {
      getQueryKey,
      query,
      invalidate,
    }
  })(),
  getOneTourBatchs,
  getOneTourCartBatchs,
  getOneTourProducer,
  create,
  duplicate,
  update,
  conclude,
  assignCartBatchs,
  removeProducer,
  sendMail,
  join,
  deleteTour,
  getAllDayArrivalBatchs,
  createAsProducer,
  updateDeliveryExpenses,
  inviteProducerTour,
  inviteClientTour,
}

export default TourCarrierService
