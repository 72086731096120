import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const BoxWrapper = styled.View`
  width: 100%;
`

const StyledMiddleWrapper = styled.View``

const StyledRightWrapper = styled.View``

export { StyledMiddleWrapper, StyledRightWrapper, BoxWrapper }
