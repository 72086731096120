import Body from './Body'
import BodySmall from './BodySmall'
import BodyExtraSmall from './BodyExtraSmall'
import Caption from './Caption'
import Danger from './Danger'
import Description from './Description'
import FormItem from './FormItem'
import SectionTitle from './SectionTitle'
import CardTitle from './CardTitle'
import InputLabel from './InputLabel'
import Menu from './Menu'
import PageTitle from './PageTitle'
import Title from './Title'
import ButtonLabel from './ButtonLabel'

const Typography = {
  Title,
  PageTitle,
  CardTitle,
  SectionTitle,
  Body,
  BodySmall,
  BodyExtraSmall,
  Description,
  InputLabel,
  Menu,
  Danger,
  FormItem,
  Caption,
  ButtonLabel,
}

export default Typography
