import styled from 'styled-components/native'

const ToursContentWrapper = styled.View`
  flex: 1;
  width: 95%;
  margin: ${({ theme }) => theme.spacingUnit * 1}px;
  margin-top: 0;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const StyledBackButtonWrapper = styled.View`
  width: 100%;
`
const StyledTouchableOpacity = styled.TouchableOpacity`
  width: 40px;
  width: 100%;
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`

export { ToursContentWrapper, StyledButtonWrapper, StyledTouchableOpacity, StyledBackButtonWrapper }
