import React, { useEffect } from 'react'
import { useTheme } from 'styled-components/native'
import {
  ButtonWrapper,
  PendingAccountContent,
  PendingAccountWrapper,
  StyledImage,
  StyledLogoBox,
} from './PendingAccount.styles'
import Loader from '../../../components/Loader'
import { Button, Spacer, Typography } from '../../../components'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import HelpFooter from '../../../components/HelpFooter'
import { UserEnums } from '../../../../enums'

const PendingAccountScreen = () => {
  const theme = useTheme()
  const navigationProducer = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const { getAccessInfos, viewType, logout, changeViewType, switchProfile } =
    useAuthenticationContext()

  useEffect(() => {
    switchProfile()
  }, [])

  const onChangeViewType = async () => {
    if (viewType === 2) {
      navigationProducer.navigate('ProfileSwitcher', {})
    }
  }

  return (
    <ScreenSafeAreaWrapper noPaddingLeft>
      <ContentWrapper alignCenter noHeader noDrawer>
        <PendingAccountWrapper>
          <PendingAccountContent>
            <StyledLogoBox>
              <StyledImage
                source={require('../../../../assets/logos/logo_harvy.png')}
                resizeMode="contain"
              />
            </StyledLogoBox>
            <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.Body colorName="color-primary" bold align="center">
              Validation en cours
            </Typography.Body>
            <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.Body colorName="color-primary">
              Votre inscription est en cours de validation par l’équipe (moins de 24h). Cette étape
              nous permet de garantir une mise en relation de qualité avec les producteurs autour de
              chez vous. Vous serez notifier par e-mail une fois votre compte validé
            </Typography.Body>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.Body colorName="color-primary">
              Cette étape nous permet de garantir une mise en relation de qualité entre producteurs.
            </Typography.Body>
            <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
            {getAccessInfos()?.nbProfiles > 1 ? (
              <Button label={'Changer de compte'} onPress={() => onChangeViewType()} />
            ) : null}
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <ButtonWrapper>
              <Button
                label="Se déconnecter"
                colorName="color-grey"
                onPress={() => logout()}
                small
              />
            </ButtonWrapper>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <HelpFooter short />
          </PendingAccountContent>
        </PendingAccountWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default PendingAccountScreen
