import { useEffect, useState } from 'react'
import { Button, SectionTitle, Spacer, TextInput } from '../../../../../components'
import InfoBox from '../../../../../components/InfoBox'
import { LogisticsCommissionProps } from './LogisticsCommission.model'
import { ButtonsWrapper, StyledButtonWrapper } from './LogisticsCommission.styles'
import FormatUtils from '../../../../../utilities/utils/format'

const LogisticsCommissionForm = ({
  carrierExpenses,
  onClickNext,
  onGoBack,
  marketValue,
  currentCarrierFees,
}: LogisticsCommissionProps) => {
  const [carrierFees, setCarrierFees] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)

  const infoBoxContent = {
    title: 'Comment définir ma commission logistique ?',
    content: `Si votre coût de livraison est de 200€ et que vous transportez en moyenne 1000€ de marchandise, vous couvrez vos coûts avec une commission à 20%, toutefois nous vous conseillons de commencer à 2-5% plus haut.`,
  }

  useEffect(() => {
    let computedCarrierFees = undefined
    if (carrierExpenses && marketValue) {
      computedCarrierFees = Math.ceil((carrierExpenses / Number(marketValue)) * 100)
    }
    const tempCarrierFees = currentCarrierFees || computedCarrierFees
    setCarrierFees(tempCarrierFees ? tempCarrierFees.toString() : '')
  }, [carrierExpenses, marketValue])

  const handleClickSubmitCarrierFees = () => {
    if (!carrierFees) {
      return
    }
    setIsLoading(true)
    onClickNext(FormatUtils.stringToFloat(carrierFees))
    setIsLoading(false)
  }

  return (
    <>
      <InfoBox title={infoBoxContent.title} content={infoBoxContent.content} />
      <SectionTitle title="Commission logistique" />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      <TextInput
        label="Commission logistique (en %)"
        field="text"
        value={carrierFees}
        onChangeText={(text) => setCarrierFees(text)}
      />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      <ButtonsWrapper>
        <StyledButtonWrapper>
          <Button
            label={'Retour'}
            onPress={() => onGoBack('MARKET_VALUE')}
            colorName="color-grey"
          />
        </StyledButtonWrapper>
        <StyledButtonWrapper>
          <Button
            label="Suivant"
            onPress={() => handleClickSubmitCarrierFees()}
            loading={isLoading}
            disabled={!carrierFees}
          />
        </StyledButtonWrapper>
      </ButtonsWrapper>
    </>
  )
}

export default LogisticsCommissionForm
