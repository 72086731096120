import styled, { css } from 'styled-components/native'

const StyledTitleWrapper = styled.View<{ noMarginTop?: boolean }>`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.2}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.8}px;

  ${({ noMarginTop }) =>
    noMarginTop &&
    css`
      margin-top: 0px;
    `}
`

export { StyledTitleWrapper }
