import { useNavigation } from '@react-navigation/native'
import { ProducerPropositionsV2FiltersProps } from './ProducerPropositionsV2Filters.model'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import SliderMenu from '../../../../../components/SliderMenu'
import { Button, Spacer } from '../../../../../components'
import DateSwitcher from '../../../../../components/DateSwitcher'
import FormatUtils from '../../../../../utilities/utils/format'
import { useEffect, useState } from 'react'
import { set } from 'date-fns'

const propositionModes = [
  {
    label: 'Journée',
    value: 'DAY',
  },
  {
    label: 'Semaine',
    value: 'WEEK',
  },
  {
    label: 'Stock',
    value: 'MONTH',
  },
]

const ProducerPropositionsV2Filters = ({
  propositionsFilters,
  onChangeFilters,
  nextTours,
}: ProducerPropositionsV2FiltersProps) => {
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [lastDaySelected, setLastDaySelected] = useState<Date | undefined>(undefined)

  const onSelectElementSliderMenu = async (value: string | number) => {
    if (!(value === 'DAY' || value === 'WEEK' || value === 'MONTH')) return
    let tempPropositionsFilters = { ...propositionsFilters }
    let tempStartDate = new Date(tempPropositionsFilters.start)
    let tempEndDate = new Date(tempPropositionsFilters.end)

    // formatting start & end date regarding the new mode
    if (value === 'DAY') {
      if (propositionsFilters.mode === 'MONTH') {
        tempStartDate = lastDaySelected ? lastDaySelected : new Date()
      } else if (
        lastDaySelected &&
        FormatUtils.startOfWeek(lastDaySelected) === FormatUtils.startOfWeek(tempStartDate)
      ) {
        tempStartDate = lastDaySelected
        setLastDaySelected(undefined)
      }
      tempEndDate = new Date(tempStartDate)
    }
    if (value === 'WEEK') {
      if (tempStartDate < new Date()) {
        tempStartDate = new Date()
      }
      tempStartDate.setDate(FormatUtils.startOfWeek(tempStartDate))
      tempEndDate = new Date(tempStartDate)
      tempEndDate.setDate(tempEndDate.getDate() + 6)

      if (!lastDaySelected && propositionsFilters.mode === 'DAY') {
        setLastDaySelected(propositionsFilters.start)
      } else {
        setLastDaySelected(undefined)
      }
    }
    if (value === 'MONTH') {
      tempStartDate = new Date(tempStartDate.getFullYear(), tempStartDate.getMonth(), 1, 0, 0, 0)
      tempEndDate = new Date(tempStartDate.getFullYear(), tempStartDate.getMonth() + 1, 0, 0, 0, 0)
      if (!lastDaySelected && propositionsFilters.mode === 'DAY') {
        setLastDaySelected(propositionsFilters.start)
      } else {
        setLastDaySelected(undefined)
      }
    }

    // clean hours on start and end date
    tempStartDate.setHours(0)
    tempStartDate.setMinutes(0)
    tempStartDate.setSeconds(0)

    tempEndDate.setHours(23)
    tempEndDate.setMinutes(59)
    tempEndDate.setSeconds(59)

    onChangeFilters({
      ...propositionsFilters,
      mode: value,
      start: tempStartDate,
      end: tempEndDate,
    })
    return
  }

  const onChangeDates = (dates: { start: Date; end: Date }) => {
    onChangeFilters({
      ...propositionsFilters,
      start: dates.start,
      end: dates.end,
    })
    return
  }

  const getNBToursFromDates = () => {
    if (!nextTours || nextTours.length < 1) {
      return 0
    }

    const toursFiltered = [...nextTours].filter(
      (tour) =>
        new Date(tour.deposit) > propositionsFilters.start &&
        new Date(tour.deposit) < propositionsFilters.end,
    )

    return toursFiltered.length
  }

  const onClickTodayButton = () => {
    let tempStartDate = new Date()
    let tempEndDate = new Date()

    tempStartDate.setHours(0)
    tempStartDate.setMinutes(0)
    tempStartDate.setSeconds(0)

    tempEndDate.setHours(23)
    tempEndDate.setMinutes(59)
    tempEndDate.setSeconds(59)
    onChangeFilters({
      ...propositionsFilters,
      mode: 'DAY',
      start: tempStartDate,
      end: tempEndDate,
    })
  }

  return (
    <>
      <SliderMenu
        elements={propositionModes}
        onSelectElement={onSelectElementSliderMenu}
        defaultSelectedElement={propositionsFilters.mode || 1}
      />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      <DateSwitcher
        mode={propositionsFilters.mode}
        dates={{ start: propositionsFilters.start, end: propositionsFilters.end }}
        onChangeDates={onChangeDates}
        label={`${FormatUtils.pluralizeAmountLabel('tournée', getNBToursFromDates())}`}
        onClickTodayButton={onClickTodayButton}
      />
    </>
  )
}

export default ProducerPropositionsV2Filters
