import styled, { css } from 'styled-components/native'

const FormContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  height: 100%;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 0}px;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const StyledProgressBarWrapper = styled.View`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors['color-border']};
  border-radius: 2px;
  height: 6px;
`

const StyledProgressBar = styled.View<{ percentage: number }>`
  height: 100%;
  border-radius: 2px;
  width: 0%;
  background-color: ${({ theme }) => theme.colors['color-grey']};

  ${({ percentage }) =>
    percentage &&
    css`
      width: ${percentage}%;
    `}
`

const RegisterFormWrapper = styled.View`
  min-height: 450px;
`

export {
  FormContentWrapper,
  StyledButtonWrapper,
  StyledProgressBarWrapper,
  StyledProgressBar,
  RegisterFormWrapper,
}
