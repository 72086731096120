import styled from 'styled-components/native'

const StyledWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export default StyledWrapper
