import styled from 'styled-components/native'

const StyledTouchableOpacity = styled.TouchableOpacity`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`

const StyledItem = styled.View`
  justify-content: center;
  align-items: center;
`

export { StyledTouchableOpacity, StyledItem }
