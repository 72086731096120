import { useHeaderHeight } from '@react-navigation/elements'
import React, { useMemo } from 'react'
import { Platform, StatusBar } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import styled from 'styled-components/native'

const StyledKeyboardAvoidingView = styled.KeyboardAvoidingView`
  flex: 1;
  width: 100%;
`

const KeyboardAvoidingViewWrapper: React.FC<{
  shouldRender?: boolean
  headerHeight?: number
  modal?: boolean
  withKeyboardAvoidingView?: boolean
}> = ({ children, shouldRender = true, headerHeight = 0, modal, withKeyboardAvoidingView }) => {
  const navCardTitleHeight = useHeaderHeight()
  const insets = useSafeAreaInsets()

  const keyboardVerticalOffset = useMemo(() => {
    if (withKeyboardAvoidingView) return 0
    return (
      (navCardTitleHeight || headerHeight) +
      (StatusBar.currentHeight ?? 0) +
      (modal ? insets.top : 0)
    )
  }, [withKeyboardAvoidingView, navCardTitleHeight, headerHeight, modal, insets.top])

  if (!shouldRender) {
    return <>{children}</>
  }

  return (
    <StyledKeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={keyboardVerticalOffset}
    >
      {children}
    </StyledKeyboardAvoidingView>
  )
}

KeyboardAvoidingViewWrapper.defaultProps = {
  shouldRender: true,
  headerHeight: 0,
  modal: false,
  withKeyboardAvoidingView: false,
}

export default KeyboardAvoidingViewWrapper
