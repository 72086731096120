import { AddDocument, Document } from '../../../domain'
import ApiService from '../../api.service'

const getAllDocumentsProducer = async (producerId: string): Promise<Document[]> => {
  const response = await ApiService.get(`/document/producer/${producerId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOneDocumentProducer = async (
  documentId: string,
  producerId: string,
): Promise<Document> => {
  const response = await ApiService.get(`/document/${documentId}/producer/${producerId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}
const getOne = async (documentId: string): Promise<Document> => {
  const response = await ApiService.get(`/document/${documentId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const deleteOneDocumentProducer = async (
  documentId: string,
  producerId: string,
): Promise<Document> => {
  const response = await ApiService.remove(`/document/${documentId}/producer/${producerId}`)
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const createDocumentProducer = async (
  document: AddDocument,
  producerId: string,
): Promise<Document> => {
  const response = await ApiService.post(`/document/producer/${producerId}`, document)
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const updateDocumentProducer = async (
  document: Partial<Document>,
  documentId: string,
  producerId: string,
): Promise<Document> => {
  const response = await ApiService.patch(
    `/document/${documentId}/producer/${producerId}`,
    document,
  )
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}
const DocumentProducerService = {
  getAllDocumentsProducer,
  getOneDocumentProducer,
  deleteOneDocumentProducer,
  createDocumentProducer,
  updateDocumentProducer,
  getOne,
}

export default DocumentProducerService
