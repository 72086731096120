import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Col, PageTitle, Row, Spacer, Typography } from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { AddUserAdmin, User } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierUpdateAdminUser.styles'
import UserCarrierService from '../../../services/carrier/user'
import { useQuery } from '@tanstack/react-query'
import { Role } from '../../../domain/Role'
import RoleService from '../../../services/user/role'
import RoleForm from '../../../modules/Common/RoleForm'
import GroupCarrierService from '../../../services/carrier/group'
import { useEffect } from 'react'

const CarrierUpdateAdminUserScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdateAdminUser'>>()
  const [isLoading, setIsLoading] = useState(false)

  const [userRoles, setUserRoles] = useState<Role[]>([])

  const { getAccessInfos } = useAuthenticationContext()

  const { groupId, userId } = route?.params ?? {
    groupId: '',
    userId: '',
  }

  const {
    data: roles,
    refetch,
    isInitialLoading: rolesLoading,
  } = useQuery<Role[], Error>(['sf_roles'], () => RoleService.getRoles(), {
    keepPreviousData: true,
  })

  const {
    data: adminGroups,
    refetch: refetchAdminGroups,
    isInitialLoading: isAdminGroupsLoading,
  } = useQuery<User[], Error>(
    ['sf_group_admins'],
    () =>
      GroupCarrierService.getOneGroupAdmins(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
      ),
    {
      keepPreviousData: true,
    },
  )

  const theme = useTheme()
  const [updatedUser, setUpdatedUser] = useState<AddUserAdmin>({
    email: '',
    password: '',
    lastName: '',
    firstName: '',
  })

  useEffect(() => {
    if (!adminGroups) return
    const foundUser = adminGroups.find((user) => user?.id === userId)
    if (foundUser) {
      setUpdatedUser({
        email: foundUser.email,
        password: foundUser.password,
        lastName: foundUser.lastName,
        firstName: foundUser.firstName,
      })

      if (foundUser.userRole) {
        const roles: Role[] = []
        foundUser.userRole.map((userRole) => {
          if (userRole.role) {
            roles.push(userRole.role)
          }
        })
        setUserRoles(roles)
      }
    }
  }, [setUserRoles, userId, adminGroups, setUpdatedUser])

  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in updatedUser)) return null

    const userTemp = Object.assign({}, updatedUser)

    userTemp[param as keyof typeof updatedUser] = value
    setUpdatedUser(userTemp)
  }

  const onUpdateUser = async () => {
    setErrorMessage('')
    if (!groupId) {
      setErrorMessage('Erreur de chargement du groupe')
      return
    }

    setIsLoading(true)
    const user = {
      email: FormatUtils.cleanEmail(updatedUser.email),
      password: updatedUser.password,
      lastName: updatedUser.lastName,
      firstName: updatedUser.firstName,
      carrierId: getAccessInfos().carrierId || undefined,
      roles: userRoles,
    }

    const updatedUserTemp = await UserCarrierService.updateAdmin(userId, user, groupId)
      .then((res) => {
        queryClient.invalidateQueries(['sf_group'])
        queryClient.invalidateQueries(['sf_group_admins'])

        navigation.navigate('CarrierGroupAdmins', {})

        setIsLoading(false)
        return true
      })
      .catch((error) => {
        setErrorMessage("Un problème est survenu lors de la modification de l'utilisateur")
        setIsLoading(false)
        return false
      })
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Modifier un utilisateur" />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Typography.BodySmall colorName="text-dark-3">
              Rattaché au compte super producteur
            </Typography.BodySmall>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedUser.lastName}
                  label="Nom"
                  field="text"
                  onChangeText={(text) => setValue(text, 'lastName')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedUser.firstName}
                  label="Prénom"
                  field="text"
                  onChangeText={(text) => setValue(text, 'firstName')}
                  hidden={!updatedUser.lastName}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedUser.email}
                  label="Email"
                  field="text"
                  onChangeText={(text) => setValue(text, 'email')}
                  hidden={!updatedUser.firstName}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedUser.password}
                  label="Mot de passe"
                  field="password"
                  secureTextEntry
                  onChangeText={(text) => setValue(text, 'password')}
                  hidden={!updatedUser.email}
                />
              </Col>
            </Row>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
            <RoleForm
              roles={roles}
              userRoles={userRoles}
              onChangeRole={(roles) => setUserRoles(roles)}
              dropDownDirection="TOP"
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Button
              label={FormatUtils.capitalize(i18n.t('save'))}
              onPress={() => onUpdateUser()}
              loading={isLoading}
              hasDoubleValidation
              confirmationLabel="Êtes-vous sûr de vouloir modifier cet utilisateur ?"
            />
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierUpdateAdminUserScreen
