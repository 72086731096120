import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import Header from '../../components/Header'
import ProducerPublicProfile from '../../../screens/Producer/ProducerPublicProfile'
import ProducerProfileScreen from '../../../screens/Producer/ProducerProfile'
import ProducerCertificatesScreen from '../../../screens/Producer/ProducerCertificates'
import ProducerInformationsScreen from '../../../screens/Producer/ProducerInformations'
import ProducerDetailsCertificatesScreen from '../../../screens/Producer/ProducerDetailsCertificates'
import ProducerAddCertificateScreen from '../../../screens/Producer/ProducerAddCertificate'
import ProducerSettingsScreen from '../../../screens/Producer/ProducerSettings'
import ProducerAddAddressScreen from '../../../screens/Producer/ProducerAddAddress'

const Stack = createStackNavigator()

const ProfileProducerNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="ProducerProfile"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ProducerProfile"
        component={ProducerProfileScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" noDrawer />,
        }}
      />
      <Stack.Screen
        name="ProducerPublicProfile"
        component={ProducerPublicProfile}
        options={{
          headerShown: true,
          header: () => <Header title="" noDrawer />,
        }}
      />
      <Stack.Screen
        name="ProducerInformations"
        component={ProducerInformationsScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" noDrawer />,
        }}
      />
      <Stack.Screen
        name="ProducerCertificates"
        component={ProducerCertificatesScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" noDrawer />,
        }}
      />
      <Stack.Screen
        name="ProducerAddCertificate"
        component={ProducerAddCertificateScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" noDrawer />,
        }}
      />
      <Stack.Screen
        name="ProducerDetailsCertificates"
        component={ProducerDetailsCertificatesScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" noDrawer />,
        }}
      />
      <Stack.Screen
        name="ProducerSettingsScreen"
        component={ProducerSettingsScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" noDrawer />,
        }}
      />
      <Stack.Screen
        name="ProducerAddAddress"
        component={ProducerAddAddressScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" noDrawer />,
        }}
      />
    </Stack.Navigator>
  )
}

export default ProfileProducerNavigation
