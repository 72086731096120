import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AppState } from 'react-native'
import Toast from 'react-native-toast-message'
import { useQueryClient } from '@tanstack/react-query'
import { User } from '../../../domain/User'
import * as RootNavigation from '../../../navigation/RootNavigation'
import AuthenticationService from '../../../services/authentication'
import StorageUtil from '../../storage/storage'
import { AuthenticationContextData, AuthenticationData } from './authenticationContext.model'
import { RightKeyEnum, UserEnums } from '../../../../enums'

const BACKGROUND_TIMEOUT = 1000 * 60 * 60 // 60 minute

const AuthenticationContext = createContext<AuthenticationContextData>(
  {} as AuthenticationContextData,
)

const AuthenticationProvider: React.FC<any> = ({ children }) => {
  const appState = useRef(AppState.currentState)
  const queryClient = useQueryClient()
  const [authenticationData, setContextAuthenticationData] = useState<AuthenticationData>()
  const [authenticationContextLoading, setAuthenticationContextLoading] = useState(true)
  const [authenticationErrorMessage, setAuthenticationErrorMessage] = useState('')
  const [authenticationLoading, setAuthenticationLoading] = useState(false)
  const [viewTypeValue, setViewTypeValue] = useState<UserEnums.ViewTypeEnums | undefined>(undefined)

  const storeAuthenticationDataInSecureStore = useCallback(async (data: AuthenticationData) => {
    await StorageUtil.setItem('authenticationData', data)
    await StorageUtil.setItem('login_mail', data.email)
    // await SecureStore.setItemAsync('authenticationData', JSON.stringify(data))
    setContextAuthenticationData(data)
  }, [])

  const handleError = (error: any) => {
    setAuthenticationErrorMessage(error?.messageClient ?? '')
  }

  const resetErrorMessage = useCallback(() => {
    setAuthenticationErrorMessage('')
  }, [])

  const checkUserEmail = useCallback(async (userEmail: string) => {
    setAuthenticationLoading(true)
    try {
      const emailFound = true
      const emailNotFound = false

      if (emailFound) {
        const loginScreenOptions: { invitation?: string; email?: string } = {}
        if (userEmail) {
          loginScreenOptions.email = userEmail
        }
        RootNavigation.navigate('LoginPassword', loginScreenOptions)
      }
      if (emailNotFound) {
        RootNavigation.navigate('EmailNotFound', { email: userEmail })
      }
    } catch (error) {
      handleError(error)
    } finally {
      setAuthenticationLoading(false)
    }
  }, [])

  const logout = useCallback(async (): Promise<void> => {
    await queryClient.clear()

    await setAuthenticationContextLoading(false)
    await setContextAuthenticationData(undefined)
    await StorageUtil.clearStorage()
    await setViewTypeValue(undefined)
  }, [queryClient])

  const checkTokenValidity = useCallback(async (): Promise<boolean> => {
    const storedProfileId = (await StorageUtil.getItem('profileId')) || ''
    const profileId = storedProfileId ? JSON.parse(storedProfileId) : undefined

    const storedGroupId = (await StorageUtil.getItem('groupId')) || ''
    const groupId = storedGroupId ? JSON.parse(storedGroupId) : undefined

    return AuthenticationService.refresh(profileId, groupId)
      .then((isUserValid) => {
        return isUserValid
      })
      .catch((error) => {
        Toast.show({
          type: 'error',
          text1: 'Your session has expired',
        })
        logout()
        return false
      })
  }, [logout])

  const loadStoredSession = useCallback(
    async (biometricVerification = true): Promise<void> => {
      // setBiometricStatus(EnumBiometricResult.PENDING)

      const tempToken = await StorageUtil.getItem('tempToken')
      if (tempToken) {
        await loginToken(JSON.parse(tempToken))
        await StorageUtil.deleteItem('tempToken')
        return
      }

      const authenticationDataFromSecureStore = await StorageUtil.getItem('authenticationData')

      if (!authenticationDataFromSecureStore) {
        logout()
        return
      }
      if (authenticationDataFromSecureStore) {
        const authenticationDataFormatted: AuthenticationData = JSON.parse(
          authenticationDataFromSecureStore,
        )

        const isUserValid: boolean = await checkTokenValidity()

        if (isUserValid) {
          /*
          if (biometricVerification) {
            const biometricAuthenticationStatus = await biometricAuthentication()

            setBiometricStatus(biometricAuthenticationStatus)

            if (shouldUserBeLoggedOut(biometricAuthenticationStatus)) {
              Toast.show({
                type: 'error',
                text1: 'Your session has expired',
                text2: 'Enable biometric authentication in your settings to login easily',
              })
              logout()
              return
            }
          }
          */
          await storeAuthenticationDataInSecureStore(authenticationDataFormatted)

          setTimeout(() => {
            setAuthenticationContextLoading(false)
          }, 500)
        } else {
          logout()
        }
      } else {
        logout()
      }
    },
    [checkTokenValidity, logout, storeAuthenticationDataInSecureStore],
  )

  const switchProfile = useCallback(
    async (biometricVerification = true): Promise<void> => {
      // setBiometricStatus(EnumBiometricResult.PENDING)
      const storedProfileId = (await StorageUtil.getItem('profileId')) || ''
      const profileId = storedProfileId ? JSON.parse(storedProfileId) : undefined

      const storedGroupId = (await StorageUtil.getItem('groupId')) || ''
      const groupId = storedGroupId ? JSON.parse(storedGroupId) : undefined

      const userData: User = await AuthenticationService.switchProfile(profileId, groupId)
        .then((userData) => {
          return userData
        })
        .catch((error) => {
          Toast.show({
            type: 'error',
            text1: 'Your session has expired',
          })
          logout()
          return false
        })

      if (userData) {
        const { screenToRedirectTo, data } = await AuthenticationService.getLoginDataInformations(
          userData,
        )

        await storeAuthenticationDataInSecureStore(data)

        setTimeout(() => {
          setAuthenticationContextLoading(false)
        }, 500)
      } else {
        logout()
      }
    },
    [logout, storeAuthenticationDataInSecureStore],
  )

  const login = useCallback(
    async (email: string, password: string): Promise<boolean> => {
      setAuthenticationLoading(true)

      resetErrorMessage()
      try {
        const loginData = await AuthenticationService.login(email, password).catch((error) => {
          throw { messageClient: 'Mail ou mot de passe invalide' }
        })

        const { screenToRedirectTo, data } = await AuthenticationService.getLoginDataInformations(
          loginData,
        )

        if (screenToRedirectTo) {
          RootNavigation.navigate(screenToRedirectTo, { ...data })
        } else {
          await storeAuthenticationDataInSecureStore(data)
        }

        // await checkNotificationToken(loginData)
        // await loadStoredSession(false)

        return true
      } catch (error) {
        return false
        handleError(error)
      } finally {
        setTimeout(() => {
          setAuthenticationLoading(false)
          setAuthenticationContextLoading(false)
        }, 500)
      }
    },
    [storeAuthenticationDataInSecureStore, resetErrorMessage, loadStoredSession],
  )

  const loginToken = useCallback(
    async (authToken: string): Promise<boolean> => {
      const storedProfileId = (await StorageUtil.getItem('profileId')) || ''
      const profileId = storedProfileId ? JSON.parse(storedProfileId) : undefined

      const storedGroupId = (await StorageUtil.getItem('groupId')) || ''
      const groupId = storedGroupId ? JSON.parse(storedGroupId) : undefined

      try {
        const loginData = await AuthenticationService.loginToken(
          authToken,
          profileId,
          groupId,
        ).catch((error) => {
          // throw { messageClient: 'Mail ou mot de passe invalide' }
        })

        const { screenToRedirectTo, data } = await AuthenticationService.getLoginDataInformations(
          loginData,
        )

        await storeAuthenticationDataInSecureStore(data)

        let viewType
        if (data.access.clientId) {
          viewType = 3
        }

        await StorageUtil.setItem('viewType', 3)

        return true
      } finally {
        setTimeout(() => {
          setAuthenticationLoading(false)
          setAuthenticationContextLoading(false)
        }, 1500)
      }
    },
    [storeAuthenticationDataInSecureStore, resetErrorMessage, loadStoredSession],
  )

  const validateInvitation = useCallback(async (email: string, password: string): Promise<any> => {
    try {
      await AuthenticationService.validateInvitation(email, password).catch((error) => {
        throw { messageClient: `Une erreur s'est produite` }
      })

      await login(email, password)
      return true
    } catch (error) {
      handleError(error)
      return false
    } finally {
    }
  }, [])

  const getStoredViewType = useCallback(async () => {
    const storedViewType = await StorageUtil.getItem('viewType')
    if (storedViewType && storedViewType) {
      return setViewTypeValue(parseInt(storedViewType))
    }
    return undefined
  }, [])

  const hasCarrierRole = useCallback((): boolean => {
    if (!authenticationData) return false

    return (
      authenticationData.access?.roles?.find((role) => role === UserEnums.RoleEnums.CARRIER) !==
      undefined
    )
  }, [authenticationData])

  const changeViewType = useCallback(
    async (newType?: UserEnums.ViewTypeEnums, profileId?: string, groupId?: string) => {
      await setAuthenticationLoading(true)
      await StorageUtil.setItem('viewType', newType)
      if (profileId) {
        await StorageUtil.setItem('profileId', profileId)
      }
      if (groupId) {
        await StorageUtil.setItem('groupId', groupId)
      }
      await setViewTypeValue(newType)
      await switchProfile()
      await setAuthenticationLoading(false)
    },
    [setViewTypeValue],
  )

  const carrierHasRight = useCallback(
    (rightKey: RightKeyEnum): boolean => {
      if (!authenticationData) return false
      const carrierRights = authenticationData.access.carrierRights

      if (!carrierRights) return false
      const foundRight = carrierRights.find((carrierRight) => carrierRight === rightKey)

      const foundRightAdmin = carrierRights.find(
        (carrierRight) => carrierRight === RightKeyEnum.C_SUPER_ADMIN,
      )

      if (foundRight || foundRightAdmin) {
        return true
      }
      return false
    },
    [authenticationData],
  )

  const isCurrentUserRootAdmin = useCallback((): boolean => {
    if (!authenticationData) return false
    return authenticationData.user.isRootAdmin
  }, [authenticationData])

  const getAccessInfos = useCallback(() => {
    return authenticationData?.access
  }, [authenticationData])

  const value: AuthenticationContextData = useMemo(
    () => ({
      authenticationData,
      isUserAuthenticated: !!authenticationData,
      authenticationContextLoading,
      checkUserEmail,
      login,
      logout,
      loadStoredSession,
      storeAuthenticationDataInSecureStore,
      authenticationErrorMessage,
      resetErrorMessage,
      authenticationLoading,
      viewType: viewTypeValue,
      getStoredViewType,
      changeViewType,
      switchProfile,
      hasCarrierRole,
      getAccessInfos,
      carrierHasRight,
      isCurrentUserRootAdmin,
      validateInvitation,
      setAuthenticationContextLoading,
    }),
    [
      authenticationData,
      authenticationContextLoading,
      checkUserEmail,
      login,
      logout,
      loadStoredSession,
      storeAuthenticationDataInSecureStore,
      authenticationErrorMessage,
      resetErrorMessage,
      authenticationLoading,
      changeViewType,
      switchProfile,
      getStoredViewType,
      viewTypeValue,
      hasCarrierRole,
      getAccessInfos,
      carrierHasRight,
      isCurrentUserRootAdmin,
      validateInvitation,
      setAuthenticationContextLoading,
    ],
  )

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
}

export {
  AuthenticationProvider,
  AuthenticationContext,
  AuthenticationData,
  AuthenticationContextData,
}
