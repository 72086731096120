import { useQuery } from '@tanstack/react-query'
import { ProducerTour } from '../../../domain'
import ApiService from '../../api.service'
import { queryClient } from '../../../utilities/queryClient'
import FormatUtils from '../../../utilities/utils/format'

const getFromTour = async (tourId: string): Promise<ProducerTour[]> => {
  if (!tourId) return []
  const response = await ApiService.get(`producer-tour/${tourId}`)
  return response
}

type UseGetFromTourArgs = {
  tourId: string
}

export interface ProducerTourExtended extends ProducerTour {
  orderEndDateWarning?: string
}

export function extendProducerTour(producersTour: ProducerTour[]): ProducerTourExtended[] {
  return producersTour.map((producerTour) => {
    const { orderEndDate } = producerTour
    let orderEndDateWarning

    if (orderEndDate) {
      const dOrderEndDate = new Date(orderEndDate)
      orderEndDateWarning = `${
        dOrderEndDate.getTime() > Date.now() ? 'Disponible' : 'Était disponible'
      } à la vente jusqu'au ${FormatUtils.formatDate(
        orderEndDate,
        'DateShort',
      )} à ${FormatUtils.formatDate(orderEndDate, 'Time')}`
    }

    return {
      ...producerTour,
      orderEndDateWarning,
    }
  })
}

export function useGetFromTour(args: UseGetFromTourArgs) {
  function getQueryKey() {
    return ['producers-tour', args.tourId || ''].filter(Boolean)
  }

  function query() {
    return useQuery<ProducerTour[], Error, ProducerTourExtended[]>({
      queryKey: getQueryKey(),
      queryFn: () => getFromTour(args.tourId),
      keepPreviousData: true,
      cacheTime: 6 * 60 * 1000,
      staleTime: 3 * 60 * 1000,
      enabled: !!args.tourId,
      select: extendProducerTour,
    })
  }

  function invalidate() {
    queryClient.invalidateQueries(getQueryKey())
  }

  return {
    getQueryKey,
    query,
    invalidate,
  }
}

const CarrierProducerTourService = {
  useGetFromTour,
  getFromTour,
}

export default CarrierProducerTourService
