export enum AxisEnum {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export interface SpacerProps {
  theme?: any
  size?: number
  axis?: AxisEnum
  fixedSize?: number
}
