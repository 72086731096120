import { AddDocument, Document } from '../../../domain'
import ApiService from '../../api.service'

const getAllDocumentsProducer = async (
  producerId: string | undefined,
  clientId: string,
): Promise<Document[]> => {
  if (!producerId || !clientId) return []
  const response = await ApiService.get(`/document/producer/${producerId}/client/${clientId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOneDocumentProducer = async (
  documentId: string,
  producerId: string,
  clientId: string,
): Promise<Document> => {
  const response = await ApiService.get(
    `/document/${documentId}/producer/${producerId}/client/${clientId}`,
    {},
  )
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}
const getOne = async (documentId: string): Promise<Document> => {
  const response = await ApiService.get(`/document/${documentId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const DocumentClientService = {
  getAllDocumentsProducer,
  getOneDocumentProducer,
  getOne,
}

export default DocumentClientService
