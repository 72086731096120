import React from 'react'
import { useTheme } from 'styled-components/native'
import Typography from '../Typography'
import { HelpFooterProps } from './HelpFooter.model'
import { StyledTitleWrapper } from './HelpFooter.styles'
import Spacer from '../Spacer'
import Env from '../../utilities/env'
import * as Linking from 'expo-linking'
import Button from '../Button'
import { version } from '../../../package.json'

function HelpFooter({ short }: HelpFooterProps) {
  const theme = useTheme()

  const onClickUpdatesPageRedirect = async () => {
    Linking.openURL(
      'https://harvyapp.notion.site/Les-nouveaut-s-de-l-application-Harvy-3a7f87b0a9c54e0db77dcec735206fbb?pvs=4',
    )
  }

  return (
    <StyledTitleWrapper>
      <Typography.BodySmall colorName="text-dark-3" align="center">
        Un problème ? Une question ? Contactez-nous !
      </Typography.BodySmall>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
      <Typography.Body colorName="text-dark-3" align="center">
        Par téléphone : 05 64 35 00 78 / Par mail : contact@harvy.tech
      </Typography.Body>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      {!short && (
        <>
          <Button.Text
            label={`Découvrir les dernières nouveautés de l'application`}
            onPress={() => {
              onClickUpdatesPageRedirect()
            }}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Typography.BodySmall colorName="text-dark-3" align="center">
            Version {version}
          </Typography.BodySmall>
        </>
      )}

      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
    </StyledTitleWrapper>
  )
}

export default HelpFooter
