import styled, { css } from 'styled-components/native'

const StyledStepCardWrapper = styled.TouchableOpacity`
  width: 98%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.25}px;
`

const AlternativeStyledStepCardWrapper = styled.View`
  width: 98%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.25}px;
`
const StyledStepCard = styled.View<{ completed: boolean | undefined }>`
  width: 96%;
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  border: 1.9px solid ${({ theme }) => theme.colors && theme.colors['color-grey']};
  ${({ completed }) =>
    completed &&
    css`
      border-color: ${({ theme }) => theme.colors['color-primary']};
    `}

  flex-direction: row;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacingUnit}px;
  margin-left: 12px;
  min-height: ${({ theme }) => theme.spacingUnit * 6}px;
`

const CaretBox = styled.View`
  min-width: ${({ theme }) => theme.spacingUnit * 2}px;
  min-height: ${({ theme }) => theme.spacingUnit * 2}px;
`
const StyledStepNumberBox = styled.View<{ completed: boolean | undefined }>`
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  border: 1.75px solid ${({ theme }) => theme.colors && theme.colors['color-grey']};
  ${({ completed }) =>
    completed &&
    css`
      background-color: ${({ theme }) => theme.colors['color-primary']};
      border-color: ${({ theme }) => theme.colors['color-primary']};
    `}
  margin-left: -12px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  padding: ${({ theme }) => theme.spacingUnit * 1.5}px;
`

const StyledStepCardContent = styled.View`
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 1.5}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1.5}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 1.5}px;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledTourStepCardRightBox = styled.View<{ align?: 'flex-start' | 'center' | 'flex-end' }>`
  align-items: flex-end;
  justify-content: center;
  //flex: 1;
  width: 45%;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.5}px;

  ${({ align }) =>
    align &&
    css`
      align-items: ${align};
    `}
`

const StyledMiddleBox = styled.View`
  flex-wrap: wrap;
  flex: 1;
  // max-width: 65%;
`
const StyledRightBox = styled.View`
  gap: ${({ theme }) => theme.spacingUnit * 1.5}px;
  align-items: center;
  flex-direction: row;
`
export {
  StyledStepCardWrapper,
  StyledStepCard,
  StyledStepCardContent,
  StyledStepNumberBox,
  StyledTourStepCardRightBox,
  CaretBox,
  StyledMiddleBox,
  StyledRightBox,
  AlternativeStyledStepCardWrapper,
}
