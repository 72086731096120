import React from 'react'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledTimeBox,
} from './ClientProducerCertificateCard.styles'
import { DocumentEnums } from '../../../../../../enums/document'
import GeneralUtils from '../../../../../utilities/utils/general'
import { InterfaceColors } from '../../../../../utilities/styling/theme/theme'
import { DocumentProducerCardProps } from './ClientProducerCertificateCard.model'
import { Linking } from 'react-native'
import DirectusUtil from '../../../../../utilities/utils/directus'
import DocumentClientService from '../../../../../services/client/document'

export enum DocumentExpirationStatus {
  VALID = 'VALID',
  EXPIRES_SOON = 'EXPIRES_SOON',
  EXPIRED = 'EXPIRED',
}

const ClientProducerCertificateCard = ({ document }: DocumentProducerCardProps) => {
  const theme = useTheme()

  const OnClickDocument = async () => {
    if (!document.id) return

    const documentWithAccess = await DocumentClientService.getOne(document.id)
    if (!documentWithAccess.documentId || !documentWithAccess.accessToken) return

    Linking.openURL(
      DirectusUtil.getDocumentDownloadUrlFromId(
        documentWithAccess.documentId,
        documentWithAccess.accessToken,
      ),
    )
  }

  const getDocumentExpirationStatus = (): DocumentExpirationStatus => {
    let tempStatus: DocumentExpirationStatus = DocumentExpirationStatus.VALID

    const currentDate = new Date()
    const expirationDate = new Date(document.expiration)

    const diffDays = GeneralUtils.diffDays(currentDate, expirationDate)

    if (diffDays < 30) {
      tempStatus = DocumentExpirationStatus.EXPIRES_SOON
    }

    if (diffDays < 0) {
      tempStatus = DocumentExpirationStatus.EXPIRED
    }

    return tempStatus
  }

  const expirationDateColor = (): { label: string; color: keyof InterfaceColors } => {
    let tempColor: keyof InterfaceColors = 'text-dark-1'
    let tempLabel = `Valide jusqu'au ${FormatUtils.formatDate(
      document.expiration.toString(),
      'DateFullYear',
    )}`
    const status = getDocumentExpirationStatus()

    if (status === DocumentExpirationStatus.EXPIRES_SOON) {
      tempColor = 'color-warning'
      tempLabel = `Expire le ${FormatUtils.formatDate(
        document.expiration.toString(),
        'DateFullYear',
      )}`
    }

    if (status === DocumentExpirationStatus.EXPIRED) {
      tempColor = 'color-danger'
      tempLabel = `Expiré depuis le ${FormatUtils.formatDate(
        document.expiration.toString(),
        'DateFullYear',
      )}`
    }

    return {
      color: tempColor,
      label: tempLabel,
    }
  }

  if (!document) return null

  return (
    <StyledCardWrapper
      onPress={OnClickDocument}
      activeOpacity={0.8}
      key={`documentCard-${document.id}`}
    >
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Icon
              type={Icons.MaterialCommunityIcons}
              name="file-document-outline"
              size={theme.spacingUnit * 4.5}
              color={theme.colors['color-grey']}
            />
            <StyledTimeBox>
              <Typography.CardTitle>
                {DocumentEnums.DocumentTypes.find((type) => type.value === document.type)?.label}
              </Typography.CardTitle>

              <Typography.BodySmall colorName={expirationDateColor().color}>
                {expirationDateColor().label}
              </Typography.BodySmall>
            </StyledTimeBox>
            <Spacer size={0.15} axis={Spacer.AxisEnum.HORIZONTAL} />
          </StyledLeftBox>
          <Icon
            type={Icons.Ionicons}
            name="download-outline"
            size={theme.spacingUnit * 3}
            color={theme.colors['color-grey']}
          />
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default ClientProducerCertificateCard
