import React, { createContext, useState, useEffect, useMemo, useCallback, useRef } from 'react'
import * as Notifications from 'expo-notifications'
import * as Device from 'expo-device'
import * as Linking from 'expo-linking'
import { Platform } from 'react-native'
import { Subscription } from 'expo-modules-core'

import { NotificationsContextData } from './notificationsContext.model'

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
})

const NotificationsContext = createContext<NotificationsContextData>({} as NotificationsContextData)

const NotificationsProvider: React.FC<any> = ({ children }) => {
  const [expoPushToken, setExpoPushToken] = useState('')
  const [notification, setNotification] = useState<Notifications.Notification>()
  const [notificationResponse, setNotificationResponse] =
    useState<Notifications.NotificationResponse>()
  const notificationListener = useRef() as React.MutableRefObject<Subscription>
  const responseListener = useRef() as React.MutableRefObject<Subscription>
  const refreshTokenListener = useRef() as React.MutableRefObject<Subscription>

  // from: https://docs.expo.dev/versions/latest/sdk/notifications/#api
  const getPushToken = useCallback(async (): Promise<string> => {
    let token

    if (Device.isDevice && Device.osInternalBuildId !== null) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync()
      let finalStatus = existingStatus
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync()
        finalStatus = status
      }
      if (finalStatus !== 'granted') {
        // TODO: Show notification to user
        Promise.reject(new Error('Permission not granted for push token'))
      }

      token = (await Notifications.getExpoPushTokenAsync()).data
    } else {
      // TODO: Show notification to user
      // Promise.reject(new Error('Must use physical device for Push Notifications'))
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#0f172f',
      })
    }

    return token ?? ''
  }, [])

  const refreshPushToken = useCallback(async (): Promise<void> => {
    const token = await getPushToken()
    setExpoPushToken(token)
  }, [getPushToken])

  useEffect(() => {
    getPushToken().then((token) => {
      if (token) setExpoPushToken(token)
    })

    notificationListener.current = Notifications.addNotificationReceivedListener(
      (receivedNotification) => {
        setNotification(receivedNotification)
      },
    )

    responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
      setNotificationResponse(response)

      if (response?.notification?.request?.content?.data?.urlActionMobile) {
        Linking.openURL(response?.notification?.request?.content?.data?.urlActionMobile as string)
      }
    })

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current)
      Notifications.removeNotificationSubscription(responseListener.current)
    }
  }, [getPushToken])

  useEffect(() => {
    refreshTokenListener.current = Notifications.addPushTokenListener((token) => {
      // reset the token when it's needed
      // TODO: API CALL TO UPDATE THE USER TOKEN
      if (token) setExpoPushToken(token.data)
    })
    return () => {
      Notifications.removeNotificationSubscription(refreshTokenListener.current)
    }
  }, [])

  const value: NotificationsContextData = useMemo(
    () => ({
      expoPushToken,
      getPushToken,
      refreshPushToken,
      notification,
      notificationResponse,
    }),
    [expoPushToken, getPushToken, refreshPushToken, notification, notificationResponse],
  )

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>
}

export { NotificationsProvider, NotificationsContext, NotificationsContextData }
