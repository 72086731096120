import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { TourEnums } from '../../../../enums'
import FormatUtils from '../../../utilities/utils/format'
import { TourStepperProducerProps } from './TourStepperProducer.model'
import { StyledTourStepperWrapper } from './TourStepperProducer.styles'
import TourStepCard from '../../../components/TourStepCard'
import { Typography } from '../../../components'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { StyledTourStepCardRightBox } from '../../../components/TourStepCard/TourStepCard.styles'
import TourUtil from '../../../utilities/utils/tour'

const TourStepperProducer = ({ tour, tourBatchs, onCardClick }: TourStepperProducerProps) => {
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const start = new Date(tour.deposit)
  const end = new Date(tour.deposit)
  if (!start || !end) return <></>

  type stepProps = {
    isActive: boolean
    isFinished: boolean
    number: number
    label: string
    description?: string
    rightBox?: any
    displayMinInfos?: boolean
    rightBoxOnFinished?: any
    display?: boolean
    onPress?: () => void
  }

  const nbProductsBatchs = tourBatchs?.length || 0

  const getStep1 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: true,
      isFinished: TourUtil.getTourStatus(tour) !== TourEnums.STATUS.PENDING || nbProductsBatchs > 0,
      number: 1,
      label: 'Vérifiez votre mercuriale',
      description: "Avant l'ouverture des commandes",
      rightBox: (
        <StyledTourStepCardRightBox>
          <Typography.BodySmall
            bold
            colorName={nbProductsBatchs > 0 ? 'text-dark-1' : 'color-danger'}
          >
            {FormatUtils.pluralizeAmountLabel('lot', nbProductsBatchs)}
          </Typography.BodySmall>
        </StyledTourStepCardRightBox>
      ),
      onPress: () =>
        navigation.navigate('PropositionProducerNavigationConfiguration', {
          screen: 'ProducerPropositions',
          params: { mode: 'DAY', start: new Date(tour.deposit) },
        }),
    }
    return stepPropsValues
  }

  const getStep2 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: TourUtil.getTourStatus(tour) === TourEnums.STATUS.OPEN,
      isFinished: TourUtil.getTourStatus(tour) !== TourEnums.STATUS.PENDING,
      number: 2,
      displayMinInfos: true,
      label: 'Ouverture des commandes',
      rightBox: (
        <StyledTourStepCardRightBox align="flex-end">
          <Typography.BodySmall bold colorName="color-primary" align="end">
            {FormatUtils.capitalize(FormatUtils.formatDate(tour.open, 'PartialDate'))} à{' '}
            {FormatUtils.formatDate(tour.open, 'Time')}
          </Typography.BodySmall>
        </StyledTourStepCardRightBox>
      ),
      rightBoxOnFinished: (
        <StyledTourStepCardRightBox>
          <Typography.BodySmall bold colorName="color-primary">
            {TourUtil.getTourStatus(tour) === TourEnums.STATUS.OPEN ? 'Ouvert' : ''}
          </Typography.BodySmall>
        </StyledTourStepCardRightBox>
      ),
    }
    return stepPropsValues
  }

  const getStep3 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: TourUtil.getTourStatus(tour) !== TourEnums.STATUS.PENDING,
      isFinished: TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED,
      displayMinInfos: true,
      number: 3,
      label: 'Fermeture des commandes',
      rightBox: (
        <StyledTourStepCardRightBox align="flex-end">
          <Typography.BodySmall bold colorName="color-primary" align="end">
            {FormatUtils.capitalize(FormatUtils.formatDate(tour.close, 'PartialDate'))} à{' '}
            {FormatUtils.formatDate(tour.close, 'Time')}
          </Typography.BodySmall>
        </StyledTourStepCardRightBox>
      ),
      rightBoxOnFinished: (
        <StyledTourStepCardRightBox>
          <Typography.BodySmall bold colorName="color-primary">
            Fermé
          </Typography.BodySmall>
        </StyledTourStepCardRightBox>
      ),
    }
    return stepPropsValues
  }

  const getStep4 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED,
      isFinished: TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED,
      number: 4,
      label: 'Dépôt de la marchandise',
      description: 'Chez votre super-producteur',
      onPress: () => {
        if (!tour || !tour.id) return
        const params = {
          mode: 'DAY',
          start: new Date(tour.deposit),
        }
        navigation.navigate('ProducerReceptionPreparation', params)
      },
    }
    return stepPropsValues
  }

  const getStep5 = (): stepProps => {
    const stepPropsValues: stepProps = {
      isActive: TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED,
      isFinished: TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED,
      number: 5,
      label: 'Bons de livraison',
      description: 'Vos BL sont générés automatiquement',
      onPress: () => {
        if (onCardClick) {
          onCardClick('DOCUMENTS')
        }
      },
    }
    return stepPropsValues
  }

  const renderStep = (step: stepProps) => {
    if (step.display !== undefined && step.display === false) {
      return <></>
    }
    return (
      <>
        <TourStepCard step={step} onPress={step.onPress} />
      </>
    )
  }

  return (
    <StyledTourStepperWrapper>
      {renderStep(getStep1())}
      {renderStep(getStep2())}
      {renderStep(getStep3())}
      {renderStep(getStep4())}
      {renderStep(getStep5())}
    </StyledTourStepperWrapper>
  )
}

export default TourStepperProducer
