import { useState } from 'react'
import { Button, PageTitle, Spacer, TextInput, Typography } from '../../../components'
import { ContentWrapper, ScrollableFormWrapper } from '../../../utilities/styling/wrappers'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import GeneralUtils from '../../../utilities/utils/general'
import AuthenticationService from '../../../services/authentication'
import { PublicStackParamList } from '../../../navigation/PublicNavigationStack/PublicNavigationStack.model'

const ForgottenPasswordScreen = () => {
  const navigation = useNavigation<StackNavigationProp<PublicStackParamList>>()

  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onMailChange = (text: any) => {
    setEmail(text)
  }

  const onSendResetToken = async () => {
    if (!email) {
      setErrorMessage('Veuillez remplir tous les champs')
      return
    }

    if (!GeneralUtils.isEmail(email)) {
      setErrorMessage('Veuillez saisir un email valide')
      return
    }

    setErrorMessage('')
    setIsLoading(true)

    await AuthenticationService.sendResetPasswordEmail(email)
      .then((response) => {
        setIsLoading(false)
        if (response) {
          navigation.navigate('Authentication', {
            screen: 'ResetPassword',
            params: {
              email,
            },
          })
        }
      })
      .catch((error) => {
        setIsLoading(false)
        setErrorMessage('Les données saisies sont incorrectes')
      })
  }

  return (
    <ContentWrapper alignCenter noPaddingLeft noHeader>
      <ScrollableFormWrapper>
        <Spacer size={4} />
        <PageTitle title="Réinitialiser mon mot de passe"></PageTitle>
        <TextInput
          value={email}
          label="Email"
          field="email"
          keyboardType="email-address"
          onKeyPressCallBack={() => onSendResetToken()}
          onChangeText={(text) => onMailChange(text)}
        />
        <Spacer size={1} />
        {errorMessage ? (
          <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
        ) : null}
        <Typography.Body colorName="text-dark-3" align="center">
          Vous recevrez un code par email à saisir pour définir votre nouveau mot de passe si votre
          email est bien associé à un compte.
        </Typography.Body>
        <Spacer size={2} />
      </ScrollableFormWrapper>
      <Button label={'Envoyer'} onPress={onSendResetToken} fullWidth loading={isLoading} />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
    </ContentWrapper>
  )
}

export default ForgottenPasswordScreen
