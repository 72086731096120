import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { CountryEnums } from '../../../../enums'
import i18n from '../../../i18n'
import StorageUtil from '../../storage/storage'
import { LanguageContextData } from './languageContext.model'

export const LanguageContext = createContext<LanguageContextData>({} as LanguageContextData)

export const LanguageProvider: React.FC<any> = ({ children }) => {
  const [language, setLanguage] = useState()
  const i18nObject = i18n
  const getLanguage = useCallback(async () => {
    const storedLanguage = await StorageUtil.getItem('language')
    /*
    Add when handling language changes
    if (storedLanguage) {
      
      const languageStoredObject = JSON.parse(storedLanguage)
      setLanguage(languageStoredObject)
      i18nObject.locale = languageStoredObject.i18n
    
    } else {
      */
    const languageObject = JSON.stringify({
      key: 2,
      label: 'France',
      code: CountryEnums.Code.FRANCE,
      i18n: 'fr',
    })
    setLanguage(JSON.parse(languageObject))
    i18nObject.locale = 'fr'
    await StorageUtil.setItem('language', languageObject)

    // await SecureStore.setItemAsync('language', JSON.stringify(languageObject))
    // }
  }, [i18nObject])

  useEffect(() => {
    getLanguage()
  }, [getLanguage])

  const refreshLanguage = useCallback(getLanguage, [getLanguage])

  const storeItemInCache = useCallback(
    async (newLanguage: any) => {
      const oldLanguage = await StorageUtil.getItem('language')

      const isItemAlreadyInCache = newLanguage === oldLanguage

      if (!isItemAlreadyInCache && newLanguage) {
        await StorageUtil.setItem('language', newLanguage)

        // await SecureStore.setItemAsync('language', JSON.stringify(newLanguage))
      }

      refreshLanguage()
    },
    [refreshLanguage, i18nObject],
  )

  const value: LanguageContextData = useMemo(() => {
    return {
      language,
      storeItemInCache,
    }
  }, [language, storeItemInCache])

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
}
