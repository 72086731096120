import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Dimensions } from 'react-native'
import UserCarrierConfigurationNavigation from './UserCarrierNavigation/UserCarrierNavigation'
import ForceUpgradeScreen from '../../screens/Authentification/ForceUpgrade'
import CarrierMainNavigationStack from './CarrierMainNavigationStack'
import useDeepLinks from '../../utilities/hook/useDeepLinks'
import { DeepLinkEnum } from '../../utilities/context/deepLinkContext'
import LockedScreen from '../../screens/Authentification/LockedScreen'
import { CarrierStackParamList } from './CarrierNavigationStack.model'

const Stack = createStackNavigator<CarrierStackParamList>()

const CarrierNavigationStack = () => {
  useDeepLinks([DeepLinkEnum.NAVIGATION])

  return (
    <Stack.Navigator
      initialRouteName="CarrierMainNavigation"
      screenOptions={{
        headerShown: false,
      }}
    >
      {/*<Stack.Screen name="CarrierMainNavigation" component={BottomTabsCarrier} />*/}
      <Stack.Screen name="CarrierMainNavigation" component={CarrierMainNavigationStack} />

      <Stack.Screen
        name="UserCarrierConfigurationNav"
        component={UserCarrierConfigurationNavigation}
        options={{
          headerShown: false,
          presentation: 'transparentModal',
          ...TransitionPresets.ModalPresentationIOS,
          gestureResponseDistance: Dimensions.get('window').height,
        }}
      />

      <Stack.Screen
        name="ForceUpgradeScreen"
        component={ForceUpgradeScreen}
        options={{ gestureEnabled: false, headerShown: false, animationEnabled: false }}
      />

      <Stack.Screen
        name="LockedScreen"
        component={LockedScreen}
        options={{ gestureEnabled: false, headerShown: false, animationEnabled: false }}
      />

      {/* 
      <Stack.Screen
        name="PasswordForgotten"
        component={PasswordForgottenNavigation}
        options={{
          headerShown: false,
        }}
      />
      */}
    </Stack.Navigator>
  )
}

export default CarrierNavigationStack
