import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Col, PageTitle, Row, Spacer, Typography } from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { AddUserAdmin, User } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import ClientCarrierService from '../../../services/carrier/client'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierUpdateUser.styles'
import UserCarrierService from '../../../services/carrier/user'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import ProducerCarrierService from '../../../services/carrier/producer'
import { RightKeyEnum } from '../../../../enums'

const CarrierUpdateUserScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdateUser'>>()
  const [isLoading, setIsLoading] = useState(false)

  const { getAccessInfos, carrierHasRight } = useAuthenticationContext()

  const { groupId, userId, clientId, producerId } = route?.params ?? {
    groupId: '',
    userId: '',
    clientId: '',
    producerId: '',
  }

  // TODO ======= > Get one user
  const { data: user } = useQuery<User, Error>(
    ['sf_group_user'],
    () => UserCarrierService.findOneUserGroup(userId, getAccessInfos().carrierGroupId),
    {
      enabled: userId !== '',
    },
  )

  const [updatedUser, setUpdatedUser] = useState<AddUserAdmin>({
    email: '',
    password: '',
    lastName: '',
    firstName: '',
  })

  useEffect(() => {
    if (!user) return

    setUpdatedUser({
      email: user.email,
      password: user.password,
      lastName: user.lastName,
      firstName: user.firstName,
    })
  }, [user])

  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in updatedUser)) return null

    const userTemp = Object.assign({}, updatedUser)

    userTemp[param as keyof typeof updatedUser] = value
    setUpdatedUser(userTemp)
  }

  const onUpdateUser = async () => {
    setErrorMessage('')
    if (!user) {
      setErrorMessage("Erreur de chargement de l'utilisateur")
      return
    }

    setIsLoading(true)
    const userTemp = {
      email: FormatUtils.cleanEmail(updatedUser.email),
      password: updatedUser.password,
      lastName: updatedUser.lastName,
      firstName: updatedUser.firstName,
    }

    const updatedUserTemp = await UserCarrierService.updateUser(userId, userTemp, groupId)
      .then((res) => {
        queryClient.invalidateQueries(['sf_group'])
        queryClient.invalidateQueries(['sf_group_producers'])
        queryClient.invalidateQueries(['sf_group_clients'])

        if (navigation.canGoBack()) {
          navigation.goBack()
        }

        setIsLoading(false)
        return true
      })
      .catch((error) => {
        setErrorMessage("Un problème est survenu lors de la modification de l'utilisateur")
        setIsLoading(false)
        return false
      })
  }

  const onDeleteUser = async () => {
    setIsLoading(true)

    if (route.params?.clientId) {
      if (!clientId) {
        setIsLoading(false)
        return
      }

      await ClientCarrierService.deleteUserClient(userId, clientId, groupId)
        .then((res) => {
          queryClient.invalidateQueries(['sf_group'])
          queryClient.invalidateQueries(['sf_group_user'])
          queryClient.invalidateQueries(['sf_group_client', clientId])
          queryClient.invalidateQueries(['sf_group_clients'])

          setIsLoading(false)
          return true
        })
        .catch((error) => {
          setErrorMessage("Un problème est survenu lors de la suppression de l'utilisateur")
          setIsLoading(false)
          return false
        })
    } else if (route.params?.producerId) {
      if (!producerId) {
        setIsLoading(false)
        return
      }

      await ProducerCarrierService.deleteUserProducer(userId, producerId, groupId)
        .then((res) => {
          queryClient.invalidateQueries(['sf_group'])
          queryClient.invalidateQueries(['sf_group_user'])
          queryClient.invalidateQueries(['sf_group_producer'])
          queryClient.invalidateQueries(['sf_group_producers'])

          setIsLoading(false)
          return true
        })
        .catch((error) => {
          setErrorMessage("Un problème est survenu lors de la suppression de l'utilisateur")
          setIsLoading(false)
          return false
        })
    }

    if (navigation.canGoBack()) {
      navigation.goBack()
    }

    setIsLoading(false)
  }

  const canDeleteUser = () => {
    if (carrierHasRight(RightKeyEnum.C_ACCOUNTS_ACCESS)) {
      return true
    }

    return false
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Modifier un utilisateur" />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Typography.BodySmall colorName="text-dark-3">
              Rattaché au compte super producteur
            </Typography.BodySmall>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedUser.lastName}
                  label="Nom"
                  field="text"
                  onChangeText={(text) => setValue(text, 'lastName')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedUser.firstName}
                  label="Prénom"
                  field="text"
                  onChangeText={(text) => setValue(text, 'firstName')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedUser.email}
                  label="Email"
                  field="text"
                  onChangeText={(text) => setValue(text, 'email')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedUser.password}
                  label="Mot de passe"
                  field="password"
                  secureTextEntry
                  onChangeText={(text) => setValue(text, 'password')}
                  hidden={!updatedUser.email}
                />
              </Col>
            </Row>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Button
              label={FormatUtils.capitalize(i18n.t('save'))}
              onPress={() => onUpdateUser()}
              loading={isLoading}
              hasDoubleValidation
              confirmationLabel="Êtes-vous sûr de vouloir modifier cet utilisateur ?"
            />
            {(route.params?.clientId || route.params?.producerId) && (
              <Button
                label={'Supprimer'}
                onPress={() => onDeleteUser()}
                loading={isLoading}
                hasDoubleValidation
                confirmationLabel="Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
                colorName={'color-danger'}
                disabled={!canDeleteUser()}
              />
            )}
          </StyledButtonWrapper>
          {(route.params?.clientId || route.params?.producerId) && !canDeleteUser() && (
            <>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Typography.BodySmall colorName="color-warning" align="center">
                Vous n'avez pas les droits pour supprimer cet utilisateur
              </Typography.BodySmall>
            </>
          )}
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierUpdateUserScreen
