import { DeliveryNote, Pagination, Tour } from '../../../domain'
import ApiService from '../../api.service'

const getAll = async (
  tourId?: string,
  clientId?: string,
  producerId?: string,
  before?: Date,
  after?: Date,
  limit: number = 30,
  pageNumber: number = 1,
  groupId?: string,
): Promise<{ data: DeliveryNote[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/delivery-note/producer/${producerId}`, {
    clientId: clientId || undefined,
    tourId: tourId || undefined,
    before: before || undefined,
    after: after || undefined,
    limit,
    page: pageNumber,
    groupId: groupId || undefined,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOne = async (deliveryNoteId: string): Promise<DeliveryNote> => {
  const response = await ApiService.get(`/delivery-note/${deliveryNoteId}`)
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const DeliveryNoteProducerService = {
  getAll,
  getOne,
}

export default DeliveryNoteProducerService
