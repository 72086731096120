import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import HeaderHome from '../../components/HeaderHome'
import ProducerToursScreen from '../../../screens/Producer/ProducerTours'
import { Dimensions } from 'react-native'
import Header from '../../components/Header'
import ProducerTourOrdersScreen from '../../../screens/Producer/ProducerTourOrders'
import ProducerTourCratesScreen from '../../../screens/Producer/ProducerTourCrates'
import ProducerTourBatchsScreen from '../../../screens/Producer/ProducerTourBatchs'
import ProducerArrivalPreparationScreen from '../../../screens/Producer/ProducerArrivalPreparation'
import ProducerTourV2Screen from '../../../screens/Producer/ProducerTourV2'
import ProducerTourOrderScreen from '../../../screens/Producer/ProducerTourV2/screens/ProducerTourOrder'
import ProducerUpdateCart from '../../../screens/Producer/ProducerUpdateCart'
import ProducerAddCartBatch from '../../../screens/Producer/ProducerAddCartBatch'

const Stack = createStackNavigator()

const TourProducerNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="ProducerTours"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ProducerTours"
        component={ProducerToursScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Tournées',
        }}
      />
      <Stack.Screen
        name="ProducerTour"
        component={ProducerTourV2Screen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
          title: 'Tournée',
        }}
      />
      <Stack.Screen
        name="ProducerTourBatchs"
        component={ProducerTourBatchsScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
          title: 'Tournée',
        }}
      />
      <Stack.Screen
        name="ProducerTourOrders"
        component={ProducerTourOrdersScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
          title: 'Commandes',
        }}
      />
      <Stack.Screen
        name="ProducerTourOrder"
        component={ProducerTourOrderScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
          title: 'Tournée',
        }}
      />
      <Stack.Screen
        name="ProducerTourCrates"
        component={ProducerTourCratesScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
          title: 'Commandes',
        }}
      />
      <Stack.Screen
        name="ProducerReceptionPreparation"
        component={ProducerArrivalPreparationScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
          title: 'Tournée - colis',
        }}
      />
      <Stack.Screen
        name="ProducerUpdateCart"
        component={ProducerUpdateCart}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
          title: 'Mettre a jour commande',
        }}
      />
      <Stack.Screen
        name="ProducerAddCartBatch"
        component={ProducerAddCartBatch}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
          title: 'Mettre a jour commande',
        }}
      />
    </Stack.Navigator>
  )
}

export default TourProducerNavigation
