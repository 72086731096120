import styled from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`
const DeliveryNoteLineCardWrapper = styled.View`
  flex-direction: row;
  padding: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCheckboxWrapper = styled.View`
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacingUnit}px;
`

const LabelWrapperLeft = styled.View`
  flex: 1;
`

const CardTitleWrapper = styled.View`
  flex-direction: row;
  gap: ${({ theme }) => theme.spacingUnit * 0.7}px;
`

const LeftIconWrapper = styled.View`
  justify-content: center;
`

const LabelWrapperRight = styled.View`
  align-items: flex-end;
  justify-content: center;
`

export {
  DeliveryNoteLineCardWrapper,
  StyledCheckboxWrapper,
  LabelWrapperLeft,
  LabelWrapperRight,
  LeftIconWrapper,
  CardTitleWrapper,
  StyledCardWrapper,
}
