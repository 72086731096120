import React from 'react'
import { TextProps, TextTag } from './Text.model'
import StyledText from './Text.styles'

const Text: React.FC<TextProps> & { TextTag: typeof TextTag } = ({
  children,
  tag,
  colorName,
  bold = false,
  semiBold = false,
  medium = false,
  gutterBottom = false,
  numberOfLines,
  style,
  align,
  onPress,
  ellipsis = false,
  ...rest
}) => {
  return (
    <StyledText
      tag={tag}
      bold={bold}
      semiBold={semiBold}
      medium={medium}
      colorName={colorName}
      gutterBottom={gutterBottom}
      numberOfLines={ellipsis ? 1 : numberOfLines}
      style={style}
      align={align}
      onPress={onPress}
      ellipsizeMode={ellipsis ? 'tail' : undefined}
      ellipsis={ellipsis}
      {...rest}
    >
      {children}
    </StyledText>
  )
}

Text.TextTag = TextTag

export default Text
