import React, { useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useQuery } from '@tanstack/react-query'
import { Button, SectionTitle, Spacer } from '../../../components'
import { Pagination, PriceReporting } from '../../../domain'
import i18n from '../../../i18n'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import Loader from '../../../components/Loader'
import PriceReportingCarrierService from '../../../services/carrier/priceReporting'
import PriceReportingCard from '../../../modules/PriceReporting/PriceReportingCard'
import CardListPaginated from '../../../components/CardListPaginated'

const CarrierPriceReportingsScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierPriceReportings'>>()
  const [pageSizePriceReportings, setPageSizePriceReportings] = useState(5)
  const [pageNumberPriceReportings, setPageNumberPriceReportings] = useState(1)

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const dateFrom = new Date()
  dateFrom.setDate(dateFrom.getDate() - 60)

  const {
    data: priceReportings,
    refetch: refetchPriceReportings,
    isInitialLoading: isPriceReportingsLoading,
  } = useQuery<{ data: PriceReporting[]; pagination: Pagination }, Error>(
    ['sf_price_reportings'],
    () =>
      PriceReportingCarrierService.getAll(
        getAccessInfos().carrierGroupId,
        dateFrom,
        undefined,
        pageSizePriceReportings,
        pageNumberPriceReportings,
      ),
    {
      keepPreviousData: true,
    },
  )

  const onClickAddPriceReporting = () => {
    navigation.navigate('CarrierAddPriceReporting', { groupId: getAccessInfos().carrierGroupId })
  }

  const onClickPriceReporting = (priceReporting: PriceReporting) => {
    navigation.navigate('CarrierPriceReporting', { id: priceReporting.id })
  }

  const onChangePagePriceReportings = async (pageNumber: number) => {
    await setPageNumberPriceReportings(pageNumber)
    refetchPriceReportings()
  }

  if (isPriceReportingsLoading) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <SectionTitle
          title={`Relevés de prix (${priceReportings?.pagination?.totalCount || 0})`}
          buttonRight={
            <Button
              small
              label={FormatUtils.capitalize(i18n.t('add'))}
              onPress={() => onClickAddPriceReporting()}
            />
          }
        />
        <CardListPaginated
          data={priceReportings?.data || []}
          pagination={priceReportings?.pagination}
          element={function (priceReporting: any) {
            return (
              <PriceReportingCard
                key={priceReporting.id}
                priceReporting={priceReporting}
                onClick={onClickPriceReporting}
              />
            )
          }}
          isLoading={isPriceReportingsLoading}
          emptyMessage="Aucun relevé de prix"
          onChangePage={onChangePagePriceReportings}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierPriceReportingsScreen
