import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const DashboardContentWrapper = styled.View`
  flex: 1;
  width: 92%;
`
const ArrivalSectionWrapper = styled.View`
  width: 100%;
  justify-content: center;
`

export { DashboardContentWrapper, ArrivalSectionWrapper }
