import React from 'react'
import { useTheme } from 'styled-components/native'
import { CarrierTourCratesTopBoxProps } from './CarrierTourCratesTopBox.model'
import { CardParam } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { StyledTopBox } from './CarrierTourCratesTopBox.styles'

function CarrierTourCratesTopBox({
  tour,
  tourBatchs,
  emptyPropositions,
}: CarrierTourCratesTopBoxProps) {
  const theme = useTheme()

  return (
    <>
      <StyledTopBox>
        <CardParam label={'Colis'} value={tour.nbCrates || '-'} flex={5} isStart />
        <CardParam label={'Palettes'} value={tour.palletQuantity || 'NA'} flex={5} isStart />
        <CardParam
          label={'Identification'}
          value={tour.areAllCartBatchIdentified ? 'Complète' : 'Incomplète'}
          flex={5}
          isStart
        />
      </StyledTopBox>
    </>
  )
}

export default CarrierTourCratesTopBox
