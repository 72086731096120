import styled from 'styled-components/native'

const LandingPageWrapper = styled.View`
  width: 100%;
  min-height: 80vh;
`

const StyledLogoBox = styled.View`
  height: 260px;
  align-items: center;
  justify-content: center;
`

const StyledImageLogo = styled.Image`
  width: 70%;
  max-width: 300px;
  max-height: 200px;
  height: 70%;
`

const LandingTextContentWrapper = styled.View`
  align-items: center;
`

const PageTitleWrapper = styled.View`
  align-items: center;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

export {
  LandingPageWrapper,
  StyledLogoBox,
  StyledImageLogo,
  LandingTextContentWrapper,
  PageTitleWrapper,
  StyledButtonWrapper,
}
