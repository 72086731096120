import { TourChronologySlideProps } from './TourChronologySlide.model'
import { Button, Icon, Icons, Spacer, Typography } from '../../../../../components'
import { View } from 'react-native'
import { ButtonWrapper, StyledGainBox } from './TourChronologySlide.styles'
import InfoBox from '../../../../../components/InfoBox'
import OrderedCard from '../../../../../components/OrderedCard'
import { useTheme } from 'styled-components/native'
import FormatUtils from '../../../../../utilities/utils/format'

const TourChronologySlide = ({ onClick, tour }: TourChronologySlideProps) => {
  const theme = useTheme()

  const info = {
    title: 'Pourquoi avoir défini cette chronologie ?',
    content:
      'En orchestrant votre tournée de livraison dans cet ordre, vous maximiser vos chance de remplir votre camion, particulièrement si vous travaillez avec plusieurs producteurs.',
  }

  const steps = [
    {
      ranking: 1,
      title: 'Les producteurs font leur mercuriale',
      body: 'Avant l’ouverture des des commandes',
    },
    {
      ranking: 2,
      title: 'Les commandes sont ouvertes',
      body: 'Les clients passent commande',
      children: (
        <StyledGainBox>
          <Icon type={Icons.MaterialCommunityIcons} name="clock" size={theme.spacingUnit * 1.5} />
          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.Body bold>
            {FormatUtils.formatDate(tour?.open?.toString(), 'FullDate') +
              ' ' +
              FormatUtils.formatDate(tour?.open?.toString(), 'Time')}
          </Typography.Body>
        </StyledGainBox>
      ),
    },
    {
      ranking: 3,
      title: 'Les commandes sont fermées',
      body: 'Les producteurs reçoivent leur ordre de dépôt',
      children: (
        <StyledGainBox>
          <Icon type={Icons.MaterialCommunityIcons} name="clock" size={theme.spacingUnit * 1.5} />
          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.Body bold>
            {FormatUtils.formatDate(tour?.close?.toString(), 'FullDate') +
              ' ' +
              FormatUtils.formatDate(tour?.close?.toString(), 'Time')}
          </Typography.Body>
        </StyledGainBox>
      ),
    },
    {
      ranking: 4,
      title: 'Dépot de la marchandise',
      body: 'Les producteurs apportent leur marchandise',
      children: (
        <StyledGainBox>
          <Icon type={Icons.MaterialCommunityIcons} name="clock" size={theme.spacingUnit * 1.5} />
          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.Body bold>
            {FormatUtils.formatDate(tour?.deposit?.toString(), 'FullDate') +
              ' ' +
              FormatUtils.formatDate(tour?.deposit?.toString(), 'Time')}
          </Typography.Body>
        </StyledGainBox>
      ),
    },
    {
      ranking: 5,
      title: 'Départ en livraison',
      body: 'Vous partez en livraison',
      children: (
        <StyledGainBox>
          <Icon type={Icons.MaterialCommunityIcons} name="clock" size={theme.spacingUnit * 1.5} />
          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.Body bold>
            {FormatUtils.formatDate(tour?.start?.toString(), 'FullDate') +
              ' ' +
              FormatUtils.formatDate(tour?.start?.toString(), 'Time')}
          </Typography.Body>
        </StyledGainBox>
      ),
    },
  ]

  return (
    <>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <View style={{ width: '100%', alignItems: 'center' }}>
        <Typography.PageTitle>{'Chronologie'}</Typography.PageTitle>
      </View>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <InfoBox title={info.title} content={info.content} />
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      {steps.map((step, index) => (
        <OrderedCard
          key={index}
          ranking={step.ranking}
          title={step.title}
          description={step.body}
          children={step.children}
        />
      ))}
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <ButtonWrapper>
        <Button onPress={() => onClick()} label="Valider" colorName="color-secondary" />
      </ButtonWrapper>
    </>
  )
}

export default TourChronologySlide
