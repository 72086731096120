import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { RightKeyEnum, TourEnums } from '../../../../enums'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import BadgeStatus from '../../../components/BadgeStatus'
import FormatUtils from '../../../utilities/utils/format'
import GeneralUtils from '../../../utilities/utils/general'
import { TourCardProps } from './TourCard.model'
import {
  StyledArrowBox,
  StyledCardContent,
  StyledCardWrapper,
  StyledCardWrapperUnclickable,
  StyledLeftBox,
  StyledRightBox,
  StyledRightBoxCapacity,
  StyledTimeBox,
  StyledTimeBoxLeft,
  StyledTimeBoxRight,
  StyledTourDateTimeWrapper,
  StyledTourInfosBox,
} from './TourCard.styles'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import DeviceUtil from '../../../utilities/utils/device'
import TourUtil from '../../../utilities/utils/tour'
import { TourFillingInfo } from './TourFillingInfo'
import { TourWithFormattedFillingInfo } from '../../../utilities/selector/formatTourFillingInfo'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'

const TourCard = ({
  tour,
  onClick,
  rightIcon,
  isCarrier,
  displayType,
  fromScreen,
}: TourCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const { carrierHasRight } = useAuthenticationContext()
  const hasTourContentRight = carrierHasRight(RightKeyEnum.C_TOUR_CONTENT)
  const hasTourPreparationRight = carrierHasRight(RightKeyEnum.C_TOUR_PREPARATION)

  const OnClickTour = () => {
    if (onClick) {
      // If user has the C_TOUR_CONTENT right, we redirect him to the tour content screen
      if (hasTourContentRight) {
        onClick(tour)
        // If user has the C_TOUR_PREPARATION right, we redirect him to the tour preparation screen depending on the navigation source
      } else if (hasTourPreparationRight) {
        if (fromScreen === 'CarrierDashboard') {
          navigation.navigate('CarrierTours', {
            screen: 'CarrierTourCrates',
            params: { id: tour.id },
          })
        } else if (fromScreen === 'CarrierTours') {
          navigation.navigate('CarrierTourCrates', {
            id: tour.id,
          })
        }
        // If the user has no right (producer), we just call the onClick function
      } else {
        onClick(tour)
      }
    }
  }

  const getLabelFromStatus = () => {
    const status = TourUtil.getTourStatus(tour)
    let label = 'Commandes ouvertes'
    if (tour.close) {
      label = `Ouvert jusqu'au ${FormatUtils.formatDate(
        tour.close,
        'Date',
      )} à ${FormatUtils.formatDate(tour.close, 'Time')}`
    }
    if (status === TourEnums.STATUS.PENDING) {
      label = `Ouverture le ${FormatUtils.formatDate(tour.open, 'Date')} à ${FormatUtils.formatDate(
        tour.open,
        'Time',
      )}`
    }
    if (status === TourEnums.STATUS.CLOSED) {
      label = 'Commandes fermées'
    }

    if (status === TourEnums.STATUS.COMPLETED) {
      label = 'Clôturée'
    }
    return label
  }

  if (!tour) return null

  const isLargeScreen = !DeviceUtil.isMobileApp()
  const hasToursDetailsRight = carrierHasRight(RightKeyEnum.C_TOURS_DETAILS)

  if (displayType === 'tourCardLight') {
    return (
      <StyledCardWrapperUnclickable>
        <TouchableCard>
          <StyledCardContent isTourCardLight>
            <StyledTourDateTimeWrapper>
              <Typography.CardTitle style={{ lineHeight: `${theme.fontSizes.CARD_TITLE}px` }}>
                {FormatUtils.capitalize(
                  FormatUtils.formatDate(tour.start, 'PartialDate', tour.end),
                )}
              </Typography.CardTitle>
              <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
              <View>
                <Typography.BodySmall style={{ lineHeight: `${theme.fontSizes.BODY_SMALL + 1}px` }}>
                  de{' '}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </View>
            </StyledTourDateTimeWrapper>
            <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3">
              {FormatUtils.getTourTarget(tour, 'long')}{' '}
            </Typography.BodySmall>
          </StyledCardContent>
        </TouchableCard>
      </StyledCardWrapperUnclickable>
    )
  }

  if (displayType === 'tourToConcludeCard') {
    return (
      <StyledCardWrapper onPress={OnClickTour} activeOpacity={0.8}>
        <TouchableCard>
          <StyledCardContent>
            <StyledLeftBox>
              <StyledTourDateTimeWrapper>
                <Typography.CardTitle style={{ lineHeight: `${theme.fontSizes.CARD_TITLE}px` }}>
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'PartialDate', tour.end),
                  )}
                </Typography.CardTitle>
                <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
                <Typography.BodySmall style={{ lineHeight: `${theme.fontSizes.BODY_SMALL + 1}px` }}>
                  de{' '}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </StyledTourDateTimeWrapper>
              <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall colorName="text-dark-3" bold>
                vers {FormatUtils.getTourTarget(tour, 'short')}{' '}
                {!isCarrier ? `(${tour.group?.label}) ` : ''}
              </Typography.BodySmall>
              <Spacer size={1.5} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall colorName="color-danger" bold>
                À clôturer
              </Typography.BodySmall>
            </StyledLeftBox>
            <StyledRightBox isTourToConcludeCard>
              {tour.isPrivate && isCarrier && (
                <>
                  <Typography.BodyExtraSmall colorName="text-dark-3">
                    Privée
                  </Typography.BodyExtraSmall>
                  <Spacer size={0.4} axis={Spacer.AxisEnum.HORIZONTAL} />
                </>
              )}

              <Icon
                type={Icons.Ionicons}
                name="chevron-forward-outline"
                size={theme.spacingUnit * 2.5}
                color={theme.colors['color-primary']}
              />
              <StyledRightBoxCapacity>
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name="truck-cargo-container"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['color-primary']}
                />
                {tour && isCarrier && (
                  <TourFillingInfo tour={tour as TourWithFormattedFillingInfo} />
                )}
                <Typography.BodyExtraSmall colorName={'color-primary'}>
                  {`(${tour.nbCrates || 0} colis)`}
                </Typography.BodyExtraSmall>
              </StyledRightBoxCapacity>
              <Typography.BodyExtraSmall colorName={'color-primary'}>
                {`${tour.nbOrders || 0} cmds. / ${FormatUtils.formatPrice(tour.totalIncome)}`}
              </Typography.BodyExtraSmall>
            </StyledRightBox>
          </StyledCardContent>
        </TouchableCard>
      </StyledCardWrapper>
    )
  }

  return (
    <StyledCardWrapper onPress={OnClickTour} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <StyledTourDateTimeWrapper>
              <Typography.CardTitle style={{ lineHeight: `${theme.fontSizes.CARD_TITLE}px` }}>
                {FormatUtils.capitalize(
                  FormatUtils.formatDate(tour.start, 'PartialDate', tour.end),
                )}
              </Typography.CardTitle>
              <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
              <Typography.BodySmall style={{ lineHeight: `${theme.fontSizes.BODY_SMALL + 1}px` }}>
                de{' '}
                {FormatUtils.capitalize(
                  FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                )}
              </Typography.BodySmall>
            </StyledTourDateTimeWrapper>
            <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3" bold>
              vers {FormatUtils.getTourTarget(tour, 'short')}{' '}
              {!isCarrier ? `(${tour.group?.label}) ` : ''}
            </Typography.BodySmall>
            <Spacer size={0.9} axis={Spacer.AxisEnum.VERTICAL} />
            {TourUtil.getTourStatus(tour) === TourEnums.STATUS.CLOSED && (
              <BadgeStatus
                label={
                  GeneralUtils.isDateBefore(new Date(), new Date(tour.end))
                    ? 'Commandes fermées'
                    : 'Terminée'
                }
                color="text-light-1"
                backgroundColor="text-dark-3"
                extraSmall
              />
            )}
            {TourUtil.getTourStatus(tour) === TourEnums.STATUS.COMPLETED && (
              <BadgeStatus
                label={'Clôturée'}
                color="text-light-1"
                backgroundColor="text-dark-3"
                extraSmall
              />
            )}
            {(TourUtil.getTourStatus(tour) === TourEnums.STATUS.OPEN ||
              TourUtil.getTourStatus(tour) === TourEnums.STATUS.PENDING) && (
              <StyledTimeBox>
                <StyledTimeBoxLeft>
                  <Icon
                    type={Icons.MaterialCommunityIcons}
                    name="door-open"
                    size={theme.spacingUnit * 2}
                    color={theme.colors['color-primary']}
                  />
                </StyledTimeBoxLeft>
                <StyledTimeBoxRight>
                  {TourUtil.getTourStatus(tour) === TourEnums.STATUS.OPEN ? (
                    <BadgeStatus
                      label="Ouvert"
                      color="text-dark-1"
                      backgroundColor="color-secondary"
                      extraSmall
                    />
                  ) : (
                    <Typography.BodySmall bold>
                      {GeneralUtils.remainingTime(new Date(tour.open || ''))
                        ? `dans ${GeneralUtils.remainingTime(new Date(tour.open || ''))}`
                        : `${FormatUtils.formatDate(tour.open, 'Time')} (J-${Math.round(
                            GeneralUtils.diffDays(new Date(), new Date(tour.open || '')),
                          )})`}
                    </Typography.BodySmall>
                  )}
                </StyledTimeBoxRight>
                <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1.5} />
                {TourUtil.getTourStatus(tour) === TourEnums.STATUS.OPEN ? (
                  <>
                    <StyledTimeBoxLeft>
                      <Icon
                        type={Icons.MaterialCommunityIcons}
                        name="door-sliding-lock"
                        size={theme.spacingUnit * 2}
                        color={theme.colors['color-primary']}
                      />
                    </StyledTimeBoxLeft>
                    <StyledTimeBoxRight>
                      <Typography.BodySmall bold>
                        {GeneralUtils.remainingTime(new Date(tour.close || ''))
                          ? `dans ${GeneralUtils.remainingTime(new Date(tour.close || ''))}`
                          : `${FormatUtils.formatDate(tour.close, 'Time')} (J-${Math.round(
                              GeneralUtils.diffDays(new Date(), new Date(tour.close || '')),
                            )})`}
                      </Typography.BodySmall>
                    </StyledTimeBoxRight>
                  </>
                ) : null}
              </StyledTimeBox>
            )}
          </StyledLeftBox>
          <StyledRightBox>
            {rightIcon ? (
              rightIcon
            ) : (
              <StyledArrowBox>
                {tour.isPrivate && isCarrier && (
                  <>
                    <Typography.BodyExtraSmall colorName="text-dark-3">
                      Privée
                    </Typography.BodyExtraSmall>
                    <Spacer size={0.4} axis={Spacer.AxisEnum.HORIZONTAL} />
                  </>
                )}
                {tour && isCarrier && (
                  <TourFillingInfo tour={tour as TourWithFormattedFillingInfo} variant="extended" />
                )}
                <Spacer size={0.6} axis={Spacer.AxisEnum.HORIZONTAL} />
                <View>
                  <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                  <Icon
                    type={Icons.Ionicons}
                    name="chevron-forward-outline"
                    size={theme.spacingUnit * 2}
                    color={theme.colors['text-dark-3']}
                  />
                </View>
              </StyledArrowBox>
            )}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.3} />
            <StyledTourInfosBox isLargeScreen={isLargeScreen}>
              {tour?.nbOrders && tour.nbOrders > 0 && isCarrier ? (
                <Typography.BodyExtraSmall colorName={'text-dark-3'} bold>
                  {hasToursDetailsRight
                    ? `${tour.nbOrders || 0} cmds. (${
                        tour.nbCrates || 0
                      } cls / ${FormatUtils.formatPrice(tour.totalIncome)})`
                    : `${tour.nbCrates || 0} colis`}
                </Typography.BodyExtraSmall>
              ) : (
                <Typography.BodyExtraSmall colorName={'text-dark-3'}>
                  Dépôt
                </Typography.BodyExtraSmall>
              )}
            </StyledTourInfosBox>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <StyledTimeBox>
              <StyledTimeBoxLeft>
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name="database-arrow-down"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['color-primary']}
                />
              </StyledTimeBoxLeft>
              <StyledTimeBoxRight>
                <Typography.BodySmall bold>
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.deposit, 'PartialDate'))} à{' '}
                  {FormatUtils.formatDate(tour.deposit, 'Time')}
                </Typography.BodySmall>
              </StyledTimeBoxRight>
              {/*
              <StyledTimeBoxLeft>
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name="truck-delivery"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['color-primary']}
                />
              </StyledTimeBoxLeft>
              <StyledTimeBoxRight>
                <Typography.BodySmall bold>
                  {FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end)}
                </Typography.BodySmall>
              </StyledTimeBoxRight>
              */}
            </StyledTimeBox>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default TourCard
