import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const DashboardContentWrapper = styled.View`
  flex: 1;
  width: 92%;
`

const StyledButtonWrapper = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 2}px;
`

export { DashboardContentWrapper, StyledButtonWrapper }
