import React from 'react'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../components'
import { CardPageLinkProps } from './CardPageLink.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRightBox,
} from './CardPageLink.styles'

const CardPageLink = ({
  label,
  icon,
  onClick,
  subtitle,
  amount,
  hasAmountBox,
}: CardPageLinkProps) => {
  const theme = useTheme()

  const OnClickCard = () => {
    if (onClick) {
      onClick()
    }
  }

  const iconName = () => {
    if (icon === 'arrow-right') {
      return 'chevron-forward-outline'
    } else return 'home'
  }

  return (
    <StyledCardWrapper onPress={OnClickCard} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent hasAmountBox={hasAmountBox}>
          {hasAmountBox && (
            <>
              <StyledLeftBox>
                <Typography.CardTitle style={{ alignSelf: 'center' }}>
                  {amount || 0}
                </Typography.CardTitle>
              </StyledLeftBox>
              <Spacer size={0.1} axis={Spacer.AxisEnum.VERTICAL} />
            </>
          )}
          <StyledMiddleBox>
            <Typography.CardTitle>{label}</Typography.CardTitle>
            {subtitle && (
              <>
                <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall colorName="text-dark-3">{subtitle}</Typography.BodySmall>
              </>
            )}
          </StyledMiddleBox>
          <StyledRightBox>
            {icon ? (
              <Icon
                type={Icons.Ionicons}
                name={iconName()}
                size={theme.spacingUnit * 2}
                color={theme.colors['text-dark-3']}
              />
            ) : null}
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default CardPageLink
