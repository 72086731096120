import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const CardContentWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacingUnit * 0.5}px;
`

const StyledCardParam = styled.View`
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  min-height: 60px;
`

export { CardContentWrapper, StyledCardParam }
