import styled, { css } from 'styled-components/native'
import { AxisEnum, SpacerProps } from './Spacer.model'

function getHeight({ theme, axis, size, fixedSize }: SpacerProps) {
  if (axis === 'horizontal') {
    return 1
  } else if (fixedSize && fixedSize > 0) {
    return fixedSize
  } else {
    return theme.spacingUnit * (size || 1)
  }
}
function getWidth({ axis, size }: SpacerProps) {
  if (axis === 'vertical') {
    return 1
  }
  return size || 1
}
const StyledSpacerView = styled.View<SpacerProps>`
  width: ${({ theme, axis, size }) => theme.spacingUnit * getWidth({ axis, size })}px;
  min-width: ${({ theme, axis, size }) => theme.spacingUnit * getWidth({ axis, size })}px;
  height: ${({ theme, axis, size, fixedSize }) => getHeight({ theme, axis, size, fixedSize })}px;
  min-height: ${({ theme, axis, size, fixedSize }) =>
    getHeight({ theme, axis, size, fixedSize })}px;
`

export default StyledSpacerView
