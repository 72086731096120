import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { useTheme } from 'styled-components/native'
import FormatUtils from '../../utilities/utils/format'
import DirectusUtil from '../../utilities/utils/directus'
import { ProducerStackParamList } from '../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { Product } from '../../domain'
import Typography from '../Typography'
import Spacer from '../Spacer'
import TouchableCard from '../TouchableCard'
import { ShelfLabelCardProps } from './ShelfLabelCard.model'
import {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledImage,
  StyledCenterBox,
  StyledValuesWrapper,
  StyledValueBox,
  StyledRightBox,
  StyledGainBox,
} from './ShelfLabelCard.styles'
import { Icon, Icons, TextInput, TitleWithTag } from '..'
import ShelfLabelClientService from '../../services/client/shelfLabel'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { ProductEnums } from '../../../enums'

const ShelfLabelCard = ({
  shelfLabel,
  refetchShelves,
  onPressPrint,
  onPressEdit,
  isLoading,
}: ShelfLabelCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [newCustomerPrice, setNewCustomerPrice] = useState<string | undefined>(
    shelfLabel.customerPrice ? shelfLabel.customerPrice.toString().replace('.', ',') : undefined,
  )
  const [isLoadingPrint, setIsLoadingPrint] = useState<boolean>(false)

  useEffect(() => {
    if (shelfLabel.customerPrice) {
      setNewCustomerPrice(shelfLabel.customerPrice.toString().replace('.', ','))
    }
  }, [shelfLabel])

  const onChangeCustomerPriceInput = (valueText: string) => {
    // const valueNumber = parseFloat(valueText)
    setNewCustomerPrice(valueText)
  }

  const onBlurInputRanking = async () => {
    if (newCustomerPrice) {
      await ShelfLabelClientService.update(shelfLabel.id, {
        customerPrice: parseFloat(newCustomerPrice.replace(',', '.')),
      })
      if (refetchShelves) {
        refetchShelves()
      }
    }
  }

  const newMesureType = shelfLabel.mesureType
  const mesureType = shelfLabel.cartBatch?.batch?.product?.mesureType
  const unitWeight = shelfLabel.cartBatch?.batch?.product?.unitWeight
  const vatRate = shelfLabel.cartBatch?.batch?.product?.vatRate

  const getMargin = () => {
    let clientPrice = shelfLabel.clientPrice
    let customerPrice = parseFloat((newCustomerPrice || '').replace(',', '.'))

    if (
      newMesureType &&
      mesureType &&
      newMesureType !== mesureType &&
      unitWeight &&
      unitWeight !== 1
    ) {
      customerPrice = customerPrice * unitWeight
    }

    const computedMargin = customerPrice
      ? (customerPrice / (1 + (vatRate || 0) / 100) - (clientPrice || 0)) /
        (customerPrice / (1 + (vatRate || 0) / 100))
      : 0

    return computedMargin
  }

  const displayMargin = () => {
    const isPcsToKiloWithUnitWeight =
      mesureType === ProductEnums.MESURE_TYPE.PIECE &&
      newMesureType === ProductEnums.MESURE_TYPE.KILO &&
      unitWeight

    const isKiloToPcsWithUnitWeight =
      mesureType === ProductEnums.MESURE_TYPE.KILO &&
      newMesureType === ProductEnums.MESURE_TYPE.PIECE &&
      unitWeight &&
      unitWeight !== 1

    return (
      getMargin() &&
      (newMesureType === mesureType || isPcsToKiloWithUnitWeight || isKiloToPcsWithUnitWeight)
    )
  }

  const onPrintOne = async () => {
    setIsLoadingPrint(true)
    await onPressPrint([shelfLabel.id])
    setIsLoadingPrint(false)
  }

  return (
    <StyledCardWrapper activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <StyledImage
              source={DirectusUtil.getProductImageUrlFromId({
                imageId: shelfLabel.cartBatch?.batch?.product?.imageId,
              } as any as Product)}
              resizeMode="cover"
            />
          </StyledLeftBox>
          <StyledCenterBox>
            <View>
              <Typography.CardTitle colorName="text-dark-1" ellipsis>
                {shelfLabel?.title}
              </Typography.CardTitle>
              <Typography.BodyExtraSmall ellipsis colorName="text-dark-3" bold>
                {shelfLabel?.subTitle}
              </Typography.BodyExtraSmall>
            </View>

            <StyledValuesWrapper>
              <StyledValueBox>
                <Typography.BodySmall>Prix d'achat HT</Typography.BodySmall>
                <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall semiBold>
                  {FormatUtils.formatPrice(shelfLabel.clientPrice)}/
                  {FormatUtils.formatUnity(shelfLabel?.cartBatch?.batch?.product?.mesureType)}
                </Typography.BodySmall>
              </StyledValueBox>
              <Spacer size={1.5} axis={Spacer.AxisEnum.HORIZONTAL} />

              <StyledValueBox>
                <Typography.BodySmall align="center">Marge</Typography.BodySmall>
                <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                <StyledGainBox>
                  <Typography.BodySmall semiBold>
                    {displayMargin() ? FormatUtils.formatPercentage(getMargin()) : '-'}
                  </Typography.BodySmall>
                </StyledGainBox>
              </StyledValueBox>
              <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
            </StyledValuesWrapper>
          </StyledCenterBox>
          <StyledRightBox>
            <TextInput
              value={(newCustomerPrice || '').toString()}
              label=""
              field="text"
              onChangeText={(text) => onChangeCustomerPriceInput(text)}
              onBlurCallBack={onBlurInputRanking}
              small
              suffix={`€ TTC/${FormatUtils.formatUnity(shelfLabel.mesureType)}`}
            />
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity onPress={() => onPressEdit(shelfLabel.id)}>
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name="clipboard-edit-outline"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['color-primary']}
                />
              </TouchableOpacity>
              <Spacer size={1.5} axis={Spacer.AxisEnum.HORIZONTAL} />
              {isLoadingPrint ? (
                <ActivityIndicator size="small" color={theme.colors['color-primary']} />
              ) : (
                <TouchableOpacity onPress={() => onPrintOne()}>
                  <Icon
                    type={Icons.Ionicons}
                    name="print"
                    size={theme.spacingUnit * 2}
                    color={theme.colors['color-primary']}
                  />
                </TouchableOpacity>
              )}
            </View>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default ShelfLabelCard
