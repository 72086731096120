import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const BoxWrapper = styled.View`
  width: 100%;
  max-width: 1000px;
  min-height: 200px;
  background-color: ${({ theme }) => theme.colors['background-3']};
  margin-top: ${({ theme }) => theme.spacingUnit * 1}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 2}px;

  border-radius: ${({ theme }) => theme.borderRadius}px;
`

const ProducerBox = styled.View`
  min-height: 50px;
  margin: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-bottom: 0;
  flex-direction: row;
`

const LeftBox = styled.View``

const StyledProducerImage = styled.Image`
  width: ${({ theme }) => theme.spacingUnit * 7}px;
  height: ${({ theme }) => theme.spacingUnit * 7}px;
  border-radius: ${({ theme }) => theme.spacingUnit * (7 / 2)}px;
`

const RightBox = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 0}px;
  flex: 1;
  height: 45px;
  overflow: hidden;
`

export { BoxWrapper, ProducerBox, StyledProducerImage, LeftBox, RightBox }
