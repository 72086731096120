import React from 'react'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'

import {
  StyleStatusBox,
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
  StyledTimeBox,
} from './ProducerCertificateCard.styles'
import { DocumentProducerCardProps } from './ProducerCertificateCard.model'
import { useNavigation } from '@react-navigation/native'
import { ProducerStackParamList } from '../../../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { StackNavigationProp } from '@react-navigation/stack'
import { DocumentEnums } from '../../../../../../enums/document'
import GeneralUtils from '../../../../../utilities/utils/general'
import { InterfaceColors } from '../../../../../utilities/styling/theme/theme'

export enum DocumentExpirationStatus {
  VALID = 'VALID',
  EXPIRES_SOON = 'EXPIRES_SOON',
  EXPIRED = 'EXPIRED',
}

const ProducerCertificateCard = ({ document }: DocumentProducerCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const OnClickDocument = () => {
    navigation.navigate('ProfileProducerNav', {
      screen: 'ProducerDetailsCertificates',
      params: { documentId: document.id },
    })
  }

  const getDocumentExpirationStatus = (): DocumentExpirationStatus => {
    let tempStatus: DocumentExpirationStatus = DocumentExpirationStatus.VALID

    const currentDate = new Date()
    const expirationDate = new Date(document.expiration)

    const diffDays = GeneralUtils.diffDays(currentDate, expirationDate)

    if (diffDays < 30) {
      tempStatus = DocumentExpirationStatus.EXPIRES_SOON
    }

    if (diffDays < 0) {
      tempStatus = DocumentExpirationStatus.EXPIRED
    }

    return tempStatus
  }

  const expirationDateColor = (): { label: string; color: keyof InterfaceColors } => {
    let tempColor: keyof InterfaceColors = 'text-dark-1'
    let tempLabel = `Valide jusqu'au ${FormatUtils.formatDate(
      document.expiration.toString(),
      'DateFullYear',
    )}`
    const status = getDocumentExpirationStatus()

    if (status === DocumentExpirationStatus.EXPIRES_SOON) {
      tempColor = 'color-warning'
      tempLabel = `Expire le ${FormatUtils.formatDate(
        document.expiration.toString(),
        'DateFullYear',
      )}`
    }

    if (status === DocumentExpirationStatus.EXPIRED) {
      tempColor = 'color-danger'
      tempLabel = `Expiré depuis le ${FormatUtils.formatDate(
        document.expiration.toString(),
        'DateFullYear',
      )}`
    }

    return {
      color: tempColor,
      label: tempLabel,
    }
  }

  if (!document) return null

  return (
    <StyledCardWrapper
      onPress={OnClickDocument}
      activeOpacity={0.8}
      key={`documentCard-${document.id}`}
    >
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Icon
              type={Icons.MaterialCommunityIcons}
              name="file-document-outline"
              size={theme.spacingUnit * 4.5}
              color={theme.colors['color-grey']}
            />
            <StyledTimeBox>
              <Typography.CardTitle>
                {DocumentEnums.DocumentTypes.find((type) => type.value === document.type)?.label}
              </Typography.CardTitle>

              <Typography.BodySmall colorName={expirationDateColor().color}>
                {expirationDateColor().label}
              </Typography.BodySmall>
            </StyledTimeBox>
            <Spacer size={0.15} axis={Spacer.AxisEnum.HORIZONTAL} />
          </StyledLeftBox>

          <StyledRightBox>
            <StyleStatusBox>
              <Typography.BodySmall>
                {`Ajouté le ${FormatUtils.formatDate(document.createdDate.toString(), 'Date')}`}
              </Typography.BodySmall>
              <Typography.BodySmall
                colorName={
                  document.status === DocumentEnums.DocumentStatusEnum.PRIVATE
                    ? 'color-danger'
                    : 'color-primary'
                }
                bold
              >
                {document.status === DocumentEnums.DocumentStatusEnum.PRIVATE ? 'Privé' : 'Public'}
              </Typography.BodySmall>
            </StyleStatusBox>
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              size={theme.spacingUnit * 2.5}
              color={theme.colors['color-grey']}
            />
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default ProducerCertificateCard
