import { Typography, Spacer } from '../../../../../components'
import {
  Cart,
  CartBatch,
  CatalogExtended,
  Producer,
  ProducerWithProducerTourData,
} from '../../../../../domain'
import CartBatchCard from '../../../../../modules/Cart/CartBatchCard'
import AccountUtil from '../../../../../utilities/utils/account'
import DirectusUtil from '../../../../../utilities/utils/directus'
import FormatUtils from '../../../../../utilities/utils/format'
import {
  BoxWrapper,
  LeftBox,
  ProducerBox,
  RightBox,
  StyledProducerImage,
} from './ClientCartBatchs.styles'

export interface ClientCartBatchsProps {
  producer: ProducerWithProducerTourData
  cartBatchs?: CartBatch[]
  catalog?: CatalogExtended
  cart: Cart
}

const ClientCartBatchs = ({ producer, cartBatchs, catalog, cart }: ClientCartBatchsProps) => {
  const producerTour = producer.producerTourData

  const canEditCartBatch = () => {
    if (!producerTour.orderEndDate) {
      return true
    }

    const orderEndDate = new Date(producerTour.orderEndDate)
    return orderEndDate > new Date()
  }

  return (
    <BoxWrapper>
      <ProducerBox>
        <LeftBox>
          <StyledProducerImage
            source={{
              uri: DirectusUtil.getProducerImageUrlFromId(producer as any as Producer),
            }}
            resizeMode="cover"
          />
        </LeftBox>
        <RightBox>
          <Typography.Body bold colorName="text-dark-3">
            {producer.label}
          </Typography.Body>
          {producer?.account && !AccountUtil.isProfileSubjectToVAT(producer.account) && (
            <>
              <Spacer size={0.1} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall colorName="color-danger">
                Ce producteur n'est pas assujetti à la TVA, elle n'est pas déductible sur ses
                produits.
              </Typography.BodySmall>
            </>
          )}

          {producerTour.minOrderValueHT ? (
            <>
              <Spacer size={0.1} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall colorName="color-danger">
                Minimum de commande de {FormatUtils.formatPrice(producerTour.minOrderValueHT)}
              </Typography.BodySmall>
            </>
          ) : null}

          {producerTour.orderEndDate && (
            <>
              <Spacer size={0.1} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall colorName="color-warning">
                {`${
                  cart.targetCart ? 'Modification' : 'Commande'
                } possible jusqu'au ${FormatUtils.formatDate(
                  producerTour.orderEndDate,
                  'DateShort',
                )} à ${FormatUtils.formatDate(producerTour.orderEndDate, 'Time')}`}
              </Typography.BodySmall>
            </>
          )}
        </RightBox>
      </ProducerBox>
      {(cartBatchs || []).map((cartBatch) => {
        const cartBatchComputed = cartBatch
        if (catalog) {
          const foundBatch = catalog.batchs.find((b) => b.id === cartBatch.batch?.id)
          if (foundBatch) {
            cartBatchComputed.batch = foundBatch
          }
        }

        return (
          <CartBatchCard
            key={cartBatch.id}
            cartBatch={cartBatch}
            producerCartBatchs={cartBatchs}
            catalog={catalog}
            cart={cart}
            editable={canEditCartBatch()}
            producerTour={producerTour}
          />
        )
      })}
    </BoxWrapper>
  )
}

export default ClientCartBatchs
