import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  max-width: 450px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.2}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.2}px;
  flex-direction: row;
`

const StyledLeftBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 2}px;
  justify-content: center;
`

const StyledMiddleBox = styled.View`
  flex: 4;
`

const StyledRightBox = styled.View`
  flex: 2.5;
  text-align: right;
  flex-direction: row;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
  justify-items: flex-end;
`

export { StyledCardWrapper, StyledCardContent, StyledLeftBox, StyledMiddleBox, StyledRightBox }
