import styled, { css } from 'styled-components/native'

const StyledInputWrapper = styled.View<{
  borderColor: string
  multiline?: boolean
  backgroundColor: string
  small?: boolean
  extraMargin?: boolean
}>`
  position: relative;
  height: ${({ theme, small }) => theme.inputHeight / (small ? 2 : 1.1)}px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors['color-input']};
  border-width: 1px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border-color: ${({ borderColor }) => borderColor};
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${({ multiline }) =>
    multiline &&
    css`
      height: ${({ theme }) => theme.inputHeight * 2}px;
      padding-top: ${({ theme }) => theme.spacingUnit * 1.5}px;
    `}

  ${({ extraMargin }) =>
    extraMargin &&
    css`
      margin-left: ${({ theme }) => theme.spacingUnit * 1}px;
      padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
      width: 98%;
    `}
`

export default StyledInputWrapper
