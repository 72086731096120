import styled from 'styled-components/native'

const SLIDER_HEIGHT = 34
const StyledSliderMenuWrapper = styled.View`
  // margin-bottom: ${({ theme }) => theme.spacingUnit * 2}px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors['background-3']};
  padding: ${({ theme }) => theme.spacingUnit * 0.7}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1}px;

  border-radius: 8px;
`

const StyledElement = styled.TouchableOpacity`
  flex: 1;
  height: ${SLIDER_HEIGHT}px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
`

const StyledElementCardWrapper = styled.TouchableOpacity`
  width: 100%;
  height: ${SLIDER_HEIGHT}px;
`

const StyledElementCardContentWrapper = styled.TouchableOpacity`
  width: 100%;
  height: ${SLIDER_HEIGHT}px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
`

export {
  StyledSliderMenuWrapper,
  StyledElement,
  StyledElementCardWrapper,
  StyledElementCardContentWrapper,
}
