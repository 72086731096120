import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { SectionTitle, Spacer } from '../../../components'
import { Group, GroupRequest, Pagination } from '../../../domain'
import GroupCarrierService from '../../../services/carrier/group'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import Loader from '../../../components/Loader'
import CardListPaginated from '../../../components/CardListPaginated'
import CardList from '../../../components/CardList'
import GroupRequestCard from '../../../modules/GroupRequest/GroupRequestCard'
import { GroupEnums } from '../../../../enums/group'
import { queryClient } from '../../../utilities/queryClient'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierGroupRequestsScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [pageSizeProducerGroups, setPageSizeProducerGroups] = useState(5)
  const [pageNumberProducerGroups, setPageNumberProducerGroups] = useState(1)

  const {
    data: groupRequestData,
    refetch: refetchGroupRequests,
    isLoading: isGroupRequestsLoading,
  } = useQuery<
    {
      data: { groupRequests: GroupRequest[]; groupRequestsPending: GroupRequest[] }
      pagination: Pagination
    },
    Error
  >(
    ['sf_group_requests'],
    () =>
      GroupCarrierService.getOneGroupRequests(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        pageSizeProducerGroups,
        pageNumberProducerGroups,
      ),
    {
      keepPreviousData: true,
    },
  )

  if (isGroupRequestsLoading) {
    return <Loader isLoading pageLoading />
  }

  const onChangePage = async (pageNumber: number) => {
    await setPageNumberProducerGroups(pageNumber)
    refetchGroupRequests()
  }

  const onChangeStatus = async (
    groupRequestId: string,
    status: GroupEnums.GroupRequestStatusEnum,
  ) => {
    const updatedRequest = {
      status: status,
    }

    const updateResponse = await GroupCarrierService.updateGroupRequest(
      getAccessInfos().carrierGroupId,
      groupRequestId,
      updatedRequest,
    )
    if (updateResponse.id) {
      queryClient.invalidateQueries(['sf_group', getAccessInfos().carrierGroupId])
      queryClient.invalidateQueries(['sf_group_producer'])
      await refetchGroupRequests().then(() => {
        return true
      })
    }
    return false
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <SectionTitle title={`Demandes de nouveaux producteurs`} />
        <CardList
          data={groupRequestData?.data.groupRequestsPending}
          element={function (groupRequest: any) {
            return (
              <GroupRequestCard
                key={groupRequest.id}
                groupRequest={groupRequest}
                onChangeStatus={onChangeStatus}
              />
            )
          }}
          isLoading={isGroupRequestsLoading}
          emptyMessage="Aucune demande en attente"
        />
        <SectionTitle title={`Demandes approuvées / rejetées`} />
        <CardListPaginated
          data={groupRequestData?.data.groupRequests}
          pagination={groupRequestData?.pagination}
          element={function (groupRequest: GroupRequest) {
            return (
              <GroupRequestCard
                key={groupRequest.id}
                groupRequest={groupRequest}
                onChangeStatus={onChangeStatus}
              />
            )
          }}
          isLoading={isGroupRequestsLoading}
          emptyMessage="Aucune demande antérieur"
          onChangePage={onChangePage}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierGroupRequestsScreen
