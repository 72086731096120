import { CarrierTourOrganizationProps } from './CarrierTourOrganization.model'
import { Button, SectionTitle, Spacer, Typography } from '../../../../../components'
import CardSettings from '../../../../../components/CardSettings'
import TourCarrierService from '../../../../../services/carrier/tour'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import { CarrierStackParamList } from '../../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { MailEnums, RightKeyEnum, TourEnums } from '../../../../../../enums'
import TourStepper from '../../../../../modules/Tour/TourStepper'
import TutorialStepCard from '../../../../../components/TutorialStepCard'
import TutorialStepCards from '../../../../../components/TutorialStepCards'
import { TourTutorialSteps } from '../../../../../../enums/tutorialStep'
import { View } from 'react-native'
import { queryClient } from '../../../../../utilities/queryClient'

const CarrierTourOrganization = ({
  tour,
  stepsData,
  tourCarrierBatchs,
  onCardClick,
}: CarrierTourOrganizationProps) => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const { carrierHasRight } = useAuthenticationContext()

  if (!tour) return null

  const onNavigateSendMail = () => {
    navigation.navigate('CarrierTourSendMail', { id: tour.id, type: MailEnums.PROPOSITION_CLIENTS })
  }

  const onNavigateTourDeliveryExpenses = () => {
    navigation.navigate('CarrierTourDeliveryExpenses', { id: tour.id })
  }

  const onDeleteTour = async () => {
    const deletedTour = await TourCarrierService.deleteTour(tour.id)
    if (deletedTour && deletedTour === tour.id) {
      navigation.navigate('CarrierTours', {})
    }
  }

  const onNavigateLogisticsFees = () => {
    navigation.navigate('CarrierTourAddProducers', { id: tour.id })
  }

  const onNavigateAddClients = () => {
    navigation.navigate('CarrierTourAddClients', { id: tour.id })
  }

  const onSkipTourOnboarding = async () => {
    const updatedTour = {
      status: TourEnums.TourStatusEnum.ONGOING,
      carrierFees: 0,
    }

    const updatedTourTemp = await TourCarrierService.update(tour.id, updatedTour)
    if (updatedTourTemp && updatedTourTemp.id) {
      TourCarrierService.getOne.invalidate(tour.id)
    }
  }

  return (
    <>
      {carrierHasRight(RightKeyEnum.C_TOUR_CONTENT) && (
        <>
          {tour.status !== TourEnums.TourStatusEnum.ONBOARDING && (
            <>
              <SectionTitle title={`Organisation`} />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            </>
          )}
          {tour &&
          tour.status === TourEnums.TourStatusEnum.ONBOARDING &&
          stepsData &&
          stepsData.length > 0 ? (
            <>
              <SectionTitle title={`Paramétrez votre tournée`} />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <TutorialStepCards
                tutorialStepsData={stepsData}
                tutorialSteps={TourTutorialSteps}
                navigationType={'TOUR'}
                onCardClick={onCardClick}
              />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <View style={{ width: '100%', alignItems: 'center' }}>
                <Button.Text
                  label={`Passer le tutoriel`}
                  colorName="color-grey"
                  onPress={() => {
                    onSkipTourOnboarding()
                  }}
                />
              </View>
            </>
          ) : (
            <TourStepper tour={tour} tourBatchs={tourCarrierBatchs} onCardClick={onCardClick} />
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <SectionTitle title={`Actions`} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <CardSettings
            title="Envoyer la mercuriale"
            description={'Envoie automatique par mail de la mercuriale'}
            children={
              <Button
                fullWidth={true}
                colorName="color-grey"
                label={'Destinataires'}
                onPress={() => onNavigateSendMail()}
              />
            }
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <CardSettings
            title={
              tour.carrierExpenses
                ? 'Estimer à nouveau vos frais de livraison'
                : 'Estimer vos frais de livraison'
            }
            description={
              'Estimez vos frais de livraison en fonction des paramètres de votre tournée'
            }
            children={
              <Button
                fullWidth={true}
                colorName="color-grey"
                label={tour.carrierExpenses ? 'Estimer à nouveau' : 'Estimer'}
                onPress={() => onNavigateTourDeliveryExpenses()}
              />
            }
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <CardSettings
            title="Économisez en vous mutualisant"
            description={
              'Calculez votre commission logistique et proposez à vos collègues producteurs de rejoindre vos tournées de livraison'
            }
            children={
              <Button
                fullWidth={true}
                colorName="color-grey"
                label={'Économiser'}
                onPress={() => onNavigateLogisticsFees()}
              />
            }
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <CardSettings
            title="Invitez vos clients"
            description={
              'Invitez vos clients afin qu’ils puissent passer commande en ligne directement pour votre prochaine tournée de livraison'
            }
            children={
              <Button
                fullWidth={true}
                colorName="color-grey"
                label={'Inviter'}
                onPress={() => onNavigateAddClients()}
              />
            }
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <CardSettings
            title="Supprimer la tournée"
            description={`Une tournée peut être supprimée seulement si aucune commande n’a été réalisée et qu'il n'y a plus aucun producteurs/clients participants`}
            children={
              <Button
                fullWidth={true}
                colorName="color-grey"
                label={'Supprimer'}
                onPress={() => onDeleteTour()}
                disabled={
                  (tour.producerTour && tour.producerTour.length > 0) ||
                  (tour.carts && tour.carts.length > 0)
                }
                hasDoubleValidation
                confirmationLabel="Êtes-vous sûr de vouloir supprimer cette tournée ?"
              />
            }
          />
        </>
      )}
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
    </>
  )
}

export default CarrierTourOrganization
