import React from 'react'
import { useTheme } from 'styled-components/native'
import { Card, Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import i18n from '../../../i18n'
import FormatUtils from '../../../utilities/utils/format'
import { OrderCardProps } from './OrderCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
  StyledTimeBox,
  StyledTimeBoxLeft,
  StyledTimeBoxRight,
} from './OrderCard.styles'

const OrderCard = ({ order, onClick }: OrderCardProps) => {
  const theme = useTheme()

  const OnClickOrder = () => {
    onClick(order)
  }

  if (!order) return null

  return (
    <StyledCardWrapper onPress={OnClickOrder} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.CardTitle>#{FormatUtils.formatId(order.id)}</Typography.CardTitle>
            <Typography.BodySmall colorName="text-dark-1" bold>
              commande du{' '}
              {FormatUtils.capitalize(
                FormatUtils.formatDate(order.createdDate, 'FullDate+StartTime'),
              )}
            </Typography.BodySmall>
            <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName={'text-dark-3'}>
              {order.totalHTBatchs ? FormatUtils.formatPrice(order.totalHTBatchs || 0, 'ht') : 'NA'}
            </Typography.BodySmall>
          </StyledLeftBox>
          <StyledRightBox>
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              size={theme.spacingUnit * 2}
              color={theme.colors['text-dark-3']}
            />
            <Typography.BodySmall colorName="color-primary" bold>
              {FormatUtils.capitalize(
                FormatUtils.formatDate(order.cart?.tour?.start, 'FullDate', order.cart?.tour?.end),
              )}
            </Typography.BodySmall>
            <StyledTimeBox>
              <StyledTimeBoxLeft>
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name="truck-delivery"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['color-primary']}
                />
              </StyledTimeBoxLeft>
              <StyledTimeBoxRight>
                <Typography.BodySmall bold>
                  {FormatUtils.formatDate(
                    order.cart?.tour?.start,
                    'StartTime+EndTime',
                    order.cart?.tour?.end,
                  )}
                </Typography.BodySmall>
              </StyledTimeBoxRight>
            </StyledTimeBox>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default OrderCard
