import React from 'react'
import Spacer from '../../Spacer'
import Typography from '../../Typography'
import { TextInputIndicationProps } from './TextInputIndication.model'

const TextInputIndication = ({ content }: TextInputIndicationProps) => {
  if (!content) {
    return null
  }

  return (
    <>
      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      <Typography.InputLabel>{content}</Typography.InputLabel>
    </>
  )
}

export default TextInputIndication
