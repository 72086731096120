import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { Button, PageTitle, SectionTitle, Spacer } from '../../../components'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { useQuery } from '@tanstack/react-query'
import { StackNavigationProp } from '@react-navigation/stack'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import CarrierDeliveryFeesTopBox from './components/CarrierDeliveryFeesTopBox'
import CarrierFeesCard from './components/CarrierFeesCard'
import CarrierFeesCarrierService from '../../../services/carrier/carrierFees'
import { CarrierFeesRecord, CarrierFeesStatsResponse } from '../../../domain/CarrierFees'
import GroupCarrierService from '../../../services/carrier/group'
import { Group, Pagination, Tour } from '../../../domain'
import TourCarrierService from '../../../services/carrier/tour'
import GeneralUtils from '../../../utilities/utils/general'
import ActionCard from '../../../modules/Common/ActionCard'
import { TourEnums } from '../../../../enums'
import FormatUtils from '../../../utilities/utils/format'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierDeliveryFeesScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const {
    data: carrierFees,
    refetch: refetchCarrierFees,
    isInitialLoading: carrierFeesLoading,
    isLoading: isLoadingCarrierFees,
  } = useQuery<CarrierFeesStatsResponse, Error>(
    ['sf_billing_carrier_fees', getAccessInfos().carrierGroupId],
    () => CarrierFeesCarrierService.getAllGroupStats(getAccessInfos().carrierGroupId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: groupData,
    refetch,
    isInitialLoading: feedLoading,
  } = useQuery<{ group: Group; stepsData: TutorialStepData[] }, Error>(
    ['sf_group', getAccessInfos().carrierGroupId],
    () =>
      GroupCarrierService.getOneGroup(getAccessInfos().carrierGroupId, getAccessInfos().carrierId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: oldTours,
    refetch: refetchOldTours,
    isInitialLoading: oldToursLoading,
  } = useQuery<{ data: Tour[]; pagination: Pagination }, Error>(
    ['sf_tours_to_conclude'],
    () =>
      TourCarrierService.getAllTours(getAccessInfos().carrierId, false, true, 50, 1, false, true),
    {
      keepPreviousData: true,
    },
  )

  const onClickActionToursToConclude = (tours: Tour[]) => {
    if (!tours) return
    navigation.navigate('CarrierConcludeTours', {})
  }

  const getUrgentAction = (): { title: string; description: string; onClick: () => void } => {
    const nbTour = oldTours?.data.length

    if (oldTours && oldTours?.data.length > 0) {
      const action: { title: string; description: string; onClick: () => void } = {
        title: 'Tournées non finalisées',
        description:
          nbTour && nbTour > 1
            ? `${nbTour} tournées n'ont pas été finalisées, les frais ne sont pas enregistrés`
            : `${nbTour} tournée n'a pas été finalisée, les frais ne sont pas enregistrés`,
        onClick: () => onClickActionToursToConclude(oldTours?.data),
      }
      return action
    }

    return { title: '', description: '', onClick: () => {} }
  }

  const urgentAction = oldTours && oldTours?.data.length > 0 ? getUrgentAction() : undefined

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title={`Frais de livraison`} />
        <CarrierDeliveryFeesTopBox stats={carrierFees?.statsGroup} group={groupData?.group} />
        {urgentAction && (
          <>
            <SectionTitle title={'Action urgente'} />
            <ActionCard
              title={getUrgentAction().title}
              description={getUrgentAction().description}
              onClick={getUrgentAction().onClick}
              badgeLabel="Finaliser"
            />
          </>
        )}
        <SectionTitle title={`Solde des producteurs-livreurs`} />
        {carrierFees?.statsCarriers.map((statsCarrier) => (
          <CarrierFeesCard statsCarrier={statsCarrier} />
        ))}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierDeliveryFeesScreen
