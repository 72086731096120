import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import ButtonSeeMore from '../ButtonSeeMore'
import Loader from '../Loader'
import Spacer from '../Spacer'
import Typography from '../Typography'
import { CardListProps } from './CardList.model'
import { StyledButtonSeeMore, StyledList, Styledloading } from './CardList.styles'
import { set } from 'date-fns'
import FormatUtils from '../../utilities/utils/format'
import { View } from 'react-native'

function CardList({ data, element, listLength, isLoading, emptyMessage }: CardListProps) {
  const theme = useTheme()
  const [seeMore, setSeeMore] = useState<boolean>(false)
  const [visibleResults, setVisibleResults] = useState<number>(2)

  const list = () => {
    if (!data || data.length === 0) return []
    if (seeMore) {
      return data
    }
    const length = data.length
    const listLimit = listLength || 2
    return data.slice(0, length < listLimit ? length : listLimit)
  }

  if (isLoading && (!data || data.length === 0)) {
    return (
      <StyledList>
        <Loader isLoading withText />
      </StyledList>
    )
  }

  if (!isLoading && (!data || data.length < 1)) {
    return (
      <StyledList>
        <Styledloading>
          <Typography.BodySmall colorName="text-dark-3">{emptyMessage}</Typography.BodySmall>
        </Styledloading>
      </StyledList>
    )
  }

  return (
    <StyledList>
      {list().map(element)}
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      <StyledButtonSeeMore>
        <View style={{ flex: 0.22 }} />
        <ButtonSeeMore
          seeMore={seeMore}
          onClick={() => setSeeMore(!seeMore)}
          hide={(data || []).length <= 2}
        />
        <View style={{ alignItems: 'flex-end' }}>
          <Typography.BodySmall colorName="text-dark-3">
            {`${list().length} sur ${data?.length} ${FormatUtils.pluralizeLabel('résultat')}`}
          </Typography.BodySmall>
        </View>
      </StyledButtonSeeMore>
    </StyledList>
  )
}

export default CardList
