import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Col, Dropdown, PageTitle, Row, Spacer, Typography } from '../../../components'
import DatePicker from '../../../components/DatePicker'
import TextInput from '../../../components/inputs/TextInput'
import Loader from '../../../components/Loader'
import {
  AddTour,
  City,
  PriceReporting,
  Tour,
  UpdatePriceReporting,
  UpdateTour,
} from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../services/carrier/tour'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import {
  ToursContentWrapper,
  StyledButtonWrapper,
  StyledModeSwitchWrapper,
} from './CarrierUpdatePriceReporting.styles'
import { Switch } from 'react-native-gesture-handler'
import PriceReportingCarrierService from '../../../services/carrier/priceReporting'
import CityProducerService from '../../../services/producer/city'
import CitiesForm from '../../../modules/Common/CitiesForm'

const CarrierUpdatePriceReportingScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdatePriceReporting'>>()
  const { id } = route?.params ?? { id: '' }
  const [isLoading, setIsLoading] = useState(false)

  const theme = useTheme()

  const {
    data: oldPriceReporting,
    refetch: refetchPriceReporting,
    isInitialLoading: priceReportingLoading,
  } = useQuery<PriceReporting, Error>(
    ['sf_price_reporting', id],
    () => PriceReportingCarrierService.getOne(id),
    {
      keepPreviousData: true,
      enabled: id !== '',
    },
  )

  if (priceReportingLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!oldPriceReporting) return null

  const cities: City[] = []
  oldPriceReporting.priceReportingCities?.map((prc) => {
    cities.push(prc.city)
  })

  const [selectedCities, setSelectedCities] = useState<City[]>(cities)

  const [updatedPriceReporting, setUpdatedPriceReporting] = useState<UpdatePriceReporting>({
    label: oldPriceReporting.label,
    validityStart: new Date(oldPriceReporting.validityStart),
    validityEnd: new Date(oldPriceReporting.validityEnd),
    cityIds: [],
  })

  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in updatedPriceReporting)) return null

    const tourTemp = Object.assign({}, updatedPriceReporting)

    tourTemp[param as keyof typeof updatedPriceReporting] = value
    setUpdatedPriceReporting(tourTemp)
  }

  const onChangeDateStart = (value: any) => {
    setErrorMessage('')

    const tourTemp = Object.assign({}, updatedPriceReporting)
    tourTemp.validityStart = value

    setUpdatedPriceReporting(tourTemp)
  }

  const onChangeDateEnd = (value: any) => {
    setErrorMessage('')

    const tourTemp = Object.assign({}, updatedPriceReporting)
    tourTemp.validityEnd = value

    setUpdatedPriceReporting(tourTemp)
  }

  const onUpdatePriceReporting = async () => {
    setErrorMessage('')
    setIsLoading(true)

    const cityIds: string[] = []
    selectedCities.map((city) => {
      cityIds.push(city.id)
    })

    const priceReporting = {
      label: updatedPriceReporting.label,
      validityStart: updatedPriceReporting.validityStart,
      validityEnd: updatedPriceReporting.validityEnd,
      cityIds: cityIds,
    }

    const updatedPriceReportingTemp = await PriceReportingCarrierService.update(
      oldPriceReporting.id,
      priceReporting,
    )
    if (updatedPriceReportingTemp && updatedPriceReportingTemp.id) {
      queryClient.invalidateQueries({ queryKey: ['sf_price_reporting', id] })
      queryClient.invalidateQueries({ queryKey: ['sf_price_reportings'] })

      navigation.navigate('CarrierPriceReporting', { id: oldPriceReporting.id })
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour du relevé')
    }
    setIsLoading(false)
  }

  const onDeletePriceReporting = async () => {
    setErrorMessage('')
    setIsLoading(true)

    const deletedPriceReportingTemp = await PriceReportingCarrierService.remove(
      oldPriceReporting.id,
    )
    if (deletedPriceReportingTemp && deletedPriceReportingTemp.id) {
      queryClient.invalidateQueries({ queryKey: ['sf_price_reportings'] })

      navigation.navigate('CarrierPriceReportings', {})
    } else {
      setErrorMessage('Un problème est survenu lors de la suppression du relevé')
    }
    setIsLoading(false)
  }

  const getIdFormatted = () => {
    if (oldPriceReporting && oldPriceReporting.id && oldPriceReporting.id.length > 5) {
      return oldPriceReporting.id.slice(-5)
    }
    return 'NA'
  }

  const onChangeSelectedCities = (cities: City[]) => {
    setSelectedCities([...cities])
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title={`Mettre à jour la tournée #${getIdFormatted()}`} />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <TextInput
            value={updatedPriceReporting.label}
            label="Libellé"
            field="text"
            onChangeText={(text) => setValue(text, 'label')}
            autoFocus
          />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <CitiesForm selectedCities={selectedCities} onChangeCities={onChangeSelectedCities} />

          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

          <Row>
            <Col xs={12} sm={12} md={6}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="startdate"
                label="Date début de validité"
                mode="date"
                onChange={onChangeDateStart}
                value={updatedPriceReporting.validityStart}
              />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="enddate"
                label="Fin de validité"
                mode="date"
                onChange={onChangeDateEnd}
                value={updatedPriceReporting.validityEnd}
              />
            </Col>
          </Row>

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Button
              label={FormatUtils.capitalize(i18n.t('update'))}
              onPress={() => onUpdatePriceReporting()}
              loading={isLoading}
              hasDoubleValidation
              confirmationLabel="Êtes-vous sûr de vouloir mettre à jour ce relevé ?"
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
            <Button
              label={'Supprimer le relevé'}
              onPress={() => onDeletePriceReporting()}
              loading={isLoading}
              colorName="color-danger"
              hasDoubleValidation
              confirmationLabel="Êtes-vous sûr de vouloir supprimer ce relevé ?"
            />
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierUpdatePriceReportingScreen
