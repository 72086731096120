import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Dimensions } from 'react-native'
import Header from '../../components/Header'
import PriceReportingCarrierNavigationStackParamList from './PriceReportingCarrierNavigation.model'
import CarrierPriceReportingsScreen from '../../../screens/Carrier/CarrierPriceReportings'
import CarrierAddPriceReportingScreen from '../../../screens/Carrier/CarrierAddPriceReporting'
import CarrierPriceReportingScreen from '../../../screens/Carrier/CarrierPriceReporting'
import CarrierUpdatePriceReportingScreen from '../../../screens/Carrier/CarrierUpdatePriceReporting'
import CarrierDuplicatePriceReportingScreen from '../../../screens/Carrier/CarrierDuplicatePriceReporting'
import CarrierPriceReportingAddProductType from '../../../screens/Carrier/CarrierPriceReportingAddProductType'
import HeaderHome from '../../components/HeaderHome'

const Stack = createStackNavigator<PriceReportingCarrierNavigationStackParamList>()

const PriceReportingCarrierNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="CarrierPriceReportings"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="CarrierPriceReportings"
        component={CarrierPriceReportingsScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Reporting Prix',
        }}
      />
      <Stack.Screen
        name="CarrierPriceReporting"
        component={CarrierPriceReportingScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => (
            <Header title="" goBackFallback="CarrierPriceReportings" leftButton={<></>} />
          ),
          title: 'Ajouter un relevé de prix',
        }}
      />

      <Stack.Screen
        name="CarrierAddPriceReporting"
        component={CarrierAddPriceReportingScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierPriceReportings" />,
          title: 'Ajouter un relevé de prix',
        }}
      />
      <Stack.Screen
        name="CarrierDuplicatePriceReporting"
        component={CarrierDuplicatePriceReportingScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierPriceReportings" />,
          title: 'Ajouter un relevé de prix',
        }}
      />
      <Stack.Screen
        name="CarrierUpdatePriceReporting"
        component={CarrierUpdatePriceReportingScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierPriceReportings" />,
          title: 'Ajouter un relevé de prix',
        }}
      />
      <Stack.Screen
        name="CarrierPriceReportingAddProductType"
        component={CarrierPriceReportingAddProductType}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="UserMenu" />,
        }}
      />
    </Stack.Navigator>
  )
}

export default PriceReportingCarrierNavigation
