import { TutorialStepData } from '../../../../enums/tutorialStep'
import { Client, ClientGroup, Pagination, Producer } from '../../../domain'
import ApiService from '../../api.service'

export const getOneProducer = async (
  idProducer: string,
): Promise<{ producer: Producer; stepsData: TutorialStepData[] }> => {
  const response = await ApiService.get(`/producer/${idProducer}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getAllClients = async (
  idProducer: string,
  hasOrders?: boolean,
  limit?: number,
  page?: number,
): Promise<{ data: Client[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/producer/${idProducer}/clients`, {
    hasOrders: hasOrders ? true : undefined,
    limit: limit,
    page: page,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getBillingPageURL = async (idProducer: string): Promise<string> => {
  const response = await ApiService.get(`/producer/${idProducer}/billing`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const update = async (idProducer: string, producer: any): Promise<Producer> => {
  const response = await ApiService.patch(`/producer/${idProducer}`, producer).catch((error) => {
    throw error
  })
  return response
}

const ProducerProducerService = {
  getOne: getOneProducer,
  getAllClients,
  getBillingPageURL,
  update,
}

export default ProducerProducerService
