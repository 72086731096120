import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, SectionTitle, Spacer, Typography } from '../../../components'
import { Batch, CartBatch, Producer, Tour } from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { StyledModeSwitchWrapper, ToursContentWrapper } from './CarrierTourCrates.styles'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import Loader from '../../../components/Loader'
import CarrierTourCratesTopBox from './components/CarrierTourCratesTopBox'
import { Switch, View } from 'react-native'
import BatchCartCard from '../../../modules/Batch/BatchCartCard'
import BatchCartBatchsCard from '../../../modules/Batch/BatchCartBatchsCard'
import { StackNavigationProp } from '@react-navigation/stack'
import { UserEnums } from '../../../../enums'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierTourCratesScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourCrates'>>()
  const [mode, setMode] = useState<number>(1)
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const { id } = route?.params ?? { id: '' }

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = TourCarrierService.getOne.query(id)

  const {
    data: tourBatchs,
    refetch: refetchTourBatchs,
    isInitialLoading: tourBatchsLoading,
  } = useQuery<Tour, Error>(['sf_tour_batchs', id], () => TourCarrierService.getOneTourBatchs(id), {
    keepPreviousData: true,
  })

  const {
    data: tourCartBatchs,
    refetch: refetchTourCartBatchs,
    isInitialLoading: tourCartBatchsLoading,
  } = useQuery<CartBatch[], Error>(
    ['sf_tour_cart_batchs', id],
    () => TourCarrierService.getOneTourCartBatchs(id),
    {
      keepPreviousData: true,
    },
  )

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  const getTourProducersWithBatchs = (): Producer[] => {
    if (!tour) return []
    if (!tourBatchs || !tourBatchs?.batchs || tourBatchs?.batchs?.length < 1) return []

    let producersInBatchs: Producer[] = []
    tourBatchs?.batchs.map((batch) => {
      if (!producersInBatchs.find((producer) => producer.id === batch?.product?.producer?.id)) {
        if (batch?.product?.producer) {
          producersInBatchs.push(batch?.product?.producer)
        }
      }
    })
    return producersInBatchs
  }

  const getEmptyPropositionCount = () => {
    if (!tour) return 0

    const producersWithBatchs = getTourProducersWithBatchs().length || 0
    return (tour?.producerTour?.length || 0) - producersWithBatchs
  }

  if (tourLoading || tourBatchsLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour) return null

  const producersTour = tour.producerTour || []

  const stops = tour.stops

  const renderCartBatchFromBatch = (batch: Batch) => {
    if (!tourCartBatchs) return <></>
    const cartBatchs = tourCartBatchs.filter((cb) => batch.id === cb.batch?.id)

    if (cartBatchs && cartBatchs.length > 0) {
      return (
        <BatchCartBatchsCard
          key={batch.id}
          cartBatchs={cartBatchs}
          tour={tour}
          batch={batch}
          onClick={() => null}
          userRole={UserEnums.RoleEnums.CARRIER}
        />
      )
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Colis Tournée #${getTourIdFormatted()}`}
            buttonRight={
              <>
                <Typography.BodySmall>
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                  {'\n'}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </>
            }
          />
          <CarrierTourCratesTopBox
            tour={tour}
            tourBatchs={tourBatchs}
            emptyPropositions={getEmptyPropositionCount()}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledModeSwitchWrapper>
            <Typography.Body>Mode réception</Typography.Body>
            <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
            <Switch
              trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
              onValueChange={() => setMode(mode === 1 ? 2 : 1)}
              value={mode === 2}
            />
            <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
            <Typography.Body>Mode préparation</Typography.Body>
          </StyledModeSwitchWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          {mode === 1 &&
            producersTour.map((producerTour) => {
              const producer = producerTour.producer
              const producerBatchs =
                tourBatchs?.batchs?.filter(
                  (b) =>
                    b.product?.producer?.id === producer.id &&
                    tourCartBatchs &&
                    tourCartBatchs.find(
                      (cb) =>
                        cb.batch?.product?.producer?.id === producerTour.producer.id &&
                        b.id === cb.batch.id,
                    ),
                ) || []

              return producerBatchs.length > 0 ? (
                <View key={producer.id}>
                  <SectionTitle
                    title={`${producer.label} (${producerBatchs.length | 0} lots)`}
                    buttonRight={
                      producerBatchs.length > 0 &&
                      true && (
                        <View>
                          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

                          <Button
                            small
                            lowPadding
                            colorName={'color-grey'}
                            label={'Étiquettes'}
                            onPress={() =>
                              navigation.navigate('CarrierTourPrintSticker', {
                                id: tour?.id || '',
                                idProducer: producer.id,
                              })
                            }
                          />
                        </View>
                      )
                    }
                  />

                  {producerBatchs.map((batch) => renderCartBatchFromBatch(batch))}
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                </View>
              ) : null
            })}

          {mode === 2 &&
            (stops || [])
              .filter((stop) => (stop.nbCrates || 0) > 0)
              .map((stop, index) => {
                const client = stop.client
                const clientCartBatchs =
                  tourCartBatchs?.filter((cb) => cb?.cart?.client?.id === client.id) || []
                let totalCrates = 0
                const mappedCartBatchs: CartBatch[] = []
                clientCartBatchs.map((cartBatch) => {
                  totalCrates += cartBatch.packingQuantity || 0
                  const otherCartBatchs = clientCartBatchs.filter(
                    (cbf) => cbf.batch?.id === cartBatch.batch?.id && cbf.id !== cartBatch.id,
                  )
                  if (otherCartBatchs.length > 0) {
                    const newMergedCartBatch: CartBatch = {
                      ...cartBatch,
                    }
                    otherCartBatchs.map((otherCartBatch) => {
                      newMergedCartBatch.packingQuantity =
                        (newMergedCartBatch.packingQuantity || 0) +
                        (otherCartBatch.packingQuantity || 0)
                      newMergedCartBatch.totalQuantity =
                        (newMergedCartBatch.totalQuantity || 0) +
                        (otherCartBatch.totalQuantity || 0)
                      newMergedCartBatch.identifications = (
                        newMergedCartBatch.identifications || []
                      ).concat(otherCartBatch.identifications || [])
                      const cartBatchIndex = clientCartBatchs.findIndex(
                        (cb2) => cb2.id === otherCartBatch.id,
                      )
                      if (cartBatchIndex > -1) {
                        clientCartBatchs.splice(cartBatchIndex, 1)
                      }
                    })
                    mappedCartBatchs.push(newMergedCartBatch)
                  } else {
                    mappedCartBatchs.push(cartBatch)
                  }
                  // Todo search for other cartBatchs from same batch, merge them and add to mappedCartBatchs
                })
                return (
                  <View key={stop.id}>
                    <Typography.SectionTitle>
                      Arrêt {index + 1} - {client.label} ({totalCrates || 0} colis)
                    </Typography.SectionTitle>

                    {mappedCartBatchs.map((cartBatch) => (
                      <BatchCartCard
                        key={cartBatch.id}
                        cartBatch={cartBatch}
                        onClick={() => null}
                        userRole={UserEnums.RoleEnums.CARRIER}
                        withProducer
                      />
                    ))}
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  </View>
                )
              })}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourCratesScreen
