import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import i18n from '../../../i18n'
import FormatUtils from '../../../utilities/utils/format'
import { PriceReportingCardProps } from './PriceReportingCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
} from './PriceReportingCard.styles'
import { CarrierGroup, User, UserCarrier } from '../../../domain'
import { LABELS_TYPES } from '../../../../enums/label'
import GeneralUtils from '../../../utilities/utils/general'

const PriceReportingCard = ({ priceReporting, onClick }: PriceReportingCardProps) => {
  const theme = useTheme()

  const OnClickPriceReporting = () => {
    if (onClick) {
      onClick(priceReporting)
    }
  }

  const isActive = () => {
    const isStartBefore = GeneralUtils.isDateBefore(
      new Date(priceReporting.validityStart || ''),
      new Date(),
    )
    const isEndAfter = GeneralUtils.isDateBefore(
      new Date(),
      new Date(priceReporting.validityEnd || ''),
    )

    return isEndAfter
  }

  return (
    <StyledCardWrapper onPress={OnClickPriceReporting} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent style={{ opacity: isActive() ? 1 : 0.7 }}>
          <StyledLeftBox>
            <Typography.CardTitle>{priceReporting.label}</Typography.CardTitle>
            <Typography.BodySmall colorName="color-primary" bold>
              {priceReporting.priceReportingCities?.map(
                (c, index) => `${index === 0 ? '' : ', '}${c.city.label}`,
              )}
            </Typography.BodySmall>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3">
              du {FormatUtils.formatDate(priceReporting.validityStart, 'FullDate')} au{' '}
              {FormatUtils.formatDate(priceReporting.validityEnd, 'FullDate')}
            </Typography.BodySmall>
          </StyledLeftBox>
          <StyledRightBox>
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              size={theme.spacingUnit * 2}
              color={theme.colors['text-dark-3']}
            />
            <View style={{ alignItems: 'flex-end' }}>
              <Typography.BodySmall colorName="text-dark-3" style={{ alignSelf: 'flex-end' }}>
                {priceReporting.priceStatements?.length} relevé(s)
              </Typography.BodySmall>
            </View>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default PriceReportingCard
