import React from 'react'
import { useTheme } from 'styled-components/native'
import { CarrierTourBatchsTopBoxProps } from './CarrierTourBatchsTopBox.model'
import { CardParam } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { StyledTopBox } from './CarrierTourBatchsTopBox.styles'

function CarrierTourBatchsTopBox({
  tour,
  tourBatchs,
  emptyPropositions,
}: CarrierTourBatchsTopBoxProps) {
  const theme = useTheme()

  return (
    <>
      <StyledTopBox>
        <CardParam
          label={'Mercuriales'}
          value={`${(tour.producerTour?.length || 0) - (emptyPropositions || 0)} / ${
            tour.producerTour?.length || 0
          }`}
          flex={5}
        />
        <CardParam label={'Lots'} value={tourBatchs?.batchs?.length || 0} flex={5} />
        <CardParam label={'Colis'} value={tour.nbCrates || '-'} flex={5} />
      </StyledTopBox>
    </>
  )
}

export default CarrierTourBatchsTopBox
