import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const ToursContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: 0;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  z-index: 0;
`
export { ToursContentWrapper, StyledButtonWrapper }
