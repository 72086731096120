import { MobileApplicationConfiguration, MobileApplicationConfigurationFake } from '../../domain'

const findOne = async (
  platform: string,
  version: string,
): Promise<MobileApplicationConfiguration> => {
  const response = MobileApplicationConfigurationFake
  return response
}

const QUERY_KEY = 'version_control'

const MobileApplicationConfigurationService = {
  findOne,
  QUERY_KEY,
}

export default MobileApplicationConfigurationService
