import styled, { css } from 'styled-components/native'

const StyledLoaderWrapper = styled.View<{
  noMargin: boolean
  flex?: boolean
  paddingLeft?: boolean
  isDesktop?: boolean
  pageLoading?: boolean
}>`
  margin-top: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-left: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 2}px;
  width: 97%;
  justify-content: center;
  align-items: center;

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `}

  ${({ flex }) =>
    flex &&
    css`
      width: auto;
    `}
`

const StyledImage = styled.Image<{ size: number }>`
  width: ${({ theme, size }) => theme.spacingUnit * size}px;
  height: ${({ theme, size }) => theme.spacingUnit * size}px;
`

export { StyledLoaderWrapper, StyledImage }
