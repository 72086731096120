import styled from 'styled-components/native'

const StyledButtonWrapper = styled.View`
  align-items: center;
  justify-content: center;
`

const CardWrapper = styled.View`
  width: 100%;
`

const CardContent = styled.View`
  min-height: ${({ theme }) => theme.spacingUnit * 5}px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`
const CardContentWrapper = styled.View`
  padding: ${({ theme }) => theme.spacingUnit * 1}px;
  align-items: center;
  flex-direction: row;
`
const CardContentLeft = styled.View`
  padding-left: ${({ theme }) => theme.spacingUnit * 2}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 2}px;
`
const CardContentRight = styled.View`
  align-items: center;
`
export {
  StyledButtonWrapper,
  CardContent,
  CardContentLeft,
  CardContentRight,
  CardWrapper,
  CardContentWrapper,
}
