import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import Typography from '../Typography'
import { SliderMenuProps } from './SliderMenu.model'
import {
  StyledSliderMenuWrapper,
  StyledElement,
  StyledElementCardWrapper,
  StyledElementCardContentWrapper,
} from './SliderMenu.styles'
import TouchableCard from '../TouchableCard'

function SliderMenu({ elements, onSelectElement, defaultSelectedElement }: SliderMenuProps) {
  const theme = useTheme()
  const [selectedElement, setSelectedElement] = useState<string | number>(defaultSelectedElement)

  useEffect(() => {
    setSelectedElement(defaultSelectedElement)
  }, [defaultSelectedElement])

  const onPressElement = (elementValue: string | number) => {
    setSelectedElement(elementValue)
    return onSelectElement(elementValue)
  }
  return (
    <StyledSliderMenuWrapper>
      {elements.map((element) => {
        if (selectedElement === element.value) {
          return (
            <StyledElement onPress={() => onPressElement(element.value)} key={element.value}>
              <StyledElementCardWrapper>
                <TouchableCard backgroundColor={'background-1'}>
                  <StyledElementCardContentWrapper>
                    <Typography.Body colorName="text-dark-1" bold>
                      {element.label}
                    </Typography.Body>
                  </StyledElementCardContentWrapper>
                </TouchableCard>
              </StyledElementCardWrapper>
            </StyledElement>
          )
        }
        return (
          <StyledElement onPress={() => onPressElement(element.value)} key={element.value}>
            <Typography.Body colorName="text-dark-3" bold>
              {element.label}
            </Typography.Body>
          </StyledElement>
        )
      })}
    </StyledSliderMenuWrapper>
  )
}

export default SliderMenu
