export enum ContainerTypeEnum {
  BOX = 1,
  CRATE = 2,
  HAMPER = 3,
  OTHER = 4,
}

export enum ContainerStatusEnum {
  ACTIVE = 1,
  PENDING = 0,
}

export const CONTAINER_TYPES = [
  {
    value: ContainerTypeEnum.BOX,
    label: 'Carton',
  },
  {
    value: ContainerTypeEnum.CRATE,
    label: 'Cagette',
  },
  {
    value: ContainerTypeEnum.HAMPER,
    label: 'Bourriche',
  },
  {
    value: ContainerTypeEnum.OTHER,
    label: 'Autres',
  },
]
