import { useTheme } from 'styled-components/native'
import useAuthenticationContext from '../../../../../../utilities/hook/useAuthenticationContext'
import { InviteClientFormProps } from './InviteClientForm.model'
import { useState } from 'react'
import { AddClientTourInvite, Client } from '../../../../../../domain'
import GeneralUtils from '../../../../../../utilities/utils/general'
import ClientCarrierService from '../../../../../../services/carrier/client'
import { set } from 'date-fns'
import {
  BackToListWrapper,
  LeftCardContentWrapper,
  StyledButtonWrapper,
  StyledCardContentWrapper,
  StyledCardWrapper,
  StyledLinkButtonWrapper,
} from './InviteClientForm.styles'
import {
  Button,
  Card,
  Col,
  Icon,
  Icons,
  Row,
  SectionTitle,
  Spacer,
  TextInput,
  Typography,
} from '../../../../../../components'
import { View } from 'react-native'
import InfoBox from '../../../../../../components/InfoBox'
import { StyledFoundClientInGroupContent } from '../AddClientTours.styles'

const InviteClientForm = ({ group, onInviteClient, onGoBack }: InviteClientFormProps) => {
  const { getAccessInfos } = useAuthenticationContext()
  const theme = useTheme()

  const [newClientToInvite, setNewClientToInvite] = useState<AddClientTourInvite>({
    label: '',
    email: '',
    firstName: '',
    lastName: '',
  })
  const [emailForm, setEmailForm] = useState<boolean>(true)
  const [clientMailRequest, setClientMailRequest] = useState<string>('')
  const [foundClient, setFoundClient] = useState<Client>()
  const [foundClientInGroup, setFoundClientInGroup] = useState<Client>()
  const [newClientToInviteForm, setNewClientToInviteForm] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newClientToInvite)) return null

    const clientTemp = Object.assign({}, newClientToInvite)

    clientTemp[param as keyof typeof newClientToInvite] = value
    setNewClientToInvite(clientTemp)
  }

  const infoBoxContent = {
    title: 'Que recevront les clients ?',
    content:
      'Les clients que vous invitez reçoivent un mail afin de créer leur compte. Ils sont automatiquement affiliés à votre groupe et à la tournée de livraison.',
  }

  const onRequestSearchMail = async () => {
    if (clientMailRequest && !GeneralUtils.isEmail(clientMailRequest)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }

    let foundExistingClient

    try {
      foundExistingClient = await ClientCarrierService.getOneClient(
        getAccessInfos().carrierId,
        undefined,
        clientMailRequest,
      )

      let foundExistingClientInGroup =
        foundExistingClient &&
        foundExistingClient.clientGroups?.find((cg) => cg.group?.id === group?.id)

      if (foundExistingClientInGroup) {
        setFoundClientInGroup(foundExistingClient)
        setIsLoading(false)
        return
      }

      if (foundExistingClient) {
        setFoundClient(foundExistingClient)
        setIsLoading(false)
        return
      }
    } catch (error) {
      console.error(error)
    }

    if (!foundExistingClient) {
      setValue(clientMailRequest, 'email')
      setEmailForm(false)
      setNewClientToInviteForm(true)
      setIsLoading(false)
    }
  }

  const onValidateClient = async (foundClient: Client) => {
    setErrorMessage('')
    if (!group?.id) {
      setErrorMessage('Erreur de chargement du groupe')
      return
    }

    const foundClientFormatted = {
      id: foundClient.id,
      label: foundClient.label,
      email: foundClient.mail || '',
      firstName: foundClient.user?.firstName || '',
      lastName: foundClient.user?.lastName || '',
    }
    if (foundClient) {
      try {
        setIsLoading(true)
        onInviteClient(foundClientFormatted)
      } catch (error) {
        setErrorMessage('Un problème est survenu lors de la liaison du client')
        console.error(error)
      }
    }

    return foundClientFormatted
  }

  const onValidateNewClient = (newClientToInvite: AddClientTourInvite) => {
    onInviteClient(newClientToInvite)
  }

  const handleBackToList = () => {
    setEmailForm(true)
    setNewClientToInviteForm(false)
    setFoundClient(undefined)
    setClientMailRequest('')
  }

  const handleGoBackToAddClientsTour = () => {
    onGoBack()
  }

  const isDisabledButtonForm = () => {
    if (!newClientToInvite.label || !newClientToInvite.email) {
      return true
    }
    return false
  }

  return (
    <>
      <BackToListWrapper onPress={handleBackToList}>
        <Icon
          type={Icons.Ionicons}
          name="chevron-back-outline"
          color={theme.colors['color-primary']}
        />
        <Button.Text label={'Revenir en arrière'} onPress={handleGoBackToAddClientsTour} />
      </BackToListWrapper>
      {emailForm ? (
        <>
          <SectionTitle title="Saisir un email" />
          <Row
            rowStyles={{
              flexDirection: !foundClient ? 'row' : 'column',
              alignItems: !foundClient ? 'center' : 'flex-start',
            }}
          >
            <Col xs={9}>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <TextInput
                value={clientMailRequest}
                label="Email"
                field="text"
                onChangeText={(text) => {
                  setClientMailRequest(text)
                  setFoundClient(undefined)
                }}
              />
              {errorMessage ? (
                <>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <View style={{ width: '100%' }}>
                    <Typography.Body colorName="color-danger" align="center">
                      {errorMessage}
                    </Typography.Body>
                  </View>
                </>
              ) : null}
            </Col>
            {foundClientInGroup && (
              <StyledFoundClientInGroupContent>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                <Typography.Body colorName="color-danger">
                  Nous avons trouvé ce client associé à cette adresse e-mail dans votre groupe :
                </Typography.Body>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                <Typography.Body colorName="color-danger" bold>
                  - {foundClientInGroup?.label}
                </Typography.Body>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                <Typography.Body colorName="color-danger">
                  S'il s'agit d'un autre point de livraison, vous pouvez créer un second compte.
                </Typography.Body>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2.5} />
                <Row>
                  <Col xs={12}>
                    <StyledButtonWrapper>
                      <Button
                        label={'Créer un autre compte avec la même adresse mail'}
                        onPress={() => {
                          setValue(clientMailRequest, 'email')
                          setEmailForm(false)
                          setNewClientToInviteForm(true)
                        }}
                        loading={isLoading}
                      />
                    </StyledButtonWrapper>
                  </Col>
                </Row>
              </StyledFoundClientInGroupContent>
            )}
            {foundClient && (
              <>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body colorName="color-grey">
                  Nous avons trouvé le(s) compte(s) suivant(s) avec cette adresse email
                </Typography.Body>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <SectionTitle title="Producteur" />
                <Row>
                  <Col xs={12}>
                    <StyledCardWrapper>
                      <Card>
                        <StyledCardContentWrapper>
                          <LeftCardContentWrapper>
                            <Typography.CardTitle ellipsis>
                              {foundClient?.label}
                            </Typography.CardTitle>
                            <Typography.BodySmall>{foundClient?.owners}</Typography.BodySmall>
                            <Typography.BodySmall>
                              {foundClient?.clientGroups
                                ? foundClient.clientGroups.map((pg) => pg.group?.label).join(' & ')
                                : null}
                            </Typography.BodySmall>
                          </LeftCardContentWrapper>
                          <StyledLinkButtonWrapper>
                            <Button
                              label={'Lier'}
                              onPress={() => onValidateClient(foundClient)}
                              loading={isLoading}
                            />
                          </StyledLinkButtonWrapper>
                        </StyledCardContentWrapper>
                      </Card>
                    </StyledCardWrapper>
                  </Col>
                </Row>
              </>
            )}
            <Col xs={3}>
              <StyledButtonWrapper>
                {!foundClient && !foundClientInGroup && (
                  <Button
                    label="Vérifier"
                    onPress={() => onRequestSearchMail()}
                    loading={isLoading}
                    disabled={!clientMailRequest}
                  />
                )}
              </StyledButtonWrapper>
            </Col>
          </Row>
        </>
      ) : null}
      {newClientToInviteForm ? (
        <>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <InfoBox title={infoBoxContent.title} content={infoBoxContent.content} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <TextInput
            value={newClientToInvite.label}
            label="Nom de l'enseigne"
            field="text"
            onChangeText={(text) => setValue(text, 'label')}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <TextInput
            value={newClientToInvite.email}
            label="Email"
            field="text"
            onChangeText={(text) => setValue(text, 'email')}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <TextInput
            value={newClientToInvite.firstName}
            label="Prénom (facultatif)"
            field="text"
            onChangeText={(text) => setValue(text, 'firstName')}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <TextInput
            value={newClientToInvite.lastName}
            label="Nom (facultatif)"
            field="text"
            onChangeText={(text) => setValue(text, 'lastName')}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
          <StyledButtonWrapper>
            <Button
              label="Ajouter à la tournée"
              onPress={() => onValidateNewClient(newClientToInvite)}
              loading={isLoading}
              disabled={isDisabledButtonForm()}
            />
          </StyledButtonWrapper>
        </>
      ) : null}
    </>
  )
}

export default InviteClientForm
