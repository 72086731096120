import React, { useCallback, useMemo } from 'react'
import { StatusBar } from 'expo-status-bar'
import { LogBox, StyleSheet, Text, View } from 'react-native'
import { useHarvyTheme } from './utilities/styling/theme'
import { ThemeProvider } from 'styled-components/native'
import { DeepLinkProvider } from './utilities/context/deepLinkContext'
import { SafeAreaProviderCompat } from '@react-navigation/elements'
import Routes from './navigation'
import { useFonts } from 'expo-font'
import harvyFonts from './utilities/styling/theme/harvyFonts'
import { LanguageProvider } from './utilities/context/languageContext'
import { UserProvider } from './utilities/context/userContext'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './utilities/queryClient'
import { AuthenticationProvider } from './utilities/context/authenticationContext'
import { NotificationsProvider } from './utilities/context/notificationsContext'
import { GroupProvider } from './utilities/context/groupContext'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import SplashScreen from './screens/Common/SplashScreen'

LogBox.ignoreAllLogs()

const App = () => {
  const [fontsLoaded] = useFonts(harvyFonts)

  const isLoadingComplete = useMemo(() => fontsLoaded, [fontsLoaded])

  const onNavigationReady = useCallback(async () => {
    /*
    if (biometricStatus === EnumBiometricResult.CANCELLED) {
      await RootNavigation.navigate('LockedScreen')
    }
    */
  }, [])

  if (!isLoadingComplete) return <SplashScreen />

  return <Routes onNavigationReady={onNavigationReady} />
}

function AppWithProviders() {
  const harvyTheme = useHarvyTheme()
  return (
    <DeepLinkProvider>
      <SafeAreaProviderCompat>
        <StatusBar translucent />
        <ThemeProvider theme={harvyTheme}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <NotificationsProvider>
              <AuthenticationProvider>
                <UserProvider>
                  <GroupProvider>
                    <LanguageProvider>
                      <App />
                    </LanguageProvider>
                  </GroupProvider>
                </UserProvider>
              </AuthenticationProvider>
            </NotificationsProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </SafeAreaProviderCompat>
    </DeepLinkProvider>
  )
}

export default AppWithProviders
