import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledContent = styled.View`
  width: 100%;
  max-width: 400px;
  min-height: 350px;
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  justify-content: center;
  padding: ${({ theme }) => theme.spacingUnit * 2}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`

export { StyledContent }
