/*
const darkThemeColors = {
  'color-primary': '#3E5D41',
  'color-primary-light': '#3e5d419d',
  'color-secondary': '#8CA074',
  'color-secondary-light': '#8ca074bd',
  'color-tertiary': '#242424',
  'color-tertiary-light': '#303030',
  'color-button-disabled': 'hsla(14, 96%, 59%, 0.6)',
  'text-primary': '#FFFFFF',
  'text-secondary': '#2B2A35',
  'text-tertiary': '#707070',
  'text-tertiary-light': '#a3a3a3',
  'text-button-disabled': 'hsla(0, 0%, 7%, 1)',
  'color-danger': '#CF0000',
  'color-background': '#F3F3F3',
  'color-background-light': '#FDFDFD',
  'background-danger': 'rgba(255, 99, 99, 0.2)',
  'color-input': '#d7d7d7',
  'color-border': '#dfdfdfc8',
  'color-badge': '#585858',
  'color-discover-card-background': '#252525',
  'color-divider': '#6D6D6D',
  'color-warning': '#FF9A03',
  'color-success': '#3E5D41',
  'color-shadow': '#a8a8a873',
  transparent: 'transparent',
}
*/

/*
const lightThemeColors = {
  'color-primary': '#396A3A',
  'color-primary-light': '#396a3ab8',
  'color-secondary': '#8CA074',
  'color-secondary-light': '#8ca074bd',
  'color-tertiary': '#090B0A',
  'color-tertiary-light': '#585858',
  'color-button-disabled': 'hsla(101, 86%, 30%, 0.3)',
  'text-primary': '#FFFFFF',
  'text-secondary': '#284036',
  'text-tertiary': '#AEAEAE',
  'text-tertiary-light': '#a3a3a3',
  'text-button-disabled': 'hsla(0, 0%, 7%, 1)',
  'color-danger': '#c40000',
  'color-background': '#FAF8F0',
  'color-background-light': '#ffffff',
  'background-danger': 'rgba(255, 99, 99, 0.2)',
  'color-border': '#f4f4f4c6',
  'color-input': '#d7d7d7',
  'color-badge': '#585858',
  'color-discover-card-background': '#252525',
  'color-divider': '#d7d7d7',
  'color-warning': '#e69924',
  'color-success': '#3E5D41',
  'color-shadow': 'rgba(0, 0, 0, 0.07)',
  transparent: 'transparent',
}
*/
const darkThemeColors = {
  'color-primary': '#284036',
  'color-primary-2': '#523D31',
  'color-secondary': '#BDFB8D',
  'color-tertiary': '#FAF8F0',
  'color-quaternary': '#F2F1DC',
  'color-grey': '#AEAEAE',
  'color-white': '#FFFF',
  'color-black': '#000000',
  'text-light-1': '#FAF8F0',
  'text-light-2': '#BDFB8D',
  'text-light-3': '#FFFF',
  'text-dark-1': '#284036',
  'text-dark-2': '#000000',
  'text-dark-3': '#AEAEAE',
  'text-dark-4': '#c6c6c6',
  'background-1': '#FAF8F0',
  'background-2': '#F2F1DC',
  'background-3': '#FFFF',
  'color-shadow': 'rgba(0, 0, 0, 0.1)',
  'color-success': '#BDFB8D',
  'color-danger': '#E13535',
  'color-warning': '#FF9900',
  'color-input': '#FFFF',
  'color-border': '#dcdcdc',
  transparent: 'transparent',
}

const lightThemeColors = {
  'color-primary': '#284036',
  'color-primary-2': '#523D31',
  'color-secondary': '#BDFB8D',
  'color-tertiary': '#FAF8F0',
  'color-quaternary': '#F2F1DC',
  'color-grey': '#7f807f',
  'color-white': '#FFFF',
  'color-black': '#000000',
  'text-light-1': '#FAF8F0',
  'text-light-2': '#BDFB8D',
  'text-light-3': '#FFFF',
  'text-dark-1': '#4f4f4f',
  'text-dark-2': '#000000',
  'text-dark-3': '#AEAEAE',
  'text-dark-4': '#c6c6c6',
  'background-1': '#FAF8F0',
  'background-2': '#F2F1DC',
  'background-3': '#FFFF',
  'color-shadow': 'rgba(0, 0, 0, 0.04)',
  'color-success': '#BDFB8D',
  'color-danger': '#cd1414',
  'color-warning': '#e98c00',
  'color-input': '#FFFF',
  'color-border': '#e8e8e8',
  transparent: 'transparent',
}
export default darkThemeColors
export { lightThemeColors, darkThemeColors }
