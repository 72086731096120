import { useState } from 'react'
import { Button, Spacer, TextInput, Typography } from '../../../components'
import { TextTag } from '../../../components/Text/Text.model'
import { View } from 'react-native'

export interface ProductBarcodeInputProps {
  barcode?: string
  customBarcodeValue?: string
  onChange: (customBarcodeValue: string) => void
  displayBarcode?: boolean
}

export function ProductBarcodeInput({
  barcode,
  customBarcodeValue,
  onChange,
  displayBarcode = true,
}: ProductBarcodeInputProps) {
  const [isProductBarcodeInputDisplayed, setProductBarcodeInputDisplayed] = useState(
    !!customBarcodeValue,
  )

  return (
    <View
      style={{
        paddingTop: 0,
      }}
    >
      {displayBarcode && (
        <Typography.Body colorName="text-dark-3">
          Code-barres Harvy (GS1) : {barcode}
        </Typography.Body>
      )}
      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      {!isProductBarcodeInputDisplayed && (
        <Button.Text
          label={`Renseigner un code-barres personnalisé`}
          onPress={() => setProductBarcodeInputDisplayed(!isProductBarcodeInputDisplayed)}
          colorName="text-dark-3"
          textTag={TextTag.BODY_SMALL}
          style={{ alignSelf: 'flex-end' }}
        />
      )}

      {isProductBarcodeInputDisplayed && (
        <>
          <TextInput
            value={customBarcodeValue}
            label={`Code-barres personnalisé`}
            field="text"
            onChangeText={(text) => onChange(text)}
          />
          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          <Button.Text
            label={`Retirer code-barres personnalisé`}
            onPress={() => {
              setProductBarcodeInputDisplayed(!isProductBarcodeInputDisplayed)
              onChange('')
            }}
            colorName="text-dark-3"
            textTag={TextTag.BODY_SMALL}
            style={{ alignSelf: 'flex-end' }}
          />
        </>
      )}
    </View>
  )
}
