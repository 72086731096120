import styled from 'styled-components/native'

const StyledTopBox = styled.View`
  flex-direction: row;
`
const MinOrderWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const ButtonsWrapper = styled.View`
  flex-direction: row;
  justify-content: center;
  gap: ${({ theme }) => theme.spacingUnit * 1}px;
`

export { StyledTopBox, ButtonsWrapper, MinOrderWrapper }
