import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Button, PageTitle, SectionTitle, Spacer } from '../../../components'
import Loader from '../../../components/Loader'
import { Order, Pagination } from '../../../domain'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'

import FormatUtils from '../../../utilities/utils/format'
import OrderClientService from '../../../services/client/order'
import CardSettings from '../../../components/CardSettings'
import { Switch } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { ShelfLabel } from '../../../domain/ShelfLabel'
import ShelfLabelClientService from '../../../services/client/shelfLabel'
import CardListPaginated from '../../../components/CardListPaginated'
import ShelfLabelCard from '../../../components/ShelfLabelCard'
import PdfUtils from '../../../utilities/utils/pdf'
import { StackNavigationProp } from '@react-navigation/stack'

const ClientPrintOrderRecapScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<ClientStackParamList, 'ClientPrintOrderRecap'>>()
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()

  const [withPrices, setWithPrices] = useState<boolean>(true)
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false)
  const { getAccessInfos } = useAuthenticationContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { orderId } = route?.params ?? {
    orderId: '',
  }

  const {
    data: order,
    refetch: refetchOrder,
    isInitialLoading: orderLoading,
  } = useQuery<Order, Error>(['c_order'], () => OrderClientService.getOneOrder(orderId), {
    keepPreviousData: false,
    enabled: orderId !== '',
  })

  const {
    data: shelfLabels,
    refetch: refetchShelfLabels,
    isLoading: shelLabelsLoading,
  } = useQuery<{ data: ShelfLabel[]; pagination: Pagination }, Error>(
    ['c_shelf_labels_order', orderId],
    () => ShelfLabelClientService.getAll(orderId, pageSize, pageNumber),
    {},
  )

  const onChangePageShelfLabels = async (pageNumber: number) => {
    await setPageNumber(pageNumber)
    refetchShelfLabels()
  }

  if (orderLoading || !order) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  const onPressPrint = async (shelfLabelIds?: string[]) => {
    setIsLoading(true)
    try {
      const base64Response = await ShelfLabelClientService.getPdf(
        orderId,
        withPrices,
        shelfLabelIds,
      )
      if (!PdfUtils.isValidBase64Pdf(base64Response)) {
        throw new Error("Le PDF reçu n'est pas valide")
      }
      PdfUtils.downloadPdf(base64Response, `order_${orderId}.pdf`)
    } finally {
      setIsLoading(false)
    }
  }

  const onPressDelete = async () => {
    setIsDeleteLoading(true)
    await ShelfLabelClientService.deleteFromOrder(orderId)
    setIsDeleteLoading(false)
    refetchShelfLabels()
  }

  const onPressEditShelfLabel = async (shelfLabelId: string) => {
    navigation.navigate('ClientEditShelfLabel', {
      shelfLabelId: shelfLabelId,
    })
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper alignCenter>
        <PageTitle
          title="Imprimer votre bon et vos étiquettes"
          subtitle={`Commande #${FormatUtils.formatId(
            order.id,
          )}, livraison le ${FormatUtils.formatDate(
            order.cart?.tour?.start,
            'FullDate+StartTime',
          )}`}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <CardSettings
          title="Afficher le prix client sur l'étiquette"
          description={`Souhaitez-vous utiliser l'étiquette Harvy pour afficher le prix de vente client de vos produits`}
          children={
            <Switch
              trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
              onValueChange={() => setWithPrices(withPrices === true ? false : true)}
              value={withPrices === true}
            />
          }
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />

        <SectionTitle title={`Définir les prix client de vos produits`} />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

        <CardListPaginated
          data={shelfLabels?.data}
          pagination={shelfLabels?.pagination}
          element={function (shelfLabel: any) {
            return (
              <ShelfLabelCard
                shelfLabel={shelfLabel}
                refetchShelves={refetchShelfLabels}
                key={shelfLabel.id}
                isLoading={isLoading}
                onPressPrint={onPressPrint}
                onPressEdit={onPressEditShelfLabel}
              />
            )
          }}
          isLoading={shelLabelsLoading}
          emptyMessage="Aucune étiquette disponible"
          onChangePage={onChangePageShelfLabels}
        />

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
        <Button
          label="Imprimer"
          onPress={() => onPressPrint()}
          loading={isLoading}
          disabled={!shelfLabels?.data || shelfLabels?.data?.length < 1}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
        {getAccessInfos().clientIsCarrier && (
          <>
            <Button.Text
              label="Supprimer les étiquettes"
              onPress={() => onPressDelete()}
              colorName="color-grey"
              loading={isDeleteLoading}
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
          </>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientPrintOrderRecapScreen
