import React, { useState } from 'react'
import { Spacer, Typography } from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { BatchCatalogProps } from './BatchCatalog.model'
import {
  ImageWrapper,
  StyledCardContent,
  StyledCardWrapper,
  StyledImage,
  StyledLabel,
  StyledLabelDescription,
  StyledLabelDescriptionWrapper,
  StyledLabelWrapper,
  StyledProductLabel,
  StyledValueBox,
  StyledValuesWrapper,
} from './BatchCatalog.styles'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { BatchExtended, Cart, Product } from '../../../domain'
import DirectusUtil from '../../../utilities/utils/directus'
import { LabelEnums, ProductEnums } from '../../../../enums'
import CatalogClientNavigationStackParamList from '../../../navigation/ClientNavigationStack/CatalogClientNavigation/CatalogClientNavigation.model'
import AddCartButton from '../../Cart/AddCartButton'
import BatchUtil from '../../../utilities/utils/batch'
import AccountUtil from '../../../utilities/utils/account'

const BatchCatalog = ({
  cart,
  batch,
  clientLabels,
  displayProducer,
  group,
  testID,
}: BatchCatalogProps) => {
  const batchNavigation =
    useNavigation<StackNavigationProp<CatalogClientNavigationStackParamList>>()
  const [displayDescription, setDisplayDescription] = useState<boolean>(false)

  const product = batch.product
  const producer = product?.producer
  const bioProductLabel = FormatUtils.getBioProductLabel(product?.productLabels)

  const getAvailablePackingQuantity = () => {
    if (
      batch?.availableQuantity &&
      product?.packing &&
      batch?.availableQuantity >= product?.packing
    ) {
      return Math.round(batch?.availableQuantity / product?.packing)
    }
    return 0
  }

  const onBatchPress = () => {
    batchNavigation.navigate('ClientBatchPage', { id: batch?.id })
  }

  if (!batch.unitPrice) {
    return <></>
  }

  const producerHasNoVAT = () => {
    return producer?.account && !AccountUtil.isProfileSubjectToVAT(producer.account)
  }

  const displayPricePerKilo = () => {
    if (product?.displayPricePerKilo && product?.unitWeight && product?.unitWeight > 0) {
      const pricePerKilo = batch.unitPrice / product?.unitWeight

      if (!pricePerKilo || pricePerKilo <= 0) return <></>
      return (
        <Typography.BodyExtraSmall colorName={'text-dark-3'}>
          {FormatUtils.formatPrice(pricePerKilo)}
          {FormatUtils.getPriceUnits('ht', producer)}
          {producerHasNoVAT() ? '*' : ''}/{FormatUtils.formatUnity(ProductEnums.MESURE_TYPE.KILO)}
        </Typography.BodyExtraSmall>
      )
    }
    return <></>
  }

  return (
    <StyledCardWrapper>
      <StyledCardContent>
        <TouchableOpacity
          testID={testID}
          onPress={() => {
            onBatchPress()
          }}
        >
          <ImageWrapper>
            <StyledImage
              source={DirectusUtil.getProductImageUrlFromId(product as any as Product)}
              resizeMode="cover"
            />
            <StyledLabelDescriptionWrapper isOpacity={displayDescription}>
              <StyledLabelDescription>
                <Typography.BodySmall bold>Infos</Typography.BodySmall>
              </StyledLabelDescription>
            </StyledLabelDescriptionWrapper>
            <StyledLabelWrapper>
              {clientLabels?.find((l) => l === LabelEnums.BIOCOOP) &&
              producer?.labels?.find((l) => l === LabelEnums.BIOCOOP) ? (
                <StyledLabel>
                  <Typography.BodySmall bold>BC</Typography.BodySmall>
                </StyledLabel>
              ) : null}
              {bioProductLabel ? (
                <StyledProductLabel>
                  <Typography.BodySmall colorName="text-light-1" bold>
                    {bioProductLabel.label}
                  </Typography.BodySmall>
                </StyledProductLabel>
              ) : null}
            </StyledLabelWrapper>
          </ImageWrapper>
        </TouchableOpacity>
        <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.Body bold ellipsis>
          {product?.label}
        </Typography.Body>
        <Spacer size={0.1} axis={Spacer.AxisEnum.VERTICAL} />

        <StyledValuesWrapper>
          <StyledValueBox>
            {displayProducer && (
              <>
                <Typography.BodyExtraSmall colorName="text-dark-1" ellipsis>
                  {producer?.label}
                </Typography.BodyExtraSmall>
                <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
              </>
            )}
            <Typography.Body colorName={'text-dark-1'}>
              {FormatUtils.formatPrice(batch.unitPrice)}
              {FormatUtils.getPriceUnits('ht', producer)}
              {producerHasNoVAT() ? '*' : ''}/{FormatUtils.formatUnity(product?.mesureType)}
            </Typography.Body>
            {group && BatchUtil.displayCrossedOutPrice(batch, group) ? (
              <Typography.BodySmall
                colorName={'color-danger'}
                style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}
              >
                {FormatUtils.formatPrice(batch.crossedOutPrice)}
                {FormatUtils.getPriceUnits('ht', producer)}
                {producerHasNoVAT() ? '*' : ''}/{FormatUtils.formatUnity(product?.mesureType)}
              </Typography.BodySmall>
            ) : (
              displayPricePerKilo()
            )}
          </StyledValueBox>
          <View style={{ flex: 1 }}></View>
          <StyledValueBox>
            <Typography.Body colorName={'text-dark-1'}>
              par {FormatUtils.formatQuantity(product?.packing, product?.mesureType)}
            </Typography.Body>
            {getAvailablePackingQuantity() > 0 && (
              <Typography.BodyExtraSmall colorName="text-dark-3">
                Dispo. : {getAvailablePackingQuantity()} colis
              </Typography.BodyExtraSmall>
            )}
          </StyledValueBox>
        </StyledValuesWrapper>
        <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
        <AddCartButton cart={cart} batch={batch} testID={`${testID}-add-cart-button`} />

        <Spacer size={1.5} axis={Spacer.AxisEnum.VERTICAL} />
      </StyledCardContent>
    </StyledCardWrapper>
  )
}

export default BatchCatalog
