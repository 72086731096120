import { useEffect, useState } from 'react'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import {
  Button,
  Col,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  TextInput,
  Typography,
} from '../../../components'
import { useQuery } from '@tanstack/react-query'
import ClientClientService from '../../../services/client/client'
import { ClientInformationsContentWrapper, StyledButtonWrapper } from './ClientInformations.styles'
import { Address, AddressType, Client, UpdateClient } from '../../../domain'
import { View } from 'react-native'
import AddressForm from '../../../modules/Address/AddressForm'
import FormatUtils from '../../../utilities/utils/format'
import i18n from '../../../i18n'
import { queryClient } from '../../../utilities/queryClient'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import { StackNavigationProp } from '@react-navigation/stack'
import { useNavigation } from '@react-navigation/native'

const ClientInformationsScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()

  const {
    data: client,
    refetch: refetchClient,
    isInitialLoading: isClientLoading,
  } = useQuery<Client, Error>(
    ['c_client', getAccessInfos().clientId],
    () => ClientClientService.getOne(getAccessInfos().clientId),
    {
      keepPreviousData: true,
    },
  )

  const [updatedClient, setUpdatedClient] = useState<Partial<UpdateClient>>({})

  useEffect(() => {
    if (!client) return

    setUpdatedClient({
      label: client.label,
      owners: client.owners,
      phone1: client.phone1,
      mail: client.mail,
      addressLine1: client.addressLine1,
      addressLine2: client.addressLine2,
      postalCode: client.postalCode,
      city: client.city,
      deliveryAddress: client.deliveryAddress,
    })
  }, [client, setUpdatedClient])

  if (!updatedClient) return <View></View>

  const setValue = (value: string, param: string) => {
    setErrorMessage('')
    if (!updatedClient || !param || !(param in updatedClient)) return null
    const clientTemp = Object.assign({}, updatedClient)

    clientTemp[param as keyof typeof updatedClient] = value as any
    setUpdatedClient(clientTemp)
  }

  const onChangeDeliveryAddress = (newAddress: Address) => {
    if (newAddress && newAddress.id) {
      const clientTemp = Object.assign({}, updatedClient)

      clientTemp['deliveryAddress'] = newAddress
      setUpdatedClient(clientTemp)
      refetchClient()
    }
  }

  const onUpdateClient = async () => {
    setErrorMessage('')
    setIsLoading(true)
    const client = {
      label: updatedClient.label,
      owners: updatedClient.owners,
      phone1: updatedClient.phone1,
      mail: updatedClient.mail,
      addressLine1: updatedClient.addressLine1,
      addressLine2: updatedClient.addressLine2,
      postalCode: updatedClient.postalCode,
      city: updatedClient.city,
    }

    const updatedClientTemp = await ClientClientService.update(getAccessInfos().clientId, client)

    if (updatedClientTemp && updatedClientTemp.id) {
      await queryClient.invalidateQueries(['c_client', getAccessInfos().clientId])
      navigation.navigate('UserMenu')
    } else {
      setErrorMessage('Une erreur est survenue lors de la modification du client')
    }
    setIsLoading(false)
  }

  return (
    <ScreenSafeAreaWrapper>
      <ContentWrapper noPaddingLeft>
        <ClientInformationsContentWrapper>
          <PageTitle title="Modifier mes informations" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Row>
              <Col xs={6} sm={6} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedClient?.label}
                  label="Nom du client"
                  field="text"
                  onChangeText={(text) => setValue(text, 'label')}
                />
              </Col>
              <Col xs={6} sm={6} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedClient?.owners}
                  label="Gérant.e.s"
                  field="text"
                  onChangeText={(text) => setValue(text, 'owners')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={6} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedClient?.mail}
                  label="Email"
                  field="text"
                  onChangeText={(text) => setValue(text, 'mail')}
                />
              </Col>
              <Col xs={6} sm={6} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedClient?.phone1}
                  label="Téléphone"
                  field="text"
                  onChangeText={(text) => setValue(text, 'phone1')}
                />
              </Col>
            </Row>
            {updatedClient.deliveryAddress && (
              <AddressForm
                sectionTitle="Adresse de livraison"
                onChangeAddress={onChangeDeliveryAddress}
                address={updatedClient.deliveryAddress}
                addressType={AddressType.DELIVERY}
                client={client}
              />
            )}
            {!updatedClient.deliveryAddress && (
              <>
                <SectionTitle title="Adresse de livraison" />
                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedClient.addressLine1}
                      label="N°, rue"
                      field="text"
                      onChangeText={(text) => setValue(text, 'addressLine1')}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedClient.addressLine2}
                      label="Complément d'adresse"
                      field="text"
                      onChangeText={(text) => setValue(text, 'addressLine2')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedClient.postalCode}
                      label="Code postal"
                      field="text"
                      onChangeText={(text) => setValue(text, 'postalCode')}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedClient.city}
                      label="Ville"
                      field="text"
                      onChangeText={(text) => setValue(text, 'city')}
                    />
                  </Col>
                </Row>
              </>
            )}
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Button
              label={FormatUtils.capitalize(i18n.t('save'))}
              onPress={() => onUpdateClient()}
              loading={isLoading}
              hasDoubleValidation
              confirmationLabel="Êtes-vous sûr de vouloir modifier ce client ?"
            />
          </StyledButtonWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        </ClientInformationsContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientInformationsScreen
