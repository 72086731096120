import Env from '../env'
import { AddDocument, Document, Producer, Product } from '../../domain'
import { Category } from '../../domain/Category'

const getProductImageUrlFromId = (product: Product): { uri: string } | any => {
  if (!product) return undefined
  if (product.newImageUri) {
    return product.newImageUri
  }
  if (product.imageId) {
    return { uri: `${Env.DIRECTUS_URL}/assets/${product.imageId}?quality=40` }
  }
  if (product.imageUrl) {
    return { uri: product.imageUrl }
  }
  return require('../../../assets/no_photo.jpeg')
}

const getProducerImageUrlFromId = (producer: Producer): string | undefined => {
  if (!producer || !producer.imageId) return require('../../../assets/no_photo.jpeg')

  if (producer.newImageUri) {
    return producer.newImageUri
  }
  if (producer.imageId) {
    return `${Env.DIRECTUS_URL}/assets/${producer.imageId}`
  }
}

const getCategoryImageUrlFromId = (category?: Category): string | undefined => {
  if (!category || !category.imageId) return require('../../../assets/no_photo.jpeg')
  if (category.imageId) {
    return `${Env.DIRECTUS_URL}/assets/${category.imageId}`
  }
  return undefined
}

const getDocumentImageUrlFromId = (document?: Document): string | undefined => {
  if (!document || !document.documentId) return require('../../../assets/no_photo.jpeg')

  if (document.documentUri) {
    return document.documentUri
  }
  if (document.documentId) {
    return `${Env.DIRECTUS_URL}/assets/${document.documentId}`
  }
  return undefined
}

const getProducerGalleryImageUrlFromId = (producer: Producer): string[] | undefined => {
  if (!producer || (!producer.newGalleryImagesUris && !producer.galleryImagesIds)) return []
  const results = []
  if (producer.newGalleryImagesUris && producer.newGalleryImagesUris.length > 0) {
    results.push(...producer.newGalleryImagesUris)
  }
  if (producer.galleryImagesIds && producer.galleryImagesIds.length > 0) {
    results.push(
      ...producer.galleryImagesIds.map((imageId) => `${Env.DIRECTUS_URL}/assets/${imageId}`),
    )
  }
  return results
}

const getDocumentDownloadUrlFromId = (documentId: string, accessToken: string): string => {
  if (!documentId) return ''
  return `${Env.DIRECTUS_URL}/assets/${documentId}/?access_token=${accessToken}`
}

const getProducerDocumentImageUrlFromId = (document?: AddDocument): string | undefined => {
  if (!document) return
  if (document.documentUri) {
    return document.documentUri
  }

  return undefined
}

const DirectusUtil = {
  getProductImageUrlFromId,
  getProducerImageUrlFromId,
  getDocumentDownloadUrlFromId,
  getProducerGalleryImageUrlFromId,
  getDocumentImageUrlFromId,
  getProducerDocumentImageUrlFromId,
  getCategoryImageUrlFromId,
}

export default DirectusUtil
