import React, { useEffect, useState } from 'react'
import { InvoiceNumberProps } from './InvoiceNumber.model'
import TextInput from '../inputs/TextInput'
import useAuthenticationContext from '../../utilities/hook/useAuthenticationContext'
import InvoiceService from '../../services/invoice'

const InvoiceNumber = ({ onEdit }: InvoiceNumberProps) => {
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const { getAccessInfos } = useAuthenticationContext()

  const onChange = (value: string) => {
    setInvoiceNumber(value)
    onEdit(value)
  }

  const { query: invoicesQuery } = InvoiceService.useGetInvoices({
    accountId: getAccessInfos().accountId,
    limit: 1,
    pageNumber: 1,
    getByIssuer: true,
    onlyLastOne: true,
  })

  const { data: invoices } = invoicesQuery()

  useEffect(() => {
    const lastInvoiceIdentifier = (invoices?.data[0]?.identifier || 0) + 1
    setInvoiceNumber(lastInvoiceIdentifier.toString())
  }, [invoices])

  return (
    <TextInput
      label="Nº de facture"
      field={'invoiceNumber'}
      value={invoiceNumber}
      defaultValue={''}
      boldInputLabel
      onChangeText={onChange}
    />
  )
}

export default InvoiceNumber
