import { ContainerStatusEnum, ContainerTypeEnum } from '../../../enums/container'
import { Carrier } from '../Carrier'
import { Group } from '../Group'
import { Producer } from '../Producer'

export type State = {
  id: string
  code?: string
  label?: string
}

export type Address = {
  id: string
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  indications?: string
  city?: string
  latitude?: string
  longitude?: string
  state?: State
  producer?: Producer
  group?: Group
  carrier?: Carrier
}

export type AddAddress = {
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  city?: string
  indications?: string
  producerId?: string
  carrierId?: string
}

export type UpdateAddress = {
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  indications?: string
  city?: string
}

export enum AddressType {
  DELIVERY = 'DELIVERY',
  BILLING = 'BILLING',
}
