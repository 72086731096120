import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledImageRight = styled.View`
  flex: 3;
  margin-top: ${({ theme }) => theme.spacingUnit * 0.5}px;
`
const StyledImageLeft = styled.View`
  align-self: flex-start;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.colors && theme.colors['color-primary']};
  padding: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledImageBox = styled.View`
  padding: ${({ theme }) => theme.spacingUnit * 1.5}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 0.5}px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  width: 100%;
  flex-direction: row;
  align-items: center;
`

const StyledButtonWrapper = styled.View`
  align-items: center;
  justify-content: center;
`
const StyledDownloadButtonWrapper = styled.View`
  justify-content: center;
  min-width: ${({ theme }) => theme.spacingUnit * 24}px;
`

const StyledButtonsWrapper = styled.View`
  gap: ${({ theme }) => theme.spacingUnit * 2}px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`

export {
  StyledButtonWrapper,
  StyledImageBox,
  StyledImageRight,
  StyledButtonsWrapper,
  StyledImageLeft,
  StyledDownloadButtonWrapper,
}
