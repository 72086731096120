import styled from 'styled-components/native'

const StyledCardWrapper = styled.View`
  margin-bottom: ${({ theme }) => theme.spacingUnit}px;
  width: 100%;
  max-width: 650px;
  background-color: ${({ theme }) => theme.colors['background-3']};
  border-radius: ${({ theme }) => theme.borderRadius}px;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 1.3}px;
  flex-direction: row;
  overflow: hidden;
`

const LeftBox = styled.View`
  min-height: 20px;
  flex-direction: row;
  align-items: center;
  width: 50%;
`

const ProductNameBox = styled.View`
  flex-direction: column;
  max-width: 75%;
  overflow: hidden;
`

const RightBox = styled.View`
  min-height: 20px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const StyledImage = styled.Image`
  width: ${({ theme }) => theme.spacingUnit * 4}px;
  height: ${({ theme }) => theme.spacingUnit * 4}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 1px solid #f3f3f3;
  background-color: ${({ theme }) => theme.colors['background-3']};
`

const StyledValuesWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  min-height: 20px;
`

const StyledValueBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 0.3}px;
  justify-content: center;
  align-items: center;
`

export {
  StyledCardWrapper,
  StyledCardContent,
  StyledImage,
  StyledValuesWrapper,
  StyledValueBox,
  LeftBox,
  RightBox,
  ProductNameBox,
}
