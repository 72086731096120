import { useState, useEffect } from 'react'
import FormatUtils from '../../utilities/utils/format'
import Spacer from '../Spacer'
import Typography from '../Typography'
import { TotalSummaryCardProps } from './TotalSummaryCard.model'
import {
  SummaryLeftBox,
  SummaryRightBox,
  TotalSummaryCardContent,
  TotalSummaryCardWrapper,
} from './TotalSummaryCard.styles'

const TotalSummaryCard = ({ items }: TotalSummaryCardProps) => {
  const [totals, setTotals] = useState({ totalHT: 0, totalVAT: 0, total: 0 })

  useEffect(() => {
    const { totalHT, totalVAT } = items.reduce(
      (acc, item) => ({
        totalHT: acc.totalHT + item.totalHT,
        totalVAT: acc.totalVAT + item.totalVAT,
      }),
      { totalHT: 0, totalVAT: 0 },
    )
    const total = totalHT + totalVAT
    setTotals({ totalHT, totalVAT, total })
  }, [items])

  return (
    <TotalSummaryCardWrapper>
      <TotalSummaryCardContent>
        <SummaryLeftBox>
          <Typography.Body>{'Total HT'}</Typography.Body>
          <Typography.Body>{'TVA'}</Typography.Body>
          <Typography.Body bold>{'Total TTC '}</Typography.Body>
        </SummaryLeftBox>
        <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
        <SummaryRightBox>
          <Typography.Body bold>{FormatUtils.formatPrice(totals.totalHT)}</Typography.Body>
          <Typography.Body bold>{FormatUtils.formatPrice(totals.totalVAT)}</Typography.Body>
          <Typography.Body bold>{FormatUtils.formatPrice(totals.total)}</Typography.Body>
        </SummaryRightBox>
      </TotalSummaryCardContent>
    </TotalSummaryCardWrapper>
  )
}

export default TotalSummaryCard
