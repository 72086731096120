import styled from 'styled-components/native'
import { InterfaceColors } from '../../utilities/styling/theme/theme'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  flex-direction: row;
`

const StyledLeftBox = styled.View`
  flex: 5;
`

const StyledRightBox = styled.View`
  flex: 2;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const StatusBox = styled.View<{ backgroundColor: keyof InterfaceColors }>`
  background-color: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
  padding: ${({ theme }) => theme.spacingUnit * 0.5}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.9}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.9}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  min-width: 80px;
  align-items: center;
`

export { StyledCardWrapper, StyledCardContent, StyledLeftBox, StyledRightBox, StatusBox }
