import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import Typography from '../../../components/Typography'
import { OrderHistoryProps } from './OrderHistory.model'
import { StyledHistoryWrapper } from './OrderHistory.styles'
import { Button, Spacer } from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { View } from 'react-native'
import DeviceUtil from '../../../utilities/utils/device'

function OrderHistory({ order }: OrderHistoryProps) {
  const theme = useTheme()
  const [displayHistory, setDisplayHistory] = useState(false)

  const isMobileScreen = DeviceUtil.isMobileApp()

  const renderUpdate = (update: any) => {
    let updateTitle = `Modification le ${FormatUtils.formatDate(update.date, 'Date+StartTime')}`
    /*
    let changes: string[] = []
    if (update.batchChanges && update.batchChanges.length > 0) {
      for (const change of update.batchChanges)
        let batchChange = ''
        if (change?.action) {
          if (change.action === 'created') {
            batchChange = '- Ajout : '
          }

        }
    }
    */

    return (
      <Typography.Body colorName="text-dark-3" style={{ marginLeft: 2 * theme.spacingUnit }}>
        {updateTitle}
      </Typography.Body>
    )
  }

  return (
    <StyledHistoryWrapper isMobile={isMobileScreen}>
      <Typography.Body>Historique de la commande</Typography.Body>
      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

      {!displayHistory ? (
        <View style={{ maxWidth: '150px' }}>
          <Button.Text
            colorName="text-dark-3"
            label={'Voir le détail'}
            onPress={() => setDisplayHistory(true)}
          />
        </View>
      ) : (
        <>
          <Typography.Body colorName="text-dark-3">
            - Créé le {FormatUtils.formatDate(order?.createdDate, 'FullDate+StartTime')}
          </Typography.Body>
          {order?.updatesHistory && (
            <>
              {order?.updatesHistory.carrierUpdates.length > 0 && (
                <>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <Typography.Body colorName="text-dark-3">
                    Modification Super Producteur
                  </Typography.Body>
                  {order?.updatesHistory.carrierUpdates.map((carrierUpdate) => (
                    <>
                      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                      {renderUpdate(carrierUpdate)}
                    </>
                  ))}
                </>
              )}
              {order?.updatesHistory.clientUpdates.length > 0 && (
                <>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <Typography.Body colorName="text-dark-3">
                    Modification {FormatUtils.getLabelFromClientType(1)}
                  </Typography.Body>
                  {order?.updatesHistory.clientUpdates.map((carrierUpdate) => (
                    <>
                      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                      {renderUpdate(carrierUpdate)}
                    </>
                  ))}
                </>
              )}
            </>
          )}
          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          <View style={{ maxWidth: '150px' }}>
            <Button.Text
              colorName="text-dark-3"
              label={'Voir moins'}
              onPress={() => setDisplayHistory(false)}
            />
          </View>
        </>
      )}
    </StyledHistoryWrapper>
  )
}

export default OrderHistory
