import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const BoxWrapper = styled.View`
  width: 100%;
  flex-direction: row;
`
const BoxWrapperProfile = styled.View`
  width: 100%;
`
const TopBoxWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 0.5}px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
const BottomBoxWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 0.5}px;
`
const LeftBoxWrapper = styled.View`
  width: 100%;
  flex: 4;
`

const RightBoxWrapper = styled.View`
  width: 100%;
  flex: 1.5;
`

const LeftBox = styled.View`
  flex-direction: row;
`
const RightBox = styled.View``
const MiddleBox = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 1}px;
  flex: 1;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const DynamicImageWrapper = styled.View`
  width: 60px;
  height: 60px;
`

const GalleryImageWrapper = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 3}px;
  margin-left: ${({ theme }) => theme.spacingUnit * 3}px;
  flex-direction: row;
`

export {
  LeftBoxWrapper,
  RightBoxWrapper,
  MiddleBox,
  RightBox,
  LeftBox,
  StyledCardWrapper,
  BoxWrapper,
  TopBoxWrapper,
  BottomBoxWrapper,
  BoxWrapperProfile,
  StyledButtonWrapper,
  DynamicImageWrapper,
  GalleryImageWrapper,
}
