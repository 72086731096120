import React, { useCallback } from 'react'
import { ActivityIndicator, TouchableWithoutFeedback, View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { stringToKey } from '../../../utilities/helpers'
import Icon, { Icons } from '../../Icon'
import Spacer from '../../Spacer'
import Text from '../../Text'
import { ButtonTextProps } from '../Button.model'
import StyledWrapper from './ButtonText.styles'

const ButtonText = ({
  label,
  onPress,
  disabled,
  style,
  textStyle,
  textTag,
  loading,
  iconName,
  iconProps,
  colorName,
  small,
}: ButtonTextProps) => {
  const theme = useTheme()
  const textColor = !disabled ? colorName || 'color-primary' : 'text-dark-1'
  const accessibilityLabel = label ? `button-text-${stringToKey(label)}` : 'button-text'

  const renderLoading = useCallback(() => {
    const color = theme.colors[textColor ?? 'text-light-1']
    return loading ? (
      <>
        <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
        <ActivityIndicator size="small" color={color} />
      </>
    ) : null
  }, [loading, textColor, theme.colors])

  const handlePress = () => {
    if (onPress && !disabled) {
      onPress()
    }
  }

  return (
    <TouchableWithoutFeedback
      accessible
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="button"
      onPress={handlePress}
    >
      <StyledWrapper style={style}>
        {iconName ? (
          <>
            <Icon
              type={Icons.Ionicons}
              name={iconName}
              color={theme.colors[textColor]}
              {...iconProps}
            />
            <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
          </>
        ) : null}
        <Text
          tag={textTag}
          bold
          colorName={textColor}
          style={[textStyle]}
          onPress={handlePress}
          numberOfLines={1}
        >
          {label}
        </Text>
        {renderLoading()}
      </StyledWrapper>
    </TouchableWithoutFeedback>
  )
}

ButtonText.defaultProps = {
  disabled: false,
  iconName: null,
  iconProps: {},
}

export default ButtonText
