import { useState } from 'react'
import {
  Button,
  Card,
  Col,
  Icon,
  Icons,
  Row,
  SectionTitle,
  Spacer,
  TextInput,
  Typography,
} from '../../../../../../components'
import { AddProducerTourInvite, Producer } from '../../../../../../domain'
import GeneralUtils from '../../../../../../utilities/utils/general'
import ProducerCarrierService from '../../../../../../services/carrier/producer'
import useAuthenticationContext from '../../../../../../utilities/hook/useAuthenticationContext'
import { InviteProducerFormProps } from './InviteProducerForm.model'
import InfoBox from '../../../../../../components/InfoBox'
import {
  BackToListWrapper,
  LeftCardContentWrapper,
  StyledButtonWrapper,
  StyledCardContentWrapper,
  StyledCardWrapper,
  StyledLinkButtonWrapper,
} from './InviteProducerForm.styles'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'

const InviteProducerForm = ({ group, onInviteProducer, onGoBack }: InviteProducerFormProps) => {
  const { getAccessInfos } = useAuthenticationContext()
  const theme = useTheme()

  const [newProducerToInvite, setNewProducerToInvite] = useState<AddProducerTourInvite>({
    label: '',
    email: '',
    firstName: '',
    lastName: '',
  })
  const [emailForm, setEmailForm] = useState<boolean>(true)
  const [producerMailRequest, setProducerMailRequest] = useState<string>('')
  const [foundProducer, setFoundProducer] = useState<Producer>()
  const [newProducerToInviteForm, setNewProducerToInviteForm] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newProducerToInvite)) return null

    const producerTemp = Object.assign({}, newProducerToInvite)

    producerTemp[param as keyof typeof newProducerToInvite] = value
    setNewProducerToInvite(producerTemp)
  }

  const infoBoxContent = {
    title: 'Que recevront les producteurs ? ',
    content:
      'Les producteurs que vous invitez reçoivent un mail afin de créer leur compte. Ils sont automatiquement affiliés à votre groupe et à la tournée de livraison',
  }

  const onRequestSearchMail = async () => {
    setErrorMessage('')
    if (producerMailRequest && !GeneralUtils.isEmail(producerMailRequest)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }
    let foundExistingProducer

    try {
      foundExistingProducer = await ProducerCarrierService.getOneProducer(
        getAccessInfos().carrierId,
        undefined,
        producerMailRequest,
      )

      if (
        foundExistingProducer &&
        foundExistingProducer.producerGroups?.find((pg) => pg.group?.id === group?.id)
      ) {
        setErrorMessage(`Un producteur avec cet email existe déjà dans ce groupe`)
        setIsLoading(false)
        return
      }
      if (foundExistingProducer) {
        setFoundProducer(foundExistingProducer)
      }
    } catch (error) {
      console.error(error)
    }

    if (!foundExistingProducer) {
      setValue(producerMailRequest, 'email')
      setEmailForm(false)
      setNewProducerToInviteForm(true)
    }
  }

  const onValidateProducer = async (foundProducer: Producer) => {
    setErrorMessage('')
    if (!group?.id) {
      setErrorMessage('Erreur de chargement du groupe')
      return
    }
    const foundProducerFormatted = {
      id: foundProducer.id,
      label: foundProducer.label,
      email: foundProducer.mail || '',
      firstName: foundProducer.user?.firstName || '',
      lastName: foundProducer.user?.lastName || '',
    }
    if (foundProducer) {
      try {
        setIsLoading(true)
        onInviteProducer(foundProducerFormatted)
      } catch (error) {
        setErrorMessage('Un problème est survenu lors de la liaison du producteur')
        console.error(error)
      }
    }

    return foundProducerFormatted
  }

  const onValidateNewProducer = (newProducerToInvite: AddProducerTourInvite) => {
    onInviteProducer(newProducerToInvite)
  }

  const handleBackToList = () => {
    setEmailForm(true)
    setNewProducerToInviteForm(false)
    setFoundProducer(undefined)
    setProducerMailRequest('')
  }

  const handleGoBackToAddProducersTour = () => {
    onGoBack()
  }

  const isDisabledButtonForm = () => {
    if (
      !newProducerToInvite.label ||
      !newProducerToInvite.email ||
      !newProducerToInvite.firstName ||
      !newProducerToInvite.lastName
    ) {
      return true
    }
    return false
  }

  return (
    <>
      <BackToListWrapper onPress={handleBackToList}>
        <Icon
          type={Icons.Ionicons}
          name="chevron-back-outline"
          color={theme.colors['color-primary']}
        />
        <Button.Text label={'Revenir en arrière'} onPress={handleGoBackToAddProducersTour} />
      </BackToListWrapper>
      {emailForm ? (
        <>
          <SectionTitle title="Saisir un email" />
          <Row
            rowStyles={{
              flexDirection: !foundProducer ? 'row' : 'column',
              alignItems: !foundProducer ? 'center' : 'flex-start',
            }}
          >
            <Col xs={9}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <TextInput
                value={producerMailRequest}
                label="Email"
                field="text"
                onChangeText={(text) => {
                  setProducerMailRequest(text)
                  setFoundProducer(undefined)
                }}
              />
              {errorMessage ? (
                <>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <View style={{ width: '100%' }}>
                    <Typography.Body colorName="color-danger" align="center">
                      {errorMessage}
                    </Typography.Body>
                  </View>
                </>
              ) : null}
            </Col>
            {foundProducer && (
              <>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body colorName="color-grey">
                  Nous avons trouvé le(s) compte(s) suivant(s) avec cette adresse email
                </Typography.Body>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <SectionTitle title="Producteur" />
                <Row>
                  <Col xs={12}>
                    <StyledCardWrapper>
                      <Card>
                        <StyledCardContentWrapper>
                          <LeftCardContentWrapper>
                            <Typography.CardTitle>{foundProducer?.label}</Typography.CardTitle>
                            <Typography.BodySmall>{foundProducer?.owners}</Typography.BodySmall>
                            <Typography.BodySmall>
                              {foundProducer?.producerGroups
                                ? foundProducer.producerGroups
                                    .map((pg) => pg.group?.label)
                                    .join(' & ')
                                : null}
                            </Typography.BodySmall>
                          </LeftCardContentWrapper>
                          <StyledLinkButtonWrapper>
                            <Button
                              label={'Lier'}
                              onPress={() => onValidateProducer(foundProducer)}
                              loading={isLoading}
                            />
                          </StyledLinkButtonWrapper>
                        </StyledCardContentWrapper>
                      </Card>
                    </StyledCardWrapper>
                  </Col>
                </Row>
              </>
            )}
            <Col xs={3}>
              <StyledButtonWrapper>
                {!foundProducer && (
                  <Button
                    label="Vérifier"
                    onPress={() => onRequestSearchMail()}
                    loading={isLoading}
                    disabled={!producerMailRequest}
                  />
                )}
              </StyledButtonWrapper>
            </Col>
          </Row>
        </>
      ) : null}
      {newProducerToInviteForm ? (
        <>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <InfoBox title={infoBoxContent.title} content={infoBoxContent.content} />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <TextInput
            value={newProducerToInvite.label}
            label="Nom de l'exploitation"
            field="text"
            onChangeText={(text) => setValue(text, 'label')}
          />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <TextInput
            value={newProducerToInvite.email}
            label="Email"
            field="text"
            onChangeText={(text) => setValue(text, 'email')}
          />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <TextInput
            value={newProducerToInvite.firstName}
            label="Prénom"
            field="text"
            onChangeText={(text) => setValue(text, 'firstName')}
          />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <TextInput
            value={newProducerToInvite.lastName}
            label="Nom"
            field="text"
            onChangeText={(text) => setValue(text, 'lastName')}
          />
          <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
          <StyledButtonWrapper>
            <Button
              label="Envoyer une invitation"
              onPress={() => onValidateNewProducer(newProducerToInvite)}
              disabled={isDisabledButtonForm()}
            />
          </StyledButtonWrapper>
        </>
      ) : null}
    </>
  )
}

export default InviteProducerForm
