import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const UpdateProductContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
`
const CardContentWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`
const StyledBackButtonWrapper = styled.TouchableOpacity`
  width: 100%;
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`

const StyledImage = styled.Image`
  width: ${({ theme }) => theme.spacingUnit * 20}px;
  max-width: 90%;
  height: ${({ theme }) => theme.spacingUnit * 20}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
`

const StyledImageBox = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const BackToListWrapper = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`

export {
  UpdateProductContentWrapper,
  StyledButtonWrapper,
  StyledImageBox,
  StyledImage,
  BackToListWrapper,
  StyledBackButtonWrapper,
  CardContentWrapper,
}
