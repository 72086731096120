import useAuthenticationContext from '../utilities/hook/useAuthenticationContext'
import StorageUtil from '../utilities/storage/storage'
import GeneralUtils from '../utilities/utils/general'
import AxiosService from './axios.service'

/*
globalThis.URL = URL as any
globalThis.URLSearchParams = URLSearchParams as any
*/
const { axiosInstance } = AxiosService

const onResponseError = async (err: any): Promise<any> => {
  if (err?.response?.status === 401) {
    await StorageUtil.deleteItem('authenticationData')
    return false
  }
  return err?.response?.data
}

const onResponseSuccess = (response: any): any => {
  return response.data
}

const getAuthentificationToken = async (): Promise<any> => {
  const authenticationDataFromSecureStore = await StorageUtil.getItem('authenticationData')

  if (authenticationDataFromSecureStore) {
    const authenticationDataFormatted: any = JSON.parse(authenticationDataFromSecureStore)
    return authenticationDataFormatted.token
  }
  return ''
}

const cleanParams = (data: any) => {
  if (!data) return {}
  const cleanData = Object.entries(data)
    .filter(([key, value]) => value !== undefined)
    .reduce((obj: any, [key, value]) => {
      obj[key] = value
      return obj
    }, {})

  return cleanData
}

const post = async (route: string, body: any) => {
  const token = await getAuthentificationToken()
  if (!GeneralUtils.isEmptyStr(token)) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
  return axiosInstance
    .post(`${route}`, body)
    .then(onResponseSuccess)
    .catch((err: any) => {
      throw onResponseError(err)
    })
}

const patch = async (route: string, body: any) => {
  const token = await getAuthentificationToken()
  if (!GeneralUtils.isEmptyStr(token)) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
  return axiosInstance
    .patch(`${route}`, body)
    .then(onResponseSuccess)
    .catch((err: any) => {
      throw onResponseError(err)
    })
}

const remove = async (route: string) => {
  const token = await getAuthentificationToken()
  if (!GeneralUtils.isEmptyStr(token)) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
  return axiosInstance
    .delete(`${route}`)
    .then(onResponseSuccess)
    .catch((err: any) => {
      throw onResponseError(err)
    })
}

const get = async (route: string, params?: any) => {
  const token = await getAuthentificationToken()
  if (!GeneralUtils.isEmptyStr(token)) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  return axiosInstance
    .get(`${route}`, { params: cleanParams(params) })
    .then(onResponseSuccess)
    .catch((err: any) => {
      throw onResponseError(err)
    })
}

const ApiService = {
  post,
  patch,
  get,
  remove,
}

export default ApiService
