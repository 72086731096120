import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.2}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.2}px;
  // flex-direction: row;
`

const StyledLeftBox = styled.View`
  flex: 4;
`

const StyledCenterBox = styled.View`
  flex: 3;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.5}px;
`

const StyledRightBox = styled.View`
  flex: 3;
  align-items: center;
`

const StyledRightButtonBox = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`

export {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledCenterBox,
  StyledRightBox,
  StyledRightButtonBox,
}
