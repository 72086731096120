import React, { useState, useCallback, useMemo } from 'react'
import DateFilter from '../DateFilter'

export interface MetricsFilters {
  start: Date
  end: Date
}

interface ExtendedDateFilterProps {
  onFilterChange: (filters: MetricsFilters) => void
}

const ExtendedDateFilter = ({ onFilterChange }: ExtendedDateFilterProps) => {
  const defaultFilters = useMemo(() => {
    const currentDate = new Date()
    return {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59),
    }
  }, [])

  const [metricsFilters, setMetricsFilters] = useState<MetricsFilters>(defaultFilters)

  const onClickMetricsChangeDate = useCallback(
    (value?: number, exactDate?: Date, paramDate?: string) => {
      let tempMetricsFilter = { ...metricsFilters }

      if (exactDate && paramDate) {
        if (paramDate === 'before') {
          tempMetricsFilter.start = exactDate
        } else if (paramDate === 'after') {
          tempMetricsFilter.end = exactDate
        }
      } else if (value !== undefined) {
        let currentMonthNew = metricsFilters.start.getMonth()
        let currentYear = metricsFilters.start.getFullYear()
        currentMonthNew = currentMonthNew + value

        if (currentMonthNew < 0) {
          currentMonthNew = 11
          currentYear = currentYear - 1
        }

        if (currentMonthNew > 11) {
          currentMonthNew = 0
          currentYear = currentYear + 1
        }

        tempMetricsFilter.start = new Date(currentYear, currentMonthNew, 1, 0, 0, 0)
        tempMetricsFilter.end = new Date(currentYear, currentMonthNew + 1, 0, 23, 59, 59)
      }

      setMetricsFilters(tempMetricsFilter)
      onFilterChange(tempMetricsFilter)
    },
    [metricsFilters, onFilterChange],
  )

  return (
    <DateFilter
      onClickMetricsChangeDate={onClickMetricsChangeDate}
      metricsFilters={metricsFilters}
    />
  )
}

export default ExtendedDateFilter
