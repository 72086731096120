import styled from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  /* margin-top: ${({ theme }) => theme.spacingUnit}px; */
`

const StyledCardWrapperUnclickable = styled.View`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  /* margin-top: ${({ theme }) => theme.spacingUnit}px; */
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 1}px;
  flex-direction: row;
`

const StyledSubCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 1}px;
  flex-direction: row;
`

const StyledSubCard = styled.TouchableOpacity`
  /* align-items: center; */
  margin-left: 3%;
  width: 95%;
`

const StyledSubCardLeftContent = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacingUnit * 0.8}px;
`

const StyledSubCardRightContent = styled.View`
  flex: 1;
  align-items: center;
  justify-content: end;
  flex-direction: row;
`

const StyledLeftBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 1.3}px;
  width: ${({ theme }) => theme.spacingUnit * 4}px;
  height: ${({ theme }) => theme.spacingUnit * 4}px;
  border: ${({ theme }) => theme.spacingUnit * 0.1}px solid
    ${({ theme }) => theme.colors['color-grey']};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  align-self: center;
`

const StyledContentBox = styled.View`
  flex-direction: row;
  justify-content: space-between;
  flex: 3;
`

const StyledMiddleBox = styled.View`
  width: 65%;
  justify-content: space-around;
`
const StyledRightBox = styled.View`
  align-items: flex-end;
  justify-content: end;
`

const StyledRightBoxDetails = styled.View`
  flex-direction: row;
`

export {
  StyledCardWrapper,
  StyledCardContent,
  StyledMiddleBox,
  StyledLeftBox,
  StyledContentBox,
  StyledRightBox,
  StyledSubCardContent,
  StyledSubCard,
  StyledSubCardLeftContent,
  StyledSubCardRightContent,
  StyledRightBoxDetails,
  StyledCardWrapperUnclickable,
}
