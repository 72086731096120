import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Card,
  Dropdown,
  PageTitle,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import {
  StyledButtonWrapper,
  StyledCardWrapper,
  SummaryLeftBox,
  SummaryRightBox,
  TotalSummaryCard,
  TotalSummaryCardContent,
} from './CarrierBillingPayment.styles'
import { useQuery } from '@tanstack/react-query'
import { StackNavigationProp } from '@react-navigation/stack'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import CarrierFeesCard from '../../../components/CarrierFeesCard'
import { Payment } from '../../../domain/Payment'
import PaymentCarrierService from '../../../services/carrier/payment'
import { PAYMENT_METHODS, PaymentStatusEnum } from '../../../../enums/payment'
import { queryClient } from '../../../utilities/queryClient'
import FormatUtils from '../../../utilities/utils/format'
import InvoiceService from '../../../services/invoice'
import { CarrierFeesRecord } from '../../../domain/CarrierFees'
import { Invoice } from '../../../domain/Invoice'
import InfoCard from '../../../components/InfoCard'

const CarrierBillingPaymentScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()

  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierBillingPayment'>>()

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any | undefined>()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const { data: invoice } = InvoiceService.useGetOneInvoice({
    invoiceId: route.params?.invoiceId,
  }).query()

  const onChangePaymentMethod = (value: any) => {
    setSelectedPaymentMethod(value)
  }
  const handleConfirmPayment = (paymentId: string) => {
    if (!selectedPaymentMethod) {
      setErrorMessage('Veuillez sélectionner une méthode de paiement')
      return
    }
    PaymentCarrierService.update(paymentId, {
      paymentMethod: selectedPaymentMethod.value,
    }).then(() => {
      setErrorMessage('')
      queryClient.invalidateQueries(['sf_billing_invoice', route.params?.invoiceId])
      queryClient.invalidateQueries(['sf_billing_invoices', invoice?.issuer.id])
      navigation.navigate('CarrierBillingProducers', { carrierId: getAccessInfos().carrierId })
    })
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title={`Paiement #${FormatUtils.formatId(invoice?.payment?.id)}`} />
        <InfoCard leftIcon="link">
          <Typography.BodySmall colorName="text-dark-3">
            {`${invoice?.issuer.legalName} facture les frais logistique à`}{' '}
          </Typography.BodySmall>
          <Typography.BodySmall bold>{invoice?.recipient.legalName}</Typography.BodySmall>
        </InfoCard>{' '}
        <SectionTitle title={`Tournées`} />
        {invoice?.carrierFeesRecords?.map((record) => (
          <CarrierFeesCard carrierFeesRecord={record} />
        ))}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <TotalSummaryCard>
          <TotalSummaryCardContent>
            <SummaryLeftBox>
              <Typography.Body>{'Total HT '}</Typography.Body>
              <Typography.Body>{'Total TVA '}</Typography.Body>
              <Typography.Body bold>{'Total TTC '}</Typography.Body>
            </SummaryLeftBox>
            <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
            <SummaryRightBox>
              <Typography.Body bold>
                {FormatUtils.formatPrice(invoice?.totalAmounts.totalHT)}
              </Typography.Body>
              <Typography.Body bold>
                {FormatUtils.formatPrice(invoice?.totalAmounts.totalVAT20)}
              </Typography.Body>
              <Typography.Body bold>
                {FormatUtils.formatPrice(invoice?.totalAmounts.totalTTC)}
              </Typography.Body>
            </SummaryRightBox>
          </TotalSummaryCardContent>
        </TotalSummaryCard>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        {invoice?.payment?.status === PaymentStatusEnum.PENDING &&
          invoice.carrierFeesRecords[0].carrier.id === getAccessInfos().carrierId && (
            <>
              {invoice?.customIdentifier && (
                <Typography.BodySmall colorName="text-dark-3">
                  Code facture: {invoice?.customIdentifier}
                </Typography.BodySmall>
              )}
              <Dropdown
                label={'Méthode de paiement'}
                onChange={onChangePaymentMethod}
                multiple={false}
                optionsDefault={PAYMENT_METHODS.map((paymentMethod) => ({
                  label: paymentMethod.label,
                  value: paymentMethod.value,
                }))}
                zIndex={1000}
                zIndexInverse={4000}
                itemKey="paymentMethod"
              />

              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              {errorMessage ? (
                <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
              ) : null}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <StyledButtonWrapper>
                <Button
                  label={'Confirmer réception du paiement'}
                  onPress={() => handleConfirmPayment(invoice?.payment?.id)}
                />
              </StyledButtonWrapper>
            </>
          )}
        {invoice?.payment.status === PaymentStatusEnum.COMPLETED && (
          <StyledCardWrapper>
            <Card>
              <Typography.BodySmall colorName="text-dark-3" align="center">
                {`Payé le ${FormatUtils.formatDate(invoice?.payment.updatedDate, 'Date')} via ${
                  PAYMENT_METHODS.find((method) => method.value === invoice?.payment.paymentMethod)
                    ?.label
                }`}
              </Typography.BodySmall>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.2} />
              {invoice?.customIdentifier && (
                <Typography.BodySmall colorName="text-dark-3" align="center">
                  {`Code facture: ${invoice?.customIdentifier}`}
                </Typography.BodySmall>
              )}
            </Card>
          </StyledCardWrapper>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierBillingPaymentScreen
