import { useState } from 'react'
import Card from '../Card'
import Icon, { Icons } from '../Icon'
import Typography from '../Typography'
import CardTitle from '../Typography/CardTitle'
import { CopyPasteCardProps } from './CopyPasteCard.model'
import { CardContent, CardLeftContent, CardRightContent } from './CopyPasteCard.styles'
import Spacer from '../Spacer'

const CopyPasteCard = ({ data, onPress }: CopyPasteCardProps) => {
  const [isCopied, setIsCopied] = useState(false)

  return (
    <Card isLowMargin>
      <CardContent>
        <CardLeftContent>
          <CardTitle bold>{data.label}</CardTitle>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
          <Typography.Body colorName="color-primary">{data.value}</Typography.Body>
        </CardLeftContent>
        <CardRightContent>
          <Icon
            type={Icons.Ionicons}
            name={isCopied ? 'checkmark' : 'copy-outline'}
            size={20}
            color={isCopied ? 'color-primary' : 'text-dark-3'}
            onPress={() => {
              setIsCopied(true)
              onPress(isCopied)
            }}
          />
        </CardRightContent>
      </CardContent>
    </Card>
  )
}

export default CopyPasteCard
