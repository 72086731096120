import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const AddProductContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`
const StyledBackButtonWrapper = styled.TouchableOpacity`
  width: 100%;
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`
const CardContentWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const BackToListWrapper = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`

const StyledImageCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const BoxWrapperImage = styled.View`
  width: 100%;
`

const DynamicImageWrapper = styled.View`
  width: 90px;
  max-width: 90%;
  height: 90px;
`

const TopBoxWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 0.5}px;
  flex-direction: row;
`

const LeftBox = styled.View`
  flex: 1.25;
`

const RightBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 1.5}px;
  flex: 3.5;
`

export {
  AddProductContentWrapper,
  StyledButtonWrapper,
  BackToListWrapper,
  StyledBackButtonWrapper,
  CardContentWrapper,
  StyledImageCardWrapper,
  BoxWrapperImage,
  DynamicImageWrapper,
  TopBoxWrapper,
  LeftBox,
  RightBox,
}
