import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const DashboardContentWrapper = styled.View`
  flex: 1;
  width: 92%;
`

const StyledButtonWrapper = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 2}px;
`
const SubTotalWrapper = styled.View`
  width: 100%;
  max-width: 650px;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
`

export { DashboardContentWrapper, StyledButtonWrapper, SubTotalWrapper }
