import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTheme } from 'styled-components/native'
import { Button, SectionTitle, Spacer, Typography } from '../../../components'
import CardList from '../../../components/CardList'
import PageTitle from '../../../components/PageTitle'
import { Pagination, Tour } from '../../../domain'
import i18n from '../../../i18n'
import TourCard from '../../../modules/Tour/TourCard'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../services/carrier/tour'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import CardListPaginated from '../../../components/CardListPaginated'
import { formatTourFillingInfo } from '../../../utilities/selector/formatTourFillingInfo'

const CarrierToursScreen = () => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [pageSizePreviousTours, setPageSizePreviousTours] = useState(4)
  const [pageNumberPreviousTours, setPageNumberPreviousTours] = useState(1)

  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: nextTours,
    refetch: refetchNextTours,
    isInitialLoading: nextToursLoading,
  } = useQuery<{ data: Tour[]; pagination: Pagination }, Error>(
    ['sf_next_tours'],
    () => TourCarrierService.getAllTours(getAccessInfos().carrierId, true, undefined, 100),
    {
      keepPreviousData: true,
      select: (data) => {
        data.data = data.data.map(formatTourFillingInfo)
        return data
      },
    },
  )

  const {
    data: previousTours,
    refetch: refetchPreviousTours,
    isLoading: previousToursLoading,
  } = useQuery<{ data: Tour[]; pagination: Pagination }, Error>(
    ['sf_previous_tours'],
    () =>
      TourCarrierService.getAllTours(
        getAccessInfos().carrierId,
        undefined,
        true,
        pageSizePreviousTours,
        pageNumberPreviousTours,
      ),
    {
      keepPreviousData: true,
    },
  )

  const OnClickAddTour = () => {
    navigation.navigate('CarrierAddTour', {})
  }

  const OnClickTour = (tour: Tour) => {
    navigation.navigate('CarrierTour', { id: tour.id })
  }

  const onChangePagePreviousTours = async (pageNumber: number) => {
    await setPageNumberPreviousTours(pageNumber)
    refetchPreviousTours()
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle
          title={FormatUtils.capitalize(i18n.t('pageTitleTours'))}
          buttonRight={
            <Button
              small
              label={FormatUtils.capitalize(i18n.t('add'))}
              onPress={() => OnClickAddTour()}
            />
          }
        />

        <SectionTitle
          title={`${FormatUtils.capitalize(i18n.t('nextTours'))} (${
            nextTours?.pagination?.totalCount
          })`}
        />
        <CardList
          data={nextTours?.data}
          element={function (tour: any) {
            return (
              <TourCard
                key={tour.id}
                tour={tour}
                onClick={OnClickTour}
                isCarrier
                fromScreen="CarrierTours"
              />
            )
          }}
          isLoading={nextToursLoading}
          emptyMessage="Aucune tournée à venir"
        />

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <Typography.SectionTitle>
          {FormatUtils.capitalize(i18n.t('finishedTours'))} ({previousTours?.pagination?.totalCount}
          )
        </Typography.SectionTitle>
        <CardListPaginated
          data={previousTours?.data}
          pagination={previousTours?.pagination}
          element={function (tour: any) {
            return (
              <TourCard
                key={tour.id}
                tour={tour}
                onClick={OnClickTour}
                isCarrier
                fromScreen="CarrierTours"
              />
            )
          }}
          isLoading={previousToursLoading}
          emptyMessage="Aucune tournée passée"
          onChangePage={onChangePagePreviousTours}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierToursScreen
