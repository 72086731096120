import { QueryObserverOptions } from "@tanstack/react-query"
import { ProductLabel } from "../../../domain"
import ApiService from "../../api.service"

const getAllProductLabels = async (): Promise<ProductLabel[]> => {
    return await ApiService.get(`/product-label`, {})
}

const ProductService = {
    getAllProductLabels,
}

export const getProductLabelsQO = (): QueryObserverOptions<ProductLabel[]> => (
    {
        queryKey: ['product_labels'],
        queryFn: getAllProductLabels,
    }

)

export default ProductService