import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { useTheme } from 'styled-components/native'
import {
  CardTitleWrapper,
  StyledButtonWrapper,
  CertificatesWrapper,
} from './ProducerCertificates.styles'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { Document } from '../../../domain'
import { ContentWrapper } from '../../../utilities/styling/wrappers'
import { Button, PageTitle, Spacer, Typography } from '../../../components'
import { useQuery } from '@tanstack/react-query'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import Loader from '../../../components/Loader'
import DocumentProducerCard from './components/ProducerCertificateCard'
import DocumentProducerService from '../../../services/producer/document'
import { View } from 'react-native'

const ProducerCertificatesScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const {
    data: producerDocuments,
    refetch: refetchProducerDocuments,
    isInitialLoading: toursLoading,
  } = useQuery<Document[], Error>(
    ['p_documents'],
    () => DocumentProducerService.getAllDocumentsProducer(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  if (toursLoading) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ContentWrapper noPaddingLeft>
      <CertificatesWrapper>
        <CardTitleWrapper>
          <PageTitle
            title="Vos certificats"
            buttonRight={
              <StyledButtonWrapper>
                <Button
                  label="Ajouter"
                  onPress={() =>
                    navigation.navigate('ProfileProducerNav', {
                      screen: 'ProducerAddCertificate',
                      params: { documentId: '9309fdc6-eef2-4e0e-b711-376f7dbc04a7' },
                    })
                  }
                />
              </StyledButtonWrapper>
            }
          />
        </CardTitleWrapper>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        {producerDocuments && producerDocuments.length > 0 ? (
          producerDocuments.map((document) => (
            <DocumentProducerCard key={document.id} document={document} />
          ))
        ) : (
          <View style={{ alignItems: 'center' }}>
            <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall>Aucun document</Typography.BodySmall>
          </View>
        )}
      </CertificatesWrapper>
    </ContentWrapper>
  )
}

export default ProducerCertificatesScreen
