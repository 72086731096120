import React from 'react'
import { useTheme } from 'styled-components/native'
import { CarrierTourProducersTopBoxProps } from './CarrierTourProducersTopBox.model'
import { CardParam } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { StyledTopBox } from './CarrierTourProducersTopBox.styles'

function CarrierTourProducersTopBox({
  tour,
  onClickDeliveryNote,
  deliveryNotes,
}: CarrierTourProducersTopBoxProps) {
  const theme = useTheme()

  return (
    <>
      <StyledTopBox>
        <CardParam 
          label={'Colis'} 
          value={`${(tour.nbCrates || '-')}\n(${(tour.totalWeight || '-')}kg)`} 
          flex={5} 
          isStart 
        />
        <CardParam
          label={'Volume utilisé'}
          value={`${FormatUtils.formatPercentage(
            (tour.usedCapacity || 0) / (tour.capacity || 1),
          )} remplis\n(${FormatUtils.formatVolume(
            tour.usedCapacity,
            true,
          )} sur ${FormatUtils.formatVolume(tour.capacity, true)})`}
          flex={10}
          isStart
        />
        <CardParam
          label={'Bons de livraison'}
          value={(deliveryNotes || []).length}
          flex={6}
          isStart
          icon="open-outline"
          onClick={() => onClickDeliveryNote()}
        />
      </StyledTopBox>
    </>
  )
}

export default CarrierTourProducersTopBox
