import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { TouchableHighlight, View } from 'react-native'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Icon,
  Icons,
  Spacer,
  TextInput,
  TouchableCard,
  Typography,
} from '../../../components'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import FormatUtils from '../../../utilities/utils/format'
import { PriceStatementCardProps } from './PriceStatementCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledCenterBox,
  StyledRightBox,
  StyledRightButtonBox,
} from './PriceStatementCard.styles'
import { AddPriceStatement, Batch } from '../../../domain'
import PriceReportingCarrierService from '../../../services/carrier/priceReporting'
import Loader from '../../../components/Loader'
import BatchUtil from '../../../utilities/utils/batch'

const PriceStatementCard = ({
  priceReporting,
  priceStatement,
  productTypePropositions,
  onChangePriceStatement,
}: PriceStatementCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [hasChanged, setHasChanged] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showBatchs, setShowBatchs] = useState<boolean>(false)

  const defaultPriceStatement = priceStatement?.id
    ? priceStatement
    : {
        id: undefined,
        minPrice: undefined,
        maxPrice: undefined,
        minPriceWholsalers: undefined,
        maxPriceWholsalers: undefined,
        mesureType: productTypePropositions?.mesureType,
        packing: 1,
        productType: productTypePropositions,
      }

  const [newPriceStatement, setNewPriceStatement] = useState<AddPriceStatement | undefined>(
    defaultPriceStatement,
  )

  useEffect(() => {
    if (priceStatement?.id) {
      setNewPriceStatement(priceStatement)
    }
  }, [priceStatement])

  const onChangeInput = (field: string, value: any) => {
    if (!newPriceStatement || (!field && !(field in newPriceStatement))) return

    const tempNewPriceStatement = Object.assign({}, newPriceStatement)

    tempNewPriceStatement[field as keyof typeof newPriceStatement] = value
    setNewPriceStatement(tempNewPriceStatement)
    setHasChanged(true)
  }

  const onBlurInput = (field: string): void => {}

  const onValidateChanges = async () => {
    if (!hasChanged) return
    setErrorMessage('')
    if (
      !newPriceStatement?.minPrice ||
      FormatUtils.stringToFloat(newPriceStatement?.minPrice.toString() || '') <= 0
    ) {
      setErrorMessage('Le prix ne peut pas être inférieur à 0')
      return
    }
    setIsLoading(true)
    const priceStatementTemp = {
      minPrice: FormatUtils.stringToFloat(newPriceStatement?.minPrice?.toString() || ''),
      maxPrice: undefined,
      minPriceWholsalers: newPriceStatement?.minPriceWholsalers
        ? FormatUtils.stringToFloat(newPriceStatement?.minPriceWholsalers?.toString() || '')
        : undefined,
      maxPriceWholsalers: undefined,
      mesureType: newPriceStatement?.mesureType,
      packing: newPriceStatement.packing || 1,
      productTypeId: newPriceStatement.productType?.id,
      hidden: false,
    }

    let updatedPriceStatementTemp = null
    if (newPriceStatement?.id) {
      delete priceStatementTemp['productTypeId']
      delete priceStatementTemp['mesureType']
      updatedPriceStatementTemp = await PriceReportingCarrierService.updatePriceStatement(
        priceReporting.id,
        newPriceStatement?.id,
        priceStatementTemp,
      )
    } else {
      updatedPriceStatementTemp = await PriceReportingCarrierService.createPriceStatement(
        priceReporting.id,
        priceStatementTemp,
      )
    }

    if (updatedPriceStatementTemp && updatedPriceStatementTemp.id) {
      await onChangePriceStatement()
      setHasChanged(false)
    } else {
      setErrorMessage('Un problème est survenu lors de la sauvegarde')
    }
    setIsLoading(false)
  }

  const onHide = async () => {
    if (hasChanged || !newPriceStatement) return

    setIsLoading(true)

    const priceStatementTemp = {
      hidden: true,
      minPrice: 0,
      mesureType: newPriceStatement?.mesureType,
      packing: newPriceStatement.packing || 1,
      productTypeId: newPriceStatement.productType?.id,
    }

    const updatedPriceStatementTemp = await PriceReportingCarrierService.createPriceStatement(
      priceReporting.id,
      priceStatementTemp,
    )

    if (updatedPriceStatementTemp && updatedPriceStatementTemp.id) {
      await onChangePriceStatement()
      setHasChanged(false)
    } else {
      setErrorMessage('Un problème est survenu de la sauvegarde')
    }
    setIsLoading(false)
  }

  const onDelete = async () => {
    if (!priceStatement?.id) return
    setErrorMessage('')
    setIsLoading(true)

    const deletedPriceStatementTemp = await PriceReportingCarrierService.removePriceStatement(
      priceReporting.id,
      priceStatement.id,
    )

    if (deletedPriceStatementTemp && deletedPriceStatementTemp.id) {
      await onChangePriceStatement()
      setHasChanged(false)
    } else {
      setErrorMessage('Un problème est survenu lors de la suppression')
    }
    setIsLoading(false)
  }

  const productType =
    priceStatement && priceStatement?.productType
      ? priceStatement.productType
      : productTypePropositions
  const mesureType =
    priceStatement && priceStatement?.mesureType
      ? priceStatement?.mesureType
      : productType?.mesureType

  const dangerMinPrice =
    (FormatUtils.stringToFloat(newPriceStatement?.minPrice?.toString() || '') || 0) >
    (productTypePropositions?.minPrice || 100)

  const dangerMinPriceWholsaler =
    (FormatUtils.stringToFloat(newPriceStatement?.minPriceWholsalers?.toString() || '') || 0) >
    (productTypePropositions?.minPriceWholsalers || 100)

  return (
    <StyledCardWrapper onPress={() => null} activeOpacity={1}>
      <TouchableCard>
        <StyledCardContent>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <StyledLeftBox>
              <Typography.Body bold>{productType?.label}</Typography.Body>
              <Typography.Body>{FormatUtils.formatUnity(mesureType)}</Typography.Body>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              {!hasChanged && !isLoading && priceReporting?.id && priceStatement?.id && (
                <TouchableHighlight onPress={() => onDelete()}>
                  <View>
                    <Icon
                      type={Icons.Ionicons}
                      name="trash"
                      size={theme.spacingUnit * 1.8}
                      color={theme.colors['color-primary']}
                    />
                  </View>
                </TouchableHighlight>
              )}
              {errorMessage ? (
                <Typography.BodyExtraSmall colorName="color-danger">
                  {errorMessage}
                </Typography.BodyExtraSmall>
              ) : null}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              {productTypePropositions?.batchs && productTypePropositions?.batchs?.length > 0 && (
                <Button.Text
                  label={showBatchs ? 'Voir moins' : 'Voir les lots'}
                  onPress={() => setShowBatchs((prevShowBatchs) => !prevShowBatchs)}
                  style={{ justifyContent: 'flex-start' }}
                  colorName="text-dark-3"
                />
              )}
            </StyledLeftBox>
            <StyledCenterBox>
              <Typography.BodyExtraSmall colorName="text-dark-3">
                Prix Grossiste
              </Typography.BodyExtraSmall>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.3} />
              <View style={{ maxWidth: '70px' }}>
                <TextInput
                  value={(newPriceStatement?.minPriceWholsalers || '').toString()}
                  label=""
                  field="text"
                  onChangeText={(text) => onChangeInput('minPriceWholsalers', text)}
                  onBlurCallBack={() => onBlurInput('')}
                  onKeyPressCallBack={() => onValidateChanges()}
                  small
                />
              </View>

              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.4} />
              <Typography.BodyExtraSmall colorName="text-dark-3">
                Prix Producteurs
              </Typography.BodyExtraSmall>
              <Typography.BodySmall
                colorName={dangerMinPriceWholsaler && false ? 'color-danger' : 'text-dark-3'}
              >
                {FormatUtils.formatPrice(productTypePropositions?.minPriceWholsalers)} -{' '}
                {FormatUtils.formatPrice(productTypePropositions?.maxPriceWholsalers)}
              </Typography.BodySmall>
            </StyledCenterBox>
            <StyledRightBox>
              <Typography.BodyExtraSmall colorName="text-dark-3">
                Prix {FormatUtils.capitalize(FormatUtils.getLabelFromClientType(1))}
              </Typography.BodyExtraSmall>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.3} />
              <View style={{ maxWidth: '70px' }}>
                <TextInput
                  value={(newPriceStatement?.minPrice || '').toString()}
                  label=""
                  field="text"
                  onChangeText={(text) => onChangeInput('minPrice', text)}
                  onBlurCallBack={() => onBlurInput('')}
                  onKeyPressCallBack={() => onValidateChanges()}
                  small
                />
              </View>

              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.4} />
              <Typography.BodyExtraSmall colorName="text-dark-3">
                Prix Producteurs
              </Typography.BodyExtraSmall>
              <Typography.BodySmall
                colorName={dangerMinPrice && false ? 'color-danger' : 'text-dark-3'}
              >
                {FormatUtils.formatPrice(productTypePropositions?.minPrice)} -{' '}
                {FormatUtils.formatPrice(productTypePropositions?.maxPrice)}
              </Typography.BodySmall>
            </StyledRightBox>
            <StyledRightButtonBox>
              {hasChanged && !isLoading && (
                <TouchableHighlight
                  onPress={() => onValidateChanges()}
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Icon
                      type={Icons.Ionicons}
                      name="save"
                      size={theme.spacingUnit * 1.8}
                      color={theme.colors['color-primary']}
                    />
                  </View>
                </TouchableHighlight>
              )}
              {!priceStatement && !hasChanged && (
                <TouchableHighlight
                  onPress={() => onHide()}
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Icon
                      type={Icons.MaterialCommunityIcons}
                      name="eye-remove"
                      size={theme.spacingUnit * 2}
                      color={theme.colors['color-primary']}
                    />
                  </View>
                </TouchableHighlight>
              )}
              {isLoading && <Loader isLoading />}
            </StyledRightButtonBox>
          </View>
          {showBatchs && (
            <View>
              {showBatchs &&
                productTypePropositions?.batchs &&
                productTypePropositions?.batchs?.length > 0 &&
                productTypePropositions?.batchs?.length !== undefined && (
                  <>
                    {productTypePropositions?.batchs?.map((batch: Batch) => (
                      <React.Fragment key={batch.id}>
                        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.4} />
                        <Typography.BodySmall>
                          • {batch?.product?.producer?.label} - {batch?.product?.label} -{' '}
                          {FormatUtils.formatQuantity(
                            batch?.product?.packing,
                            batch?.product?.mesureType,
                          )}{' '}
                          - {BatchUtil.getBatchPeriodLabel(batch)} -{' '}
                          {FormatUtils.formatPrice(batch.unitPrice)}
                        </Typography.BodySmall>
                      </React.Fragment>
                    ))}
                  </>
                )}
            </View>
          )}
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default PriceStatementCard
