import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { View } from 'react-native'
import { useState } from 'react'
import { format } from 'date-fns'
import {
  Button,
  Card,
  Dropdown,
  PageTitle,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'
import InvoiceService from '../../../../services/invoice'
import BadgeStatus from '../../../../components/BadgeStatus'
import { InvoiceStatusEnum, INVOICE_STATUSES } from '../../../../../enums/invoice'
import FormatUtils from '../../../../utilities/utils/format'
import TotalSummaryCard from '../../../../components/TotalSummaryCard'
import i18n from '../../../../i18n'
import {
  StyledBottomWrapper,
  StyledButtonWrapper,
  StyledInfoCardWrapper,
  StyledPageTitleWrapper,
  StyledTopWrapper,
} from './UserEditInvoiceScreen.styles'
import InvoiceLine from '../../../../components/InvoiceLine'
import Loader from '../../../../components/Loader'
import { CarrierStackParamList } from '../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ProducerStackParamList } from '../../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import PdfUtils from '../../../../utilities/utils/pdf'
import CardSettings from '../../../../components/CardSettings'
import DeviceUtil from '../../../../utilities/utils/device'

function EditInvoiceScreen() {
  const [newStatus, setNewStatus] = useState<any>(undefined)
  const [isSending, setIsSending] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const navigation =
    useNavigation<StackNavigationProp<CarrierStackParamList | ProducerStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList | ProducerStackParamList, 'EditInvoice'>>()
  const { invoiceId } = route?.params ?? {
    id: '',
  }

  const { data: invoice, isInitialLoading: invoiceLoading } = InvoiceService.useGetOneInvoice({
    invoiceId,
    detailled: true,
  }).query()

  const isMobileScreen = DeviceUtil.isMobileApp()

  if (!invoice) return null

  const handleStatusChange = (selectedValue: any[]) => {
    setNewStatus(selectedValue)
  }

  const onUpdateInvoice = async () => {
    if (!newStatus) {
      setError('Veuillez sélectionner un statut valide')
      return
    }
    setIsUpdating(true)
    setError(null)
    try {
      await InvoiceService.update(invoiceId, { status: newStatus.value })
      InvoiceService.useGetOneInvoice({ invoiceId }).invalidate()
      navigation.navigate('ListInvoice')
    } catch (error) {
      console.error('error', error)
      setError("Une erreur s'est produite lors de la mise à jour de la facture")
    } finally {
      setIsUpdating(false)
    }
  }

  const onSendInvoiceEmail = async () => {
    setIsSending(true)
    try {
      await InvoiceService.sendInvoiceEmail(invoiceId)
      InvoiceService.useGetOneInvoice({ invoiceId: invoiceId }).invalidate()
    } catch (error) {
      console.error('error', error)
      setError("Une erreur s'est produite lors de l'envoi de la facture")
    }

    setIsSending(false)
  }

  const invoiceStatus = INVOICE_STATUSES.find((statusType) => statusType.value === invoice.status)

  if (!invoice) {
    return (
      <>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.BodySmall colorName="text-dark-1" align="center">
          Facture introuvable
        </Typography.BodySmall>
      </>
    )
  }

  if (invoiceLoading) {
    return (
      <>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <Loader />
      </>
    )
  }

  const state = navigation.getState()
  const filteredRoutes = state.routes.filter((route) => route.name !== 'CreateInvoice')
  const newIndex = filteredRoutes.findIndex((route) => route.key === state.routes[state.index].key)
  const newState = {
    ...state,
    routes: filteredRoutes,
    index: newIndex >= 0 ? newIndex : 0,
  }
  navigation.reset(newState)

  const onClickDownloadInvoice = async () => {
    setIsDownloading(true)
    try {
      const base64Response = await InvoiceService.getPdf(invoice.id)
      if (!PdfUtils.isValidBase64Pdf(base64Response)) {
        throw new Error("Le PDF reçu n'est pas valide")
      }
      PdfUtils.downloadPdf(
        base64Response,
        `${invoice.displayIdentifier} du ${format(new Date(invoice.createdDate), 'dd-MM-yy')}.pdf`,
      )
    } finally {
      setIsDownloading(false)
    }
  }

  const canSendEmail = () => {
    return invoice.recipient.email && invoiceStatus?.value !== InvoiceStatusEnum.SEND
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <StyledTopWrapper>
          <StyledPageTitleWrapper>
            <PageTitle
              title={`Facture${invoice.displayIdentifier ? ' #' : ''}${invoice.displayIdentifier}`}
              noFixedWidth
              noMarginLeft
              noMarginBottom
            />
            {invoiceStatus && (
              <View style={{ bottom: '4px' }}>
                <BadgeStatus
                  label={invoiceStatus?.label || ''}
                  color={invoiceStatus?.color}
                  backgroundColor={invoiceStatus?.backgroundColor}
                  extraSmall
                />
              </View>
            )}
          </StyledPageTitleWrapper>
          <StyledButtonWrapper isWrapped={isMobileScreen}>
            <Button
              label={FormatUtils.capitalize(i18n.t('download'))}
              onPress={onClickDownloadInvoice}
              loading={isDownloading}
              small
            />
          </StyledButtonWrapper>
        </StyledTopWrapper>
        <Typography.BodyExtraSmall colorName="text-dark-3" bold>
          {invoice.dateInfos}
        </Typography.BodyExtraSmall>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <StyledInfoCardWrapper>
          <View style={{ width: '45%' }}>
            <Card>
              <Typography.BodySmall colorName="text-dark-1" bold>
                Émetteur
              </Typography.BodySmall>
              <Typography.BodySmall colorName="text-dark-1" ellipsis>
                {invoice.issuer.legalName}
              </Typography.BodySmall>
            </Card>
          </View>
          <View style={{ width: '45%' }}>
            <Card>
              <Typography.BodySmall colorName="text-dark-1" bold>
                Destinataire
              </Typography.BodySmall>
              <Typography.BodySmall colorName="text-dark-1" ellipsis>
                {invoice.recipient.legalName}
              </Typography.BodySmall>
            </Card>
          </View>
        </StyledInfoCardWrapper>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <SectionTitle title="Contenu de la facture" />
        {invoice.lines.map((line) => (
          <InvoiceLine key={line.id} parentLine={line} />
        ))}
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <TotalSummaryCard items={invoice.priceItems} />
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <SectionTitle title="Statut de la facture" />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <StyledBottomWrapper>
          <View style={{ width: '50%' }}>
            <Dropdown
              label="Nouveau statut"
              boldInputLabel
              multiple={false}
              defaultValue={invoice.status}
              optionsDefault={INVOICE_STATUSES.map((invoiceStatus) => ({
                value: invoiceStatus.value,
                label: invoiceStatus.label,
              }))}
              onChange={handleStatusChange}
            />
          </View>
          <Button
            label={FormatUtils.capitalize(i18n.t('save'))}
            onPress={() => onUpdateInvoice()}
            disabled={!newStatus}
            loading={isUpdating}
          />
        </StyledBottomWrapper>

        <Spacer size={5} axis={Spacer.AxisEnum.VERTICAL} />
        <CardSettings
          title="Envoyer la facture par email"
          descriptionColor={invoice.recipient.email ? undefined : 'color-warning'}
          description={
            invoice.recipient.email
              ? `Cette facture ${canSendEmail() ? 'sera' : 'a été'} envoyée à ${
                  invoice.recipient.legalName
                }.`
              : `L'email du destinaire n'est pas renseigné`
          }
        >
          <Button
            label="Envoyer"
            colorName="color-grey"
            onPress={() => onSendInvoiceEmail()}
            loading={isSending}
            disabled={!canSendEmail()}
          />
        </CardSettings>

        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {error && (
          <Typography.BodySmall colorName="color-danger" align="center">
            {error}
          </Typography.BodySmall>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default EditInvoiceScreen
