export enum PaymentStatusEnum {
  PENDING = 0,
  FAILED = 1,
  COMPLETED = 2,
}

export enum PaymentMethodEnum {
  BANK_TRANSFER = 0,
  CREDIT_CARD = 1,
  PAYPAL = 2,
  STRIPE = 3,
}

export const PAYMENT_METHODS = [
  { label: 'Transfert Bancaire', value: PaymentMethodEnum.BANK_TRANSFER },
  { label: 'Carte Bancaire', value: PaymentMethodEnum.CREDIT_CARD },
  { label: 'Paypal', value: PaymentMethodEnum.PAYPAL },
  { label: 'Stripe', value: PaymentMethodEnum.STRIPE },
]
