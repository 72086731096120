import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Col, Dropdown, PageTitle, Row, Spacer, Typography } from '../../../components'
import DatePicker from '../../../components/DatePicker'
import {
  DuplicatePriceReporting,
  DuplicateTour,
  Pagination,
  PriceReporting,
  Tour,
  ToursFake,
} from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../services/carrier/tour'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierDuplicatePriceReporting.styles'
import { Switch } from 'react-native-gesture-handler'
import { queryClient } from '../../../utilities/queryClient'
import PriceReportingCarrierService from '../../../services/carrier/priceReporting'

const CarrierDuplicatePriceReportingScreen = () => {
  const theme = useTheme()
  const [selectedPriceReporting, setSelectedPriceReporting] = useState<PriceReporting>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()
  const [isLoading, setIsLoading] = useState(false)

  const defaultDateHour = new Date()
  defaultDateHour.setHours(0)
  defaultDateHour.setMinutes(0)

  const [newPriceReporting, setNewPriceReporting] = useState<DuplicatePriceReporting>({
    targetDate: defaultDateHour,
    defaultDateHour: defaultDateHour,
  })

  const dateFrom = new Date()
  dateFrom.setDate(dateFrom.getDate() - 60)

  const {
    data: priceReportings,
    refetch: refetchPriceReportings,
    isInitialLoading: isPriceReportingsLoading,
  } = useQuery<{ data: PriceReporting[]; pagination: Pagination }, Error>(
    ['sf_price_reportings'],
    () => PriceReportingCarrierService.getAll(getAccessInfos().carrierGroupId, dateFrom),
    {
      keepPreviousData: true,
    },
  )

  const onChangeDate = (value: any) => {
    const tourTemp = Object.assign({}, newPriceReporting)
    tourTemp.targetDate = value

    setNewPriceReporting(tourTemp)
  }

  const onChangePriceReporting = (value: any) => {
    setSelectedPriceReporting(value)
  }

  const onDuplicatePriceReporting = async () => {
    setErrorMessage('')
    if (!selectedPriceReporting || !selectedPriceReporting.id || !newPriceReporting.targetDate) {
      setErrorMessage('Relevé ou date invalide')

      return
    }
    setIsLoading(true)
    const body = {
      validityStart: newPriceReporting.targetDate,
    }

    const createdPriceReporting = await PriceReportingCarrierService.duplicate(
      selectedPriceReporting.id,
      body,
    )
    if (createdPriceReporting && createdPriceReporting.id) {
      await queryClient.fetchQuery(['sf_price_reportings'])
      navigation.popToTop()
      navigation.navigate('CarrierPriceReporting', { id: createdPriceReporting.id })
    } else {
      setErrorMessage('Un problème est survenu lors de la duplication du relevé')
    }
    setIsLoading(false)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Dupliquer un relevé" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          <Dropdown
            label={'Choisir un relevé'}
            onChange={onChangePriceReporting}
            multiple={false}
            defaultValue={selectedPriceReporting?.id}
            optionsDefault={(priceReportings?.data || []).map((priceReporting) => ({
              ...priceReporting,
              label: `${FormatUtils.capitalize(
                FormatUtils.formatDate(priceReporting.validityStart, 'FullDate'),
              )} au ${FormatUtils.formatDate(priceReporting.validityEnd, 'FullDate')} - ${
                priceReporting.label
              }`,
              value: priceReporting.id,
            }))}
            zIndex={1000}
            itemKey="priceReporting"
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Row>
              <Col xs={12} sm={12} md={7}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <DatePicker
                  label="Date du nouveau rapport"
                  onChange={onChangeDate}
                  value={newPriceReporting.targetDate}
                  mode={'date'}
                  hidden={!selectedPriceReporting}
                />
              </Col>
            </Row>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            {newPriceReporting.targetDate !== newPriceReporting.defaultDateHour && (
              <Button
                label="Dupliquer"
                onPress={() => onDuplicatePriceReporting()}
                loading={isLoading}
              />
            )}
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierDuplicatePriceReportingScreen
