import React from 'react'
import { Image, InteractionManager, Linking } from 'react-native'
// import * as SplashScreen from 'expo-splash-screen'
import { useFocusEffect } from '@react-navigation/native'
import { StyledImageWrapper, StyledContent, StyledImage } from './ForceUpgrade.styles'
import UpdateIllustration from '../../../../assets/images/upgrade.png'
import { Button, Spacer, Typography } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'

const ForceUpgradeScreen = () => {
  const { storeUrl } = { storeUrl: 'https://harvy.tech' }
  const updateIllustrationUri = Image.resolveAssetSource(UpdateIllustration).uri

  useFocusEffect(
    React.useCallback(() => {
      // wait for the screen and navigation to be rendered
      const task = InteractionManager.runAfterInteractions(() => {
        ;(async () => {
          // await SplashScreen.hideAsync()
        })()
      })

      return () => task.cancel()
    }, []),
  )

  const onButtonPress = () => {
    Linking.canOpenURL(storeUrl).then(
      (supported) => {
        if (supported) {
          Linking.openURL(storeUrl)
        }
      },
      (err) => console.log(err),
    )
  }

  return (
    <ScreenSafeAreaWrapper>
      <ContentWrapper>
        <StyledContent>
          <Typography.Title>Mise à jour importante</Typography.Title>
          <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.Body style={{ textAlign: 'center' }}>
            Veuillez mettre à jour l'application afin de continuer à l'utiliser
          </Typography.Body>
          <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
          <StyledImageWrapper>
            <StyledImage source={{ uri: updateIllustrationUri }} resizeMode="contain" />
          </StyledImageWrapper>
          <Spacer size={6} axis={Spacer.AxisEnum.VERTICAL} />

          <Button label="Mettre à jour" onPress={onButtonPress} fullWidth />
        </StyledContent>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ForceUpgradeScreen
