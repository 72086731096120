import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TextInput, TouchableCard, Typography } from '../../../components'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import FormatUtils from '../../../utilities/utils/format'
import { TourClientCardProps } from './TourClientCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRankingBox,
  StyledRightBox,
} from './TourClientCard.styles'

const TourClientCard = ({
  stop,
  tour,
  changeRankingMode,
  onChangeRanking,
}: TourClientCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const OnClickClient = () => {
    if (!changeRankingMode) {
      navigation.navigate('CarrierTourClient', { id: stop.id, tourId: tour.id })
    }
  }

  const client = stop.client

  const onChangeRankingPressed = (value: number) => {
    if (onChangeRanking) {
      onChangeRanking(stop, value)
    }
  }

  const [newRanking, setNewRanking] = useState<number | undefined>(stop.ranking)

  useEffect(() => {
    setNewRanking(stop.ranking)
  }, [stop])

  const onChangeRankingInput = (valueText: string) => {
    const valueNumber = parseInt(valueText)
    setNewRanking(valueNumber)
  }

  const onBlurInputRanking = () => {
    if (onChangeRanking && newRanking) {
      onChangeRanking(stop, 0, newRanking)
    }
    // setNewRanking(undefined)
  }

  return (
    <StyledCardWrapper onPress={OnClickClient} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          {changeRankingMode ? (
            <StyledRankingBox>
              {stop.ranking > 1 ? (
                <TouchableOpacity onPress={() => onChangeRankingPressed(-1)}>
                  <Icon
                    type={Icons.Ionicons}
                    name="chevron-up-outline"
                    size={theme.spacingUnit * 2}
                    color={theme.colors['text-dark-3']}
                  />
                </TouchableOpacity>
              ) : null}

              <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
              <TextInput
                value={(newRanking || '').toString()}
                label=""
                field="text"
                onChangeText={(text) => onChangeRankingInput(text)}
                onBlurCallBack={onBlurInputRanking}
                small
              />
              <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
              <TouchableOpacity onPress={() => onChangeRankingPressed(1)}>
                <Icon
                  type={Icons.Ionicons}
                  name="chevron-down-outline"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['text-dark-3']}
                />
              </TouchableOpacity>
            </StyledRankingBox>
          ) : (
            <StyledRankingBox>
              <Typography.CardTitle>{stop.ranking}</Typography.CardTitle>
            </StyledRankingBox>
          )}
          <StyledLeftBox>
            <Typography.CardTitle ellipsis>{client.label}</Typography.CardTitle>
            <Typography.BodySmall colorName="color-primary" bold>
              {FormatUtils.capitalize(FormatUtils.getLabelFromClientType(client.clientType))} -{' '}
              {client.city}
            </Typography.BodySmall>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            {stop.hasCartWithNoOrder ? (
              <Typography.Body colorName="color-danger">
                {FormatUtils.pluralizeAmountLabel('panier', stop.nbCarts || 0)} -{' '}
                {FormatUtils.pluralizeAmountLabel('commande', stop.nbOrders || 0)}
              </Typography.Body>
            ) : (
              <Typography.Body colorName="text-dark-3">
                {FormatUtils.formatPrice(stop.totalIncome)}HT - {stop.nbCrates || '0'} colis
              </Typography.Body>
            )}
          </StyledLeftBox>
          <StyledMiddleBox>
            <View>
              <Typography.BodySmall colorName="text-dark-3">prévu</Typography.BodySmall>
              <Typography.BodySmall>
                {FormatUtils.capitalize(FormatUtils.formatDate(stop.estimate, 'Time'))}
              </Typography.BodySmall>
            </View>
          </StyledMiddleBox>
          <StyledRightBox>
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              size={theme.spacingUnit * 2}
              color={theme.colors['text-dark-3']}
            />
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default TourClientCard
