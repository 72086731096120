import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, PageTitle, Spacer, Typography } from '../../../components'
import { DeliveryExpensesParameters, Tour } from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import Loader from '../../../components/Loader'
import TruckInfosForm from './slides/TruckInfosForm'
import { CarrierFeesEnums } from '../../../../enums/carrierFees'
import LogisticInfosForm from './slides/LogisticInfosForm'
import DeliveryExpensesForm from './slides/DeliveryExpensesForm'
import { queryClient } from '../../../utilities/queryClient'
import TourExpensesUtil from '../../../utilities/utils/tourExpensesUtil'
import { StyledBackButtonWrapper } from './CarrierTourDeliveryExpenses.styles'
import { View } from 'react-native'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import FormatUtils from '../../../utilities/utils/format'

const carrierTourModes = [
  {
    label: 'Votre Camion',
    value: 'TRUCK',
  },
  {
    label: 'Logistique',
    value: 'LOGISTIC',
  },
  {
    label: 'Coût de livraison',
    value: 'EXPENSES',
  },
]

const CarrierTourDeliveryExpenses = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTour'>>()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [selectedMode, setSelectedMode] = useState<string | number>(carrierTourModes[0].value)
  const [modePreselected, setModePreselected] = useState<Boolean>(true)
  const [estimatedExpenses, setEstimatedExpenses] = useState<{
    transportCost: number
    logisticCost: number
  }>()
  const [deliveryExpensesParameters, setDeliveryExpensesParameters] = useState<any>({
    truckType: undefined,
    fuelType: undefined,
    capacity: undefined,
    distance: undefined,
    hourlyCost: undefined,
    orderPreparationTime: undefined,
    deliveryTime: undefined,
    annexCosts: undefined,
  })

  const { id } = route?.params ?? { id: '' }

  let mode = route?.params?.mode
  const { data: tour, isInitialLoading: tourLoading } = TourCarrierService.getOne.query(id)

  useEffect(() => {
    if (mode && modePreselected) {
      setSelectedMode(mode)
      setModePreselected(false)
    } else if (selectedMode) {
      setSelectedMode(selectedMode)
    }
  }, [selectedMode])

  useEffect(() => {
    if (tour?.capacity) {
      setDeliveryExpensesParameters({
        ...deliveryExpensesParameters,
        capacity: tour?.capacity / 1000000,
      })
    }
    if (tour?.deliveryExpensesParameters) {
      setDeliveryExpensesParameters({ ...tour.deliveryExpensesParameters })
    }
  }, [tour?.capacity, tour?.deliveryExpensesParameters])

  const onValidateStep1 = (
    truckType: CarrierFeesEnums.TruckTypeEnum,
    fuelType: CarrierFeesEnums.FuelTypeEnum,
    capacity: number,
    distance: number,
    annexCosts: string,
  ) => {
    setDeliveryExpensesParameters({
      ...deliveryExpensesParameters,
      truckType,
      fuelType,
      capacity,
      distance,
      annexCosts: parseFloat(annexCosts) || 0,
    })
    setSelectedMode('LOGISTIC')
  }

  const onValidateStep2 = (
    orderPreparationTime: number,
    deliveryTime: number,
    hourlyCost: number,
  ) => {
    const updatedDeliveryExpensesParameters = {
      ...deliveryExpensesParameters,
      orderPreparationTime,
      deliveryTime,
      hourlyCost,
    }

    setDeliveryExpensesParameters({
      ...deliveryExpensesParameters,
      orderPreparationTime,
      deliveryTime,
      hourlyCost,
    })

    const estimatedExpenses = TourExpensesUtil.estimateCarrierExpenses(
      deliveryExpensesParameters.truckType, // Provide a default value for truckType
      deliveryExpensesParameters.fuelType,
      deliveryExpensesParameters.capacity,
      deliveryExpensesParameters.distance,
      updatedDeliveryExpensesParameters.orderPreparationTime,
      updatedDeliveryExpensesParameters.deliveryTime,
      updatedDeliveryExpensesParameters.hourlyCost,
      updatedDeliveryExpensesParameters.annexCosts,
    )
    setEstimatedExpenses(estimatedExpenses)
    setSelectedMode('EXPENSES')
  }

  const onValidateStep3 = async (carrierExpenses: string) => {
    const estimatedCarrierExpenses =
      (estimatedExpenses?.logisticCost || 0) + (estimatedExpenses?.transportCost || 0)
    const parametersToUpdate = {
      truckType: deliveryExpensesParameters.truckType,
      fuelType: deliveryExpensesParameters.fuelType,
      capacity: deliveryExpensesParameters.capacity,
      distance: deliveryExpensesParameters.distance,
      orderPreparationTime: deliveryExpensesParameters.orderPreparationTime,
      deliveryTime: deliveryExpensesParameters.deliveryTime,
      hourlyCost: deliveryExpensesParameters.hourlyCost,
      annexCosts: deliveryExpensesParameters.annexCosts,
      carrierExpenses: FormatUtils.stringToFloat(carrierExpenses) || estimatedCarrierExpenses,
    }

    await TourCarrierService.updateDeliveryExpenses(id, {
      ...parametersToUpdate,
    })

    queryClient.invalidateQueries(['sf_tour', id])

    navigation.navigate('CarrierTour', { id })
  }

  if (tourLoading) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  if (!tour) return null

  const handleGoBack = (selectedMode: 'TRUCK' | 'LOGISTIC' | 'EXPENSES') => {
    setSelectedMode(selectedMode)
  }

  const renderSelectedComponent = () => {
    switch (selectedMode) {
      case 'TRUCK':
        return (
          <>
            <PageTitle title="Votre Camion" />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.6} />
            <TruckInfosForm
              deliveryExpensesParameters={deliveryExpensesParameters}
              capacity={tour?.capacity || 0}
              onClickNext={onValidateStep1}
            />
          </>
        )
      case 'LOGISTIC':
        return (
          <>
            <PageTitle title="Logistique" />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.6} />
            <LogisticInfosForm
              deliveryExpensesParameters={deliveryExpensesParameters}
              capacity={tour?.capacity || 0}
              onClickNext={onValidateStep2}
              onGoBack={handleGoBack}
            />
          </>
        )
      case 'EXPENSES':
        return (
          <>
            <PageTitle title="Coût de livraison" />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.6} />
            <DeliveryExpensesForm
              deliveryExpensesParameters={deliveryExpensesParameters}
              capacity={tour?.capacity || 0}
              carrierExpenses={tour?.carrierExpenses || 0}
              onClickSubmit={onValidateStep3}
              onGoBack={handleGoBack}
              estimatedExpenses={
                estimatedExpenses || {
                  transportCost: 0,
                  logisticCost: 0,
                }
              }
            />
          </>
        )
      default:
        return null
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper marginTopLarge>{renderSelectedComponent()}</ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourDeliveryExpenses
