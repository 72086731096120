import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const BoxWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`

const StyledLeftWrapper = styled.View`
  flex-direction: row;
  gap: ${({ theme }) => theme.spacingUnit * 1.3}px;
`

const StyledRightWrapper = styled.View``

const StyledCardWrapperCart = styled.TouchableOpacity`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 0}px;
  /* margin-bottom: ${({ theme }) => theme.spacingUnit * 2}px; */
`

const StyledButtonWrapper = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledBoxWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
`

const StyledBox = styled.View``

export {
  StyledLeftWrapper,
  StyledRightWrapper,
  BoxWrapper,
  StyledCardWrapperCart,
  StyledButtonWrapper,
  StyledBoxWrapper,
  StyledBox,
}
