import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import FormatUtils from '../../../utilities/utils/format'
import { TourProducerCardProps } from './TourProducerCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
} from './TourProducerCard.styles'

const TourProducerCard = ({ producerTour, tour }: TourProducerCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const OnClickProducer = () => {
    navigation.navigate('CarrierTourProducer', {
      id: tour.id,
      idProducer: producerTour.producer.id,
    })
  }

  const producer = producerTour.producer

  return (
    <StyledCardWrapper onPress={OnClickProducer} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.CardTitle ellipsis>{producer.label}</Typography.CardTitle>
            <Typography.BodySmall colorName="color-primary" bold>
              {FormatUtils.pluralizeAmountLabel('commande', producerTour?.nbOrders || 0)}
            </Typography.BodySmall>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.Body colorName="text-dark-3">
              {FormatUtils.formatPrice(producerTour.totalIncome)}
              HT - {producerTour.nbCrates || 0} colis
            </Typography.Body>
          </StyledLeftBox>
          <StyledRightBox>
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              size={theme.spacingUnit * 3}
              color={theme.colors['text-dark-1']}
            />
            <View style={{ alignItems: 'flex-end' }}>
              {producerTour.minOrderValueHT ? (
                <Typography.BodyExtraSmall colorName="color-warning">
                  Min. cmd{' '}
                  {FormatUtils.formatPrice(
                    producerTour.minOrderValueHT,
                    undefined,
                    undefined,
                    false,
                    true,
                  )}
                </Typography.BodyExtraSmall>
              ) : null}
              {producerTour.orderEndDate ? (
                <Typography.BodyExtraSmall colorName="color-warning">
                  Fin cmd.{' '}
                  {FormatUtils.formatDate(producerTour.orderEndDate.toString(), 'WeekDay+Time')}
                </Typography.BodyExtraSmall>
              ) : null}
            </View>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default TourProducerCard
