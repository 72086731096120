import {
  CarrierFeesEnums,
  FUEL_EFFICIENCIES,
  FUEL_PRICES_PER_LITER,
} from '../../../enums/carrierFees'

function getFuelCostPer100Km(
  truckType: CarrierFeesEnums.TruckTypeEnum,
  fuelType: CarrierFeesEnums.FuelTypeEnum,
): number {
  const fuelConsumptionPer100Km = FUEL_EFFICIENCIES[truckType][fuelType]
  const fuelPricePerLiter = FUEL_PRICES_PER_LITER[fuelType]

  const fuelCostPer100Km = Math.round(fuelConsumptionPer100Km * fuelPricePerLiter * 100) / 100

  return fuelCostPer100Km
}

const estimateCarrierExpenses = (
  truckType: CarrierFeesEnums.TruckTypeEnum,
  fuelType: CarrierFeesEnums.FuelTypeEnum,
  capacity: number,
  distance: number,
  orderPreparationTime: number,
  deliveryTime: number,
  hourlyCost: number,
  annexCosts: number,
): { transportCost: number; logisticCost: number } => {
  let transportCost = 0
  let logisticCost = 0
  const fuelCostPer100Km = getFuelCostPer100Km(truckType, fuelType)

  transportCost = Math.round(fuelCostPer100Km * (distance / 100) * 100) / 100 + annexCosts || 0

  logisticCost = (orderPreparationTime + deliveryTime) * hourlyCost

  return { transportCost, logisticCost }
}

const TourExpensesUtil = {
  estimateCarrierExpenses,
}

export default TourExpensesUtil
