import styled from 'styled-components/native'
import { css } from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 1.3}px;
  flex-direction: row;
  min-height: ${({ theme }) => theme.spacingUnit * 7}px;
`

const StyledLeftBox = styled.View`
  flex: 3;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
`
const StyledMiddleBox = styled.View`
  // flex: 2;
  flex: 6;
`
const StyledRightBox = styled.View`
  flex: 2;
  align-items: center;
  text-align: center;
  justify-content: center;
`

const StyledCratesBox = styled.View`
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  min-height: auto;
  flex-direction: row;
  flex: 1;
`

const StyledCrateBox = styled.TouchableOpacity<{ noBackground?: boolean }>`
  min-width: ${({ theme }) => theme.spacingUnit * 2}px;
  min-height: ${({ theme }) => theme.spacingUnit * 2}px;
  margin: ${({ theme }) => theme.spacingUnit * 0.3}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 0}px;
  padding: ${({ theme }) => theme.spacingUnit * 0.8}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 0.3}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 0.3}px;
  background-color: ${({ theme }) => theme.colors['color-grey']};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  align-items: center;
  justify-content: center;

  ${({ noBackground }) =>
    noBackground &&
    css`
      background-color: ${({ theme }) => theme.colors['color-grey']};
      color: ${({ theme }) => theme.colors['text-dark-1']};
    `}
`

const StyledValuesWrapper = styled.View`
  flex-direction: row;
`

const StyledValueBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 0.3}px;
  flex: 1;
`

const StyledStatusBox = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 0.5}px;
  flex: 1;
  align-items: center;
`

const CancelButtonWrapper = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.colors['color-grey']};
  border-radius: ${({ theme }) => theme.borderRadius * 0.5}px;
  padding: ${({ theme }) => theme.spacingUnit * 0.15}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.8}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.8}px;
  opacity: 0.7;
`

export {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRightBox,
  StyledValuesWrapper,
  StyledValueBox,
  StyledCrateBox,
  StyledCratesBox,
  StyledStatusBox,
  CancelButtonWrapper,
}
