export namespace DocumentEnums {
  export enum DocumentTypeEnum {
    ORGANIC_CERTIFICATION = 1,
    DEMETER_CERTIFICATION = 2,
    OTHER_DOCUMENT = 12,
  }

  export enum DocumentStatusEnum {
    PUBLIC = 1,
    PRIVATE = 2,
  }

  export const DocumentTypes = [
    {
      label: 'Certificat BIO',
      value: DocumentTypeEnum.ORGANIC_CERTIFICATION,
    },
    {
      label: 'Certificat DEMETER',
      value: DocumentTypeEnum.DEMETER_CERTIFICATION,
    },
    {
      label: 'Autre document',
      value: DocumentTypeEnum.OTHER_DOCUMENT,
    },
  ]

  export const DocumentStatuses = [
    {
      label: 'Public',
      value: DocumentStatusEnum.PUBLIC,
    },
    {
      label: 'Privé',
      value: DocumentStatusEnum.PRIVATE,
    },
  ]
}
