import styled from 'styled-components/native'
import {
  HeaderWrapper as HeaderWrapperCommon,
  StyledTouchableOpacity as StyledTouchableOpacityCommon,
} from '../common.styles'
import { css } from 'styled-components/native'
import { Insets } from 'react-native'

const HeaderWrapper = styled.View`
  width: 100%;
  background-color: ${({ theme }) => theme.colors['color-primary']};
`
const HeaderTopWrapper = styled(HeaderWrapperCommon)<{ isDesktop: boolean; insets: Insets }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors['color-primary']};
  min-height: 54px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacingUnit * 1.5}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 0.9}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 3}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 3}px;
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      padding-left: 245px;
    `}
`
const HeaderBottomWrapper = styled.View`
  width: 100%;
  min-height: 30px;
  border-radius: ${({ theme }) => theme.spacingUnit * 2.5}px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${({ theme }) => theme.colors['background-1']};
`

const LeftContainer = styled.View<{ isDesktop: boolean }>`
  flex-direction: row;
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      margin-left: 25px;
    `}
  min-height: 25px;
`

const CenterContainer = styled.View`
  align-items: center;
  justify-content: center;
`

const RightContainer = styled.View`
  align-self: stretch;
  flex-direction: row;
  justify-content: right;
`

const AccountSwitcherWrapper = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`
const AccountSwitcherLeft = styled.View`
  justify-content: center;
`
const AccountSwitcherRight = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 1.3}px;
  align-items: center;
  justify-content: center;
`

const StyledTouchableOpacity = styled(StyledTouchableOpacityCommon)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledTouchableLogoOpacity = styled(StyledTouchableOpacityCommon)`
  align-items: left;
  justify-content: center;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.5}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 0.5}px;
`

const BADGE_SIZE = 18

const StyledBadgeWrapper = styled.View`
  position: absolute;
  top: 6px;
  right: ${BADGE_SIZE / 2}px;
  background-color: ${({ theme }) => theme.colors['color-primary']};
  border-radius: ${BADGE_SIZE / 2}px;
  width: ${BADGE_SIZE}px;
  height: ${BADGE_SIZE}px;
  justify-content: center;
  align-items: center;
`

const StyledBadgeText = styled.Text`
  color: ${({ theme }) => theme.colors['background-1']};
  font-size: 11px;
  font-weight: bold;
  font-family: 'Euclid-Medium';
`

export {
  HeaderWrapper,
  LeftContainer,
  CenterContainer,
  RightContainer,
  StyledTouchableOpacity,
  StyledTouchableLogoOpacity,
  StyledBadgeWrapper,
  StyledBadgeText,
  AccountSwitcherWrapper,
  AccountSwitcherLeft,
  AccountSwitcherRight,
  HeaderBottomWrapper,
  HeaderTopWrapper,
}
