import { DeliveryNotesCartBatchs, Order, Pagination } from '../../../domain'
import ApiService from '../../api.service'

const getAllCartBatchs = async (
  groupId: string,
  tourId?: string,
  clientId?: string,
  producerId?: string,
  before?: Date,
  after?: Date,
  limit: number = 30,
  pageNumber: number = 1,
): Promise<{ data: DeliveryNotesCartBatchs[]; pagination: Pagination; stats: any }> => {
  const response = await ApiService.get(`/order/group/${groupId}/batchs`, {
    clientId: clientId || undefined,
    tourId: tourId || undefined,
    producerId: producerId || undefined,
    before: before || undefined,
    after: after || undefined,
    limit,
    page: pageNumber,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })

  return response
}
const create = async (dto: any): Promise<Order> => {
  const response = await ApiService.post(`/order`, dto).catch((error) => {
    throw error
  })
  return response
}

const update = async (dto: any): Promise<Order> => {
  const response = await ApiService.patch(`/order`, dto).catch((error) => {
    throw error
  })
  return response
}

const sendMail = async (idCart: string, mails: string[]): Promise<Order> => {
  const response = await ApiService.post(`/order/cart/${idCart}/send-mail`, {
    mails: mails,
  }).catch((error) => {
    throw error
  })
  return response
}

const deleteOrder = async (idProducer: string, orderId: string) => {
  const response = await ApiService.remove(`/order/${orderId}/producer/${idProducer}`).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const OrderProducerService = {
  getAllCartBatchs,
  create,
  update,
  deleteOrder,
  sendMail,
}

export default OrderProducerService
