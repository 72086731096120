import React from 'react'
import Text from '../Text'
import { TextProps } from '../Text/Text.model'

const ButtonLabel: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text tag={Text.TextTag.BUTTON_LABEL} {...rest}>
      {children}
    </Text>
  )
}

export default ButtonLabel
