import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const BatchMailContentWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacingUnit * 1}px;
  padding: ${({ theme }) => theme.spacingUnit * 1}px;
`
const BatchMailCardWrapper = styled.View`
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1}px;
`

const LabelWrapperLeft = styled.View`
  flex: 2;
  align-items: flex-start;
`
const LabelWrapperRight = styled.View`
  flex: 1;
  align-items: flex-end;
`

const StyledCheckboxWrapper = styled.View`
  width: ${({ theme }) => theme.spacingUnit * 4}px;
`

export {
  BatchMailContentWrapper,
  BatchMailCardWrapper,
  LabelWrapperLeft,
  LabelWrapperRight,
  StyledCheckboxWrapper,
}
