import { Client, ClientsFake } from '../Client'

export type Step = {
  id: string
  rank: number
  estimate: string
  client?: Client
}

export const StepsFake: Step[] = [
  {
    id: 's1',
    rank: 1,
    estimate: '08/03/2023 19:00:00',
    client: ClientsFake.find((client) => client.id === 'c1'),
  },
  {
    id: 's2',
    rank: 2,
    estimate: '08/03/2023 20:00:00',
    client: ClientsFake.find((client) => client.id === 'c2'),
  },
]
