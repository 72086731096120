import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Typography, Card, Button, Row, Col, Spacer } from '../../../../../components'
import { ClientStackParamList } from '../../../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import FormatUtils from '../../../../../utilities/utils/format'

import { BoxWrapper } from './ClientCatalogNavBar.styles'
import { Switch } from 'react-native-gesture-handler'
import { useEffect, useState } from 'react'
import StorageUtil from '../../../../../utilities/storage/storage'
import { useTheme } from 'styled-components/native'
import CatalogNavBarDeliveryInfo from './ClientCatalogNavBarDeliveryInfo'
import CatalogNavBarSwitcher from './ClientCatalogNavBarSwitcher'
import CatalogNavBarCartInfo from './ClientCatalogNavBarCartInfo'
import DeviceUtil from '../../../../../utilities/utils/device'
import { View } from 'react-native'
import { ClientCatalogNavBarProps } from './ClientCatalogNavBar.model'

const ClientCatalogNavBar = ({
  cart,
  tour,
  onChangeTour,
  displayMode,
  onChangeDisplayMode,
}: ClientCatalogNavBarProps) => {
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const theme = useTheme()

  const OnClickEndOrder = () => {
    navigation.navigate('ClientCart', {})
  }

  if (!tour || !cart) return null

  return (
    <BoxWrapper>
      <Row>
        <Col xs={12} sm={12} md={12} lg={5}>
          <CatalogNavBarDeliveryInfo
            tour={tour}
            onClick={cart.targetCart ? null : () => onChangeTour()}
          />
          <Spacer size={0.6} axis={Spacer.AxisEnum.VERTICAL} />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4}>
          <CatalogNavBarSwitcher
            onChangeDisplayMode={onChangeDisplayMode}
            displayMode={displayMode}
          />
          <Spacer size={0.6} axis={Spacer.AxisEnum.VERTICAL} />
        </Col>
        <Col
          xs={12}
          sm={6}
          md={6}
          lg={3}
          smAlignItems="flex-end"
          mdAlignItems="flex-end"
          lgAlignItems="flex-end"
          justifyContent="center"
        >
          <CatalogNavBarCartInfo cart={cart} onClickEndOrder={OnClickEndOrder} />
          <Spacer size={0.6} axis={Spacer.AxisEnum.VERTICAL} />
        </Col>
      </Row>
    </BoxWrapper>
  )
}

export default ClientCatalogNavBar
