import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { CatalogToursProps } from './CatalogTours.model'
import { CaptionWrapper, ToursWrapper } from './CatalogTours.styles'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { Cart, Order, Pagination, Tour } from '../../../domain'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { PageTitle, Spacer, Typography } from '../../../components'
import CartTourClientCard from '../../../modules/Cart/CartTourClientCard'
import { useQuery } from '@tanstack/react-query'
import TourClientService from '../../../services/client/tour'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import CartClientService from '../../../services/client/cart'
import StorageUtil from '../../../utilities/storage/storage'
import { Alert, View } from 'react-native'
import CatalogClientNavigationStackParamList from '../../../navigation/ClientNavigationStack/CatalogClientNavigation/CatalogClientNavigation.model'
import Loader from '../../../components/Loader'
import OrderClientService from '../../../services/client/order'

const ClientCatalogToursScreen = () => {
  const theme = useTheme()

  const navigation = useNavigation<StackNavigationProp<CatalogClientNavigationStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()

  const [cartId, setCartId] = useState<string | undefined>(undefined)

  const {
    data: tours,
    refetch: refetchTours,
    isInitialLoading: toursLoading,
  } = useQuery<Tour[], Error>(
    ['c_tours'],
    () => TourClientService.getAllTours(getAccessInfos().clientId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: ordersNext,
    refetch: refetchOrdersNext,
    isInitialLoading: ordersNextLoading,
  } = useQuery<{ data: Order[]; pagination: Pagination }, Error>(
    ['c_orders_next'],
    () => OrderClientService.getAllOrders(getAccessInfos().clientId, true, false, 50),
    {
      keepPreviousData: true,
    },
  )

  if (toursLoading) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  const onTourSelect = async (tour: Tour) => {
    if (!tour || !tour.id) return

    const createdCart = await CartClientService.create(getAccessInfos().clientId, {
      tourId: tour.id,
    })
    if (createdCart && createdCart.id) {
      await StorageUtil.setItem('c_cart_id', createdCart.id)
      await setCartId(createdCart.id)
    } else {
      Alert.alert('Erreur lors de la création du panier')
    }

    navigation.navigate('ClientCatalog', {})
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
          <PageTitle title="Choisir une tournée" />
          <CaptionWrapper>
            <Typography.Body colorName="text-dark-3">
              Cliquez sur une tournée pour voir la mercuriale des producteurs participants
            </Typography.Body>
          </CaptionWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {tours && tours.length > 0 ? (
            tours.map((tour) => (
              <CartTourClientCard
                key={tour.id}
                tour={tour}
                onClick={(tour) => onTourSelect(tour)}
                order={ordersNext?.data?.find((order) => order?.cart?.tour?.id === tour?.id)}
              />
            ))
          ) : (
            <View style={{ width: '100%' }}>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2.5} />
              <Typography.BodySmall colorName="text-dark-3">
                Aucune tournée à venir ouverte
              </Typography.BodySmall>
            </View>
          )}
        </ToursWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientCatalogToursScreen
