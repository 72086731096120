import axios from 'axios'
import { Producer } from '../../../domain'
import { Account } from '../../../domain/Account'
import Env from '../../../utilities/env'

const createAccountToken = async (account: Account): Promise<string | null> => {
  const response = await axios.post(
    'https://api.stripe.com/v1/tokens',
    new URLSearchParams({
      'account[company][name]': account.legalName,
      'account[tos_shown_and_accepted]': 'true',
      'account[business_type]': 'company',
    }),
    {
      headers: {
        Authorization: `Bearer ${Env.STRIPE_PUBLISHABLE_KEY}`, // Remplacez par votre clé publishable Stripe
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  )

  if (response.data?.id) {
    return response.data?.id
  } else {
    return null
  }
}

const StripeService = {
  createAccountToken,
}

export default StripeService
