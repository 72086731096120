export namespace TourEnums {
  export enum STATUS {
    PENDING = 'PENDING',
    ONBOARDING = 'ONBOARDING',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    COMPLETED = 'COMPLETED',
  }

  export enum TourStatusEnum {
    ONBOARDING = -1,
    ONGOING = 0,
    COMPLETED = 3,
  }

  export type TourStep = {
    ranking: number
    title: string
    body?: string
    active?: boolean
    completed?: boolean
    disabled?: boolean
  }

  export enum TourStepStatusEnum {
    ACTIVE = 10,
    COMPLETED = 20,
    DISABLED = 0,
  }

  // réfléchir aux infos à droite des trois premières cards
  export const ProducerTourSteps: TourEnums.TourStep[] = [
    {
      ranking: 1,
      title: 'Vérifiez votre mercuriale',
      body: 'Avant l`ouverture des commandes',
    },
    {
      ranking: 2,
      title: 'Ouverture des commandes',
    },
    {
      ranking: 3,
      title: 'Fermeture des commandes',
    },
    {
      ranking: 4,
      title: 'Dépôt de la marchandise',
      body: 'Chez votre super-producteur',
    },
    {
      ranking: 5,
      title: 'Bons de livraison',
      body: 'Vos BL sont générés automatiquement',
    },
  ]
}
