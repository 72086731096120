import { BatchEnums } from '../../../../enums'
import {
  Batch,
  BatchComputed,
  CartBatch,
  Catalog,
  Pagination,
  ProductBatchs,
} from '../../../domain'
import ApiService from '../../api.service'
import FormatUtils from '../../../utilities/utils/format'

const getAllBatchs = async (
  producerId: string,
  start?: Date,
  end?: Date,
  tourId?: string,
  types?: BatchEnums.TYPE[],
  limit: number = 30,
  pageNumber: number = 1,
): Promise<{ data: Batch[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/batch/producer/${producerId}`, {
    after: start ? FormatUtils.dateToUtcString(start, '00:01:00') : null,
    before: end ? FormatUtils.dateToUtcString(end, '23:58:59') : null,
    tourId: tourId ? tourId : null,
    types: types ? types : null,
    limit,
    page: pageNumber,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getAllBatchsTour = async (
  tourId?: string,
  cartId?: string,
  limit: number = 30,
  pageNumber: number = 1,
  selectedCategories?: string[],
  selectedProducers?: string[],
  search?: string,
): Promise<{ data: Batch[]; pagination: Pagination }> => {
  if (!tourId || !cartId)
    return {
      data: [],
      pagination: { page: 0, totalPages: 0, totalCount: 0, noFixedPages: true, limit: 0 },
    }

  const response = await ApiService.get(`/batch/tour/${tourId}/cart/${cartId}`, {
    limit: limit,
    page: pageNumber,
    categories: selectedCategories,
    selectedProducers: selectedProducers,
    search,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getAllBatchsProducerComputed = async (
  producerId: string,
  start?: Date,
  end?: Date,
  tourId?: string,
  types?: BatchEnums.TYPE[],
  limit: number = 30,
  pageNumber: number = 1,
  search: string = '',
): Promise<{ data: ProductBatchs[]; pagination: Pagination }> => {
  if (!start && !end) {
    return {
      data: [],
      pagination: {
        page: 0,
        totalPages: 0,
        totalCount: 0,
        noFixedPages: true,
        limit: 0,
      },
    }
  }
  const response = await ApiService.get(`/batch/producer/${producerId}/computed`, {
    after: start ? FormatUtils.toIsoString(start) : null,
    before: end ? FormatUtils.toIsoString(end) : null,
    tourId: tourId ? tourId : null,
    types: types ? types : null,
    limit,
    page: pageNumber,
    search,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOneBatch = async (batchId: string, withOtherProducersBatchs?: boolean): Promise<Batch> => {
  const response = await ApiService.get(`/batch/${batchId}`, {
    withOtherProducersBatchs: withOtherProducersBatchs ? true : undefined,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

export type PartialBatch = {
  unitPriceProducer?: number
  start?: Date
  end?: Date
  carrierFees?: number
  createdDate?: Date
}

const getComputed = async (
  productId?: string,
  partialBatch?: PartialBatch,
): Promise<BatchComputed | null> => {
  if (!productId || !partialBatch || !partialBatch.unitPriceProducer) return null
  const response = await ApiService.get(`/batch/computed/product/${productId}`, {
    unitPriceProducer: partialBatch.unitPriceProducer,
    start: partialBatch.start,
    end: partialBatch.end,
    carrierFees: partialBatch.carrierFees,
    createdDate: partialBatch.createdDate,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const create = async (producerId: string, newBatch: any): Promise<Batch> => {
  const response = await ApiService.post(`/batch/producer/${producerId}`, newBatch).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const duplicateBatchs = async (
  producerId: string,
  body: any,
): Promise<{ data: Batch[]; pagination: Pagination }> => {
  const response = await ApiService.post(`/batch/producer/${producerId}/duplicate`, body)
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const update = async (batchId: string, batch: any): Promise<Batch> => {
  const response = await ApiService.patch(`/batch/${batchId}`, batch).catch((error) => {
    throw error
  })
  return response
}

const updateCartBatchs = async (
  producerId: string,
  batchId: string,
  cartBatchs: Partial<CartBatch>[],
): Promise<CartBatch[]> => {
  const body = {
    cartBatchs: cartBatchs,
  }
  const response = await ApiService.patch(
    `/batch/${batchId}/producer/${producerId}/cartBatch`,
    body,
  ).catch((error) => {
    throw error
  })
  return response
}

const remove = async (batchId: string): Promise<Batch> => {
  const response = await ApiService.remove(`/batch/${batchId}`).catch((error) => {
    throw error
  })
  return response
}

const conclude = async (batchId: string): Promise<Batch> => {
  const response = await ApiService.patch(`/batch/${batchId}/conclude`, {}).catch((error) => {
    throw error
  })
  return response
}

const BatchProducerService = {
  getAllBatchs,
  getAllBatchsProducerComputed,
  getAllBatchsTour,
  getOneBatch,
  getComputed,
  create,
  update,
  remove,
  duplicateBatchs,
  updateCartBatchs,
  conclude,
}

export default BatchProducerService
