import styled from 'styled-components/native'

const ToursContentWrapper = styled.View`
  flex: 1;
  width: 95%;
  margin: ${({ theme }) => theme.spacingUnit * 1}px;
  margin-top: 0;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

export { ToursContentWrapper, StyledButtonWrapper }
