import React from 'react'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { CartCardProps } from './CartCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
  StyledTimeBox,
  StyledTimeBoxLeft,
  StyledTimeBoxRight,
} from './CartCard.styles'

const CartCard = ({ cart, onClick }: CartCardProps) => {
  const theme = useTheme()

  const OnClickCart = () => {
    onClick(cart)
  }

  if (!cart) return null

  return (
    <StyledCardWrapper onPress={OnClickCart} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.CardTitle>Panier #{FormatUtils.formatId(cart.id)}</Typography.CardTitle>
            <Typography.BodyExtraSmall colorName="text-dark-3" bold>
              livraison du{' '}
            </Typography.BodyExtraSmall>
            <Typography.BodySmall colorName="text-dark-3" bold>
              {FormatUtils.capitalize(
                FormatUtils.formatDate(
                  cart.tour?.start,
                  'FullDate+StartTime+EndTime',
                  cart.tour?.end,
                ),
              )}
            </Typography.BodySmall>
            <Typography.BodyExtraSmall colorName="text-dark-3" bold>
              créé le{' '}
              {FormatUtils.capitalize(
                FormatUtils.formatDate(cart.createdDate, 'FullDate+StartTime'),
              )}
            </Typography.BodyExtraSmall>
            <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
          </StyledLeftBox>
          <StyledRightBox>
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              size={theme.spacingUnit * 2}
              color={theme.colors['text-dark-3']}
            />
            <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />

            <Typography.BodySmall colorName="text-dark-3" bold>
              fin des commandes
            </Typography.BodySmall>
            <StyledTimeBox>
              <StyledTimeBoxRight>
                <Typography.BodySmall bold>
                  {FormatUtils.formatDate(cart.tour?.close, 'FullDate+StartTime')}
                </Typography.BodySmall>
              </StyledTimeBoxRight>
            </StyledTimeBox>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default CartCard
