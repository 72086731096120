import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  SectionTitle,
  Spacer,
  TextInput,
  Typography,
} from '../../../components'

import { CardContentWrapper, StyledButtonWrapper, StyledCardCity } from './CityForm.styles'
import { CityFormProps } from './CityForm.model'
import { AddCity, City } from '../../../domain'
import CityService from '../../../services/common/city'
import { queryClient } from '../../../utilities/queryClient'
import { View } from 'react-native'

const CityForm = ({ cities, onChangeCity, selectedCity }: CityFormProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isAddMode, setIsAddMode] = useState(false)
  const [newCity, setNewCity] = useState<AddCity>({
    label: '',
    postalCode: '',
  })
  const [similarCities, setSimilarCities] = useState<City[] | undefined>()

  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (!selectedCity || !selectedCity.id) return
    onChangeCity(selectedCity)
  }, [selectedCity])

  const setValue = (value: any, param: string) => {
    setErrorMessage('')

    if (!param && !(param in newCity)) return null
    const tempContainer = Object.assign({}, newCity)

    tempContainer[param as keyof typeof newCity] = value
    setNewCity(tempContainer)
  }

  const fetchSimilarCities = async () => {
    if (newCity.label.length >= 2 || newCity.postalCode.length >= 2) {
      await CityService.getAll(newCity.label + ' ' + newCity.postalCode).then((result) => {
        result && setSimilarCities(result)
      })
    }
  }

  const onAddCity = async () => {
    if (!newCity.label || !newCity.postalCode) {
      setErrorMessage(`Veuillez compléter tous les champs`)
      setIsLoading(false)
      return
    }

    if (newCity.postalCode.length !== 5) {
      setErrorMessage(`Le code postal doit contenir 5 chiffres`)
      setIsLoading(false)
      return
    }
    setErrorMessage('')
    setIsLoading(true)
    const cityToAdd = {
      label: newCity.label,
      postalCode: newCity.postalCode,
    }

    const addedCity = await CityService.create(cityToAdd)
    if (addedCity && addedCity.id) {
      setIsAddMode(false)
      if (onChangeCity) {
        onChangeCity(addedCity)
      }
      queryClient.invalidateQueries(['cities'])
    } else {
      setErrorMessage("Un problème est survenu lors de l'ajout de la ville")
    }

    setIsLoading(false)
  }

  const displayAddForm = () => {
    if (!isAddMode) return undefined

    return (
      <>
        <Row>
          <Col xs={12} sm={12} md={6}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newCity.postalCode}
              label="Code postale"
              field="text"
              onChangeText={(text) => setValue(text, 'postalCode')}
              onBlurCallBack={() => fetchSimilarCities()}
            />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newCity.label}
              label="Ville"
              field="text"
              onChangeText={(text) => setValue(text, 'label')}
              onBlurCallBack={() => fetchSimilarCities()}
            />
          </Col>
        </Row>
        <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
        {renderSimilarCities()}
        <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
        {(!similarCities || similarCities.length < 1) && (
          <Button
            small
            style={{ alignSelf: 'flex-end' }}
            label={'Annuler'}
            colorName="color-grey"
            onPress={() => setIsAddMode(!isAddMode)}
          />
        )}
        <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
        {errorMessage ? (
          <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
        ) : null}
        <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />

        <StyledButtonWrapper>
          <Button
            label={'Ajouter'}
            onPress={onAddCity}
            disabled={newCity.label === ''}
            loading={isLoading}
          />
        </StyledButtonWrapper>
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      </>
    )
  }
  const renderSimilarCities = () => {
    if (!similarCities || similarCities.length < 1) return <></>
    let cities = [...similarCities]
    if (similarCities.length > 3) {
      cities = similarCities.slice(0, 3)
    }

    return (
      <View>
        <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
        <View style={{ alignItems: 'center' }}>
          <Typography.Body semiBold align="center">
            Il existe des villes similaires. Souhaitez-vous en sélectionner une ?
          </Typography.Body>
          <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
          <Button
            small
            label="Non"
            colorName="color-grey"
            onPress={() => {
              setSimilarCities(undefined)
            }}
          />
          <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
        </View>
        <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
        {cities.map((city) => (
          <>
            <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
            <Card key={city.id}>
              <CardContentWrapper>
                <Typography.Body>{city.label + ' (' + city.postalCode + ')'}</Typography.Body>
                <Button
                  label="Sélectionner"
                  onPress={() => {
                    onChangeCity(city)
                    setIsAddMode(!isAddMode)
                    setSimilarCities(undefined)
                    setNewCity({ label: '', postalCode: '' })
                  }}
                  small
                />
              </CardContentWrapper>
            </Card>
          </>
        ))}
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      </View>
    )
  }

  const displayCity = () => {
    if (!cities || isAddMode) return undefined

    return (
      <StyledCardCity>
        <>
          <Dropdown
            label={'Ville de destination'}
            onChange={onChangeCity}
            multiple={false}
            defaultValue={selectedCity?.id}
            optionsDefault={
              !cities
                ? []
                : cities.map((city) => ({
                    ...city,
                    label: city.label + ' (' + city.postalCode + ')',
                    value: city.id,
                  }))
            }
            zIndex={1000}
            zIndexInverse={4000}
            itemKey="city"
            isSearchable
          />
          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          <Button.Icon
            iconName="add"
            small
            style={{ alignSelf: 'flex-end' }}
            label={'Ajouter une ville'}
            onPress={() => setIsAddMode(!isAddMode)}
          />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        </>
        <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
      </StyledCardCity>
    )
  }

  return (
    <>
      <SectionTitle title="Ville de destination" />
      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      {displayAddForm()}
      {displayCity()}
    </>
  )
}

export default CityForm
