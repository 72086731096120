import { useState } from 'react'
import { Alert, View } from 'react-native'
import { Typography, Card, Button, Row, Col, Spacer, SectionTitle } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { ClientOrderSummaryProps } from './ClientOrderSummary.model'
import {
  BoxWrapper,
  StyledCardWrapperTour,
  StyledCardWrapperCart,
  StyledButtonWrapper,
  CartLine,
  CartLineLabel,
  CartLineValue,
} from './ClientOrderSummary.styles'
import CartClientService from '../../../../../services/client/cart'
import StorageUtil from '../../../../../utilities/storage/storage'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ClientStackParamList } from '../../../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import TotalSummaryCard from '../../../../../components/TotalSummaryCard'
import CardSettings from '../../../../../components/CardSettings'

const ClientOrderSummary = ({ order, isUpdatedOrder }: ClientOrderSummaryProps) => {
  const [seeMore, setSeeMore] = useState<boolean | null>(false)
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [isLoading, setIsLoading] = useState(false)

  const onUpdateOrder = async () => {
    if (!order || !order.id || !order.cart?.client?.id || !order?.cart?.id) return
    setIsLoading(true)
    setErrorMessage('')
    const newCart = await CartClientService.createUpdateOrder(order.cart.client.id, order.cart.id)
    if (newCart && newCart.id) {
      await StorageUtil.setItem('c_cart_id', newCart.id)
      const now = new Date()
      now.setMinutes(now.getMinutes() + 60)
      await StorageUtil.setItem('c_cart_id_expiration_date', now)

      navigation.navigate('ClientCatalog', {
        screen: 'ClientCatalog',
        params: {},
      })
      setIsLoading(false)
      return
    } else {
      setErrorMessage('Erreur lors de la création du panier de modification de commande')
    }
    setIsLoading(false)
  }

  const canUpdateOrder = () => {
    const tourOpen = new Date(order?.cart?.tour?.open || '')
    const tourClose = new Date(order?.cart?.tour?.close || '')
    const today = new Date()

    if (tourOpen < today && tourClose > today) {
      return true
    }

    return false
  }

  const onPressPrintShelveLabels = () => {
    if (order.id) {
      navigation.navigate('ClientPrintOrderRecap', {
        orderId: order.id,
      })
    }
  }

  const totalSummaryItems = [
    {
      totalHT: order.totalHTBatchs || 0,
      totalVAT: (order.totalVAT55 || 0) + (order.totalVAT10 || 0) + (order.totalVAT20 || 0),
    },
  ]

  return (
    <BoxWrapper>
      <StyledCardWrapperTour onPress={() => null}>
        <Card>
          <Typography.Body colorName="color-primary" bold>
            Livraison
          </Typography.Body>
          <Typography.Body>
            {FormatUtils.formatDate(
              order.cart?.tour?.start,
              'FullDate+StartTime+EndTime',
              order.cart?.tour?.end,
            )}
          </Typography.Body>
          <Typography.BodySmall colorName="text-dark-3">
            Fin des commandes{' '}
            {FormatUtils.formatDate(order.cart?.tour?.close, 'FullDate+StartTime')}
          </Typography.BodySmall>
        </Card>
      </StyledCardWrapperTour>

      <StyledCardWrapperCart onPress={() => null}>
        <Typography.Body colorName="color-primary" bold>
          Totaux
        </Typography.Body>
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

        <TotalSummaryCard items={totalSummaryItems} />

        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {order.cart?.tour?.deliveryFeesHT && order.cart?.tour?.deliveryFeesHT > 0 ? (
          <>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <CartLine>
              <CartLineLabel>
                <View>
                  <Typography.Body>Frais de livraison</Typography.Body>
                </View>
              </CartLineLabel>
              <CartLineValue>
                <Typography.Body colorName="text-dark-3">
                  {order.deliveryFeesHT && order.deliveryFeesHT > 0
                    ? `+${FormatUtils.formatPrice(order.deliveryFeesHT)}`
                    : 'Offerts'}
                </Typography.Body>
              </CartLineValue>
            </CartLine>
          </>
        ) : undefined}
      </StyledCardWrapperCart>
      <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
      {errorMessage ? (
        <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
      ) : null}
      <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
      <StyledButtonWrapper>
        <CardSettings
          title="Code-barres caisse et étiquettes produits"
          description={`Préparez la réception de la marchandise`}
          children={
            <Button
              colorName="color-secondary"
              label={'Imprimer'}
              small
              onPress={() => onPressPrintShelveLabels()}
            />
          }
        />
        <Spacer size={1.5} axis={Spacer.AxisEnum.VERTICAL} />
        {order.cart?.tour?.start && new Date(order.cart?.tour?.start) > new Date() ? (
          <CardSettings
            title="Modification de commande"
            description={
              canUpdateOrder()
                ? `Ajoutez, ajustez, supprimer un produit`
                : `La prise de commande est fermée, la commande n'est plus modifiable`
            }
            descriptionColor={canUpdateOrder() ? 'color-primary' : 'color-danger'}
            children={
              <Button
                colorName={canUpdateOrder() ? 'color-secondary' : 'color-grey'}
                label={'Modifier'}
                onPress={() => onUpdateOrder()}
                disabled={!canUpdateOrder()}
                loading={isLoading}
                hasDoubleValidation
                small
                confirmationLabel="Êtes-vous sûr de vouloir modifier cette commande ?"
              />
            }
          />
        ) : undefined}

        <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
      </StyledButtonWrapper>
    </BoxWrapper>
  )
}

export default ClientOrderSummary
