import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Card,
  Col,
  Dropdown,
  Icon,
  Icons,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { AddCarrier, Carrier, Pagination, Producer, ProducerGroup } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import CarrierCarrierService from '../../../services/carrier/carrier'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import {
  ToursContentWrapper,
  StyledButtonWrapper,
  StyledCardContentWrapper,
  LeftCardContentWrapper,
  StyledCardWrapper,
  BackToListWrapper,
} from './CarrierAddCarrier.styles'
import GeneralUtils from '../../../utilities/utils/general'
import { useQuery } from '@tanstack/react-query'
import GroupCarrierService from '../../../services/carrier/group'
import ProducerCarrierService from '../../../services/carrier/producer'

const CarrierAddCarrierScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierAddCarrier'>>()
  const [pageSizeProducerGroups, setPageSizeProducerGroups] = useState(5)
  const [pageNumberProducerGroups, setPageNumberProducerGroups] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const { getAccessInfos } = useAuthenticationContext()

  const { groupId } = route?.params ?? { groupId: '' }

  const {
    data: producerGroups,
    refetch: refetchProducerGroups,
    isLoading: isProducerGroupsLoading,
  } = useQuery<{ data: ProducerGroup[]; pagination: Pagination }, Error>(
    ['sf_group_producers'],
    () =>
      GroupCarrierService.getOneGroupProducers(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        pageSizeProducerGroups,
        pageNumberProducerGroups,
      ),
    {
      keepPreviousData: true,
    },
  )

  const theme = useTheme()
  const [newCarrier, setNewCarrier] = useState<AddCarrier>({
    name: '',
    legalName: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    city: '',
    phone1: '',
    phone2: '',
    mail: '',
    siret: '',
    apeCode: '',
    vatNumber: '',
  })
  const [carrierMailRequest, setCarrierMailRequest] = useState<string>('')
  const [selectedProducer, setSelectedProducer] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [foundCarrier, setFoundCarrier] = useState<Carrier>()
  const [foundProducer, setFoundProducer] = useState<Producer>()
  const [formIsOpen, setFormIsOpen] = useState<boolean>(false)
  const [displayNoProfile, setDisplayNoProfile] = useState<boolean>(false)

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newCarrier)) return null

    const clientTemp = Object.assign({}, newCarrier)

    clientTemp[param as keyof typeof newCarrier] = value
    setNewCarrier(clientTemp)
  }

  const onAddCarrier = async () => {
    setErrorMessage('')
    if (!groupId) {
      setErrorMessage('Erreur de chargement du groupe')
      return
    }
    if (newCarrier.mail && !GeneralUtils.isEmail(newCarrier.mail)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    const carrier = {
      name: newCarrier.name,
      legalName: newCarrier.legalName,
      addressLine1: newCarrier.addressLine1,
      addressLine2: newCarrier.addressLine2,
      postalCode: newCarrier.postalCode,
      city: newCarrier.city,
      phone1: newCarrier.phone1,
      phone2: newCarrier.phone2,
      mail: newCarrier.mail,
      siret: newCarrier.siret,
      apeCode: newCarrier.apeCode,
      vatNumber: newCarrier.vatNumber,
    }
    if (
      !carrier.mail ||
      !carrier.name ||
      !carrier.legalName ||
      !carrier.addressLine1 ||
      !carrier.postalCode ||
      !carrier.city ||
      !carrier.phone1
    ) {
      setErrorMessage('Veuillez remplir tous les champs obligatoires')
      setIsLoading(false)
      return
    }
    let createdCarrier
    try {
      createdCarrier = await CarrierCarrierService.create(groupId, carrier)
    } catch (error) {
      setErrorMessage(
        `Un problème est survenu lors de la création du producteur-livreur, veuillez vérifier qu'il n'existe pas déjà un producteur-livreur avec cet email`,
      )
      setIsLoading(false)
      return
    }

    if (createdCarrier && createdCarrier.id) {
      queryClient.invalidateQueries(['sf_group'])
      queryClient.invalidateQueries(['sf_group_carriers'])

      navigation.navigate('CarrierUpdateCarrier', { id: createdCarrier.id })
    } else {
      setErrorMessage('Un problème est survenu lors de la création du producteur-livreur')
    }
    setIsLoading(false)
  }

  const onChangeProducer = (value: any) => {
    setErrorMessage('')
    setCarrierMailRequest('')
    setSelectedProducer(value)
  }
  const onRequestSearchMail = async () => {
    if (carrierMailRequest && !GeneralUtils.isEmail(carrierMailRequest)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }
    let foundExistingCarrier

    try {
      foundExistingCarrier = await CarrierCarrierService.findCarrierByEmail(carrierMailRequest)

      if (
        foundExistingCarrier &&
        foundExistingCarrier.carrierGroups.find((cg) => cg.group.id === groupId)
      ) {
        setErrorMessage('Ce producteur-livreur est déjà lié à ce groupe')
        return
      }

      if (foundExistingCarrier) {
        setFoundCarrier(foundExistingCarrier)
      }
    } catch (error) {
      console.error(error)
    }

    let foundExistingProducer
    try {
      foundExistingProducer = await ProducerCarrierService.getOneProducer(
        getAccessInfos().carrierId,
        undefined,
        carrierMailRequest,
      )
      if (foundExistingProducer) {
        setFoundProducer(foundExistingProducer)
      }
    } catch (error) {
      console.error(error)
    }

    if (!foundExistingCarrier && !foundExistingProducer) {
      setDisplayNoProfile(true)
    }
  }

  const onValidateProducer = async (foundProducer?: Producer) => {
    setErrorMessage('')
    const producer = producerGroups?.data.find((pg) => pg.producer?.id === selectedProducer?.value)

    if (producer && producer.producer?.mail) {
      let foundExistingCarrier
      try {
        foundExistingCarrier = await CarrierCarrierService.findCarrierByEmail(
          producer.producer?.mail,
        )

        if (foundExistingCarrier) {
          await CarrierCarrierService.linkCarrier(foundExistingCarrier.id, groupId)
          navigation.navigate('CarrierUpdateCarrier', { id: foundExistingCarrier.id })
          queryClient.invalidateQueries(['sf_group'])
          queryClient.invalidateQueries(['sf_group_carriers'])
          return
        }
      } catch (error) {
        console.error(error)
      }

      const producerAddress = producer.producer?.address

      const carrierToAdd = {
        name: producer.producer?.label,
        legalName: producer.producer?.label,
        addressLine1: producerAddress?.addressLine1 || producer.producer?.addressLine1,
        addressLine2: producerAddress?.addressLine2 || producer.producer?.addressLine2,
        postalCode: producerAddress?.postalCode || producer.producer?.postalCode,
        city: producerAddress?.city || producer.producer?.city,
        phone1: producer.producer?.phone1,
        phone2: producer.producer?.phone2,
        mail: producer.producer?.mail,
        siret: producer.producer?.account?.legalInfo.siret,
        apeCode: producer.producer?.account?.legalInfo.apeCode,
        vatNumber: producer.producer?.account?.legalInfo.vatNumber,
        accountId: producer.producer?.account?.id,
      }
      setNewCarrier(carrierToAdd)
    } else if (foundProducer) {
      const producerAddress = foundProducer?.address

      const carrierToAdd = {
        name: foundProducer?.label,
        legalName: foundProducer?.label,
        addressLine1: producerAddress?.addressLine1 || foundProducer?.addressLine1,
        addressLine2: producerAddress?.addressLine2 || foundProducer?.addressLine2,
        postalCode: producerAddress?.postalCode || foundProducer?.postalCode,
        city: producerAddress?.city || foundProducer?.city,
        phone1: foundProducer?.phone1,
        phone2: foundProducer?.phone2,
        mail: foundProducer?.mail,
        siret: foundProducer?.account?.legalInfo.siret,
        apeCode: foundProducer?.account?.legalInfo.apeCode,
        vatNumber: foundProducer?.account?.legalInfo.vatNumber,
        accountId: foundProducer?.account?.id,
      }
      let createdCarrier
      try {
        createdCarrier = await CarrierCarrierService.create(groupId, carrierToAdd)
      } catch (error) {
        setErrorMessage(
          `Un problème est survenu lors de la création du producteur-livreur, veuillez vérifier qu'il n'existe pas déjà un producteur-livreur avec cet email`,
        )
        setIsLoading(false)
        return
      }

      if (createdCarrier && createdCarrier.id) {
        queryClient.invalidateQueries(['sf_group'])
        queryClient.invalidateQueries(['sf_group_carriers'])
        navigation.navigate('CarrierUpdateCarrier', { id: createdCarrier.id })
        setIsLoading(false)
      } else {
        setErrorMessage('Un problème est survenu lors de la création du producteur-livreur')
      }
    } else {
      return
    }
    setDisplayNoProfile(false)
    setFormIsOpen(true)
  }
  const handleBackToList = () => {
    setCarrierMailRequest('')
    setFoundCarrier(undefined)
    setFoundProducer(undefined)
    setFormIsOpen(false)
    setDisplayNoProfile(false)
    setErrorMessage('')
    setNewCarrier({
      name: '',
      legalName: '',
      addressLine1: '',
      addressLine2: '',
      postalCode: '',
      city: '',
      phone1: '',
      phone2: '',
      mail: '',
      siret: '',
      apeCode: '',
      vatNumber: '',
    })
  }

  const onValidateAddExistingCarrier = async () => {
    if (!foundCarrier?.id) {
      return
    }
    await CarrierCarrierService.linkCarrier(foundCarrier.id, groupId)
    queryClient.invalidateQueries(['sf_group'])
    queryClient.invalidateQueries(['sf_group_carriers'])
    navigation.navigate('CarrierUpdateCarrier', { id: foundCarrier.id })
  }
  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Ajouter un producteur-livreur" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {!formIsOpen && (
            <>
              {(foundCarrier || foundProducer) && (
                <BackToListWrapper onPress={handleBackToList}>
                  <Icon
                    type={Icons.Ionicons}
                    name="chevron-back-outline"
                    color={theme.colors['color-primary']}
                  />
                  <Button.Text label={'Revenir en arrière'} onPress={handleBackToList} />
                </BackToListWrapper>
              )}
              <SectionTitle title="Saisir un email" />
              <Row rowStyles={{ justifyContent: 'center', alignItems: 'center' }}>
                <Col xs={9}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={carrierMailRequest}
                    label="Email"
                    field="text"
                    onChangeText={(text) => {
                      setCarrierMailRequest(text)
                      setFoundCarrier(undefined)
                      setFoundProducer(undefined)
                      setErrorMessage('')
                      setDisplayNoProfile(false)
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <StyledButtonWrapper>
                    {!(foundCarrier || foundProducer) && !displayNoProfile && (
                      <Button
                        label="Vérifier"
                        onPress={() => onRequestSearchMail()}
                        loading={isLoading}
                      />
                    )}
                  </StyledButtonWrapper>
                </Col>
              </Row>
              <Row>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                {errorMessage ? (
                  <>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                    <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  </>
                ) : null}
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              </Row>
              {(foundCarrier || foundProducer) && (
                <>
                  <Typography.Body colorName="color-grey">
                    Nous avons trouvé les comptes suivants avec cette adresse email
                  </Typography.Body>
                  {foundCarrier && (
                    <>
                      <SectionTitle title="Producteur-livreur" />
                      <Row>
                        <Col xs={12}>
                          <StyledCardWrapper>
                            <Card>
                              <StyledCardContentWrapper>
                                <LeftCardContentWrapper>
                                  <Typography.CardTitle>{foundCarrier?.name}</Typography.CardTitle>
                                  <Typography.BodySmall>
                                    {foundCarrier?.legalName}
                                  </Typography.BodySmall>
                                </LeftCardContentWrapper>

                                <StyledButtonWrapper>
                                  <Button
                                    label={'Lier'}
                                    onPress={() => onValidateAddExistingCarrier()}
                                    loading={isLoading}
                                  />
                                </StyledButtonWrapper>
                              </StyledCardContentWrapper>
                            </Card>
                          </StyledCardWrapper>
                        </Col>
                      </Row>
                    </>
                  )}

                  {foundProducer && (
                    <>
                      <SectionTitle title="Producteur" />
                      <StyledCardWrapper>
                        <Card>
                          <StyledCardContentWrapper>
                            <LeftCardContentWrapper>
                              <Typography.CardTitle>{foundProducer?.label}</Typography.CardTitle>
                              <Typography.BodySmall>{foundProducer?.owners}</Typography.BodySmall>
                            </LeftCardContentWrapper>

                            <StyledButtonWrapper>
                              <Button
                                label={'Lier'}
                                onPress={() => onValidateProducer(foundProducer)}
                                loading={isLoading}
                              />
                            </StyledButtonWrapper>
                          </StyledCardContentWrapper>
                        </Card>
                      </StyledCardWrapper>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {displayNoProfile && (
            <>
              <Typography.Body colorName="color-grey">
                Cette adresse email n'est pas liée à un compte Producteur-livreur, vous pouvez en
                créer un.
              </Typography.Body>
              <SectionTitle title="À partir d'un producteur" />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Row>
                <Col xs={9}>
                  <Dropdown
                    label={'Producteur'}
                    onChange={onChangeProducer}
                    multiple={false}
                    defaultValue={selectedProducer?.id}
                    optionsDefault={
                      !producerGroups
                        ? []
                        : producerGroups.data.map((pg) => ({
                            label: pg.producer?.label,
                            value: pg.producer?.id,
                          }))
                    }
                    itemKey="selectedProducer"
                  />
                </Col>
                <Col xs={3}>
                  <StyledButtonWrapper>
                    {selectedProducer && (
                      <Button
                        label={'Valider'}
                        onPress={() => onValidateProducer()}
                        loading={isLoading}
                      />
                    )}
                  </StyledButtonWrapper>
                </Col>
              </Row>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Typography.Body align="center" bold>
                OU
              </Typography.Body>
              <SectionTitle title="Créer un nouveau compte" />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />
              <Row>
                <Col xs={12}>
                  <StyledButtonWrapper>
                    <Button
                      label={'Créer un compte'}
                      onPress={() => {
                        setFormIsOpen(true)
                        setDisplayNoProfile(false)
                      }}
                      loading={isLoading}
                    />
                  </StyledButtonWrapper>
                </Col>
              </Row>
            </>
          )}
          {formIsOpen && (
            <>
              <BackToListWrapper onPress={handleBackToList}>
                <Icon
                  type={Icons.Ionicons}
                  name="chevron-back-outline"
                  color={theme.colors['color-primary']}
                />
                <Button.Text label={'Revenir en arrière'} onPress={handleBackToList} />
              </BackToListWrapper>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCarrier.name}
                    label="Nom du producteur-livreur"
                    field="text"
                    onChangeText={(text) => setValue(text, 'name')}
                    required
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCarrier.legalName}
                    label="Nom légal"
                    field="text"
                    onChangeText={(text) => setValue(text, 'legalName')}
                    required
                    hidden={!newCarrier.name}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCarrier.mail}
                    label="Email"
                    field="text"
                    onChangeText={(text) => setValue(text, 'mail')}
                    required
                    hidden={!newCarrier.legalName}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCarrier.phone1}
                    label="Téléphone portable (0606...)"
                    field="text"
                    onChangeText={(text) => setValue(text, 'phone1')}
                    required
                    hidden={!newCarrier.legalName}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCarrier.phone2}
                    label="Téléphone Fixe (0505...)"
                    field="text"
                    onChangeText={(text) => setValue(text, 'phone2')}
                    hidden={!newCarrier.legalName}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCarrier.addressLine1}
                    label="Adresse (n°, rue)"
                    field="text"
                    onChangeText={(text) => setValue(text, 'addressLine1')}
                    required
                    hidden={!newCarrier.legalName}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCarrier.addressLine2}
                    label="Complément d'adresse"
                    field="text"
                    onChangeText={(text) => setValue(text, 'addressLine2')}
                    hidden={!newCarrier.legalName}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCarrier.postalCode}
                    label="Code postal"
                    field="text"
                    onChangeText={(text) => setValue(text, 'postalCode')}
                    required
                    hidden={!newCarrier.legalName}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCarrier.city}
                    label="Ville"
                    field="text"
                    onChangeText={(text) => setValue(text, 'city')}
                    required
                    hidden={!newCarrier.legalName}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCarrier.siret}
                    label="Siret"
                    field="text"
                    onChangeText={(text) => setValue(text, 'siret')}
                    hidden={!newCarrier.legalName}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCarrier.apeCode}
                    label="Code APE"
                    field="text"
                    onChangeText={(text) => setValue(text, 'apeCode')}
                    hidden={!newCarrier.legalName}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCarrier.vatNumber}
                    label="Numéro de TVA"
                    field="text"
                    onChangeText={(text) => setValue(text, 'vatNumber')}
                    hidden={!newCarrier.legalName}
                  />
                </Col>
              </Row>

              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <Row>
                <Col xs={12} alignItems="center">
                  {errorMessage ? (
                    <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                  ) : null}
                  <StyledButtonWrapper>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                    {newCarrier.legalName && (
                      <Button
                        label={FormatUtils.capitalize(i18n.t('add'))}
                        onPress={() => onAddCarrier()}
                        loading={isLoading}
                      />
                    )}
                  </StyledButtonWrapper>
                </Col>
              </Row>
            </>
          )}
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierAddCarrierScreen
