import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Row,
  Spacer,
  Typography,
  PageTitle,
  SectionTitle,
  Dropdown,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { Address, Producer, UpdateProducer } from '../../../domain'
import i18n from '../../../i18n'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { StyledButtonWrapper, ToursContentWrapper } from './ProducerInformations.styles'
import { View } from 'react-native'
import ProducerProducerService from '../../../services/producer/producer'
import { PRODUCER_VAT_TYPES } from '../../../../enums/producer'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import AddressForm from '../../../modules/Address/AddressForm'
import LabelForm from '../../../modules/Common/LabelForm'

const ProducerInformationsScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedVatType, setSelectedVatType] = useState<any | undefined>(undefined)

  const {
    data: producerData,
    refetch: refetchProducer,
    isInitialLoading: producerLoading,
  } = useQuery<{ producer: Producer; stepsData: TutorialStepData[] }, Error>(
    ['p_producer', getAccessInfos().producerId],
    () => ProducerProducerService.getOne(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )
  const [updatedProducer, setUpdatedProducer] = useState<UpdateProducer>()

  useEffect(() => {
    if (!producerData?.producer) return

    setUpdatedProducer({
      ...producerData.producer,
    })
    const vatType = PRODUCER_VAT_TYPES.find(
      (mt: any) => mt.value === producerData.producer?.account?.legalInfo.vatType,
    )

    if (vatType) {
      setSelectedVatType({
        ...vatType,
      })
    } else {
      setSelectedVatType({
        label: 'Choisir une option',
        value: 0,
      })
    }
  }, [producerData?.producer])

  if (!updatedProducer || !producerData?.producer) return <View></View>

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!updatedProducer || !param || !(param in updatedProducer)) return null
    const producerTemp = Object.assign({}, updatedProducer)

    producerTemp[param as keyof typeof updatedProducer] = value
    setUpdatedProducer(producerTemp)
  }

  const onUpdateProducer = async () => {
    setErrorMessage('')
    setIsLoading(true)
    const producer = {
      phone1: updatedProducer.phone1,
      phone2: updatedProducer.phone2,
      addressLine1: updatedProducer.addressLine1,
      addressLine2: updatedProducer.addressLine2,
      postalCode: updatedProducer.postalCode,
      city: updatedProducer.city,
      mail: updatedProducer.mail,
      deliveryNoteStatment1: updatedProducer.deliveryNoteStatment1,
      deliveryNoteStatment2: updatedProducer.deliveryNoteStatment2,
      deliveryNoteBottomStatment: updatedProducer.deliveryNoteBottomStatment,
      labels: updatedProducer.labels,
    }

    const updatedProducerTemp = await ProducerProducerService.update(
      getAccessInfos().producerId,
      producer,
    )
    if (updatedProducerTemp && updatedProducerTemp.id) {
      await queryClient.invalidateQueries(['p_producer', getAccessInfos().producerId])

      navigation.navigate('ProfileProducerNav', {
        screen: 'ProducerProfile',
        params: {},
      })
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour de vos informations')
    }
    setIsLoading(false)
  }

  const onChangeVatType = (value: any) => {
    setSelectedVatType(value)
  }

  const onChangeAddress = (newAddress: Address) => {
    if (newAddress && newAddress.id) {
      const producerTemp = Object.assign({}, updatedProducer)

      producerTemp['address'] = newAddress
      setUpdatedProducer(producerTemp)
    }
  }

  return (
    <ScreenSafeAreaWrapper>
      <ContentWrapper noPaddingLeft>
        <ToursContentWrapper>
          <PageTitle title="Modifier mes informations" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper paddingBottom="50px">
            <Row>
              <Col xs={6} sm={6} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.phone1}
                  label="N° portable"
                  field="text"
                  onChangeText={(text) => setValue(text, 'phone1')}
                />
              </Col>
              <Col xs={6} sm={6} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.phone2}
                  label="N° fixe"
                  field="text"
                  onChangeText={(text) => setValue(text, 'phone2')}
                />
              </Col>
            </Row>
            <AddressForm
              onChangeAddress={onChangeAddress}
              address={updatedProducer.address}
              producer={producerData.producer}
            />
            {!updatedProducer.address && (
              <>
                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedProducer.addressLine1}
                      label="Adresse (n°, rue)"
                      field="text"
                      onChangeText={(text) => setValue(text, 'addressLine1')}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedProducer.addressLine2}
                      label="Complément d'adresse"
                      field="text"
                      onChangeText={(text) => setValue(text, 'addressLine2')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} sm={6} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedProducer.postalCode}
                      label="Code postale"
                      field="text"
                      onChangeText={(text) => setValue(text, 'postalCode')}
                    />
                  </Col>
                  <Col xs={6} sm={6} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedProducer.city}
                      label="Ville"
                      field="text"
                      onChangeText={(text) => setValue(text, 'city')}
                    />
                  </Col>
                </Row>
              </>
            )}

            <SectionTitle title="Pour vos bons de livraison" />
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.deliveryNoteStatment1}
                  label="Origine"
                  field="text"
                  onChangeText={(text) => setValue(text, 'deliveryNoteStatment1')}
                />
                <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodyExtraSmall colorName="color-grey">
                  Ex: Lot et Garonne, France
                </Typography.BodyExtraSmall>
              </Col>
              <Col xs={12} sm={12} md={12}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.deliveryNoteStatment2}
                  label="Certification"
                  field="text"
                  onChangeText={(text) => setValue(text, 'deliveryNoteStatment2')}
                />
                <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodyExtraSmall colorName="color-grey">
                  Ex: Certifié BIO (FR-BIO-00)
                </Typography.BodyExtraSmall>
              </Col>
              {/* <Col xs={12} sm={12} md={12}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.account?.billingStatement.billingStatement}
                  label="Condition de facturation / paiement"
                  field="text"
                  onChangeText={(text) =>
                    setAccountValue(
                      { ...updatedProducer.account?.billingStatement, billingStatement: text },
                      'billingStatement',
                    )
                  }
                />
                <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodyExtraSmall colorName="color-grey">
                  Ex: Paiements sous 15 jours - IBAN : FR00 000 000...
                </Typography.BodyExtraSmall>
              </Col> */}
            </Row>
            {/* <Row>
              <Col xs={6} sm={6} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.account?.legalInfo.siret}
                  label="Siret"
                  field="text"
                  onChangeText={(text) =>
                    setAccountValue(
                      { ...updatedProducer.account?.legalInfo, siret: text },
                      'legalInfo',
                    )
                  }
                />
              </Col>
              <Col xs={6} sm={6} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.account?.legalInfo.apeCode}
                  label="Code APE"
                  field="text"
                  onChangeText={(text) =>
                    setAccountValue(
                      { ...updatedProducer.account?.legalInfo, apeCode: text },
                      'legalInfo',
                    )
                  }
                />
              </Col>
              <Col xs={6} sm={6} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.account?.legalInfo.vatNumber}
                  label="Numéro de TVA"
                  field="text"
                  onChangeText={(text) =>
                    setAccountValue(
                      { ...updatedProducer.account?.legalInfo, vatNumber: text },
                      'legalInfo',
                    )
                  }
                />
              </Col>
            </Row> */}
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedProducer.deliveryNoteBottomStatment}
                  label="Pied de page (Siret - Code APE ...)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'deliveryNoteBottomStatment')}
                />
              </Col>
            </Row>

            <SectionTitle title="Paramètres du compte" />
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <Dropdown
              label={'Facturation de la TVA'}
              onChange={onChangeVatType}
              multiple={false}
              optionsDefault={PRODUCER_VAT_TYPES.map((vatType: any) => ({
                ...vatType,
              }))}
              zIndex={200}
              itemKey="vatType"
              defaultValue={selectedVatType.value}
            />
            <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodyExtraSmall colorName="color-grey">
              Si vous ne re-facturez pas la TVA (ex: micro-entreprise), les clients verront une
              mention s'afficher sur le prix de vos produits et les prix seront affichés directement
              en TTC sur le catalogue et dans vos BL.
            </Typography.BodyExtraSmall>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <LabelForm
              labels={updatedProducer.labels}
              type={2}
              onChangeLabel={(labels) => setValue(labels, 'labels')}
              title="Vos labels"
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <StyledButtonWrapper>
              {errorMessage ? (
                <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
              ) : null}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <Button
                label={FormatUtils.capitalize(i18n.t('save'))}
                onPress={() => onUpdateProducer()}
                loading={isLoading}
              />
            </StyledButtonWrapper>
          </ScrollableFormWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerInformationsScreen
