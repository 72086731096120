import { TranslationObject } from './TranslationObject.model'

// eslint-disable-next-line import/prefer-default-export
export const EN: TranslationObject = {
  // GET STARTED

  getStartedTitleIntro1: 'A new Social information network',
  getStartedTitleIntro2: 'useful and helpful',
  getStartedDescriptionIntro:
    'Build your own tree, join your friends forests, Share tips and hints, read, save, use',
  getStartedCreateAccount: 'Create Account',
  getStartedLogin: 'Login',

  // COMMON
  greeting: 'hi',
  changeLanguage: 'Change Language',
  chooseLanguage: 'Choose a language',

  // ACTION
  add: 'Add',
  added: 'Added',
  remove: 'Retirer',
  update: 'update',
  save: 'save',
  displayMore: 'See more',
  displayLess: 'See less',
  conclude: 'Conclude',

  // MOTS
  destination: 'destination',
  orders: 'orders',
  producers: 'producers',
  products: 'products',
  product: 'product',
  since: 'since',
  steps: 'steps',
  tours: 'Tours',
  tour: 'Tour',

  // PAGE TITLES
  pageTitleGroup: 'Group',
  pageTitleTours: 'Tours',
  pageTitleToursProducer: 'Your tours',
  pageTitleTour: 'Tour',
  pageTitleAddTour: 'Add Tour',
  pageTitleCatalog: 'Your catalog',
  pageTitleAddProduct: 'Add product',
  pageTitleUpdateProduct: 'Update product',
  pageTitleProposition: 'Your propositions',
  pageTitleAddBatch: 'Add product to proposition',
  pageTitleUpdateBatch: 'Update batch',

  // FARMERS GROUP
  groupName: `Producers' group name`,

  // TOURS
  nextTours: 'Next Tours',
  participantTours: 'tours you take part in',
  joinableTours: 'tours you can join',
  finishedTours: 'Finished Tours',
  addTour: 'Add new tour',
  organiseSteps: 'Organise steps',

  // CATALOG
  createProduct: 'Create product',

  // PROPOSITION
  propositionProducts: 'Available products for this tour',
  chooseTour: 'Choose Tour',
  seeProposition: 'See proposition',

  // BATCH
  addBatch: 'Add product',
  chooseProduct: 'Choose product',
}
