import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardWrapper = styled.View`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.2}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.2}px;
  flex-direction: row;
`

const StyledLeftBox = styled.View`
  flex: 1;
`

const StyledRightBox = styled.View`
  align-items: flex-end;
  justify-content: center;
`

const StyledTimeBox = styled.View`
  flex-direction: row;
  align-items: flex-start;
  justify-items: flex-start;
`

const StyledArrowBox = styled.View`
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: center;
`

const StyledTimeBoxLeft = styled.View`
  justify-content: flex-start;
  margin-left: ${({ theme }) => theme.spacingUnit * 1.3}px;
`

const StyledTimeBoxRight = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 0.5}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 0.2}px;
`

const StyledTourInfosBox = styled.View`
  flex-direction: row;
`

export {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledRightBox,
  StyledTimeBox,
  StyledTimeBoxLeft,
  StyledTimeBoxRight,
  StyledArrowBox,
  StyledTourInfosBox,
}
