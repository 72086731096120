import styled from 'styled-components/native'

const StyledTitleWrapper = styled.View`
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacingUnit * 5}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 3}px;
`

export { StyledTitleWrapper }
