import React, { useMemo } from 'react'
import { useTheme } from 'styled-components/native'
import { stringToKey } from '../../../utilities/helpers'
import { InterfaceColors } from '../../../utilities/styling/theme/theme'
import Icon, { Icons } from '../../Icon'
import { IconButtonProps } from '../Button.model'
import StyledTouchableOpacity from './ButtonIcon.styles'
import Text from '../../Text'
import Spacer from '../../Spacer'

const ButtonIcon = ({
  iconName,
  onPress,
  disabled,
  style,
  label,
  iconProps,
  noMargin,
  noPadding,
  colorName,
  rounded,
  small,
  iconType,
  iconPosition,
  bordered,
}: IconButtonProps) => {
  const theme = useTheme()
  const accessibilityLabel = label ? `button-text-${stringToKey(label)}` : 'button-text'

  const textColor = useMemo((): keyof InterfaceColors => {
    if (disabled) {
      return 'text-light-1'
    }

    if (colorName && colorName === 'color-grey') {
      return 'text-light-1'
    }

    if (colorName && colorName === 'color-primary') {
      return 'text-light-1'
    }

    if (colorName && colorName === 'color-primary-2') {
      return 'text-light-1'
    }

    return 'text-dark-1'
  }, [colorName, disabled])

  const colorNameComputed = useMemo((): keyof InterfaceColors => {
    if (disabled) {
      return 'color-grey'
    }

    return colorName || 'color-secondary'
  }, [colorName, disabled])

  const iconComponent = (
    <Icon
      type={
        iconType === 'Ionicons'
          ? Icons.Ionicons
          : iconType === 'MaterialCommunityIcons'
          ? Icons.MaterialCommunityIcons
          : ''
      }
      name={iconName}
      color={textColor}
      {...iconProps}
      size={small ? theme.spacingUnit * 1.7 : theme.spacingUnit * 2.5}
    />
  )

  const labelComponent = label && (
    <Text
      tag={Text.TextTag.BUTTON_LABEL}
      colorName={textColor}
      style={small ? { fontSize: `${theme.fontSizes.BUTTON_LABEL * 0.8}px` } : {}}
    >
      {label}
    </Text>
  )

  return (
    <StyledTouchableOpacity
      accessible
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="button"
      activeOpacity={theme.activeOpacity}
      onPress={onPress}
      disabled={disabled}
      style={style}
      noMargin={noMargin}
      noPadding={noPadding}
      colorName={colorNameComputed}
      rounded={rounded}
      small={small}
      bordered={bordered}
    >
      {iconType ? (
        <>
          {iconPosition === 'left' && (
            <>
              {iconComponent}
              {label && <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />}
              {labelComponent}
            </>
          )}
          {iconPosition === 'right' && (
            <>
              {labelComponent}
              {label && <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />}
              {iconComponent}
            </>
          )}
        </>
      ) : (
        labelComponent
      )}
    </StyledTouchableOpacity>
  )
}

ButtonIcon.defaultProps = {
  disabled: false,
}

export default ButtonIcon
