import { TourStartSlideProps } from './TourStartSlide.model'
import { Button, Col, DatePicker, Row, Spacer, Typography } from '../../../../../components'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import { CarrierStackParamList } from '../../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { View } from 'react-native'
import { ButtonWrapper } from './TourStartSlide.styles'
import { AddTour } from '../../../../../domain'
import { useState } from 'react'
import GeneralUtils from '../../../../../utilities/utils/general'

const TourStartSlide = ({ onClick, tour, hasDeliveredBefore }: TourStartSlideProps) => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const { carrierHasRight } = useAuthenticationContext()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [dateIsInvalid, setDateIsInvalid] = useState<boolean>(true)
  const [timeIsInvalid, setTimeIsInvalid] = useState<boolean>(true)

  const defaultDateHour = new Date()
  defaultDateHour.setUTCHours(-2)
  defaultDateHour.setUTCMinutes(0)

  const [newTour, setNewTour] = useState<Partial<AddTour>>({
    start: defaultDateHour,
    end: defaultDateHour,
  })

  const onChangeDate = (value: any) => {
    setErrorMessage('')
    setDateIsInvalid(false)

    const tourTemp = Object.assign({}, newTour)
    tourTemp.start = value
    tourTemp.end = value

    if (GeneralUtils.isDateBeforeCurrent(value)) {
      setDateIsInvalid(true)
      setErrorMessage('La date de la tournée doit être supérieure à la date actuelle.')
      return
    }

    setNewTour(tourTemp)
  }

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newTour)) return null

    const tourTemp = Object.assign({}, newTour)

    tourTemp[param as keyof typeof newTour] = value
    setNewTour(tourTemp)
  }

  const onChangeTimeStart = (value: any) => {
    setValue(value, 'start')
    if (GeneralUtils.isDateBefore(new Date(newTour.end || ''), new Date(value || ''), -1)) {
      setErrorMessage("L'heure de début de la tournée doit être avant celle de fin")
      return false
    } else {
      setTimeIsInvalid(false)
    }
  }

  const onChangeTimeEnd = (value: any) => {
    setErrorMessage('')
    setValue(value, 'end')
    if (GeneralUtils.isDateBefore(new Date(value || ''), new Date(newTour.start || ''), -1)) {
      setErrorMessage("L'heure de début de la tournée doit être avant celle de fin")
      return false
    } else {
      setTimeIsInvalid(false)
    }
  }

  const handleSubmit = () => {
    onClick({ ...newTour })
  }

  return (
    <>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <View style={{ width: '100%', alignItems: 'center' }}>
        <Typography.PageTitle align="center">
          {hasDeliveredBefore
            ? 'Quand effectuez vous votre prochaine tournée ? '
            : 'Quand souhaitez vous effectuer votre première tournée ?'}
        </Typography.PageTitle>
      </View>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <Row>
        <Col xs={12} sm={12}>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <DatePicker
            id="tourstartdate"
            label="Date de la livraison"
            mode="date"
            onChange={onChangeDate}
            value={newTour.start}
            withDay
          />
        </Col>
      </Row>
      {!dateIsInvalid && (
        <Row>
          <Col xs={6} sm={6}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <DatePicker
              id="heuredebut"
              label="Heure de début"
              onChange={onChangeTimeStart}
              value={newTour.start}
              mode={'time'}
              outputFormat="date"
            />
          </Col>
          <Col xs={6} sm={6}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <DatePicker
              id="heurefin"
              label="Heure de fin"
              onChange={onChangeTimeEnd}
              value={newTour.end}
              mode={'time'}
              outputFormat="date"
            />
          </Col>
        </Row>
      )}
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      {errorMessage ? (
        <Typography.Body colorName="color-danger" style={{ textAlign: 'center' }}>
          {errorMessage}
        </Typography.Body>
      ) : (
        <>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {!dateIsInvalid && !timeIsInvalid && (
            <ButtonWrapper>
              <Button onPress={() => handleSubmit()} label="Valider" colorName="color-secondary" />
            </ButtonWrapper>
          )}
        </>
      )}
    </>
  )
}

export default TourStartSlide
