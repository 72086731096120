import React from 'react'
import { View } from 'react-native'
import { Card, Typography, Spacer, TouchableCard, Icon, Icons } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledRightBox,
} from './CarrierFeesCard.styles'
import { CarrierFeesCardProps } from './CarrierFeesCard.model'
import { useTheme } from 'styled-components/native'

const CarrierFeesCard = ({ onPress, stats }: CarrierFeesCardProps) => {
  const theme = useTheme()
  return (
    <StyledCardWrapper activeOpacity={0.8} onPress={onPress}>
      <Card>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.CardTitle>{stats.producerLabel}</Typography.CardTitle>
            <Typography.BodySmall colorName="color-primary" bold></Typography.BodySmall>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3">
              {FormatUtils.pluralizeAmountLabel('tournée', stats.nbTours || 0)}
              {` - ${FormatUtils.formatPrice(stats.totalSalesRevenue, 'ht')} transportées`}
            </Typography.BodySmall>
          </StyledLeftBox>
          <StyledRightBox>
            <View style={{ alignItems: 'flex-end' }}>
              <Typography.BodySmall colorName="text-dark-3" style={{ alignSelf: 'flex-end' }}>
                à facturer
              </Typography.BodySmall>
              <Typography.BodySmall bold colorName="color-primary">
                {FormatUtils.formatPrice(stats.totalCarrierFeesCharged, 'ht')}
              </Typography.BodySmall>
            </View>
            <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            <Icon
              type={Icons.Ionicons}
              name={'chevron-forward-outline'}
              size={theme.spacingUnit * 2}
              color={theme.colors['text-dark-3']}
            />
          </StyledRightBox>
        </StyledCardContent>
      </Card>
    </StyledCardWrapper>
  )
}

export default CarrierFeesCard
