import { Typography } from '../../../components'
import { TourWithFormattedFillingInfo } from '../../../utilities/selector/formatTourFillingInfo'
import { InterfaceColors } from '../../../utilities/styling/theme/theme'

export interface TourFillingInfoProps {
  tour: TourWithFormattedFillingInfo
  variant?: 'default' | 'extended'
}

function getColor({ tour, variant }: TourFillingInfoProps) {
  if (variant === 'default') return 'color-primary' as keyof InterfaceColors

  let color = 'text-dark-3'

  if (tour.usedVolumeInPercent > 60) {
    color = 'color-warning'
  }

  if (tour.usedVolumeInPercent > 75) {
    color = 'color-danger'
  }

  return color as keyof InterfaceColors
}

function getFormattedText({ tour, variant }: TourFillingInfoProps) {
  if (variant === 'default') return `${tour.usedVolumeInPercent}%`

  return `Rempli à ${tour.usedVolumeInPercent}% (${tour.increasedTotalWeight}kg)`
}

export function TourFillingInfo({ tour, variant = 'default' }: TourFillingInfoProps) {
  if (!tour || !tour.capacity || tour.capacity === 0) return null
  if (tour.usedVolumeInPercent === undefined || tour.increasedTotalWeight === undefined) return null

  return (
    <Typography.BodyExtraSmall colorName={getColor({ tour, variant })}>
      {getFormattedText({ tour, variant })}
    </Typography.BodyExtraSmall>
  )
}
