import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledTourStepperWrapper = styled.View`
  width: 100%;
`

const RightBoxValueWrapper = styled.View`
  flex-direction: row;
`

export { StyledTourStepperWrapper, RightBoxValueWrapper }
