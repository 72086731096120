import styled from 'styled-components/native'

const RegistrationLandingPageWrapper = styled.View`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacingUnit * 10}px;
  /* min-height: 80vh; */
`

const StyledLogoBox = styled.View`
  background-color: red;
  min-height: 400px;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacingUnit * 10}px;
  justify-content: center;
`

const StyledImageLogo = styled.Image`
  width: 200px;
  height: 100px;
`

const StyledImageWrapper = styled.View`
  align-items: center;
  width: 100%;
`

const TextWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 3}px;
  margin-left: ${({ theme }) => theme.spacingUnit * 4}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 4}px;
`

const ButtonWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
`

const BottomPartWrapper = styled.View`
  width: 100%;
  align-items: center;
`
const StyledGainBox = styled.View`
  padding: ${({ theme }) => theme.spacingUnit * 0.6}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.7}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.7}px;
  background-color: ${({ theme }) => theme.colors['color-secondary']};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  flex-direction: row;
`

export {
  StyledLogoBox,
  RegistrationLandingPageWrapper,
  TextWrapper,
  ButtonWrapper,
  BottomPartWrapper,
  StyledImageLogo,
  StyledImageWrapper,
  StyledGainBox,
}
