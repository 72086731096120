import React from 'react'
import { useTheme } from 'styled-components/native'
import { CardProps } from './Card.model'
import { StyledCard, StyledCardContent } from './Card.styles'

function Card({ children, isLowMargin, noMargin }: CardProps) {
  const theme = useTheme()

  return (
    <StyledCard>
      <StyledCardContent isLowMargin={isLowMargin} noMargin={noMargin}>
        {children}
      </StyledCardContent>
    </StyledCard>
  )
}

export default Card
