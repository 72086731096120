import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { PageTitle, SectionTitle, Spacer, Typography } from '../../../components'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { useQuery } from '@tanstack/react-query'
import { StackNavigationProp } from '@react-navigation/stack'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import CarrierDeliveryFeesTopBox from './components/CarrierDeliveryFeesTopBox'
import CarrierFeesCard from './components/CarrierFeesCard'
import { CarrierFeesStatsProducer, CarrierFeesStatsResponse } from '../../../domain/CarrierFees'
import CarrierFeesCarrierService from '../../../services/carrier/carrierFees'
import { Invoice } from '../../../domain/Invoice'
import PaymentCard from '../../../components/PaymentCard'
import InvoiceService from '../../../services/invoice'
import { Payment } from '../../../domain/Payment'
import CarrierCarrierService from '../../../services/carrier/carrier'
import { Carrier, Pagination } from '../../../domain'
import InfoCard from '../../../components/InfoCard'
import CardListPaginated from '../../../components/CardListPaginated'

const CarrierBillingProducersScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()
  const [payments, setPayments] = useState<Payment[]>([])
  const [pageSizeInvoices, setPageSizeInvoices] = useState(5)
  const [pageNumberInvoices, setPageNumberInvoices] = useState(1)

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierBillingProducers'>>()

  const carrierId = route.params.carrierId

  //fetch Carrier
  const {
    data: carrier,
    refetch: refetchCarrier,
    isInitialLoading: carrierLoading,
    isLoading: isLoadingCarrier,
  } = useQuery<Carrier, Error>(
    ['sf_billing_carrier', carrierId, getAccessInfos().carrierGroupId],
    () => CarrierCarrierService.getOneCarrier(carrierId),
    {
      keepPreviousData: true,
    },
  )

  //fetch CarrierFees

  const {
    data: carrierFees,
    refetch: refetchCarrierFees,
    isInitialLoading: carrierFeesLoading,
    isLoading: isLoadingCarrierFees,
  } = useQuery<CarrierFeesStatsResponse, Error>(
    ['sf_billing_carrier_fees', getAccessInfos().carrierGroupId],
    () => CarrierFeesCarrierService.getAllGroupStats(getAccessInfos().carrierGroupId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: carrierFeesStatsProducers,
    refetch: refetchCarrierFeesStatsProducers,
    isInitialLoading: carrierFeesStatsProducersLoading,
    isLoading: isLoadingCarrierFeesStatsProducers,
  } = useQuery<CarrierFeesStatsProducer[], Error>(
    ['sf_billing_carrier_fees_stats_producer', getAccessInfos().carrierGroupId, carrierId],
    () =>
      CarrierFeesCarrierService.getAllGroupProducerStats(
        getAccessInfos().carrierGroupId,
        carrierId,
        undefined,
      ),
    {
      keepPreviousData: true,
    },
  )

  const { query: invoicesQuery } = InvoiceService.useGetInvoices({
    accountId: carrier?.account.id || '',
    limit: pageSizeInvoices,
    pageNumber: pageNumberInvoices,
    getByIssuer: true,
  })

  const { data: invoices, refetch: refetchInvoices, isLoading: isLoadingInvoices } = invoicesQuery()

  useEffect(() => {
    if (invoices?.data) {
      const payments: Payment[] = []
      invoices.data.map(
        (invoice) => invoice.payment && payments.push({ ...invoice.payment, invoices: [invoice] }),
      )
      setPayments(payments)
    }
  }, [invoices, carrier])

  const carrierStats = carrierFees?.statsCarriers.find((stats) => stats.carrierId === carrierId)

  const onClickBillProducer = (idProducer: string) => {
    navigation.navigate('CarrierBillingProducer', { producerId: idProducer, carrierId: carrierId })
  }

  const onClickPayment = (invoiceId: string) => {
    navigation.navigate('CarrierBillingPayment', {
      invoiceId: invoiceId,
    })
  }

  const onChangePageInvoices = async (pageNumber: number) => {
    await setPageNumberInvoices(pageNumber)
    refetchInvoices()
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title={`Facturer les producteurs`} />
        <InfoCard leftIcon="link">
          <Typography.BodySmall colorName="text-dark-3">Producteur-livreur</Typography.BodySmall>
          <Typography.BodySmall bold>{carrier?.name}</Typography.BodySmall>
        </InfoCard>{' '}
        <CarrierDeliveryFeesTopBox stats={carrierStats} />
        <SectionTitle title={`À facturer`} />
        {!carrierFeesStatsProducers ||
          (carrierFeesStatsProducers.length < 1 && (
            <>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Typography.BodySmall colorName="text-dark-3">
                Aucun frais à facturer
              </Typography.BodySmall>
            </>
          ))}
        {(carrierFeesStatsProducers || []).map((recordsCarrier) => (
          <CarrierFeesCard
            stats={recordsCarrier}
            onPress={() => onClickBillProducer(recordsCarrier.producerId)}
          />
        ))}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <SectionTitle title={`Paiements`} />
        <CardListPaginated
          data={payments}
          pagination={invoices?.pagination}
          element={function (payment: any) {
            return (
              <PaymentCard
                payment={payment}
                onPress={() => onClickPayment(payment.invoices[0].id)}
                invoice={payment.invoices[0]}
              />
            )
          }}
          isLoading={isLoadingInvoices}
          emptyMessage="Aucun paiement"
          onChangePage={onChangePageInvoices}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierBillingProducersScreen
