import React from 'react'
import { useTheme } from 'styled-components/native'
import { CardProps } from './TouchableCard.model'
import StyledTouchableCard from './TouchableCard.styles'
import DropShadow from 'react-native-drop-shadow'

function TouchableCard({ children, hasGreenBorder, backgroundColor }: CardProps) {
  const theme = useTheme()

  return (
    <DropShadow
      style={{
        shadowColor: theme.colors['color-shadow'],
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 1,
        shadowRadius: theme.borderRadius * 0.3,
        borderRadius: theme.borderRadius,
      }}
    >
      <StyledTouchableCard hasGreenBorder={hasGreenBorder} backgroundColor={backgroundColor}>
        {children}
      </StyledTouchableCard>
    </DropShadow>
  )
}

export default TouchableCard
