import styled from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 0.6}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 0.6}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 0.8}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 0.8}px;
  flex-direction: row;
  overflow: hidden;
`

const StyledSubCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 1.3}px;
  flex-direction: row;
`

const StyledLeftBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 1.3}px;
`

const StyledCenterBox = styled.View`
  flex: 1;
`

const TotalHTContainer = styled.View`
  align-items: flex-end;
  justify-content: center;
`

const StyledRightBox = styled.View`
  flex-direction: row;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
  gap: ${({ theme }) => theme.spacingUnit * 1}px;
`

const StyledValuesWrapper = styled.View`
  flex-direction: row;
`

const StyledValueBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 0.3}px;
  justify-content: center;
`

const StyledButtonWrapper = styled.TouchableOpacity`
  width: 100%;
  align-items: center;
  flex-direction: row;
`

export {
  StyledCardWrapper,
  StyledCardContent,
  StyledSubCardContent,
  StyledLeftBox,
  StyledCenterBox,
  StyledRightBox,
  StyledValuesWrapper,
  StyledValueBox,
  StyledButtonWrapper,
  TotalHTContainer,
}
