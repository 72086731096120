import { useContext } from 'react'
import { GroupContext, GroupContextData } from '../context/groupContext'

export default function useGroupContext(): GroupContextData {
  const context = useContext(GroupContext)

  if (!context) {
    throw new Error('useGroupContext must be used within an GroupProvider')
  }

  return context
}
