import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import FormatUtils from '../../../utilities/utils/format'
import { TourDeliveryNoteCardProps } from './TourDeliveryNoteCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRightBox,
} from './TourDeliveryNoteCard.styles'
import { DeliveryNoteTypeEnum, MailEnums } from '../../../../enums'
import DeviceUtil from '../../../utilities/utils/device'

const TourDeliveryNoteCard = ({
  deliveryNote,
  onClick,
  isCarrier,
  isBilling,
}: TourDeliveryNoteCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const OnClickDeliveryNote = () => {
    onClick(deliveryNote)
  }

  const producer = deliveryNote.producer
  const carrier = deliveryNote.carrier
  const client = deliveryNote.order?.cart?.client

  let from = ''
  let to = ''
  if (deliveryNote.type === DeliveryNoteTypeEnum.PRODUCER_CLIENT) {
    from = producer?.label || ''
    to = client?.label || ''
  }
  if (deliveryNote.type === DeliveryNoteTypeEnum.CARRIER_CLIENT) {
    from = deliveryNote.issuer?.legalName || ''
    to = client?.label || ''
  }
  if (deliveryNote.type === DeliveryNoteTypeEnum.PRODUCER_CARRIER) {
    from = producer?.label || ''
    to = deliveryNote.recipient?.legalName || ''
  }

  const mailSent = (mailType: MailEnums): boolean => {
    let isSent = false
    const history = deliveryNote.mailsStatus?.history
    if (history && history.length) {
      const foundHistory = history.find((h) => h.type === mailType)
      isSent = foundHistory !== null
    }

    return isSent
  }

  const mappedMailType = (): MailEnums => {
    let mappedMailType = MailEnums.DELIVERY_NOTE_PRODUCER_CARRIER
    if (deliveryNote.type === DeliveryNoteTypeEnum.PRODUCER_CLIENT) {
      mappedMailType = MailEnums.DELIVERY_NOTE_PRODUCER_CLIENT
    }
    if (deliveryNote.type === DeliveryNoteTypeEnum.CARRIER_CLIENT) {
      mappedMailType = MailEnums.DELIVERY_NOTE_CARRIER_CLIENT
    }
    return mappedMailType
  }

  const isMobile = DeviceUtil.isMobileApp()

  return (
    <StyledCardWrapper onPress={OnClickDeliveryNote} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Icon
              type={Icons.Ionicons}
              name="document-text-outline"
              size={theme.spacingUnit * 5}
              color={theme.colors['color-primary']}
            />
          </StyledLeftBox>
          <StyledMiddleBox>
            <Typography.CardTitle ellipsis>{to}</Typography.CardTitle>
            <Typography.Body colorName="text-dark-3">par {from}</Typography.Body>
            <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3">
              {!isBilling
                ? FormatUtils.getLabelFromDeliveryNoteType(deliveryNote.type)
                : `${FormatUtils.formatDate(deliveryNote.tour?.start, 'FullDate')} - ${
                    deliveryNote.tour?.city?.label
                  } `}
            </Typography.BodySmall>
          </StyledMiddleBox>
          <StyledRightBox>
            <View>
              {isCarrier && mailSent(mappedMailType()) ? (
                <Typography.BodySmall colorName="color-primary" style={{ textAlign: 'right' }}>
                  Mail envoyé
                </Typography.BodySmall>
              ) : null}
              {isCarrier && !mailSent(mappedMailType()) ? (
                <Typography.BodySmall colorName="color-warning" style={{ textAlign: 'right' }}>
                  À envoyer
                </Typography.BodySmall>
              ) : null}
              <Typography.BodySmall colorName="text-dark-3">Total HT</Typography.BodySmall>
              <Typography.BodySmall colorName="color-primary" bold style={{ textAlign: 'right' }}>
                {FormatUtils.formatPrice(deliveryNote.totalAmounts.totalHT)}
              </Typography.BodySmall>
              <Typography.BodySmall style={{ textAlign: 'right' }}>
                N° {deliveryNote.customNumber || deliveryNote.identifier}
              </Typography.BodySmall>
              <Typography.BodySmall colorName="text-dark-3">
                Créé le {FormatUtils.formatDate(deliveryNote.createdDate, 'Date')}
              </Typography.BodySmall>
            </View>
            {!isMobile && (
              <View style={{ height: '100%', justifyContent: 'center', marginLeft: '10px' }}>
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name="download-circle"
                  size={theme.spacingUnit * 4}
                  color={theme.colors['color-primary']}
                />
              </View>
            )}
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default TourDeliveryNoteCard
