export enum MailEnums {
  PROPOSITION_CLIENTS = 'PROPOSITION_CLIENTS',
  PRODUCER_ORDER = 'PRODUCER_ORDER',
  PRODUCERS_ORDERS = 'PRODUCERS_ORDERS',
  ORDER_CLIENT_CONFIRMATION = 'ORDER_CLIENT_CONFIRMATION',
  DELIVERY_NOTE_PRODUCER_CARRIER = 'DELIVERY_NOTE_PRODUCER_CARRIER',
  DELIVERY_NOTE_CARRIER_CLIENT = 'DELIVERY_NOTE_CARRIER_CLIENT',
  DELIVERY_NOTE_PRODUCER_CLIENT = 'DELIVERY_NOTE_PRODUCER_CLIENT',
}

export enum MailOptionEnums {
  ALL_CLIENTS = 'ALL_CLIENTS',
  SELECTED_CLIENTS = 'SELECTED_CLIENTS',
  CUSTOM_MAIL = 'CUSTOM_MAIL',
}

export const MAIL_TYPES = [
  {
    label: 'Mercuriale Clients',
    value: MailEnums.PROPOSITION_CLIENTS,
  },
  {
    label: 'Ordre de dépôt producteurs (Avec BL)',
    value: MailEnums.DELIVERY_NOTE_PRODUCER_CARRIER,
  },
  {
    label: 'Ordre de dépôt clients (Avec BL)',
    value: MailEnums.DELIVERY_NOTE_PRODUCER_CLIENT,
  },
  {
    label: 'Ordre de dépôt clients (Avec BL)',
    value: MailEnums.DELIVERY_NOTE_CARRIER_CLIENT,
  },
]

export const MAIL_OPTIONS = [
  {
    label: `J'envoie à tous les clients`,
    value: MailOptionEnums.ALL_CLIENTS,
  },
  {
    label: 'Je sélectionne les clients',
    value: MailOptionEnums.SELECTED_CLIENTS,
  },
  {
    id: 2,
    label: 'Je saisis un mail personnalisé',
    value: MailOptionEnums.CUSTOM_MAIL,
  },
]

export enum PropositionClientsMailTemplateEnums {
  PROPOSITION_CLIENTS_V1 = 'PROPOSITION_CLIENTS_V1',
  PROPOSITION_CLIENTS_V2 = 'PROPOSITION_CLIENTS_V2',
  PROPOSITION_CLIENTS_V3 = 'PROPOSITION_CLIENTS_V3',
}

export const PROPOSITION_CLIENTS_MAIL_TEMPLATE_OPTIONS = [
  {
    label: `Liste de produits (Template 45)`,
    value: PropositionClientsMailTemplateEnums.PROPOSITION_CLIENTS_V1,
  },
  {
    label: 'Fiche produit et producteurs (Template 55)',
    value: PropositionClientsMailTemplateEnums.PROPOSITION_CLIENTS_V2,
  },
  {
    label: 'Personnalisé (Template 56)',
    value: PropositionClientsMailTemplateEnums.PROPOSITION_CLIENTS_V3,
  },
]
