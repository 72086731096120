import { useNavigation } from '@react-navigation/native'
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import useAuthenticationContext from '../../utilities/hook/useAuthenticationContext'
import { PublicStackParamList } from './PublicNavigationStack.model'
import useCardTitleOptions from './PublicNavigationStack.utils'
import ShelfLabelInfosScreen from '../../screens/Public/ShelfLabelInfos'
import AuthenticationNavigationStack from '../AuthenticationNavigationStack'
import ProfileSwitcherScreen from '../../screens/Authentification/ProfileSwitcher'

const Stack = createStackNavigator<PublicStackParamList>()

const PublicNavigationStack = () => {
  const navigation = useNavigation<StackNavigationProp<PublicStackParamList>>()
  const headerOptions = useCardTitleOptions()
  const { isUserAuthenticated } = useAuthenticationContext()

  return (
    <Stack.Navigator
      initialRouteName={'Authentication'}
      screenOptions={{
        ...headerOptions,
        keyboardHandlingEnabled: false,
      }}
    >
      <Stack.Screen
        name="ShelfLabelInfos"
        component={ShelfLabelInfosScreen}
        options={{
          gestureEnabled: false,
          headerShown: false,
          animationEnabled: false,
          title: 'Informations produit',
        }}
      />

      <Stack.Screen
        name="ProfileSwitcher"
        component={ProfileSwitcherScreen}
        options={{
          gestureEnabled: false,
          headerShown: true,
          animationEnabled: false,
          title: 'Choix du compte',
        }}
      />

      <Stack.Screen
        name="Authentication"
        component={AuthenticationNavigationStack}
        options={{
          gestureEnabled: false,
          headerShown: false,
          animationEnabled: false,
          title: 'Informations produit',
        }}
      />
    </Stack.Navigator>
  )
}

export default PublicNavigationStack
