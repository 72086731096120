import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledStepCardsWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`
const StyledLeftBox = styled.View`
  background-color: ${({ theme }) => theme.colors && theme.colors['color-primary']};
  margin-left: -12px;
  border-radius: 100%;
  border: 1.75px solid ${({ theme }) => theme.colors && theme.colors['color-primary']};
  align-items: center;
  justify-content: center;
`
const StyledStepCardRanking = styled.View`
  /* background-color: ${({ theme }) => theme.colors && theme.colors['background-3']}; */
  width: 14px;
  height: 14px;
  padding: ${({ theme }) => theme.spacingUnit * 1.5}px;
  align-items: center;
  justify-content: center;
`
const StyledStepCardContent = styled.View`
  padding: ${({ theme }) => theme.spacingUnit * 1.5}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 1.5}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1.5}px;
`

const StyledRightBox = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: ${({ theme }) => theme.spacingUnit * 1.5}px;
`

export {
  StyledStepCardsWrapper,
  StyledStepCardContent,
  StyledStepCardRanking,
  StyledLeftBox,
  StyledRightBox,
}
