import { useEffect } from 'react'
import { Linking } from 'react-native'

const useURL = (handleUrlChange: ({ url }: { url: string }) => void) => {
  useEffect(() => {
    ;(async () => {
      const initialUrl = await Linking.getInitialURL()

      if (!initialUrl) {
        return
      }

      handleUrlChange({ url: initialUrl })
    })()

    const subscribe = Linking.addEventListener('url', handleUrlChange)

    return () => subscribe.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}

export default useURL
