import { Button, PageTitle, Spacer, Typography } from '../../../../../components'
import { LandingPageProps } from './LandingPage.model'
import {
  LandingPageWrapper,
  LandingTextContentWrapper,
  PageTitleWrapper,
  StyledButtonWrapper,
  StyledImageLogo,
  StyledLogoBox,
} from './LandingPage.styles'

const CarrierTourAddClientsLandingPage = ({ onClickNext }: LandingPageProps) => {
  return (
    <LandingPageWrapper>
      <StyledLogoBox>
        <StyledImageLogo
          source={require('../../../../../../assets/drawings/client-shop.png')}
          resizeMode="contain"
        />
      </StyledLogoBox>
      <LandingTextContentWrapper>
        <PageTitleWrapper>
          <PageTitle title="Vos clients rejoignent la tournée et passent commande" />
        </PageTitleWrapper>
        <Typography.Body align="center">
          Invitez vos clients afin qu'ils puissent passer commande en ligne directement pour votre
          prochaine tournée de livraison.
        </Typography.Body>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <StyledButtonWrapper>
          <Button label={'Suivant'} onPress={onClickNext} />
        </StyledButtonWrapper>
      </LandingTextContentWrapper>
    </LandingPageWrapper>
  )
}

export default CarrierTourAddClientsLandingPage
