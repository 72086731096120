import React from 'react'
import { useTheme } from 'styled-components/native'
import { StyledTopBox } from './CarrierTourDeliveryTopBox.styles'
import { CarrierTourDeliveryTopBoxProps } from './CarrierTourDeliveryTopBox.model'
import { CardParam } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import i18n from '../../../../../i18n'

function CarrierTourDeliveryTopBox({ tour }: CarrierTourDeliveryTopBoxProps) {
  const theme = useTheme()

  return (
    <>
      <StyledTopBox>
        <CardParam
          label={'Date de livraison'}
          value={FormatUtils.formatDate(tour.start, 'FullDate')}
          flex={6}
          isStart
        />
        <CardParam
          label={'Horaires'}
          value={FormatUtils.capitalize(
            FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
          )}
          flex={6}
        />
        <CardParam label={'Arrêts'} value={tour.stops?.length} flex={4} isEnd />
      </StyledTopBox>
    </>
  )
}

export default CarrierTourDeliveryTopBox
