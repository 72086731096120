import { Controller } from 'react-hook-form'
import DatePicker from '../DatePicker'
import Spacer from '../Spacer'
import Typography from '../Typography'
import { RhfDatePickerProps } from './RhfDatePicker.model'

const RhfDatePicker = ({
  control,
  name,
  dateInputLabel,
  rules,
  mode,
  outputFormat,
  withDay,
  noDisplayError,
}: RhfDatePickerProps) => {
  return (
    <Controller
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <DatePicker
            label={dateInputLabel}
            onChange={onChange}
            value={value}
            mode={mode}
            outputFormat={outputFormat}
            withDay={withDay}
          />
          {error && !noDisplayError && (
            <>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              <Typography.Body colorName="color-danger">{error.message}</Typography.Body>
            </>
          )}
        </>
      )}
      name={name}
    />
  )
}

export default RhfDatePicker
