import { is } from 'date-fns/locale'
import styled from 'styled-components/native'
import { css } from 'styled-components/native'

type Insets = {
  top: number
  right: number
  bottom: number
  left: number
}

const HeaderWrapper = styled.View`
  width: 100%;
  background-color: ${({ theme }) => theme.colors['color-primary']};
`
const HeaderTopWrapper = styled.View<{ isDesktop: boolean; insets: Insets; noDrawer?: boolean }>`
  width: 100%;
  height: auto;
  min-height: 55px;
  flex-direction: row;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-top: ${({ theme, insets }) => theme.spacingUnit * 1 + insets.top}px;
  padding-bottom: 0;
  align-items: center;
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      padding-left: 245px;
    `}
  ${({ noDrawer }) =>
    noDrawer &&
    css`
      padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
    `}
  
  background-color: ${({ theme }) => theme.colors['color-primary']};
`
const HeaderBottomWrapper = styled.View`
  width: 100%;
  min-height: 30px;
  border-radius: ${({ theme }) => theme.spacingUnit * 2.5}px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${({ theme }) => theme.colors['background-1']};
`

const LeftContainer = styled.View<{ isDesktop: boolean }>`
  flex-direction: row;
  align-items: center;
  width: ${({ theme }) => theme.headerBoxWidth}px;
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      margin-left: 25px;
    `}
`

const CenterContainer = styled.View`
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-top: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1}px;
`

const RightContainer = styled.View`
  flex-direction: row;
  align-items: center;
  width: ${({ theme }) => theme.headerBoxWidth}px;
`

const StyledTouchableOpacity = styled.TouchableOpacity`
  width: 100%;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: row;
`

export {
  HeaderWrapper,
  LeftContainer,
  RightContainer,
  CenterContainer,
  StyledTouchableOpacity,
  HeaderTopWrapper,
  HeaderBottomWrapper,
}
