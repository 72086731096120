import styled from 'styled-components/native'

const RegistrationLandingPageWrapper = styled.View`
  width: 100%;
`

const StyledLogoBox = styled.View`
  height: 100%;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacingUnit * 14}px;
`

const StyledImageLogo = styled.Image`
  width: 50%;
  height: 50%;
`

const StyledImageLabel = styled.Image`
  width: 50%;
  height: 25%;
`

const TextWrapper = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 4}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 4}px;
`

const ButtonWrapper = styled.TouchableOpacity`
  margin-left: ${({ theme }) => theme.spacingUnit * 4}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 4}px;
  align-items: center;
`

const BottomPartWrapper = styled.View`
  /* justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 2}px; */
`

export {
  StyledLogoBox,
  RegistrationLandingPageWrapper,
  TextWrapper,
  ButtonWrapper,
  BottomPartWrapper,
  StyledImageLogo,
  StyledImageLabel,
}
