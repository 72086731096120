import { Container, Pagination, Product, ProductLabel, ProductType } from '../../../domain'
import { Category } from '../../../domain/Category'
import ApiService from '../../api.service'

const getAllProducts = async (
  producerId: string,
  limit: number = 200,
  pageNumber: number = 1,
  search?: string,
): Promise<{ data: Product[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/product/producer/${producerId}`, {
    limit: limit,
    page: pageNumber,
    search,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOneProduct = async (producerId: string, productId: string): Promise<Product> => {
  const response = await ApiService.get(`/product/${productId}/producer/${producerId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getAllContainers = async (producerId?: string): Promise<Container[]> => {
  const response = await ApiService.get(`/container`, {
    producerId: producerId,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getAllProductTypes = async (search?: string, categoryId?: string): Promise<ProductType[]> => {
  const response = await ApiService.get(`/product/product-type`, {
    search: search,
    categoryId: categoryId,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getAllCategories = async (): Promise<Category[]> => {
  const response = await ApiService.get(`/product/category`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const create = async (producerId: string, newProduct: any): Promise<Product> => {
  const response = await ApiService.post(`/product/producer/${producerId}`, newProduct).catch(
    (error) => {
      throw error
    },
  )
  return response
}
const createContainer = async (newContainer: any): Promise<Container> => {
  const response = await ApiService.post(`/container/`, newContainer).catch((error) => {
    throw error
  })
  return response
}

const update = async (productId: string, product: any): Promise<Product> => {
  const response = await ApiService.patch(`/product/${productId}`, product).catch((error) => {
    throw error
  })
  return response
}


const ProductProducerService = {
  getAllProducts,
  getOneProduct,
  getAllProductTypes,
  getAllCategories,
  getAllContainers,
  create,
  createContainer,
  update,
}

export default ProductProducerService
