import { useContext } from 'react'
import { UserContext, UserContextData } from '../context/userContext'

export default function useUserContext(): UserContextData {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useUserContext must be used within an UserProvider')
  }

  return context
}
