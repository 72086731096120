import { useRoute, RouteProp, useNavigation } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import { UserEnums } from '../../../../enums'
import { Spacer, SectionTitle, Button, Typography, TextInput } from '../../../components'
import Loader from '../../../components/Loader'
import TourTopBox from '../../../components/TourTopBox'
import { Batch, Cart, Catalog, Tour, Pagination } from '../../../domain'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import TourProducerService from '../../../services/producer/tour'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ScreenSafeAreaWrapper, ContentWrapper } from '../../../utilities/styling/wrappers'
import { StyledButtonWrapper, TextInputWrapper } from './ProducerAddCartBatch.style'
import { useEffect, useState } from 'react'
import StorageUtil from '../../../utilities/storage/storage'
import BatchProducerService from '../../../services/producer/batch'
import GeneralUtils from '../../../utilities/utils/general'
import CartProducerService from '../../../services/producer/cart'
import { StackNavigationProp } from '@react-navigation/stack'
import { GroupEnums } from '../../../../enums/group'
import { queryClient } from '../../../utilities/queryClient'
import FormatUtils from '../../../utilities/utils/format'
import PaginationBar from '../../../components/PaginationBar'
import BatchCardNew from '../../../components/BatchCardNew'
import { View } from 'react-native'

const ProducerAddCartBatch = () => {
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerTourOrder'>>()
  const tourId = route?.params?.tourId
  const [cartId, setCartId] = useState<string | undefined>(undefined)
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const [pageSize, setPageSize] = useState(6)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchValue, setSearchValue] = useState('')

  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: cart,
    refetch: refetchCart,
    isInitialLoading: cartLoading,
  } = useQuery<Cart | null, Error>(['p_cart'], () => CartProducerService.getOneCart(cartId), {
    keepPreviousData: false,
  })

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = useQuery<Tour, Error>(
    ['p_tour_orders'],
    () => TourProducerService.getOneTourProducerOrders(tourId || '', getAccessInfos().producerId),
    {},
  )

  const {
    data: catalog,
    refetch: refetchBatchs,
    isInitialLoading: batchsLoading,
  } = useQuery<Catalog, Error>(
    ['p_catalog'],
    () =>
      BatchProducerService.getAllBatchsTour(
        tourId,
        cartId,
        pageSize,
        pageNumber,
        undefined,
        [getAccessInfos().producerId],
        searchValue,
      ),
    {
      keepPreviousData: true,
      enabled: cartId !== undefined,
    },
  )

  useEffect(() => {
    const getStoredCart = async () => {
      let storedCartId = await StorageUtil.getItem('p_cart_id')
      const storedCartIdExpirationDate = await StorageUtil.getItem('p_cart_id_expiration_date')

      if (storedCartIdExpirationDate) {
        const isToOld = GeneralUtils.isDateBefore(
          new Date(JSON.parse(storedCartIdExpirationDate)),
          new Date(),
        )
        if (isToOld) {
          storedCartId = null
          StorageUtil.deleteItem('p_cart_id')
          StorageUtil.deleteItem('p_cart_id_expiration_date')
        }
      }

      if (storedCartId) {
        await setCartId(JSON.parse(storedCartId))
        refetchCart()
      }
    }
    getStoredCart()
  }, [])

  useEffect(() => {
    if (!searchValue || searchValue.length > 2) {
      refetchBatchs()
    }
  }, [searchValue])

  const onValidateAddBatchs = async () => {
    if (!cart || !cart.id) return
    await queryClient.refetchQueries(['p_cart'])
    navigation.goBack()
  }

  const groupIsDeliveryProducer = () => {
    return tour?.group?.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS
  }

  const onChangePage = async (pageNumber: number) => {
    await setPageNumber(pageNumber)
    refetchBatchs()
  }

  const producerCartBatchs =
    cart?.cartBatchs?.filter(
      (cb) => cb.batch?.product?.producer?.id === getAccessInfos().producerId,
    ) || []
  if (!cart) {
    return <Loader isLoading pageLoading />
  }

  const cartWithProducerCartBatchs = { ...cart, cartBatchs: producerCartBatchs }

  const batchs = catalog?.batchs || []

  if (!cart || batchsLoading) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <TourTopBox
        tour={tour}
        cart={cartWithProducerCartBatchs}
        userRole={UserEnums.RoleEnums.PRODUCER}
      />
      <ContentWrapper marginTopLarge>
        <Spacer fixedSize={50} axis={Spacer.AxisEnum.VERTICAL} />
        <SectionTitle title={'Ajoutez des produits de votre catalogue'} />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <TextInputWrapper>
          <TextInput
            field="text"
            label="Rechercher par nom"
            value={searchValue}
            onChangeText={setSearchValue}
            small
          />
        </TextInputWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {batchs.length < 1 && !batchsLoading && (
          <View style={{ alignItems: 'center', width: '100%' }}>
            <Spacer fixedSize={2} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall align="center">
              Vous n'avez aucun lot de produit disponible dans votre mercuriale le{' '}
              {FormatUtils.formatDate(tour?.deposit, 'Date')}
            </Typography.BodySmall>
          </View>
        )}
        {batchs.map((batch) => (
          <BatchCardNew
            key={batch.id}
            batch={batch}
            cart={cart}
            displayClientPrice={groupIsDeliveryProducer()}
            displayMinPrice
          />
        ))}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <PaginationBar
          data={batchs}
          pagination={batchsLoading ? undefined : catalog?.pagination}
          onChangePage={onChangePage}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <StyledButtonWrapper>
          <Button label="Confirmer l'ajout" onPress={() => onValidateAddBatchs()} />
        </StyledButtonWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerAddCartBatch
