import React, { useState } from 'react'
import { DynamicImageProps } from './DynamicImage.model'
import {
  DynamicImageWrapper,
  ModalCloseButton,
  StyleImageWrapper,
  StyledImage,
  StyledImageModal,
  StyledModal,
  StyledModalWrapper,
} from './DynamicImage.styles'
import { Modal, TouchableOpacity } from 'react-native'
import Icon, { Icons } from '../Icon'
import { useTheme } from 'styled-components/native'

const DynamicImage = ({ imageUri, closable, onClose, togglesModal }: DynamicImageProps) => {
  const theme = useTheme()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const toggleModal = () => {
    setIsModalVisible(!isModalVisible)
  }

  const displayImage = () => {
    return (
      <StyleImageWrapper>
        {!imageUri || imageUri === '' ? (
          <StyledImage source={require('../../../assets/no_photo.jpeg')} />
        ) : (
          <StyledImage source={{ uri: imageUri }} />
        )}
        {closable && onClose && (
          <TouchableOpacity
            onPress={() => {
              onClose()
            }}
          >
            <Icon
              type={Icons.Ionicons}
              name="close-circle"
              size={theme.spacingUnit * 2}
              color={theme.colors['color-danger']}
            />
          </TouchableOpacity>
        )}
      </StyleImageWrapper>
    )
  }
  return (
    <DynamicImageWrapper>
      {togglesModal ? (
        <TouchableOpacity onPress={toggleModal} style={{ width: '100%', height: '100%' }}>
          <Modal transparent={true} visible={isModalVisible} animationType="fade">
            <StyledModalWrapper>
              <StyledModal>
                <StyledImageModal source={{ uri: imageUri }} />
                <ModalCloseButton
                  onPress={() => {
                    toggleModal()
                  }}
                >
                  <Icon
                    type={Icons.Ionicons}
                    name="close-circle"
                    size={theme.spacingUnit * 4}
                    color={theme.colors['color-danger']}
                  />
                </ModalCloseButton>
              </StyledModal>
            </StyledModalWrapper>
          </Modal>
          {displayImage()}
        </TouchableOpacity>
      ) : (
        displayImage()
      )}
    </DynamicImageWrapper>
  )
}

export default DynamicImage
