import { Pagination, PriceReporting, PriceStatement } from '../../../domain'
import ApiService from '../../api.service'

const getAll = async (
  groupId: string,
  next?: Date,
  previous?: Date,
  limit?: number,
  pageNumber: number = 1,
  withMissingPriceStatements?: boolean,
): Promise<{ data: PriceReporting[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/price-reporting/group/${groupId}`, {
    after: next ? next : null,
    before: previous ? previous : null,
    limit,
    page: pageNumber,
    withMissingPriceStatements: withMissingPriceStatements ? true : undefined,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getAllPriceStatement = async (
  groupId: string,
  targetDate?: Date,
  cityId?: string,
): Promise<PriceStatement[]> => {
  if (!targetDate) return []
  const response = await ApiService.get(`/price-reporting/group/${groupId}/price-statements`, {
    targetDate: targetDate,
    cityId: cityId,
  })
    .then((result) => result.data)
    .catch((error) => {
      throw error
    })
  return response
}

const getOne = async (priceReportingId?: string): Promise<PriceReporting> => {
  const response = await ApiService.get(`/price-reporting/${priceReportingId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const create = async (groupId: string, priceReporting: any): Promise<PriceReporting> => {
  const response = await ApiService.post(`/price-reporting/group/${groupId}`, priceReporting).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const duplicate = async (
  priceReportingId: string,
  priceReporting: any,
): Promise<PriceReporting> => {
  const response = await ApiService.post(
    `/price-reporting/${priceReportingId}/duplicate`,
    priceReporting,
  ).catch((error) => {
    throw error
  })
  return response
}

const createPriceStatement = async (
  priceReportingId: string,
  priceStatement: any,
): Promise<PriceStatement> => {
  const response = await ApiService.post(
    `/price-reporting/${priceReportingId}/price-statement`,
    priceStatement,
  ).catch((error) => {
    throw error
  })
  return response
}

const update = async (priceReportingId: string, priceReporting: any): Promise<PriceReporting> => {
  const response = await ApiService.patch(
    `/price-reporting/${priceReportingId}`,
    priceReporting,
  ).catch((error) => {
    throw error
  })
  return response
}

const updatePriceStatement = async (
  priceReportingId: string,
  priceStatementId: string,
  priceStatement: any,
): Promise<PriceReporting> => {
  const response = await ApiService.patch(
    `/price-reporting/${priceReportingId}/price-statement/${priceStatementId}`,
    priceStatement,
  ).catch((error) => {
    throw error
  })
  return response
}

const remove = async (priceReportingId: string): Promise<PriceReporting> => {
  const response = await ApiService.remove(`/price-reporting/${priceReportingId}`).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const removePriceStatement = async (
  priceReportingId: string,
  priceStatementId: string,
): Promise<PriceReporting> => {
  const response = await ApiService.remove(
    `/price-reporting/${priceReportingId}/price-statement/${priceStatementId}`,
  ).catch((error) => {
    throw error
  })
  return response
}

const PriceReportingCarrierService = {
  getAll,
  getAllPriceStatement,
  getOne,
  create,
  duplicate,
  createPriceStatement,
  update,
  updatePriceStatement,
  remove,
  removePriceStatement,
}

export default PriceReportingCarrierService
