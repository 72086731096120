import { Typography, Card, Button, Row, Col, SectionTitle, Spacer } from '../../../../../components'
import { Producer } from '../../../../../domain'
import BatchCatalog from '../../../../../modules/Batch/BatchCatalog'
import CartBatch from '../../../../../modules/Cart/CartBatchCard'
import OrderBatch from '../../../../../modules/Order/OrderBatch'
import DirectusUtil from '../../../../../utilities/utils/directus'
import { ClientOrderBatchsProps } from './ClientOrderBatchs.model'
import {
  BoxWrapper,
  LeftBox,
  ProducerBox,
  RightBox,
  StyledProducerImage,
} from './ClientOrderBatchs.styles'

const ClientOrderBatchs = ({ producer, cartBatchs }: ClientOrderBatchsProps) => {
  return (
    <BoxWrapper>
      <ProducerBox>
        <LeftBox>
          <StyledProducerImage
            source={{
              uri: DirectusUtil.getProducerImageUrlFromId(producer as any as Producer),
            }}
            resizeMode="cover"
          />
        </LeftBox>
        <RightBox>
          <Typography.Body bold colorName="text-dark-3">
            {producer.label}
          </Typography.Body>
        </RightBox>
      </ProducerBox>
      <Spacer size={0} axis={Spacer.AxisEnum.VERTICAL} />
      {cartBatchs.map((cartBatch) => (
        <OrderBatch key={cartBatch.id} cartBatch={cartBatch} />
      ))}
    </BoxWrapper>
  )
}

export default ClientOrderBatchs
