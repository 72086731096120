import React from 'react'
import { useTheme } from 'styled-components/native'
import { View } from 'react-native'
import {
  CardTitleWrapper,
  DeliveryNoteLineCardWrapper,
  LabelWrapperLeft,
  LabelWrapperRight,
  LeftIconWrapper,
  StyledCardWrapper,
} from './InvoiceCard.styles'
import TouchableCard from '../TouchableCard'
import Icon, { Icons } from '../Icon'
import Spacer from '../Spacer'
import Typography from '../Typography'
import BadgeStatus from '../BadgeStatus'
import FormatUtils from '../../utilities/utils/format'
import Loader from '../Loader'
import { INVOICE_STATUSES, invoiceStatusColors, InvoiceStatusEnum } from '../../../enums/invoice'
import { InvoiceStatementTypeEnum } from '../../../enums/invoice-statement'
import { Invoice } from '../../domain/Invoice'
import { InvoiceStatement } from '../../domain/InvoiceStatement'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { CarrierStackParamList } from '../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import useAuthenticationContext from '../../utilities/hook/useAuthenticationContext'

type AccountingDocumentCardProps =
  | {
      type: 'invoice'
      item: Invoice
      onDownload?: never
      isDownloading?: never
    }
  | {
      type: 'invoice-statement'
      item: InvoiceStatement
      onDownload?: (item: InvoiceStatement) => void
      isDownloading?: boolean
    }

export default function AccountingDocumentCard({
  item,
  onDownload,
  isDownloading,
  type,
}: AccountingDocumentCardProps) {
  const { getAccessInfos } = useAuthenticationContext()
  const theme = useTheme()
  const navigationProducer = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const navigationCarrier = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const onClickCard = (item: Invoice | InvoiceStatement) => {
    if (type === 'invoice') {
      if (getAccessInfos().carrierId) {
        navigationCarrier.navigate('EditInvoice', { invoiceId: item.id })
      } else {
        navigationProducer.navigate('EditInvoice', { invoiceId: item.id })
      }
    } else if (onDownload) {
      onDownload(item as InvoiceStatement)
    }
  }

  const getTitle = () => {
    if (type === 'invoice-statement') {
      const identifier =
        item.type === InvoiceStatementTypeEnum.CLIENT
          ? item.displayClientIdentifier
          : item.displayProducerIdentifier
      return `Relevé #${identifier}`
    } else {
      return `Facture #${item.displayIdentifier} - ${item.recipient.legalName}`
    }
  }

  const getSubtitle = () => {
    if (type === 'invoice-statement') {
      return `du ${FormatUtils.formatDate(
        item.periodStart.toString(),
        'Date',
      )} au ${FormatUtils.formatDate(item.periodEnd.toString(), 'Date')}`
    }
    return undefined
  }

  const getTotalHT = () => {
    if (type === 'invoice-statement') {
      return item.type === InvoiceStatementTypeEnum.CLIENT
        ? item.clientTotalAmounts?.totalHT || 0
        : item.producerTotalAmounts?.totalHT || 0
    } else {
      return item.totalAmounts.totalHT
    }
  }

  const status = INVOICE_STATUSES.find((s) => s.value === item.status)
  const statusColor = invoiceStatusColors[item.status as InvoiceStatusEnum]

  return (
    <StyledCardWrapper onPress={() => onClickCard(item)}>
      <TouchableCard>
        <DeliveryNoteLineCardWrapper>
          <LeftIconWrapper>
            <Icon
              type={Icons.MaterialCommunityIcons}
              name="file-document-multiple-outline"
              size={theme.spacingUnit * 3}
              color="color-primary"
            />
          </LeftIconWrapper>
          <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
          <LabelWrapperLeft>
            <CardTitleWrapper>
              <Typography.CardTitle>{getTitle()}</Typography.CardTitle>
            </CardTitleWrapper>
            {getSubtitle() && (
              <Typography.BodySmall colorName="text-dark-3">{getSubtitle()}</Typography.BodySmall>
            )}
            <BadgeStatus label={status?.label || ''} color={statusColor} />
          </LabelWrapperLeft>
          <Spacer size={3} axis={Spacer.AxisEnum.HORIZONTAL} />
          <LabelWrapperRight>
            <Typography.BodyExtraSmall colorName="text-dark-3">Total HT</Typography.BodyExtraSmall>
            <Typography.BodySmall bold colorName="text-dark-1">
              {FormatUtils.formatPrice(getTotalHT())}
            </Typography.BodySmall>
            <Typography.BodySmall colorName="text-dark-3">
              {`Crée le ${FormatUtils.formatDate(
                item.createdDate.toString(),
                'Date',
              )} à ${FormatUtils.formatDate(item.createdDate.toString(), 'InputTime')}`}
            </Typography.BodySmall>
          </LabelWrapperRight>
          <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
          <View style={{ display: 'flex', justifyContent: 'center' }}>
            {isDownloading ? (
              <Loader isLoading noMargin />
            ) : (
              type === 'invoice-statement' && (
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name="download-circle"
                  color="color-primary"
                  size={theme.spacingUnit * 4}
                />
              )
            )}
          </View>
        </DeliveryNoteLineCardWrapper>
      </TouchableCard>
    </StyledCardWrapper>
  )
}
