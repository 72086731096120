import React, { useRef } from 'react'
import { Animated, GestureResponderEvent } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Icon, Spacer, Typography } from '../../../../components'
import type { TabButtonProps } from './TabButton.model'
import { StyledItem, StyledTouchableOpacity } from './TabButton.styles'

const TabButton = ({ item, onPress, accessibilityState }: TabButtonProps) => {
  const theme = useTheme()
  const scale = useRef(new Animated.Value(1)).current
  const focused = accessibilityState?.selected
  const inActiveColorName = 'text-dark-3'
  const activeColorName = 'color-primary'
  const colorName = focused ? activeColorName : inActiveColorName

  if (item.hidden) {
    return null
  }

  const handlePress = (event: GestureResponderEvent) => {
    Animated.spring(scale, {
      toValue: 0.95,
      speed: 200,
      useNativeDriver: false,
    }).start(() => {
      Animated.spring(scale, {
        toValue: 1,
        speed: 200,
        useNativeDriver: false,
      }).start()
    })

    if (onPress) {
      onPress(event)
    }
  }

  const renderIcon = () => {
    if (!item.icon) {
      return null
    }

    return <Icon type={item.type} name={item.icon} color={theme.colors[colorName]} size={24} />
  }

  return (
    <StyledTouchableOpacity onPress={handlePress} activeOpacity={0.8}>
      <Animated.View
        style={{
          transform: [
            { scale },
            {
              perspective: 1000,
            },
          ],
        }}
      >
        <StyledItem>
          {renderIcon()}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
          <Typography.Menu colorName={colorName} bold={focused}>
            {item.label}
          </Typography.Menu>
        </StyledItem>
      </Animated.View>
    </StyledTouchableOpacity>
  )
}

export default TabButton
