import React, { useEffect, useRef, useState } from 'react'
import {
  TextInput as RNTextInput,
  TouchableWithoutFeedback,
  View,
  StyleSheet,
  Platform,
} from 'react-native'

import { useTheme } from 'styled-components/native'
import Typography from '../../Typography'
import InputUtils from '../inputs.utils'
import { QuantityInputProps } from './QuantityInput.model'
import {
  StyledInlineWrapper,
  StyledPrefixWrapper,
  StyledQuantityInputWrapper,
  StyledSuffixWrapper,
  StyledTextInput,
} from './QuantityInput.styles'

const styles = StyleSheet.create({
  textInput: {
    flex: 1,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
    textAlign: 'center',
  },
})

const QuantityInput = ({
  value,
  defaultValue,
  field,
  onChangeText,
  onBlurCallBack,
  onKeyPressCallBack,
  label,
  keyboardType,
  hasError,
  editable,
  autoFocus,
  prefix,
  suffix,
  placeholder,
  hidden,
  small,
}: QuantityInputProps) => {
  const theme = useTheme()
  const placeholderTextColor = theme.colors['text-dark-3']
  const textInputRef = useRef<RNTextInput>(null)
  const isFocused = textInputRef.current?.isFocused() ?? false
  const isActive = !!(isFocused || value || defaultValue)
  const { backgroundColor, borderColor, onBlur, onFocus } = InputUtils.useInputWrapperTheme({
    hasError,
    disabled: !editable,
  })
  const [isScrollEnabled, setIsScrollEnabled] = useState(false)

  // ?: to fix scrolling issue when keyboard is shown and text input is multinlined
  useEffect(() => {
    if (isFocused) {
      setTimeout(() => {
        setIsScrollEnabled(true)
      }, 500)
    } else {
      setIsScrollEnabled(false)
    }
  }, [isFocused])

  const handleInputFocus = () => {
    textInputRef.current?.focus()
  }

  const autoCapitalize = keyboardType === 'email-address' ? 'none' : 'sentences'
  const accessibilityLabel = `text-input-${field}`

  const isLabelToLong = label.length > 40

  if (hidden) return null

  const onBlurAction = () => {
    if (onBlurCallBack) {
      onBlurCallBack()
    }
    onBlur()
  }

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter' && onKeyPressCallBack) {
      onKeyPressCallBack()
    }
  }

  return (
    <TouchableWithoutFeedback onPress={handleInputFocus} testID={`${field}-input-pressable`}>
      <>
        <StyledQuantityInputWrapper
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          small={small}
        >
          <StyledInlineWrapper>
            {prefix ? (
              <StyledPrefixWrapper>
                <Typography.Body>{prefix}</Typography.Body>
              </StyledPrefixWrapper>
            ) : null}
            <View style={{ flex: 1, height: theme.inputHeight / (small ? 2 : 1) }}>
              <StyledTextInput
                ref={textInputRef as any}
                accessible
                accessibilityLabel={accessibilityLabel}
                testID={accessibilityLabel}
                value={value}
                numberOfLines={3}
                style={{
                  borderColor,
                  ...styles.textInput,
                  ...{ paddingRight: suffix ? `55%` : 0 },
                  ...{ textAlign: suffix ? `right` : 'center' },
                }}
                onChangeText={onChangeText}
                onFocus={onFocus}
                onBlur={onBlurAction}
                onKeyPress={onKeyPress}
                placeholderTextColor={placeholderTextColor}
                selectionColor={placeholderTextColor}
                keyboardType={keyboardType ?? 'default'}
                autoCapitalize={autoCapitalize}
                editable={editable}
                autoFocus={autoFocus}
                keyboardAppearance="light"
                scrollEnabled={isScrollEnabled}
                small={small}
              />
            </View>
            {suffix ? (
              <StyledSuffixWrapper>
                <Typography.Body>{suffix}</Typography.Body>
              </StyledSuffixWrapper>
            ) : null}
          </StyledInlineWrapper>
        </StyledQuantityInputWrapper>
      </>
    </TouchableWithoutFeedback>
  )
}

QuantityInput.defaultProps = {
  value: null,
  label: '',
  placeholder: '',
  keyboardType: 'default',
  secureTextEntry: false,
  defaultValue: '',
  hasError: false,
  editable: true,
}

export default QuantityInput
