import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const ToursContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: 0;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const StyledBox = styled.View`
  flex-direction: row;
`

const StyledLeftBox = styled.View`
  flex: 6;
  margin-right: ${({ theme }) => theme.spacingUnit * 1}px;
`

const StyledRightBox = styled.View`
  flex: 6;
  margin-left: ${({ theme }) => theme.spacingUnit * 1}px;
`

const StyledModeSwitchWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledModeExplainerButtonWrapper = styled.View`
  width: 100%;
  align-items: flex-end;
`

export {
  ToursContentWrapper,
  StyledButtonWrapper,
  StyledBox,
  StyledLeftBox,
  StyledRightBox,
  StyledModeSwitchWrapper,
  StyledModeExplainerButtonWrapper,
}
