import ApiService from '../../api.service'

export type CarrierDashboardMetrics = {
  nbOrders: number
  nbTours: number
  totalHT: number
  averageCarrierFees: number
}

const getDashboardMetrics = async (
  groupId: string,
  start: Date,
  end: Date,
): Promise<CarrierDashboardMetrics> => {
  const response = await ApiService.get(`/metrics/dashboard/group/${groupId}`, {
    start: start,
    end: end,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const MetricsCarrierService = {
  getDashboardMetrics,
}

export default MetricsCarrierService
