import { useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components/native'

const useInputWrapperTheme = ({
  hasError = false,
  disabled = false,
}: {
  hasError?: boolean
  disabled?: boolean
}) => {
  const theme = useTheme()
  const borderInitialColor = useMemo(() => theme.colors['color-border'], [theme])
  const backgroundInitialColor = useMemo(() => theme.colors['color-input'], [theme])

  const [borderColor, setBorderColor] = useState(backgroundInitialColor) // useState(borderInitialColor)
  const [backgroundColor, setBackgroundColor] = useState(backgroundInitialColor)

  const onFocus = () => {
    if (disabled) return
    setBorderColor(theme.colors['color-grey'])
  }

  const onBlur = () => {
    if (hasError) {
      setBorderColor(theme.colors['color-danger'])
    } else if (disabled) {
      setBackgroundColor(theme.colors['background-3'])
      setBorderColor(theme.colors['background-3'])
    } else {
      setBorderColor(borderInitialColor)
      setBackgroundColor(backgroundInitialColor)
    }
  }

  useEffect(() => {
    if (hasError) {
      setBorderColor(theme.colors['color-danger'])
    } else if (disabled) {
      setBackgroundColor(theme.colors['background-3'])
      setBorderColor(theme.colors['background-3'])
    } else {
      setBorderColor(borderInitialColor)
      setBackgroundColor(backgroundInitialColor)
    }
  }, [backgroundInitialColor, borderInitialColor, disabled, hasError, theme])

  return {
    borderColor,
    backgroundColor,
    onFocus,
    onBlur,
  }
}

const InputUtils = {
  useInputWrapperTheme,
}

export default InputUtils
