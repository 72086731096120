import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.2}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.2}px;
  flex-direction: row;
`

const StyledLeftBox = styled.View`
  flex: 7;
`

const StyledRightBox = styled.View`
  flex: 4;
  align-items: flex-end;
  justify-content: space-between;
`

export { StyledCardWrapper, StyledCardContent, StyledLeftBox, StyledRightBox }
