import React from 'react'
import { useTheme } from 'styled-components/native'
import { TourStepCardProps } from './TourStepCard.model'
import {
  StyledStepCard,
  StyledStepCardContent,
  StyledStepNumberBox,
  StyledStepCardWrapper,
  StyledMiddleBox,
  AlternativeStyledStepCardWrapper,
} from './TourStepCard.styles'
import Typography from '../Typography'
import Icon, { Icons } from '../Icon'
import Spacer from '../Spacer'

const TourStepCard = ({ step, onPress }: TourStepCardProps) => {
  const theme = useTheme()

  const isFinished = step.isFinished
  const isActive = step.isActive || isFinished

  const isDisabled = !isActive

  const displayRightBox = () => {
    if (!isActive && !step.displayMinInfos) return <></>

    if (isFinished && step.rightBoxOnFinished) {
      return step.rightBoxOnFinished
    }
    return step.rightBox || <></>
  }

  return onPress ? (
    <StyledStepCardWrapper onPress={isActive ? onPress : () => null} disabled={isDisabled}>
      <StyledStepCard completed={isFinished}>
        <StyledStepNumberBox completed={isFinished}>
          <Typography.Body colorName={isFinished ? 'text-light-1' : 'text-dark-3'}>
            {step.number}
          </Typography.Body>
        </StyledStepNumberBox>
        <StyledStepCardContent>
          <StyledMiddleBox>
            <Typography.Body colorName={isDisabled ? 'color-grey' : 'color-primary'} bold>
              {step.label}
            </Typography.Body>
            <Typography.BodySmall colorName={'text-dark-3'}>
              {step.description}
            </Typography.BodySmall>
          </StyledMiddleBox>
          {displayRightBox()}
        </StyledStepCardContent>
        <Icon
          type={Icons.Ionicons}
          name={'chevron-forward-outline'}
          size={theme.spacingUnit * 3}
          color={theme.colors['text-dark-1']}
        />
        <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.5} />
      </StyledStepCard>
    </StyledStepCardWrapper>
  ) : (
    <AlternativeStyledStepCardWrapper>
      <StyledStepCard completed={isFinished}>
        <StyledStepNumberBox completed={isFinished}>
          <Typography.Body colorName={isFinished ? 'text-light-1' : 'text-dark-3'}>
            {step.number}
          </Typography.Body>
        </StyledStepNumberBox>
        <StyledStepCardContent>
          <StyledMiddleBox>
            <Typography.Body colorName={isDisabled ? 'color-grey' : 'color-primary'} bold>
              {step.label}
            </Typography.Body>
            <Typography.BodySmall colorName={'text-dark-3'}>
              {step.description}
            </Typography.BodySmall>
          </StyledMiddleBox>
          {displayRightBox()}
        </StyledStepCardContent>
      </StyledStepCard>
    </AlternativeStyledStepCardWrapper>
  )
}

export default TourStepCard
