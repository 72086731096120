import { CommonActions, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { ClientTypeEnum } from '../../../../enums/client'
import {
  Button,
  Card,
  Col,
  Icon,
  Icons,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { AddClient, Client } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import ClientCarrierService from '../../../services/carrier/client'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import {
  ToursContentWrapper,
  StyledButtonWrapper,
  BackToListWrapper,
  StyledCardWrapper,
  StyledCardContentWrapper,
  LeftCardContentWrapper,
} from './CarrierAddClient.styles'
import GeneralUtils from '../../../utilities/utils/general'
import useGroupContext from '../../../utilities/hook/useGroupContext'

const CarrierAddClientScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierAddClient'>>()
  const [isLoading, setIsLoading] = useState(false)

  const { getAccessInfos } = useAuthenticationContext()

  const { groupId } = route?.params ?? { groupId: '' }

  const { updateGroup } = useGroupContext()

  const theme = useTheme()
  const [newClient, setNewClient] = useState<AddClient>({
    label: '',
    owners: '',
    phone1: '',
    phone2: '',
    mail: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    city: '',
    // siret: '',
  })
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [clientMailRequest, setClientMailRequest] = useState<string>('')
  const [foundClientInGroup, setFoundClientInGroup] = useState<Client>()
  const [foundClient, setFoundClient] = useState<Client>()
  const [displayNoProfile, setDisplayNoProfile] = useState<boolean>(false)
  const [formIsOpen, setFormIsOpen] = useState<boolean>(false)

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newClient)) return null

    const clientTemp = Object.assign({}, newClient)

    clientTemp[param as keyof typeof newClient] = value
    setNewClient(clientTemp)
  }

  const [selectedClientType, setSelectedClientType] = useState<any | undefined>(undefined)

  const onChangeClientType = (value: any) => {
    setSelectedClientType(value)
  }

  const onAddClient = async () => {
    setErrorMessage('')
    if (!groupId) {
      setErrorMessage('Erreur de chargement du groupe')
      return
    }
    if (newClient.mail && !GeneralUtils.isEmail(newClient.mail)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    const client = {
      clientType: ClientTypeEnum.RETAILER,
      label: newClient.label,
      owners: newClient.owners,
      phone1: newClient.phone1,
      phone2: newClient.phone2,
      mail: newClient.mail,
      addressLine1: newClient.addressLine1,
      addressLine2: newClient.addressLine2,
      postalCode: newClient.postalCode,
      city: newClient.city,
      // siret: newClient.siret,
    }

    if (!GeneralUtils.isEmail(client.mail!)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }

    const createdClient = await ClientCarrierService.create(groupId, client)
    if (createdClient && createdClient.id) {
      await queryClient.fetchQuery(['sf_group'])
      await queryClient.fetchQuery(['sf_group_clients'])

      updateGroup()

      navigation.dispatch(
        CommonActions.reset({
          index: 3,
          routes: [
            {
              name: 'CarrierGroup',
              params: {},
            },
            {
              name: 'CarrierGroupClients',
              params: {},
            },
            {
              name: 'CarrierUpdateClient',
              params: { id: createdClient.id },
            },
            {
              name: 'CarrierAddUser',
              params: {
                groupId: groupId,
                clientId: createdClient.id,
                clientLabel: client.label,
                email: client.mail,
              },
            },
          ],
        }),
      )
    } else {
      setErrorMessage('Un problème est survenu lors de la création du client')
    }
    setIsLoading(false)
  }

  const onRequestSearchMail = async () => {
    if (clientMailRequest && !GeneralUtils.isEmail(clientMailRequest)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }

    let foundExistingClient

    try {
      foundExistingClient = await ClientCarrierService.getOneClient(
        getAccessInfos().carrierId,
        undefined,
        clientMailRequest,
      )

      let foundExistingClientInGroup =
        foundExistingClient &&
        foundExistingClient.clientGroups?.find((cg) => cg.group?.id === groupId)

      if (foundExistingClientInGroup) {
        setFoundClientInGroup(foundExistingClient)
        setIsLoading(false)
        return
      }

      if (foundExistingClient) {
        setFoundClient(foundExistingClient)
        setIsLoading(false)
        return
      }
    } catch (error) {
      console.error(error)
    }

    if (!foundExistingClient) {
      setDisplayNoProfile(true)
      setIsLoading(false)
    }
  }

  const handleBackToList = () => {
    setClientMailRequest('')
    setFoundClient(undefined)
    setFormIsOpen(false)
    setDisplayNoProfile(false)
    setErrorMessage('')
    setNewClient({
      label: '',
      owners: '',
      phone1: '',
      phone2: '',
      mail: '',
      addressLine1: '',
      addressLine2: '',
      postalCode: '',
      city: '',
      // siret: '',
    })
  }

  const onValidateClient = async (foundClient: Client) => {
    setErrorMessage('')
    if (!groupId) {
      setErrorMessage('Erreur de chargement du groupe')
      return
    }

    if (foundClient) {
      try {
        setIsLoading(true)
        await ClientCarrierService.linkClient(foundClient.id, groupId)
        navigation.navigate('CarrierUpdateClient', { id: foundClient.id })
        await queryClient.fetchQuery(['sf_group'])
        await queryClient.fetchQuery(['sf_group_clients'])
      } catch (error) {
        setErrorMessage('Un problème est survenu lors de la liaison du client')
        console.error(error)
      }
    }

    setDisplayNoProfile(false)
    setFormIsOpen(true)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Ajouter un client" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {!formIsOpen && (
            <>
              {foundClient && (
                <BackToListWrapper onPress={handleBackToList}>
                  <Icon
                    type={Icons.Ionicons}
                    name="chevron-back-outline"
                    color={theme.colors['color-primary']}
                  />
                  <Button.Text label={'Revenir en arrière'} onPress={handleBackToList} />
                </BackToListWrapper>
              )}
              <SectionTitle title="Saisir un email" />
              <Row rowStyles={{ justifyContent: 'center', alignItems: 'center' }}>
                <Col xs={9}>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  <TextInput
                    value={clientMailRequest}
                    label="Email"
                    field="text"
                    onChangeText={(text) => {
                      setClientMailRequest(text)
                      setFoundClient(undefined)
                      setErrorMessage('')
                      setDisplayNoProfile(false)
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <StyledButtonWrapper>
                    {!foundClient && !displayNoProfile && (
                      <Button
                        label="Vérifier"
                        onPress={() => onRequestSearchMail()}
                        loading={isLoading}
                      />
                    )}
                  </StyledButtonWrapper>
                </Col>
              </Row>
              <Row>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                {errorMessage ? (
                  <>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                    <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  </>
                ) : null}
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              </Row>
              {foundClientInGroup && (
                <>
                  <Typography.Body colorName="color-danger">
                    Nous avons trouvé ce client associé à cette adresse e-mail dans votre groupe :
                  </Typography.Body>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                  <Typography.Body colorName="color-danger" bold>
                    - {foundClientInGroup?.label}
                  </Typography.Body>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                  <Typography.Body colorName="color-danger">
                    S'il s'agit d'un autre point de livraison, vous pouvez créer un second compte.
                  </Typography.Body>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />
                  <Row>
                    <Col xs={12}>
                      <StyledButtonWrapper>
                        <Button
                          label={'Créer un autre compte avec la même adresse mail'}
                          onPress={() => {
                            setFormIsOpen(true)
                            setDisplayNoProfile(false)
                            setValue(clientMailRequest, 'mail')
                          }}
                          loading={isLoading}
                        />
                      </StyledButtonWrapper>
                    </Col>
                  </Row>
                </>
              )}
              {foundClient && (
                <>
                  <Typography.Body colorName="color-grey">
                    Nous avons trouvé ce client associé à cette adresse e-mail dans un autre groupe.
                    Vous pouvez le rattacher à votre groupe en cliquant sur le bouton correspondant.
                  </Typography.Body>
                  <SectionTitle title="Client" />
                  <Row>
                    <Col xs={12}>
                      <StyledCardWrapper>
                        <Card>
                          <StyledCardContentWrapper>
                            <LeftCardContentWrapper>
                              <Typography.CardTitle>{foundClient.label}</Typography.CardTitle>
                              <Typography.Body>
                                {foundClient.clientGroups?.map((cg) => cg.group?.label)}
                              </Typography.Body>
                            </LeftCardContentWrapper>
                            <StyledButtonWrapper>
                              <Button
                                label="Lier"
                                onPress={() => onValidateClient(foundClient)}
                                loading={isLoading}
                              />
                            </StyledButtonWrapper>
                          </StyledCardContentWrapper>
                        </Card>
                      </StyledCardWrapper>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          {displayNoProfile && (
            <>
              <Typography.Body colorName="color-grey">
                Cette adresse email n'est pas liée à un compte Client, vous pouvez en créer un.
              </Typography.Body>
              <SectionTitle title="Créer un nouveau compte" />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />
              <Row>
                <Col xs={12}>
                  <StyledButtonWrapper>
                    <Button
                      label={'Créer un compte'}
                      onPress={() => {
                        setFormIsOpen(true)
                        setDisplayNoProfile(false)
                        setValue(clientMailRequest, 'mail')
                      }}
                      loading={isLoading}
                    />
                  </StyledButtonWrapper>
                </Col>
              </Row>
            </>
          )}

          {formIsOpen && (
            <>
              <BackToListWrapper onPress={handleBackToList}>
                <Icon
                  type={Icons.Ionicons}
                  name="chevron-back-outline"
                  color={theme.colors['color-primary']}
                />
                <Button.Text label={'Revenir en arrière'} onPress={handleBackToList} />
              </BackToListWrapper>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              {/*
              <Dropdown
                label={'Type de client'}
                onChange={onChangeClientType}
                multiple={false}
                optionsDefault={CLIENT_TYPES.map((clientType) => ({
                  ...clientType,
                }))}
                zIndex={500}
                itemKey="clientType"
              />
              */}

              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newClient.label}
                    label="Nom de l'enseigne"
                    field="text"
                    onChangeText={(text) => setValue(text, 'label')}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newClient.owners}
                    label="Gérant.e.s"
                    field="text"
                    onChangeText={(text) => setValue(text, 'owners')}
                    hidden={!newClient.label}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newClient.mail}
                    label="Email"
                    field="text"
                    onChangeText={(text) => setValue(text, 'mail')}
                    hidden={!newClient.label}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newClient.phone1}
                    label="Téléphone (0656...)"
                    field="text"
                    onChangeText={(text) => setValue(text, 'phone1')}
                    hidden={!newClient.mail || !newClient.label}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newClient.addressLine1}
                    label="N°, rue"
                    field="text"
                    onChangeText={(text) => setValue(text, 'addressLine1')}
                    hidden={!newClient.label}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newClient.addressLine2}
                    label="Complément d'adresse"
                    field="text"
                    onChangeText={(text) => setValue(text, 'addressLine2')}
                    hidden={!newClient.label}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newClient.postalCode}
                    label="Code postal"
                    field="text"
                    onChangeText={(text) => setValue(text, 'postalCode')}
                    hidden={!newClient.label}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newClient.city}
                    label="Ville"
                    field="text"
                    onChangeText={(text) => setValue(text, 'city')}
                    hidden={!newClient.label}
                  />
                </Col>
              </Row>
              {/* <Row>
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newClient.siret}
                    label="Siret"
                    field="text"
                    onChangeText={(text) => setValue(text, 'siret')}
                    hidden={!newClient.label}
                  />
                </Col>
              </Row> */}

              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <StyledButtonWrapper>
                {errorMessage ? (
                  <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                ) : null}
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                {newClient.label && (
                  <Button
                    label={FormatUtils.capitalize(i18n.t('add'))}
                    onPress={() => onAddClient()}
                    loading={isLoading}
                  />
                )}
              </StyledButtonWrapper>
            </>
          )}
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierAddClientScreen
