import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Col, Dropdown, PageTitle, Row, Spacer, Typography } from '../../../../components'
import DatePicker from '../../../../components/DatePicker'
import { DuplicateTour, Pagination, Tour, ToursFake } from '../../../../domain'
import i18n from '../../../../i18n'
import { CarrierStackParamList } from '../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../../services/carrier/tour'
import useAuthenticationContext from '../../../../utilities/hook/useAuthenticationContext'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../../utilities/styling/wrappers'
import FormatUtils from '../../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierDuplicateNewTour.styles'
import { StyledSwitchWrapper } from '../CarrierAddTour.styles'
import { Switch } from 'react-native-gesture-handler'
import { queryClient } from '../../../../utilities/queryClient'
import { TourEnums } from '../../../../../enums'

const CarrierDuplicateNewTourScreen = () => {
  const theme = useTheme()
  const [selectedTour, setSelectedTour] = useState<Tour>()

  const [errorMessage, setErrorMessage] = useState<string>('')
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()
  const [isLoading, setIsLoading] = useState(false)

  const defaultDateHour = new Date()
  defaultDateHour.setHours(0)
  defaultDateHour.setMinutes(0)

  const [newTour, setNewTour] = useState<DuplicateTour>({
    targetDate: defaultDateHour,
    defaultDateHour: defaultDateHour,
    withProducers: true,
    withStops: true,
    carrierId: undefined,
  })

  const {
    data: tours,
    refetch: refetchNextTours,
    isInitialLoading: nextToursLoading,
  } = useQuery<{ data: Tour[]; pagination: Pagination }, Error>(
    ['sf_all_tours'],
    () => TourCarrierService.getAllTours(getAccessInfos().carrierId, undefined, undefined, 50),
    {
      keepPreviousData: true,
    },
  )

  const setValueWithProducer = (value: boolean): void => {
    const userTemp = Object.assign({}, newTour)
    userTemp.withProducers = value
    setNewTour(userTemp)
  }

  const setValueWithStops = (value: boolean): void => {
    const userTemp = Object.assign({}, newTour)
    userTemp.withStops = value
    setNewTour(userTemp)
  }

  const onChangeDate = (value: any) => {
    const tourTemp = Object.assign({}, newTour)
    tourTemp.targetDate = value

    setNewTour(tourTemp)
  }

  const onChangeTour = (value: any) => {
    setSelectedTour(value)
  }

  const onDuplicateTour = async () => {
    setErrorMessage('')
    if (!selectedTour || !selectedTour.id || !newTour.targetDate) {
      setErrorMessage('Tournée ou date invalide')

      return
    }
    setIsLoading(true)
    const body = {
      start: newTour.targetDate,
      withStops: newTour.withStops,
      withProducers: newTour.withProducers,
      status: TourEnums.TourStatusEnum.ONGOING,
    }
    const createdTour = await TourCarrierService.duplicate(selectedTour.id, body)
    if (createdTour && createdTour.id) {
      await queryClient.invalidateQueries(['sf_tour', createdTour.id])
      await queryClient.fetchQuery(['sf_next_tours'])
      navigation.popToTop()
      navigation.navigate('CarrierTour', { id: createdTour.id })
    } else {
      setErrorMessage('Un problème est survenu lors de la duplication de la tournée')
    }
    setIsLoading(false)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Dupliquer une Tournée" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          <Dropdown
            label={i18n.t('chooseTour')}
            onChange={onChangeTour}
            multiple={false}
            defaultValue={selectedTour?.id}
            optionsDefault={(tours?.data || []).map((tour) => ({
              ...tour,
              label: `${FormatUtils.capitalize(
                FormatUtils.formatDate(tour.start, 'FullDate+StartTime'),
              )} - ${FormatUtils.getTourTarget(tour, 'short')}`,
              value: tour.id,
            }))}
            zIndex={1000}
            itemKey="product"
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Row>
              <Col xs={12} sm={12} md={7}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <DatePicker
                  label="Date de la nouvelle tournée"
                  onChange={onChangeDate}
                  value={newTour.targetDate}
                  mode={'date'}
                  hidden={!selectedTour}
                />
              </Col>
              <Col xs={12} sm={12} md={5}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <DatePicker
                  label="Heure de début"
                  onChange={onChangeDate}
                  value={newTour.targetDate}
                  mode={'time'}
                  hidden={!selectedTour}
                />
              </Col>
            </Row>
            {selectedTour && (
              <>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                <Typography.Body>Que souhaitez vous dupliquer dans la tournée ?</Typography.Body>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                <Row>
                  <Col xs={6}>
                    <StyledSwitchWrapper>
                      <Typography.Body>Producteurs</Typography.Body>
                      <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
                      <Switch
                        trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
                        onValueChange={(value: boolean) => setValueWithProducer(value)}
                        value={newTour.withProducers === true}
                      />
                    </StyledSwitchWrapper>
                  </Col>
                  <Col xs={6}>
                    <StyledSwitchWrapper>
                      <Typography.Body>
                        {FormatUtils.capitalize(
                          FormatUtils.pluralizeLabel(FormatUtils.getLabelFromClientType(1)),
                        )}
                      </Typography.Body>
                      <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
                      <Switch
                        trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
                        onValueChange={(value: boolean) => setValueWithStops(value)}
                        value={newTour.withStops === true}
                      />
                    </StyledSwitchWrapper>
                  </Col>
                </Row>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              </>
            )}
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

            {newTour.targetDate !== newTour.defaultDateHour && (
              <Button label="Dupliquer" onPress={() => onDuplicateTour()} loading={isLoading} />
            )}
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierDuplicateNewTourScreen
