import React from 'react'
import { useTheme } from 'styled-components/native'
import {
  SplashScreenContent,
  SplashScreenWrapper,
  StyledImage,
  StyledLogoBox,
} from './SplashScreen.styles'
import Loader from '../../../components/Loader'

const SplashScreen = () => {
  const theme = useTheme()

  return (
    <SplashScreenWrapper>
      <SplashScreenContent>
        <StyledLogoBox>
          <StyledImage
            source={require('../../../../assets/logos/logo_harvy.png')}
            resizeMode="contain"
          />
        </StyledLogoBox>
        <Loader isLoading />
      </SplashScreenContent>
    </SplashScreenWrapper>
  )
}

export default SplashScreen
