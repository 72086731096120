import styled from 'styled-components/native'
import { css } from 'styled-components/native'

const StyledProfileSwitcherCard = styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors['background-3']};
  border-radius: ${({ theme }) => theme.spacingUnit * 0.4}px;
  padding: ${({ theme }) => theme.spacingUnit * 0.7}px;
  min-width: 120px;
`
const ProfileSwitcherLeft = styled.View<{ isMobile?: boolean }>`
  justify-content: center;
  width: 120px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100px;
    `}
`
const ProfileSwitcherRight = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 1.3}px;
  justify-content: center;
`

export { StyledProfileSwitcherCard, ProfileSwitcherLeft, ProfileSwitcherRight }
