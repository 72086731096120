import { Tour } from '../../domain'
import FormatUtils from '../utils/format'

/**
 * Increase computed tour volume
 */
const safetyFactorVolume = 1.2

/**
 * Increase computed tour weight
 */
const safetyFactorWeight = 1.05

export type TourFormattedFillingInfo = {
  usedVolumeInPercent: number
  increasedTotalWeight: number
}

export type TourWithFormattedFillingInfo = Tour & TourFormattedFillingInfo

export function formatTourFillingInfo(tour: Tour): TourWithFormattedFillingInfo {
  const percentageUsedCapacity = Math.round(
    FormatUtils.numberToPercentage((tour.usedCapacity || 0) / (tour.capacity || 1)) *
      safetyFactorVolume,
  )

  const tourFormattedFillingInfo = {
    usedVolumeInPercent: percentageUsedCapacity,
    increasedTotalWeight:
      tour.totalWeight && tour.totalWeight > 0
        ? Math.round(tour.totalWeight * safetyFactorWeight)
        : 0,
  }

  return { ...tour, ...tourFormattedFillingInfo }
}
