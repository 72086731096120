import { Alert } from 'react-native'
import { Divider, Typography, Card } from '../../../../../components'
import DashboardStat from '../CatalogStat'
import { CatalogTopBoxProps } from './CatalogTopBox.model'
import { StatsWrapper, StyledCardWrapper } from './CatalogTopBox.styles'

const CatalogTopBox = ({ products, pagination }: CatalogTopBoxProps) => {
  const openLink = () => {
    Alert.alert('Open card link 🔗')
  }
  return (
    <StyledCardWrapper onPress={openLink}>
      <Card>
        <StatsWrapper>
          <DashboardStat value={(pagination?.totalCount || 0).toString()} label="Total" />
          <DashboardStat value="-" label="Disponible" />
          <DashboardStat value="-" label="Notes" />
        </StatsWrapper>
      </Card>
    </StyledCardWrapper>
  )
}

export default CatalogTopBox
