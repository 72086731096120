import { Typography } from '../../../../../components'
import { CatalogStatProps } from './CatalogStat.model'
import { StyledStatWrapper } from './CatalogStat.styles'

const CatalogStat = ({ value, label }: CatalogStatProps) => {
  return (
    <StyledStatWrapper>
      <Typography.Body>{label}</Typography.Body>
      <Typography.CardTitle colorName="text-dark-1">{value}</Typography.CardTitle>
    </StyledStatWrapper>
  )
}

export default CatalogStat
