import styled from 'styled-components/native'

const StyledButtonWrapper = styled.View`
  width: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const AddProducersTourWrapper = styled.View`
  gap: ${({ theme }) => theme.spacingUnit * 25}px;
`

const BottomContentWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const StyledButtonsWrapper = styled.View`
  flex-direction: row;
  gap: ${({ theme }) => theme.spacingUnit * 2}px;
`

export { StyledButtonWrapper, AddProducersTourWrapper, BottomContentWrapper, StyledButtonsWrapper }
