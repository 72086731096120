import { useCallback, useEffect, useState } from 'react'
import { Spacer } from '../../../../components'
import CardListPaginated from '../../../../components/CardListPaginated'
import FilterModule from '../../../../components/FilterModule'
import PageTitle from '../../../../components/PageTitle'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'
import { InvoiceStatement } from '../../../../domain/InvoiceStatement'
import InvoiceStatementService from '../../../../services/invoice-statement'
import {
  InvoiceFilterEnum,
  SelectedValues,
} from '../../../../components/FilterModule/FilterModule.model'
import { INVOICE_STATUSES, InvoiceStatusEnum } from '../../../../../enums/invoice'
import PdfUtils from '../../../../utilities/utils/pdf'
import useAuthenticationContext from '../../../../utilities/hook/useAuthenticationContext'
import { InvoiceStatementTypeEnum } from '../../../../../enums/invoice-statement'
import ExtendedDateFilter, { MetricsFilters } from '../../../../components/DateFilterExtended'
import AccountingDocumentCard from '../../../../components/AccountingDocumentCard'

const InvoiceStatementListScreen = () => {
  const [invoiceStatementList, setInvoiceStatementList] = useState<InvoiceStatement[]>([])
  const [isDownloading, setIsDownloading] = useState(false)
  const [pageSize, setPageSize] = useState(5)
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedFilters, setSelectedFilters] = useState<SelectedValues>({})
  const [currentlyUpdatingFilter, setCurrentlyUpdatingFilter] = useState<InvoiceFilterEnum | null>(
    null,
  )
  const [metricsFilters, setMetricsFilters] = useState<MetricsFilters>()

  const { getAccessInfos } = useAuthenticationContext()
  const isClient = getAccessInfos().clientId !== null

  const { query: invoiceStatementsQuery } = InvoiceStatementService.useFindInvoiceStatements({
    status: selectedFilters[InvoiceFilterEnum.STATUS] as InvoiceStatusEnum | undefined,
    type: isClient ? InvoiceStatementTypeEnum.CLIENT : InvoiceStatementTypeEnum.PRODUCER,
    start: metricsFilters?.start,
    end: metricsFilters?.end,
    limit: pageSize,
    pageNumber: pageNumber,
  })

  const {
    data: invoiceStatementData,
    refetch: refetchInvoiceStatements,
    isLoading: invoiceStatementsLoading,
  } = invoiceStatementsQuery()

  const handleDateFilterChange = useCallback((newFilters: MetricsFilters) => {
    setMetricsFilters(newFilters)
  }, [])

  useEffect(() => {
    if (metricsFilters) {
      refetchInvoiceStatements()
    }
  }, [metricsFilters, refetchInvoiceStatements])

  useEffect(() => {
    if (invoiceStatementData?.data) {
      setInvoiceStatementList(invoiceStatementData?.data)
    }
  }, [invoiceStatementData?.data])

  const onChangePage = async (pageNumber: number) => {
    setPageNumber(pageNumber)
    refetchInvoiceStatements()
  }

  const getStatusFilter = useCallback(() => {
    const getConditionalLabel = (filterKey: InvoiceFilterEnum) => {
      if (selectedFilters[filterKey] && filterKey === InvoiceFilterEnum.STATUS) {
        const selectedStatus = INVOICE_STATUSES.find(
          (status) => status.value.toString() === selectedFilters[filterKey]?.toString(),
        )
        return `Statut : ${selectedStatus?.label || 'Sélectionné'}`
      }
      return 'par statut'
    }

    return [
      {
        key: InvoiceFilterEnum.STATUS,
        active: !!selectedFilters[InvoiceFilterEnum.STATUS],
        label: getConditionalLabel(InvoiceFilterEnum.STATUS),
        options: INVOICE_STATUSES.map((status) => ({ label: status.label, value: status.value })),
      },
    ]
  }, [selectedFilters, INVOICE_STATUSES])

  const handleFilterUpdate = async (filterKey: InvoiceFilterEnum, selectedValue?: any) => {
    if (selectedValue === undefined) {
      setCurrentlyUpdatingFilter(currentlyUpdatingFilter === filterKey ? null : filterKey)
    } else {
      setCurrentlyUpdatingFilter(null)
      setSelectedFilters((prev) => ({
        ...prev,
        [filterKey]: selectedValue,
      }))
      setPageNumber(1)
      await refetchInvoiceStatements()
    }
  }

  const handleFilterDelete = async (filterKey: InvoiceFilterEnum) => {
    setSelectedFilters((prev) => {
      const newFilters = { ...prev }
      delete newFilters[filterKey]
      return newFilters
    })
    setCurrentlyUpdatingFilter(null)
    setPageNumber(1)
    await refetchInvoiceStatements()
  }

  const onClickDownloadInvoiceStatement = async (invoiceStatement: InvoiceStatement) => {
    setIsDownloading(true)
    try {
      const base64Response = await InvoiceStatementService.getPdf(invoiceStatement.id)
      if (!PdfUtils.isValidBase64Pdf(base64Response)) {
        throw new Error("Le PDF reçu n'est pas valide")
      }
      PdfUtils.downloadPdf(base64Response, invoiceStatement.fileName)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Relevés" />
        <ExtendedDateFilter onFilterChange={handleDateFilterChange} />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <FilterModule
          filters={getStatusFilter()}
          currentlyUpdating={currentlyUpdatingFilter}
          onFilterUpdate={handleFilterUpdate}
          onFilterDelete={handleFilterDelete}
        />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <CardListPaginated
          data={invoiceStatementList}
          pagination={invoiceStatementData?.pagination}
          element={(invoiceStatement: InvoiceStatement) => (
            <AccountingDocumentCard
              key={invoiceStatement.id}
              item={invoiceStatement}
              onDownload={onClickDownloadInvoiceStatement}
              isDownloading={isDownloading}
              type="invoice-statement"
            />
          )}
          emptyMessage="Aucun relevé n'a été créé"
          onChangePage={onChangePage}
          isLoading={invoiceStatementsLoading || isDownloading}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default InvoiceStatementListScreen
