import styled, { css } from 'styled-components/native'
import { IconButtonProps } from '../Button.model'

const StyledTouchableOpacity = styled.TouchableOpacity<Partial<IconButtonProps>>`
  padding: ${({ theme }) => theme.spacingUnit * 0.5}px ${({ theme }) => theme.spacingUnit * 0.5}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.8}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1}px;
  flex-direction: row;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  justify-content: center;
  align-items: center;
  width: fit-content;

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-bottom: 0;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      max-width: 290px;
    `}

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}

  ${({ colorName }) =>
    colorName &&
    css`
      background-color: ${({ theme }) => theme.colors[colorName]};
    `}

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 50%;
    `}


  ${({ small }) =>
    small &&
    css`
      height: ${({ theme }) => theme.buttonHeight * 0.7}px;
      padding: ${({ theme }) => theme.spacingUnit * 0.6}px;
      padding-right: ${({ theme }) => theme.spacingUnit * 1.2}px;
      padding-left: ${({ theme }) => theme.spacingUnit * 0.8}px;
    `}

    ${({ bordered }) =>
    bordered &&
    css`
      border-width: 1px;
      border-color: ${({ theme }) => theme.colors['color-border']};
    `}
`
export default StyledTouchableOpacity
