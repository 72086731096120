import styled from 'styled-components/native'
import { css } from 'styled-components/native'

const CardContentWrapper = styled.View`
  padding: ${({ theme }) => theme.spacingUnit * 0.5}px;
  flex-direction: row;
  justify-content: space-between;
`
const LeftBoxWrapper = styled.View``

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
`

export { CardContentWrapper, LeftBoxWrapper as LeftBoxWrapper, StyledCardWrapper }
