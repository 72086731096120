import { useState } from 'react'
import Card from '../Card'
import Icon, { Icons } from '../Icon'
import Typography from '../Typography'
import CardTitle from '../Typography/CardTitle'
import { CopyPasteAddressCardProps } from './CopyPasteAddressCard.model'
import { CardContent, CardLeftContent, CardRightContent } from './CopyPasteAddressCard.styles'

const CopyPasteAddressCard = ({
  producerAddress,
  clientAddress,
  isClientLabel,
  onPress,
}: CopyPasteAddressCardProps) => {
  const [isCopied, setIsCopied] = useState(false)

  return (
    <Card>
      <CardContent>
        <CardLeftContent>
          {producerAddress && (
            <>
              <CardTitle bold>{producerAddress?.label}</CardTitle>
              <Typography.BodySmall colorName="color-primary">
                {producerAddress?.addressLine1}
              </Typography.BodySmall>
              {producerAddress?.addressLine2 && (
                <Typography.BodySmall colorName="color-primary">
                  {producerAddress?.addressLine2}
                </Typography.BodySmall>
              )}
              <Typography.BodySmall colorName="color-primary">
                {producerAddress?.postalCode} {producerAddress?.city}
              </Typography.BodySmall>
            </>
          )}
          {clientAddress ? (
            <>
              {isClientLabel && <CardTitle bold>{clientAddress?.label}</CardTitle>}
              <Typography.Body colorName="color-primary">
                {clientAddress?.addressLine1}
              </Typography.Body>
              {clientAddress?.addressLine2 && (
                <Typography.Body colorName="color-primary">
                  {clientAddress?.addressLine2}
                </Typography.Body>
              )}
              <Typography.Body colorName="color-primary" bold>
                {clientAddress?.postalCode} {clientAddress?.city}
              </Typography.Body>
            </>
          ) : null}
        </CardLeftContent>
        <CardRightContent>
          <Icon
            type={Icons.Ionicons}
            name={isCopied ? 'checkmark' : 'copy-outline'}
            size={14}
            color={isCopied ? 'color-primary' : 'text-dark-3'}
            onPress={() => {
              setIsCopied(true)
              onPress(isCopied)
            }}
          />
        </CardRightContent>
      </CardContent>
    </Card>
  )
}

export default CopyPasteAddressCard
