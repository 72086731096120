import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Card,
  Col,
  Dropdown,
  NumberInput,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import Loader from '../../../components/Loader'
import {
  AddCartBatchCarrier,
  Batch,
  Cart,
  CartBatch,
  Catalog,
  Product,
  Tour,
  UdpateCartBatchCarrier,
  UpdateBatch,
} from '../../../domain'
import i18n from '../../../i18n'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import BatchProducerService from '../../../services/producer/batch'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FeesUtil from '../../../utilities/utils/fees'
import FormatUtils from '../../../utilities/utils/format'
import { BatchContentWrapper, StyledButtonWrapper } from './CarrierAddCartBatch.styles'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import OrderCarrierService from '../../../services/carrier/order'
import TourCarrierService from '../../../services/carrier/tour'
import { ClientTypeEnum } from '../../../../enums'
import BatchCarrierService from '../../../services/carrier/batch'
import { GroupEnums } from '../../../../enums/group'
import useGroupContext from '../../../utilities/hook/useGroupContext'

const CarrierAddCartBatchScreen = () => {
  const theme = useTheme()
  const { group } = useGroupContext()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierAddCartBatch'>>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [crateQuantityString, setCrateQuantityString] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [selectedProducer, setSelectedProducer] = useState<any>()

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const [selectedBatch, setSelectedBatch] = useState<Batch>()
  const [cartBatch, setCartBatch] = useState<AddCartBatchCarrier>({
    batchId: undefined,
    clientId: undefined,
    packingQuantity: undefined,
    packing: undefined,
    totalQuantity: undefined,
    unitPrice: undefined,
    unitPriceProducer: undefined,
    batch: undefined,
    carrierFees: undefined,
    otherBatchs: undefined,
  })

  const { cartId, clientId, tourId } = route?.params ?? {
    cartId: '',
    clientId: '',
    tourId: '',
  }

  const { data: tour, isInitialLoading: tourLoading } = TourCarrierService.getOne.query(tourId)

  const {
    data: cart,
    refetch: refetchCart,
    isInitialLoading: cartLoading,
  } = useQuery<Cart, Error>(['sf_cart', cartId], () =>
    OrderCarrierService.getOneCartOrder(cartId, clientId),
  )

  const {
    data: catalog,
    refetch: refetchBatchs,
    isLoading: batchsLoading,
    isRefetching: batchsRefetching,
  } = useQuery<Catalog, Error>(
    ['c_catalog'],
    () =>
      BatchCarrierService.getAllBatchs(tourId, cartId, 100, 1, undefined, [selectedProducer.id]),
    {
      enabled:
        cart?.tour?.id !== undefined &&
        cart?.id !== undefined &&
        selectedProducer?.id !== undefined,
    },
  )

  const setValue = (value: any, param: string) => {
    if (!cartBatch || (!param && !(param in cartBatch))) return null
    const cartBatchTemp = Object.assign({}, cartBatch)

    cartBatchTemp[param as keyof typeof cartBatch] = value
    setCartBatch(cartBatchTemp)
  }

  const onChangeBatch = (value: any) => {
    if (!value || !value.id) return
    const cartBatchTemp = Object.assign({}, cartBatch)
    cartBatchTemp.batch = value
    cartBatchTemp.batchId = value.id
    cartBatchTemp.packing = value.product?.packing

    cartBatchTemp.unitPriceProducer = value.unitPriceProducer
    cartBatchTemp.unitPrice = value.unitPrice
    cartBatchTemp.carrierFees = value.priceCarrierFees
    cartBatchTemp.otherBatchs = value.otherBatchs

    setCartBatch(cartBatchTemp)

    setSelectedBatch(value)
  }

  const onChangeCrateQuantity = (crateQuantityString: string) => {
    const cartBatchTemp = Object.assign({}, cartBatch)

    if (!crateQuantityString || crateQuantityString === '0') {
      cartBatchTemp.totalQuantity = 0
      cartBatchTemp.packingQuantity = 0
      setCartBatch(cartBatchTemp)
      setCrateQuantityString(crateQuantityString)

      return
    }
    const crateQuantity = parseFloat(crateQuantityString)
    const totalQuantity = crateQuantity * (cartBatch?.packing || 1)

    cartBatchTemp.totalQuantity = totalQuantity
    cartBatchTemp.packingQuantity = crateQuantity
    setCartBatch(cartBatchTemp)
    setCrateQuantityString(crateQuantityString)
  }

  const onUpdateCartBatch = async () => {
    if (!cartBatch || !cart) return

    if (cartBatch?.totalQuantity === undefined || cartBatch?.totalQuantity < 0) {
      setErrorMessage('La quantité disponible ne peut pas être inférieure à 0')
      return
    }

    if (!cart.order?.id || !cart.client?.id) {
      setErrorMessage('Aucune commande retrouvée ou client associé.')
      return
    }

    setErrorMessage('')

    setIsLoading(true)
    const cartBatchTemp = {
      batchId: cartBatch.batchId,
      packingQuantity: cartBatch.packingQuantity,
      packing: cartBatch.packing,
      totalQuantity: cartBatch.totalQuantity,
      unitPrice: cartBatch.unitPrice,
      unitPriceProducer: cartBatch.unitPriceProducer,
      carrierFees: cartBatch.carrierFees,
      otherBatchs: cartBatch.otherBatchs,
    }

    const updatedCartBatchTemp = await OrderCarrierService.updateCartBatch(
      cart.order?.id,
      cart.client?.id,
      cartBatchTemp,
    )
    if (updatedCartBatchTemp && updatedCartBatchTemp.id) {
      await queryClient.setQueryData(['sf_cart', cartId], null)
      await queryClient.invalidateQueries(['sf_cart', cartId])
      await queryClient.invalidateQueries(['sf_tour', tourId])
      await queryClient.invalidateQueries(['sf_stop'])
      await queryClient.invalidateQueries(['sf_tour_producer'])
      await queryClient.invalidateQueries(['sf_next_tours'])
      await queryClient.invalidateQueries(['sf_tour_clients'])
      await queryClient.invalidateQueries(['sf_tour_delivery_notes', tourId, clientId])
      await queryClient.invalidateQueries([
        'sf_tour_delivery_notes',
        tourId,
        cartBatch.batch?.product?.producer?.id,
      ])

      setIsConfirmed(true)
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour')
    }
    setIsLoading(false)
  }

  const onGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    }
  }

  const onChangeProducer = (value: any) => {
    setSelectedProducer(value)
  }

  if (cartLoading || !cartBatch || tourLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!cart || !cartBatch || !tour) return null

  const groupTypeIsDeliveryProducers = group?.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <BatchContentWrapper>
          <PageTitle
            title={`Modifier une commande`}
            buttonRight={
              <>
                <Typography.BodySmall>
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                  {'\n'}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </>
            }
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            {groupTypeIsDeliveryProducers && (
              <Typography.BodySmall colorName="color-warning" align="center">
                Vous ne pouvez pas modifier cette information, car vous êtes dans un groupe de type
                "producteur-livreurs", seuls les producteurs peuvent modifier cette information
                depuis leur espace.
              </Typography.BodySmall>
            )}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Card>
              <Typography.Body>Client</Typography.Body>
              <Typography.CardTitle>{cart?.client?.label}</Typography.CardTitle>
            </Card>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Dropdown
              label={'Choisir un producteur'}
              onChange={onChangeProducer}
              multiple={false}
              optionsDefault={(tour?.producerTour || []).map((producerTour) => ({
                ...producerTour.producer,
                label: producerTour.producer?.label,
                value: producerTour.producer?.id,
              }))}
              zIndex={1000}
              itemKey="producer"
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Dropdown
              label={'Choisir un lot à ajouter'}
              onChange={onChangeBatch}
              multiple={false}
              defaultValue={selectedBatch?.id}
              optionsDefault={
                !catalog?.batchs
                  ? []
                  : catalog.batchs.map((batch) => ({
                      ...batch,
                      key: batch.id,
                      label: `${batch.product?.label} ${
                        (cart.cartBatchs || []).find(
                          (cb) => cb && cb.batch && cb.batch?.id === batch.id,
                        )
                          ? '(déjà dans la commande) '
                          : ''
                      }- ${batch.product?.producer?.label}`,
                      value: batch.id,
                    }))
              }
              zIndex={1000}
              itemKey="batch"
              hidden={!selectedProducer?.id}
            />
            {selectedBatch?.id &&
              !(cart.cartBatchs || []).find(
                (cb) => cb && cb.batch && cb.batch?.id === selectedBatch.id,
              ) && (
                <>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  <Typography.Body>
                    Quantité disponible :{' '}
                    {((selectedBatch?.availableQuantity || 0) -
                      (selectedBatch?.orderedQuantity || 0)) /
                      (selectedBatch?.product?.packing || 1)}{' '}
                    colis (
                    {FormatUtils.formatQuantity(
                      (selectedBatch?.availableQuantity || 0) -
                        (selectedBatch?.orderedQuantity || 0),
                      selectedBatch?.product?.mesureType,
                    )}
                    )
                  </Typography.Body>

                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                      {!isConfirmed ? (
                        <TextInput
                          value={cartBatch.unitPrice?.toString()}
                          label={`Prix unitaire (par ${FormatUtils.formatUnity(
                            cartBatch.batch?.product?.mesureType,
                          )})`}
                          field="text"
                          onChangeText={(text) => setValue(text, 'unitPrice')}
                          editable
                        />
                      ) : (
                        <>
                          <Typography.BodySmall>
                            Prix unitaire (par{' '}
                            {FormatUtils.formatUnity(cartBatch.batch?.product?.mesureType)})
                          </Typography.BodySmall>
                          <Typography.BodySmall bold>
                            {FormatUtils.formatPrice(cartBatch.unitPrice)}
                          </Typography.BodySmall>
                        </>
                      )}
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                      {!isConfirmed ? (
                        <TextInput
                          value={crateQuantityString}
                          label={`Colis commandés`}
                          field="text"
                          onChangeText={(text) => onChangeCrateQuantity(text)}
                          editable
                        />
                      ) : (
                        <>
                          <Typography.BodySmall>Colis commandés</Typography.BodySmall>
                          <Typography.BodySmall bold>
                            {cartBatch.packingQuantity} (
                            {FormatUtils.formatQuantity(
                              cartBatch.totalQuantity,
                              cartBatch.batch?.product?.mesureType,
                            )}
                            )
                          </Typography.BodySmall>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
                </>
              )}

            {!isConfirmed &&
              selectedBatch?.id &&
              (cart.cartBatchs || []).find(
                (cb) => cb && cb.batch && cb.batch?.id === selectedBatch.id,
              ) && (
                <>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                  <Typography.BodySmall colorName="color-danger">
                    Ce lot est déjà dans la commande.
                  </Typography.BodySmall>
                </>
              )}

            {!isConfirmed &&
              selectedBatch?.id &&
              !(cart.cartBatchs || []).find(
                (cb) => cb && cb.batch && cb.batch?.id === selectedBatch.id,
              ) && (
                <>
                  <Typography.BodySmall>
                    En ajoutant ce lot à la commande le total de commande sera actualisé et le bon
                    de livraison supprimé
                  </Typography.BodySmall>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                  {errorMessage ? (
                    <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                  ) : null}
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <StyledButtonWrapper>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <Button
                      label={FormatUtils.capitalize(i18n.t('add'))}
                      onPress={() => onUpdateCartBatch()}
                      loading={isLoading}
                      disabled={groupTypeIsDeliveryProducers}
                    />
                  </StyledButtonWrapper>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                </>
              )}

            {isConfirmed && (
              <>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                <Typography.BodySmall colorName="color-danger">
                  Pensez à re-générer les bons de livraison et actualiser les identifications de
                  cagette
                </Typography.BodySmall>
                <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />

                <StyledButtonWrapper>
                  <Button label={'Terminer'} onPress={() => onGoBack()} loading={isLoading} />
                </StyledButtonWrapper>
              </>
            )}

            <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
          </ScrollableFormWrapper>
        </BatchContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierAddCartBatchScreen
