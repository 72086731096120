import React, { ReactNode } from 'react'
import Spacer from '../../components/Spacer'
import Text from '../../components/Text'
import { TextTag } from '../../components/Text/Text.model'

const stringToKey = (input: string): string => input.replace(/\s/g, '-').toLowerCase()

const capitalize = (input: string): string => {
  if (typeof input !== 'string' || !input) return ''

  const wordsToIgnore = ['of', 'the', 'and', 'in', 'for', 'to', 'a', 'an', 'or']

  return input
    .split(' ')
    .map((word: string) => {
      if (wordsToIgnore.includes(word)) {
        return word
      }
      return word[0].toUpperCase() + word.slice(1)
    })
    .join(' ')
}

const stringToPascalCase = (input: string): string =>
  input
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')

const flattenObject = (obj: object) => {
  const toReturn = {}

  if (obj) {
    Object.keys(obj).forEach((key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const value = obj[key as keyof typeof obj]

        if (typeof value === 'object') {
          const flatObject = flattenObject(value)

          Object.keys(flatObject).forEach((flatKey) => {
            // eslint-disable-next-line no-prototype-builtins
            if (flatObject.hasOwnProperty(flatKey)) {
              toReturn[`${key}.${flatKey}` as keyof typeof toReturn] =
                flatObject[flatKey as keyof typeof flatObject]
            }
          })
        } else {
          toReturn[key as keyof typeof toReturn] = value
        }
      }
    })
  }

  return toReturn
}

const removeHtmlTags = (html: string, keepBold?: boolean): string => {
  // extract <br/> or </br> or <li/> or </li>
  const regexBRorLi = /<\/?br\s*\/?>|<\/?li\s*\/?>/gi
  // extract <b> or </b> or <strong> or </strong>
  const regexBold = /<\/?b\s*\/?>|<\/?strong\s*\/?>/gi
  // extract any other tag
  const regexOtherTags = /<[^>]*>/gi

  let result = html.replace(regexBRorLi, '\n')

  if (keepBold) {
    result = result.replace(regexBold, '{bold}')
  }

  result = result.replace(regexOtherTags, '')

  // remove trailing spaces
  result = result.replace(/\s+$/, '')
  // remove leading spaces
  result = result.replace(/^\s+/, '')

  return result
}

const htmlToText = (html: string, textTag?: TextTag): ReactNode => {
  const text = removeHtmlTags(html, true)
  const lines = text.split('\n')

  const result = lines.map((line: string, index: number) => {
    const isLast = index === lines.length - 1

    let lineToPrint = line
    const isBold = lineToPrint.includes('{bold}')

    if (isBold) {
      lineToPrint = line.replaceAll('{bold}', '')
    }

    const tag = textTag || TextTag.BODY

    return (
      <>
        <Text
          // eslint-disable-next-line
          key={`${stringToKey(line)}-${index}`}
          tag={tag}
          bold={isBold}
          gutterBottom={isBold}
        >
          {lineToPrint.trim()}
        </Text>
        {isLast ? null : <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />}
      </>
    )
  })

  return result
}

const removeExtensionFromFileName = (fileName: string): string => {
  const extension = fileName.split('.').pop()
  return fileName.replace(`.${extension}`, '')
}

export {
  stringToKey,
  capitalize,
  stringToPascalCase,
  flattenObject,
  removeHtmlTags,
  htmlToText,
  removeExtensionFromFileName,
}
