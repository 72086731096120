import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Dimensions } from 'react-native'
import ForceUpgradeScreen from '../../screens/Authentification/ForceUpgrade'
import LockedScreen from '../../screens/Authentification/LockedScreen'
import ClientMainNavigationStack from './ClientMainNavigationStack'
import UserClientConfigurationNavigation from './UserClientNavigation/UserClientConfigurationNavigation'

const Stack = createStackNavigator()

const ClientNavigationStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="ClientMainNavigation"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="ClientMainNavigation" component={ClientMainNavigationStack} />

      <Stack.Screen
        name="UserClientConfigurationNav"
        component={UserClientConfigurationNavigation}
        options={{
          headerShown: false,
          presentation: 'transparentModal',
          ...TransitionPresets.ModalPresentationIOS,
          gestureResponseDistance: Dimensions.get('window').height,
        }}
      />

      <Stack.Screen
        name="ForceUpgradeScreen"
        component={ForceUpgradeScreen}
        options={{ gestureEnabled: false, headerShown: false, animationEnabled: false }}
      />

      <Stack.Screen
        name="LockedScreen"
        component={LockedScreen}
        options={{ gestureEnabled: false, headerShown: false, animationEnabled: false }}
      />
    </Stack.Navigator>
  )
}

export default ClientNavigationStack
