import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Row,
  SectionTitle,
  Spacer,
  TextInput,
  Typography,
} from '../../../components'

import { StyledButtonWrapper, StyledCardAddress, StyledContentCard } from './AddressForm.styles'
import { AddressFormProps } from './AddressForm.model'
import { AddAddress, AddressType } from '../../../domain'
import AddressService from '../../../services/common/address'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { queryClient } from '../../../utilities/queryClient'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { View } from 'react-native'
import DropShadow from 'react-native-drop-shadow'
import { useTheme } from 'styled-components/native'

const AddressForm = ({
  address,
  onChangeAddress,
  carrier,
  producer,
  client,
  group,
  autoDisplayForm,
  sectionTitle = 'Adresse',
  canEdit = true,
  addressType = AddressType.DELIVERY,
  sectionTitleNoMarginLeft,
  boldInputLabel,
}: AddressFormProps) => {
  const { getAccessInfos } = useAuthenticationContext()
  const theme = useTheme()

  const [isLoading, setIsLoading] = useState(false)
  const [isAddMode, setIsAddMode] = useState(false)
  const [newAddress, setNewAddress] = useState<AddAddress>({
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    city: '',
    indications: '',
  })
  const [errorMessage, setErrorMessage] = useState<string>('')

  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  useEffect(() => {
    if (!address || !address.id) return

    setNewAddress({
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      postalCode: address.postalCode,
      city: address.city,
      indications: address.indications,
    })
  }, [address?.id, producer?.id, client?.id])

  useEffect(() => {
    if (autoDisplayForm) {
      setIsAddMode(true)
    }
  }, [autoDisplayForm])

  const setValue = (value: any, param: string) => {
    setErrorMessage('')

    if (!param && !(param in newAddress)) return null
    const tempContainer = Object.assign({}, newAddress)

    tempContainer[param as keyof typeof newAddress] = value
    setNewAddress(tempContainer)
  }

  const onAddAddress = async () => {
    if (!newAddress.addressLine1 || !newAddress.city || !newAddress.postalCode) {
      setErrorMessage(`Veuillez compléter les champs obligatoires`)
      setIsLoading(false)
      return
    }

    if (newAddress.postalCode.length !== 5) {
      setErrorMessage(`Le code postal doit contenir 5 chiffres`)
      setIsLoading(false)
      return
    }
    setErrorMessage('')
    setIsLoading(true)
    const addressToAdd = {
      addressLine1: newAddress.addressLine1,
      addressLine2: newAddress.addressLine2,
      postalCode: newAddress.postalCode,
      city: newAddress.city,
      indications: newAddress.indications,
      carrierId: carrier?.id || undefined,
      producerId: producer?.id || undefined,
      clientDeliveryId: addressType === AddressType.DELIVERY ? client?.id : undefined,
      clientBillingId: addressType === AddressType.BILLING ? client?.id : undefined,
      groupId: group?.id || undefined,
    }

    let addedAddress = undefined
    try {
      if (address) {
        addedAddress = await AddressService.update(address.id, addressToAdd)
      } else {
        addedAddress = await AddressService.create(addressToAdd)
      }

      handleSuccess(addedAddress)
    } catch (error) {
      handleFailure(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSuccess = (addedAddress: any) => {
    setIsAddMode(false)

    if (onChangeAddress) {
      onChangeAddress(addedAddress)
      queryClient.invalidateQueries(['sf_group'])
      queryClient.invalidateQueries(['sf_group_carrier', carrier?.id])
      queryClient.invalidateQueries(['account', getAccessInfos().accountId])

      if (autoDisplayForm) {
        navigation.goBack()
        queryClient.invalidateQueries(['p_producer', getAccessInfos().producerId])
      }
    }
  }

  const handleFailure = (error: any) => {
    console.error(error)
    setErrorMessage("Un problème est survenu lors de l'ajout de l'adresse")
  }

  const renderButtonRight = () => {
    if (isAddMode) {
      return (
        <>
          <Spacer size={2.4} axis={Spacer.AxisEnum.VERTICAL} />
          <Button small label={'Annuler'} onPress={() => setIsAddMode(!isAddMode)} />
        </>
      )
    }

    if (!isAddMode && canEdit && !address) {
      return (
        <>
          <Spacer size={2.4} axis={Spacer.AxisEnum.VERTICAL} />
          <Button
            small
            label={'Ajouter une adresse'}
            onPress={() => {
              setIsAddMode(true)
              setNewAddress({
                addressLine1: '',
                addressLine2: '',
                postalCode: '',
                city: '',
                indications: '',
              })
            }}
          />
        </>
      )
    }

    return null
  }

  const displayAddForm = () => {
    if (!isAddMode) return undefined

    return (
      <>
        <Row>
          <Col xs={12} sm={12} md={6}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newAddress.addressLine1}
              label="Adresse (n°, rue)"
              field="text"
              onChangeText={(text) => setValue(text, 'addressLine1')}
              boldInputLabel={boldInputLabel}
              extraMargin
            />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newAddress.addressLine2}
              label="Complément d'adresse"
              field="text"
              onChangeText={(text) => setValue(text, 'addressLine2')}
              boldInputLabel={boldInputLabel}
              extraMargin
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={6}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newAddress.postalCode}
              label="Code postal"
              field="text"
              onChangeText={(text) => setValue(text, 'postalCode')}
              boldInputLabel={boldInputLabel}
              extraMargin
            />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newAddress.city}
              label="Ville"
              field="text"
              onChangeText={(text) => setValue(text, 'city')}
              boldInputLabel={boldInputLabel}
              extraMargin
            />
          </Col>
        </Row>
        {addressType === AddressType.DELIVERY && (!producer || !carrier) && (
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <TextInput
                value={newAddress.indications}
                label="Indications de livraison"
                field="text"
                onChangeText={(text) => setValue(text, 'indications')}
                multiline
                boldInputLabel={boldInputLabel}
                extraMargin
              />
            </Col>
          </Row>
        )}
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {errorMessage ? (
          <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
        ) : null}
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

        <StyledButtonWrapper>
          <Button
            label={address ? 'Modifier' : 'Ajouter'}
            onPress={onAddAddress}
            disabled={newAddress.city === ''}
          />
        </StyledButtonWrapper>
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      </>
    )
  }

  const displayAddress = () => {
    if (!address || isAddMode) return undefined

    return (
      <StyledCardAddress>
        <DropShadow
          style={{
            shadowColor: theme.colors['color-shadow'],
            shadowOffset: {
              width: 1,
              height: 5,
            },
            shadowOpacity: 1,
            shadowRadius: theme.borderRadius * 1,
            borderRadius: 10,
          }}
        >
          <Card>
            <StyledContentCard>
              <View style={{ width: '80%' }}>
                {address.addressLine1 && (
                  <Typography.Body ellipsis style={{ fontWeight: '500' }}>
                    {address.addressLine1}
                  </Typography.Body>
                )}
                <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                {address.addressLine2 && (
                  <Typography.Body ellipsis colorName="text-dark-3" bold>
                    {address.addressLine2}
                  </Typography.Body>
                )}
                <Spacer size={0.6} axis={Spacer.AxisEnum.VERTICAL} />
                {(address.postalCode || address.city) && (
                  <Typography.Body bold>
                    {address.postalCode} {address.city}
                  </Typography.Body>
                )}

                {address.state && (
                  <>
                    <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                    <Typography.Body>{address.state?.label}</Typography.Body>
                  </>
                )}
                {(address.latitude || address.longitude) && (
                  <>
                    <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                    <Typography.BodySmall>
                      {address.latitude}, {address.longitude}
                    </Typography.BodySmall>
                  </>
                )}
              </View>
              {!canEdit ||
              (producer && !producer?.address) ||
              (carrier && !carrier?.address) ? null : (
                <StyledButtonWrapper>
                  <Button
                    small
                    label="Modifier"
                    onPress={() => {
                      setIsAddMode(!isAddMode)
                    }}
                  />
                </StyledButtonWrapper>
              )}
            </StyledContentCard>
          </Card>
        </DropShadow>
      </StyledCardAddress>
    )
  }

  return (
    <>
      <SectionTitle
        title={sectionTitle}
        noMarginLeft={sectionTitleNoMarginLeft}
        buttonRight={renderButtonRight()}
      />
      {displayAddForm()}
      {displayAddress()}
    </>
  )
}

export default AddressForm
