import React, { useState } from 'react'
import { CarrierTourProducerTopBoxProps } from './CarrierTourProducerTopBox.model'
import { Button, Card, CardParam, Col, Row, Spacer, Typography } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { ButtonsWrapper, MinOrderWrapper, StyledTopBox } from './CarrierTourProducerTopBox.styles'
import { View } from 'react-native'
import TourProducerService from '../../../../../services/producer/tour'
import { queryClient } from '../../../../../utilities/queryClient'
import { useForm } from 'react-hook-form'
import RhfTextInput from '../../../../../components/RhfTextInput'
import RhfDatePicker from '../../../../../components/RhfDatePicker'
import CarrierProducerTourService from '../../../../../services/carrier/producerTour'

function CarrierTourProducerTopBox({ producerTour, tour }: CarrierTourProducerTopBoxProps) {
  const [displayMinOrderInput, setDisplayMinOrderInput] = useState<Boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)

  if (!tour) return

  const {
    control,
    handleSubmit,
    reset,
    getFieldState,
    resetField,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      minOrderValueHT: (producerTour?.minOrderValueHT || 0).toString(),
      orderEndDate: producerTour?.orderEndDate || null,
    },
  })

  const onUpdateProducerTour = async ({
    minOrderValueHT,
    orderEndDate,
  }: {
    minOrderValueHT: string
    orderEndDate: Date | null | undefined
  }) => {
    if (!producerTour) return
    setIsLoading(true)
    setErrorMessage('')

    await TourProducerService.updateProducerTour(producerTour?.id, {
      minOrderValueHT: parseFloat(minOrderValueHT),
      orderEndDate,
    })
      .then(() => {
        queryClient.invalidateQueries(['sf_tour_producer'])
        resetField('orderEndDate', { defaultValue: orderEndDate })
        CarrierProducerTourService.useGetFromTour({ tourId: tour?.id }).invalidate()
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        setErrorMessage('Une erreur est survenue lors de la mise à jour')
      })
    setDisplayMinOrderInput(false)
  }

  const validateDateBeforeTourClose = (value: Date) => {
    if (!value || value === producerTour?.orderEndDate) {
      return true
    }
    return (
      value < new Date(tour.close) ||
      'Cette date doit être avant la date de fermeture des commandes.'
    )
  }

  return (
    <>
      <StyledTopBox>
        <CardParam
          bold
          label={'Producteur'}
          value={producerTour?.producer?.label || ''}
          flex={8}
          isStart
        />
      </StyledTopBox>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
      <Card>
        <MinOrderWrapper>
          <View>
            <Typography.BodySmall colorName="color-primary" bold>
              Min. de commande
            </Typography.BodySmall>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
            {displayMinOrderInput ? (
              <RhfTextInput
                control={control}
                name="minOrderValueHT"
                textInputLabel="Min. commande"
              />
            ) : (
              <Typography.Body colorName="color-primary">
                {FormatUtils.formatPrice(producerTour?.minOrderValueHT)}
              </Typography.Body>
            )}
          </View>

          {displayMinOrderInput ? (
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Button
                lowPadding
                label="Annuler"
                onPress={() => {
                  setDisplayMinOrderInput(false)
                  reset({ minOrderValueHT: (producerTour?.minOrderValueHT || 0).toString() })
                }}
                colorName={'color-grey'}
                small
              />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              <Button
                lowPadding
                label="Enregistrer"
                onPress={handleSubmit(onUpdateProducerTour)}
                colorName={'color-secondary'}
                loading={isLoading}
                small
              />
            </View>
          ) : (
            <Button
              lowPadding
              label="Modifier"
              onPress={() => setDisplayMinOrderInput(!displayMinOrderInput)}
              colorName={'color-grey'}
              small
            />
          )}
        </MinOrderWrapper>
      </Card>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
      <Card>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography.BodySmall colorName="color-primary" bold>
            Date de fin des cmds.
          </Typography.BodySmall>
        </View>

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
        <Row>
          <Col xs={8}>
            <RhfDatePicker
              control={control}
              rules={{
                validate: validateDateBeforeTourClose,
              }}
              name="orderEndDate"
              dateInputLabel="Date"
              mode="date"
              outputFormat="date"
              withDay
            />
          </Col>
          <Col xs={4}>
            <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />

            <RhfDatePicker
              control={control}
              rules={{
                validate: validateDateBeforeTourClose,
              }}
              name="orderEndDate"
              dateInputLabel="Heure"
              mode="time"
              outputFormat="date"
              noDisplayError
            />
          </Col>
        </Row>

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
        <ButtonsWrapper>
          {producerTour?.orderEndDate ? (
            <Button
              lowPadding
              label="Retirer"
              onPress={handleSubmit((data) => {
                onUpdateProducerTour({
                  minOrderValueHT: data.minOrderValueHT,
                  orderEndDate: null,
                })
              })}
              colorName={'color-danger'}
              small
            />
          ) : null}
          {getFieldState('orderEndDate').isDirty ? (
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Button
                lowPadding
                label="Annuler"
                onPress={() => {
                  reset({ orderEndDate: producerTour?.orderEndDate })
                }}
                colorName={'color-grey'}
                small
              />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              <Button
                lowPadding
                label="Enregistrer"
                onPress={handleSubmit(onUpdateProducerTour as any)}
                colorName={'color-secondary'}
                small
                loading={isLoading}
              />
            </View>
          ) : null}
        </ButtonsWrapper>
        {errorMessage ? (
          <>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
          </>
        ) : null}
      </Card>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
      <StyledTopBox>
        <CardParam bold label={'Commandes'} value={tour?.nbOrders || '0'} flex={5} isStart />
        <CardParam
          label={'Ventes'}
          value={FormatUtils.formatPrice(tour?.totalIncome)}
          flex={4}
          isEnd
          bold
        />
        <CardParam bold label={'Colis'} value={tour?.nbCrates || '0'} flex={3} isEnd />
      </StyledTopBox>
    </>
  )
}

export default CarrierTourProducerTopBox
