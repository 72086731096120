import styled from 'styled-components/native'

const StyledButtonWrapper = styled.View`
  width: auto;
  align-items: center;
  justify-content: center;
`

const ButtonsWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacingUnit * 2}px;
`

export { StyledButtonWrapper, ButtonsWrapper }
