export enum ProducerBillingTypeEnum {
  DIRECT = 1,
  CARRIER = 2,
}
export enum ProducerStatusEnum {
  INACTIVE = -1,
  PENDING = 0,
  VERIFICATION = 2,
  ONBOARDING = 5,
  ACTIVE = 10,
}

export enum ProducerVatTypeEnum {
  WITHOUT_VAT = 0,
  WITH_VAT = 1,
}

export const PRODUCER_BILLING_TYPES = [
  {
    label: 'Direct client',
    value: 1,
  },
  {
    label: 'Via le super producteur',
    value: 2,
  },
]

export const PRODUCER_VAT_TYPES = [
  {
    label: 'Sans TVA',
    value: 0,
  },
  {
    label: 'Avec TVA',
    value: 1,
  },
]
