import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, Spacer, Typography } from '../../../components'
import { DeliveryNote, ProducersFake, StepsFake, Tour, ToursFake } from '../../../domain'
import i18n from '../../../i18n'
import TourProducerCard from '../../../modules/Tour/TourProducerCard'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import CarrierTourProducersTopBox from './components/CarrierTourProducersTopBox'
import { StyledButtonWrapper, ToursContentWrapper } from './CarrierTourProducers.styles'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import DeliveryNoteCarrierService from '../../../services/carrier/deliveryNote'
import Loader from '../../../components/Loader'
import { StackNavigationProp } from '@react-navigation/stack'

const CarrierTourProducersScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourProducers'>>()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const { id } = route?.params ?? { id: '' }

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = TourCarrierService.getOne.query(id)

  const {
    data: deliveryNotes,
    refetch: refetchDeliveryNotes,
    isInitialLoading: deliveryNotesLoading,
  } = useQuery<DeliveryNote[], Error>(
    ['sf_tour_delivery_notes', id],
    () => DeliveryNoteCarrierService.getAllTour(id),
    {
      keepPreviousData: true,
    },
  )

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  const onClickDeliveryNote = async () => {
    if (!tour?.id) return

    /*
    navigation.navigate('CarrierTourDeliveryNotes', {
      id: tour.id,
      type: null
    })
    */
    // await DeliveryNoteCarrierService.generate(tour.id)
  }

  if (tourLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour) return null

  const producersTour = tour.producerTour || []

  const OnClickAddProducer = () => {
    navigation.navigate('CarrierTourAddProducer', { tourId: tour.id })
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`${FormatUtils.capitalize(i18n.t('producers'))} #${getTourIdFormatted()}`}
            buttonRight={
              <>
                <Typography.BodySmall>
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                  {'\n'}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </>
            }
          />
          <CarrierTourProducersTopBox
            tour={tour}
            onClickDeliveryNote={onClickDeliveryNote}
            deliveryNotes={deliveryNotes}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          <Typography.SectionTitle>
            Producteurs dans cette tournée ({producersTour.length | 0})
          </Typography.SectionTitle>
          {producersTour.map((producerTour) => (
            <TourProducerCard key={producerTour.id} producerTour={producerTour} tour={tour} />
          ))}
          <StyledButtonWrapper>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Button label="Ajouter un producteur" onPress={() => OnClickAddProducer()} />
          </StyledButtonWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourProducersScreen
