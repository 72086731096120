import { useQuery } from '@tanstack/react-query'
import { InvoiceStatusEnum } from '../../../enums/invoice'
import { InvoiceStatementTypeEnum } from '../../../enums/invoice-statement'
import { Pagination } from '../../domain'
import { InvoiceStatement } from '../../domain/InvoiceStatement'
import ApiService from '../api.service'
import { queryClient } from '../../utilities/queryClient'

interface FindParams {
  status?: InvoiceStatusEnum
  type?: InvoiceStatementTypeEnum
  start?: Date
  end?: Date
  limit?: number
  pageNumber?: number
}

const find = async ({
  status,
  type,
  start,
  end,
  limit,
  pageNumber,
}: FindParams): Promise<{ data: InvoiceStatement[]; pagination?: Pagination }> => {
  const response = await ApiService.get(`/invoice-statement`, {
    status,
    type,
    after: start?.toISOString(),
    before: end?.toISOString(),
    limit,
    page: pageNumber,
  }).then((result) => result)
  return response
}

const getPdf = async (invoiceStatementId: string): Promise<any> => {
  const response = await ApiService.get(`/invoice-statement/${invoiceStatementId}/pdf`)
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

// ============================ custom hook ============================

export type UseFindInvoiceStatementsArgs = FindParams

function useFindInvoiceStatements(args: UseFindInvoiceStatementsArgs) {
  function getQueryKey() {
    return [
      'invoice-statement',
      args.status ? `status_${args.status}` : '',
      args.type ? `type_${args.type}` : '',
      args.start ? `start_${args.start.toISOString()}` : '',
      args.end ? `end_${args.end.toISOString()}` : '',
      args.limit ? `limit_${args.limit}` : '',
      args.pageNumber ? `pageNumber_${args.pageNumber}` : '',
    ].filter(Boolean)
  }

  function query() {
    return useQuery<{ data: InvoiceStatement[]; pagination?: Pagination }, Error>({
      queryKey: getQueryKey(),
      queryFn: () => find(args),
    })
  }

  function refetch() {
    queryClient.refetchQueries(getQueryKey())
  }

  return {
    query,
    refetch,
  }
}

const InvoiceStatementService = {
  useFindInvoiceStatements,
  getPdf,
}

export default InvoiceStatementService
