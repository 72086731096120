import { BatchEnums } from '../../../../enums'
import { Batch, BatchComputed, Pagination, ProductBatchs } from '../../../domain'
import ApiService from '../../api.service'

const create = async (newRule: any): Promise<Batch> => {
  const response = await ApiService.post(`/rule`, newRule).catch((error) => {
    throw error
  })
  return response
}

const update = async (ruleId: string, rule: any): Promise<Batch> => {
  const response = await ApiService.patch(`/rule/${ruleId}`, rule).catch((error) => {
    throw error
  })
  return response
}

const remove = async (ruleId: string): Promise<Batch> => {
  const response = await ApiService.remove(`/rule/${ruleId}`).catch((error) => {
    throw error
  })
  return response
}

const RuleProducerService = {
  create,
  update,
  remove,
}

export default RuleProducerService
