import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import Env from '../utilities/env'

const axiosInstance: AxiosInstance = axios.create({
  baseURL: Env.API_URL,
  withCredentials: true,
})

axiosInstance.interceptors.request.use((config: any) => {
  return config
})

// axiosInstance.interceptors.response.use(
//   (response) => onResponseSuccess(response),
//   (error) => error,
// )

const AxiosService = {
  axiosInstance,
}

export default AxiosService
