import { View } from "react-native"
import { Icon, Icons, Spacer, TouchableCard, Typography } from "../../../../../components"
import { StyledCardWrapper, StyledCircleWrapper, StyledViewTypeBox, StyledViewTypeBoxLeft, StyledViewTypeBoxRight } from "./ProfileCard.styles"
import { useTheme } from "styled-components/native"
import { ProfileCardProps } from "./ProfileCard.model"
import FormatUtils from "../../../../../utilities/utils/format"


const ProfileCard = ({ profile, onPress, }: ProfileCardProps) => {
    const theme = useTheme()
    const groups = profile.groups || []
    const isMultiGroup = groups.length > 1
    const group = groups.length > 0 ? groups[0] : undefined
    return (
        <>
            <StyledCardWrapper
                onPress={() => onPress(profile.viewType, profile.profileId)}
                activeOpacity={0.8}
                key={profile?.profileId}
            >
                <TouchableCard>
                    <StyledViewTypeBox>
                        <StyledViewTypeBoxLeft>
                            <Typography.BodySmall colorName="text-dark-3">
                                {isMultiGroup ? 'Multi-groupes' : FormatUtils.getViewTypeLabel(profile.role, group)}
                            </Typography.BodySmall>
                            <Typography.CardTitle>{profile.label}</Typography.CardTitle>
                        </StyledViewTypeBoxLeft>
                        <StyledViewTypeBoxRight>
                            {!isMultiGroup && (
                                <StyledCircleWrapper>
                                    <Icon
                                        type={Icons.Ionicons}
                                        name="chevron-forward-outline"
                                        size={theme.spacingUnit * 2}
                                        color={theme.colors['text-light-1']}
                                    />
                                </StyledCircleWrapper>
                            )}
                        </StyledViewTypeBoxRight>
                    </StyledViewTypeBox>
                </TouchableCard>
            </StyledCardWrapper>
            {isMultiGroup && (
                <View style={{ width: '95%', marginLeft: '3%' }}>
                    {groups.map((group) => (
                        <StyledCardWrapper
                            onPress={() => onPress(profile.viewType, profile.profileId, group.id)}
                            activeOpacity={0.8}
                            key={`${profile?.profileId}-${group.id}`}
                        >
                            <TouchableCard>
                                <StyledViewTypeBox>
                                    <StyledViewTypeBoxLeft>
                                        <Spacer size={1} />
                                        <Typography.Body bold>{group.label}</Typography.Body>
                                    </StyledViewTypeBoxLeft>
                                    <StyledViewTypeBoxRight>
                                        <StyledCircleWrapper>
                                            <Icon
                                                type={Icons.Ionicons}
                                                name="chevron-forward-outline"
                                                size={theme.spacingUnit * 2}
                                                color={theme.colors['text-light-1']}
                                            />
                                        </StyledCircleWrapper>
                                    </StyledViewTypeBoxRight>
                                </StyledViewTypeBox>
                            </TouchableCard>
                        </StyledCardWrapper>
                    ))}
                </View>
            )}
        </>
    )
}

export default ProfileCard