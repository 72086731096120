import { AddProducerRegister, Producer } from '../../domain'
import { User } from '../../domain/User'
import StorageUtil from '../../utilities/storage/storage'
import ApiService from '../api.service'
import UserSeeds from './user.seed'

const me = async (): Promise<User> => {
  const authenticationDataFromSecureStore = await StorageUtil.getItem('authenticationData')

  if (authenticationDataFromSecureStore) {
    const authenticationDataFormatted: any = JSON.parse(authenticationDataFromSecureStore)

    return ApiService.get('/auth/refresh')
      .then((result: any) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  throw new Error()
}

const updatePassword = async (
  idUser: string,
  oldPassword: string,
  newPassword: string,
): Promise<User> => {
  const response = ApiService.post(`/user/${idUser}/update-password`, {
    oldPassword,
    newPassword,
  }).catch((error) => {
    throw error
  })
  return response
}

const registerProducer = async (
  userProducer: any,
): Promise<{ user: User; producer: Producer; token: string }> => {
  const response = ApiService.post(`/producer`, userProducer).catch((error) => {
    throw error
  })
  return response
}

const update = async (user: User): Promise<User> => {
  const response = UserSeeds.update(user)
  if (!response) {
    throw new Error()
  }
  return response
}

const saveToken = async (userToken: string, token: string): Promise<User> => {
  const response = UserSeeds.saveToken(userToken)
  if (!response) {
    throw new Error()
  }
  return response
}

const removeToken = async (userToken: string, token: string): Promise<User> => {
  const response = UserSeeds.removeToken(userToken)
  if (!response) {
    throw new Error()
  }
  return response
}

const checkEmail = async (mail: string): Promise<boolean> => {
  const response = ApiService.get(`/user/check-email`, {
    mail,
  }).catch((error) => {
    throw error
  })

  return response
}

const UserService = {
  me,
  update,
  updatePassword,
  registerProducer,
  saveToken,
  removeToken,
  checkEmail,
}

export default UserService
