import styled from 'styled-components/native'

const StyledStatWrapper = styled.View`
  border-radius: ${({ theme }) => theme.borderRadius * 2}px;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: ${({ theme }) => theme.spacingUnit * 1.8}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 1.3}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1.3}px;
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  height: 100%;
`

const StyledCardsWrapper = styled.View`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  flex-direction: row;
`

const StatCardWrapper = styled.View`
  flex: 1;
  align-items: center;
  margin: ${({ theme }) => theme.spacingUnit * 0.6}px;
`

const StyledDateSwitcherWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const ArrowBoxWrapperLeft = styled.View`
  width: auto;
  text-align: right;
`

const ArrowBoxWrapperRight = styled.View`
  width: auto;
  align-items: left;
`

const MiddleBox = styled.View`
  margin: 0 10px;
`

export {
  StyledStatWrapper,
  StyledCardsWrapper,
  StatCardWrapper,
  StyledDateSwitcherWrapper,
  ArrowBoxWrapperRight,
  ArrowBoxWrapperLeft,
  MiddleBox,
}
