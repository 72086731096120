import { TourOpenSlideProps } from './TourOpenSlide.model'
import {
  Button,
  Col,
  DatePicker,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../../../components'
import { View } from 'react-native'
import { ButtonWrapper } from './TourOpenSlide.styles'
import { AddTour } from '../../../../../domain'
import { useState } from 'react'
import GeneralUtils from '../../../../../utilities/utils/general'
import InfoBox from '../../../../../components/InfoBox'

const TourOpenSlide = ({ onClick, tour }: TourOpenSlideProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [dateIsInvalid, setDateIsInvalid] = useState<boolean>(false)
  const [timeIsInvalid, setTimeIsInvalid] = useState<boolean>(false)

  let defaultDateHour = new Date()
  defaultDateHour.setUTCDate((tour?.close?.getUTCDate() || 0) - 3)
  defaultDateHour.setUTCHours(11)
  defaultDateHour.setUTCMinutes(0)

  const [newTour, setNewTour] = useState<Partial<AddTour>>({
    open: defaultDateHour,
  })

  const info1 = {
    title: `Pourquoi définir une date d'ouverture des commandes ?`,
    content: `La date d'ouverture des commandes permet à tous les producteurs de préparer leur proposition de produit disponible au jour du dépot de la marchandise. Ceci afin que tous les clients puisse alors passer commande à tout les producteurs en une seule fois.`,
  }

  const info2 = {
    title: `Quand définir la date d'ouverture ?`,
    content:
      'Il est conseillé de laisser au moins 3 jours ouvrés aux clients pour passer et finaliser leur commande. Plus les produits proposés sont frais, et les récoltes dûr à anticiper, plus il faut rapprocher la date de début de commande de celle de livraison.',
  }

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newTour)) return null

    const tourTemp = Object.assign({}, newTour)

    tourTemp[param as keyof typeof newTour] = value
    setNewTour(tourTemp)
  }

  const onChangeDateOpen = (value: any) => {
    setValue(value, 'open')
    setDateIsInvalid(false)
    setTimeIsInvalid(false)

    if (GeneralUtils.isDateBeforeCurrent(value)) {
      setDateIsInvalid(true)
      setErrorMessage("La date d'ouverture des commandes doit être supérieure à la date actuelle.")
      return
    }

    if (GeneralUtils.isDateBefore(new Date(tour.close || ''), new Date(value || ''), -1)) {
      setDateIsInvalid(true)
      setTimeIsInvalid(true)
      setErrorMessage("La date d'ouverture des commandes doit être avant celle de fermeture")
      return false
    }
  }

  return (
    <>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <View style={{ width: '100%', alignItems: 'center' }}>
        <Typography.PageTitle>{'Ouverture des commandes'}</Typography.PageTitle>
      </View>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <InfoBox title={info1.title} content={info1.content} />
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <InfoBox title={info2.title} content={info2.content} />
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <SectionTitle title="Date et heure de ouverture des commandes" />
      <Row>
        <Col xs={8}>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <DatePicker
            id="datedebutcommandes"
            label="Début des commandes"
            onChange={onChangeDateOpen}
            value={newTour.open}
            mode={'date'}
            outputFormat="date"
            withDay
          />
        </Col>
        <Col xs={4}>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <DatePicker
            id="heuredebutcommandes"
            label="Heure"
            onChange={onChangeDateOpen}
            value={newTour.open}
            mode={'time'}
            outputFormat="date"
          />
        </Col>
      </Row>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      {errorMessage ? (
        <Typography.Body colorName="color-danger" style={{ textAlign: 'center' }}>
          {errorMessage}
        </Typography.Body>
      ) : (
        <>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {!dateIsInvalid && !timeIsInvalid && (
            <ButtonWrapper>
              <Button
                onPress={() => onClick({ ...newTour })}
                label="Valider"
                colorName="color-secondary"
              />
            </ButtonWrapper>
          )}
        </>
      )}
    </>
  )
}

export default TourOpenSlide
