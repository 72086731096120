import React from 'react'
import { FlatList, ScrollView, View } from 'react-native'
import styled from 'styled-components/native'

/*
const ScrollableFormWrapper = styled((props) => (
  <ScrollView bounces={false} keyboardShouldPersistTaps="handled" {...props} />
))`
  width: 100%;
  // min-height: 100px;
  // min-height: 'fit-content';
  // margin-bottom: ${({ theme }) => theme.spacingUnit}px;
`
*/

const ScrollableFormWrapper = styled(({ paddingBottom, ...props }) => <View {...props} />)`
  width: 100%;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  // min-height: 100px;
  // min-height: 'fit-content';
  // margin-bottom: ${({ theme }) => theme.spacingUnit}px;
`

const FlatListForm = styled(
  React.forwardRef<FlatList, any>((props, ref) => (
    <FlatList ref={ref} bounces={false} keyboardShouldPersistTaps="handled" {...props} />
  )),
)`
  width: 100%;
`
// margin-bottom: ${({ theme }) => theme.spacingUnit}px;
export default ScrollableFormWrapper
export { FlatListForm }
