import { TourDepositSlideProps } from './TourDepositSlide.model'
import {
  Button,
  Col,
  DatePicker,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../../../components'
import { View } from 'react-native'
import { ButtonWrapper } from './TourDepositSlide.styles'
import { AddTour } from '../../../../../domain'
import { useState } from 'react'
import GeneralUtils from '../../../../../utilities/utils/general'
import InfoBox from '../../../../../components/InfoBox'

const TourDepositSlide = ({ onClick, tour }: TourDepositSlideProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [showDateDepositErrorMessage, setShowDateDepositErrorMessage] = useState(false)
  const [dateIsInvalid, setDateIsInvalid] = useState<boolean>(false)
  const [timeIsInvalid, setTimeIsInvalid] = useState<boolean>(false)

  let defaultDateHour = new Date()

  if (tour?.start && tour?.start?.getUTCHours() < 13) {
    defaultDateHour = new Date(tour.start || '')
    defaultDateHour.setUTCDate(defaultDateHour.getUTCDate() - 1)
    defaultDateHour.setUTCHours(17)
    defaultDateHour.setUTCMinutes(0)
  } else {
    defaultDateHour = new Date(tour.start || '')
    defaultDateHour.setUTCHours(8)
    defaultDateHour.setUTCMinutes(0)
  }

  const [newTour, setNewTour] = useState<Partial<AddTour>>({
    deposit: defaultDateHour,
  })

  const info1 = {
    title: 'Pourquoi définir une date de dépôt de la marchandise ?',
    content:
      'Afin de plannifier la préparation de votre tournée de livraison, vous définissez une limite avant laquelle les producteurs qui participent à votre tournée doivent déposer la marchandise chez vous.',
  }

  const info2 = {
    title: 'Quand définir la date de dépôt ?',
    content:
      'Afin de réduire le temps de stockage, cette limite est en générale la veille de la tournée si celle est le matin, le jour même si vous partez en livraison l’après midi.',
  }

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newTour)) return null

    const tourTemp = Object.assign({}, newTour)

    tourTemp[param as keyof typeof newTour] = value
    setNewTour(tourTemp)
  }

  const onChangeDateDeposit = (value: any) => {
    setValue(value, 'deposit')
    setDateIsInvalid(false)
    setShowDateDepositErrorMessage(false)
    setTimeIsInvalid(false)
    if (GeneralUtils.isDateBeforeCurrent(value)) {
      setDateIsInvalid(true)
      setErrorMessage('La date de dépôt de la marchandise doit être supérieure à la date actuelle.')
      return
    }
    if (GeneralUtils.isDateBefore(new Date(tour.start || ''), new Date(value || ''), -1)) {
      setDateIsInvalid(true)
      setTimeIsInvalid(true)
      setErrorMessage('La date de dépôt de la marchandise doit être avant celle de livraison')
      return false
    }

    const diffHours = tour.start ? GeneralUtils.diffHours(new Date(value), tour.start) : 0
    setShowDateDepositErrorMessage(diffHours > 24)
  }

  return (
    <>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <View style={{ width: '100%', alignItems: 'center' }}>
        <Typography.PageTitle>{'Dépot de la marchandise'}</Typography.PageTitle>
      </View>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <InfoBox title={info1.title} content={info1.content} />
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <InfoBox title={info2.title} content={info2.content} />
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <SectionTitle title="Limite de dépot de la marchandise" />
      <Row>
        <Col xs={8} sm={8}>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <DatePicker
            id="datedepot"
            label="Date"
            onChange={onChangeDateDeposit}
            value={newTour.deposit}
            mode={'date'}
            outputFormat="date"
            withDay
          />
        </Col>
        <Col xs={4} sm={4}>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <DatePicker
            id="datedepot"
            label="Heure"
            onChange={onChangeDateDeposit}
            value={newTour.deposit}
            mode={'time'}
            outputFormat="date"
          />
        </Col>
      </Row>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <Row>
        {showDateDepositErrorMessage && (
          <Col xs={12}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="color-danger" style={{ textAlign: 'center' }}>
              Attention, la date de dépôt de la marchandise est supérieure à 24h par rapport à la
              date de début de tournée.
            </Typography.BodySmall>
          </Col>
        )}
      </Row>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      {errorMessage ? (
        <Typography.Body colorName="color-danger" style={{ textAlign: 'center' }}>
          {errorMessage}
        </Typography.Body>
      ) : (
        <>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {!dateIsInvalid && !timeIsInvalid && (
            <ButtonWrapper>
              <Button
                onPress={() => onClick({ ...newTour })}
                label="Valider"
                colorName="color-secondary"
              />
            </ButtonWrapper>
          )}
        </>
      )}
    </>
  )
}

export default TourDepositSlide
