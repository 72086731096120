import ApiService from '../../api.service'

const getRoles = async () => {
  const response = await ApiService.get(`/role`).catch((error) => {
    throw error
  })
  return response
}

const RoleService = {
  getRoles,
}

export default RoleService
