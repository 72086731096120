import styled from 'styled-components/native'

const StyledViewTypeBox = styled.View`
  // min-height: ${({ theme }) => theme.spacingUnit * 5}px;
  margin: ${({ theme }) => theme.spacingUnit * 0.9}px;

  margin-left: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 2}px;

  flex-direction: row;
`

const StyledViewTypeBoxLeft = styled.View`
  flex: 1;
`

const StyledViewTypeBoxRight = styled.View`
  justify-content: center;
  aspect-ratio: 1;
`

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const TextInputWrapper = styled.View`
  width: 65%;
`

const StyledCircleWrapper = styled.View`
  width: ${({ theme }) => theme.spacingUnit * 3}px;
  height: ${({ theme }) => theme.spacingUnit * 3}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.1}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.2}px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors['color-primary']};
  align-items: center;
  justify-content: center;
`

export {
  StyledViewTypeBox,
  StyledViewTypeBoxLeft,
  StyledViewTypeBoxRight,
  StyledCardWrapper,
  TextInputWrapper,
  StyledCircleWrapper,
}
