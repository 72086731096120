import { RouteProp, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { Spacer } from '../../../components'
import { Tour } from '../../../domain'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import TourProducerService from '../../../services/producer/tour'
import { useQuery } from '@tanstack/react-query'
import { ScrollView } from 'react-native-gesture-handler'
import Loader from '../../../components/Loader'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import TourTopBox from '../../../components/TourTopBox'
import SliderMenu from '../../../components/SliderMenu'
import ProducerTourOrganization from './slides/ProducerTourOrganization'
import ProducerTourOrders from './slides/ProducerTourOrders'
import ProducerTourDocuments from './slides/ProducerTourDocuments'
import { UserEnums } from '../../../../enums'

const producerTourModes = [
  {
    label: 'Organisation',
    value: 'ORGANIZATION',
  },
  {
    label: 'Commandes',
    value: 'ORDERS',
  },
  {
    label: 'Documents',
    value: 'DOCUMENTS',
  },
]

const ProducerTourV2Screen = () => {
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerTour'>>()
  const [selectedMode, setSelectedMode] = useState<string | number>(producerTourModes[0].value)

  const { getAccessInfos } = useAuthenticationContext()

  const { id } = route?.params ?? { id: '' }

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = useQuery<Tour, Error>(
    ['p_tour_orders'],
    () => TourProducerService.getOneTourProducerOrders(id, getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (selectedMode) {
      setSelectedMode(selectedMode)
    }
  }, [selectedMode])

  const onCardClick = (mode: string) => {
    setSelectedMode(mode)
  }

  if (tourLoading) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  if (!tour) return null

  const renderSelectedComponent = () => {
    switch (selectedMode) {
      case 'ORGANIZATION':
        return (
          <ProducerTourOrganization
            tour={tour}
            tourProducerBatchs={tour.batchs}
            onCardClick={onCardClick}
          />
        )
      case 'ORDERS':
        return <ProducerTourOrders tour={tour} />
      case 'DOCUMENTS':
        return <ProducerTourDocuments tour={tour} />
      default:
        return null
    }
  }

  const onSelectElementSliderMenu = (value: string | number) => {
    setSelectedMode(value)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <TourTopBox tour={tour} userRole={UserEnums.RoleEnums.PRODUCER} />
      <ContentWrapper marginTopLarge>
        <Spacer fixedSize={65} axis={Spacer.AxisEnum.VERTICAL} />
        <SliderMenu
          elements={producerTourModes}
          onSelectElement={onSelectElementSliderMenu}
          defaultSelectedElement={selectedMode}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {renderSelectedComponent()}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerTourV2Screen
