import { UserEnums } from './user'

export enum RuleTypeEnum {
  CLIENT_ACCESS = 'client_access',
  PRODUCER_MIN_CAPACITY = 'producer_min_capacity',
  BATCH_ACCESS = 'batch_access',
  BATCH_CARRIER_FEES = 'carrier_fees',
  CLIENT_PRICE = 'client_price',
}

export type RuleTypeForm = {
  label: string
  value: RuleTypeEnum
  userEnums: UserEnums.RoleEnums[]
  type: 'number' | 'string'
}

export const RULE_TYPES: RuleTypeForm[] = [
  {
    label: 'Prix client',
    value: RuleTypeEnum.CLIENT_PRICE,
    userEnums: [UserEnums.RoleEnums.PRODUCER, UserEnums.RoleEnums.CARRIER],
    type: 'number',
  },
  {
    label: 'Commission logistique dédié',
    value: RuleTypeEnum.BATCH_CARRIER_FEES,
    userEnums: [UserEnums.RoleEnums.CARRIER],
    type: 'number',
  },
]
