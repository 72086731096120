import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const InfoBoxWrapper = styled.TouchableOpacity`
  width: 100%;
  max-width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors['text-dark-4']};
  background-color: ${({ theme }) => theme.colors['background-3']};
  margin-bottom: 10px;
`
const InfoBoxTopWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const InfoBoxTopContentWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`
const InfoBoxContentRight = styled.View`
  padding-left: ${({ theme }) => theme.spacingUnit * 0.5}px;
  max-width: 90%;
`

const IconBox = styled.View`
  background-color: ${({ theme }) => theme.colors && theme.colors['color-secondary']};
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin-left: -6px;
  width: 10px;
  height: 10px;
  padding: ${({ theme }) => theme.spacingUnit * 1.2}px;
`

export { InfoBoxWrapper, InfoBoxTopWrapper, InfoBoxContentRight, IconBox, InfoBoxTopContentWrapper }
