import styled from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 1.3}px;
  flex-direction: row;
`

const StyledLeftBox = styled.View`
  // flex: 2;
  margin-right: ${({ theme }) => theme.spacingUnit * 1.3}px;
`

const StyledImage = styled.Image`
  width: ${({ theme }) => theme.spacingUnit * 7}px;
  height: ${({ theme }) => theme.spacingUnit * 7}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
`

const StyledRightBox = styled.View`
  /* width: 95%; */
  flex: 1;
`

const StyledValuesWrapper = styled.View`
  flex-direction: row;
`

const StyledValueBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 0.3}px;
  flex: 1;
`

const StyledTypeInfoBox = styled.View`
  min-height: ${({ theme }) => theme.spacingUnit * 1}px;
`

export {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledImage,
  StyledRightBox,
  StyledValuesWrapper,
  StyledValueBox,
  StyledTypeInfoBox,
}
