import { ProducerVatTypeEnum } from '../../../enums/producer'
import { Account } from '../../domain/Account'

const isProfileSubjectToVAT = (account: Account): boolean =>
  account.legalInfo.vatType === ProducerVatTypeEnum.WITH_VAT

const AccountUtil = {
  isProfileSubjectToVAT,
}

export default AccountUtil
