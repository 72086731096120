import { InterfaceColors } from '../../utilities/styling/theme/theme'

export enum TextTag {
  TITLE = 'Title',
  PAGE_TITLE = 'PageTitle',
  CARD_TITLE = 'CardTitle',
  SECTION_TITLE = 'SectionTitle',

  BODY = 'Body',
  BODY_SMALL = 'BodySmall',
  BODY_EXTRA_SMALL = 'BodyExtraSmall',

  INPUT_LABEL = 'InputLabel',
  MENU = 'Menu',
  DANGER = 'Danger',
  FORM_ITEM = 'FormItem',
  BUTTON_LABEL = 'ButtonLabel',
}

export interface TextProps {
  children?: any
  tag?: TextTag
  bold?: boolean
  semiBold?: boolean
  medium?: boolean
  colorName?: keyof InterfaceColors
  gutterBottom?: boolean
  numberOfLines?: number
  align?: string
  style?: object
  onPress?: () => void
  ellipsis?: boolean
}
