import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Col, PageTitle, Row, SectionTitle, Spacer, Typography } from '../../../components'
import Loader from '../../../components/Loader'
import { Batch, CartBatch, Producer, Tour } from '../../../domain'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { View } from 'react-native'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../services/carrier/tour'
import BatchCartCardArrival from '../../../modules/Batch/BatchCartCardArrival'
import { UserEnums } from '../../../../enums'
import CarrierArrivalPreparationFilters from './components/CarrierArrivalPreparationFilters'
import {
  CartBatchCarrierStatusEnum,
  CartBatchProducerStatusEnum,
} from '../../../../enums/cartBatch'
import BatchCarrierService from '../../../services/carrier/batch'
import { queryClient } from '../../../utilities/queryClient'

export type PropositionsFiltersObject = {
  mode: 'DAY' | 'WEEK' | 'MONTH'
  start: Date
  end: Date
  selectedTour?: Tour
}

const CarrierArrivalPreparationScreen = () => {
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierReceptionPreparation'>>()
  const { tourId, mode, start } = (route?.params as any) ?? {
    tourId: '',
    mode: undefined,
    start: undefined,
  }
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  let tempStartDate = new Date()
  let tempEndDate = new Date()
  let tempMode: 'DAY' | 'WEEK' | 'MONTH' = 'DAY'

  if (mode && start) {
    tempStartDate = new Date(start)
    tempEndDate = new Date(start)

    if (mode === 'WEEK') {
      tempMode = 'WEEK'
      tempStartDate.setDate(FormatUtils.startOfWeek(tempStartDate))
      tempEndDate.setDate(tempStartDate.getDate() + 6)
    }

    if (mode === 'MONTH') {
      tempMode = 'MONTH'
      tempStartDate = new Date(tempStartDate.getFullYear(), tempStartDate.getMonth(), 1, 0, 0, 0)
      tempEndDate = new Date(tempStartDate.getFullYear(), tempStartDate.getMonth() + 1, 0, 0, 0, 0)
    }

    // clean hours on start and end date
    tempStartDate.setHours(0)
    tempStartDate.setMinutes(0)
    tempStartDate.setSeconds(0)

    tempEndDate.setHours(23)
    tempEndDate.setMinutes(59)
    tempEndDate.setSeconds(59)
  }

  const [propositionsFilters, setPropositionsFilter] = useState<PropositionsFiltersObject>({
    mode: tempMode,
    start: tempStartDate,
    end: tempEndDate,
    selectedTour: undefined,
  })

  useEffect(() => {}, [mode, start])

  const dateHourMinusDays = new Date()
  dateHourMinusDays.setUTCDate(-5)

  const {
    data: arrivalBatchs,
    refetch: refetchProductBatchs,
    isRefetching: productBatchsRefetching,
    isLoading: productBatchsLoading,
  } = useQuery<{ batchs: Batch[]; tours: Tour[] }, Error>(
    ['sf_arrival_batchs'],
    () =>
      TourCarrierService.getAllDayArrivalBatchs(
        getAccessInfos().carrierId,
        propositionsFilters.start,
        propositionsFilters.end,
      ),
    {
      keepPreviousData: true,
    },
  )
  const productsBatchs = arrivalBatchs?.batchs
  const tours = arrivalBatchs?.tours

  const producers: Producer[] = []
  productsBatchs?.forEach((pbs) => {
    if (!producers?.find((producer) => producer.id === pbs.product?.producer?.id)) {
      producers.push(pbs.product?.producer!)
    }
  })

  useEffect(() => {
    refetchProductBatchs()
  }, [propositionsFilters])

  const onChangeFilters = async (newFilters: PropositionsFiltersObject) => {
    await setPropositionsFilter(newFilters)
    refetchProductBatchs()
  }

  const renderBatchs = () => {
    if (productBatchsLoading) {
      return <Loader isLoading />
    }

    return (
      productsBatchs &&
      productsBatchs.length > 0 &&
      producers?.map((producer) => {
        const batchs = productsBatchs.filter((productBatch) => {
          return productBatch.product?.producer?.id === producer?.id
        })
        if (batchs.length > 0) {
          return (
            <View key={producer.id} style={{ width: '100%' }}>
              <SectionTitle title={producer?.label} />
              {batchs.map((productBatch) => (
                <BatchCartCardArrival
                  key={productBatch.product?.id}
                  batch={productBatch}
                  userRole={UserEnums.RoleEnums.CARRIER}
                />
              ))}
            </View>
          )
        }
      })
    )
  }
  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Réception de la marchandise" />

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <CarrierArrivalPreparationFilters
          propositionsFilters={propositionsFilters}
          onChangeFilters={onChangeFilters}
          nextTours={tours}
        />
        {(!productsBatchs || productsBatchs.length < 1) && (
          <View style={{ alignItems: 'center', width: '100%' }}>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Typography.Body colorName="text-dark-3" align="center">
              Aucun lot disponible à la journée ou à la semaine 2
            </Typography.Body>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
          </View>
        )}
        {renderBatchs()}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierArrivalPreparationScreen
