export enum ClientTypeEnum {
  RETAILER = 1,
  WHOLESALER = 2,
}

export enum ClientStatusEnum {
  ONBOARDING = 5,
  ACTIVE = 10,
}

export const CLIENT_TYPES = [
  {
    label: 'Client (Magasin, Restaurant...)',
    value: 1,
  },
  {
    label: 'Grossiste',
    value: 2,
  },
]
