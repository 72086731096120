import { useEffect, useState } from 'react'
import { Button, SectionTitle, Spacer, TextInput } from '../../../../../components'
import InfoBox from '../../../../../components/InfoBox'
import { MarketValueFormProps } from './MarketValueForm.model'
import { ButtonsWrapper, StyledButtonWrapper } from './MarketValueForm.styles'
import FormatUtils from '../../../../../utilities/utils/format'

const MarketValueForm = ({ onClickNext, onGoBack, currentMarketValue }: MarketValueFormProps) => {
  const [marketValue, setMarketValue] = useState<string>(
    currentMarketValue ? currentMarketValue.toString() : '',
  )

  const infoBoxContent1 = {
    title: 'Pourquoi définir une commission logistique ?',
    content: `Afin de couvrir vos frais de livraison, vous définissez une commission logistique sur la valeur des marchandes des produits que vous prenez aux producteurs afin de vous aider à couvrir les frais.`,
  }

  useEffect(() => {
    setMarketValue(currentMarketValue ? currentMarketValue.toString() : '')
  }, [currentMarketValue])

  return (
    <>
      <InfoBox title={infoBoxContent1.title} content={infoBoxContent1.content} />
      <SectionTitle title="Estimation de la valeur marchande moyenne dans le camion" />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      <TextInput
        label="Valeur marchande moyenne (en €)"
        field="text"
        value={marketValue}
        onChangeText={(text) => setMarketValue(text)}
      />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      <ButtonsWrapper>
        <StyledButtonWrapper>
          <Button label={'Retour'} onPress={() => onGoBack('LANDING')} colorName="color-grey" />
        </StyledButtonWrapper>
        <StyledButtonWrapper>
          <Button
            label="Suivant"
            onPress={() => onClickNext(FormatUtils.stringToFloat(marketValue))}
            disabled={!marketValue}
          />
        </StyledButtonWrapper>
      </ButtonsWrapper>
    </>
  )
}

export default MarketValueForm
