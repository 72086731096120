import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { PageTitle, Spacer, Typography } from '../../../components'
import { Batch, Producer, ProducersFake, StepsFake, Tour, ToursFake } from '../../../domain'
import i18n from '../../../i18n'
import TourProducerCard from '../../../modules/Tour/TourProducerCard'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper } from './ProducerTourBatchs.styles'
import { useQuery } from '@tanstack/react-query'
import DeliveryNoteProducerService from '../../../services/carrier/deliveryNote'
import BatchCard from '../../../modules/Batch/BatchCard'
import Loader from '../../../components/Loader'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import TourProducerService from '../../../services/producer/tour'

const ProducerTourBatchsScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerTourBatchs'>>()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const { id } = route?.params ?? { id: '' }

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = useQuery<Tour, Error>(['sf_tour', id], () => TourProducerService.getOneTour(id), {
    keepPreviousData: true,
  })

  const {
    data: tourBatchs,
    refetch: refetchTourBatchs,
    isInitialLoading: tourBatchsLoading,
  } = useQuery<Tour, Error>(
    ['sf_tour_batchs', id],
    () => TourProducerService.getOneTourAllBatchs(id),
    {
      keepPreviousData: true,
    },
  )

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  if (tourLoading || tourBatchsLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour) return null

  const producersTour = tour.producerTour || []

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Mercuriale Tournée #${getTourIdFormatted()}`}
            buttonRight={
              <>
                <Typography.BodySmall>
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                  {'\n'}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </>
            }
          />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          {producersTour.map((producerTour) => {
            const producer = producerTour.producer
            const producerBatchs =
              tourBatchs?.batchs?.filter((b) => b.product?.producer?.id === producer.id) || []
            return (
              <>
                <Typography.SectionTitle>
                  {producer.label} ({producerBatchs.length | 0} lots)
                </Typography.SectionTitle>
                {producerBatchs.length < 1 && (
                  <Typography.BodySmall
                    colorName="text-dark-3"
                    style={{ marginLeft: theme.spacingUnit * 2 }}
                  >
                    Aucune proposition de lots dans cette tournée
                  </Typography.BodySmall>
                )}

                {producerBatchs.map((batch) => (
                  <BatchCard key={batch.id} batch={batch} onClick={() => null} isProducer />
                ))}
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              </>
            )
          })}
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerTourBatchsScreen
