import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import Typography from '../../../components/Typography'
import { AddRuleFormProps } from './AddRuleForm.model'
import { StyledButtonWrapper } from './AddRuleForm.styles'
import { Button, Card, Col, Dropdown, PageTitle, Row, Spacer, TextInput } from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { View } from 'react-native'
import { AddRule } from '../../../domain/Rule'
import RuleProducerService from '../../../services/producer/rule'
import { ScrollableFormWrapper } from '../../../utilities/styling/wrappers'
import { RULE_TYPES, RuleTypeEnum, RuleTypeForm } from '../../../../enums/rule-type'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'

function AddRuleForm({ batch, product, tour, clients, onAddRule }: AddRuleFormProps) {
  const theme = useTheme()
  const [selectedType, setSelectedType] = useState<RuleTypeForm>()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [selectedClient, setSelectedClient] = useState<any>()
  const { getAccessInfos } = useAuthenticationContext()

  const [newRule, setNewRule] = useState<AddRule>({
    type: undefined,
    valueId: undefined,
    value: undefined,
    tourId: tour?.id || undefined,
    producerId: getAccessInfos().producerId || undefined,
    carrierId: getAccessInfos().carrierId || undefined,
    clientId: undefined,
    batchId: batch?.id || undefined,
    productId: product?.id || undefined,
  })

  const setValue = async (value: any, param: string) => {
    if (!param && !(param in newRule)) return null
    const ruleTemp = Object.assign({}, newRule)

    ruleTemp[param as keyof typeof newRule] = value

    await setNewRule(ruleTemp)
  }

  const onChangeType = async (selectedTypeTemp: any) => {
    await setSelectedType(selectedTypeTemp)
    await setValue(selectedTypeTemp.value, 'type')
  }

  const onChangeClient = async (value: any) => {
    await setSelectedClient(value)
    await setValue(value.id, 'clientId')
  }

  const onClickAddRule = async () => {
    setErrorMessage('')
    if (!batch && !product && !tour) {
      setErrorMessage('Une erreur est survenue, aucune cible pour la règle')
      return
    }

    let tempValue: any = newRule.value
    if (selectedType?.type === 'number') {
      tempValue = parseFloat(tempValue || '')
    }
    setIsLoading(true)
    const rule = {
      type: newRule.type,
      value: tempValue,
      tourId: newRule.tourId,
      producerId: newRule.producerId,
      carrierId: newRule.carrierId,
      clientId: newRule.clientId,
      batchId: newRule.batchId,
      productId: newRule.productId,
    }

    const createdRule = await RuleProducerService.create(rule)
    if (createdRule && createdRule.id) {
      onAddRule()
    } else {
      setErrorMessage('Un problème est survenu lors de la création du lot')
    }
    setIsLoading(false)
  }

  const getDisplayedData = () => {
    if (selectedType?.value === RuleTypeEnum.CLIENT_PRICE) {
      return {
        indicationMessage:
          'Vous pouvez définir un prix client dédié. Il sera appliqué quelque soit vos conditions de prix minimum et les frais de logistique seront déduits.',
        valueLabel: `Prix client €/${FormatUtils.formatUnity(batch?.product?.mesureType)}`,
      }
    }

    return {
      indicationMessage: '',
      valueLabel: '',
    }
  }

  return (
    <>
      <ScrollableFormWrapper>
        <PageTitle title="Ajouter une règle" />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <Card>
          <Typography.Body>Lot</Typography.Body>
          <Typography.CardTitle>{batch?.product?.label}</Typography.CardTitle>
        </Card>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <Dropdown
          label={'Choisir une règle'}
          onChange={onChangeType}
          multiple={false}
          optionsDefault={RULE_TYPES.map((element) => ({
            ...element,
            label: element.label,
            value: element.value,
          }))}
          defaultValue={selectedType?.value}
          zIndex={1000}
          itemKey="type"
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {selectedType?.value === RuleTypeEnum.CLIENT_PRICE && (
          <Dropdown
            label={'Choisir un client'}
            onChange={onChangeClient}
            multiple={false}
            optionsDefault={(clients || []).map((client) => ({
              ...client,
              label: client?.label,
              value: client?.id,
            }))}
            zIndex={1000}
            itemKey="client"
          />
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <Typography.Body colorName="text-dark-3">
          {getDisplayedData()?.indicationMessage}
        </Typography.Body>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <TextInput
          value={newRule.value?.toString()}
          label={getDisplayedData()?.valueLabel}
          field="text"
          onChangeText={(text) => setValue(text, 'value')}
          editable
          hidden={!selectedType}
        />

        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      </ScrollableFormWrapper>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      <StyledButtonWrapper>
        {errorMessage ? (
          <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
        ) : null}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {newRule.value && (
          <Button label={'Ajouter'} onPress={() => onClickAddRule()} loading={isLoading} />
        )}
      </StyledButtonWrapper>
    </>
  )
}

export default AddRuleForm
