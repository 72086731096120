import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'

import TextInput from '../../../components/inputs/TextInput'
import { Producer, UpdateProducerProfile } from '../../../domain'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import {
  BoxWrapperProfile,
  DynamicImageWrapper,
  LeftBox,
  RightBox,
  StyledButtonWrapper,
  StyledCardWrapper,
  StyledImageBox,
  StyledImageRight,
  TopBoxWrapper,
} from './ProducerPublicProfile.styles'
import DirectusUtil from '../../../utilities/utils/directus'
import ProducerProducerService from '../../../services/producer/producer'
import Loader from '../../../components/Loader'
import ProducerGallery from './components/ProducerGallery'
import {
  Button,
  Card,
  DocumentPickerInput,
  DynamicImage,
  PageTitle,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const ProducerPublicProfileScreen = () => {
  const theme = useTheme()
  const [updatedProducer, setUpdatedProducer] = useState<UpdateProducerProfile>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!updatedProducer || !param || !(param in updatedProducer)) return null
    const producerTemp = Object.assign({}, updatedProducer)

    if (param === 'newGalleryImagesUris') {
      ;(producerTemp[param as keyof typeof updatedProducer] as Array<string>).push(value)
    } else {
      producerTemp[param as keyof typeof updatedProducer] = value
    }
    setUpdatedProducer(producerTemp)
  }
  const onChangeProducerImage = (itemsDroppedWithId: any[]) => {
    if (itemsDroppedWithId && itemsDroppedWithId[0]) {
      setIsDisabled(true)

      setValue(itemsDroppedWithId[0].uri, 'newImageUri')
      setTimeout(() => {
        setIsDisabled(false)
      }, 3000)
    }
  }
  const onChangeProducerGalleryImage = (itemsDroppedWithId: any[]) => {
    setErrorMessage('')
    if (itemsDroppedWithId && itemsDroppedWithId[0]) {
      const oldImagesLength = (updatedProducer?.galleryImagesIds || []).length
      const newImagesLength = (updatedProducer?.newGalleryImagesUris || []).length
      const nbGalleryImages = oldImagesLength + newImagesLength
      if (nbGalleryImages > 3) {
        setErrorMessage('Vous ne pouvez pas ajouter plus de 4 images dans votre galerie')
        return
      }
      setIsDisabled(true)
      setValue(itemsDroppedWithId[0].uri, 'newGalleryImagesUris')
      setTimeout(() => {
        setIsDisabled(false)
      }, 4000)
    }
  }
  const {
    data: producerData,
    refetch: refetchProducer,
    isInitialLoading: producerLoading,
  } = useQuery<{ producer: Producer; stepsData: TutorialStepData[] }, Error>(
    ['p_producer', getAccessInfos().producerId],
    () => ProducerProducerService.getOne(getAccessInfos().producerId),
    {
      keepPreviousData: true,
      enabled: getAccessInfos().producerId !== undefined,
    },
  )

  const MAX_DESCRIPTION_LENGTH = 1000

  useEffect(() => {
    if (!producerData?.producer) return

    setUpdatedProducer({
      ...producerData?.producer,
      newImageUri: undefined,
      newGalleryImagesUris: [],
    })
  }, [producerData?.producer, setUpdatedProducer])

  const onDeleteGalleryImage = (imageUri: string) => {
    if (!updatedProducer) return
    const producerTemp = { ...updatedProducer }
    const trimmedImageUri = imageUri.split('/').pop() || ''
    const index = producerTemp.galleryImagesIds?.indexOf(trimmedImageUri)
    if (index !== -1) {
      producerTemp.galleryImagesIds = producerTemp.galleryImagesIds?.filter(
        (imageId) => imageId !== trimmedImageUri,
      )
    }
    const indexNew = producerTemp.newGalleryImagesUris?.indexOf(imageUri)
    if (indexNew !== -1) {
      producerTemp.newGalleryImagesUris = producerTemp.newGalleryImagesUris?.filter(
        (imageId) => imageId !== imageUri,
      )
    }
    setUpdatedProducer(producerTemp)
  }
  const onUpdateProducerProfile = async () => {
    if (!updatedProducer) return
    setErrorMessage('')
    setIsLoading(true)
    const producer = {
      label: updatedProducer.label,
      owners: updatedProducer.owners,
      description: updatedProducer.description,
      location: updatedProducer.location,
      newImageUri: updatedProducer.newImageUri,
      galleryImagesIds: [...updatedProducer.galleryImagesIds!],
      newGalleryImagesUris: [...updatedProducer.newGalleryImagesUris!],
    }
    await ProducerProducerService.update(getAccessInfos().producerId, producer)
      .then((updatedProducer) => {
        queryClient.invalidateQueries(['p_producer', getAccessInfos().producerId])
        navigation.navigate('ProfileProducerNav', {
          screen: 'ProducerProfile',
          params: {},
        })
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la mise à jour de votre profil')
      })
    setIsLoading(false)
  }
  if (producerLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!updatedProducer) return null

  return (
    <ScreenSafeAreaWrapper>
      <ContentWrapper noPaddingLeft>
        <PageTitle title={'Mon Profil producteur'} />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <ScrollableFormWrapper>
          <StyledCardWrapper>
            <Card>
              <BoxWrapperProfile>
                <TopBoxWrapper>
                  <LeftBox>
                    <DynamicImageWrapper>
                      <DynamicImage
                        togglesModal
                        imageUri={DirectusUtil.getProducerImageUrlFromId(
                          updatedProducer as any as Producer,
                        )}
                      />
                    </DynamicImageWrapper>
                  </LeftBox>
                  <RightBox>
                    <Typography.Body bold colorName="color-grey">
                      Votre photo
                    </Typography.Body>
                    <DocumentPickerInput
                      customAcceptedFileExtensions={['png', 'jpg', 'jpeg']}
                      onAddFiles={onChangeProducerImage}
                    />
                  </RightBox>
                </TopBoxWrapper>
              </BoxWrapperProfile>
            </Card>
          </StyledCardWrapper>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <TextInput
            value={updatedProducer.label}
            label="Nom de l’exploitation"
            field="text"
            onChangeText={(text) => setValue(text, 'label')}
          />
          <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.BodyExtraSmall colorName="color-grey">
            Ex: La ferme aux petits oignons
          </Typography.BodyExtraSmall>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <TextInput
            value={updatedProducer.owners}
            label="Votre (vos) prénom(s) et nom(s)"
            field="text"
            onChangeText={(text) => setValue(text, 'owners')}
          />
          <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.BodyExtraSmall colorName="color-grey">
            Ex: Julie et Pierre
          </Typography.BodyExtraSmall>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <TextInput
            value={updatedProducer.location}
            label="Département (code)"
            field="text"
            onChangeText={(text) => setValue(text, 'location')}
          />
          <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.BodyExtraSmall colorName="color-grey">
            Ex: Lot et Garonne (47)
          </Typography.BodyExtraSmall>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <TextInput
            value={updatedProducer.description}
            label="Présentation"
            field="text"
            onChangeText={(text) => setValue(text, 'description')}
            multiline
          />

          <SectionTitle title="Autres images (votre ferme, vos produits ...)"></SectionTitle>
          <ProducerGallery
            mode="edit"
            galleryImagesIds={DirectusUtil.getProducerGalleryImageUrlFromId(
              updatedProducer as any as Producer,
            )}
            onChangeProducerGalleryImage={onChangeProducerGalleryImage}
            onDeleteGalleryImage={onDeleteGalleryImage}
          />
          <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
          {errorMessage ? (
            <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
          ) : null}
          {(updatedProducer?.description || '').length > MAX_DESCRIPTION_LENGTH ? (
            <Typography.Body colorName="color-danger">
              Votre description est trop longue ({updatedProducer.description?.length}/
              {MAX_DESCRIPTION_LENGTH})
            </Typography.Body>
          ) : null}
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

          <StyledButtonWrapper>
            <Button
              label="Modifier"
              onPress={() => onUpdateProducerProfile()}
              loading={isLoading}
              disabled={
                isDisabled || (updatedProducer?.description || '').length > MAX_DESCRIPTION_LENGTH
              }
            />
          </StyledButtonWrapper>
          <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        </ScrollableFormWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerPublicProfileScreen
