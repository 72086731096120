import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledImageLeft = styled.View`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.colors && theme.colors['color-primary']};
  padding: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledButtonWrapper = styled.View`
  align-items: center;
  justify-content: center;
`
const StyledDownloadButtonWrapper = styled.View`
  justify-content: center;
  min-width: ${({ theme }) => theme.spacingUnit * 24}px;
`

const StyledButtonsWrapper = styled.View`
  gap: ${({ theme }) => theme.spacingUnit * 2}px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`

const LeftBox = styled.View`
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
`
const RightBox = styled.View`
  flex: 3;
  justify-content: center;
  padding-right: ${({ theme }) => theme.spacingUnit * 2}px;
`

const TopBoxWrapper = styled.View`
  flex: 4;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: ${({ theme }) => theme.spacingUnit * 2}px;
`
const BottomBoxWrapper = styled.View`
  flex: 0.7;
`

const BoxWrapperProfile = styled.View`
  width: 100%;
  min-height: 140px;
`

const StyledCardWrapper = styled.View`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
`

export {
  StyledButtonWrapper,
  LeftBox,
  RightBox,
  TopBoxWrapper,
  BottomBoxWrapper,
  StyledButtonsWrapper,
  StyledCardWrapper,
  BoxWrapperProfile,
  StyledImageLeft,
  StyledDownloadButtonWrapper,
}
