import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import ClientProducersScreen from '../../../screens/Client/ClientProducers'
import ClientProducerScreen from '../../../screens/Client/ClientProducer'
import Header from '../../components/Header'
import HeaderHome from '../../components/HeaderHome'

const Stack = createStackNavigator()

const ProducerClientNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="ClientProducers"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ClientProducers"
        component={ClientProducersScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Producteurs',
        }}
      />
      <Stack.Screen
        name="ClientProducer"
        component={ClientProducerScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ClientProducers" />,
          title: 'Producteur',
        }}
      />
    </Stack.Navigator>
  )
}

export default ProducerClientNavigation
