import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'styled-components/native'
import { PageTitle, SectionTitle } from '../../../components'
import CardList from '../../../components/CardList'
import Loader from '../../../components/Loader'
import { Order, OrdersFake, Pagination } from '../../../domain'
import OrderCard from '../../../modules/Order/OrderCard'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import OrderClientService from '../../../services/client/order'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import CardListPaginated from '../../../components/CardListPaginated'

const ClientOrdersScreen = () => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()
  const [pageSizePreviousOrders, setPageSizePreviousOrders] = useState(4)
  const [pageNumberPreviousOrders, setPageNumberPreviousOrders] = useState(1)

  const {
    data: ordersNext,
    refetch: refetchOrdersNext,
    isInitialLoading: ordersNextLoading,
  } = useQuery<{ data: Order[]; pagination: Pagination }, Error>(
    ['c_orders_next'],
    () => OrderClientService.getAllOrders(getAccessInfos().clientId, true, false, 50),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: ordersPrevious,
    refetch: refetchOrdersPrevious,
    isInitialLoading: ordersPreviousLoading,
  } = useQuery<{ data: Order[]; pagination: Pagination }, Error>(
    ['c_orders_previous'],
    () =>
      OrderClientService.getAllOrders(
        getAccessInfos().clientId,
        false,
        true,
        pageSizePreviousOrders,
        pageNumberPreviousOrders,
      ),
    {
      keepPreviousData: true,
    },
  )

  const OnClickOrder = (order: Order) => {
    navigation.navigate('ClientOrder', { id: order.id })
  }

  const onChangePagePreviousOrders = async (pageNumber: number) => {
    await setPageNumberPreviousOrders(pageNumber)
    refetchOrdersPrevious()
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Vos commandes" />

        <SectionTitle title={`Commandes à venir`} />
        <CardList
          data={ordersNext?.data}
          element={function (order: any) {
            return <OrderCard key={order.id} order={order} onClick={OnClickOrder} />
          }}
          isLoading={ordersNextLoading}
          emptyMessage="Aucune commande à venir"
        />

        <SectionTitle title={`Commandes passées (${ordersPrevious?.pagination?.totalCount})`} />
        <CardListPaginated
          data={ordersPrevious?.data}
          pagination={ordersPrevious?.pagination}
          element={function (order: any) {
            return <OrderCard key={order.id} order={order} onClick={OnClickOrder} />
          }}
          isLoading={ordersPreviousLoading}
          emptyMessage="Aucune commande passée"
          onChangePage={onChangePagePreviousOrders}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientOrdersScreen
