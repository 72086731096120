import styled from 'styled-components/native'

const StyledValuesButtonWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
`

const StyledValueBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 0.3}px;
  justify-content: center;
`

const QuantityInputWrapper = styled.View`
  width: 96%;
  max-width: 85px;
  margin-left: 2%;
  margin-right: 2%;
`

export { StyledValuesButtonWrapper, StyledValueBox, QuantityInputWrapper }
