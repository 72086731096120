import styled, { css } from 'styled-components/native'

const StyledLogoBox = styled.View`
  width: 96%;
  height: ${({ theme }) => theme.spacingUnit * 8}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 10}px;
  align-items: center;
`

const StyledImage = styled.Image`
  width: 75%;
  height: 100%;
`
const PendingAccountWrapper = styled.View`
  width: 100%;
  justify-content: top;
  align-items: center;
`
const PendingAccountContent = styled.View`
  width: 100%;
  padding: ${({ theme }) => theme.spacingUnit * 2}px;
`

const ButtonWrapper = styled.View`
  align-items: center;
  justify-content: center;
`

export { StyledImage, StyledLogoBox, PendingAccountWrapper, PendingAccountContent, ButtonWrapper }
