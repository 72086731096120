import { ProductEnums } from '../../../enums'
import { Container } from '../Container'
import { Producer } from '../Producer'
import { ProductType } from '../ProductType'

export type Product = {
  id: string
  label: string
  typeInfo?: string
  internalReference?: string
  description?: string
  imageUrl?: string
  imageId?: string
  newImageUri?: string
  packing: number
  vatRate: ProductEnums.VAT_ENUM
  minUnitPrice: number
  mesureType: ProductEnums.MESURE_TYPE
  containerId?: string
  container?: Container
  productTypeId?: string
  productType?: ProductType
  sumQuantity30?: number
  sumCA30?: number
  producer?: Producer
  unitWeight?: number
  productLabels?: ProductLabel[]
  formattedProductLabels?: string
  displayPricePerKilo?: boolean
  chargedActualWeight?: boolean
  createdDate?: string
  barcode?: string
  customBarcode?: string
}

export type AddProduct = {
  label?: string
  typeInfo?: string
  description?: string
  internalReference?: string
  newImageUri?: string
  vatRate?: ProductEnums.VAT_ENUM
  packing?: string
  minUnitPrice?: string
  mesureType?: ProductEnums.MESURE_TYPE
  containerId?: string
  productTypeId?: string
  unitWeight?: string
  productLabels?: ProductLabel[]
  barcode?: string
  customBarcode?: string
}

export type UpdateProduct = {
  id?: string
  label?: string
  typeInfo?: string
  description?: string
  internalReference?: string
  vatRate?: ProductEnums.VAT_ENUM
  packing?: string
  minUnitPrice?: string
  mesureType?: ProductEnums.MESURE_TYPE
  containerId?: string
  productTypeId?: string
  newImageUri?: string
  imageId?: string
  unitWeight?: string
  productLabels?: ProductLabel[]
  createdDate?: string
  barcode?: string
  customBarcode?: string
}

export type ProductLabel = {
  id: string
  label: string
}

export const ProductsFake: Product[] = []
