import React from 'react'
import { DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer'
import { useTheme } from 'styled-components/native'
import {
  StyledDrawerItems,
  StyledDrawerMenu,
  StyledDrawerMenuSpace,
  StyledImage,
  StyledLogoBox,
} from './CustomDrawerContent.styles'

const CustomDrawerContent = (props: any) => {
  const theme = useTheme()

  return (
    <StyledDrawerMenuSpace>
      <DrawerContentScrollView {...props}>
        <StyledDrawerMenu>
          <StyledLogoBox>
            <StyledImage
              source={require('../../../../assets/logos/logo_harvy.png')}
              resizeMode="contain"
            />
          </StyledLogoBox>
          <StyledDrawerItems>
            <DrawerItemList {...props} labelStyle={{ paddingLeft: '-30px' }} />
          </StyledDrawerItems>
        </StyledDrawerMenu>
      </DrawerContentScrollView>
    </StyledDrawerMenuSpace>
  )
}

export default CustomDrawerContent
