import { Dimensions } from 'react-native'
import styled, { css } from 'styled-components/native'
import { DividerProps } from './Divider.model'

const StyledDividerView = styled.View<DividerProps>`
  width: 100%;
  margin-top: ${({ theme, spacing }) => theme.spacingUnit * (spacing ?? 1)}px;
  margin-bottom: ${({ theme, spacing }) => theme.spacingUnit * (spacing ?? 1)}px;
  height: 0.7px;
  background-color: ${({ theme, color }) => color ?? theme.colors['color-grey']};

  ${({ fullScreenWidth }) =>
    fullScreenWidth &&
    css`
      width: ${Dimensions.get('screen').width}px;
    `}
`

export default StyledDividerView
