import { LogisticInfosFormProps } from './LogisticInfosForm.model'
import {
  Button,
  Col,
  Row,
  SectionTitle,
  Spacer,
  TextInput,
  Typography,
} from '../../../../../components'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { useEffect, useState } from 'react'
import { StyledButtonWrapper } from './LogisticInfosForm.styles'
import { View } from 'react-native'

const LogisticInfosForm = ({
  deliveryExpensesParameters,
  capacity,
  onClickNext,
  onGoBack,
}: LogisticInfosFormProps) => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTour'>>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [updatedDeliveryExpensesParameters, setUpdatedDeliveryExpensesParameters] = useState<any>({
    orderPreparationTime: undefined,
    deliveryTime: undefined,
    hourlyCost: undefined,
  })
  const { id } = route?.params ?? { id: '' }

  if (!id) return null

  useEffect(() => {
    setUpdatedDeliveryExpensesParameters({
      orderPreparationTime: deliveryExpensesParameters.orderPreparationTime
        ? deliveryExpensesParameters.orderPreparationTime?.toString()
        : '',
      deliveryTime: deliveryExpensesParameters.deliveryTime
        ? deliveryExpensesParameters.deliveryTime?.toString()
        : '',
      hourlyCost: deliveryExpensesParameters.hourlyCost
        ? deliveryExpensesParameters.hourlyCost?.toString()
        : '',
    })
  }, [deliveryExpensesParameters])

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in updatedDeliveryExpensesParameters)) return null

    const tempObj = Object.assign({}, updatedDeliveryExpensesParameters)

    tempObj[param as keyof typeof updatedDeliveryExpensesParameters] = value
    setUpdatedDeliveryExpensesParameters(tempObj)
  }

  const handleClickNext = () => {
    onClickNext(
      parseFloat(updatedDeliveryExpensesParameters?.orderPreparationTime),
      parseFloat(updatedDeliveryExpensesParameters?.deliveryTime),
      parseFloat(updatedDeliveryExpensesParameters?.hourlyCost),
    )
  }

  return (
    <>
      <SectionTitle title={`Préparation des commandes`} />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      <Row>
        <Col xs={6} sm={6}>
          <TextInput
            value={updatedDeliveryExpensesParameters?.orderPreparationTime}
            label="Temps (en h)"
            field="text"
            onChangeText={(text) => setValue(text, 'orderPreparationTime')}
          />
        </Col>
        <Col xs={6} sm={6}>
          <Typography.BodySmall colorName="text-dark-3">
            Le temps que vous passez à recevoir et contrôler la marchandise, ainsi que pour la
            préparation des commandes clients.
          </Typography.BodySmall>
        </Col>
      </Row>

      {updatedDeliveryExpensesParameters?.orderPreparationTime !== '' && (
        <>
          <SectionTitle title={`Tournée de livraison`} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Row>
            <Col xs={6} sm={6}>
              <TextInput
                value={updatedDeliveryExpensesParameters?.deliveryTime}
                label="Temps (en h)"
                field="text"
                onChangeText={(text) => setValue(text, 'deliveryTime')}
                hidden={updatedDeliveryExpensesParameters?.orderPreparationTime === ''}
              />
            </Col>
            <Col xs={6} sm={6}>
              <Typography.BodySmall colorName="text-dark-3">
                Le temps passé en tournée de livraison en comptant le trajet retour
              </Typography.BodySmall>
            </Col>
          </Row>
        </>
      )}
      {updatedDeliveryExpensesParameters?.deliveryTime !== '' && (
        <>
          <SectionTitle title={`Taux horaire chargé`} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Row>
            <Col xs={6} sm={6}>
              <TextInput
                value={updatedDeliveryExpensesParameters?.hourlyCost}
                label="€ brut/h"
                field="text"
                onChangeText={(text) => setValue(text, 'hourlyCost')}
              />
            </Col>
            <Col xs={6} sm={6}>
              <Typography.BodySmall colorName="text-dark-3">
                La rémunération d'un temps plein qui effectue toutes ces tâches.
              </Typography.BodySmall>
            </Col>
          </Row>
        </>
      )}

      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
      {updatedDeliveryExpensesParameters?.hourlyCost !== '' && (
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <StyledButtonWrapper>
            <Button label={'Retour'} onPress={() => onGoBack('TRUCK')} colorName="color-grey" />
          </StyledButtonWrapper>
          <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
          <StyledButtonWrapper>
            <Button label={'Suivant'} onPress={() => handleClickNext()} />
          </StyledButtonWrapper>
        </View>
      )}
    </>
  )
}

export default LogisticInfosForm
