import { Address, Container, Product } from '../../../domain'
import ApiService from '../../api.service'

const create = async (newAddress: any): Promise<Address> => {
  const response = await ApiService.post(`/address/`, newAddress).catch((error) => {
    throw error
  })
  return response
}

const update = async (addressId: string, address: any): Promise<Address> => {
  const response = await ApiService.patch(`/address/${addressId}`, address).catch((error) => {
    throw error
  })
  return response
}

const AddressService = {
  create,
  update,
}

export default AddressService
