import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Col, PageTitle, Row, Spacer, Typography } from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { AddUserAdmin } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierAddAdminUser.styles'
import UserCarrierService from '../../../services/carrier/user'
import { useQuery } from '@tanstack/react-query'
import { Role } from '../../../domain/Role'
import RoleService from '../../../services/user/role'
import RoleForm from '../../../modules/Common/RoleForm'

const CarrierAddAdminUserScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierAddAdminUser'>>()
  const [isLoading, setIsLoading] = useState(false)

  const [userRoles, setUserRoles] = useState<Role[]>([])

  const { getAccessInfos } = useAuthenticationContext()

  const { groupId, carrierId, firstName, lastName, email, password } = route?.params ?? {
    groupId: '',
    carrierId: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  }

  const {
    data: roles,
    refetch,
    isInitialLoading: rolesLoading,
  } = useQuery<Role[], Error>(['sf_roles'], () => RoleService.getRoles(), {
    keepPreviousData: true,
  })

  const theme = useTheme()
  const [newUser, setNewUser] = useState<AddUserAdmin>({
    email: email || '',
    password: password || '',
    lastName: lastName || '',
    firstName: firstName || '',
    carrierId: carrierId || '',
  })

  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newUser)) return null

    const userTemp = Object.assign({}, newUser)

    userTemp[param as keyof typeof newUser] = value
    setNewUser(userTemp)
  }

  const onAddUser = async () => {
    setErrorMessage('')
    if (!groupId) {
      setErrorMessage('Erreur de chargement du groupe')
      return
    }

    setIsLoading(true)
    const user = {
      email: FormatUtils.cleanEmail(newUser.email),
      password: newUser.password,
      lastName: newUser.lastName,
      firstName: newUser.firstName,
      groupId: groupId || undefined,
      carrierId: newUser.carrierId || getAccessInfos().carrierId || undefined,
      roles: userRoles,
    }

    const createdUser = await UserCarrierService.createAdmin(user, groupId)
      .then((res) => {
        queryClient.invalidateQueries(['sf_group_admins'])
        queryClient.invalidateQueries(['sf_group'])
        // queryClient.invalidateQueries(['sf_group_admins'])

        navigation.navigate('CarrierGroupAdmins', {})

        setIsLoading(false)
        return true
      })
      .catch((error) => {
        setErrorMessage("Un problème est survenu lors de la création de l'utilisateur")
        setIsLoading(false)
        return false
      })
  }

  const onChangeRolesForm = (userRoles: Role[]) => {
    setUserRoles(userRoles)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Ajouter un utilisateur" />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Typography.BodySmall colorName="text-dark-3">
              Rattaché au compte super producteur
            </Typography.BodySmall>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={newUser.lastName}
                  label="Nom"
                  field="text"
                  onChangeText={(text) => setValue(text, 'lastName')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={newUser.firstName}
                  label="Prénom"
                  field="text"
                  onChangeText={(text) => setValue(text, 'firstName')}
                  hidden={!newUser.lastName}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={newUser.email}
                  label="Email"
                  field="text"
                  onChangeText={(text) => setValue(text, 'email')}
                  hidden={!newUser.firstName}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={newUser.password}
                  label="Mot de passe"
                  field="password"
                  secureTextEntry
                  onChangeText={(text) => setValue(text, 'password')}
                  hidden={!newUser.email}
                />
              </Col>
            </Row>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
            {newUser.password && (
              <RoleForm
                roles={roles}
                userRoles={userRoles}
                onChangeRole={onChangeRolesForm}
                dropDownDirection="TOP"
              />
            )}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            {userRoles.length > 0 && (
              <Button
                label={FormatUtils.capitalize(i18n.t('add'))}
                onPress={() => onAddUser()}
                loading={isLoading}
              />
            )}
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierAddAdminUserScreen
