import styled from 'styled-components/native'

const StyledTopBox = styled.View`
  flex-direction: row;
`
const StyledRightBox = styled.View`
  align-items: flex-end;
`

const StyledCardWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 0.5}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 0.5}px;
  width: 100%;
`
const ButtonsWrapper = styled.View`
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1}px;
`

export { StyledTopBox, StyledRightBox, StyledCardWrapper, ButtonsWrapper }
