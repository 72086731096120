import { Container, Product, ProductType } from '../../../domain'
import { Category } from '../../../domain/Category'
import ApiService from '../../api.service'

const getAllProductTypes = async (): Promise<ProductType[]> => {
  const response = await ApiService.get(`/product/product-type`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getAllCategories = async (): Promise<Category[]> => {
  const response = await ApiService.get(`/product/category`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const createProductType = async (newProductType: any): Promise<ProductType> => {
  const response = await ApiService.post(`/product/product-type`, newProductType).catch((error) => {
    throw error
  })
  return response
}

const ProductCarrierService = {
  getAllProductTypes,
  getAllCategories,
  createProductType,
}

export default ProductCarrierService
