export namespace CountryEnums {
  export enum Code {
    AFGHANISTAN = 'AF',
    ALAND_ISLANDS = 'AX',
    ALBANIA = 'AL',
    ALGERIA = 'DZ',
    AMERICAN_SAMOA = 'AS',
    ANDORRA = 'AD',
    ANGOLA = 'AO',
    ANGUILLA = 'AI',
    ANTARCTICA = 'AQ',
    ANTIGUA_AND_BARBUDA = 'AG',
    ARGENTINA = 'AR',
    ARMENIA = 'AM',
    ARUBA = 'AW',
    AUSTRALIA = 'AU',
    AUSTRIA = 'AT',
    AZERBAIJAN = 'AZ',
    BAHAMAS = 'BS',
    BAHRAIN = 'BH',
    BANGLADESH = 'BD',
    BARBADOS = 'BB',
    BELARUS = 'BY',
    BELGIUM = 'BE',
    BELIZE = 'BZ',
    BENIN = 'BJ',
    BERMUDA = 'BM',
    BHUTAN = 'BT',
    BOLIVIA = 'BO',
    BONAIRE = 'BQ',
    BOSNIA_AND_HERZEGOVINA = 'BA',
    BOTSWANA = 'BW',
    BOUVET_ISLAND = 'BV',
    BRAZIL = 'BR',
    BRITISH_INDIAN_OCEAN_TERRITORY = 'IO',
    BRUNEI_DARUSSALAM = 'BN',
    BULGARIA = 'BG',
    BURKINA_FASO = 'BF',
    BURUNDI = 'BI',
    CABO_VERDE = 'CV',
    CAMBODIA = 'KH',
    CAMEROON = 'CM',
    CANADA = 'CA',
    CAYMAN_ISLANDS = 'KY',
    CENTRAL_AFRICAN_REPUBLIC = 'CF',
    CHAD = 'TD',
    CHILE = 'CL',
    CHINA = 'CN',
    CHRISTMAS_ISLAND = 'CX',
    CLIPPERTON_ISLAND = 'CP',
    COCOS_KEELING_ISLANDS = 'CC',
    COLOMBIA = 'CO',
    COMOROS = 'KM',
    CONGO = 'CG',
    CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE = 'CD',
    COOK_ISLANDS = 'CK',
    COSTA_RICA = 'CR',
    COTE_IVOIRE = 'CI',
    CROATIA = 'HR',
    CUBA = 'CU',
    CURACAO = 'CW',
    CYPRUS = 'CY',
    CZECH_REPUBLIC = 'CZ',
    DENMARK = 'DK',
    DJIBOUTI = 'DJ',
    DOMINICA = 'DM',
    DOMINICAN_REPUBLIC = 'DO',
    ECUADOR = 'EC',
    EGYPT = 'EG',
    EL_SALVADOR = 'SV',
    EQUATORIAL_GUINEA = 'GQ',
    ERITREA = 'ER',
    ESTONIA = 'EE',
    ETHIOPIA = 'ET',
    FALKLAND_ISLANDS_MALVINAS = 'FK',
    FAROE_ISLANDS = 'FO',
    FIJI = 'FJ',
    FINLAND = 'FI',
    FRANCE = 'FR',
    FRENCH_GUIANA = 'GF',
    FRENCH_POLYNESIA = 'PF',
    FRENCH_SOUTHERN_TERRITORIES = 'TF',
    GABON = 'GA',
    GAMBIA = 'GM',
    GEORGIA = 'GE',
    GERMANY = 'DE',
    GHANA = 'GH',
    GIBRALTAR = 'GI',
    GREECE = 'GR',
    GREENLAND = 'GL',
    GRENADA = 'GD',
    GUADELOUPE = 'GP',
    GUAM = 'GU',
    GUATEMALA = 'GT',
    GUERNSEY = 'GG',
    GUINEA = 'GN',
    GUINEA_BISSAU = 'GW',
    GUYANA = 'GY',
    HAITI = 'HT',
    HEARD_ISLAND_AND_MCDONALD_ISLANDS = 'HM',
    HOLY_SEE_VATICAN_CITY_STATE = 'VA',
    HONDURAS = 'HN',
    HONG_KONG = 'HK',
    HUNGARY = 'HU',
    ICELAND = 'IS',
    INDIA = 'IN',
    INDONESIA = 'ID',
    IRAN_ISLAMIC_REPUBLIC_OF = 'IR',
    IRAQ = 'IQ',
    IRELAND = 'IE',
    ISLE_OF_MAN = 'IM',
    ISRAEL = 'IL',
    ITALY = 'IT',
    JAMAICA = 'JM',
    JAPAN = 'JP',
    JERSEY = 'JE',
    JORDAN = 'JO',
    KAZAKHSTAN = 'KZ',
    KENYA = 'KE',
    KIRIBATI = 'KI',
    KOREA_DEMOCRATIC_PEOPLE_REPUBLIC_OF = 'KP',
    KOREA_REPUBLIC_OF = 'KR',
    KOSOVO = 'XK',
    KUWAIT = 'KW',
    KYRGYZSTAN = 'KG',
    LAO_PEOPLE_DEMOCRATIC_REPUBLIC = 'LA',
    LATVIA = 'LV',
    LEBANON = 'LB',
    LESOTHO = 'LS',
    LIBERIA = 'LR',
    LIBYA = 'LY',
    LIECHTENSTEIN = 'LI',
    LITHUANIA = 'LT',
    LUXEMBOURG = 'LU',
    MACAO = 'MO',
    MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF = 'MK',
    MADAGASCAR = 'MG',
    MALAWI = 'MW',
    MALAYSIA = 'MY',
    MALDIVES = 'MV',
    MALI = 'ML',
    MALTA = 'MT',
    MARSHALL_ISLANDS = 'MH',
    MARTINIQUE = 'MQ',
    MAURITANIA = 'MR',
    MAURITIUS = 'MU',
    MAYOTTE = 'YT',
    MEXICO = 'MX',
    MICRONESIA_FEDERATED_STATES_OF = 'FM',
    MOLDOVA_REPUBLIC_OF = 'MD',
    MONACO = 'MC',
    MONGOLIA = 'MN',
    MONTENEGRO = 'ME',
    MONTSERRAT = 'MS',
    MOROCCO = 'MA',
    MOZAMBIQUE = 'MZ',
    MYANMAR = 'MM',
    NAMIBIA = 'NA',
    NAURU = 'NR',
    NEPAL = 'NP',
    NETHERLANDS = 'NL',
    NEW_CALEDONIA = 'NC',
    NEW_ZEALAND = 'NZ',
    NICARAGUA = 'NI',
    NIGER = 'NE',
    NIGERIA = 'NG',
    NIUE = 'NU',
    NORFOLK_ISLAND = 'NF',
    NORTHERN_MARIANA_ISLANDS = 'MP',
    NORWAY = 'NO',
    OMAN = 'OM',
    PAKISTAN = 'PK',
    PALAU = 'PW',
    PALESTINE = 'PS',
    PANAMA = 'PA',
    PAPUA_NEW_GUINEA = 'PG',
    PARACEL_ISLANDS = 'PF2',
    PARAGUAY = 'PY',
    PERU = 'PE',
    PHILIPPINES = 'PH',
    PITCAIRN = 'PN',
    POLAND = 'PL',
    PORTUGAL = 'PT',
    PUERTO_RICO = 'PR',
    QATAR = 'QA',
    REUNION = 'RE',
    ROMANIA = 'RO',
    RUSSIAN_FEDERATION = 'RU',
    RWANDA = 'RW',
    SAINT_BARTHELEMY = 'BL',
    SAINT_HELENA = 'SH',
    SAINT_KITTS_AND_NEVIS = 'KN',
    SAINT_LUCIA = 'LC',
    SAINT_MARTIN_FRENCH_PART = 'MF',
    SAINT_PIERRE_AND_MIQUELON = 'PM',
    SAINT_VINCENT_AND_THE_GRENADINES = 'VC',
    SAMOA = 'WS',
    SAN_MARINO = 'SM',
    SAO_TOME_AND_PRINCIPE = 'ST',
    SAUDI_ARABIA = 'SA',
    SENEGAL = 'SN',
    SERBIA = 'RS',
    SEYCHELLES = 'SC',
    SIERRA_LEONE = 'SL',
    SINGAPORE = 'SG',
    SINT_MAARTEN_DUTCH_PART = 'SX',
    SLOVAKIA = 'SK',
    SLOVENIA = 'SI',
    SOLOMON_ISLANDS = 'SB',
    SOMALIA = 'SO',
    SOUTH_AFRICA = 'ZA',
    SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = 'GS',
    SOUTH_SUDAN = 'SD2',
    SPAIN = 'ES',
    SPRATLY_ISLANDS = 'PG2',
    SRI_LANKA = 'LK',
    SUDAN = 'SD',
    SURINAME = 'SR',
    SVALBARD_AND_JAN_MAYEN = 'SJ',
    SWAZILAND = 'SZ',
    SWEDEN = 'SE',
    SWITZERLAND = 'CH',
    SYRIAN_ARAB_REPUBLIC = 'SY',
    TAIWAN_PROVINCE_OF_CHINA = 'TW',
    TAJIKISTAN = 'TJ',
    TANZANIA = 'TZ',
    THAILAND = 'TH',
    TIMOR_LESTE = 'TL',
    TOGO = 'TG',
    TOKELAU = 'TK',
    TONGA = 'TO',
    TRINIDAD_AND_TOBAGO = 'TT',
    TUNISIA = 'TN',
    TURKEY = 'TR',
    TURKISH_REPUBLIC_OF_NORTHERN_CYPRUS_NORTHERN_CYPRUS = 'CY2',
    TURKMENISTAN = 'TM',
    TURKS_AND_CAICOS_ISLANDS = 'TC',
    TUVALU = 'TV',
    UGANDA = 'UG',
    UKRAINE = 'UA',
    UNITED_ARAB_EMIRATES = 'AE',
    UNITED_KINGDOM = 'GB',
    UNITED_STATES = 'US',
    UNITED_STATES_MINOR_OUTLYING_ISLANDS = 'UM',
    URUGUAY = 'UY',
    UZBEKISTAN = 'UZ',
    VANUATU = 'VU',
    VENEZUELA = 'VE',
    VIETNAM = 'VN',
    VIRGIN_ISLANDS_BRITISH = 'VG',
    VIRGIN_ISLANDS_US = 'VI',
    WALLIS_AND_FUTUNA = 'WF',
    WESTERN_SAHARA = 'EH',
    YEMEN = 'YE',
    ZAMBIA = 'ZM',
    ZIMBABWE = 'ZW',
  }

  export const DropdownOptions = [
    {
      key: 1,
      label: 'United Kingdom',
      code: Code.UNITED_KINGDOM,
      i18n: 'en',
    },
    {
      key: 2,
      label: 'France',
      code: Code.FRANCE,
      i18n: 'fr',
    },
  ]

  export const Code2 = {
    AFGHANISTAN: {
      code: 'AF',
      alpha2: 'AF',
      alpha3: 'AFG',
      label: 'Afghanistan',
    },
    ALAND_ISLANDS: {
      code: 'AX',
      alpha2: 'AX',
      alpha3: 'ALA',
      label: 'Åland Islands',
    },
    ALBANIA: {
      code: 'AL',
      alpha2: 'AL',
      alpha3: 'ALB',
      label: 'Albania',
    },
    ALGERIA: {
      code: 'DZ',
      alpha2: 'DZ',
      alpha3: 'DZA',
      label: 'Algeria',
    },
    AMERICAN_SAMOA: {
      code: 'AS',
      alpha2: 'AS',
      alpha3: 'ASM',
      label: 'American Samoa',
    },
    ANDORRA: {
      code: 'AD',
      alpha2: 'AD',
      alpha3: 'AND',
      label: 'Andorra',
    },
    ANGOLA: {
      code: 'AO',
      alpha2: 'AO',
      alpha3: 'AGO',
      label: 'Angola',
    },
    ANGUILLA: {
      code: 'AI',
      alpha2: 'AI',
      alpha3: 'AIA',
      label: 'Anguilla',
    },
    ANTARCTICA: {
      code: 'AQ',
      alpha2: 'AQ',
      alpha3: 'ATA',
      label: 'Antarctica',
    },
    ANTIGUA_AND_BARBUDA: {
      code: 'AG',
      alpha2: 'AG',
      alpha3: 'ATG',
      label: 'Antigua and Barbuda',
    },
    ARGENTINA: {
      code: 'AR',
      alpha2: 'AR',
      alpha3: 'ARG',
      label: 'Argentina',
    },
    ARMENIA: {
      code: 'AM',
      alpha2: 'AM',
      alpha3: 'ARM',
      label: 'Armenia',
    },
    ARUBA: {
      code: 'AW',
      alpha2: 'AW',
      alpha3: 'ABW',
      label: 'Aruba',
    },
    AUSTRALIA: {
      code: 'AU',
      alpha2: 'AU',
      alpha3: 'AUS',
      label: 'Australia',
    },
    AUSTRIA: {
      code: 'AT',
      alpha2: 'AT',
      alpha3: 'AUT',
      label: 'Austria',
    },
    AZERBAIJAN: {
      code: 'AZ',
      alpha2: 'AZ',
      alpha3: 'AZE',
      label: 'Azerbaijan',
    },
    BAHAMAS: {
      code: 'BS',
      alpha2: 'BS',
      alpha3: 'BHS',
      label: 'Bahamas',
    },
    BAHRAIN: {
      code: 'BH',
      alpha2: 'BH',
      alpha3: 'BHR',
      label: 'Bahrain',
    },
    BANGLADESH: {
      code: 'BD',
      alpha2: 'BD',
      alpha3: 'BGD',
      label: 'Bangladesh',
    },
    BARBADOS: {
      code: 'BB',
      alpha2: 'BB',
      alpha3: 'BRB',
      label: 'Barbados',
    },
    BELARUS: {
      code: 'BY',
      alpha2: 'BY',
      alpha3: 'BLR',
      label: 'Belarus',
    },
    BELGIUM: {
      code: 'BE',
      alpha2: 'BE',
      alpha3: 'BEL',
      label: 'Belgium',
    },
    BELIZE: {
      code: 'BZ',
      alpha2: 'BZ',
      alpha3: 'BLZ',
      label: 'Belize',
    },
    BENIN: {
      code: 'BJ',
      alpha2: 'BJ',
      alpha3: 'BEN',
      label: 'Benin',
    },
    BERMUDA: {
      code: 'BM',
      alpha2: 'BM',
      alpha3: 'BMU',
      label: 'Bermuda',
    },
    BHUTAN: {
      code: 'BT',
      alpha2: 'BT',
      alpha3: 'BTN',
      label: 'Bhutan',
    },
    BOLIVIA: {
      code: 'BO',
      alpha2: 'BO',
      alpha3: 'BOL',
      label: 'Bolivia (Plurinational State of)',
    },
    BONAIRE: {
      code: 'BQ',
      alpha2: 'BQ',
      alpha3: 'BES',
      label: 'Bonaire, Sint Eustatius and Saba',
    },
    BOSNIA_AND_HERZEGOVINA: {
      code: 'BA',
      alpha2: 'BA',
      alpha3: 'BIH',
      label: 'Bosnia and Herzegovina',
    },
    BOTSWANA: {
      code: 'BW',
      alpha2: 'BW',
      alpha3: 'BWA',
      label: 'Botswana',
    },
    BOUVET_ISLAND: {
      code: 'BV',
      alpha2: 'BV',
      alpha3: 'BVT',
      label: 'Bouvet Island',
    },
    BRAZIL: {
      code: 'BR',
      alpha2: 'BR',
      alpha3: 'BRA',
      label: 'Brazil',
    },
    BRITISH_INDIAN_OCEAN_TERRITORY: {
      code: 'IO',
      alpha2: 'IO',
      alpha3: 'IOT',
      label: 'British Indian Ocean Territory (the)',
    },
    BRUNEI_DARUSSALAM: {
      code: 'BN',
      alpha2: 'BN',
      alpha3: 'BRN',
      label: 'Brunei Darussalam',
    },
    BULGARIA: {
      code: 'BG',
      alpha2: 'BG',
      alpha3: 'BGR',
      label: 'Bulgaria',
    },
    BURKINA_FASO: {
      code: 'BF',
      alpha2: 'BF',
      alpha3: 'BFA',
      label: 'Burkina Faso',
    },
    BURUNDI: {
      code: 'BI',
      alpha2: 'BI',
      alpha3: 'BDI',
      label: 'Burundi',
    },
    CABO_VERDE: {
      code: 'CV',
      alpha2: 'CV',
      alpha3: 'CPV',
      label: 'Cabo Verde',
    },
    CAMBODIA: {
      code: 'KH',
      alpha2: 'KH',
      alpha3: 'KHM',
      label: 'Cambodia',
    },
    CAMEROON: {
      code: 'CM',
      alpha2: 'CM',
      alpha3: 'CMR',
      label: 'Cameroon',
    },
    CANADA: {
      code: 'CA',
      alpha2: 'CA',
      alpha3: 'CAN',
      label: 'Canada',
    },
    CAYMAN_ISLANDS: {
      code: 'KY',
      alpha2: 'KY',
      alpha3: 'CYM',
      label: 'Cayman Islands (the)',
    },
    CENTRAL_AFRICAN_REPUBLIC: {
      code: 'CF',
      alpha2: 'CF',
      alpha3: 'CAF',
      label: 'Central African Republic',
    },
    CHAD: {
      code: 'TD',
      alpha2: 'TD',
      alpha3: 'TCD',
      label: 'Chad',
    },
    CHILE: {
      code: 'CL',
      alpha2: 'CL',
      alpha3: 'CHL',
      label: 'Chile',
    },
    CHINA: {
      code: 'CN',
      alpha2: 'CN',
      alpha3: 'CHN',
      label: 'China',
    },
    CHRISTMAS_ISLAND: {
      code: 'CX',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    CLIPPERTON_ISLAND: {
      code: 'CP',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    COCOS_KEELING_ISLANDS: {
      code: 'CC',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    COLOMBIA: {
      code: 'CO',
      alpha2: 'CO',
      alpha3: 'COL',
      label: 'Colombia',
    },
    COMOROS: {
      code: 'KM',
      alpha2: 'KM',
      alpha3: 'COM',
      label: 'Comoros',
    },
    CONGO: {
      code: 'CG',
      alpha2: 'CG',
      alpha3: 'COG',
      label: 'Congo',
    },
    CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE: {
      code: 'CD',
      alpha2: 'CD',
      alpha3: 'COD',
      label: 'Congo, Democratic Republic of the',
    },
    COOK_ISLANDS: {
      code: 'CK',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    COSTA_RICA: {
      code: 'CR',
      alpha2: 'CR',
      alpha3: 'CRI',
      label: 'Costa Rica',
    },
    COTE_IVOIRE: {
      code: 'CI',
      alpha2: 'CI',
      alpha3: 'CIV',
      label: "Côte d'Ivoire",
    },
    CROATIA: {
      code: 'HR',
      alpha2: 'HR',
      alpha3: 'HRV',
      label: 'Croatia',
    },
    CUBA: {
      code: 'CU',
      alpha2: 'CU',
      alpha3: 'CUB',
      label: 'Cuba',
    },
    CURACAO: {
      code: 'CW',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    CYPRUS: {
      code: 'CY',
      alpha2: 'CY',
      alpha3: 'CYP',
      label: 'Cyprus',
    },
    CZECH_REPUBLIC: {
      code: 'CZ',
      alpha2: 'CZ',
      alpha3: 'CZE',
      label: 'Czechia',
    },
    DENMARK: {
      code: 'DK',
      alpha2: 'DK',
      alpha3: 'DNK',
      label: 'Denmark',
    },
    DJIBOUTI: {
      code: 'DJ',
      alpha2: 'DJ',
      alpha3: 'DJI',
      label: 'Djibouti',
    },
    DOMINICA: {
      code: 'DM',
      alpha2: 'DM',
      alpha3: 'DMA',
      label: 'Dominica',
    },
    DOMINICAN_REPUBLIC: {
      code: 'DO',
      alpha2: 'DO',
      alpha3: 'DOM',
      label: 'Dominican Republic',
    },
    ECUADOR: {
      code: 'EC',
      alpha2: 'EC',
      alpha3: 'ECU',
      label: 'Ecuador',
    },
    EGYPT: {
      code: 'EG',
      alpha2: 'EG',
      alpha3: 'EGY',
      label: 'Egypt',
    },
    EL_SALVADOR: {
      code: 'SV',
      alpha2: 'SV',
      alpha3: 'SLV',
      label: 'El Salvador',
    },
    EQUATORIAL_GUINEA: {
      code: 'GQ',
      alpha2: 'GQ',
      alpha3: 'GNQ',
      label: 'Equatorial Guinea',
    },
    ERITREA: {
      code: 'ER',
      alpha2: 'ER',
      alpha3: 'ERI',
      label: 'Eritrea',
    },
    ESTONIA: {
      code: 'EE',
      alpha2: 'EE',
      alpha3: 'EST',
      label: 'Estonia',
    },
    ETHIOPIA: {
      code: 'ET',
      alpha2: 'ET',
      alpha3: 'ETH',
      label: 'Ethiopia',
    },
    FALKLAND_ISLANDS_MALVINAS: {
      code: 'FK',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    FAROE_ISLANDS: {
      code: 'FO',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    FIJI: {
      code: 'FJ',
      alpha2: 'FJ',
      alpha3: 'FJI',
      label: 'Fiji',
    },
    FINLAND: {
      code: 'FI',
      alpha2: 'FI',
      alpha3: 'FIN',
      label: 'Finland',
    },
    FRANCE: {
      code: 'FR',
      alpha2: 'FR',
      alpha3: 'FRA',
      label: 'France',
    },
    FRENCH_GUIANA: {
      code: 'GF',
      alpha2: 'GF',
      alpha3: 'GUF',
      label: 'French Guiana',
    },
    FRENCH_POLYNESIA: {
      code: 'PF',
      alpha2: 'PF',
      alpha3: 'PYF',
      label: 'French Polynesia',
    },
    FRENCH_SOUTHERN_TERRITORIES: {
      code: 'TF',
      alpha2: 'TF',
      alpha3: 'ATF',
      label: 'French Southern Territories (the)',
    },
    GABON: {
      code: 'GA',
      alpha2: 'GA',
      alpha3: 'GAB',
      label: 'Gabon',
    },
    GAMBIA: {
      code: 'GM',
      alpha2: 'GM',
      alpha3: 'GMB',
      label: 'Gambia',
    },
    GEORGIA: {
      code: 'GE',
      alpha2: 'GE',
      alpha3: 'GEO',
      label: 'Georgia',
    },
    GERMANY: {
      code: 'DE',
      alpha2: 'DE',
      alpha3: 'DEU',
      label: 'Germany',
    },
    GHANA: {
      code: 'GH',
      alpha2: 'GH',
      alpha3: 'GHA',
      label: 'Ghana',
    },
    GIBRALTAR: {
      code: 'GI',
      alpha2: 'GI',
      alpha3: 'GIB',
      label: 'Gibraltar',
    },
    GREECE: {
      code: 'GR',
      alpha2: 'GR',
      alpha3: 'GRC',
      label: 'Greece',
    },
    GREENLAND: {
      code: 'GL',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    GRENADA: {
      code: 'GD',
      alpha2: 'GD',
      alpha3: 'GRD',
      label: 'Grenada',
    },
    GUADELOUPE: {
      code: 'GP',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    GUAM: {
      code: 'GU',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    GUATEMALA: {
      code: 'GT',
      alpha2: 'GT',
      alpha3: 'GTM',
      label: 'Guatemala',
    },
    GUERNSEY: {
      code: 'GG',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    GUINEA: {
      code: 'GN',
      alpha2: 'GN',
      alpha3: 'GIN',
      label: 'Guinea',
    },
    GUINEA_BISSAU: {
      code: 'GW',
      alpha2: 'GW',
      alpha3: 'GNB',
      label: 'Guinea-Bissau',
    },
    GUYANA: {
      code: 'GY',
      alpha2: 'GY',
      alpha3: 'GUY',
      label: 'Guyana',
    },
    HAITI: {
      code: 'HT',
      alpha2: 'HT',
      alpha3: 'HTI',
      label: 'Haiti',
    },
    HEARD_ISLAND_AND_MCDONALD_ISLANDS: {
      code: 'HM',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    HOLY_SEE_VATICAN_CITY_STATE: {
      code: 'VA',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    HONDURAS: {
      code: 'HN',
      alpha2: 'HN',
      alpha3: 'HND',
      label: 'Honduras',
    },
    HONG_KONG: {
      code: 'HK',
      alpha2: 'HK',
      alpha3: 'HKG',
      label: 'Hong Kong',
    },
    HUNGARY: {
      code: 'HU',
      alpha2: 'HU',
      alpha3: 'HUN',
      label: 'Hungary',
    },
    ICELAND: {
      code: 'IS',
      alpha2: 'IS',
      alpha3: 'ISL',
      label: 'Iceland',
    },
    INDIA: {
      code: 'IN',
      alpha2: 'IN',
      alpha3: 'IND',
      label: 'India',
    },
    INDONESIA: {
      code: 'ID',
      alpha2: 'ID',
      alpha3: 'IDN',
      label: 'Indonesia',
    },
    IRAN_ISLAMIC_REPUBLIC_OF: {
      code: 'IR',
      alpha2: 'IR',
      alpha3: 'IRN',
      label: 'Iran (Islamic Republic of)',
    },
    IRAQ: {
      code: 'IQ',
      alpha2: 'IQ',
      alpha3: 'IRQ',
      label: 'Iraq',
    },
    IRELAND: {
      code: 'IE',
      alpha2: 'IE',
      alpha3: 'IRL',
      label: 'Ireland',
    },
    ISLE_OF_MAN: {
      code: 'IM',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    ISRAEL: {
      code: 'IL',
      alpha2: 'IL',
      alpha3: 'ISR',
      label: 'Israel',
    },
    ITALY: {
      code: 'IT',
      alpha2: 'IT',
      alpha3: 'ITA',
      label: 'Italy',
    },
    JAMAICA: {
      code: 'JM',
      alpha2: 'JM',
      alpha3: 'JAM',
      label: 'Jamaica',
    },
    JAPAN: {
      code: 'JP',
      alpha2: 'JP',
      alpha3: 'JPN',
      label: 'Japan',
    },
    JERSEY: {
      code: 'JE',
      alpha2: 'JE',
      alpha3: 'JEY',
      label: 'Jersey',
    },
    JORDAN: {
      code: 'JO',
      alpha2: 'JO',
      alpha3: 'JOR',
      label: 'Jordan',
    },
    KAZAKHSTAN: {
      code: 'KZ',
      alpha2: 'KZ',
      alpha3: 'KAZ',
      label: 'Kazakhstan',
    },
    KENYA: {
      code: 'KE',
      alpha2: 'KE',
      alpha3: 'KEN',
      label: 'Kenya',
    },
    KIRIBATI: {
      code: 'KI',
      alpha2: 'KI',
      alpha3: 'KIR',
      label: 'Kiribati',
    },
    KOREA_DEMOCRATIC_PEOPLE_REPUBLIC_OF: {
      code: 'KP',
      alpha2: 'KP',
      alpha3: 'PRK',
      label: "Korea (Democratic People's Republic of)",
    },
    KOREA_REPUBLIC_OF: {
      code: 'KR',
      alpha2: 'KR',
      alpha3: 'KOR',
      label: 'Korea, Republic of',
    },
    KOSOVO: {
      code: 'XK',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    KUWAIT: {
      code: 'KW',
      alpha2: 'KW',
      alpha3: 'KWT',
      label: 'Kuwait',
    },
    KYRGYZSTAN: {
      code: 'KG',
      alpha2: 'KG',
      alpha3: 'KGZ',
      label: 'Kyrgyzstan',
    },
    LAO_PEOPLE_DEMOCRATIC_REPUBLIC: {
      code: 'LA',
      alpha2: 'LA',
      alpha3: 'LAO',
      label: "Lao People's Democratic Republic",
    },
    LATVIA: {
      code: 'LV',
      alpha2: 'LV',
      alpha3: 'LVA',
      label: 'Latvia',
    },
    LEBANON: {
      code: 'LB',
      alpha2: 'LB',
      alpha3: 'LBN',
      label: 'Lebanon',
    },
    LESOTHO: {
      code: 'LS',
      alpha2: 'LS',
      alpha3: 'LSO',
      label: 'Lesotho',
    },
    LIBERIA: {
      code: 'LR',
      alpha2: 'LR',
      alpha3: 'LBR',
      label: 'Liberia',
    },
    LIBYA: {
      code: 'LY',
      alpha2: 'LY',
      alpha3: 'LBY',
      label: 'Libya',
    },
    LIECHTENSTEIN: {
      code: 'LI',
      alpha2: 'LI',
      alpha3: 'LIE',
      label: 'Liechtenstein',
    },
    LITHUANIA: {
      code: 'LT',
      alpha2: 'LT',
      alpha3: 'LTU',
      label: 'Lithuania',
    },
    LUXEMBOURG: {
      code: 'LU',
      alpha2: 'LU',
      alpha3: 'LUX',
      label: 'Luxembourg',
    },
    MACAO: {
      code: 'MO',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF: {
      code: 'MK',
      alpha2: 'MK',
      alpha3: 'MKD',
      label: 'North Macedonia',
    },
    MADAGASCAR: {
      code: 'MG',
      alpha2: 'MG',
      alpha3: 'MDG',
      label: 'Madagascar',
    },
    MALAWI: {
      code: 'MW',
      alpha2: 'MW',
      alpha3: 'MWI',
      label: 'Malawi',
    },
    MALAYSIA: {
      code: 'MY',
      alpha2: 'MY',
      alpha3: 'MYS',
      label: 'Malaysia',
    },
    MALDIVES: {
      code: 'MV',
      alpha2: 'MV',
      alpha3: 'MDV',
      label: 'Maldives',
    },
    MALI: {
      code: 'ML',
      alpha2: 'ML',
      alpha3: 'MLI',
      label: 'Mali',
    },
    MALTA: {
      code: 'MT',
      alpha2: 'MT',
      alpha3: 'MLT',
      label: 'Malta',
    },
    MARSHALL_ISLANDS: {
      code: 'MH',
      alpha2: 'MH',
      alpha3: 'MHL',
      label: 'Marshall Islands',
    },
    MARTINIQUE: {
      code: 'MQ',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    MAURITANIA: {
      code: 'MR',
      alpha2: 'MR',
      alpha3: 'MRT',
      label: 'Mauritania',
    },
    MAURITIUS: {
      code: 'MU',
      alpha2: 'MU',
      alpha3: 'MUS',
      label: 'Mauritius',
    },
    MAYOTTE: {
      code: 'YT',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    MEXICO: {
      code: 'MX',
      alpha2: 'MX',
      alpha3: 'MEX',
      label: 'Mexico',
    },
    MICRONESIA_FEDERATED_STATES_OF: {
      code: 'FM',
      alpha2: 'FM',
      alpha3: 'FSM',
      label: 'Micronesia (Federated States of)',
    },
    MOLDOVA_REPUBLIC_OF: {
      code: 'MD',
      alpha2: 'MD',
      alpha3: 'MDA',
      label: 'Moldova, Republic of',
    },
    MONACO: {
      code: 'MC',
      alpha2: 'MC',
      alpha3: 'MCO',
      label: 'Monaco',
    },
    MONGOLIA: {
      code: 'MN',
      alpha2: 'MN',
      alpha3: 'MNG',
      label: 'Mongolia',
    },
    MONTENEGRO: {
      code: 'ME',
      alpha2: 'ME',
      alpha3: 'MNE',
      label: 'Montenegro',
    },
    MONTSERRAT: {
      code: 'MS',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    MOROCCO: {
      code: 'MA',
      alpha2: 'MA',
      alpha3: 'MAR',
      label: 'Morocco',
    },
    MOZAMBIQUE: {
      code: 'MZ',
      alpha2: 'MZ',
      alpha3: 'MOZ',
      label: 'Mozambique',
    },
    MYANMAR: {
      code: 'MM',
      alpha2: 'MM',
      alpha3: 'MMR',
      label: 'Myanmar',
    },
    NAMIBIA: {
      code: 'NA',
      alpha2: 'NA',
      alpha3: 'NAM',
      label: 'Namibia',
    },
    NAURU: {
      code: 'NR',
      alpha2: 'NR',
      alpha3: 'NRU',
      label: 'Nauru',
    },
    NEPAL: {
      code: 'NP',
      alpha2: 'NP',
      alpha3: 'NPL',
      label: 'Nepal',
    },
    NETHERLANDS: {
      code: 'NL',
      alpha2: 'NL',
      alpha3: 'NLD',
      label: 'Netherlands',
    },
    NEW_CALEDONIA: {
      code: 'NC',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    NEW_ZEALAND: {
      code: 'NZ',
      alpha2: 'NZ',
      alpha3: 'NZL',
      label: 'New Zealand',
    },
    NICARAGUA: {
      code: 'NI',
      alpha2: 'NI',
      alpha3: 'NIC',
      label: 'Nicaragua',
    },
    NIGER: {
      code: 'NE',
      alpha2: 'NE',
      alpha3: 'NER',
      label: 'Niger',
    },
    NIGERIA: {
      code: 'NG',
      alpha2: 'NG',
      alpha3: 'NGA',
      label: 'Nigeria',
    },
    NIUE: {
      code: 'NU',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    NORFOLK_ISLAND: {
      code: 'NF',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    NORTHERN_MARIANA_ISLANDS: {
      code: 'MP',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    NORWAY: {
      code: 'NO',
      alpha2: 'NO',
      alpha3: 'NOR',
      label: 'Norway',
    },
    OMAN: {
      code: 'OM',
      alpha2: 'OM',
      alpha3: 'OMN',
      label: 'Oman',
    },
    PAKISTAN: {
      code: 'PK',
      alpha2: 'PK',
      alpha3: 'PAK',
      label: 'Pakistan',
    },
    PALAU: {
      code: 'PW',
      alpha2: 'PW',
      alpha3: 'PLW',
      label: 'Palau',
    },
    PALESTINE: {
      code: 'PS',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    PANAMA: {
      code: 'PA',
      alpha2: 'PA',
      alpha3: 'PAN',
      label: 'Panama',
    },
    PAPUA_NEW_GUINEA: {
      code: 'PG',
      alpha2: 'PG',
      alpha3: 'PNG',
      label: 'Papua New Guinea',
    },
    PARACEL_ISLANDS: {
      code: 'PF2',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    PARAGUAY: {
      code: 'PY',
      alpha2: 'PY',
      alpha3: 'PRY',
      label: 'Paraguay',
    },
    PERU: {
      code: 'PE',
      alpha2: 'PE',
      alpha3: 'PER',
      label: 'Peru',
    },
    PHILIPPINES: {
      code: 'PH',
      alpha2: 'PH',
      alpha3: 'PHL',
      label: 'Philippines',
    },
    PITCAIRN: {
      code: 'PN',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    POLAND: {
      code: 'PL',
      alpha2: 'PL',
      alpha3: 'POL',
      label: 'Poland',
    },
    PORTUGAL: {
      code: 'PT',
      alpha2: 'PT',
      alpha3: 'PRT',
      label: 'Portugal',
    },
    PUERTO_RICO: {
      code: 'PR',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    QATAR: {
      code: 'QA',
      alpha2: 'QA',
      alpha3: 'QAT',
      label: 'Qatar',
    },
    REUNION: {
      code: 'RE',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    ROMANIA: {
      code: 'RO',
      alpha2: 'RO',
      alpha3: 'ROU',
      label: 'Romania',
    },
    RUSSIAN_FEDERATION: {
      code: 'RU',
      alpha2: 'RU',
      alpha3: 'RUS',
      label: 'Russian Federation',
    },
    RWANDA: {
      code: 'RW',
      alpha2: 'RW',
      alpha3: 'RWA',
      label: 'Rwanda',
    },
    SAINT_BARTHELEMY: {
      code: 'BL',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    SAINT_HELENA: {
      code: 'SH',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    SAINT_KITTS_AND_NEVIS: {
      code: 'KN',
      alpha2: 'KN',
      alpha3: 'KNA',
      label: 'Saint Kitts and Nevis',
    },
    SAINT_LUCIA: {
      code: 'LC',
      alpha2: 'LC',
      alpha3: 'LCA',
      label: 'Saint Lucia',
    },
    SAINT_MARTIN_FRENCH_PART: {
      code: 'MF',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    SAINT_PIERRE_AND_MIQUELON: {
      code: 'PM',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    SAINT_VINCENT_AND_THE_GRENADINES: {
      code: 'VC',
      alpha2: 'VC',
      alpha3: 'VCT',
      label: 'Saint Vincent and the Grenadines',
    },
    SAMOA: {
      code: 'WS',
      alpha2: 'WS',
      alpha3: 'WSM',
      label: 'Samoa',
    },
    SAN_MARINO: {
      code: 'SM',
      alpha2: 'SM',
      alpha3: 'SMR',
      label: 'San Marino',
    },
    SAO_TOME_AND_PRINCIPE: {
      code: 'ST',
      alpha2: 'ST',
      alpha3: 'STP',
      label: 'Sao Tome and Principe',
    },
    SAUDI_ARABIA: {
      code: 'SA',
      alpha2: 'SA',
      alpha3: 'SAU',
      label: 'Saudi Arabia',
    },
    SENEGAL: {
      code: 'SN',
      alpha2: 'SN',
      alpha3: 'SEN',
      label: 'Senegal',
    },
    SERBIA: {
      code: 'RS',
      alpha2: 'RS',
      alpha3: 'SRB',
      label: 'Serbia',
    },
    SEYCHELLES: {
      code: 'SC',
      alpha2: 'SC',
      alpha3: 'SYC',
      label: 'Seychelles',
    },
    SIERRA_LEONE: {
      code: 'SL',
      alpha2: 'SL',
      alpha3: 'SLE',
      label: 'Sierra Leone',
    },
    SINGAPORE: {
      code: 'SG',
      alpha2: 'SG',
      alpha3: 'SGP',
      label: 'Singapore',
    },
    SINT_MAARTEN_DUTCH_PART: {
      code: 'SX',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    SLOVAKIA: {
      code: 'SK',
      alpha2: 'SK',
      alpha3: 'SVK',
      label: 'Slovakia',
    },
    SLOVENIA: {
      code: 'SI',
      alpha2: 'SI',
      alpha3: 'SVN',
      label: 'Slovenia',
    },
    SOLOMON_ISLANDS: {
      code: 'SB',
      alpha2: 'SB',
      alpha3: 'SLB',
      label: 'Solomon Islands',
    },
    SOMALIA: {
      code: 'SO',
      alpha2: 'SO',
      alpha3: 'SOM',
      label: 'Somalia',
    },
    SOUTH_AFRICA: {
      code: 'ZA',
      alpha2: 'ZA',
      alpha3: 'ZAF',
      label: 'South Africa',
    },
    SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: {
      code: 'GS',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    SOUTH_SUDAN: {
      code: 'SD2',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    SPAIN: {
      code: 'ES',
      alpha2: 'ES',
      alpha3: 'ESP',
      label: 'Spain',
    },
    SPRATLY_ISLANDS: {
      code: 'PG2',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    SRI_LANKA: {
      code: 'LK',
      alpha2: 'LK',
      alpha3: 'LKA',
      label: 'Sri Lanka',
    },
    SUDAN: {
      code: 'SD',
      alpha2: 'SD',
      alpha3: 'SDN',
      label: 'Sudan',
    },
    SURINAME: {
      code: 'SR',
      alpha2: 'SR',
      alpha3: 'SUR',
      label: 'Suriname',
    },
    SVALBARD_AND_JAN_MAYEN: {
      code: 'SJ',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    SWAZILAND: {
      code: 'SZ',
      alpha2: 'SZ',
      alpha3: 'SWZ',
      label: 'Eswatini',
    },
    SWEDEN: {
      code: 'SE',
      alpha2: 'SE',
      alpha3: 'SWE',
      label: 'Sweden',
    },
    SWITZERLAND: {
      code: 'CH',
      alpha2: 'CH',
      alpha3: 'CHE',
      label: 'Switzerland',
    },
    SYRIAN_ARAB_REPUBLIC: {
      code: 'SY',
      alpha2: 'SY',
      alpha3: 'SYR',
      label: 'Syrian Arab Republic',
    },
    TAIWAN_PROVINCE_OF_CHINA: {
      code: 'TW',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    TAJIKISTAN: {
      code: 'TJ',
      alpha2: 'TJ',
      alpha3: 'TJK',
      label: 'Tajikistan',
    },
    TANZANIA: {
      code: 'TZ',
      alpha2: 'TZ',
      alpha3: 'TZA',
      label: 'Tanzania, United Republic of',
    },
    THAILAND: {
      code: 'TH',
      alpha2: 'TH',
      alpha3: 'THA',
      label: 'Thailand',
    },
    TIMOR_LESTE: {
      code: 'TL',
      alpha2: 'TL',
      alpha3: 'TLS',
      label: 'Timor-Leste',
    },
    TOGO: {
      code: 'TG',
      alpha2: 'TG',
      alpha3: 'TGO',
      label: 'Togo',
    },
    TOKELAU: {
      code: 'TK',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    TONGA: {
      code: 'TO',
      alpha2: 'TO',
      alpha3: 'TON',
      label: 'Tonga',
    },
    TRINIDAD_AND_TOBAGO: {
      code: 'TT',
      alpha2: 'TT',
      alpha3: 'TTO',
      label: 'Trinidad and Tobago',
    },
    TUNISIA: {
      code: 'TN',
      alpha2: 'TN',
      alpha3: 'TUN',
      label: 'Tunisia',
    },
    TURKEY: {
      code: 'TR',
      alpha2: 'TR',
      alpha3: 'TUR',
      label: 'Turkey',
    },
    TURKISH_REPUBLIC_OF_NORTHERN_CYPRUS_NORTHERN_CYPRUS: {
      code: 'CY2',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    TURKMENISTAN: {
      code: 'TM',
      alpha2: 'TM',
      alpha3: 'TKM',
      label: 'Turkmenistan',
    },
    TURKS_AND_CAICOS_ISLANDS: {
      code: 'TC',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    TUVALU: {
      code: 'TV',
      alpha2: 'TV',
      alpha3: 'TUV',
      label: 'Tuvalu',
    },
    UGANDA: {
      code: 'UG',
      alpha2: 'UG',
      alpha3: 'UGA',
      label: 'Uganda',
    },
    UKRAINE: {
      code: 'UA',
      alpha2: 'UA',
      alpha3: 'UKR',
      label: 'Ukraine',
    },
    UNITED_ARAB_EMIRATES: {
      code: 'AE',
      alpha2: 'AE',
      alpha3: 'ARE',
      label: 'United Arab Emirates',
    },
    UNITED_KINGDOM: {
      code: 'GB',
      alpha2: 'GB',
      alpha3: 'GBR',
      label: 'United Kingdom of Great Britain and Northern Ireland',
    },
    UNITED_STATES: {
      code: 'US',
      alpha2: 'US',
      alpha3: 'USA',
      label: 'United States of America',
    },
    UNITED_STATES_MINOR_OUTLYING_ISLANDS: {
      code: 'UM',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    URUGUAY: {
      code: 'UY',
      alpha2: 'UY',
      alpha3: 'URY',
      label: 'Uruguay',
    },
    UZBEKISTAN: {
      code: 'UZ',
      alpha2: 'UZ',
      alpha3: 'UZB',
      label: 'Uzbekistan',
    },
    VANUATU: {
      code: 'VU',
      alpha2: 'VU',
      alpha3: 'VUT',
      label: 'Vanuatu',
    },
    VENEZUELA: {
      code: 'VE',
      alpha2: 'VE',
      alpha3: 'VEN',
      label: 'Venezuela (Bolivarian Republic of)',
    },
    VIETNAM: {
      code: 'VN',
      alpha2: 'VN',
      alpha3: 'VNM',
      label: 'Viet Nam',
    },
    VIRGIN_ISLANDS_BRITISH: {
      code: 'VG',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    VIRGIN_ISLANDS_US: {
      code: 'VI',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    WALLIS_AND_FUTUNA: {
      code: 'WF',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    WESTERN_SAHARA: {
      code: 'EH',
      alpha2: '???',
      alpha3: '???',
      label: '???',
    },
    YEMEN: {
      code: 'YE',
      alpha2: 'YE',
      alpha3: 'YEM',
      label: 'Yemen',
    },
    ZAMBIA: {
      code: 'ZM',
      alpha2: 'ZM',
      alpha3: 'ZMB',
      label: 'Zambia',
    },
    ZIMBABWE: {
      code: 'ZW',
      alpha2: 'ZW',
      alpha3: 'ZWE',
      label: 'Zimbabwe',
    },
  }

  export interface Option {
    country: string
    code: Code
    data: { residency: { score: number; risk: string }; bank: { score: number; risk: string } }
  }

  export const Options: Option[] = [
    {
      country: 'Afghanistan',
      code: Code.AFGHANISTAN,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Åland Islands',
      code: Code.ALAND_ISLANDS,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Albania',
      code: Code.ALBANIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Algeria',
      code: Code.ALGERIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'American Samoa',
      code: Code.AMERICAN_SAMOA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Andorra',
      code: Code.ANDORRA,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Angola',
      code: Code.ANGOLA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Anguilla',
      code: Code.ANGUILLA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Antarctica',
      code: Code.ANTARCTICA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Antigua and Barbuda',
      code: Code.ANTIGUA_AND_BARBUDA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Argentina',
      code: Code.ARGENTINA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Armenia',
      code: Code.ARMENIA,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Aruba',
      code: Code.ARUBA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Australia',
      code: Code.AUSTRALIA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Austria',
      code: Code.AUSTRIA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Azerbaijan',
      code: Code.AZERBAIJAN,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Bahamas',
      code: Code.BAHAMAS,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Bahrain',
      code: Code.BAHRAIN,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Bangladesh',
      code: Code.BANGLADESH,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Barbados',
      code: Code.BARBADOS,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Belarus',
      code: Code.BELARUS,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Belgium',
      code: Code.BELGIUM,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Belize',
      code: Code.BELIZE,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Benin',
      code: Code.BENIN,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Bermuda',
      code: Code.BERMUDA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Bhutan',
      code: Code.BHUTAN,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Bolivia',
      code: Code.BOLIVIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Bonaire',
      code: Code.BONAIRE,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Bosnia and Herzegovina',
      code: Code.BOSNIA_AND_HERZEGOVINA,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Botswana',
      code: Code.BOTSWANA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Bouvet Island',
      code: Code.BOUVET_ISLAND,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Brazil',
      code: Code.BRAZIL,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'British Indian Ocean Territory',
      code: Code.BRITISH_INDIAN_OCEAN_TERRITORY,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Brunei Darussalam',
      code: Code.BRUNEI_DARUSSALAM,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Bulgaria',
      code: Code.BULGARIA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Burkina Faso',
      code: Code.BURKINA_FASO,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Burundi',
      code: Code.BURUNDI,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Cabo Verde',
      code: Code.CABO_VERDE,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Cambodia',
      code: Code.CAMBODIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Cameroon',
      code: Code.CAMEROON,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Canada',
      code: Code.CANADA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Cayman Islands',
      code: Code.CAYMAN_ISLANDS,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Central African Republic',
      code: Code.CENTRAL_AFRICAN_REPUBLIC,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Chad',
      code: Code.CHAD,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Chile',
      code: Code.CHILE,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'China',
      code: Code.CHINA,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Christmas Island',
      code: Code.CHRISTMAS_ISLAND,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Clipperton Island',
      code: Code.CLIPPERTON_ISLAND,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Cocos (Keeling) Islands',
      code: Code.COCOS_KEELING_ISLANDS,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Colombia',
      code: Code.COLOMBIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Comoros',
      code: Code.COMOROS,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Congo',
      code: Code.CONGO,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Congo, the Democratic Republic of the',
      code: Code.CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Cook Islands',
      code: Code.COOK_ISLANDS,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Costa Rica',
      code: Code.COSTA_RICA,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: "Côte d'Ivoire",
      code: Code.COTE_IVOIRE,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Croatia',
      code: Code.CROATIA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Cuba',
      code: Code.CUBA,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Curaçao',
      code: Code.CURACAO,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Cyprus',
      code: Code.CYPRUS,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Czech Republic',
      code: Code.CZECH_REPUBLIC,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Denmark',
      code: Code.DENMARK,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Djibouti',
      code: Code.DJIBOUTI,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Dominica',
      code: Code.DOMINICA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Dominican Republic',
      code: Code.DOMINICAN_REPUBLIC,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Ecuador',
      code: Code.ECUADOR,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Egypt',
      code: Code.EGYPT,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'El Salvador',
      code: Code.EL_SALVADOR,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Equatorial Guinea',
      code: Code.EQUATORIAL_GUINEA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Eritrea',
      code: Code.ERITREA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Estonia',
      code: Code.ESTONIA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Ethiopia',
      code: Code.ETHIOPIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Falkland Islands [Malvinas]',
      code: Code.FALKLAND_ISLANDS_MALVINAS,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Faroe Islands',
      code: Code.FAROE_ISLANDS,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Fiji',
      code: Code.FIJI,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Finland',
      code: Code.FINLAND,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'France',
      code: Code.FRANCE,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'French Guiana',
      code: Code.FRENCH_GUIANA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'French Polynesia',
      code: Code.FRENCH_POLYNESIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'French Southern Territories',
      code: Code.FRENCH_SOUTHERN_TERRITORIES,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Gabon',
      code: Code.GABON,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Gambia',
      code: Code.GAMBIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Georgia',
      code: Code.GEORGIA,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Germany',
      code: Code.GERMANY,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Ghana',
      code: Code.GHANA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Gibraltar',
      code: Code.GIBRALTAR,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Greece',
      code: Code.GREECE,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Greenland',
      code: Code.GREENLAND,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Grenada',
      code: Code.GRENADA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Guadeloupe',
      code: Code.GUADELOUPE,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Guam',
      code: Code.GUAM,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Guatemala',
      code: Code.GUATEMALA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Guernsey',
      code: Code.GUERNSEY,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Guinea',
      code: Code.GUINEA,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Guinea-Bissau',
      code: Code.GUINEA_BISSAU,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Guyana',
      code: Code.GUYANA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Haiti',
      code: Code.HAITI,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Heard Island and McDonald Islands',
      code: Code.HEARD_ISLAND_AND_MCDONALD_ISLANDS,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Holy See [Vatican City State]',
      code: Code.HOLY_SEE_VATICAN_CITY_STATE,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Honduras',
      code: Code.HONDURAS,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Hong Kong',
      code: Code.HONG_KONG,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Hungary',
      code: Code.HUNGARY,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Iceland',
      code: Code.ICELAND,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'India',
      code: Code.INDIA,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Indonesia',
      code: Code.INDONESIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Iran, Islamic Republic of',
      code: Code.IRAN_ISLAMIC_REPUBLIC_OF,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Iraq',
      code: Code.IRAQ,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Ireland',
      code: Code.IRELAND,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Isle of Man',
      code: Code.ISLE_OF_MAN,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Israel',
      code: Code.ISRAEL,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Italy',
      code: Code.ITALY,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Jamaica',
      code: Code.JAMAICA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Japan',
      code: Code.JAPAN,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Jersey',
      code: Code.JERSEY,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Jordan',
      code: Code.JORDAN,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Kazakhstan',
      code: Code.KAZAKHSTAN,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Kenya',
      code: Code.KENYA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Kiribati',
      code: Code.KIRIBATI,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: "Korea, Democratic People's Republic of",
      code: Code.KOREA_DEMOCRATIC_PEOPLE_REPUBLIC_OF,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Korea, Republic of',
      code: Code.KOREA_REPUBLIC_OF,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Kosovo',
      code: Code.KOSOVO,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Kuwait',
      code: Code.KUWAIT,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Kyrgyzstan',
      code: Code.KYRGYZSTAN,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: "Lao People's Democratic Republic",
      code: Code.LAO_PEOPLE_DEMOCRATIC_REPUBLIC,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Latvia',
      code: Code.LATVIA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Lebanon',
      code: Code.LEBANON,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Lesotho',
      code: Code.LESOTHO,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Liberia',
      code: Code.LIBERIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Libya',
      code: Code.LIBYA,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Liechtenstein',
      code: Code.LIECHTENSTEIN,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Lithuania',
      code: Code.LITHUANIA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Luxembourg',
      code: Code.LUXEMBOURG,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Macao',
      code: Code.MACAO,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Macedonia, The former Yugoslav Republic of',
      code: Code.MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Madagascar',
      code: Code.MADAGASCAR,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Malawi',
      code: Code.MALAWI,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Malaysia',
      code: Code.MALAYSIA,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Maldives',
      code: Code.MALDIVES,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Mali',
      code: Code.MALI,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Malta',
      code: Code.MALTA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Marshall Islands',
      code: Code.MARSHALL_ISLANDS,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Martinique',
      code: Code.MARTINIQUE,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Mauritania',
      code: Code.MAURITANIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Mauritius',
      code: Code.MAURITIUS,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Mayotte',
      code: Code.MAYOTTE,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Mexico',
      code: Code.MEXICO,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Micronesia, Federated States of',
      code: Code.MICRONESIA_FEDERATED_STATES_OF,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Moldova, Republic of',
      code: Code.MOLDOVA_REPUBLIC_OF,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Monaco',
      code: Code.MONACO,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Mongolia',
      code: Code.MONGOLIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Montenegro',
      code: Code.MONTENEGRO,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 120, risk: 'Prohibited' },
      },
    },
    {
      country: 'Montserrat',
      code: Code.MONTSERRAT,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Morocco',
      code: Code.MOROCCO,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Mozambique',
      code: Code.MOZAMBIQUE,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Myanmar',
      code: Code.MYANMAR,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Namibia',
      code: Code.NAMIBIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Nauru',
      code: Code.NAURU,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Nepal',
      code: Code.NEPAL,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Netherlands',
      code: Code.NETHERLANDS,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'New Caledonia',
      code: Code.NEW_CALEDONIA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'New Zealand',
      code: Code.NEW_ZEALAND,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Nicaragua',
      code: Code.NICARAGUA,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Niger',
      code: Code.NIGER,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Nigeria',
      code: Code.NIGERIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Niue',
      code: Code.NIUE,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Norfolk Island',
      code: Code.NORFOLK_ISLAND,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Northern Mariana Islands',
      code: Code.NORTHERN_MARIANA_ISLANDS,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Norway',
      code: Code.NORWAY,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Oman',
      code: Code.OMAN,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Pakistan',
      code: Code.PAKISTAN,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Palau',
      code: Code.PALAU,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Palestine',
      code: Code.PALESTINE,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 120, risk: 'Prohibited' },
      },
    },
    {
      country: 'Panama',
      code: Code.PANAMA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Papua New Guinea',
      code: Code.PAPUA_NEW_GUINEA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Paracel Islands',
      code: Code.PARACEL_ISLANDS,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Paraguay',
      code: Code.PARAGUAY,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Peru',
      code: Code.PERU,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Philippines',
      code: Code.PHILIPPINES,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Pitcairn',
      code: Code.PITCAIRN,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Poland',
      code: Code.POLAND,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Portugal',
      code: Code.PORTUGAL,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Puerto Rico',
      code: Code.PUERTO_RICO,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Qatar',
      code: Code.QATAR,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Réunion',
      code: Code.REUNION,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Romania',
      code: Code.ROMANIA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Russian Federation',
      code: Code.RUSSIAN_FEDERATION,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Rwanda',
      code: Code.RWANDA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Saint Barthélemy',
      code: Code.SAINT_BARTHELEMY,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Saint Helena',
      code: Code.SAINT_HELENA,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Saint Kitts and Nevis',
      code: Code.SAINT_KITTS_AND_NEVIS,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Saint Lucia',
      code: Code.SAINT_LUCIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Saint Martin (French part)',
      code: Code.SAINT_MARTIN_FRENCH_PART,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Saint Pierre and Miquelon',
      code: Code.SAINT_PIERRE_AND_MIQUELON,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Saint Vincent and the Grenadines',
      code: Code.SAINT_VINCENT_AND_THE_GRENADINES,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Samoa',
      code: Code.SAMOA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'San Marino',
      code: Code.SAN_MARINO,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Sao Tome and Principe',
      code: Code.SAO_TOME_AND_PRINCIPE,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Saudi Arabia',
      code: Code.SAUDI_ARABIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Senegal',
      code: Code.SENEGAL,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Serbia',
      code: Code.SERBIA,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Seychelles',
      code: Code.SEYCHELLES,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Sierra Leone',
      code: Code.SIERRA_LEONE,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Singapore',
      code: Code.SINGAPORE,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Sint Maarten (Dutch part)',
      code: Code.SINT_MAARTEN_DUTCH_PART,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Slovakia',
      code: Code.SLOVAKIA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Slovenia',
      code: Code.SLOVENIA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Solomon Islands',
      code: Code.SOLOMON_ISLANDS,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Somalia',
      code: Code.SOMALIA,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'South Africa',
      code: Code.SOUTH_AFRICA,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'South Georgia and the South Sandwich Islands',
      code: Code.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'South Sudan',
      code: Code.SOUTH_SUDAN,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Spain',
      code: Code.SPAIN,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Spratly Islands',
      code: Code.SPRATLY_ISLANDS,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Sri Lanka',
      code: Code.SRI_LANKA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Sudan',
      code: Code.SUDAN,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Suriname',
      code: Code.SURINAME,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Svalbard and Jan Mayen',
      code: Code.SVALBARD_AND_JAN_MAYEN,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Swaziland',
      code: Code.SWAZILAND,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Sweden',
      code: Code.SWEDEN,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Switzerland',
      code: Code.SWITZERLAND,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Syrian Arab Republic',
      code: Code.SYRIAN_ARAB_REPUBLIC,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Taiwan, Province of China',
      code: Code.TAIWAN_PROVINCE_OF_CHINA,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Tajikistan',
      code: Code.TAJIKISTAN,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Tanzania',
      code: Code.TANZANIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Thailand',
      code: Code.THAILAND,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Timor-Leste',
      code: Code.TIMOR_LESTE,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Togo',
      code: Code.TOGO,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Tokelau',
      code: Code.TOKELAU,
      data: { residency: { score: 35, risk: 'Low' }, bank: { score: 35, risk: 'Low' } },
    },
    {
      country: 'Tonga',
      code: Code.TONGA,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Trinidad and Tobago',
      code: Code.TRINIDAD_AND_TOBAGO,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Tunisia',
      code: Code.TUNISIA,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Turkey',
      code: Code.TURKEY,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Turkish Republic of Northern Cyprus (Northern Cyprus)',
      code: Code.TURKISH_REPUBLIC_OF_NORTHERN_CYPRUS_NORTHERN_CYPRUS,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Turkmenistan',
      code: Code.TURKMENISTAN,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Turks and Caicos Islands',
      code: Code.TURKS_AND_CAICOS_ISLANDS,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Tuvalu',
      code: Code.TUVALU,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Uganda',
      code: Code.UGANDA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Ukraine',
      code: Code.UKRAINE,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'United Arab Emirates',
      code: Code.UNITED_ARAB_EMIRATES,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'United Kingdom',
      code: Code.UNITED_KINGDOM,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'United States',
      code: Code.UNITED_STATES,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'United States Minor Outlying Islands',
      code: Code.UNITED_STATES_MINOR_OUTLYING_ISLANDS,
      data: { residency: { score: 5, risk: 'Low' }, bank: { score: 1, risk: 'Low' } },
    },
    {
      country: 'Uruguay',
      code: Code.URUGUAY,
      data: { residency: { score: 10, risk: 'Medium' }, bank: { score: 10, risk: 'Medium' } },
    },
    {
      country: 'Uzbekistan',
      code: Code.UZBEKISTAN,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Vanuatu',
      code: Code.VANUATU,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Venezuela',
      code: Code.VENEZUELA,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Vietnam',
      code: Code.VIETNAM,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Virgin Islands, British',
      code: Code.VIRGIN_ISLANDS_BRITISH,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Virgin Islands (U.S.)',
      code: Code.VIRGIN_ISLANDS_US,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Wallis and Futuna',
      code: Code.WALLIS_AND_FUTUNA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Western Sahara',
      code: Code.WESTERN_SAHARA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Yemen',
      code: Code.YEMEN,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
    {
      country: 'Zambia',
      code: Code.ZAMBIA,
      data: { residency: { score: 35, risk: 'High' }, bank: { score: 35, risk: 'High' } },
    },
    {
      country: 'Zimbabwe',
      code: Code.ZIMBABWE,
      data: {
        residency: { score: 120, risk: 'Prohibited' },
        bank: { score: 100, risk: 'Prohibited' },
      },
    },
  ]

  export namespace US {
    export namespace States {
      export enum Code {
        ALABAMA = 'AL',
        ALASKA = 'AK',
        AMERICAN_SAMOA = 'AS',
        ARIZONA = 'AZ',
        ARKANSAS = 'AR',
        CALIFORNIA = 'CA',
        COLORADO = 'CO',
        CONNECTICUT = 'CT',
        DELAWARE = 'DE',
        DISTRICT_OF_COLUMBIA = 'DC',
        FLORIDA = 'FL',
        GEORGIA = 'GA',
        GUAM = 'GU',
        HAWAII = 'HI',
        IDAHO = 'ID',
        ILLINOIS = 'IL',
        INDIANA = 'IN',
        IOWA = 'IA',
        KANSAS = 'KS',
        KENTUCKY = 'KY',
        LOUISIANA = 'LA',
        MAINE = 'ME',
        MARYLAND = 'MD',
        MASSACHUSETTS = 'MA',
        MICHIGAN = 'MI',
        MINNESOTA = 'MN',
        MISSISSIPPI = 'MS',
        MISSOURI = 'MO',
        MONTANA = 'MT',
        NEBRASKA = 'NE',
        NEVADA = 'NV',
        NEW_HAMPSHIRE = 'NH',
        NEW_JERSEY = 'NJ',
        NEW_MEXICO = 'NM',
        NEW_YORK = 'NY',
        NORTH_CAROLINA = 'NC',
        NORTH_DAKOTA = 'ND',
        NORTHERN_MARIANA_ISLANDS = 'MP',
        OHIO = 'OH',
        OKLAHOMA = 'OK',
        OREGON = 'OR',
        PENNSYLVANIA = 'PA',
        PUERTO_RICO = 'PR',
        RHODE_ISLAND = 'RI',
        SOUTH_CAROLINA = 'SC',
        SOUTH_DAKOTA = 'SD',
        TENNESSEE = 'TN',
        TEXAS = 'TX',
        UTAH = 'UT',
        VERMONT = 'VT',
        VIRGIN_ISLANDS = 'VI',
        VIRGINIA = 'VA',
        WASHINGTON = 'WA',
        WEST_VIRGINIA = 'WV',
        WISCONSIN = 'WI',
        WYOMING = 'WY',
      }
      export const Options = [
        { state: 'Alabama', code: Code.ALABAMA },
        { state: 'Alaska', code: Code.ALASKA },
        { state: 'American Samoa', code: Code.AMERICAN_SAMOA },
        { state: 'Arizona', code: Code.ARIZONA },
        { state: 'Arkansas', code: Code.ARKANSAS },
        { state: 'California', code: Code.CALIFORNIA },
        { state: 'Colorado', code: Code.COLORADO },
        { state: 'Connecticut', code: Code.CONNECTICUT },
        { state: 'Delaware', code: Code.DELAWARE },
        { state: 'District Of Columbia', code: Code.DISTRICT_OF_COLUMBIA },
        { state: 'Florida', code: Code.FLORIDA },
        { state: 'Georgia', code: Code.GEORGIA },
        { state: 'Guam', code: Code.GUAM },
        { state: 'Hawaii', code: Code.HAWAII },
        { state: 'Idaho', code: Code.IDAHO },
        { state: 'Illinois', code: Code.ILLINOIS },
        { state: 'Indiana', code: Code.INDIANA },
        { state: 'Iowa', code: Code.IOWA },
        { state: 'Kansas', code: Code.KANSAS },
        { state: 'Kentucky', code: Code.KENTUCKY },
        { state: 'Louisiana', code: Code.LOUISIANA },
        { state: 'Maine', code: Code.MAINE },
        { state: 'Maryland', code: Code.MARYLAND },
        { state: 'Massachusetts', code: Code.MASSACHUSETTS },
        { state: 'Michigan', code: Code.MICHIGAN },
        { state: 'Minnesota', code: Code.MINNESOTA },
        { state: 'Mississippi', code: Code.MISSISSIPPI },
        { state: 'Missouri', code: Code.MISSOURI },
        { state: 'Montana', code: Code.MONTANA },
        { state: 'Nebraska', code: Code.NEBRASKA },
        { state: 'Nevada', code: Code.NEVADA },
        { state: 'New Hampshire', code: Code.NEW_HAMPSHIRE },
        { state: 'New Jersey', code: Code.NEW_JERSEY },
        { state: 'New Mexico', code: Code.NEW_MEXICO },
        { state: 'New York', code: Code.NEW_YORK },
        { state: 'North Carolina', code: Code.NORTH_CAROLINA },
        { state: 'North Dakota', code: Code.NORTH_DAKOTA },
        { state: 'Northern Mariana Islands', code: Code.NORTHERN_MARIANA_ISLANDS },
        { state: 'Ohio', code: Code.OHIO },
        { state: 'Oklahoma', code: Code.OKLAHOMA },
        { state: 'Oregon', code: Code.OREGON },
        { state: 'Pennsylvania', code: Code.PENNSYLVANIA },
        { state: 'Puerto Rico', code: Code.PUERTO_RICO },
        { state: 'Rhode Island', code: Code.RHODE_ISLAND },
        { state: 'South Carolina', code: Code.SOUTH_CAROLINA },
        { state: 'South Dakota', code: Code.SOUTH_DAKOTA },
        { state: 'Tennessee', code: Code.TENNESSEE },
        { state: 'Texas', code: Code.TEXAS },
        { state: 'Utah', code: Code.UTAH },
        { state: 'Vermont', code: Code.VERMONT },
        { state: 'Virgin Islands', code: Code.VIRGIN_ISLANDS },
        { state: 'Virginia', code: Code.VIRGINIA },
        { state: 'Washington', code: Code.WASHINGTON },
        { state: 'West Virginia', code: Code.WEST_VIRGINIA },
        { state: 'Wisconsin', code: Code.WISCONSIN },
        { state: 'Wyoming', code: Code.WYOMING },
      ]
    }
  }
}
