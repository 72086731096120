import React from 'react'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, TouchableCard, Typography } from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { StopCardProps } from './StopCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRightBox,
} from './StopCard.styles'
import { format } from 'date-fns'

const StopCard = ({ stop, hasRanking, onClick }: StopCardProps) => {
  const theme = useTheme()

  const OnClickStep = () => {
    if (onClick) {
      onClick()
    }
  }

  format(new Date(), 'dd/MM/yyyy')

  const isStopDelivered = () => {
    if (stop.delivered) {
      return true
    }

    return false
  }

  return (
    <StyledCardWrapper onPress={OnClickStep} activeOpacity={0.8}>
      <TouchableCard hasGreenBorder={isStopDelivered()}>
        <StyledCardContent>
          {hasRanking ? (
            <StyledLeftBox>
              <Typography.CardTitle>{stop.ranking}</Typography.CardTitle>
            </StyledLeftBox>
          ) : null}

          <StyledMiddleBox>
            <Typography.CardTitle ellipsis>{stop.client?.label}</Typography.CardTitle>
            <Typography.BodySmall>
              {stop.client?.user?.firstName} - {stop.client?.user?.lastName}
            </Typography.BodySmall>
          </StyledMiddleBox>
          <StyledRightBox>
            {onClick && (
              <Icon
                type={Icons.Ionicons}
                name="chevron-forward-outline"
                size={theme.spacingUnit * 2}
                color={theme.colors['text-dark-3']}
              />
            )}

            <Typography.BodySmall colorName={stop.delivered ? 'color-primary' : 'text-dark-1'}>
              {stop.delivered
                ? 'Livré'
                : FormatUtils.capitalize(FormatUtils.formatDate(stop.estimate, 'Time'))}
            </Typography.BodySmall>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default StopCard
