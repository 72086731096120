export namespace CarrierFeesEnums {
  export enum FeeSharingRuleEnum {
    CARRIER_RESPONSABILITY = 1,
    PRODUCER_SHARED_RESPONSABILITY = 2,
  }

  export const CARRIER_FEES_RULES = [
    {
      label: 'Les producteurs-livreurs prennent le risque',
      value: FeeSharingRuleEnum.CARRIER_RESPONSABILITY,
      description: `Les producteurs-livreurs prennent le risque. Ils prélèvent une commission logistique qui couvre leurs frais à partir d'un certain montant transporté.`,
    },
    {
      label: 'Les producteurs prennent le risque',
      value: FeeSharingRuleEnum.PRODUCER_SHARED_RESPONSABILITY,
      description: `Les producteurs prennent le risque. Ils se répartissent les frais réels de la tournée en fonction du chiffre d'affaire réalisé.`,
    },
  ]

  export enum TruckTypeEnum {
    CAR = 0,
    SMALL_UTILITY_TRUCK = 10,
    UTILITY_TRUCK = 20,
    TRUCK = 30,
  }

  export const TRUCK_TYPES = [
    {
      label: 'Voiture (< 2m3)',
      value: TruckTypeEnum.CAR,
    },
    {
      label: 'Petit utilitaire (2 à 4m3)',
      value: TruckTypeEnum.SMALL_UTILITY_TRUCK,
    },
    {
      label: 'Utilitaire (4 à 7m3)',
      value: TruckTypeEnum.UTILITY_TRUCK,
    },
    {
      label: 'Camion (> 7m3)',
      value: TruckTypeEnum.TRUCK,
    },
  ]

  export enum FuelTypeEnum {
    GASOLINE = 10,
    DIESEL = 20,
    ELECTRIC = 30,
    LPG = 40,
  }

  export const FUEL_TYPES = [
    {
      label: 'Essence',
      value: FuelTypeEnum.GASOLINE,
    },
    {
      label: 'Diesel',
      value: FuelTypeEnum.DIESEL,
    },
    {
      label: 'Electrique',
      value: FuelTypeEnum.ELECTRIC,
    },
    {
      label: 'GPL',
      value: FuelTypeEnum.LPG,
    },
  ]
}

// Estimated fuel prices (per liter)
export const FUEL_PRICES_PER_LITER: Record<CarrierFeesEnums.FuelTypeEnum, number> = {
  [CarrierFeesEnums.FuelTypeEnum.GASOLINE]: 1.98,
  [CarrierFeesEnums.FuelTypeEnum.DIESEL]: 1.9,
  [CarrierFeesEnums.FuelTypeEnum.ELECTRIC]: 0.7, // Price per kWh for electric
  [CarrierFeesEnums.FuelTypeEnum.LPG]: 2.0,
}

// Estimated fuel efficiencies (liters per hundred kilometers)
export const FUEL_EFFICIENCIES = {
  [CarrierFeesEnums.TruckTypeEnum.CAR]: {
    [CarrierFeesEnums.FuelTypeEnum.GASOLINE]: 5,
    [CarrierFeesEnums.FuelTypeEnum.DIESEL]: 6,
    [CarrierFeesEnums.FuelTypeEnum.ELECTRIC]: 17, // Price per kWh for electric
    [CarrierFeesEnums.FuelTypeEnum.LPG]: 8,
  },
  [CarrierFeesEnums.TruckTypeEnum.SMALL_UTILITY_TRUCK]: {
    [CarrierFeesEnums.FuelTypeEnum.GASOLINE]: 8,
    [CarrierFeesEnums.FuelTypeEnum.DIESEL]: 10,
    [CarrierFeesEnums.FuelTypeEnum.ELECTRIC]: 20, // Price per kWh for electric
    [CarrierFeesEnums.FuelTypeEnum.LPG]: 15,
  },
  [CarrierFeesEnums.TruckTypeEnum.UTILITY_TRUCK]: {
    [CarrierFeesEnums.FuelTypeEnum.GASOLINE]: 12,
    [CarrierFeesEnums.FuelTypeEnum.DIESEL]: 14,
    [CarrierFeesEnums.FuelTypeEnum.ELECTRIC]: 30, // Price per kWh for electric
    [CarrierFeesEnums.FuelTypeEnum.LPG]: 20,
  },
  [CarrierFeesEnums.TruckTypeEnum.TRUCK]: {
    [CarrierFeesEnums.FuelTypeEnum.GASOLINE]: 17,
    [CarrierFeesEnums.FuelTypeEnum.DIESEL]: 20,
    [CarrierFeesEnums.FuelTypeEnum.ELECTRIC]: 40, // Price per kWh for electric
    [CarrierFeesEnums.FuelTypeEnum.LPG]: 30,
  },
}
