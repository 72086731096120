import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useQuery } from '@tanstack/react-query'
import { Button, CardPageLink, SectionTitle, Spacer, TextInput } from '../../../components'
import { Group, Pagination, Producer, ProducerGroup } from '../../../domain'
import i18n from '../../../i18n'
import ProducerCard from '../../../modules/Producer/ProducerCard'
import GroupCarrierService from '../../../services/carrier/group'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import Loader from '../../../components/Loader'
import CardListPaginated from '../../../components/CardListPaginated'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import { TextInputWrapper } from './CarrierGroupProducers.styles'

const CarrierGroupProducersScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [pageSizeProducerGroups, setPageSizeProducerGroups] = useState(5)
  const [pageNumberProducerGroups, setPageNumberProducerGroups] = useState(1)
  const [searchValue, setSearchValue] = useState('')

  const {
    data: groupData,
    refetch,
    isInitialLoading: feedLoading,
  } = useQuery<{ group: Group; stepsData: TutorialStepData[] }, Error>(
    ['sf_group'],
    () =>
      GroupCarrierService.getOneGroup(getAccessInfos().carrierGroupId, getAccessInfos().carrierId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: producerGroups,
    refetch: refetchProducerGroups,
    isLoading: isProducerGroupsLoading,
  } = useQuery<{ data: ProducerGroup[]; pagination: Pagination }, Error>(
    ['sf_group_producers'],
    () =>
      GroupCarrierService.getOneGroupProducers(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        pageSizeProducerGroups,
        pageNumberProducerGroups,
        searchValue,
      ),
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (!searchValue || searchValue.length > 2) {
      refetchProducerGroups()
    }
  }, [searchValue])

  const OnClickAddProducer = () => {
    if (!groupData?.group?.id) return
    navigation.navigate('CarrierAddProducer', { groupId: groupData?.group.id })
  }

  const onClickProducer = (producer: Producer) => {
    navigation.navigate('CarrierUpdateProducer', { id: producer.id })
  }

  if (isProducerGroupsLoading || !groupData?.group) {
    return <Loader isLoading pageLoading />
  }

  const onChangePage = async (pageNumber: number) => {
    await setPageNumberProducerGroups(pageNumber)
    refetchProducerGroups()
  }

  const onChangeSearchValue = async (newSearchValue: string) => {
    setSearchValue(newSearchValue)
    setPageNumberProducerGroups(1)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <SectionTitle
          title={`${FormatUtils.capitalize(i18n.t('producers'))} (${
            producerGroups?.pagination?.totalCount || 0
          })`}
          buttonRight={
            <Button
              small
              label={FormatUtils.capitalize(i18n.t('add'))}
              onPress={() => OnClickAddProducer()}
            />
          }
        />

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <TextInputWrapper>
          <TextInput
            field="text"
            label="Rechercher par nom"
            value={searchValue}
            onChangeText={onChangeSearchValue}
            small
          />
        </TextInputWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />

        <CardListPaginated
          data={producerGroups?.data}
          pagination={producerGroups?.pagination}
          element={function (producerGroup: any) {
            return (
              <ProducerCard
                key={producerGroup.id}
                producerGroup={producerGroup}
                group={groupData?.group}
                onClick={onClickProducer}
              />
            )
          }}
          isLoading={isProducerGroupsLoading}
          emptyMessage="Aucun producteur dans votre groupe"
          onChangePage={onChangePage}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <CardPageLink
          label={`Demandes d'ajout de nouveaux producteurs`}
          icon="arrow-right"
          onClick={() => navigation.navigate('CarrierGroupRequests', {})}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierGroupProducersScreen
