import React, { useEffect, useMemo, useRef } from 'react'
import { Animated, Easing, StyleSheet } from 'react-native'
import { useTheme } from 'styled-components/native'
import { fontSizes } from '../../../utilities/styling/theme/fontSizes'
import Icon, { Icons } from '../../Icon'
import { TextInputLabelProps } from './TextInputLabel.model'
import animatedStyles from './TextInputLabel.styles'

const ANIMATION_DURATION = 150
const TEXT_SCALE_MAX = 1.1

const TextInputLabel = ({
  label,
  editable,
  isActive,
  required,
  style,
  multiline,
  boldInputLabel,
}: TextInputLabelProps) => {
  const theme = useTheme()
  const textScale = useRef(new Animated.Value(isActive ? 1 : TEXT_SCALE_MAX)).current
  const textTranslateY = useRef(new Animated.Value(isActive ? -theme.spacingUnit * 2 : 0)).current
  const textColor = useMemo(
    () => theme.colors[isActive ? 'text-dark-1' : 'text-dark-1'],
    [theme, isActive],
  )

  useEffect(() => {
    if (isActive) {
      Animated.timing(textTranslateY, {
        toValue: -theme.spacingUnit * (multiline ? 7 : 2),
        duration: ANIMATION_DURATION,
        easing: Easing.linear,
        useNativeDriver: false,
      }).start()
      Animated.timing(textScale, {
        toValue: 1,
        duration: ANIMATION_DURATION,
        easing: Easing.linear,
        useNativeDriver: false,
      }).start()
    } else {
      Animated.timing(textTranslateY, {
        toValue: 0,
        duration: ANIMATION_DURATION,
        easing: Easing.linear,
        useNativeDriver: false,
      }).start()
      Animated.timing(textScale, {
        toValue: TEXT_SCALE_MAX,
        duration: ANIMATION_DURATION,
        easing: Easing.linear,
        useNativeDriver: false,
      }).start()
    }
  }, [isActive, textScale, textTranslateY, theme.spacingUnit, multiline])

  if (!label) {
    return null
  }

  return (
    <Animated.View
      style={[
        StyleSheet.absoluteFill,
        animatedStyles(theme).animatedTextContainer,
        style,
        {
          transform: [
            {
              translateY: textTranslateY,
            },
          ],
        },
      ]}
    >
      <Animated.Text
        style={[
          animatedStyles(theme, textColor).animatedText,
          {
            fontSize: textScale.interpolate({
              inputRange: [1, TEXT_SCALE_MAX],
              outputRange: [fontSizes.INPUT_LABEL, fontSizes.INPUT_LABEL * TEXT_SCALE_MAX],
            }),
            fontWeight: boldInputLabel ? '800' : '400',
          },
        ]}
      >
        {label}
        {required ? '*' : ''}{' '}
        {!editable ? (
          <Icon
            type={Icons.Ionicons}
            name="ios-lock-closed-outline"
            color={theme.colors['text-dark-1']}
            size={theme.fontSizes.INPUT_LABEL}
          />
        ) : (
          ''
        )}
      </Animated.Text>
    </Animated.View>
  )
}

export default TextInputLabel
