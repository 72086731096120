import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { CardPageLink, SectionTitle, Spacer } from '../../../components'
import PageTitle from '../../../components/PageTitle'
import { Group, User, UserCarrier } from '../../../domain'
import i18n from '../../../i18n'
import GroupCarrierService from '../../../services/carrier/group'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import CarrierGroupTopBox from './components/CarrierGroupTopBox'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import Loader from '../../../components/Loader'
import { GroupEnums } from '../../../../enums/group'
import ActionCard from '../../../modules/Common/ActionCard'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierGroupScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const {
    data: groupData,
    refetch,
    isInitialLoading: feedLoading,
  } = useQuery<{ group: Group; stepsData: TutorialStepData[] }, Error>(
    ['sf_group', getAccessInfos().carrierGroupId],
    () =>
      GroupCarrierService.getOneGroup(getAccessInfos().carrierGroupId, getAccessInfos().carrierId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: adminGroups,
    refetch: refetchAdminGroups,
    isInitialLoading: isAdminGroupsLoading,
  } = useQuery<User[], Error>(
    ['sf_group_admins'],
    () =>
      GroupCarrierService.getOneGroupAdmins(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
      ),
    {
      keepPreviousData: true,
    },
  )

  if (!groupData?.group || isAdminGroupsLoading) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title={FormatUtils.capitalize(i18n.t('pageTitleGroup'))} />
        <CarrierGroupTopBox group={groupData?.group} />
        <SectionTitle title={`Membres`} />
        {groupData?.group.groupRequestsPending &&
        groupData?.group.groupRequestsPending.length > 0 ? (
          <>
            <ActionCard
              title={`Nouvelle(s) demande(s) producteur`}
              description={`Vous avez ${FormatUtils.pluralizeAmountLabel(
                'demande',
                groupData?.group.groupRequestsPending.length,
              )} de producteur(s) souhaitant rejoindre le groupe`}
              onClick={() => navigation.navigate('CarrierGroupRequests', {})}
            />
          </>
        ) : undefined}
        <CardPageLink
          label={`${FormatUtils.capitalize(i18n.t('producers'))} (${
            groupData?.group?.nbProducers || 0
          })`}
          icon="arrow-right"
          onClick={() => navigation.navigate('CarrierGroupProducers', {})}
        />

        <CardPageLink
          label={`Clients (${groupData?.group?.nbClients || 0})`}
          icon="arrow-right"
          onClick={() => navigation.navigate('CarrierGroupClients', {})}
        />
        {groupData?.group.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS && (
          <CardPageLink
            label={`Producteurs-Livreurs (${groupData?.group?.nbCarriers || 0})`}
            icon="arrow-right"
            onClick={() => navigation.navigate('CarrierGroupCarriers', {})}
          />
        )}
        <CardPageLink
          label={`Utilisateurs du compte (${adminGroups?.length || 0})`}
          icon="arrow-right"
          onClick={() => navigation.navigate('CarrierGroupAdmins', {})}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <SectionTitle title={`Paramètres`} />
        <CardPageLink
          label={`Informations du groupe`}
          icon="arrow-right"
          onClick={() => navigation.navigate('CarrierUpdateGroup', {})}
        />
        <CardPageLink
          label={`Paramètres du groupe`}
          icon="arrow-right"
          onClick={() => navigation.navigate('CarrierUpdateGroupSettings', {})}
        />

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierGroupScreen
