import { Carrier } from '../../../domain'
import ApiService from '../../api.service'

const create = async (groupId: string, newCarrier: any): Promise<Carrier> => {
  const response = await ApiService.post(`carrier/group/${groupId}`, newCarrier).catch((error) => {
    throw error
  })
  return response
}

const update = async (carrierId: string, carrier: any): Promise<Carrier> => {
  const response = await ApiService.patch(`/carrier/${carrierId}`, carrier).catch((error) => {
    throw error
  })
  return response
}

const getOneCarrier = async (carrierId: string, requestedCarrierId?: string): Promise<Carrier> => {
  const response = await ApiService.get(`/carrier`, {
    id: carrierId,
    requestedCarrierId: requestedCarrierId,
  }).catch((error) => {
    throw error
  })
  return response
}

const findCarrierByEmail = async (email: string): Promise<Carrier> => {
  const response = await ApiService.get(`/carrier/email`, { email: email }).catch((error) => {
    throw error
  })
  return response
}

const linkCarrier = async (carrierId: string, groupId?: string): Promise<Carrier> => {
  const response = await ApiService.patch(
    `/carrier/group/${groupId}/carrier/${carrierId}`,
    {},
  ).catch((error) => {
    throw error
  })
  return response
}

const CarrierCarrierService = {
  create,
  update,
  getOneCarrier,
  findCarrierByEmail,
  linkCarrier,
}

export default CarrierCarrierService
