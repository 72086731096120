import styled, { css } from 'styled-components/native'

const GalleryImageWrapper = styled.View<{ isMobile?: boolean }>`
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacingUnit * 0.5}px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      justify-content: center;
    `}
`

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
`

const StyledCardContent = styled.View`
  width: 100%;
`

const DynamicImageWrapper = styled.View`
  width: 100%;
  max-width: 80px;
  height: 80px;
`

const DynamicImageGalleryWrapper = styled.View`
  width: 48%;
  max-width: 100px;
  height: 80px;
`

const TextWrapper = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 0.6}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 0.4}px;
`

export {
  GalleryImageWrapper,
  StyledCardWrapper,
  StyledCardContent,
  DynamicImageWrapper,
  TextWrapper,
  DynamicImageGalleryWrapper,
}
