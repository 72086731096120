import React, { useCallback, useMemo } from 'react'
import { useTheme } from 'styled-components/native'
import Icon, { Icons } from '../../../Icon'
import Spacer from '../../../Spacer'
import Typography from '../../../Typography'
import { ListItemProps } from './ListItem.model'
import { StyledTickIconWrapper, StyledTouchableHighlight } from './ListItem.styles'

const ListItem = ({
  item,
  label,
  value,
  parent,
  selectable,
  disabled,
  isSelected,
  IconComponent,
  TickIconComponent,
  categorySelectable,
  onPress,
  setPosition,
  zIndex,
}: ListItemProps) => {
  const theme = useTheme()
  const ItemIcon = useMemo(() => {
    return item.iconName ? (
      <>
        <Icon type={Icons.Ionicons} name={item.iconName} />
        <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
      </>
    ) : (
      IconComponent
    )
  }, [item, IconComponent])

  const TickIcon = useMemo(
    () => isSelected && <TickIconComponent />,
    [isSelected, TickIconComponent],
  )

  const handlePress = useCallback(() => {
    if (parent === null && !categorySelectable && selectable !== true) {
      return
    }

    onPress(item)
  }, [parent, categorySelectable, selectable, onPress, item])

  const onLayout = useCallback(
    (layoutEvent: any) => {
      setPosition(value, layoutEvent.nativeEvent.layout.y)
    },
    [setPosition, value],
  )

  const textStyle = useMemo(() => {
    return {
      ...(item.options?.bold ? { fontFamily: 'Inter-SemiBold' } : {}),
    }
  }, [item.options?.bold])

  return (
    <StyledTouchableHighlight
      underlayColor={theme.colors['color-border']}
      isSelected={isSelected}
      onPress={handlePress}
      onLayout={onLayout}
      disabled={selectable === false || disabled}
      testID={item.testID}
      withDivider={item.options?.withDivider}
      key={item.key || item.id}
      zIndex={zIndex}
    >
      <>
        {ItemIcon}
        <Typography.Body style={textStyle}>{label}</Typography.Body>
        <StyledTickIconWrapper>{TickIcon}</StyledTickIconWrapper>
      </>
    </StyledTouchableHighlight>
  )
}

export default ListItem
