import React from 'react'
import { useTheme } from 'styled-components/native'
import { TutorialStepCardProps } from './TutorialStepCard.model'
import {
  StyledRightBox,
  StyledStepCard,
  StyledStepCardContent,
  StyledStepCardRanking,
  StyledLeftBox,
  StyledStepCardWrapper,
} from './TutorialStepCard.styles'
import Typography from '../Typography'

const OrderedCard = ({ ranking, title, description, children }: TutorialStepCardProps) => {
  const theme = useTheme()
  return (
    <StyledStepCardWrapper>
      <StyledStepCard completed={true}>
        <StyledLeftBox completed={true}>
          <StyledStepCardRanking>
            <Typography.Body colorName={'text-light-1'}>{ranking}</Typography.Body>
          </StyledStepCardRanking>
        </StyledLeftBox>
        <StyledRightBox>
          <StyledStepCardContent>
            <Typography.CardTitle colorName={'color-primary'} bold>
              {title}
            </Typography.CardTitle>
            <Typography.BodySmall colorName={'text-dark-3'}>{description}</Typography.BodySmall>
          </StyledStepCardContent>
          <>{children !== undefined && children}</>
        </StyledRightBox>
      </StyledStepCard>
    </StyledStepCardWrapper>
  )
}

export default OrderedCard
