import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Col,
  Dropdown,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import DatePicker from '../../../components/DatePicker'
import TextInput from '../../../components/inputs/TextInput'
import { AddPriceReporting, City } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierAddPriceReporting.styles'
import { queryClient } from '../../../utilities/queryClient'
import GeneralUtils from '../../../utilities/utils/general'
import { useQuery } from '@tanstack/react-query'
import CityProducerService from '../../../services/producer/city'
import PriceReportingCarrierService from '../../../services/carrier/priceReporting'
import CitiesForm from '../../../modules/Common/CitiesForm'

const CarrierAddPriceReportingScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCities, setSelectedCities] = useState<City[]>([])

  const defaultDateHour = new Date()
  defaultDateHour.setUTCHours(-2)
  defaultDateHour.setUTCMinutes(0)

  const theme = useTheme()
  const [newPriceReporting, setNewpriceReporting] = useState<AddPriceReporting>({
    label: '',
    validityStart: defaultDateHour,
    validityEnd: defaultDateHour,
    cityId: undefined,
    cityIds: [],
    defaultDateHour: defaultDateHour,
  })
  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newPriceReporting)) return null

    const tourTemp = Object.assign({}, newPriceReporting)

    tourTemp[param as keyof typeof newPriceReporting] = value
    setNewpriceReporting(tourTemp)
  }

  const onChangeDateStart = (value: any) => {
    setErrorMessage('')

    const tourTemp = Object.assign({}, newPriceReporting)
    tourTemp.validityStart = value

    setNewpriceReporting(tourTemp)
  }

  const onChangeDateEnd = (value: any) => {
    setErrorMessage('')

    const tourTemp = Object.assign({}, newPriceReporting)
    tourTemp.validityEnd = value

    setNewpriceReporting(tourTemp)
  }

  const onAddPriceReporting = async () => {
    setErrorMessage('')
    if (
      GeneralUtils.isDateBefore(
        new Date(newPriceReporting.validityEnd || ''),
        new Date(newPriceReporting.validityStart || ''),
      )
    ) {
      setErrorMessage("L'heure de début doit être avant celle de fin")
      return false
    }

    const cityIds: string[] = []
    selectedCities.map((city) => {
      cityIds.push(city.id)
    })

    setIsLoading(true)
    const priceReporting = {
      label: newPriceReporting.label,
      validityStart: newPriceReporting.validityStart,
      validityEnd: newPriceReporting.validityEnd,
      cityIds: cityIds,
    }

    await PriceReportingCarrierService.create(getAccessInfos().carrierGroupId, priceReporting)
      .then((priceReporting) => {
        queryClient.fetchQuery(['sf_price_reportings'])
        navigation.popToTop()
        navigation.navigate('CarrierPriceReporting', { id: priceReporting.id })
        setIsLoading(false)
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la création du relevé')
        setIsLoading(false)
      })
  }

  const onClickDuplicatePriceReporting = () => {
    navigation.navigate('CarrierDuplicatePriceReporting', {})
  }

  const onChangeSelectedCities = (cities: City[]) => {
    setSelectedCities([...cities])
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Créer un nouveau relevé de prix" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Row>
            <Col xs={8}>
              <Typography.Body colorName="text-dark-3">
                Vous souhaitez dupliquer un relevé de prix existant ?{' '}
              </Typography.Body>
            </Col>
            <Col xs={4}>
              <Button
                small
                label={'Dupliquer un relevé'}
                colorName="color-grey"
                onPress={() => onClickDuplicatePriceReporting()}
              />
            </Col>
          </Row>

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <SectionTitle title={`Villes ciblés par ce relevé`} />
          <CitiesForm selectedCities={selectedCities} onChangeCities={onChangeSelectedCities} />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="startdate"
                label="Date début de validité"
                mode="date"
                onChange={onChangeDateStart}
                value={newPriceReporting.validityStart}
                hidden={selectedCities.length < 1}
              />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="enddate"
                label="Fin de validité"
                mode="date"
                onChange={onChangeDateEnd}
                value={newPriceReporting.validityEnd}
                hidden={newPriceReporting.validityStart === newPriceReporting.defaultDateHour}
              />
            </Col>
          </Row>

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Row>
            <Col xs={12}>
              <TextInput
                value={newPriceReporting.label}
                label="Libellé"
                field="text"
                onChangeText={(text) => setValue(text, 'label')}
                hidden={newPriceReporting.validityEnd === newPriceReporting.defaultDateHour}
              />
            </Col>
          </Row>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            {newPriceReporting.label && (
              <Button
                label={FormatUtils.capitalize(i18n.t('add'))}
                onPress={() => onAddPriceReporting()}
                loading={isLoading}
              />
            )}
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierAddPriceReportingScreen
