import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Col,
  Dropdown,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import Loader from '../../../components/Loader'
import { Tour, UpdateTour } from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../services/carrier/tour'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierConcludeTour.styles'
import { CarrierFeesEnums } from '../../../../enums/carrierFees'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const updatedTourTemp: UpdateTour = {
  id: '',
  carrierExpenses: '',
}

const CarrierConcludeTourScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const navigate = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierConcludeTour'>>()
  const { id, origin } = route?.params ?? { id: '', origin: '' }
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCity, setSelectedCity] = useState<any>()
  const [selectedDeliveryProducer, setSelectedDeliveryProducer] = useState<any>()
  const [selectedCarrierFeesSharingRule, setSelectedCarrierFeesSharingRule] = useState<any>()
  const [updatedTour, setUpdatedTour] = useState<UpdateTour>(updatedTourTemp)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()

  const { data: oldTour, isInitialLoading: oldTourLoading } = TourCarrierService.getOne.query(id)

  useEffect(() => {
    if (oldTour?.carrier?.id) {
      setSelectedDeliveryProducer({
        ...oldTour?.carrier,
        value: oldTour?.carrier?.id,
      })
    }

    const type = CarrierFeesEnums.CARRIER_FEES_RULES.find(
      (cfr: any) => cfr.value === oldTour?.carrierFeesSharingRule,
    )

    if (type) {
      setSelectedCarrierFeesSharingRule({
        ...type,
      })
    } else {
      setSelectedCarrierFeesSharingRule({
        label: 'Choisir une option',
        value: 0,
      })
    }
  }, [oldTour])

  if (oldTourLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!oldTour) return <></>

  const setValue = (value: any, param: string) => {
    if (!updatedTour) return null
    setErrorMessage('')
    if (!param && !(param in updatedTour)) return null

    const tourTemp = Object.assign({}, updatedTour)

    tourTemp[param as keyof typeof updatedTour] = value
    setUpdatedTour(tourTemp)
  }

  const onConcludeTour = async () => {
    setErrorMessage('')
    if (
      (!updatedTour?.carrierExpenses && updatedTour?.carrierExpenses !== '0') ||
      !selectedDeliveryProducer?.id ||
      !selectedCarrierFeesSharingRule.value
    ) {
      setErrorMessage('Veuillez compléter tous les champs')
      return false
    }
    setIsLoading(true)

    const tour = {
      carrierExpenses: FormatUtils.stringToFloat(updatedTour?.carrierExpenses),
      carrierId: selectedDeliveryProducer.id,
      carrierFeesSharingRule: selectedCarrierFeesSharingRule.value,
    }

    const concludedTourTemp = await TourCarrierService.conclude(oldTour?.id, tour)
    if (concludedTourTemp && concludedTourTemp.id) {
      TourCarrierService.getOne.invalidate(id)
      queryClient.invalidateQueries({
        queryKey: ['sf_tours_to_conclude', oldTour?.carrier.id],
      })
      queryClient.invalidateQueries({
        queryKey: ['sf_billing_carrier_fees', getAccessInfos().carrierGroupId],
      })

      if (origin === 'CarrierConcludeTours') {
        navigate.navigate('CarrierBilling', {
          screen: 'CarrierConcludeTours',
          params: {},
        })
      } else {
        navigation.navigate('CarrierTour', { id: id })
      }
    } else {
      setErrorMessage('Un problème est survenu lors de la clôture de la tournée')
    }
    setIsLoading(false)
  }

  const getTourIdFormatted = () => {
    if (oldTour && oldTour?.id && oldTour?.id.length > 5) {
      return oldTour?.id.slice(-5)
    }
    return 'NA'
  }

  const onChangeDeliveryProducer = (value: any) => {
    setSelectedDeliveryProducer(value)
  }

  const onChangeCarrierFeesSharingRule = (value: any) => {
    setSelectedCarrierFeesSharingRule(value)
  }

  const currentCarrierFeesSharingRuleDescription = () => {
    const rule = CarrierFeesEnums.CARRIER_FEES_RULES.find(
      (cfr: any) => cfr.value === selectedCarrierFeesSharingRule?.value,
    )
    if (rule && rule.description) {
      return rule.description
    }
    return ''
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title={`Clôturer la tournée #${getTourIdFormatted()}`} />
          <ScrollableFormWrapper>
            <Typography.Body colorName="text-dark-3">
              Une fois la tournée livrée, les différents retours clients pris en compte, vous pouvez
              la clôturer afin d'inscrire les frais de logistique dans la balance. Toute
              modification sera alors impossible.
            </Typography.Body>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <Dropdown
              label={'Producteur-livreur'}
              onChange={onChangeDeliveryProducer}
              multiple={false}
              defaultValue={selectedDeliveryProducer?.id}
              optionsDefault={
                !oldTour?.group?.carrierGroups
                  ? []
                  : oldTour?.group.carrierGroups.map((cg) => ({
                      ...cg.carrier,
                      label: cg?.carrier?.name,
                      value: cg?.carrier?.id,
                    }))
              }
              zIndex={1000}
              zIndexInverse={4000}
              itemKey="carrier"
            />
            <SectionTitle title={`Validez le coût réel de la tournée`} />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <Row>
              <Col xs={12} sm={6}>
                <TextInput
                  value={updatedTour?.carrierExpenses?.toString()}
                  label="Coût réel (en €)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'carrierExpenses')}
                />
              </Col>
            </Row>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <SectionTitle title={`Répartition des frais`} />
            <Dropdown
              label={'Choisir une règle'}
              onChange={onChangeCarrierFeesSharingRule}
              multiple={false}
              defaultValue={selectedCarrierFeesSharingRule?.value}
              optionsDefault={CarrierFeesEnums.CARRIER_FEES_RULES.map((cfr) => ({
                label: cfr.label,
                value: cfr.value,
              }))}
              zIndex={1000}
              zIndexInverse={4000}
              itemKey="carrierFeesRule"
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            {currentCarrierFeesSharingRuleDescription() && (
              <Typography.BodySmall colorName="text-dark-3">
                {currentCarrierFeesSharingRuleDescription()}
              </Typography.BodySmall>
            )}

            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Button
              label={'Cloturer'}
              onPress={() => onConcludeTour()}
              loading={isLoading && false}
            />
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierConcludeTourScreen
