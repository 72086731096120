import { AddCartBatchCarrier, Cart, Catalog, Tour } from '../../../domain'
import React, { useEffect, useState } from 'react'
import { UserEnums } from '../../../../enums'
import Loader from '../../../components/Loader'
import { useQuery } from '@tanstack/react-query'
import TourTopBox from '../../../components/TourTopBox'
import BatchCardNew from '../../../components/BatchCardNew'
import { queryClient } from '../../../utilities/queryClient'
import PaginationBar from '../../../components/PaginationBar'
import { StackNavigationProp } from '@react-navigation/stack'
import TourCarrierService, { TourAndStepsData } from '../../../services/carrier/tour'
import CartCarrierService from '../../../services/carrier/cart'
import BatchCarrierService from '../../../services/carrier/batch'
import { Button, SectionTitle, Spacer, TextInput } from '../../../components'
import { StyledButtonWrapper, TextInputWrapper } from './CarrierAddCartBatchV2.styles'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierAddCartBatchScreenV2 = () => {
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierAddCartBatchV2'>>()
  const [isLoading, setIsLoading] = useState(false)
  const [pageSizeBatchs, setPageSizeBatchs] = useState(6)
  const [pageNumberBatchs, setPageNumberBatchs] = useState(1)
  const [searchValue, setSearchValue] = useState('')

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [cartBatch, setCartBatch] = useState<AddCartBatchCarrier>({
    batchId: undefined,
    clientId: undefined,
    packingQuantity: undefined,
    packing: undefined,
    totalQuantity: undefined,
    unitPrice: undefined,
    unitPriceProducer: undefined,
    batch: undefined,
    carrierFees: undefined,
    otherBatchs: undefined,
  })

  const { cartId, clientId, tourId } = route?.params ?? {
    cartId: '',
    clientId: '',
    tourId: '',
  }

  const { data: tour, isInitialLoading: tourLoading } = TourCarrierService.getOne.query(tourId)

  const {
    data: cart,
    refetch: refetchCart,
    isInitialLoading: cartLoading,
  } = useQuery<Cart | null, Error>(['sf_cart'], () => CartCarrierService.getOneCart(cartId), {
    keepPreviousData: true,
    enabled: cartId !== undefined,
  })

  const {
    data: catalog,
    refetch: refetchBatchs,
    isLoading: batchsLoading,
    isRefetching: batchsRefetching,
  } = useQuery<Catalog, Error>(
    ['sf_catalog'],
    () =>
      BatchCarrierService.getAllBatchs(
        cart?.tour?.id,
        cart?.id,
        pageSizeBatchs,
        pageNumberBatchs,
        undefined,
        producers,
        searchValue,
      ),
    {
      enabled: cart?.tour?.id !== undefined && cart?.id !== undefined,
    },
  )

  const producers = tour?.producerTour?.map((pt) => pt.producer.id)
  useEffect(() => {
    if (!searchValue || searchValue.length > 2) {
      refetchBatchs()
    }
  }, [searchValue])

  if (cartLoading || !cartBatch || tourLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!cart || !cartBatch || !tour) return null

  const onChangePage = async (pageNumber: number) => {
    await setPageNumberBatchs(pageNumber)
    refetchBatchs()
  }

  const onValidateAddBatchs = async () => {
    if (!cart || !cart.id) return
    await queryClient.refetchQueries(['sf_cart'])
    navigation.goBack()
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <TourTopBox tour={tour} cart={cart} userRole={UserEnums.RoleEnums.CARRIER} />
      <ContentWrapper marginTopLarge>
        <Spacer fixedSize={50} axis={Spacer.AxisEnum.VERTICAL} />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
        <SectionTitle title={'Ajoutez des produits à la commande de ce client'} />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <TextInputWrapper>
          <TextInput
            field="text"
            label="Rechercher par nom"
            value={searchValue}
            onChangeText={setSearchValue}
            small
          />
        </TextInputWrapper>
        {catalog?.batchs?.map((batch) => (
          <BatchCardNew
            key={batch.id}
            batch={batch}
            cart={cart}
            displayClientPrice
            displayProducerLabel
          />
        ))}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <PaginationBar
          data={catalog?.batchs}
          pagination={batchsLoading ? undefined : catalog?.pagination}
          onChangePage={onChangePage}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <StyledButtonWrapper>
          <Button
            label="Confirmer l'ajout"
            onPress={() => onValidateAddBatchs()}
            loading={isLoading}
          />
        </StyledButtonWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierAddCartBatchScreenV2
