import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, Typography } from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import {
  LeftBox,
  ProductNameBox,
  RightBox,
  StyledCardContent,
  StyledCardWrapper,
  StyledImage,
  StyledValueBox,
  StyledValuesWrapper,
} from './CartBatchCard.styles'
import { Alert } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import DeviceUtil from '../../../utilities/utils/device'
import {
  Cart,
  CartBatch,
  CartValidationRuleName,
  CatalogExtended,
  Product,
  UdpateCartBatch,
} from '../../../domain'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import CartClientService from '../../../services/client/cart'
import { queryClient } from '../../../utilities/queryClient'
import DirectusUtil from '../../../utilities/utils/directus'
import { ProducerTourExtended } from '../../../services/carrier/producerTour'
import CartUtil from '../../../utilities/utils/cart'

export interface CartBatchCardProps {
  cartBatch: CartBatch
  catalog?: CatalogExtended
  cart: Cart
  editable?: boolean
  producerTour: ProducerTourExtended
  producerCartBatchs?: CartBatch[]
}

const CartBatchCard = ({
  cartBatch,
  cart,
  producerTour,
  editable = true,
  producerCartBatchs,
}: CartBatchCardProps) => {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { getAccessInfos } = useAuthenticationContext()
  const [isNotALlowedToDecreaseQuantity, setIiNotALlowedToDecreaseQuantity] =
    useState<boolean>(false)

  const isNotAllowedToEditRelatedToOrderEndDate = !editable

  useEffect(() => {
    const producerTotalHT = CartUtil.calculateSubtotal(producerCartBatchs)

    const isNotALlowedToDecreaseQuantityComputed = !!(
      cart.targetCart &&
      producerTour.minOrderValueHT &&
      producerTour.minOrderValueHT >
        (producerTotalHT || 0) - cartBatch.unitPrice! * cartBatch.batch?.product?.packing!
    )

    setIiNotALlowedToDecreaseQuantity(isNotALlowedToDecreaseQuantityComputed)
  }, [producerCartBatchs, cartBatch])

  const batch = cartBatch.batch

  const cartValidationQueryKey = CartClientService.useValidateCart(
    cart?.id,
    CartValidationRuleName.MinOrder,
  ).getQueryKey()

  if (!cartBatch || !batch) return null

  const onUpdateCartBatch = async (changeQuatity: number) => {
    if (isLoading || !cartBatch || !batch.product?.packing) return
    if (changeQuatity > 0 && !batchIsAvailable()) {
      Alert.alert(`Il ne reste plus suffisament de stock disponible de ${batch.product.label}`)
      return
    }
    setIsLoading(true)

    let newQuantity = (cartBatch.packingQuantity || 0) + changeQuatity
    newQuantity = newQuantity < 0 ? 0 : newQuantity

    const newTotalQuantity = batch.product?.packing * newQuantity

    const newCartbatch: UdpateCartBatch = {
      batchId: batch.id,
      clientId: getAccessInfos().clientId,
      packingQuantity: newQuantity,
      packing: batch.product?.packing,
      totalQuantity: newTotalQuantity,
      unitPrice: cartBatch.unitPrice,
      carrierFees: cartBatch.carrierFees,
      otherBatchs: cartBatch.otherBatchs,
      unitPriceProducer: cartBatch.unitPriceProducer,
    }

    const updateResponse = await CartClientService.updateCartBatch(cart.id, newCartbatch)
    if (updateResponse.id) {
      cartBatch.packingQuantity = newQuantity
      cartBatch.totalQuantity = newTotalQuantity
    }
    queryClient.invalidateQueries(['c_cart', cart.id])

    queryClient.invalidateQueries(cartValidationQueryKey)
    setIsLoading(false)
  }

  const batchIsAvailable = () => {
    if (
      batch?.availableQuantity &&
      batch.product?.packing &&
      batch?.availableQuantity > batch.product?.packing
    ) {
      const availableQuantity = batch?.availableQuantity - (cartBatch.totalQuantity || 0)

      return availableQuantity > 0
    }
    return false
  }

  const isMobileScreen = DeviceUtil.isMobileApp()

  return (
    <StyledCardWrapper>
      <StyledCardContent>
        <LeftBox>
          <StyledImage
            source={DirectusUtil.getProductImageUrlFromId(
              cartBatch.batch?.product as any as Product,
            )}
            resizeMode="cover"
          />
          <Spacer size={1.7} axis={Spacer.AxisEnum.HORIZONTAL} />
          <ProductNameBox>
            <Typography.Body bold ellipsis>
              {cartBatch.batch?.product?.label}
            </Typography.Body>
            <Typography.Body colorName={'text-dark-1'} ellipsis>
              par{' '}
              {FormatUtils.formatQuantity(cartBatch?.packing, cartBatch.batch?.product?.mesureType)}
              {batch.product?.typeInfo ? ` - ${batch.product?.typeInfo}` : ''}
            </Typography.Body>
          </ProductNameBox>
        </LeftBox>
        <RightBox>
          {!isMobileScreen && (
            <>
              <Typography.Body colorName={'text-dark-1'}>
                {FormatUtils.formatPrice(
                  cartBatch.unitPrice,
                  'ht',
                  cartBatch.batch?.product?.producer,
                )}
                /{FormatUtils.formatUnity(cartBatch.batch?.product?.mesureType)}
              </Typography.Body>
              <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
            </>
          )}

          <StyledValuesWrapper>
            <StyledValueBox>
              <TouchableOpacity
                onPress={() => onUpdateCartBatch(-1)}
                disabled={
                  isLoading ||
                  isNotAllowedToEditRelatedToOrderEndDate ||
                  isNotALlowedToDecreaseQuantity
                }
                style={{
                  opacity:
                    !isNotAllowedToEditRelatedToOrderEndDate && !isNotALlowedToDecreaseQuantity
                      ? 1
                      : 0.5,
                }}
              >
                <Icon
                  type={Icons.Ionicons}
                  name={
                    (cartBatch?.totalQuantity || 0) <= (batch.product?.packing || 0)
                      ? 'trash'
                      : 'remove-circle'
                  }
                  size={
                    theme.spacingUnit *
                    ((cartBatch?.totalQuantity || 0) <= (batch.product?.packing || 0) ? 3 : 3.5)
                  }
                  color={
                    !isNotAllowedToEditRelatedToOrderEndDate && !isNotALlowedToDecreaseQuantity
                      ? theme.colors['color-primary']
                      : theme.colors['color-grey']
                  }
                />
              </TouchableOpacity>
            </StyledValueBox>
            <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            <StyledValueBox>
              <Typography.Body semiBold colorName={'color-primary'}>
                {`${FormatUtils.formatQuantity(
                  cartBatch?.totalQuantity,
                  batch.product?.mesureType,
                )}`}
              </Typography.Body>
              {!isMobileScreen ? (
                <Typography.Body semiBold colorName={'color-primary'}>
                  ({`${cartBatch.packingQuantity} colis`})
                </Typography.Body>
              ) : null}
            </StyledValueBox>
            <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            <StyledValueBox>
              <TouchableOpacity
                onPress={() => onUpdateCartBatch(1)}
                disabled={
                  isLoading || !batchIsAvailable() || isNotAllowedToEditRelatedToOrderEndDate
                }
                style={{
                  opacity: batchIsAvailable() && !isNotAllowedToEditRelatedToOrderEndDate ? 1 : 0.5,
                }}
              >
                <Icon
                  type={Icons.Ionicons}
                  name="add-circle"
                  size={theme.spacingUnit * 3.5}
                  color={
                    !batchIsAvailable()
                      ? theme.colors['text-dark-3']
                      : theme.colors['color-primary']
                  }
                />
              </TouchableOpacity>
            </StyledValueBox>
          </StyledValuesWrapper>

          <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
          <Typography.Body colorName={'text-dark-1'}>
            {FormatUtils.formatPrice(
              (cartBatch.unitPrice || 0) * (cartBatch.totalQuantity || 0),
              'ht',
              cartBatch.batch?.product?.producer,
            )}
          </Typography.Body>
        </RightBox>
      </StyledCardContent>
    </StyledCardWrapper>
  )
}

export default CartBatchCard
