export namespace BatchEnums {
  export enum TYPE {
    DAY = 1,
    WEEK = 2,
    MONTH = 3,
  }

  export enum BatchStatusMessageTypeEnum {
    MISSING_PRICE_STATEMENT = 10,
  }

  export enum BatchStatusEnum {
    ONGOING = 0,
    CONCLUDED = 5,
  }
}

export const BATCH_TYPES = [
  {
    label: 'Extra frais (1 journée)',
    value: BatchEnums.TYPE.DAY,
  },
  {
    label: 'Frais (1 semaine maximum)',
    value: BatchEnums.TYPE.WEEK,
  },
  {
    label: "Stock (Plus d'une semaine)",
    value: BatchEnums.TYPE.MONTH,
  },
]
