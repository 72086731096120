import styled from 'styled-components/native'

const StyledUserContentWrapper = styled.View`
  flex: 1;
  width: 100%;
`

const StyledAvatarUpdateWrapper = styled.View`
  align-items: center;
  justify-content: center;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

export { StyledUserContentWrapper, StyledAvatarUpdateWrapper, StyledButtonWrapper }
