import { Producer, Tour } from '../../../domain'
import { Category } from '../../../domain/Category'
import ApiService from '../../api.service'

const getAllTours = async (clientId: string): Promise<Tour[]> => {
  const response = await ApiService.get(`/tour/client/${clientId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getTourCategories = async (
  idClient: string,
  idTour: string | undefined,
): Promise<{ categories: Category[]; producers: Producer[] }> => {
  if (!idTour) return { categories: [], producers: [] }
  const response = await ApiService.get(`/tour/${idTour}/client/${idClient}/category_producers`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })

  return response
}

const TourClientService = {
  getAllTours,
  getTourCategories,
}

export default TourClientService
