import styled from 'styled-components/native'

const ToursContentWrapper = styled.View`
  flex: 1;
  width: 95%;
  margin: ${({ theme }) => theme.spacingUnit * 1}px;
  margin-top: 0;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`
const StyledModeSwitchWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacingUnit * 5}px;
`

const TextInputWrapper = styled.View`
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
`

export { ToursContentWrapper, StyledButtonWrapper, StyledModeSwitchWrapper, TextInputWrapper }
