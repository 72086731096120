import styled, { css } from 'styled-components/native'
import { InterfaceColors } from '../../utilities/styling/theme/theme'

const StyledTouchableCard = styled.View<{
  hasGreenBorder?: boolean
  backgroundColor?: keyof InterfaceColors
}>`
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors && theme.colors[backgroundColor || 'background-3']};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  // border-width: 1px;
  border-color: ${({ theme }) => theme.colors && theme.colors['color-border']};

  ${({ hasGreenBorder }) =>
    hasGreenBorder &&
    css`
      border: 2px solid ${({ theme }) => theme.colors['color-primary']};
    `}
`

export default StyledTouchableCard
