import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledButtonWrapper = styled.View`
  align-items: center;
  justify-content: center;
`

const CardTitleWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const CertificatesWrapper = styled.View`
  width: 100%;
`
export { CardTitleWrapper, StyledButtonWrapper, CertificatesWrapper }
