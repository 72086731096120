import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import ClientOrderScreen from '../../../screens/Client/ClientOrder'

import ClientOrdersScreen from '../../../screens/Client/ClientOrders'
import Header from '../../components/Header'
import HeaderHome from '../../components/HeaderHome'
import ClientPrintOrderRecapScreen from '../../../screens/Client/ClientPrintOrderRecap'
import ClientEditShelfLabelScreen from '../../../screens/Client/ClientEditShelfLabel'

const Stack = createStackNavigator()

const OrderClientNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="ClientOrders"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ClientOrders"
        component={ClientOrdersScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Commandes',
        }}
      />
      <Stack.Screen
        name="ClientOrder"
        component={ClientOrderScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ClientOrders" />,
          title: 'Commande',
        }}
      />
      <Stack.Screen
        name="ClientPrintOrderRecap"
        component={ClientPrintOrderRecapScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ClientOrders" />,
          title: 'Bon de commande',
        }}
      />

      <Stack.Screen
        name="ClientEditShelfLabel"
        component={ClientEditShelfLabelScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ClientOrders" />,
          title: 'Modifier Étiquette',
        }}
      />
    </Stack.Navigator>
  )
}

export default OrderClientNavigation
