import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Card, PageTitle, Spacer, Typography } from '../../../components'
import Loader from '../../../components/Loader'
import { CartBatch, Tour } from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../services/carrier/tour'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierTourPrintSticker.styles'
import FormatUtils from '../../../utilities/utils/format'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierTourPrintStickerScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourPrintSticker'>>()
  const { id, idProducer } = route?.params ?? { id: '', idProducer: '' }
  const [isLoading, setIsLoading] = useState(false)

  const theme = useTheme()

  const {
    data: tour,
    refetch: refetchOldTour,
    isInitialLoading: tourLoading,
  } = TourCarrierService.getOne.query(id)

  const {
    data: tourBatchs,
    refetch: refetchTourBatchs,
    isInitialLoading: tourBatchsLoading,
  } = useQuery<Tour, Error>(['sf_tour_batchs', id], () => TourCarrierService.getOneTourBatchs(id), {
    keepPreviousData: true,
  })

  const {
    data: tourCartBatchs,
    refetch: refetchTourCartBatchs,
    isInitialLoading: tourCartBatchsLoading,
  } = useQuery<CartBatch[], Error>(
    ['sf_tour_cart_batchs', id],
    () => TourCarrierService.getOneTourCartBatchs(id),
    {
      keepPreviousData: true,
    },
  )

  if (tourLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour) return null

  const producer = (tour.producerTour || []).find(
    (producerTour) => producerTour?.producer?.id === idProducer,
  )?.producer

  if (!producer) return null

  const producerBatchs =
    tourBatchs?.batchs?.filter((b) => b.product?.producer?.id === producer.id) || []

  const [errorMessage, setErrorMessage] = useState<string>('')

  /*
  const [selectMail, setSelectedMail] = useState<string>('')

  const onSendMail = async () => {
    setErrorMessage('')
    setIsLoading(true)

    const dto = {
      mailType: type,
      mails: selectMail ? [selectMail] : undefined,
    }

    const sendedMailTour = await TourCarrierService.sendMail(tour.id, dto)
    if (sendedMailTour && sendedMailTour.id) {
      await queryClient.invalidateQueries({ queryKey: ['sf_tour', id] })

      navigation.navigate('CarrierTour', { id: tour.id })
    } else {
      setErrorMessage(`Un problème est survenu lors de l'envoie du mail pour cette tournée`)
    }
    setIsLoading(false)
  }

  const mailSend = (mailType: MailEnums): boolean => {
    let isSend = false
    const history = tour.mailsStatus?.history
    if (history && history.length) {
      const foundHistory = history.find((h) => h.type === mailType)
      isSend = foundHistory !== null
    }

    return isSend
  }
  */

  /*
<div className='print-container' style={{ margin: '0', padding: '0' }}>
          <div style={{ pageBreakAfter: 'always' }} className='page-break'>
            <div style={{ width: '56mm', paddingBottom: '20mm', pageBreakAfter: 'always' }}>
              <div style={{ textAlign: 'center', paddingLeft: '0.7mm' }}>
                <div style={{ textAlign: 'center', width: '56mm' }}>
                  <h6 style={{ margin: 0, marginTop: '0.5mm', padding: 0, fontSize: '4mm', fontWeight: 'bold' }}>{tourData_label}</h6>
                  <h6 style={{ margin: 0, marginTop: '0.5mm', padding: 0, fontSize: '4mm', fontWeight: 'bold' }}>{(new Date()).toLocaleDateString()}</h6>
                </div>
                <table style={{ margin: 0 }}>
                  {batchs.map(b => {
                    if (b.batch.location_id && b.batch.location && b.batch.location.location_ranking > locationMaxRank) {
                      return false;
                    }
                    return (
                      <tr style={{ borderBottom: '1px solid black' }}>
                        <td style={{ width: '18mm', fontSize: '3mm' }}>
                          {Facade.formatQuantityTicket(b.quantity, b.batch.batch_mesure_type)}
                          <br />
                          {b.batch.batch_mesure_type === 1 ? (
                            <span>{(b.quantity / b.min_sell_unit)} x {b.min_sell_unit} {Facade.getMesureTypeToStringShort(b.batch.batch_mesure_type)}</span>
                          ) : ''}
                          <br />
                          {b.location_name}
                        </td>
                        <td style={{ fontSize: '3.2mm' }}>
                          {b.batch.batch_is_ending ? (<span>(DLC COURTE) </span>) : null}{b.batch.product_label}
                          <br />
                          {b.provider_label || 'NA'}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>

            </div>
          </div>
        </div>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />

  */

  let stickers = ``

  producerBatchs.map((batch) => {
    const cartBatchs = (tourCartBatchs || []).filter((cb) => cb?.batch?.id === batch.id)

    cartBatchs.map((cb) => {
      ;(cb.identifications || []).map((identification) => {
        stickers += ` 
          <div style="page-break-after: always;">
            <div style="width: 48mm; height: 19.6mm; padding: 2mm; padding-top: 3mm; padding-right: 2.3mm;">
              <div style="width: 100%; height: 100%;">
                <div style="display: flex; flex-direction: row;">
                  <div style="width: 55px; height: 100%;">
                    <h3 style="margin: 0; padding: 0; font-size: 6.3mm; font-weight: bold">${identification}</h3>
                    <h6 style="margin: 0; padding: 0; font-size: 3mm; margin-top: 0.2mm; font-weight: bold">${FormatUtils.formatQuantity(
                      batch.product?.packing,
                      batch.product?.mesureType,
                    )}</h6>
                    <h6 style="margin: 0; margin-top: 0.7mm; padding: 0; font-size: 1.8mm;">${FormatUtils.formatDate(
                      tour.start,
                      'Date+StartTime',
                    )}</h6>
                    <h6 style="margin: 0; margin-top: 0.2mm; padding: 0; font-size: 2.2mm;">${
                      tour.city?.label
                    }</h6>
                    <h6 style="margin: 0; margin-top: 0.2mm; padding: 0; font-size: 2.2mm;">#6h57p98</h6>
                  </div>
                  <div style="flex: 1; text-align: left; padding-left: 0.5mm;">
                    <h6 style="margin: 0; padding: 0; font-size: 2.9mm; font-weight: bold">${
                      batch.product?.label
                    }</h6>
                    <h6 style="margin: 0; margin-top: 0.7mm; padding: 0; font-size: 1.8mm;">Provenance :</h6>
                    <h6 style="margin: 0; margin-top: 0.1mm; padding: 0; font-size: 2.5mm;">${
                      batch.product?.producer?.label
                    }</h6>
                    <h6 style="margin: 0; margin-top: 0.3mm; padding: 0; font-size: 1.8mm;">Destination :</h6>
                    <h6 style="margin: 0; margin-top: 0.1mm; padding: 0; font-size: 2.5mm;">${
                      cb.cart?.client?.label
                    }</h6>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>`
      })
    })
  })

  const html = `
    <html>
      <body style="text-align: center; padding: 0; margin: 0;">
        ${stickers}
      </body>
    </html>
    `

  const onPrintStickers = async () => {
    /*
    await Print.printAsync({
      html,
      // printerUrl: selectedPrinter?.url, // iOS only
    })
    */
    const pW = window.open('', '', 'height=2000, width=48')
    if (pW) {
      pW.document.write(html)
      pW.document.close()
      pW.print()
    }
  }

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title={`Étiquettes pour la tournée #${getTourIdFormatted()}`} />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Card>
              <Typography.Body>Producteur</Typography.Body>
              <Typography.CardTitle>{producer?.label}</Typography.CardTitle>
            </Card>
            <Spacer size={0.6} axis={Spacer.AxisEnum.VERTICAL} />
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Button
              label={'Imprimer'}
              onPress={() => onPrintStickers()}
              loading={isLoading}
              colorName={'color-secondary'}
            />
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourPrintStickerScreen
