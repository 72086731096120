import { css } from 'styled-components'
import styled from 'styled-components/native'

const StyledHistoryWrapper = styled.View<{ isMobile?: boolean }>`
  width: 100%;

  ${({ isMobile }) =>
    isMobile &&
    css`
      align-items: center;
    `};
`

export { StyledHistoryWrapper }
