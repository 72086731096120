import { ScrollView } from 'react-native-gesture-handler'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'
import {
  RegistrationLandingPageWrapper,
  StyledImageLogo,
  StyledLogoBox,
  TextWrapper,
  ButtonWrapper,
  BottomPartWrapper,
} from './RegistrationLanding.styles'
import { Button, Spacer, Typography } from '../../../../components'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { AuthenticationStackParamList } from '../../../../navigation/AuthenticationNavigationStack/AuthenticationNavigationStack.model'

import { StackNavigationProp } from '@react-navigation/stack'
import { useEffect, useState } from 'react'
import { Linking } from 'react-native'
import useAuthenticationContext from '../../../../utilities/hook/useAuthenticationContext'
import { PublicStackParamList } from '../../../../navigation/PublicNavigationStack/PublicNavigationStack.model'

const RegisterLandingScreen = () => {
  const route = useRoute<RouteProp<AuthenticationStackParamList, 'RegisterLanding'>>()
  const navigation = useNavigation<StackNavigationProp<PublicStackParamList>>()
  const [renderWaitingListScreen, setRenderWaitingListScreen] = useState(false)

  const { logout, isUserAuthenticated } = useAuthenticationContext()

  useEffect(() => {
    if (isUserAuthenticated) {
      logout()
    }
  }, [])

  const onClickProducer = () => {
    navigation.navigate('Authentication', {
      screen: 'RegisterProducerForm',
      params: {},
    })
  }

  const onClickClient = () => {
    setRenderWaitingListScreen(true)
  }

  const onClickLandingContact = async () => {
    Linking.openURL('https://harvy.tech/nous-contacter')
  }

  if (renderWaitingListScreen) {
    return (
      <RegistrationLandingPageWrapper>
        <StyledLogoBox>
          <StyledImageLogo
            source={require('../../../../../assets/drawings/manager_1.png')}
            resizeMode="contain"
          />
          <TextWrapper>
            <Typography.Body colorName="color-primary" bold align="center">
              Les inscriptions pour les clients professionnels ne sont pas encore ouvertes !
              Renseignez vos informations pour être tenu informé.
            </Typography.Body>
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <ButtonWrapper>
              <Button
                onPress={() => onClickLandingContact()}
                label={`S'inscrie sur liste d'attente`}
                colorName="color-secondary"
                fullWidth
              />
            </ButtonWrapper>
          </TextWrapper>
        </StyledLogoBox>
        <BottomPartWrapper>
          <ButtonWrapper>
            <Button
              onPress={() => setRenderWaitingListScreen(false)}
              label="Retour"
              colorName="color-grey"
              fullWidth
            />
          </ButtonWrapper>
        </BottomPartWrapper>
      </RegistrationLandingPageWrapper>
    )
  }

  return (
    <ScreenSafeAreaWrapper noPaddingLeft>
      <ContentWrapper alignCenter noHeader noDrawer>
        <RegistrationLandingPageWrapper>
          <StyledLogoBox>
            <StyledImageLogo
              source={require('../../../../../assets/drawings/camion.png')}
              resizeMode="contain"
            />
          </StyledLogoBox>
          <BottomPartWrapper>
            <TextWrapper>
              <Typography.Title colorName="color-primary" align="center">
                Créer mon compte
              </Typography.Title>
              <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.Body colorName="color-primary" align="center" bold>
                Je suis :
              </Typography.Body>
            </TextWrapper>
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />

            <ButtonWrapper>
              <Button
                onPress={() => onClickProducer()}
                label="Producteur"
                colorName="color-secondary"
                fullWidth
              />
            </ButtonWrapper>

            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />

            <ButtonWrapper>
              <Button
                onPress={() => onClickClient()}
                label="Client Professionnel"
                colorName="color-secondary"
                fullWidth
              />
            </ButtonWrapper>
          </BottomPartWrapper>
        </RegistrationLandingPageWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default RegisterLandingScreen
