import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import Typography from '../../../components/Typography'
import { DeliveryNoteMailHistoryProps } from './DeliveryNoteMailHistory.model'
import { StyledHistoryWrapper } from './DeliveryNoteMailHistory.styles'
import { Button, Spacer } from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { View } from 'react-native'
import { MAIL_TYPES } from '../../../../enums/mail'

function DeliveryNoteMailHistory({ deliveryNote }: DeliveryNoteMailHistoryProps) {
  const theme = useTheme()
  const [displayHistory, setDisplayHistory] = useState(false)

  const renderUpdate = (history: any) => {
    if (!history || !history.type || !history.date) return null
    let historyTitle = `[${
      MAIL_TYPES.find((emailType) => emailType.value === history?.type)?.label
    }] Envoie le ${FormatUtils.formatDate(history.date, 'Date+StartTime')} à ${
      history.mails && history.mails.length > 0
        ? history.mails.map((m: string) => `${m}, `)
        : 'tous'
    }`

    return (
      <Typography.Body colorName="text-dark-3" style={{ marginLeft: 1 * theme.spacingUnit }}>
        {historyTitle}
      </Typography.Body>
    )
  }

  return (
    <StyledHistoryWrapper>
      <Typography.Body>Historique des e-mails </Typography.Body>
      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

      {!displayHistory ? (
        <View style={{ maxWidth: '150px' }}>
          <Button.Text
            colorName="text-dark-3"
            label={'Voir le détail'}
            onPress={() => setDisplayHistory(true)}
          />
        </View>
      ) : (
        <>
          {deliveryNote?.mailsStatus && (
            <>
              {deliveryNote?.mailsStatus.history.length > 0 && (
                <>
                  {deliveryNote?.mailsStatus.history.map((history) => (
                    <>
                      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                      {renderUpdate(history)}
                    </>
                  ))}
                </>
              )}
            </>
          )}
          <Spacer size={1.5} axis={Spacer.AxisEnum.VERTICAL} />
          <View style={{ maxWidth: '150px' }}>
            <Button.Text
              colorName="text-dark-3"
              label={'Voir moins'}
              onPress={() => setDisplayHistory(false)}
            />
          </View>
        </>
      )}
    </StyledHistoryWrapper>
  )
}

export default DeliveryNoteMailHistory
