import { Controller } from 'react-hook-form'
import { RhfTextInputModel } from './RhfTextInput.model'
import TextInput from '../inputs/TextInput'
import Spacer from '../Spacer'
import Typography from '../Typography'

const RhfTextInput = ({ control, name, textInputLabel, rules, suffix }: RhfTextInputModel) => {
  return (
    <Controller
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <TextInput
            value={value}
            label={textInputLabel}
            onChangeText={(value) => onChange(value)}
            field="text"
            extraMargin
            boldInputLabel
            suffix={suffix}
          />
          {error && (
            <>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              <Typography.Body colorName="color-danger">{error.message}</Typography.Body>
            </>
          )}
        </>
      )}
      name={name}
    />
  )
}

export default RhfTextInput
