import { de } from 'date-fns/locale'
import { Client } from '../../../domain'
import ApiService from '../../api.service'

const getOne = async (idClient: string): Promise<Client> => {
  const response = await ApiService.get(`/client/${idClient}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const update = async (clientId: string, client: any): Promise<Client> => {
  const response = await ApiService.patch(`/client/${clientId}`, client).catch((error) => {
    throw error
  })
  return response
}

const ClientClientService = { getOne, update }

export default ClientClientService
