import styled from 'styled-components/native'

const StyledTopBox = styled.View`
  flex-direction: row;
`

const StyledFeesWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 0.3}px;
  flex-direction: row;
`

const StyledPriceRuleBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 1.5}px;
  padding: ${({ theme }) => theme.spacingUnit * 0.6}px;
  text-align: center;
  max-width: min-content;
`

export { StyledTopBox, StyledFeesWrapper, StyledPriceRuleBox }
