import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import Typography from '../../../components/Typography'
import { CitiesFormProps } from './CitiesForm.model'
import { StyledHistoryWrapper } from './CitiesForm.styles'
import { Button, Col, Dropdown, Icon, Icons, Row, SectionTitle, Spacer } from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { View } from 'react-native'
import { LABELS_TYPES } from '../../../../enums/label'
import { TouchableHighlight } from 'react-native-gesture-handler'
import { useQuery } from '@tanstack/react-query'
import { City } from '../../../domain'
import CityProducerService from '../../../services/producer/city'
import ItemCard from '../ItemCard'

function CitiesForm({ selectedCities, onChangeCities }: CitiesFormProps) {
  const theme = useTheme()
  const [addMode, setAddMode] = useState(
    !selectedCities || selectedCities?.length < 1 ? true : false,
  )

  const {
    data: cities,
    refetch: refetchCities,
    isInitialLoading: citiesLoading,
  } = useQuery<City[], Error>(['cities'], () => CityProducerService.getAll(), {
    keepPreviousData: true,
  })

  const onChangeCitiesSelect = (newCity: any) => {
    const tempCities = selectedCities || []
    tempCities.push(newCity)
    onChangeCities(tempCities)
    setAddMode(false)
  }

  const removeCity = (cityId: string) => {
    const tempCities = selectedCities || []
    const foundCityIndex = tempCities?.findIndex((c) => c.id === cityId)
    if (foundCityIndex > -1) {
      tempCities.splice(foundCityIndex, 1)
    }
    onChangeCities(tempCities)
    setAddMode(false)
  }

  return (
    <StyledHistoryWrapper>
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
      {selectedCities?.map((city) => {
        return <ItemCard title={city.label} iconType="DELETE" onClick={() => removeCity(city.id)} />
      })}

      {!addMode && (
        <View style={{ maxWidth: '150px', alignSelf: 'flex-end' }}>
          <Spacer size={1.5} axis={Spacer.AxisEnum.VERTICAL} />
          <Button.Text
            colorName="text-dark-1"
            label={'Ajouter une ville'}
            onPress={() => setAddMode(true)}
          />
        </View>
      )}

      {addMode && (
        <>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Dropdown
            label={'Choisir une ville'}
            onChange={onChangeCitiesSelect}
            multiple={false}
            isSearchable
            optionsDefault={(cities || [])
              .filter((city) => !(selectedCities || []).find((sc: City) => sc?.id === city.id))
              .map((city) => ({
                ...city,
                label: city.label,
                value: city.id,
              }))}
            zIndex={2000}
            itemKey="city"
          />
          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
        </>
      )}
    </StyledHistoryWrapper>
  )
}

export default CitiesForm
