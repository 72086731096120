import React from 'react'
import { useTheme } from 'styled-components/native'
import { CarrierTourDeliveryNotesTopBoxProps } from './CarrierTourDeliveryNotesTopBox.model'
import { CardParam } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { StyledTopBox } from './CarrierTourDeliveryNotesTopBox.styles'

function CarrierTourDeliveryNotesTopBox({
  tour,
  onClickDeliveryNotePrint,
  emailsToSend,
  deliveryNotesType,
}: CarrierTourDeliveryNotesTopBoxProps) {
  const theme = useTheme()

  return (
    <>
      <StyledTopBox>
        <CardParam label={'Type de bons'} value={deliveryNotesType} flex={6} isStart />
        <CardParam
          label={'Imprimer'}
          value={'à venir'}
          flex={4}
          icon="print"
          onClick={() => onClickDeliveryNotePrint()}
        />
        <CardParam label={'Emails à envoyer'} value={emailsToSend} flex={5} isEnd icon="mail" />
      </StyledTopBox>
    </>
  )
}

export default CarrierTourDeliveryNotesTopBox
