import { Tour } from '../../../domain'
import ApiService from '../../api.service'

const findOneUserGroup = async (userId: string, groupId: string) => {
  const response = await ApiService.get(`/user/${userId}/group/${groupId}`).catch((error) => {
    throw error
  })
  return response
}

const create = async (user: any, groupId: string): Promise<Tour> => {
  const response = await ApiService.post(`user/group/${groupId}`, user).catch((error) => {
    throw error
  })
  return response
}

const createAdmin = async (user: any, groupId: string): Promise<Tour> => {
  const response = await ApiService.post(`user/group/${groupId}/admin`, user).catch((error) => {
    throw error
  })
  return response
}

const updateAdmin = async (userId: string, user: any, groupId: string): Promise<Tour> => {
  const response = await ApiService.patch(`user/group/${groupId}/admin/${userId}`, user).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const updateUser = async (userId: string, user: any, groupId: string): Promise<Tour> => {
  const response = await ApiService.patch(`user/${userId}/group/${groupId}`, user).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const UserCarrierService = {
  findOneUserGroup,
  create,
  createAdmin,
  updateAdmin,
  updateUser,
}

export default UserCarrierService
