import React from 'react'
import { View } from 'react-native'
import { Card, Typography, Spacer, TouchableCard, Button, Icon, Icons } from '..'
import FormatUtils from '../../utilities/utils/format'
import {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledRightBox,
  StatusBox,
} from './PaymentCard.styles'
import { PaymentCardProps } from './PaymentCard.model'
import { PAYMENT_METHODS, PaymentStatusEnum } from '../../../enums/payment'
import { useTheme } from 'styled-components/native'

const PaymentCard = ({ onPress, payment, invoice }: PaymentCardProps) => {
  const theme = useTheme()

  return (
    <StyledCardWrapper activeOpacity={0.8} onPress={onPress}>
      <Card>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.CardTitle>{payment.accountSender.legalName}</Typography.CardTitle>
            <Typography.BodySmall colorName="color-primary" bold></Typography.BodySmall>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3">
              {FormatUtils.pluralizeAmountLabel(
                'tournée',
                payment.invoices[0].carrierFeesRecords.length || 0,
              )}
            </Typography.BodySmall>
            <Typography.BodySmall colorName="text-dark-3"></Typography.BodySmall>
            {payment.status === PaymentStatusEnum.COMPLETED && (
              <Typography.BodySmall colorName="text-dark-3">
                {`Reçu le ${FormatUtils.formatDate(payment.updatedDate, 'Date')} via ${
                  PAYMENT_METHODS.find((method) => method.value === payment.paymentMethod)?.label
                }`}
              </Typography.BodySmall>
            )}
          </StyledLeftBox>
          <StyledRightBox>
            <Spacer size={0.4} axis={Spacer.AxisEnum.HORIZONTAL} />
            <View style={{ alignItems: 'flex-end' }}>
              <StatusBox
                backgroundColor={
                  payment.status === PaymentStatusEnum.PENDING ? 'color-grey' : 'color-primary'
                }
              >
                <Typography.BodySmall colorName={'text-light-1'} bold>
                  {payment.status === PaymentStatusEnum.PENDING ? 'En attente' : 'Payé'}
                </Typography.BodySmall>
              </StatusBox>
              <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall colorName="text-dark-3" style={{ alignSelf: 'flex-end' }}>
                Com. logistique
              </Typography.BodySmall>
              <Typography.BodySmall bold colorName="color-primary">
                {FormatUtils.formatPrice(payment.amount, 'ttc')}
              </Typography.BodySmall>
              {invoice?.customIdentifier && (
                <View style={{ flexDirection: 'row' }}>
                  <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                  <Typography.BodySmall colorName="color-primary">
                    Code facture :{' '}
                  </Typography.BodySmall>
                  <Typography.BodySmall bold colorName="color-primary">
                    {invoice?.customIdentifier}
                  </Typography.BodySmall>
                </View>
              )}
            </View>
            <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            <Icon
              type={Icons.Ionicons}
              name={'chevron-forward-outline'}
              size={theme.spacingUnit * 2.4}
              color={theme.colors['text-dark-3']}
            />
          </StyledRightBox>
        </StyledCardContent>
      </Card>
    </StyledCardWrapper>
  )
}

export default PaymentCard
