import { CategoryWrapper, StyledImage } from './ClientCatalogFilterTab.styles'
import { ClientCatalogFilterTabProps } from './ClientCatalogFilterTab.model'
import { useTheme } from 'styled-components/native'
import { Typography } from '../../../../../../components'
import DirectusUtil from '../../../../../../utilities/utils/directus'

const ClientCatalogFilterTab = ({
  category,
  label,
  onChangeCategory,
  selectedCategory,
}: ClientCatalogFilterTabProps) => {
  const theme = useTheme()
  const categoryImage = {
    uri: DirectusUtil.getCategoryImageUrlFromId(category),
  }

  let isActive = false
  if (category?.id === selectedCategory) {
    isActive = true
  }
  if (!category && !selectedCategory) {
    isActive = true
  }

  return (
    <CategoryWrapper key={category?.id} onPress={() => onChangeCategory(category?.id)}>
      <StyledImage
        source={categoryImage}
        resizeMode="cover"
        imageStyle={{ opacity: 0.2 }}
        isActive={isActive}
      >
        <Typography.BodySmall bold colorName={isActive ? 'text-light-1' : 'color-primary'}>
          {label}
        </Typography.BodySmall>
      </StyledImage>
    </CategoryWrapper>
  )
}

export default ClientCatalogFilterTab
