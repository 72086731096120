import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import useDeepLinks from '../../utilities/hook/useDeepLinks'
import { DeepLinkEnum } from '../../utilities/context/deepLinkContext'
import DeviceUtil from '../../utilities/utils/device'
import { NavigationItemType } from '../Navigation.model'
import { Icons } from '../../components'
import CarrierDashboardScreen from '../../screens/Carrier/CarrierDashboard'
import HeaderHome from '../components/HeaderHome'
import CarrierGroupScreen from '../../screens/Carrier/CarrierGroup'
import BottomTabs from '../components/BottomTabs'
import CustomDrawer from '../components/Drawer'
import TourCarrierNavigation from './TourCarrierNavigation/TourCarrierNavigation'
import GroupCarrierNavigation from './GroupCarrierNavigation/GroupCarrierNavigation'
import { RightKeyEnum } from '../../../enums'
import PriceReportingCarrierNavigation from './PriceReportingCarrierNavigation/PriceReportingCarrierNavigation'
import BillingCarrierNavigation from './BillingCarrierNavigation/BillingCarrierNavigation'
import useGroupContext from '../../utilities/hook/useGroupContext'

const navigationItems: NavigationItemType[] = [
  {
    route: 'CarrierDashboard',
    label: 'Accueil',
    type: Icons.Ionicons,
    icon: 'home',
    component: CarrierDashboardScreen,
    screenOptions: {
      headerShown: true,
      header: () => <HeaderHome />,
    },
  },
  {
    route: 'CarrierTours',
    label: 'Tournées',
    type: Icons.MaterialCommunityIcons,
    icon: 'truck',
    component: TourCarrierNavigation,
    screenOptions: {
      headerShown: false,
    },
    rightKey: RightKeyEnum.C_HEADING_TOURS,
  },
  {
    route: 'CarrierGroup',
    label: 'Groupe',
    type: Icons.Ionicons,
    icon: 'people',
    component: GroupCarrierNavigation,
    screenOptions: {
      headerShown: false,
    },
    rightKey: RightKeyEnum.C_HEADING_GROUP,
  },
  {
    route: 'PriceReportingsCarrierNavigationConfiguration',
    label: 'Pricing',
    type: Icons.Ionicons,
    icon: 'pricetags',
    component: PriceReportingCarrierNavigation,
    screenOptions: {
      headerShown: false,
    },
    rightKey: RightKeyEnum.C_HEADING_PRICE_REPORTING,
  },
  {
    route: 'CarrierBilling',
    label: 'Finances',
    type: Icons.Ionicons,
    icon: 'wallet',
    component: BillingCarrierNavigation,
    screenOptions: {
      headerShown: false,
    },
    rightKey: RightKeyEnum.C_HEADING_BILLING,
  },
]

const Stack = createStackNavigator()

const CarrierMainNavigationStack = () => {
  const {group} = useGroupContext()

  const filteredNavigationItems = navigationItems.filter((item) => {
    if (item.route === 'PriceReportingsCarrierNavigationConfiguration') {
      return group?.hasPricingModule
    }
    return true
  })

  if (DeviceUtil.isMobileApp()) {
    return <BottomTabs items={filteredNavigationItems} />
  } else {
    return <CustomDrawer items={filteredNavigationItems} />
  }
}

export default CarrierMainNavigationStack
