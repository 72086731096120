import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Button } from '../../../../../components'
import StyledWrapper from './PasswordForgottenButton.styles'
import { PasswordForgottenButtonProps } from './PasswordForgottenButton.model'
import { PublicStackParamList } from '../../../../../navigation/PublicNavigationStack/PublicNavigationStack.model'

const PasswordForgottenButton = ({ email }: PasswordForgottenButtonProps) => {
  const navigation = useNavigation<StackNavigationProp<PublicStackParamList>>()

  const goToPasswordForgotten = () => {
    navigation.navigate('Authentication', {
      screen: 'ForgottenPassword',
      params: {},
    })
  }

  return (
    <StyledWrapper>
      <Button.Text
        label="Mot de passe oublié?"
        onPress={goToPasswordForgotten}
        colorName="color-grey"
      />
    </StyledWrapper>
  )
}
export default PasswordForgottenButton
