import { RouteProp, useRoute } from '@react-navigation/native'
import React from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { PageTitle, Spacer, Typography } from '../../../components'
import {
  BatchsFake,
  DeliveryNote,
  Order,
  Pagination,
  ProducersFake,
  StepsFake,
  Tour,
  ToursFake,
} from '../../../domain'
import i18n from '../../../i18n'
import BatchCartCard from '../../../modules/Batch/BatchCartCard'
import TourProducerCard from '../../../modules/Tour/TourProducerCard'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper } from './ProducerTourOrders.styles'
import { useQuery } from '@tanstack/react-query'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import TourProducerService from '../../../services/producer/tour'
import ProducerTourOrdersTopBox from './components/ProducerTourOrdersTopBox'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { Linking, View } from 'react-native'
import Loader from '../../../components/Loader'
import DeliveryNoteProducerService from '../../../services/producer/deliveryNote'
import TourDeliveryNoteCard from '../../../modules/DeliveryNote/TourDeliveryNoteCard'
import { UserEnums } from '../../../../enums'
import DirectusUtil from '../../../utilities/utils/directus'

const ProducerTourOrdersScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerTourOrders'>>()

  const { id } = route?.params ?? { id: '' }
  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = useQuery<Tour, Error>(
    ['p_tour_orders'],
    () => TourProducerService.getOneTourProducerOrders(id, getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: deliveryNotes,
    refetch: refetchDeliveryNotes,
    isInitialLoading: deliveryNotesLoading,
  } = useQuery<{ data: DeliveryNote[]; pagination: Pagination }, Error>(
    ['p_tour_delivery_notes', id, getAccessInfos().producerId],
    () => DeliveryNoteProducerService.getAll(id, undefined, getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  const getOrderIdFormatted = (order?: Order) => {
    if (order && order.id && order.id.length > 5) {
      return order.id.slice(-5)
    }
    return 'NA'
  }

  if (tourLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour) return null

  const carts = tour.carts
  const renderCarts = () => {
    if (!carts || carts.length < 1) return null
    return carts.map((cart) => {
      return (
        <View key={cart.id}>
          <Typography.SectionTitle>
            Commande #{getOrderIdFormatted(cart.order)} - {cart.client?.label}
          </Typography.SectionTitle>
          {cart.cartBatchs?.map((cartBatch) => (
            <BatchCartCard
              key={cartBatch.id}
              cartBatch={cartBatch}
              onClick={() => null}
              userRole={UserEnums.RoleEnums.PRODUCER}
              withPriceGain
            />
          ))}
        </View>
      )
    })
  }

  const onClickDeliveryNote = async (deliveryNote: DeliveryNote) => {
    if (!deliveryNote.id) return

    const deliveryNoteWithAccess = await DeliveryNoteProducerService.getOne(deliveryNote.id)
    if (!deliveryNoteWithAccess.documentId || !deliveryNoteWithAccess.accessToken) return

    window.open(
      DirectusUtil.getDocumentDownloadUrlFromId(
        deliveryNoteWithAccess.documentId,
        deliveryNoteWithAccess.accessToken,
      ),
      '_blank',
    )
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Commandes #${getTourIdFormatted()}`}
            buttonRight={
              <Typography.BodySmall>
                {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                {'\n'}
                {FormatUtils.capitalize(
                  FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                )}
              </Typography.BodySmall>
            }
          />
          <ProducerTourOrdersTopBox tour={tour} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          {carts && carts.length > 0 ? (
            renderCarts()
          ) : (
            <Typography.Body colorName="color-danger">
              Vous n'avez pas encore de commandes pour cette tournée
            </Typography.Body>
          )}

          <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
          {(deliveryNotes?.data || []).length > 0 && (
            <Typography.SectionTitle colorName="text-dark-3">
              Bons de livraison
            </Typography.SectionTitle>
          )}

          {(deliveryNotes?.data || []).map((deliveryNote) => (
            <TourDeliveryNoteCard
              key={deliveryNote.id}
              deliveryNote={deliveryNote}
              onClick={onClickDeliveryNote}
              isCarrier={false}
            />
          ))}
          <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerTourOrdersScreen
