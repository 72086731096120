import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
// import * as WebBrowser from 'expo-web-browser'
import React, { useEffect, useState } from 'react'
import { TouchableWithoutFeedback } from 'react-native'
import { useTheme } from 'styled-components'
import { Spacer, Typography } from '../../components'
import { ProducerStackParamList } from '../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { CarrierStackParamList } from '../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import useAuthenticationContext from '../../utilities/hook/useAuthenticationContext'
import useUserContext from '../../utilities/hook/useUserContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../utilities/styling/wrappers'
import GeneralUtils from '../../utilities/utils/general'
import UserMenuItem from './components/UserMenuItem'
import { UserMenuItemProps } from './components/UserMenuItem/UserMenuItem.model'
import { StyledItemsView, StyledProfilBox } from './UserMenu.styles'
import { ClientStackParamList } from '../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import useDeepLinks from '../../utilities/hook/useDeepLinks'
import { DeepLinkEnum } from '../../utilities/context/deepLinkContext'
import ProducerProducerService from '../../services/producer/producer'
import AccountService from '../../services/billing/account'

function UserMenu() {
  const { logout, getAccessInfos, viewType } = useAuthenticationContext()
  const navigationProducer = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const navigationCarrier = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const navigationClient = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const [errorMessage, setErrorMessage] = useState('')
  const theme = useTheme()

  useDeepLinks([DeepLinkEnum.NAVIGATION])

  const { user, loadUser } = useUserContext()

  const onPressBillingPage = async () => {
    if (viewType === 1) {
      navigationCarrier.navigate('Account', {
        id: getAccessInfos().carrierId,
      })
    }
    if (viewType === 2) {
      navigationProducer.navigate('Account', {
        id: getAccessInfos().producerId,
      })
    }
    if (viewType === 3) {
      navigationClient.navigate('Account', {
        id: getAccessInfos().clientId,
      })
    }
  }

  const onPressBillingPortal = async () => {
    let billingPageURL = undefined
    setErrorMessage(``)

    if (viewType === 2) {
      billingPageURL = await ProducerProducerService.getBillingPageURL(getAccessInfos().producerId)
    } else if (viewType === 3) {
      billingPageURL = await AccountService.getBillingPortalURL(getAccessInfos().accountId).catch(
        (error) => {
          setErrorMessage(`Votre compte de facturation n'est pas encore configuré`)
        },
      )
    }

    if (billingPageURL) {
      window.open(billingPageURL, '_blank')
    }
  }

  const onToggleLogout = () => {
    logout()
    /*
    if (Device.isDevice && Device.osInternalBuildId !== null) {
      ActionSheetIOS.showActionSheetWithOptions(
        {
          options: ['Cancel', 'Log out'],
          destructiveButtonIndex: 1,
          cancelButtonIndex: 0,
          tintColor: theme.colors['text-dark-1'],
        },
        (buttonIndex) => {
          if (buttonIndex === 0) {
            // cancel action
          } else if (buttonIndex === 1) {
            logout()
          }
        },
      )
    } else {
      logout()
    }
    */
  }

  const onPressListInvoice = async () => {
    if (viewType === 1) {
      navigationCarrier.navigate('ListInvoice')
    }
    if (viewType === 2) {
      navigationProducer.navigate('ListInvoice')
    }
  }

  useEffect(() => {
    if (GeneralUtils.isEmpty(user)) {
      loadUser()
    }
  }, [user, loadUser])

  const createMenuItems = (): UserMenuItemProps[] => {
    const baseMenuItems: UserMenuItemProps[] = [
      {
        key: 1,
        type: 'divider',
      },
      {
        key: 3,
        type: 'item',
        iconType: 'Ionicons',
        iconName: 'settings-outline',
        label: 'Paramètres',
        page: 'UserLoginSecurity',
      },
      {
        key: 5,
        type: 'item',
        iconType: 'MaterialCommunityIcons',
        iconName: 'face-man-profile',
        label: 'Profil Producteur',
        page: 'ProfileProducerNav',
        viewTypes: [2],
      },
      {
        key: 6,
        type: 'item',
        iconType: 'MaterialCommunityIcons',
        iconName: 'face-man-profile',
        label: 'Mes informations',
        page: 'ClientInformations',
        onPress: () => {
          navigationClient.navigate('ClientInformations', {
            id: getAccessInfos().clientId,
          })
        },
        viewTypes: [3],
      },
      {
        key: 7,
        type: 'item',
        iconType: 'Ionicons',
        iconName: 'cash-outline',
        label: 'Informations de facturation',
        page: '',
        onPress: () => {
          onPressBillingPage()
        },
      },
      {
        key: 8,
        type: 'item',
        iconType: 'MaterialCommunityIcons',
        iconName: 'bank',
        label: 'Gestion et suivi des paiements',
        page: '',
        onPress: () => {
          onPressBillingPortal()
        },
        viewTypes: [3],
      },
      {
        key: 15,
        type: 'divider',
      },
      {
        key: 16,
        type: 'item-no-arrow',
        label: 'Se déconnecter',
        color: 'color-primary',
        onPress: () => {
          onToggleLogout()
        },
      },
    ]

    return baseMenuItems
  }

  const menuItems = createMenuItems()

  const onNavigate = (screen: any) => {
    if (viewType === 1) {
      navigationCarrier.navigate(screen)
    }
    if (viewType === 2) {
      navigationProducer.navigate(screen)
    }
    if (viewType === 3) {
      navigationClient.navigate(screen)
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav noPaddingLeft>
      <ContentWrapper noPaddingLeft>
        <TouchableWithoutFeedback onPress={() => null}>
          <StyledProfilBox>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Typography.Title>
              {user?.firstName || user?.lastName
                ? `${user?.firstName || ''} ${user?.lastName || ''}`
                : ''}
            </Typography.Title>
            <Typography.BodySmall>{user?.email || '-'}</Typography.BodySmall>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            {errorMessage ? (
              <Typography.InputLabel colorName="color-danger">{errorMessage}</Typography.InputLabel>
            ) : null}
          </StyledProfilBox>
        </TouchableWithoutFeedback>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <StyledItemsView>
          {menuItems.map(({ ...item }) => {
            return (
              <UserMenuItem
                key={item.key}
                {...item}
                onNavigate={onNavigate}
                currentViewType={viewType}
              />
            )
          })}
        </StyledItemsView>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default UserMenu
