import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Card, Dropdown, PageTitle, Spacer, Typography } from '../../../components'
import Loader from '../../../components/Loader'
import { Group, Pagination, ProducerGroup, Tour } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import GroupCarrierService from '../../../services/carrier/group'
import TourCarrierService from '../../../services/carrier/tour'

import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierTourAddProducer.styles'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierTourAddProducerScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourAddProducer'>>()
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [isLoading, setIsLoading] = useState(false)

  const {
    data: producerGroups,
    refetch: refetchProducerGroups,
    isLoading: isProducerGroupsLoading,
  } = useQuery<{ data: ProducerGroup[]; pagination: Pagination }, Error>(
    ['sf_group_producers', 500, 1],
    () =>
      GroupCarrierService.getOneGroupProducers(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        500,
        1,
        undefined,
      ),
    {
      keepPreviousData: true,
      staleTime: 600000, // 10min
    },
  )

  const { tourId } = route?.params ?? { tourId: '' }

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = TourCarrierService.getOne.query(tourId)

  const [selectedProducer, setSelectedProducer] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const onChangeProducer = (value: any) => {
    setSelectedProducer(value)
  }

  const onAddProducer = async () => {
    setErrorMessage('')
    if (!tour || !selectedProducer) return
    setIsLoading(true)

    const createdProducerTour = await TourCarrierService.join(selectedProducer.id, tour.id)
    if (createdProducerTour && createdProducerTour.id) {
      await queryClient.fetchQuery(['sf_tour', tourId])
      navigation.goBack()
      setSelectedProducer(null)
    } else {
      setErrorMessage(`Un problème est survenu lors de l'ajout du producteur à la tournée`)
    }
    setIsLoading(false)
  }

  if (tourLoading || isProducerGroupsLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour) return null

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Ajouter un producteur à la tournée" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Card>
            <Typography.Body>{FormatUtils.capitalize(i18n.t('tour'))}</Typography.Body>
            <Typography.CardTitle>
              {FormatUtils.capitalize(
                FormatUtils.formatDate(tour.start, 'FullDate+StartTime+EndTime', tour.end),
              )}{' '}
              - {FormatUtils.getTourTarget(tour, 'short')}
            </Typography.CardTitle>
          </Card>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Dropdown
            label={'Choisir un producteur'}
            onChange={onChangeProducer}
            multiple={false}
            optionsDefault={(
              (producerGroups?.data || []).filter(
                (cg) => !tour.producerTour?.find((st) => st.producer.id === cg.producer?.id),
              ) || []
            ).map((producerGroup) => ({
              ...producerGroup.producer,
              label: producerGroup.producer?.label,
              value: producerGroup.producer?.id,
            }))}
            zIndex={1000}
            itemKey="producer"
          />
          {!producerGroups?.data ||
            (producerGroups?.data?.length < 1 && (
              <Typography.Body colorName="color-danger">
                Vous n'avez pas de producteur dans votre groupe
              </Typography.Body>
            ))}
          <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            {selectedProducer && (
              <Button label={'Ajouter'} onPress={() => onAddProducer()} loading={isLoading} />
            )}
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourAddProducerScreen
