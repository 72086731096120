import { City, Container, Product, ProductType } from '../../../domain'
import ApiService from '../../api.service'

const getAll = async (): Promise<City[]> => {
  const response = await ApiService.get(`/city`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const CityProducerService = {
  getAll,
}

export default CityProducerService
