import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { CarrierTourClientTopBoxProps } from './CarrierTourClientTopBox.model'
import {
  Button,
  Card,
  CardParam,
  Icon,
  Icons,
  Spacer,
  TextInput,
  Typography,
} from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { ButtonsWrapper, StyledCardWrapper, StyledTopBox } from './CarrierTourClientTopBox.styles'
import { Linking, TouchableOpacity, View } from 'react-native'

function CarrierTourClientTopBox({
  stop,
  lastOrders,
  onUpdateAddress,
}: CarrierTourClientTopBoxProps) {
  const theme = useTheme()
  const [editMode, setEditMode] = useState(false)
  const [updatedIndications, setUpdatedIndications] = useState(
    stop.client?.deliveryAddress?.indications,
  )

  const onPressCall = (phone?: string) => {
    if (phone) {
      Linking.openURL(
        `tel:${phone.replace(' ', '').replace('.', '').replace('-', '').replace('+', '')}`,
      )
      return
    }
  }

  const mapsUrl = 'https://www.google.com/maps/search/?api=1'
  const mapsQuery = stop.client.deliveryAddress
    ? `${`${stop?.client?.deliveryAddress.addressLine1} ${stop?.client?.deliveryAddress.postalCode} ${stop?.client?.deliveryAddress.city}`}`
    : `${`${stop?.client?.addressLine1} ${stop?.client?.postalCode} ${stop?.client?.city}`}`

  const wazeUrl = 'https://waze.com/ul'
  const wazeQuery = stop.client.deliveryAddress
    ? `${stop?.client?.deliveryAddress.addressLine1} ${stop?.client?.deliveryAddress.postalCode} ${stop?.client?.deliveryAddress.city}`
    : `${stop?.client?.addressLine1} ${stop?.client?.postalCode} ${stop?.client?.city}`

  const openMaps = () => {
    if (
      stop.client.deliveryAddress ||
      (stop?.client?.addressLine1 && stop?.client?.postalCode && stop?.client?.city)
    ) {
      Linking.openURL(`${mapsUrl}&query=${mapsQuery}`)
      return
    }
  }

  const openWaze = () => {
    if (
      stop.client.deliveryAddress ||
      (stop?.client?.addressLine1 && stop?.client?.postalCode && stop?.client?.city)
    ) {
      Linking.openURL(`${wazeUrl}?q=${wazeQuery}`)
      return
    }
  }

  const getLastOrdersRatio = () => {
    let ratio = 0
    if (lastOrders && lastOrders.length > 0) {
      let total = 0
      let tourId: string | undefined = undefined
      lastOrders.map((o) => {
        if (!tourId || tourId === o.cart?.tour?.id) {
          total += o.totalHTBatchs || 0
          tourId = o.cart?.tour?.id
        }
      })
      ratio = (parseFloat(stop?.totalIncome || '0') - (total || 1)) / (total || 1)
    }

    let ratioResult = '- %'
    if (ratio !== 0) {
      ratioResult = `${ratio > 1 ? '+' : ''}${FormatUtils.formatPercentage(ratio)}`
    }
    return ratioResult
  }

  const toggleEditMode = () => {
    setEditMode(!editMode)
    setUpdatedIndications(stop.client?.deliveryAddress?.indications)
  }

  const onSaveIndications = () => {
    if (!stop.client?.deliveryAddress?.id) return
    onUpdateAddress(stop.client?.deliveryAddress?.id, { indications: updatedIndications || '' })
    toggleEditMode()
  }

  return (
    <>
      <StyledTopBox>
        <CardParam
          label={FormatUtils.capitalize(FormatUtils.getLabelFromClientType(1))}
          value={stop.client?.label || ''}
          flex={8}
          isStart
          rightBox={
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {stop.client?.phone1 && (
                <Button
                  small
                  onPress={() => onPressCall(stop.client?.phone1)}
                  colorName="color-grey"
                  style={{ width: 30, height: 30 }}
                >
                  <Icon
                    type={Icons.MaterialCommunityIcons}
                    name="phone-classic"
                    size={theme.spacingUnit * 2}
                    color={theme.colors['text-light-1']}
                  />
                </Button>
              )}
              <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.5} />
              {stop.client?.phone2 && (
                <Button
                  small
                  onPress={() => onPressCall(stop.client?.phone2)}
                  colorName="color-grey"
                  style={{ width: 30, height: 30 }}
                >
                  <Icon
                    type={Icons.Ionicons}
                    name="call"
                    size={theme.spacingUnit * 2}
                    color={theme.colors['text-light-1']}
                  />
                </Button>
              )}
            </View>
          }
        />
        <CardParam
          label={'Prévu à'}
          value={
            stop.estimate
              ? FormatUtils.capitalize(FormatUtils.formatDate(stop.estimate, 'Time'))
              : 'NA'
          }
          flex={4}
          isEnd
        />
      </StyledTopBox>
      <StyledTopBox>
        <CardParam
          label={'Adresse'}
          value={
            <>
              <Typography.Body>
                {stop?.client.deliveryAddress?.addressLine1 || stop?.client?.addressLine1}
              </Typography.Body>
              {(stop?.client.deliveryAddress?.addressLine2 || stop?.client?.addressLine2) && (
                <Typography.Body>
                  {stop?.client.deliveryAddress?.addressLine2 || stop?.client?.addressLine2}
                </Typography.Body>
              )}
              <Typography.Body>
                {stop?.client.deliveryAddress?.postalCode || stop?.client?.postalCode}{' '}
                {stop?.client.deliveryAddress?.city || stop?.client?.city}
              </Typography.Body>
            </>
          }
          flex={1}
          rightBox={
            <>
              <Button small onPress={() => openMaps()} label="Maps" colorName="color-grey" />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Button small onPress={() => openWaze()} label="Waze" colorName="color-grey" />
            </>
          }
          isStart
          isEnd
        />
      </StyledTopBox>
      <StyledTopBox>
        {editMode ? (
          <>
            <StyledCardWrapper>
              <Card>
                <TextInput
                  label={'Indications'}
                  value={updatedIndications}
                  onChangeText={(text) => setUpdatedIndications(text)}
                  field={'indications'}
                  multiline
                />
                <ButtonsWrapper>
                  <Button
                    small
                    onPress={() => toggleEditMode()}
                    label={'Annuler'}
                    colorName="color-grey"
                  />
                  <Button
                    small
                    onPress={() => onSaveIndications()}
                    label={'Enregistrer'}
                    colorName="color-secondary"
                  />
                </ButtonsWrapper>
              </Card>
            </StyledCardWrapper>
          </>
        ) : (
          <CardParam
            label={'Indications'}
            value={<Typography.Body>{stop.client?.deliveryAddress?.indications}</Typography.Body>}
            isStart
            isEnd
            rightBox={
              <>
                <Button
                  small
                  lowPadding
                  onPress={() => toggleEditMode()}
                  label={'Modifier'}
                  colorName="color-grey"
                />
              </>
            }
          />
        )}
      </StyledTopBox>
      <StyledTopBox>
        <CardParam label={'Commandes'} value={stop?.nbOrders || '0'} flex={4} isStart />
        <CardParam
          label={'Ventes'}
          value={`${FormatUtils.formatPrice(stop?.totalIncome)} (${getLastOrdersRatio()})`}
          flex={5}
          isEnd
        />
        <CardParam label={'Colis'} value={stop?.nbCrates || '0'} flex={3} isEnd />
      </StyledTopBox>
    </>
  )
}

export default CarrierTourClientTopBox
