import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import React, { useEffect } from 'react'
import { Platform } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTheme } from 'styled-components/native'

import TabButton from './TabButton'
import { NavigationItemType } from '../../Navigation.model'
import styles from './BottomTabs.styles'
import { useNavigation } from '@react-navigation/native'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'

const Tab = createBottomTabNavigator()

const BottomTabs = ({ items }: { items: NavigationItemType[] }) => {
  const theme = useTheme()
  const insets = useSafeAreaInsets()
  const { tabBarStyle } = styles(theme, insets)
  const { carrierHasRight } = useAuthenticationContext()

  const rightFilteredItems = (): NavigationItemType[] => {
    const tempItems: NavigationItemType[] = []
    items.map((item) => {
      let itemRightValid = true
      if (item.rightKey) {
        if (!carrierHasRight(item.rightKey)) {
          itemRightValid = false
        }
      }
      if (itemRightValid) {
        tempItems.push(item)
      }
    })
    return tempItems
  }

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle,
        headerShown: false,
        tabBarHideOnKeyboard: Platform.OS !== 'ios',
      }}
    >
      {rightFilteredItems().map((item) => {
        return (
          <Tab.Screen
            key={item.route}
            name={item.route}
            component={item.component}
            options={{
              ...item.screenOptions,
              tabBarShowLabel: false,
              unmountOnBlur: true,
              tabBarButton: (props) => <TabButton {...props} item={item} />,
              title: item.label,
            }}
            listeners={({ navigation }) => ({
              blur: () => navigation.setParams({ screen: undefined }),
            })}
          />
        )
      })}
    </Tab.Navigator>
  )
}

export default BottomTabs
