import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCard = styled.View`
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  padding: ${({ theme }) => theme.spacingUnit * 0.5}px;
  width: auto;
  z-index: 100;
  width: 100%;
`
const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.5}px;
  flex-direction: row;

  ${() =>
    Platform.OS === 'web' &&
    css`
      margin: ${({ theme }) => theme.spacingUnit * 1.8}px;
      margin-top: ${({ theme }) => theme.spacingUnit * 1.3}px;
      margin-bottom: ${({ theme }) => theme.spacingUnit * 1.3}px;
    `}
`

const LeftCardWrapper = styled.View`
  min-width: 60%;
  flex: 1;
`
const RightCardWrapper = styled.View<{ hasChildren: boolean }>`
  align-items: flex-end;
  justify-content: center;
  min-width: 40%;
  width: min-content;
`
export { StyledCard, StyledCardContent, LeftCardWrapper, RightCardWrapper }
