import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Card,
  Col,
  Dropdown,
  Icon,
  Icons,
  PageTitle,
  Row,
  Spacer,
  TitleWithTag,
  Typography,
} from '../../../components'
import {
  Client,
  DeliveryNote,
  DeliveryNotesCartBatchs,
  Group,
  Pagination,
  Producer,
} from '../../../domain'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import {
  CartBatchCardContentWrapper,
  StyledButtonWrapper,
  StyledModeSwitchWrapper,
  ToursContentWrapper,
} from './ProducerBillingDeliveryNotes.styles'
import { useQuery } from '@tanstack/react-query'
import Loader from '../../../components/Loader'
import TourDeliveryNoteCard from '../../../modules/DeliveryNote/TourDeliveryNoteCard'
import { StackNavigationProp } from '@react-navigation/stack'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import DeliveryNoteProducerService from '../../../services/producer/deliveryNote'
import DirectusUtil from '../../../utilities/utils/directus'
import { Switch, View } from 'react-native'
import CardListPaginated from '../../../components/CardListPaginated'
import GeneralUtils from '../../../utilities/utils/general'
import ProducerProducerService from '../../../services/producer/producer'
import { ProducerBillingTypeEnum } from '../../../../enums'
import CartProducerService from '../../../services/producer/cart'
import FormatUtils from '../../../utilities/utils/format'
import { queryClient } from '../../../utilities/queryClient'
import ProducerBillingDateFilter from './components/ProducerBillingDateFilter'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import GroupProducerService from '../../../services/producer/group'

const currentMonth = new Date().getMonth()
const currentYear = new Date().getFullYear()
const defaultDateHour = new Date(currentYear, currentMonth, 1, 0, 0, 0)
const defaultDateHour2 = new Date(currentYear, currentMonth + 1, 1, 0, 0, 0)

type GroupOption = { label: string; id: string }

const ProducerBillingDeliveryNotesScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()

  const [displayFilters, setDisplayFilters] = useState(false)
  const [selectedClient, setSelectedClient] = useState<any>()
  const [metricsFilters, setMetricsFilters] = useState({
    start: defaultDateHour,
    end: defaultDateHour2,
  })

  const [mode, setMode] = useState<number>(1)
  const [pageSizeDeliveryNotes, setPageSizeDeliveryNotes] = useState(10)
  const [pageNumberDeliveryNotes, setPageNumberDeliveryNotes] = useState(1)

  const [groups, setGroups] = useState<Array<{ label: string; id: string }>>([])
  const [selectedGroup, setSelectedGroup] = useState<{ label: string; id: string } | null>(null)
  const [selectGroupMode, setSelectGroupMode] = useState<boolean>(true)
  const [isMultiGroup, setIsMultiGroup] = useState(false)

  const selectedProducer = getAccessInfos().producerId

  const {
    data: producerData,
    refetch: refetchProducer,
    isFetching: producerLoading,
  } = useQuery<{ producer: Producer; stepsData: TutorialStepData[] }, Error>(
    ['p_producer', getAccessInfos().producerId],
    () => ProducerProducerService.getOne(getAccessInfos().producerId),
    {
      keepPreviousData: true,
      enabled: getAccessInfos().producerId !== undefined,
    },
  )
  const {
    data: deliveryNotes,
    refetch: refetchDeliveryNotes,
    isInitialLoading: deliveryNotesLoading,
    isLoading: isLoadingDeliveryNotes,
  } = useQuery<{ data: DeliveryNote[]; pagination: Pagination }, Error>(
    ['p_billing_delivery_notes', getAccessInfos().producerId],
    () =>
      DeliveryNoteProducerService.getAll(
        undefined,
        selectedClient?.id,
        getAccessInfos().producerId,
        metricsFilters.end,
        metricsFilters.start,
        undefined,
        undefined,
        isMultiGroup ? (selectedGroup?.id !== 'all' ? selectedGroup?.id : undefined) : undefined,
      ),
  )

  const {
    data: deliveryNotesCartBatchs,
    refetch: refetchDeliveryNotesCartBatchs,
    isInitialLoading: deliveryNotesCartBatchsLoading,
    isLoading: isLoadingDeliveryNotesCartBatchs,
  } = useQuery<{ data: DeliveryNotesCartBatchs[]; pagination: Pagination; stats: any }, Error>(
    ['p_billing_cart_batchs', getAccessInfos().producerId],
    () =>
      CartProducerService.getAllCartBatchs(
        isMultiGroup ? (selectedGroup?.id !== 'all' ? selectedGroup?.id : undefined) : undefined,
        undefined,
        selectedClient ? selectedClient.id : undefined,
        selectedProducer ? selectedProducer : undefined,
        metricsFilters.end,
        metricsFilters.start,
        pageSizeDeliveryNotes,
        pageNumberDeliveryNotes,
      ),
    {
      enabled: selectedProducer !== undefined,
    },
  )

  const {
    data: clientGroups,
    refetch,
    isInitialLoading: feedLoading,
  } = useQuery<{ data: Client[]; pagination: Pagination }, Error>(
    ['p_clients'],
    () => ProducerProducerService.getAllClients(getAccessInfos().producerId, true),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: producerGroups,
    refetch: refetchGroups,
    isInitialLoading: groupsLoading,
  } = useQuery<Group[], Error>(
    ['p_groups'],
    () => GroupProducerService.getAllGroupsByProducerId(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  const onClickDeliveryNote = async (deliveryNote: DeliveryNote) => {
    if (!deliveryNote.id) return

    const deliveryNoteWithAccess = await DeliveryNoteProducerService.getOne(deliveryNote.id)
    if (!deliveryNoteWithAccess.documentId || !deliveryNoteWithAccess.accessToken) return

    window.open(
      DirectusUtil.getDocumentDownloadUrlFromId(
        deliveryNoteWithAccess.documentId,
        deliveryNoteWithAccess.accessToken,
      ),
      '_blank',
    )
  }

  const producerGroup = producerData?.producer?.producerGroups?.find(
    (producerGroup) => producerGroup?.group?.id === deliveryNotes?.data[0]?.tour?.group?.id,
  )

  useEffect(() => {
    if (producerGroups) {
      const formattedGroups = producerGroups.map((group) => ({
        label: group.label,
        id: group.id,
      }))

      setIsMultiGroup(producerGroups.length > 1)

      if (producerGroups.length === 1) {
        setSelectedGroup({
          label: producerGroups[0].label,
          id: producerGroups[0].id,
        })
        setSelectGroupMode(false)
      } else {
        setGroups([{ label: 'Tous', id: 'all' }, ...formattedGroups])
      }
    }
  }, [producerGroups])

  useEffect(() => {
    if (selectedGroup) {
      refetchDeliveryNotes()
    }
  }, [selectedGroup])

  const cartBatchsCsvCardTitle =
    'Produit\tTournées\tcommandes\tQuantité acheté\tPrix producteur unitaire\ttotal\n'
  let cartBatchsCsvContent = ''

  deliveryNotesCartBatchs?.data.map((cb) => {
    cartBatchsCsvContent += `${cb.productLabel}\t${cb.nbTours}\t${
      cb.nbCarts
    }\t${FormatUtils.formatQuantity(cb.totalQuantity, cb.mesureType)}\t${FormatUtils.formatPrice(
      cb.cartBatchUnitPriceProducer || cb.cartBatchUnitPrice,
    )}\t${FormatUtils.formatPrice(cb.totalCostProducer)}\n`
  })

  const copyCartBatchs = () => {
    navigator.clipboard.writeText(cartBatchsCsvCardTitle + cartBatchsCsvContent)
  }

  const onClickMetricsChangeDate = async (value?: number, exactDate?: any, paramDate?: string) => {
    let tempMetricsFilter = { start: metricsFilters.start, end: metricsFilters.end }
    if (exactDate && paramDate) {
      // date changed with date input
      if (paramDate === 'start') {
        tempMetricsFilter.start = exactDate
      } else if (paramDate === 'end') {
        tempMetricsFilter.end = exactDate
      }
    } else {
      // date changed with month switcher
      let currentMonthNew = metricsFilters.start.getMonth()
      let currentYear = metricsFilters.start.getFullYear()
      currentMonthNew = currentMonthNew + (value || 0)

      if (currentMonthNew < 0) {
        currentMonthNew = 11
        currentYear = currentYear - 1
      }

      if (currentMonthNew > 11) {
        currentMonthNew = 0
        currentYear = currentYear + 1
      }

      const newStartDate = new Date(currentYear, currentMonthNew, 1, 0, 0, 0)

      const newEndDate = new Date(currentYear, currentMonthNew + 1, 1, 0, 0, 0)

      tempMetricsFilter = { start: newStartDate, end: newEndDate }
    }

    await setMetricsFilters(tempMetricsFilter)
    if (!exactDate) {
      onValidateFilter()
    }
  }

  const isDateIntervalValidForBatchs = () => {
    if (selectedProducer) {
      if (GeneralUtils.diffDays(metricsFilters.start, metricsFilters.end) <= 46) {
        return true
      }
    }
    queryClient.setQueryData(['p_billing_cart_batchs', getAccessInfos().carrierGroupId], undefined)
    return false
  }

  const onValidateFilter = () => {
    refetchDeliveryNotes()
    if (isDateIntervalValidForBatchs()) {
      refetchDeliveryNotesCartBatchs()
    }
  }

  if (deliveryNotesLoading) {
    return <Loader isLoading pageLoading />
  }

  const removeFilters = async () => {
    await setSelectedClient(undefined)
    await setDisplayFilters(false)
    await setMode(1)
    setTimeout(() => {
      onValidateFilter()
    }, 500)
  }

  const onChangePageDeliveryNotes = async (pageNumber: number) => {
    await setPageNumberDeliveryNotes(pageNumber)
    onValidateFilter()
  }

  const onSwitchMode = async () => {
    const newMode = mode === 1 ? 2 : 1
    let newMetricsFilters = metricsFilters

    if (newMode === 2 && GeneralUtils.diffDays(metricsFilters.start, metricsFilters.end) > 45) {
      newMetricsFilters = {
        start: defaultDateHour,
        end: defaultDateHour2,
      }
    }

    await Promise.all([
      setMode(newMode),
      setMetricsFilters(newMetricsFilters),
      setPageNumberDeliveryNotes(1), // Réinitialiser le numéro de page
    ])

    // Appeler explicitement la fonction pour récupérer les données des batchs
    if (newMode === 2 && isDateIntervalValidForBatchs()) {
      await refetchDeliveryNotesCartBatchs()
    } else if (newMode === 1) {
      await refetchDeliveryNotes()
    }
  }

  const handleGroupChange = (value: GroupOption | null) => {
    setSelectedGroup(value)
    setSelectGroupMode(false)
    refetchDeliveryNotes()
  }

  const renderMainContent = () => {
    return (
      <>
        {!displayFilters && selectedClient && (
          <Typography.Body colorName="text-dark-3">
            Filtres : client ({selectedClient.label})
          </Typography.Body>
        )}

        {displayFilters && (
          <>
            <Typography.Body colorName="text-dark-3">Filtres</Typography.Body>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <Dropdown
              label={'Choisir un client'}
              onChange={(value) => {
                setSelectedClient(value)
                onValidateFilter()
              }}
              multiple={false}
              defaultValue={selectedClient?.id}
              optionsDefault={(clientGroups?.data || []).map((client) => ({
                ...client,
                label: client?.label,
                value: client?.id,
              }))}
              zIndex={1000}
              itemKey="producers"
              isSearchable
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <View style={{ flexDirection: 'row' }}>
              <Button
                small
                colorName="color-danger"
                label={'Supprimer filtres'}
                onPress={() => removeFilters()}
              />
              <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
              <Button
                small
                label={'Valider'}
                onPress={() => {
                  refetchDeliveryNotes()
                  if (selectedProducer && selectedProducer.id !== undefined) {
                    // refetchDeliveryNotesCartBatchs()
                  }
                  setDisplayFilters(false)
                }}
              />
            </View>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          </>
        )}

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <ProducerBillingDateFilter
          onClickMetricsChangeDate={onClickMetricsChangeDate}
          metricsFilters={metricsFilters}
          metricsLoading={false}
          refetchDeliveryNotes={onValidateFilter}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

        {selectedProducer && (
          <StyledModeSwitchWrapper>
            <Typography.Body>Affichage par BL</Typography.Body>
            <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
            <Switch
              trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
              onValueChange={() => onSwitchMode()}
              value={mode === 2}
            />
            <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
            <Typography.Body>Affichage par lots</Typography.Body>
          </StyledModeSwitchWrapper>
        )}

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

        {isLoadingDeliveryNotes || deliveryNotesLoading ? (
          <Loader isLoading />
        ) : (
          <>
            {mode === 1 ? (
              <CardListPaginated
                data={deliveryNotes?.data}
                pagination={deliveryNotes?.pagination}
                element={(deliveryNote: any) => (
                  <TourDeliveryNoteCard
                    key={deliveryNote.id}
                    deliveryNote={deliveryNote}
                    onClick={onClickDeliveryNote}
                    isCarrier
                    isBilling
                  />
                )}
                isLoading={deliveryNotesLoading}
                emptyMessage="Aucune tournée passée"
                onChangePage={onChangePageDeliveryNotes}
              />
            ) : (
              <>
                {!isDateIntervalValidForBatchs() ? (
                  <Typography.Body colorName="color-danger">
                    L'intervalle de date ne peut pas dépasser 45 jours
                  </Typography.Body>
                ) : (
                  <>
                    <StyledButtonWrapper>
                      <Button
                        small
                        colorName="color-grey"
                        label={'Copier'}
                        onPress={() => copyCartBatchs()}
                      />
                    </StyledButtonWrapper>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                    <Card>
                      <CartBatchCardContentWrapper>
                        <Row>
                          <Col xs={5}>
                            <Typography.BodySmall colorName="text-dark-1">
                              Produit
                            </Typography.BodySmall>
                          </Col>
                          <Col xs={2} alignItems="center">
                            <Typography.BodySmall colorName="text-dark-3" align="center">
                              Tournées-commandes
                            </Typography.BodySmall>
                          </Col>
                          <Col xs={2} alignItems="center">
                            <Typography.BodySmall colorName="text-dark-3" align="center">
                              Quantité acheté
                            </Typography.BodySmall>
                          </Col>
                          <Col xs={3} alignItems="flex-end" justifyContent="flex-end">
                            <Typography.BodySmall colorName="text-dark-3" align="right">
                              Prix producteur unitaire - total
                            </Typography.BodySmall>
                          </Col>
                        </Row>
                      </CartBatchCardContentWrapper>
                    </Card>

                    {deliveryNotesCartBatchs?.data.map((cb) => (
                      <>
                        <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.8} />
                        <Card>
                          <CartBatchCardContentWrapper>
                            <Row>
                              <Col xs={5}>
                                <TitleWithTag tag={cb.internalReference}>
                                  <Typography.BodySmall colorName="text-dark-1">
                                    {cb.productLabel}
                                  </Typography.BodySmall>
                                </TitleWithTag>
                              </Col>
                              <Col xs={2} alignItems="center">
                                <Typography.BodySmall colorName="text-dark-3">
                                  {cb.nbTours}-{cb.nbCarts}
                                </Typography.BodySmall>
                              </Col>
                              <Col xs={2} alignItems="center">
                                <Typography.BodySmall colorName="text-dark-3">
                                  {FormatUtils.formatQuantity(cb.totalQuantity, cb.mesureType)}
                                </Typography.BodySmall>
                              </Col>
                              <Col xs={3} alignItems="flex-end">
                                <Typography.BodySmall colorName="text-dark-3">
                                  {FormatUtils.formatPrice(
                                    cb.cartBatchUnitPriceProducer || cb.cartBatchUnitPrice,
                                  )}{' '}
                                  - {FormatUtils.formatPrice(cb.totalCostProducer)}
                                </Typography.BodySmall>
                              </Col>
                            </Row>
                          </CartBatchCardContentWrapper>
                        </Card>
                        <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.8} />
                      </>
                    ))}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            noMarginLeft
            title={`Bons de livraison ${
              selectedGroup ? `(${deliveryNotes?.pagination?.totalCount || 0})` : ''
            }`}
            buttonRight={
              producerGroup &&
              producerGroup.billingType === ProducerBillingTypeEnum.DIRECT && (
                <Button
                  small
                  label={'Filtres'}
                  onPress={() => setDisplayFilters(!displayFilters)}
                />
              )
            }
          />
          {isMultiGroup ? (
            <>
              {!groupsLoading && groups.length > 0 && (selectGroupMode || !selectedGroup) ? (
                <Dropdown
                  label={'Choisir un groupe'}
                  onChange={(value: any) => handleGroupChange(value as GroupOption | null)}
                  multiple={false}
                  defaultValue={selectedGroup?.id}
                  optionsDefault={groups}
                  zIndex={1000}
                  itemKey="producer_groups"
                />
              ) : (
                <>
                  {selectedGroup && (
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Typography.Body colorName="text-dark-3" bold>
                        {selectedGroup.id !== 'all' ? selectedGroup.label : 'Tous les groupes'}
                      </Typography.Body>
                      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.6} />
                      <Icon
                        type={Icons.MaterialCommunityIcons}
                        name="square-edit-outline"
                        size={theme.spacingUnit * 1.8}
                        color={theme.colors['text-dark-3']}
                        onPress={() => {
                          setSelectGroupMode(true)
                          setSelectedGroup(null)
                          removeFilters()
                          refetchGroups()
                        }}
                      />
                    </View>
                  )}
                  {renderMainContent()}
                </>
              )}
            </>
          ) : (
            <>
              {selectedGroup && (
                <Typography.Body colorName="text-dark-3" bold>
                  {selectedGroup.label}
                </Typography.Body>
              )}
              {renderMainContent()}
            </>
          )}
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerBillingDeliveryNotesScreen
