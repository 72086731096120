export namespace UserEnums {
  export enum STATUS {
    INVITATION = -1,
    ACTIVE = 1,
    SUSPENDED = 0,
  }

  export enum ViewTypeEnums {
    CARRIER = 1,
    PRODUCER = 2,
    CLIENT = 3,
  }

  export enum RoleEnums {
    CARRIER = 'CARRIER',
    PRODUCER = 'PRODUCER',
    CLIENT = 'CLIENT',
  }
}
