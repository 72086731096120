export enum LabelEnums {
  ORGANIC = 'ORGANIC',
  BIOCOOP = 'BIOCOOP',
}

export const LABELS_TYPES = [
  {
    label: 'BIOCOOP',
    value: LabelEnums.BIOCOOP,
  },
  {
    label: 'BIO',
    value: LabelEnums.ORGANIC,
  },
]
