import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Col, PageTitle, Row, Spacer, Typography } from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { AddUserGroup } from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper, StyledButtonText } from './CarrierAddUser.styles'
import UserCarrierService from '../../../services/carrier/user'
import InfoCard from '../../../components/InfoCard'
import { ca } from 'date-fns/locale'
import { Role } from '../../../domain/Role'
import { useQuery } from '@tanstack/react-query'
import RoleService from '../../../services/user/role'
import GeneralUtils from '../../../utilities/utils/general'

const CarrierAddUserScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierAddUser'>>()
  const [isLoading, setIsLoading] = useState(false)
  const [isAddingPassword, setIsAddingPassword] = useState(false)
  const [isCustomMail, setIsCustomMail] = useState(false)

  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: roles,
    refetch,
    isInitialLoading: rolesLoading,
  } = useQuery<Role[], Error>(['sf_roles'], () => RoleService.getRoles(), {
    keepPreviousData: true,
  })

  const {
    groupId,
    producerId,
    producerLabel,
    clientId,
    clientLabel,
    carrierId,
    carrierLabel,
    email,
  } = route?.params ?? {
    groupId: '',
    producerId: '',
    producerLabel: '',
    clientId: '',
    clientLabel: '',
    carrierId: '',
    carrierLabel: '',
  }

  const theme = useTheme()
  const [newUser, setNewClient] = useState<AddUserGroup>({
    email: email || '',
    password: '' || undefined,
    lastName: '',
    firstName: '',
    mailContent: undefined,
    mailSubject: undefined,
  })

  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newUser)) return null

    const userTemp = Object.assign({}, newUser)

    userTemp[param as keyof typeof newUser] = value
    setNewClient(userTemp)
  }

  const onAddUser = async () => {
    setErrorMessage('')
    if (!groupId) {
      setErrorMessage('Erreur de chargement du groupe')
      return
    }
    if (!clientId && !producerId && !carrierId) {
      setErrorMessage('Erreur de chargement du compte')
      return
    }
    setIsLoading(true)
    const user = {
      email: FormatUtils.cleanEmail(newUser.email),
      password: newUser.password,
      lastName: newUser.lastName,
      firstName: newUser.firstName,
      producerId: producerId || undefined,
      clientId: clientId || undefined,
      carrierId: carrierId || undefined,
      mailContent: isCustomMail ? GeneralUtils.textToHtml(newUser.mailContent) : undefined,
      mailSubject: isCustomMail ? newUser.mailSubject : undefined,
    }

    if ((isAddingPassword && !newUser.password) || newUser.password === '') {
      setIsLoading(false)
      setErrorMessage('Veuillez saisir un mot de passe ou cliquer sur "Envoyer une invitation"')
      return
    }

    /*
    if (user.carrierId !== getAccessInfos().carrierId) {
      const createdUser = await UserCarrierService.createAdmin(user, groupId)
        .then((res) => {
          queryClient.invalidateQueries(['sf_group_carrier', carrierId])
          if (navigation.canGoBack()) {
            navigation.goBack()
          } else {
            navigation.navigate('CarrierGroup', {})
          }

          setIsLoading(false)
          return true
        })
        .catch((error) => {
          setErrorMessage("Un problème est survenu lors de la création de l'utilisateur")
          setIsLoading(false)
          return false
        })
      return
    }
    */
    const createdUser = await UserCarrierService.create(user, groupId)
      .then((res) => {
        queryClient.invalidateQueries(['sf_group_carrier', carrierId])
        queryClient.invalidateQueries(['sf_group_client', clientId])
        queryClient.invalidateQueries(['sf_group_producer'])
        queryClient.invalidateQueries(['sf_group_producers'])
        queryClient.invalidateQueries(['u_sf_profiles', getAccessInfos()?.userId])
        queryClient.invalidateQueries(['u_p_profiles', getAccessInfos()?.userId])
        queryClient.invalidateQueries(['u_c_profiles', getAccessInfos()?.userId])

        if (carrierId) {
          navigation.navigate('CarrierAddAdminUser', {
            groupId: groupId,
            carrierId: carrierId,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
          })
        } else if (navigation.canGoBack()) {
          navigation.goBack()
        } else {
          navigation.navigate('CarrierGroup', {})
        }

        setIsLoading(false)
        return true
      })
      .catch((error) => {
        setErrorMessage("Un problème est survenu lors de la création de l'utilisateur")
        setIsLoading(false)
        return false
      })
  }

  const computeLabel = () => {
    if (producerId) {
      return producerLabel
    }
    if (clientId) {
      return clientLabel
    }
    if (carrierId) {
      return carrierLabel
    }
    return ''
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Ajouter un utilisateur" />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <InfoCard leftIcon="link">
              <Typography.BodySmall colorName="text-dark-3">
                Rattaché au compte {computeLabel()} :
              </Typography.BodySmall>
              <Typography.BodySmall bold>{computeLabel()}</Typography.BodySmall>
            </InfoCard>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={newUser.lastName}
                  label="Nom"
                  field="text"
                  onChangeText={(text) => setValue(text, 'lastName')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={newUser.firstName}
                  label="Prénom"
                  field="text"
                  onChangeText={(text) => setValue(text, 'firstName')}
                  // hidden={!newUser.lastName}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={newUser.email}
                  label="Email"
                  field="text"
                  onChangeText={(text) => setValue(text, 'email')}
                  // hidden={!newUser.firstName}
                />
              </Col>
              {isAddingPassword && (
                <Col xs={12} sm={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newUser.password}
                    label="Mot de passe"
                    field="password"
                    secureTextEntry
                    onChangeText={(text) => setValue(text, 'password')}
                  />
                </Col>
              )}
            </Row>

            {!isAddingPassword && (
              <>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                {isCustomMail ? (
                  <>
                    <Typography.BodySmall colorName="text-dark-1" align="center">
                      Vous pouvez personnaliser l'objet et le contenu du mail :
                    </Typography.BodySmall>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <StyledButtonText>
                      <Button.Text
                        label={'Annuler'}
                        colorName="color-grey"
                        onPress={() => {
                          setIsCustomMail(false)
                          setValue('', 'mailContent')
                          setValue('', 'mailSubject')
                        }}
                      />
                    </StyledButtonText>
                  </>
                ) : (
                  <>
                    <Typography.BodySmall colorName="text-dark-1" align="center">
                      Vous souhaitez personnaliser l'email d'invitation ?
                    </Typography.BodySmall>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <StyledButtonText>
                      <Button.Text
                        label={`Personnaliser le contenu du mail d'invitation`}
                        onPress={() => {
                          setIsCustomMail(true)
                        }}
                      />
                    </StyledButtonText>
                  </>
                )}
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

                {isCustomMail && (
                  <>
                    <TextInput
                      value={newUser.mailSubject}
                      label="Objet du mail"
                      field="text"
                      onChangeText={(text) => setValue(text, 'mailSubject')}
                    />
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={newUser.mailContent}
                      label="Contenu du mail"
                      field="text"
                      multiline
                      onChangeText={(text) => setValue(text, 'mailContent')}
                    />
                    <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
                  </>
                )}
              </>
            )}

            <StyledButtonWrapper>
              {carrierId && (
                <>
                  <Typography.BodySmall colorName="color-danger" align="center">
                    À la suite de l'ajout de l'utilisateur au compte producteur livreur, attribuer
                    un rôle d'administrateur à celui-ci dans le groupe afin qu'il puisse accéder à
                    toutes les fonctionnalités
                  </Typography.BodySmall>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                </>
              )}
              <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
              {!isAddingPassword && (
                <>
                  <Typography.BodySmall colorName="text-dark-3" align="center">
                    L'utilisateur recevra une invitation par mail afin de valider la création de son
                    compte
                  </Typography.BodySmall>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                </>
              )}
              <Button
                label={isAddingPassword ? 'Créer le compte' : 'Envoyer une invitation'}
                onPress={() => onAddUser()}
                disabled={!newUser.email}
                loading={isLoading}
              />
            </StyledButtonWrapper>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
            {isAddingPassword ? (
              <>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall colorName="text-dark-1" align="center">
                  Vous préférez envoyer un lien d'invitation directement par mail afin que
                  l'utilisateur puisse valider la création de son compte ?
                </Typography.BodySmall>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <StyledButtonText>
                  <Button.Text
                    label={'Envoyer une invitation'}
                    onPress={() => {
                      setIsAddingPassword(false)
                    }}
                  />
                </StyledButtonText>
                <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
              </>
            ) : (
              <>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall colorName="text-dark-1" align="center">
                  Vous préférez créer un mot de passe et envoyer vous-même les identifiants à
                  l'utilisateur ?
                </Typography.BodySmall>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <StyledButtonText>
                  <Button.Text
                    label={'Créer un mot de passe'}
                    onPress={() => {
                      setIsAddingPassword(true)
                      setValue('', 'mailContent')
                      setValue('', 'mailSubject')
                    }}
                  />
                </StyledButtonText>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              </>
            )}
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierAddUserScreen
