import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const ToursContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  align-items: flex-start;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`
export { ToursContentWrapper, StyledButtonWrapper }
