import React, { useState } from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import {
  Col,
  Icon,
  Icons,
  Row,
  Spacer,
  TitleWithTag,
  TouchableCard,
  Typography,
} from '../../components'
import FormatUtils from '../../utilities/utils/format'
import { ChildLine, ParentLineWithChildren } from './InvoiceLine.model'
import {
  StyledButtonWrapper,
  StyledCardContent,
  StyledCardWrapper,
  StyledCenterBox,
  StyledLeftBox,
  StyledRightBox,
  StyledSubCardContent,
  StyledValueBox,
  StyledValuesWrapper,
  TotalHTContainer,
} from './InvoiceLine.styles'

const InvoiceLine = ({ parentLine }: { parentLine: ParentLineWithChildren }) => {
  const theme = useTheme()
  const [displaySubLines, setDisplaySubLines] = useState(false)

  const toggleSubLines = () => {
    setDisplaySubLines(!displaySubLines)
  }

  const { label, sublabel, totalHT, children } = parentLine

  const renderSubLines = (subLines: ChildLine[]) => {
    return subLines.map((childLine) => (
      <StyledCardWrapper key={childLine.id}>
        <TouchableCard>
          <StyledSubCardContent>
            <Row>
              <Col xs={4}>
                <Typography.BodySmall bold colorName="color-primary">
                  {childLine.label}
                </Typography.BodySmall>
              </Col>
              <Col xs={4} alignItems="center">
                <StyledValuesWrapper>
                  <StyledValueBox>
                    <Typography.BodyExtraSmall>
                      {childLine.priceItemInfo?.packingQuantity} colis -{' '}
                      {FormatUtils.formatQuantity(
                        childLine.priceItemInfo?.totalQuantity || 0,
                        childLine.priceItemInfo?.mesureType,
                      )}
                    </Typography.BodyExtraSmall>
                  </StyledValueBox>
                </StyledValuesWrapper>
              </Col>
              <Col xs={4} alignItems="flex-end">
                <View style={{ alignItems: 'flex-end' }}>
                  <Typography.BodySmall semiBold>
                    {FormatUtils.formatPrice(childLine.priceItemInfo?.unitPrice || 0)}/
                    {FormatUtils.formatUnity(childLine.priceItemInfo?.mesureType)} -{' '}
                    {FormatUtils.formatPrice(childLine.priceItemInfo?.totalPriceHT || 0)}
                  </Typography.BodySmall>
                </View>
              </Col>
            </Row>
          </StyledSubCardContent>
        </TouchableCard>
      </StyledCardWrapper>
    ))
  }

  return (
    <>
      <StyledCardWrapper onPress={toggleSubLines} activeOpacity={0.8}>
        <TouchableCard>
          <StyledCardContent>
            <StyledLeftBox>
              <Icon
                type={Icons.MaterialCommunityIcons}
                name="file-document-outline"
                size={theme.spacingUnit * 4}
                color={theme.colors['text-dark-1']}
              />
            </StyledLeftBox>
            <StyledCenterBox>
              <TitleWithTag>
                <Typography.CardTitle
                  style={{
                    lineHeight: theme.lineHeights.CARD_TITLE,
                    maxWidth: '100%',
                  }}
                  ellipsis
                >
                  {label}
                </Typography.CardTitle>
              </TitleWithTag>
              <Typography.BodySmall ellipsis colorName="text-dark-3">
                {sublabel}
              </Typography.BodySmall>
            </StyledCenterBox>
            <StyledRightBox>
              <TotalHTContainer>
                <Typography.BodyExtraSmall colorName="text-dark-3">
                  Total HT
                </Typography.BodyExtraSmall>
                <Spacer size={0.15} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall semiBold>
                  {FormatUtils.formatPrice(totalHT || 0)}
                </Typography.BodySmall>
              </TotalHTContainer>
              <StyledButtonWrapper onPress={toggleSubLines}>
                <Icon
                  type={Icons.Ionicons}
                  name={displaySubLines ? 'chevron-up-sharp' : 'chevron-down-sharp'}
                  color={theme.colors['color-primary']}
                  size={theme.spacingUnit * 3}
                />
              </StyledButtonWrapper>
            </StyledRightBox>
          </StyledCardContent>
        </TouchableCard>
      </StyledCardWrapper>
      {displaySubLines && (
        <View style={{ width: '95%', marginLeft: '3%' }}>{renderSubLines(children)}</View>
      )}
    </>
  )
}

export default InvoiceLine
