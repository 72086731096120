import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledStepCardWrapper = styled.View`
  width: 98%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.25}px;
`
const StyledStepCard = styled.View<{ completed: boolean | undefined }>`
  width: 96%;
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  border: 1.9px solid ${({ theme }) => theme.colors && theme.colors['color-grey']};
  ${({ completed }) =>
    completed &&
    css`
      border-color: ${({ theme }) => theme.colors['color-primary']};
    `}

  flex-direction: row;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacingUnit}px;
  margin-left: 12px;
`

const CaretBox = styled.View`
  min-width: ${({ theme }) => theme.spacingUnit * 2}px;
  min-height: ${({ theme }) => theme.spacingUnit * 2}px;
`
const StyledLeftBox = styled.View<{ completed: boolean | undefined }>`
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  border: 1.75px solid ${({ theme }) => theme.colors && theme.colors['color-grey']};
  ${({ completed }) =>
    completed &&
    css`
      background-color: ${({ theme }) => theme.colors['color-primary']};
      border-color: ${({ theme }) => theme.colors['color-primary']};
    `}
  margin-left: -12px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
`
const StyledStepCardRanking = styled.View`
  /* background-color: ${({ theme }) => theme.colors && theme.colors['background-3']}; */
  width: 14px;
  height: 14px;
  padding: ${({ theme }) => theme.spacingUnit * 1.5}px;
  align-items: center;
  justify-content: center;
`
const StyledStepCardContent = styled.View`
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 1.5}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1.5}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.75}px;
  flex-wrap: wrap;
  flex: 3;
`

const StyledRightBox = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
  flex: 1;
`

export {
  StyledStepCardWrapper,
  StyledStepCard,
  StyledStepCardContent,
  StyledStepCardRanking,
  StyledLeftBox,
  StyledRightBox,
  CaretBox,
}
