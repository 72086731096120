import styled from 'styled-components/native'

const StatWrapper = styled.View`
  flex: 1;
  align-items: center;
  margin: ${({ theme }) => theme.spacingUnit * 0.6}px;
`

const StyledStatWrapper = styled.View`
  border-radius: ${({ theme }) => theme.borderRadius * 2}px;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: ${({ theme }) => theme.spacingUnit * 1.8}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 1.3}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1.3}px;
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  height: 100%;
`

const StyledCard = styled.View``

export { StatWrapper, StyledStatWrapper, StyledCard }
