import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'

import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import AddRuleForm from '../../../modules/Rule/AddRuleForm'
import { Client, ClientGroup, Pagination } from '../../../domain'
import { useQuery } from '@tanstack/react-query'
import ProducerProducerService from '../../../services/producer/producer'

const ProducerAddRuleScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerAddRule'>>()

  const [isLoading, setIsLoading] = useState(false)
  const [clients, setClients] = useState<Client[]>([])

  const { batch, productId, tourId } = route?.params ?? {
    batch: undefined,
    productId: undefined,
    tourId: undefined,
  }

  const {
    data: clientGroups,
    refetch: refetchClientGroups,
    isInitialLoading: clientGroupsLoading,
  } = useQuery<{ data: Client[]; pagination: Pagination }, Error>(
    ['p_clients'],
    () => ProducerProducerService.getAllClients(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  const onAddRule = async () => {
    await queryClient.invalidateQueries(['p_batchs'])
    await queryClient.invalidateQueries(['p_batchs_dashboard'])
    await queryClient.invalidateQueries(['p_update_computed_batch', batch?.id])
    navigation.goBack()
  }

  useEffect(() => {
    let tempClients: Client[] = []

    if (clientGroups) {
      clientGroups.data.map((clientGroup) => {
        if (clientGroup) {
          tempClients.push(clientGroup)
        }
      })
    }
    setClients(tempClients)
  }, [clientGroups])

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <AddRuleForm batch={batch} clients={clients} onAddRule={onAddRule} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerAddRuleScreen
