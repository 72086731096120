import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Icon,
  Icons,
  Spacer,
  TitleWithTag,
  TouchableCard,
  Typography,
} from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { BatchCartCardProps } from './BatchCartCard.model'
import {
  CancelButtonWrapper,
  StyledCardContent,
  StyledCardWrapper,
  StyledCrateBox,
  StyledCratesBox,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRightBox,
  StyledValueBox,
  StyledValuesWrapper,
} from './BatchCartCard.styles'
import { CartBatchCarrierStatusEnum } from '../../../../enums/cartBatch'
import CartCarrierService from '../../../services/carrier/cart'
import { queryClient } from '../../../utilities/queryClient'
import { CartBatch } from '../../../domain'
import { UserEnums } from '../../../../enums'
import { View } from 'react-native'

const BatchCartCard = ({
  cartBatch,
  onClick,
  withProducer,
  batch,
  displayClient,
  userRole,
  withPriceGain,
  withStatusButton = true,
}: BatchCartCardProps) => {
  const theme = useTheme()
  const [isIdentificationsExpanded, setIsIdentificationsExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const OnClickBatch = () => {
    onClick(cartBatch)
  }

  const batchTemp = batch || cartBatch.batch

  if (!batchTemp) return <></>
  if (!userRole) return <></>

  const identifications = cartBatch.identifications || []

  let displayedIdentifications = [...identifications]

  if (identifications.length > 8 && !isIdentificationsExpanded) {
    displayedIdentifications = displayedIdentifications.splice(0, 6)
  }

  const isStatusBatch = (status: CartBatchCarrierStatusEnum, cartBatch: CartBatch): boolean => {
    // TODO: add condition to check for producer status
    return cartBatch.carrierStatus === status
  }

  const updateCartBatchStatus = async (status: CartBatchCarrierStatusEnum) => {
    if (!cartBatch.cart || !cartBatch.batch) return
    setIsLoading(true)
    await CartCarrierService.updateCartBatch(cartBatch.cart.id, cartBatch.batch.id, {
      carrierStatus: status,
    })
      .then(() => {
        queryClient.invalidateQueries(['sf_arrival_batchs'])
        queryClient.invalidateQueries(['sf_tour_batchs'])
        queryClient.invalidateQueries(['sf_tour_cart_batchs'])
      })
      .catch((error) => {
        console.error(error)
      })
    setIsLoading(false)
  }

  const isProducer = () => {
    return userRole === UserEnums.RoleEnums.PRODUCER
  }

  const getGainPercentage = () => {
    const minPrice = batchTemp?.unitPriceProducer || 0
    const unitPriceProducer = cartBatch?.unitPriceProducer || 0
    if (!minPrice || minPrice === 0) return 0

    return (unitPriceProducer - minPrice) / minPrice
  }

  return (
    <StyledCardWrapper onPress={OnClickBatch} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.Body colorName="text-dark-1" bold>
              {cartBatch.packingQuantity} colis
            </Typography.Body>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

            {!identifications || identifications?.length < 1 ? (
              <View>
                <View>
                  <Typography.BodySmall colorName="text-dark-3">
                    Identification
                  </Typography.BodySmall>
                </View>

                <Typography.BodySmall colorName="text-dark-3">à générer</Typography.BodySmall>
              </View>
            ) : null}
            <StyledCratesBox>
              {displayedIdentifications?.map((identification) => (
                <StyledCrateBox key={identification} activeOpacity={1}>
                  <Typography.Body colorName="text-light-1">{identification}</Typography.Body>
                </StyledCrateBox>
              ))}
              {identifications.length > 8 && (
                <StyledCrateBox
                  key={'more'}
                  noBackground
                  onPress={() => setIsIdentificationsExpanded(!isIdentificationsExpanded)}
                >
                  <Typography.Body colorName="text-light-1">
                    {!isIdentificationsExpanded ? '...' : ' - '}
                  </Typography.Body>
                </StyledCrateBox>
              )}
            </StyledCratesBox>
          </StyledLeftBox>
          <StyledMiddleBox>
            <TitleWithTag tag={batchTemp.product?.internalReference}>
              <Typography.Body bold ellipsis>
                {batchTemp.product?.label}
              </Typography.Body>
            </TitleWithTag>

            {withProducer && (
              <Typography.BodySmall colorName="text-dark-3">
                {batchTemp.product?.producer?.label}
              </Typography.BodySmall>
            )}
            {displayClient && cartBatch?.cart?.client?.label && (
              <Typography.BodySmall colorName="text-dark-3">
                {cartBatch?.cart?.client?.label}
              </Typography.BodySmall>
            )}

            <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />

            {isProducer() && withPriceGain ? (
              <StyledValuesWrapper>
                <StyledValueBox>
                  <Typography.BodySmall colorName="text-dark-3">Prix min.</Typography.BodySmall>
                  <Typography.Body semiBold>
                    {FormatUtils.formatPrice(batchTemp.unitPriceProducer)}/
                    {FormatUtils.formatUnity(batchTemp.product?.mesureType)}
                  </Typography.Body>
                </StyledValueBox>
                <StyledValueBox>
                  <Typography.BodySmall colorName="text-dark-3">Prix réalisé</Typography.BodySmall>
                  <Typography.Body semiBold>
                    {FormatUtils.formatPrice(cartBatch.unitPriceProducer)}/
                    {FormatUtils.formatUnity(batchTemp.product?.mesureType)}
                  </Typography.Body>
                </StyledValueBox>
                <StyledValueBox>
                  <Typography.BodySmall colorName="text-dark-3">Gain</Typography.BodySmall>
                  <Typography.Body
                    semiBold
                    colorName={getGainPercentage() > 0 ? 'color-primary' : 'text-dark-1'}
                  >
                    {getGainPercentage() > 0
                      ? `+${FormatUtils.formatPercentage(getGainPercentage())}`
                      : '-'}
                  </Typography.Body>
                </StyledValueBox>
              </StyledValuesWrapper>
            ) : null}
            <StyledValuesWrapper>
              <StyledValueBox>
                <Typography.BodySmall colorName="text-dark-3">
                  Prix {isProducer() ? 'client' : ''}
                </Typography.BodySmall>
                <Typography.Body semiBold>
                  {FormatUtils.formatPrice(cartBatch.unitPrice)}/
                  {FormatUtils.formatUnity(batchTemp.product?.mesureType)}
                </Typography.Body>
              </StyledValueBox>
              <StyledValueBox>
                <Typography.BodySmall colorName="text-dark-3">Colis de</Typography.BodySmall>
                <Typography.Body semiBold>
                  {FormatUtils.formatQuantity(cartBatch.packing, batchTemp.product?.mesureType)}
                </Typography.Body>
              </StyledValueBox>
              <StyledValueBox>
                <Typography.BodySmall colorName="text-dark-3">Quantité</Typography.BodySmall>
                <Typography.Body semiBold>
                  {FormatUtils.formatQuantity(
                    cartBatch.totalQuantity,
                    batchTemp.product?.mesureType,
                  )}
                </Typography.Body>
              </StyledValueBox>
            </StyledValuesWrapper>
          </StyledMiddleBox>

          {userRole === UserEnums.RoleEnums.CARRIER && withStatusButton !== false && (
            <StyledRightBox>
              <StyledValuesWrapper>
                <StyledValueBox>
                  {isStatusBatch(CartBatchCarrierStatusEnum.PREPARED, cartBatch) ? (
                    <>
                      <Icon
                        type={Icons.Ionicons}
                        name="checkmark-circle-outline"
                        color={theme.colors['color-primary']}
                      />
                      <Typography.BodySmall colorName="text-dark-3" semiBold>
                        Préparé
                      </Typography.BodySmall>
                      <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
                      <CancelButtonWrapper
                        onPress={() => updateCartBatchStatus(CartBatchCarrierStatusEnum.RECEIVED)}
                      >
                        <Typography.BodyExtraSmall semiBold colorName="text-light-1">
                          Annuler
                        </Typography.BodyExtraSmall>
                      </CancelButtonWrapper>
                    </>
                  ) : (
                    <Button
                      small
                      lowPadding
                      label={'Préparé'}
                      onPress={() => updateCartBatchStatus(CartBatchCarrierStatusEnum.PREPARED)}
                      loading={isLoading}
                    />
                  )}
                </StyledValueBox>
              </StyledValuesWrapper>
            </StyledRightBox>
          )}
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default BatchCartCard
