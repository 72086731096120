import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, Spacer, Typography } from '../../../components'
import { CartBatch, DeliveryNote, ProducersFake, StepsFake, Tour, ToursFake } from '../../../domain'
import i18n from '../../../i18n'
import TourProducerCard from '../../../modules/Tour/TourProducerCard'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import CarrierTourDeliveryNotesTopBox from './components/CarrierTourDeliveryNotesTopBox'
import { StyledButtonWrapper, ToursContentWrapper } from './CarrierTourDeliveryNotes.styles'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import DeliveryNoteCarrierService from '../../../services/carrier/deliveryNote'
import Loader from '../../../components/Loader'
import TourDeliveryNoteCard from '../../../modules/DeliveryNote/TourDeliveryNoteCard'
import { StackNavigationProp } from '@react-navigation/stack'
import { queryClient } from '../../../utilities/queryClient'
import { DeliveryNoteTypeEnum, MailEnums, ProducerBillingTypeEnum } from '../../../../enums'
import CardSettings from '../../../components/CardSettings'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierTourDeliveryNotesScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourDeliveryNotes'>>()
  const [isLoadingGenerate, setIsLoadingGenerate] = useState(false)
  const [deliveryNotesDocumentRefreshs, setDeliveryNotesDocumentRefreshs] = useState<number>(0)

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const { id, type } = route?.params ?? { id: '', type: '' }

  const { data: tour, isInitialLoading: tourLoading } = TourCarrierService.getOne.query(id)

  let QueryFn = ''
  if (type === 1) {
    QueryFn = 'sf_tour_delivery_notes'
  }
  if (type === 2) {
    QueryFn = 'sf_tour_delivery_orders'
  }
  if (type === 3) {
    QueryFn = 'sf_tour_delivery_producers'
  }

  const {
    data: deliveryNotes,
    refetch: refetchDeliveryNotes,
    isInitialLoading: deliveryNotesLoading,
  } = useQuery<DeliveryNote[], Error>(
    [QueryFn, id],
    () => DeliveryNoteCarrierService.getAllTour(id, type),
    {
      keepPreviousData: true,
    },
  )

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  const onClickGenerateDeliveryNote = async () => {
    if (!tour?.id) return
    setIsLoadingGenerate(true)
    if (type === 1) {
      await DeliveryNoteCarrierService.generate(tour.id)
    } else if (type === 2) {
      await DeliveryNoteCarrierService.generateOrders(tour.id)
    } else if (type === 3) {
      await DeliveryNoteCarrierService.generateProducers(tour.id)
    }

    setTimeout(async () => {
      await refetchDeliveryNotes()
      await queryClient.invalidateQueries(['sf_tour_delivery_notes', tour.id])
      await queryClient.invalidateQueries(['sf_tour_delivery_notes_orders', tour.id])
      await queryClient.invalidateQueries(['sf_tour_delivery_notes_producers', tour.id])

      setIsLoadingGenerate(false)
    }, 6000)
  }

  if (tourLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour) return null

  const producersTour = tour.producerTour || []
  const carts = tour.carts || []

  const nbPossibleDeliveryNotesType = () => {
    if (type === 1) {
      return nbPossibleDeliveryNotes()
    }
    if (type === 2) {
      return nbPossibleDeliveryNotesOrders()
    }
    if (type === 3) {
      return nbPossibleDeliveryNotesProducers()
    }
    return 0
  }

  const nbPossibleDeliveryNotes = () => {
    let nbDeliveryNotes = 0
    producersTour.map((producerTour) => {
      const producerGroup = producerTour.producer.producerGroups?.find(
        (pg) => pg?.group?.id === tour?.group?.id,
      )
      if (!producerGroup?.billingType || producerGroup.billingType === 1) {
        nbDeliveryNotes += producerTour.nbOrders || 0
      }
    })
    return nbDeliveryNotes
  }

  const nbPossibleDeliveryNotesOrders = () => {
    let nbDeliveryNotes = 0
    carts.map((cart) => {
      let hasProducerBillingType2 = false
      const cartBatchs = cart.cartBatchs || []
      cartBatchs.map((cartBatch: CartBatch) => {
        const producerGroup = cartBatch?.batch?.product?.producer?.producerGroups?.find(
          (pg) => pg?.group?.id === tour?.group?.id,
        )

        if (producerGroup?.billingType === ProducerBillingTypeEnum.CARRIER) {
          hasProducerBillingType2 = true
        }
      })
      if (hasProducerBillingType2) {
        nbDeliveryNotes += 1
      }
    })
    return nbDeliveryNotes
  }

  const nbPossibleDeliveryNotesProducers = () => {
    let nbDeliveryNotes = 0
    producersTour.map((producerTour) => {
      const producerGroup = producerTour.producer.producerGroups?.find(
        (pg) => pg?.group?.id === tour?.group?.id,
      )
      if (
        producerTour?.nbOrders &&
        producerGroup?.billingType === ProducerBillingTypeEnum.CARRIER
      ) {
        nbDeliveryNotes += 1
      }
    })
    return nbDeliveryNotes
  }

  const onDeleteAll = async () => {
    if (!deliveryNotes) return
    setIsLoadingGenerate(true)
    for (const deliveryNote of deliveryNotes) {
      if (deliveryNote.id) {
        await DeliveryNoteCarrierService.deleteOne(deliveryNote.id)
      }
    }
    await refetchDeliveryNotes()

    setIsLoadingGenerate(false)
  }

  const onClickDeliveryNotePrint = () => {}

  const isAllDeliveryNotesExist = () => {
    if (!deliveryNotes) return false

    return deliveryNotes.every((deliveryNote) => deliveryNote.documentId)
  }

  const refetchDeliveryNoteForDocument = async () => {
    if (deliveryNotes && !isAllDeliveryNotesExist() && deliveryNotesDocumentRefreshs < 3) {
      setTimeout(() => {
        refetchDeliveryNotes()
        setDeliveryNotesDocumentRefreshs(deliveryNotesDocumentRefreshs + 1)
      }, 10000)
    }
  }

  const inLoadingDnMessage = () => {
    if (!isAllDeliveryNotesExist()) {
      return 'Tous les BLs doivent être générés avant de pouvoir envoyer les mails.'
    }
  }

  useEffect(() => {
    if (deliveryNotes && !isAllDeliveryNotesExist()) {
      refetchDeliveryNoteForDocument()
    }
  }, [deliveryNotes, deliveryNotesDocumentRefreshs])

  const onClickDeliveryNote = (deliveryNote: DeliveryNote) => {
    if (!deliveryNote.id) return
    navigation.navigate('CarrierTourDeliveryNote', {
      deliveryNoteId: deliveryNote.id,
    })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const onSendDeliveryNotesMail = async () => {
    if (!deliveryNotes || deliveryNotes.length < 1) return
    if (!isAllDeliveryNotesExist()) {
      return
    }

    setIsLoading(true)
    setErrorMessage('')
    for (const deliveryNote of deliveryNotes) {
      if (!mailSend(deliveryNote)) {
        await onSendMail(deliveryNote)
      }
    }
    await refetchDeliveryNotes()
    setIsLoading(false)
  }

  const onSendMail = async (deliveryNote: DeliveryNote) => {
    if (!deliveryNote || !deliveryNote.id) return

    const dto = {
      mails: undefined,
    }

    const sendedMailDeliveryNote = await DeliveryNoteCarrierService.sendMail(deliveryNote.id, dto)
    if (sendedMailDeliveryNote && sendedMailDeliveryNote.id) {
    } else {
      setErrorMessage(`Un problème est survenu lors de l'envoie du mail pour ce BL`)
    }
  }

  const mailSend = (deliveryNote: DeliveryNote, mailType?: MailEnums): boolean => {
    let isSend = false
    const history = deliveryNote.mailsStatus?.history
    if (history && history.length) {
      const foundHistory = history.find((h) => h.type === mailType)
      isSend = foundHistory !== null
    }

    return isSend
  }

  const mailsTosend = () => {
    let mailsToSendTemp = 0
    deliveryNotes?.map((deliveryNote) => {
      if (!mailSend(deliveryNote)) {
        mailsToSendTemp += 1
      }
    })
    return mailsToSendTemp
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`BL tournée #${getTourIdFormatted()}`}
            buttonRight={
              <>
                <Typography.BodySmall>
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                  {'\n'}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </>
            }
          />

          <CarrierTourDeliveryNotesTopBox
            tour={tour}
            onClickDeliveryNotePrint={onClickDeliveryNotePrint}
            emailsToSend={mailsTosend() ? mailsTosend().toString() : '-'}
            deliveryNotesType={FormatUtils.getLabelFromDeliveryNoteType(type)}
          />
          {errorMessage ? (
            <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
          ) : null}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          <Typography.SectionTitle>
            Bons de livraison ({(deliveryNotes || []).length | 0}/{nbPossibleDeliveryNotesType()})
          </Typography.SectionTitle>
          {(deliveryNotes || []).map((deliveryNote) => (
            <TourDeliveryNoteCard
              key={deliveryNote.id}
              deliveryNote={deliveryNote}
              onClick={onClickDeliveryNote}
              isCarrier
            />
          ))}
          {(deliveryNotes || []).length !== nbPossibleDeliveryNotesType() && (
            <StyledButtonWrapper>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <Button
                label={`Générer les bons manquants (${
                  nbPossibleDeliveryNotesType() - (deliveryNotes || []).length
                })`}
                onPress={() => onClickGenerateDeliveryNote()}
                loading={isLoadingGenerate}
              />
            </StyledButtonWrapper>
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
          {mailsTosend() > 0 && (
            <CardSettings
              title="Envoyer tous les BL"
              description={
                !isAllDeliveryNotesExist()
                  ? inLoadingDnMessage()
                  : 'Envoyer les BL par mail aux producteurs / clients'
              }
              children={
                <Button
                  label={`Envoyer les mails (${mailsTosend()})`}
                  small
                  onPress={() => onSendDeliveryNotesMail()}
                  loading={
                    isLoading || (!isAllDeliveryNotesExist() && deliveryNotesDocumentRefreshs < 3)
                  }
                  disabled={mailsTosend() < 1}
                  fullWidth={true}
                  colorName="color-grey"
                />
              }
            />
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

          {(deliveryNotes || []).length > 0 && (
            <CardSettings
              title="Supprimer tous les BL"
              description={`Si vous avez effectué plusieurs changements.`}
              children={
                <Button
                  label={'Supprimer tous les BLs'}
                  fullWidth={true}
                  small
                  onPress={() => onDeleteAll()}
                  loading={isLoadingGenerate}
                  colorName="color-grey"
                  hasDoubleValidation
                  confirmationLabel="Êtes-vous sûr de vouloir supprimer tous les BLs ?"
                />
              }
            />
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourDeliveryNotesScreen
