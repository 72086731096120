import { City } from '../../../domain'
import ApiService from '../../api.service'

const create = async (newCity: any): Promise<City> => {
  const response = await ApiService.post(`/city/`, newCity).catch((error) => {
    throw error
  })
  return response
}

const getAll = async (search?: string): Promise<City[]> => {
  const response = await ApiService.get(`/city`, { search })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const CityService = {
  create,
  getAll,
}

export default CityService
