import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardParam = styled.View`
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  border-radius: 10px;
  min-height: 50px;
`

const StyledCardContent = styled.TouchableOpacity``

const StyledBox = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledLeftBox = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacingUnit * 0.7}px;
`

const StyledRightBox = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 0.9}px;
`

export { StyledCardParam, StyledCardContent, StyledBox, StyledLeftBox, StyledRightBox }
