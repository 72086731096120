import React, { useEffect, useState } from 'react'
import { Spacer } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import IntroductionSlide from './slides/IntroductionSlide'
import TourStartSlide from './slides/TourStartSlide'
import TourDestinationSlide from './slides/TourDestinationSlide'
import { AddTour, Group, Producer } from '../../../domain'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import { useQuery } from '@tanstack/react-query'
import ProducerProducerService from '../../../services/producer/producer'
import TourDepositSlide from './slides/TourDepositSlide'
import TourCloseSlide from './slides/TourCloseSlide'
import TourOpenSlide from './slides/TourOpenSlide'
import TourChronologySlide from './slides/TourChronologySlide'
import TourEndSlide from './slides/TourEndSlide'
import GroupCarrierService from '../../../services/carrier/group'
import { queryClient } from '../../../utilities/queryClient'
import TourCarrierService from '../../../services/carrier/tour'
import { UserEnums } from '../../../../enums'

const carrierTourModes = [
  {
    label: 'Introduction',
    value: 'INTRODUCTION',
  },
  {
    label: 'Producteurs',
    value: 'PRODUCERS',
  },
  {
    label: 'Clients',
    value: 'CLIENTS',
  },
  {
    label: 'Documents',
    value: 'DOCUMENTS',
  },
  {
    label: 'Dépôt',
    value: 'DEPOSIT',
  },
  {
    label: 'Ouverture',
    value: 'OPENING',
  },
  {
    label: 'Fermeture',
    value: 'CLOSING',
  },
  {
    label: 'Chronologie',
    value: 'CHRONOLOGY',
  },
  {
    label: 'End',
    value: 'END',
  },
]

const ProducerAddGroupLandingScreen = () => {
  const { getAccessInfos, changeViewType } = useAuthenticationContext()
  const [selectedMode, setSelectedMode] = useState<string | number>(carrierTourModes[0].value)
  const [modePreselected, setModePreselected] = useState<Boolean>(true)
  const [hasDeliveredBefore, setHasDeliveredBefore] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [carrierId, setCarrierId] = useState<string | undefined>()

  const defaultDateHour = new Date()
  defaultDateHour.setUTCHours(-2)
  defaultDateHour.setUTCMinutes(0)
  const [newTour, setNewTour] = useState<AddTour>({
    start: defaultDateHour,
    end: defaultDateHour,
    deposit: defaultDateHour,
    open: defaultDateHour,
    close: defaultDateHour,
    cityId: undefined,
    carrierId: undefined,
  })

  const {
    data: producerData,
    refetch: refetchProducer,
    isInitialLoading: producerLoading,
  } = useQuery<{ producer: Producer; stepsData: TutorialStepData[] }, Error>(
    ['p_producer', getAccessInfos().producerId],
    () => ProducerProducerService.getOne(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )
  if (!producerData?.producer) return <></>

  let mode = 'INTRODUCTION'

  useEffect(() => {
    if (mode && modePreselected) {
      setSelectedMode(mode)
      setModePreselected(false)
    } else if (selectedMode) {
      setSelectedMode(selectedMode)
    }
  }, [selectedMode])

  const createGroup = async (): Promise<Group | undefined> => {
    const group = {
      producerId: getAccessInfos().producerId,
    }

    const createdGroup = await GroupCarrierService.createGroup('', group)
      .then((response) => {
        queryClient.invalidateQueries(['u_profiles', getAccessInfos()?.userId])
        queryClient.invalidateQueries(['p_groups'])
        return response
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la création du groupe')
      })

    if (createdGroup) return createdGroup
    else return undefined
  }

  const createTour = async (groupId: string, carrierId: string, tour: AddTour) => {
    const createdTour = await TourCarrierService.createAsProducer(groupId, carrierId, {
      ...tour,
      carrierId,
    })
      .then((createdTour) => {
        return createdTour
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la création de la tournée')
      })

    return createdTour || undefined
  }

  const onClickStep1 = async (hasDeliveredBefore: boolean) => {
    setHasDeliveredBefore(hasDeliveredBefore)
    setSelectedMode('PRODUCERS')
  }
  const onClickStep2 = (tourToAdd: AddTour) => {
    setNewTour((currentTour) => {
      return {
        ...currentTour,
        start: tourToAdd.start,
        end: tourToAdd.end,
        carrierId: tourToAdd.carrierId,
      }
    })
    setSelectedMode('DOCUMENTS')
  }
  const onClickStep3 = (tourToAdd: AddTour) => {
    setNewTour((currentTour) => {
      return {
        ...currentTour,
        cityId: tourToAdd.cityId,
      }
    })
    setSelectedMode('DEPOSIT')
  }

  const onClickStep4 = (tourToAdd: AddTour) => {
    setNewTour((currentTour) => {
      return {
        ...currentTour,
        deposit: tourToAdd.deposit,
      }
    })
    setSelectedMode('CLOSING')
  }

  const onClickStep5 = (tourToAdd: AddTour) => {
    setNewTour((currentTour) => {
      return {
        ...currentTour,
        close: tourToAdd.close,
      }
    })
    setSelectedMode('OPENING')
  }

  const onClickStep6 = (tourToAdd: AddTour) => {
    setNewTour((currentTour) => {
      return {
        ...currentTour,
        open: tourToAdd.open,
      }
    })
    setSelectedMode('CHRONOLOGY')
  }

  const onClickStep7 = async () => {
    await createGroup().then(async (createdGroup) => {
      if (createdGroup) {
        const carrier = createdGroup.carrierGroups.find(
          (cg) => cg.carrier?.account.id === producerData.producer.account?.id,
        )?.carrier
        setNewTour((currentTour) => {
          return {
            ...currentTour,
            groupId: createdGroup.id,
            carrierId: carrier?.id,
          }
        })
        if (!carrier) return
        setCarrierId(carrier.id)
        await createTour(createdGroup.id, carrier.id, newTour)
        setSelectedMode('END')
      }
    })
  }

  const onClickStep8 = async () => {
    await queryClient.cancelQueries()
    await queryClient.clear()
    await queryClient.resetQueries()
    changeViewType(UserEnums.ViewTypeEnums.CARRIER, carrierId)
  }

  const renderSelectedComponent = () => {
    switch (selectedMode) {
      case 'INTRODUCTION':
        return <IntroductionSlide onClick={onClickStep1} />
      case 'PRODUCERS':
        return (
          <TourStartSlide
            onClick={onClickStep2}
            tour={newTour}
            hasDeliveredBefore={hasDeliveredBefore}
          />
        )
      case 'DOCUMENTS':
        return (
          <TourDestinationSlide
            onClick={onClickStep3}
            producer={producerData.producer}
            tour={newTour}
          />
        )
      case 'DEPOSIT':
        return <TourDepositSlide onClick={onClickStep4} tour={newTour} />
      case 'CLOSING':
        return <TourCloseSlide onClick={onClickStep5} tour={newTour} />
      case 'OPENING':
        return <TourOpenSlide onClick={onClickStep6} tour={newTour} />
      case 'CHRONOLOGY':
        return <TourChronologySlide onClick={onClickStep7} tour={newTour} />
      case 'END':
        return <TourEndSlide onClick={onClickStep8} />
      default:
        return null
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper marginTopLarge>
        {renderSelectedComponent()}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerAddGroupLandingScreen
