import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Spacer,
  Typography,
  PageTitle,
  Card,
  Icon,
  Icons,
  DynamicImage,
} from '../../../components'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'

import {
  LeftBoxWrapper,
  RightBoxWrapper,
  MiddleBox,
  LeftBox,
  StyledCardWrapper,
  BoxWrapper,
  TopBoxWrapper,
  BottomBoxWrapper,
  BoxWrapperProfile,
  StyledButtonWrapper,
  DynamicImageWrapper,
  RightBox,
} from './ProducerProfile.styles'
import { TouchableOpacity } from 'react-native'
import DirectusUtil from '../../../utilities/utils/directus'
import { Producer } from '../../../domain'
import ProducerProducerService from '../../../services/producer/producer'
import ProducerGallery from '../ProducerPublicProfile/components/ProducerGallery'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const ProducerProfileScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const {
    data: producerData,
    refetch: refetchProducer,
    isFetching: producerLoading,
  } = useQuery<{ producer: Producer; stepsData: TutorialStepData[] }, Error>(
    ['p_producer', getAccessInfos().producerId],
    () => ProducerProducerService.getOne(getAccessInfos().producerId),
    {
      keepPreviousData: true,
      enabled: getAccessInfos().producerId !== undefined,
    },
  )

  return (
    <ScreenSafeAreaWrapper>
      <ContentWrapper noPaddingLeft>
        <PageTitle title={'Compte producteur'} />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <ScrollableFormWrapper>
          {producerData?.producer && (
            <>
              <Typography.Body colorName="text-dark-3">
                Votre profile publique (visible par les clients)
              </Typography.Body>
              <StyledCardWrapper>
                <Card>
                  <BoxWrapperProfile>
                    <TopBoxWrapper>
                      <LeftBox>
                        <DynamicImageWrapper>
                          <DynamicImage
                            togglesModal
                            imageUri={DirectusUtil.getProducerImageUrlFromId(
                              producerData?.producer as any as Producer,
                            )}
                          />
                        </DynamicImageWrapper>
                      </LeftBox>
                      <MiddleBox>
                        <Typography.CardTitle>{producerData?.producer.label}</Typography.CardTitle>
                        <Typography.BodySmall bold colorName="text-dark-3">
                          {producerData?.producer.owners} - {producerData?.producer.location}
                        </Typography.BodySmall>
                      </MiddleBox>
                      <RightBox>
                        <TouchableOpacity
                          onPress={() =>
                            navigation.navigate('ProfileProducerNav', {
                              screen: 'ProducerPublicProfile',
                              params: {},
                            })
                          }
                        >
                          <Icon
                            type={Icons.MaterialCommunityIcons}
                            name="pencil"
                            size={theme.spacingUnit * 3}
                            color={theme.colors['color-primary']}
                          />
                        </TouchableOpacity>
                      </RightBox>
                    </TopBoxWrapper>
                    <BottomBoxWrapper>
                      <Typography.BodySmall colorName="text-dark-3">
                        {producerData?.producer.description}
                      </Typography.BodySmall>
                    </BottomBoxWrapper>
                  </BoxWrapperProfile>
                </Card>
              </StyledCardWrapper>
            </>
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />

          <StyledCardWrapper>
            <Card>
              <BoxWrapper>
                <LeftBoxWrapper>
                  <Typography.Body colorName="color-grey" bold>
                    {'Mon profil public'}
                  </Typography.Body>
                  <Typography.BodySmall>
                    {'Personalisez votre présentation pour vos clients'}
                  </Typography.BodySmall>
                </LeftBoxWrapper>
                <RightBoxWrapper>
                  <StyledButtonWrapper>
                    <Button
                      style={{ width: '100%' }}
                      label="Personnaliser"
                      small
                      onPress={() =>
                        navigation.navigate('ProfileProducerNav', {
                          screen: 'ProducerPublicProfile',
                          params: {},
                        })
                      }
                    />
                  </StyledButtonWrapper>
                </RightBoxWrapper>
              </BoxWrapper>
            </Card>
          </StyledCardWrapper>
          <StyledCardWrapper>
            <Card>
              <BoxWrapper>
                <LeftBoxWrapper>
                  <Typography.Body colorName="color-grey" bold>
                    {'Mes informations'}
                  </Typography.Body>
                  <Typography.BodySmall>
                    {'Utilisées dans vos bons de livraison'}
                  </Typography.BodySmall>
                </LeftBoxWrapper>
                <RightBoxWrapper>
                  <StyledButtonWrapper>
                    <Button
                      style={{ width: '100%' }}
                      label="Modifier"
                      small
                      onPress={() =>
                        navigation.navigate('ProfileProducerNav', {
                          screen: 'ProducerInformations',
                          params: {},
                        })
                      }
                    />
                  </StyledButtonWrapper>
                </RightBoxWrapper>
              </BoxWrapper>
            </Card>
          </StyledCardWrapper>
          <StyledCardWrapper>
            <Card>
              <BoxWrapper>
                <LeftBoxWrapper>
                  <Typography.Body colorName="color-grey" bold>
                    {'Certificats'}
                  </Typography.Body>
                  <Typography.BodySmall>
                    {'Partagez vos certificats avec vos clients'}
                  </Typography.BodySmall>
                </LeftBoxWrapper>
                <RightBoxWrapper>
                  <StyledButtonWrapper>
                    <Button
                      style={{ width: '100%' }}
                      label="Consulter"
                      small
                      onPress={() =>
                        navigation.navigate('ProfileProducerNav', {
                          screen: 'ProducerCertificates',
                          params: {},
                        })
                      }
                    />
                  </StyledButtonWrapper>
                </RightBoxWrapper>
              </BoxWrapper>
            </Card>
          </StyledCardWrapper>
          <StyledCardWrapper>
            <Card>
              <BoxWrapper>
                <LeftBoxWrapper>
                  <Typography.Body colorName="color-grey" bold>
                    {'Notifications'}
                  </Typography.Body>
                  <Typography.BodySmall>{'Notifications, alertes, etc.'}</Typography.BodySmall>
                </LeftBoxWrapper>
                <RightBoxWrapper>
                  <StyledButtonWrapper>
                    <Button
                      style={{ width: '100%' }}
                      label="Gérer"
                      small
                      onPress={() =>
                        navigation.navigate('ProfileProducerNav', {
                          screen: 'ProducerSettingsScreen',
                          params: {},
                        })
                      }
                    />
                  </StyledButtonWrapper>
                </RightBoxWrapper>
              </BoxWrapper>
            </Card>
          </StyledCardWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
          {producerData?.producer?.galleryImagesIds &&
            producerData?.producer?.galleryImagesIds?.length > 0 && (
              <ProducerGallery
                mode="view"
                galleryImagesIds={DirectusUtil.getProducerGalleryImageUrlFromId(
                  producerData?.producer as any as Producer,
                )}
              />
            )}
        </ScrollableFormWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerProfileScreen
