import styled from 'styled-components/native'

const StyledGainBox = styled.View`
  padding: ${({ theme }) => theme.spacingUnit * 0.2}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.7}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.7}px;
  background-color: ${({ theme }) => theme.colors['color-secondary']};
  border-radius: ${({ theme }) => theme.borderRadius}px;
`

export { StyledGainBox }
