import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Spacer,
  PageTitle,
  DocumentPickerInput,
  Icon,
  Icons,
  Dropdown,
  DatePicker,
  Typography,
} from '../../../components'
import { AddDocument } from '../../../domain'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import {
  StyledButtonWrapper,
  StyledImageBox,
  StyledImageRight,
  StyledButtonsWrapper,
  StyledImageLeft,
  StyledDownloadButtonWrapper,
} from './ProducerAddCertificate.styles'
import { DocumentEnums } from '../../../../enums/document'
import DocumentProducerService from '../../../services/producer/document'
import { queryClient } from '../../../utilities/queryClient'

const ProducerAddCertificateScreen = () => {
  const theme = useTheme()
  const [newDocument, setNewDocument] = useState<AddDocument>({
    documentUri: '',
    expiration: undefined,
    type: undefined,
    status: undefined,
  })

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [selectedDocumentStatus, setSelectedDocumentStatus] = useState<any | undefined>(undefined)
  const [selectedDocumentType, setSelectedDocumentType] = useState<any | undefined>(undefined)

  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newDocument)) return null

    const documentTemp = Object.assign({}, newDocument)

    documentTemp[param as keyof typeof newDocument] = value
    setNewDocument(documentTemp)
  }

  const onChangeDate = (value: any) => {
    setErrorMessage('')

    const documentTemp = Object.assign({}, newDocument)
    documentTemp.expiration = value

    setNewDocument(documentTemp)
  }

  const onAddProducerDocument = async () => {
    if (
      !newDocument.documentUri ||
      !selectedDocumentStatus ||
      !selectedDocumentType ||
      !newDocument.expiration
    ) {
      setErrorMessage('Veuillez remplir tous les champs')
      return
    }
    setErrorMessage('')
    setIsLoading(true)

    const document = {
      documentUri: newDocument.documentUri,
      expiration: newDocument.expiration,
      status: selectedDocumentStatus.value,
      type: selectedDocumentType.value,
    }

    await DocumentProducerService.createDocumentProducer(document, getAccessInfos().producerId)
      .then((updatedDocument) => {
        queryClient.invalidateQueries(['p_documents'])
        navigation.navigate('ProfileProducerNav', {
          screen: 'ProducerCertificates',
          params: {},
        })
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la mise à jour de votre certificat')
      })
    setIsLoading(false)
  }

  const onChangeDocumentStatus = (value: any) => {
    setSelectedDocumentStatus(value)
  }

  const onChangeDocumentType = (value: any) => {
    setSelectedDocumentType(value)
  }

  const onChangeProducerDocument = (itemsDroppedWithId: any[]) => {
    if (itemsDroppedWithId && itemsDroppedWithId[0]) {
      setIsDisabled(true)

      setValue(itemsDroppedWithId[0].uri, 'documentUri')
      setTimeout(() => {
        setIsDisabled(false)
      }, 3000)
    }
  }

  return (
    <ScreenSafeAreaWrapper>
      <ContentWrapper noPaddingLeft>
        <PageTitle title={'Ajouter un certificat'} />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <ScrollableFormWrapper>
          <StyledImageBox>
            <StyledImageLeft>
              <Icon
                type={Icons.MaterialCommunityIcons}
                name={newDocument.documentUri ? 'text-box-check-outline' : 'camera-off-outline'}
                size={theme.spacingUnit * 4.5}
                color={
                  newDocument.documentUri
                    ? theme.colors['color-primary']
                    : theme.colors['color-grey']
                }
              />
            </StyledImageLeft>
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <StyledImageRight>
              <Typography.Body bold>{'Votre certificat'}</Typography.Body>
              <StyledDownloadButtonWrapper>
                <DocumentPickerInput
                  customAcceptedFileExtensions={['png', 'jpg', 'jpeg', 'pdf']}
                  onAddFiles={onChangeProducerDocument}
                  label="Charger un nouveau document"
                />
              </StyledDownloadButtonWrapper>
            </StyledImageRight>
          </StyledImageBox>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <Dropdown
            label={'Type de certificat'}
            onChange={onChangeDocumentType}
            multiple={false}
            optionsDefault={DocumentEnums.DocumentTypes.map((documentType) => ({
              ...documentType,
            }))}
            itemKey="documentType"
            zIndex={1000}
          />

          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <Dropdown
            label={'Visibilité'}
            onChange={onChangeDocumentStatus}
            multiple={false}
            optionsDefault={DocumentEnums.DocumentStatuses.map((documentStatus) => ({
              ...documentStatus,
            }))}
            itemKey="documentStatus"
          />

          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <DatePicker
            id="documentexpirationdate"
            label="Date d'expiration"
            mode="date"
            onChange={onChangeDate}
            value={newDocument.expiration}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {errorMessage ? (
            <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
          ) : null}
          <StyledButtonsWrapper>
            <StyledButtonWrapper>
              <Button label="Ajouter" onPress={onAddProducerDocument} loading={isLoading} />
            </StyledButtonWrapper>
          </StyledButtonsWrapper>
        </ScrollableFormWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerAddCertificateScreen
