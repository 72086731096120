export namespace GroupEnums {
  export enum GroupTypeEnum {
    DELIVERY_PRODUCERS = 1,
    SUPER_PRODUCER = 5,
  }

  export enum GroupRequestStatusEnum {
    REJECTED = -10,
    PENDING = 0,
    APPROVED = 10,
  }

  export enum GroupPublicStatusEnum {
    HIDDEN = 0,
    OPEN = 5,
    PUBLIC = 10,
  }

  export enum GroupStatusEnum {
    ONBOARDING = 5,
    ACTIVE = 10,
  }

  export const GROUP_TYPES = [
    {
      label: 'Producteurs-livreurs',
      value: GroupTypeEnum.DELIVERY_PRODUCERS,
    },
    {
      label: 'Super-producteur',
      value: GroupTypeEnum.SUPER_PRODUCER,
    },
  ]

  export const GROUP_PUBLIC_STATUS = [
    {
      label: 'Caché : Seulement les producteurs qui reçoivent une invitation',
      value: GroupPublicStatusEnum.HIDDEN,
    },
    {
      label: `Invisible : Seul les producteurs selectionnés par l'équipe Harvy`,
      value: GroupPublicStatusEnum.OPEN,
    },
    {
      label: `Public : Tout les producteurs à proximité`,
      value: GroupPublicStatusEnum.PUBLIC,
    },
  ]
}
