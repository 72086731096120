import React from 'react'
import {
  CardWrapper,
  StyledLeftBox,
  StyledRightBox,
  StyledTopBox,
  TourTopBoxWrapper,
} from './TourTopBox.styles'
import { useTheme } from 'styled-components/native'

import { TourTopBoxProps } from './TourTopBox.model'
import Card from '../Card'
import Typography from '../Typography'
import Spacer from '../Spacer'
import FormatUtils from '../../utilities/utils/format'
import TouchableCard from '../TouchableCard'
import { CartBatch, Order } from '../../domain'
import DeviceUtil from '../../utilities/utils/device'
import Icon, { Icons } from '../Icon'
import { RightKeyEnum, UserEnums } from '../../../enums'
import { TouchableOpacity } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useNavigation } from '@react-navigation/native'
import { CarrierStackParamList } from '../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import useAuthenticationContext from '../../utilities/hook/useAuthenticationContext'

function TourTopBox({ tour, cart, userRole }: TourTopBoxProps) {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const { carrierHasRight } = useAuthenticationContext()

  const getOrderIdFormatted = (order?: Order) => {
    if (order && order.id && order.id.length > 5) {
      return order.id.slice(-5)
    }
    return 'NA'
  }

  const getStats = (cartBatchs?: any) => {
    let totalPackingQuantity = 0
    let totalHT = 0

    cartBatchs?.map((cartBatch: CartBatch) => {
      totalPackingQuantity += cartBatch.packingQuantity || 0
      totalHT += (cartBatch.unitPriceProducer || 0) * (cartBatch.totalQuantity || 0)
    })

    return {
      totalPackingQuantity,
      totalHT,
    }
  }

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }
  const onClickEditTour = () => {
    tour && navigation.navigate('CarrierUpdateTour', { id: tour.id })
  }
  const isDesktop = !DeviceUtil.isMobileApp()
  const hasUpdateRight = carrierHasRight(RightKeyEnum.C_TOUR_UPDATE)

  return (
    <TourTopBoxWrapper isDesktop={isDesktop}>
      <CardWrapper>
        <TouchableCard>
          <StyledTopBox>
            <StyledLeftBox>
              {cart ? (
                <>
                  <Typography.Body bold>
                    Commande #{getOrderIdFormatted(cart?.order)}
                  </Typography.Body>
                  <Typography.BodyExtraSmall>
                    Validée le {FormatUtils.formatDate(cart?.order?.createdDate, 'DateShort')} à{' '}
                    {FormatUtils.formatDate(cart?.order?.createdDate, 'Time')}
                  </Typography.BodyExtraSmall>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                  <Typography.Body bold>{cart?.client?.label}</Typography.Body>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
                  <Typography.BodySmall>
                    {getStats(cart.cartBatchs).totalPackingQuantity} colis -{' '}
                    {userRole === UserEnums.RoleEnums.CARRIER
                      ? FormatUtils.formatPrice(cart?.totalHTBatchs || 0, 'ht')
                      : FormatUtils.formatPrice(getStats(cart.cartBatchs).totalHT, 'ht')}
                  </Typography.BodySmall>
                </>
              ) : (
                <>
                  <Typography.Body bold>Tournée #{getTourIdFormatted()}</Typography.Body>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.2} />
                  <Typography.Body bold align="center">
                    {FormatUtils.formatDate(tour?.start.toString(), 'FullDate')} -{' '}
                    {FormatUtils.formatDate(tour?.start, 'Time')} à{' '}
                    {FormatUtils.formatDate(tour?.end, 'Time')}
                  </Typography.Body>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.2} />
                  <Typography.Body>{FormatUtils.getTourTarget(tour, 'long')}</Typography.Body>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.3} />
                  <Typography.BodySmall semiBold>
                    Dépôt le {FormatUtils.formatDate(tour?.deposit, 'FullDate')} avant{' '}
                    {FormatUtils.formatDate(tour?.deposit, 'Time')}
                  </Typography.BodySmall>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.3} />
                  <Typography.BodySmall ellipsis>chez {tour?.carrier?.name}</Typography.BodySmall>
                </>
              )}
            </StyledLeftBox>
            {userRole === UserEnums.RoleEnums.CARRIER && (
              <StyledRightBox>
                <TouchableOpacity onPress={hasUpdateRight ? () => onClickEditTour() : undefined}>
                  <Icon
                    type={Icons.MaterialCommunityIcons}
                    name="square-edit-outline"
                    size={theme.spacingUnit * 2.5}
                    color={theme.colors['color-primary']}
                  />
                </TouchableOpacity>
              </StyledRightBox>
            )}
          </StyledTopBox>
        </TouchableCard>
      </CardWrapper>
    </TourTopBoxWrapper>
  )
}

export default TourTopBox
