export namespace ProductEnums {
  export enum MESURE_TYPE {
    KILO = 1,
    PIECE = 2,
  }

  export const MESURE_TYPES = [
    {
      label: 'en kilo',
      value: 1,
    },
    {
      label: 'à la pièce',
      value: 2,
    },
  ]

  export enum VAT_ENUM {
    VAT55 = 5.5,
    VAT10 = 10,
    VAT20 = 20,
  }

  export const VATS = [
    {
      label: '5,5%',
      value: 5.5,
      valueDecimal: '5.50',
    },
    {
      label: '10%',
      value: 10,
      valueDecimal: '10.00',
    },
    {
      label: '20%',
      value: 20,
      valueDecimal: '20.00',
    },
  ]
}
