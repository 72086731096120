import { useNavigation, useFocusEffect, NavigationProp } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { Alert, BackHandler, InteractionManager, Image, Dimensions } from 'react-native'
// import * as SplashScreen from 'expo-splash-screen'
import { Button, Spacer, Typography } from '../../../components'
// import { EnumBiometricResult } from '../../utilities/context/authenticationContext/biometricAuthentication/biometricAuthentication.utils'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import StyledBottomButtonsWrapper from './LockedScreen.styles'
// import { AppStackParamList } from '../../../navigation/AppNavigationStack/AppNavigationStack.model'
// import splashScreenImage from '../../../../assets/splash-dark.png'

const LockedScreen = () => {
  // const navigate = useNavigation<NavigationProp<AppStackParamList>>()
  const { logout, loadStoredSession, biometricStatus } = useAuthenticationContext()
  // const splashScreenImageUri = Image.resolveAssetSource(splashScreenImage).uri

  useFocusEffect(
    React.useCallback(() => {
      // wait for the screen and navigation to be rendered
      const task = InteractionManager.runAfterInteractions(() => {
        ;(async () => {
          // await SplashScreen.hideAsync()
        })()
      })

      return () => task.cancel()
    }, []),
  )

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      Alert.alert('Please unlock the app to continue')
      return true
    })
    return () => backHandler.remove()
  }, [])

  /*
  useEffect(() => {
    
    if (biometricStatus === EnumBiometricResult.SUCCESS) {
      if (navigate.canGoBack()) {
        navigate.goBack()
      } else {
        navigate.navigate('MainNavigation')
      }
    }
    
  }, [biometricStatus, navigate])
  */

  /*
  if (biometricStatus === EnumBiometricResult.PRE_PENDING) {
    return (
      <Image
        source={{ uri: splashScreenImageUri }}
        style={{ width: Dimensions.get('screen').width, height: Dimensions.get('screen').height }}
        resizeMode="contain"
      />
    )
  }
  */

  return (
    <ScreenSafeAreaWrapper>
      <ContentWrapper>
        <Typography.Title style={{ textAlign: 'center' }}>
          Please unlock the app to continue
        </Typography.Title>
      </ContentWrapper>
      <StyledBottomButtonsWrapper>
        <Button label="Try again" onPress={loadStoredSession} fullWidth />
        <Spacer size={2} />
        <Button label="Login" onPress={logout} outlined fullWidth />
      </StyledBottomButtonsWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default LockedScreen
