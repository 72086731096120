import { useEffect, useState } from 'react'
import { AddProducersTourProps } from './AddProducersTour.model'
import { Button, Dropdown, SectionTitle, Spacer, Typography } from '../../../../../components'
import InviteProducerForm from './InviteProducerForm'
import ItemCard from '../../../../../modules/Common/ItemCard'
import {
  AddProducersTourWrapper,
  BottomContentWrapper,
  StyledButtonWrapper,
  StyledButtonsWrapper,
} from './AddProducersTour.styles'
import { queryClient } from '../../../../../utilities/queryClient'
import TourCarrierService from '../../../../../services/carrier/tour'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { View } from 'react-native'
import FormatUtils from '../../../../../utilities/utils/format'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import { useQuery } from '@tanstack/react-query'
import { Pagination, ProducerGroup } from '../../../../../domain'
import GroupCarrierService from '../../../../../services/carrier/group'

const AddProducersTour = ({ group, tour, onGoBack }: AddProducersTourProps) => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [selectedProducers, setSelectedProducers] = useState<string[] | undefined>(undefined)
  const [newProducers, setNewProducers] = useState<any[]>([])
  const [showForm, setShowForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: producerGroups,
    refetch: refetchProducerGroups,
    isLoading: isProducerGroupsLoading,
  } = useQuery<{ data: ProducerGroup[]; pagination: Pagination }, Error>(
    ['sf_group_producers', 500, 1],
    () =>
      GroupCarrierService.getOneGroupProducers(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        500,
        1,
        undefined,
      ),
    {
      keepPreviousData: true,
      staleTime: 600000, // 10min
    },
  )

  const onChangeProducers = (values: any) => {
    if (values && values.length > 0) {
      const producers: string[] = []
      values.map((item: any) => {
        if (item.value) {
          producers.push(item?.value)
        }
      })
      setSelectedProducers(producers)
    } else {
      setSelectedProducers([])
    }
  }

  const producerNotInTour = producerGroups?.data.filter(
    (producerGroup) =>
      !tour?.producerTour?.find(
        (producer) => producer?.producer?.id === producerGroup?.producer?.id,
      ),
  )

  const handleInviteProducer = async (newProducerData: any) => {
    if (!group?.id) return

    setShowForm(false)
    setNewProducers((prevProducers) => [...prevProducers, newProducerData])

    if (newProducerData?.id) {
      queryClient.invalidateQueries(['sf_tour_producers'])
      queryClient.invalidateQueries(['sf_group_producers'])
    }
  }

  const itemCardDescription = (producer: any) => {
    if (!producer.firstName && !producer.lastName) return producer.email
    return `${producer.firstName} ${producer.lastName} - ${producer.email}`
  }

  const removeProducer = (producerToRemove: any) => {
    setNewProducers((tempProducers) =>
      tempProducers.filter((producer) => producer.label !== producerToRemove.label),
    )
  }

  const onValidateProducersToTour = async () => {
    setIsLoading(true)

    if (!tour) return

    const selectedProducerIds = selectedProducers?.map((producer) => producer) ?? []

    if ((selectedProducers && selectedProducers?.length > 0) || newProducers.length > 0) {
      await TourCarrierService.inviteProducerTour(tour?.id, {
        producerIds: selectedProducerIds,
        producersToCreate: newProducers || [],
      })
    }

    setIsLoading(false)
    queryClient.invalidateQueries(['sf_tour', tour?.id])
    navigation.navigate('CarrierTour', { id: tour?.id })
  }

  const handleGoBack = () => {
    setShowForm(false)
  }

  const renderProducersMessage = (selectedProducers: string[], newProducers: any[]) => {
    const hasSelectedProducers = selectedProducers && selectedProducers.length > 0
    const hasNewProducers = newProducers.length > 0

    return (
      (hasSelectedProducers || hasNewProducers) && (
        <Typography.BodySmall align="center">
          {`Vous ajoutez ${selectedProducers?.length || 0} ${FormatUtils.pluralizeAmountLabel(
            'producteur',
            selectedProducers?.length || 0,
            true,
          )} à la tournée${
            hasNewProducers
              ? `, et ${newProducers.length} ${
                  newProducers.length > 1 || newProducers.length === 0
                    ? 'qui ne sont pas encore dans votre groupe et qui recevront une invitation.'
                    : "qui n'est pas encore dans votre groupe et qui recevra une invitation."
                }`
              : '.'
          }`}
        </Typography.BodySmall>
      )
    )
  }

  return (
    <>
      {!showForm ? (
        <>
          <AddProducersTourWrapper>
            <View>
              <SectionTitle title="Sélectionner des producteurs de votre groupe :" />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Dropdown
                label={'Producteurs de votre groupe'}
                onChange={onChangeProducers}
                multiple={true}
                defaultValue={selectedProducers}
                optionsDefault={
                  !producerNotInTour
                    ? []
                    : producerNotInTour?.map((producerGroup) => ({
                        value: producerGroup?.producer?.id,
                        label: producerGroup?.producer?.label,
                      }))
                }
                itemKey="Producer"
              />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
              <SectionTitle title="Invitez des nouveaux producteurs" />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <Typography.BodySmall>
                Vous souhaitez inviter un producteur à cette tournée qui n'est pas encore dans votre
                groupe ?
              </Typography.BodySmall>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              {newProducers.map((producer) => {
                return (
                  <ItemCard
                    title={producer.label}
                    description={itemCardDescription(producer)}
                    iconType="DELETE"
                    onClick={() => removeProducer(producer)}
                  />
                )
              })}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <StyledButtonWrapper>
                <Button
                  label="Inviter un nouveau producteur"
                  onPress={() => setShowForm(true)}
                  small
                />
              </StyledButtonWrapper>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            </View>
            <BottomContentWrapper>
              <View>{renderProducersMessage(selectedProducers ?? [], newProducers ?? [])}</View>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
              <StyledButtonsWrapper>
                <StyledButtonWrapper>
                  <Button
                    label={'Retour'}
                    onPress={() => onGoBack('LOGISTIC_COMMISSION')}
                    colorName="color-grey"
                  />
                </StyledButtonWrapper>
                <StyledButtonWrapper>
                  <Button
                    label="Terminer"
                    onPress={() => onValidateProducersToTour()}
                    loading={isLoading}
                  />
                </StyledButtonWrapper>
              </StyledButtonsWrapper>
            </BottomContentWrapper>
          </AddProducersTourWrapper>
        </>
      ) : (
        <InviteProducerForm
          group={group}
          onInviteProducer={handleInviteProducer}
          onGoBack={handleGoBack}
        />
      )}
    </>
  )
}

export default AddProducersTour
