import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Card,
  Col,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import Loader from '../../../components/Loader'
import {
  Batch,
  BatchComputed,
  Group,
  Pagination,
  PriceStatement,
  ProducerGroup,
  Product,
  Tour,
  UpdateBatchCarrier,
} from '../../../domain'
import i18n from '../../../i18n'
import BatchCarrierService from '../../../services/carrier/batch'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import {
  BatchContentWrapper,
  StyledButtonWrapper,
  StyledCardContent,
  StyledCardWrapper,
  StyledGainBox,
} from './CarrierUpdateBatch.styles'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../services/carrier/tour'
import BatchUtil from '../../../utilities/utils/batch'
import { BATCH_TYPES } from '../../../../enums/batch'
import { TextTag } from '../../../components/Text/Text.model'
import PriceReportingCarrierService from '../../../services/carrier/priceReporting'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import GroupCarrierService from '../../../services/carrier/group'
import TourUtil from '../../../utilities/utils/tour'
import { View } from 'react-native'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import { GroupEnums } from '../../../../enums/group'
import useGroupContext from '../../../utilities/hook/useGroupContext'

const CarrierUpdateBatchScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const { group } = useGroupContext()

  const theme = useTheme()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdateBatch'>>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [crateQuantityString, setCrateQuantityString] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [displayBatchToursInfos, setDisplayBatchToursInfos] = useState<boolean>(false)

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [product, setProduct] = useState<Product>()

  const { batchId, tourId } = route?.params ?? { batchId: '', tourId: '' }
  const [updatedBatch, setUpdatedBatch] = useState<UpdateBatchCarrier>()

  const { data: tour } = TourCarrierService.getOne.query(tourId)

  const {
    data: batch,
    refetch: refetchBatch,
    isInitialLoading: batchLoading,
  } = useQuery<Batch, Error>(
    ['sf_batch', batchId],
    () => BatchCarrierService.getOneBatch(batchId),
    {
      enabled: batchId !== '',
    },
  )

  const {
    data: priceStatements,
    refetch: refetchPriceStatements,
    isInitialLoading: priceStatementsLoading,
  } = useQuery<PriceStatement[], Error>(
    ['p_price_statements', tour?.id],
    () =>
      PriceReportingCarrierService.getAllPriceStatement(
        getAccessInfos().carrierGroupId,
        tour?.start ? new Date(tour?.start) : undefined,
        tour?.city?.id,
      ),
    {
      keepPreviousData: true,
      enabled: tour !== undefined,
    },
  )

  const {
    data: producerGroups,
    refetch: refetchProducerGroups,
    isLoading: isProducerGroupsLoading,
  } = useQuery<{ data: ProducerGroup[]; pagination: Pagination }, Error>(
    ['sf_group_producers', 500, 1],
    () =>
      GroupCarrierService.getOneGroupProducers(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        500,
        1,
        undefined,
      ),
    {
      keepPreviousData: true,
      staleTime: 600000, // 10min
    },
  )

  const {
    data: updatedComputedBatch,
    refetch: refetchUpdatedComputedBatch,
    isInitialLoading: updatedComputedBatchLoading,
  } = useQuery<BatchComputed | null, Error>(
    ['p_update_computed_batch', batchId],
    () =>
      BatchCarrierService.getComputed(
        product?.id,
        {
          start: updatedBatch?.start ? new Date(updatedBatch?.start) : undefined,
          end: updatedBatch?.end ? new Date(updatedBatch?.end) : undefined,
          unitPriceProducer: updatedBatch?.unitPriceProducer
            ? FormatUtils.stringToFloat(updatedBatch?.unitPriceProducer)
            : undefined,
          carrierFees: updatedBatch?.carrierFees
            ? FormatUtils.stringToFloat(updatedBatch?.carrierFees)
            : undefined,
          createdDate: batch?.createdDate ? new Date(batch.createdDate) : undefined,
        },
        tourId,
      ),
    {
      enabled:
        product !== undefined &&
        updatedBatch !== undefined &&
        updatedBatch.unitPriceProducer !== '',
    },
  )

  useEffect(() => {
    if (batch) {
      setUpdatedBatch({
        ...batch,
        unitPriceProducer: FormatUtils.floatToString(batch.unitPriceProducer),
        unitPrice: FormatUtils.floatToString(batch.unitPrice),
        carrierFees: FormatUtils.floatToString(batch.carrierFees),
        totalQuantity: batch.totalQuantity.toString(),
        availableQuantity: batch.availableQuantity,
        start: new Date(batch.start),
        end: new Date(batch.end),
      })
      setCrateQuantityString((batch.totalQuantity / (batch.product?.packing || 1)).toString())

      setProduct(batch.product)
    }
  }, [batch, setProduct])

  const setValue = async (value: any, param: string) => {
    setErrorMessage('')

    if (!updatedBatch || (!param && !(param in updatedBatch))) return null
    const batchTemp = Object.assign({}, updatedBatch)

    if (param === 'unitPrice') {
      // On verifie que le prix de vente n'est pas inférieur au prix producteur
      /*
      const valueCheck = parseFloat(value.replace(',', '.'))
      let currentUnitPriceProducer =
        batchComputed.computed?.pricesStats?.producerPrice.min || batch?.unitPriceProducer || 0

      if (
        value === '' ||
        value.indexOf('%') !== -1 ||
        value === undefined ||
        typeof valueCheck !== 'number' ||
        valueCheck <= 0 ||
        valueCheck < currentUnitPriceProducer ||
        valueCheck === undefined
      ) {
        setErrorMessage(`Le prix est invalide, il doit être supérieur au prix producteur.`)
      }

      const tempNewCom = Math.round((valueCheck / currentUnitPriceProducer - 1) * 10000) / 100
      batchTemp.unitPrice = value
      batchTemp.carrierFees = tempNewCom.toString().replace('.', ',')
      */
    } else if (param === 'carrierFees') {
      batchTemp.carrierFees = value
    } else if (param === 'cancelCarrierFees') {
      let comission = batch?.tour?.carrierFees || 20
      batchTemp.unitPrice = getUnitPrice(batchTemp.unitPriceProducer || '', false, comission)
      batchTemp.carrierFees = undefined
    } else {
      batchTemp[param as keyof typeof updatedBatch] = value
    }

    await setUpdatedBatch(batchTemp)
    refetchUpdatedComputedBatch()
  }

  const onUpdateBatch = async () => {
    if (!controlPrices()) {
      setErrorMessage(
        'Le prix de vente ne peux pas être inférieur ou égale à 0, ni inférieur au prix producteur',
      )
      return
    }
    setErrorMessage('')
    if (!updatedBatch || !batch || !batch.id) return
    setIsLoading(true)
    const batchTemp = {
      unitPrice: FormatUtils.stringToFloat(updatedBatch.unitPrice),
      carrierFees: FormatUtils.stringToFloat(updatedBatch.carrierFees),
      totalQuantity: FormatUtils.stringToFloat(updatedBatch.totalQuantity),
    }

    const updatedBatchTemp = await BatchCarrierService.update(batch.id, batchTemp)
    if (updatedBatchTemp && updatedBatchTemp.id) {
      await onConfirm()
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour du lot')
    }
    setIsLoading(false)
  }

  const onConfirm = async () => {
    await queryClient.invalidateQueries(['sf_batch', batchId])
    await queryClient.setQueryData(['p_update_computed_batch', batchId], null)
    await queryClient.invalidateQueries(['sf_product_batchs', tourId])

    await queryClient.invalidateQueries(['sf_cart'])
    await queryClient.invalidateQueries(['sf_tour_batchs'])

    navigation.goBack()
  }

  if (batchLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour || !updatedBatch || !product) return null

  const controlPrices = () => {
    /*
    const unitPrice = FormatUtils.stringToFloat(updatedBatch.unitPrice)
    const unitPriceProducer = FormatUtils.stringToFloat(updatedBatch.unitPriceProducer)

    if (unitPrice < 0.01) return false
    if (unitPrice < unitPriceProducer) return false
    */

    return true
  }

  const getCarrierFees = (isWholsaler: boolean, comission?: number, value?: string) => {
    const batchCarrierFees = comission || parseFloat(updatedBatch.carrierFees || '')
    const carrierFees =
      batchCarrierFees && batchCarrierFees > 0 ? batchCarrierFees : batch?.tour?.carrierFees
    let unitPriceProducer = updatedBatch.unitPriceProducer
    unitPriceProducer = unitPriceProducer
    if (!unitPriceProducer || !carrierFees) return 0

    const carrierCom = FormatUtils.stringToFloat(unitPriceProducer) * (carrierFees / 100)

    return carrierCom > 0 ? carrierCom : 0
  }

  const getPlatformFees = (isWholsaler: boolean, value?: string) => {
    return 0
  }

  const getUnitPrice = (unitPriceProducer: string, isWholsaler: boolean, comission: number) => {
    const carrierFees = getCarrierFees(isWholsaler, comission, unitPriceProducer)
    const platformFees = getPlatformFees(isWholsaler, unitPriceProducer)

    const unitPrice = FormatUtils.stringToFloat(unitPriceProducer) + carrierFees + platformFees

    return FormatUtils.floatToString(Math.round(unitPrice * 100) / 100)
  }

  const onChangeCrateQuantity = (crateQuantityString: string) => {
    if (!crateQuantityString || crateQuantityString === '0') {
      setValue('0', 'totalQuantity')
    }
    const crateQuantity = parseFloat(crateQuantityString)
    const totalQuantity = crateQuantity * (product?.packing || 1)
    setValue(totalQuantity.toString(), 'totalQuantity')
    setCrateQuantityString(crateQuantityString)
  }

  const getSoldQuantity = () => {
    const orderedQuantity = (batch?.totalQuantity || 0) - (batch?.availableQuantity || 0)

    return orderedQuantity
  }

  const getAvailableQuantity = () => {
    return parseFloat(updatedBatch?.totalQuantity || '0') - getSoldQuantity()
  }

  const onClickAddPrice = () => {
    if (!priceStatements || priceStatements.length === 0) return
    navigation.navigate('PriceReportingsCarrierNavigationConfiguration', {
      screen: 'CarrierPriceReporting',
      params: {
        id: priceStatements[0].priceReporting.id,
        productTypeId: batch?.product?.productType?.id,
        batchId: batch?.id,
        tourId: tour?.id,
      },
    })
  }

  const producerAdditionnalCarrierFees = () => {
    if (!producerGroups || !producerGroups?.data) return 0
    const foundProducerGroup = producerGroups?.data.find(
      (pg) => pg.producer?.id === batch?.product?.producer?.id,
    )

    return foundProducerGroup?.additionalCarrierFees || 0
  }

  if (!batch || !group || !producerGroups?.data) return <Loader isLoading pageLoading />

  const batchComputed = BatchUtil.getBatchComputed({
    ...updatedBatch,
    computed: updatedComputedBatch ? updatedComputedBatch : batch.computed,
    id: batch.id,
    start: new Date(updatedBatch.start || new Date()).toString(),
    end: new Date(updatedBatch.end || new Date()).toString(),
    unitPriceProducer: FormatUtils.stringToFloat(updatedBatch.unitPriceProducer),
    unitPrice: FormatUtils.stringToFloat(updatedBatch.unitPrice),
    totalQuantity: FormatUtils.stringToFloat(updatedBatch.totalQuantity),
    carrierFees: FormatUtils.stringToFloat(updatedBatch.carrierFees),
    availableQuantity: batch.availableQuantity,
    orderedQuantity: batch.orderedQuantity,
  })

  const groupTypeIsDeliveryProducers = group?.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <BatchContentWrapper>
          <PageTitle title={FormatUtils.capitalize(i18n.t('pageTitleUpdateBatch'))} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Card>
              <Typography.Body>{FormatUtils.capitalize(i18n.t('product'))}</Typography.Body>
              <Typography.CardTitle>{product.label}</Typography.CardTitle>
            </Card>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Card>
              <Typography.Body>Disponibilité</Typography.Body>
              <Typography.CardTitle>
                {BATCH_TYPES.find((bt) => bt.value === batch.type)?.label} -{' '}
                {batchComputed.computed?.periodLabel}
              </Typography.CardTitle>
              <Typography.BodySmall colorName="text-dark-3">
                Disponible pour la tournée vers {FormatUtils.getTourTarget(tour)} du{' '}
                {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate+StartTime'))}
              </Typography.BodySmall>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              <Button.Text
                label={`${
                  displayBatchToursInfos ? '-' : '+'
                } d'informations sur les tournées et prix proposés`}
                onPress={() => setDisplayBatchToursInfos(!displayBatchToursInfos)}
                colorName="text-dark-3"
                small
                textTag={TextTag.BODY_EXTRA_SMALL}
              />
              {displayBatchToursInfos && (
                <>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                  <Typography.BodySmall colorName="text-dark-3" bold>
                    Tournées où ce lot sera proposé :{' '}
                  </Typography.BodySmall>
                  {batchComputed.computed?.pricesParams?.map((ps) => (
                    <>
                      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.2} />
                      <Typography.BodySmall colorName="text-dark-1" bold>
                        - {ps.tour.city?.label} -{' '}
                        {FormatUtils.formatDate(ps.tour.start, 'FullDate+StartTime')}
                      </Typography.BodySmall>
                      <Typography.BodySmall colorName="text-dark-1">
                        Prix producteur : {FormatUtils.formatPrice(ps.producerPrice)}/
                        {FormatUtils.formatUnity(batch.product?.mesureType)} - Com. logistique :{' '}
                        {FormatUtils.formatPercentage(ps.carrierFees / 100)} - Prix client :{' '}
                        {FormatUtils.formatPrice(ps.unitPrice)}/
                        {FormatUtils.formatUnity(batch.product?.mesureType)}
                      </Typography.BodySmall>
                    </>
                  ))}
                </>
              )}
            </Card>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

            {batchComputed.computed?.pricesParams?.find(
              (priceParam) => priceParam.priceStatement === undefined,
            ) &&
              group?.hasPricingModule &&
              batchComputed.computed.pricesStats?.producerPriceBellow.min === undefined && (
                <Row>
                  <Col>
                    <Typography.BodySmall colorName="color-warning">
                      Ce produit n'a pas de relevé de prix
                    </Typography.BodySmall>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <StyledButtonWrapper>
                      <Button
                        small
                        colorName="text-dark-3"
                        label={'Ajouter un relevé de prix'}
                        onPress={() => onClickAddPrice()}
                        loading={isLoading}
                        disabled={errorMessage !== undefined && errorMessage !== ''}
                      />
                    </StyledButtonWrapper>
                  </Col>
                  <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
                </Row>
              )}
            <>
              <SectionTitle title={`Prix du lot`} />
              <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
            </>

            <Row>
              <Col xs={6} sm={6} md={4} lg={4} alignItems={'center'}>
                <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                <StyledCardWrapper>
                  <Card>
                    <StyledCardContent>
                      <Typography.BodyExtraSmall>Prix départ minimum </Typography.BodyExtraSmall>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                      <Typography.BodySmall bold>
                        {FormatUtils.formatPrice(batchComputed.unitPriceProducer)}/
                        {FormatUtils.formatUnity(batch.product?.mesureType)}
                      </Typography.BodySmall>
                      <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                      {group?.hasPricingModule &&
                        batchComputed.computed?.pricesStats?.producerPriceBellow?.min && (
                          <>
                            <Typography.BodyExtraSmall>Prix conseillé </Typography.BodyExtraSmall>
                            <Typography.BodySmall bold colorName="color-warning">
                              {FormatUtils.formatPrice(
                                batchComputed.computed?.pricesStats?.producerPriceBellow?.min,
                              )}
                              /{FormatUtils.formatUnity(batch.product?.mesureType)}
                            </Typography.BodySmall>
                          </>
                        )}
                    </StyledCardContent>
                  </Card>
                </StyledCardWrapper>
              </Col>
              <Col xs={6} sm={6} md={4} lg={4} alignItems={'center'}>
                <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                <StyledCardWrapper>
                  <Card>
                    <StyledCardContent>
                      <Typography.BodyExtraSmall>Prix départ réel</Typography.BodyExtraSmall>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                      <Typography.BodySmall bold>
                        {batchComputed.computed?.priceParams?.producerPricesFormatted}/
                        {FormatUtils.formatUnity(batch.product?.mesureType)}
                      </Typography.BodySmall>
                      <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                      {batchComputed.computed?.priceParams?.producerPriceGainPercentage && (
                        <StyledGainBox>
                          <Typography.BodyExtraSmall bold colorName="text-light-1" align="center">
                            {batchComputed.computed?.priceParams?.producerPriceGainPercentage}
                          </Typography.BodyExtraSmall>
                        </StyledGainBox>
                      )}
                    </StyledCardContent>
                  </Card>
                </StyledCardWrapper>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} alignItems={'center'}>
                <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                <StyledCardWrapper>
                  <Card>
                    <StyledCardContent>
                      <Typography.BodyExtraSmall>
                        Prix {FormatUtils.capitalize(FormatUtils.getLabelFromClientType(1))}
                      </Typography.BodyExtraSmall>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                      <Typography.BodySmall bold>
                        {batchComputed.computed?.priceParams?.unitPricesFormatted}
                      </Typography.BodySmall>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                      {batchComputed.computed?.priceParams?.carrierFeesFormatted && (
                        <Typography.BodyExtraSmall colorName="text-dark-3" align="center">
                          (incluant les {batchComputed.computed?.priceParams?.carrierFeesFormatted}{' '}
                          de frais logistique)
                        </Typography.BodyExtraSmall>
                      )}
                    </StyledCardContent>
                  </Card>
                </StyledCardWrapper>
              </Col>
            </Row>

            <SectionTitle title={`Modifier la comission pour ce lot`} />
            <Typography.BodyExtraSmall colorName="text-dark-3">
              En changeant le prix {FormatUtils.getLabelFromClientType(1)}, vous modifiez votre
              comission de transport
            </Typography.BodyExtraSmall>
            <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
            <Row>
              <Col xs={5} sm={5} md={4} lg={4} alignItems={'center'}>
                <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                <StyledCardWrapper>
                  <Card>
                    <StyledCardContent>
                      <Typography.BodyExtraSmall>Commission initiale</Typography.BodyExtraSmall>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                      <Typography.BodySmall bold>{tour.carrierFees || 0}%</Typography.BodySmall>
                      <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                      {producerAdditionnalCarrierFees() > 0 ? (
                        <Typography.BodySmall colorName="text-dark-3">
                          + {producerAdditionnalCarrierFees() || 0}% de commission dédiée
                        </Typography.BodySmall>
                      ) : undefined}
                    </StyledCardContent>
                  </Card>
                </StyledCardWrapper>
              </Col>
              <Col xs={7} sm={7} md={4} lg={4} alignItems={'center'} justifyContent="flex-start">
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedBatch.carrierFees?.toString()}
                  label={`Commission dédiée`}
                  field="text"
                  onChangeText={(text) => setValue(text, 'carrierFees')}
                  editable
                />
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} alignItems={'center'}>
                <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                {batchComputed.carrierFees ? (
                  <StyledCardWrapper>
                    <Card>
                      <StyledCardContent>
                        {batchComputed.carrierFees ? (
                          <>
                            <Typography.BodyExtraSmall>
                              Commission personnalisée
                            </Typography.BodyExtraSmall>
                            <Typography.BodySmall bold colorName="color-warning">
                              {batchComputed.carrierFees}%
                            </Typography.BodySmall>
                            <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                            {producerAdditionnalCarrierFees() > 0 ? (
                              <Typography.BodySmall colorName="text-dark-3">
                                + {producerAdditionnalCarrierFees() || 0}% de commission dédiée
                              </Typography.BodySmall>
                            ) : undefined}
                            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                            <Button.Text
                              label={`Supprimer`}
                              onPress={() => setValue('', 'cancelCarrierFees')}
                              colorName="color-danger"
                              small
                              textTag={TextTag.BODY_SMALL}
                            />
                          </>
                        ) : null}
                      </StyledCardContent>
                    </Card>
                  </StyledCardWrapper>
                ) : null}
              </Col>
            </Row>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <SectionTitle title={`Quantités en vente`} />
            <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
            <Row>
              <Col xs={5}>
                <TextInput
                  value={crateQuantityString}
                  label={`Colis disponibles`}
                  field="text"
                  onChangeText={(text) => onChangeCrateQuantity(text)}
                  editable
                />
              </Col>
              <Col xs={3} alignItems={'center'}>
                <Typography.BodySmall>
                  {FormatUtils.formatUnity(product?.mesureType)} / colis
                </Typography.BodySmall>
                <Typography.Body bold colorName="text-dark-3">
                  {FormatUtils.formatQuantity(product.packing || 1, product.mesureType)}
                </Typography.Body>
                <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall>Quantité totale</Typography.BodySmall>
                <Typography.Body bold colorName="text-dark-3">
                  {FormatUtils.formatQuantity(
                    parseFloat(updatedBatch.totalQuantity || '0'),
                    product.mesureType,
                  )}
                </Typography.Body>
              </Col>
              <Col xs={4} alignItems={'center'}>
                <Typography.BodySmall>Quantité vendu</Typography.BodySmall>
                <Typography.Body bold colorName="text-dark-3">
                  {FormatUtils.formatQuantity(getSoldQuantity() || 0, product.mesureType)}
                </Typography.Body>
                <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall>Quantité disponible</Typography.BodySmall>
                <Typography.Body
                  bold
                  colorName={getAvailableQuantity() > 0 ? 'color-primary' : 'color-danger'}
                >
                  {FormatUtils.formatQuantity(getAvailableQuantity(), product.mesureType)}
                </Typography.Body>
              </Col>
            </Row>
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            {batchComputed.computed?.statusMessage &&
            batchComputed.computed?.statusMessage?.length > 0 ? (
              <>
                <SectionTitle title={`Problèmes sur ce lot`} />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
                {batchComputed.computed.statusMessage.map((st) => (
                  <>
                    <Typography.Body bold colorName="color-danger">
                      {st.title}
                    </Typography.Body>
                    <Typography.BodySmall colorName="color-danger">
                      {st.details}
                    </Typography.BodySmall>
                  </>
                ))}
              </>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            {TourUtil.isTourCompleted(tour) && (
              <View style={{ width: '100%', alignItems: 'center' }}>
                <Typography.Body colorName="color-grey">
                  Cette tournée est terminée, vous ne pouvez plus modifier ce lot.
                </Typography.Body>
              </View>
            )}
            <StyledButtonWrapper>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <Button
                label={FormatUtils.capitalize(i18n.t('save'))}
                onPress={() => onUpdateBatch()}
                loading={isLoading}
                hasDoubleValidation
                confirmationLabel="Êtes-vous sûr de vouloir modifier ce lot ?"
                disabled={TourUtil.isTourCompleted(tour) || groupTypeIsDeliveryProducers}
              />
            </StyledButtonWrapper>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            {groupTypeIsDeliveryProducers && (
              <Typography.BodySmall colorName="color-warning" align="center">
                Vous ne pouvez pas modifier cette information, car vous êtes dans un groupe de type
                "producteur-livreurs", seuls les producteurs peuvent modifier cette information
                depuis leur espace.
              </Typography.BodySmall>
            )}
          </ScrollableFormWrapper>
        </BatchContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierUpdateBatchScreen
