import styled, { css } from 'styled-components/native'
import { InterfaceColors } from '../../utilities/styling/theme/theme'
import { TextProps, TextTag } from './Text.model'

const StyledText = styled.Text<TextProps>`
  font-family: 'Euclid';
  font-size: ${({ theme }) => theme.fontSizes.BODY}px;
  color: ${({ theme, colorName }) => theme.colors[`${colorName ?? 'text-dark-1'}`]};
  width: auto;

  ${({ gutterBottom }) =>
    gutterBottom &&
    css`
      margin-bottom: ${({ theme }) => theme.spacingUnit * 1}px;
    `}

  ${({ tag, colorName, theme }) => {
    const getColor = (themedColorName: keyof InterfaceColors) => {
      return theme.colors[`${colorName ?? themedColorName}`]
    }

    switch (tag) {
      case TextTag.TITLE:
        return css`
          color: ${() => getColor('color-primary')};
          font-family: 'Inter-SemiBold';
          font-size: ${theme.fontSizes.TITLE}px;
          line-height: ${theme.lineHeights.TITLE}px;
        `
      case TextTag.PAGE_TITLE:
        return css`
          color: ${() => getColor('color-primary')};
          font-family: 'Inter-SemiBold';
          font-size: ${theme.fontSizes.PAGE_TITLE}px;
          line-height: ${theme.lineHeights.PAGE_TITLE}px;
          margin-bottom: ${theme.spacingUnit * 0.5}px;
        `
      case TextTag.SECTION_TITLE:
        return css`
          font-family: 'Inter-SemiBold';
          font-size: ${theme.fontSizes.SECTION_TITLE}px;
          color: ${() => getColor('text-dark-1')};
          letter-spacing: 0.4px;
          margin-top: ${theme.spacingUnit * 0.7}px;
          margin-left: ${theme.spacingUnit * 1}px;
          margin-bottom: ${theme.spacingUnit * 0.2}px;
          line-height: ${theme.lineHeights.SECTION_TITLE}px;
        `

      case TextTag.CARD_TITLE:
        return css`
          font-family: 'Inter-SemiBold';
          font-size: ${theme.fontSizes.CARD_TITLE}px;
          color: ${() => getColor('text-dark-1')};
          letter-spacing: 0.4px;
          align-self: flex-start;
          line-height: ${theme.lineHeights.CARD_TITLE}px;
        `
      case TextTag.BODY:
        return css`
          font-family: 'Inter';
          color: ${() => getColor('text-dark-1')};
          font-size: ${theme.fontSizes.BODY}px;
          line-height: ${theme.lineHeights.BODY}px;
        `
      case TextTag.BODY_SMALL:
        return css`
          font-family: 'Inter';
          color: ${() => getColor('text-dark-1')};
          font-size: ${theme.fontSizes.BODY_SMALL}px;
          line-height: ${theme.lineHeights.BODY_SMALL}px;
        `
      case TextTag.BODY_EXTRA_SMALL:
        return css`
          font-family: 'Inter';
          color: ${() => getColor('text-dark-1')};
          font-size: ${theme.fontSizes.BODY_EXTRA_SMALL}px;
          line-height: ${theme.lineHeights.BODY_EXTRA_SMALL}px;
        `
      case TextTag.INPUT_LABEL:
        return css`
          font-family: 'Inter';
          font-size: ${theme.fontSizes.INPUT_LABEL}px;
          color: ${() => getColor('text-dark-1')};
          align-self: flex-start;
          line-height: ${theme.lineHeights.INPUT_LABEL}px;
        `
      case TextTag.MENU:
        return css`
          font-family: 'Inter';
          font-size: ${theme.fontSizes.MENU}px;
          color: ${() => getColor('text-dark-1')};
          align-self: flex-start;
          text-transform: capitalize;
          line-height: ${theme.lineHeights.MENU}px;
        `
      case TextTag.DANGER:
        return css`
          font-family: 'Euclid-Medium';
          color: ${() => getColor('color-danger')};
          align-self: flex-start;
          font-size: ${theme.fontSizes.DANGER}px;
          line-height: ${theme.lineHeights.DANGER}px;
        `
      case TextTag.FORM_ITEM:
        return css`
          font-family: 'Inter';
          color: ${() => getColor('text-dark-1')};
          font-size: ${theme.fontSizes.FORM_ITEM}px;
          line-height: ${theme.lineHeights.FORM_ITEM}px;
        `
      case TextTag.BUTTON_LABEL:
        return css`
          color: ${() => getColor('text-light-1')};
          font-family: 'Inter-SemiBold';
          font-size: ${theme.fontSizes.BUTTON_LABEL}px;
          line-height: ${theme.lineHeights.BUTTON_LABEL}px;
        `

      default:
        return ``
    }
  }};

  ${({ medium }) =>
    medium &&
    css`
      font-family: 'Inter-Medium';
    `}

  ${({ semiBold }) =>
    semiBold &&
    css`
      font-family: 'Inter-SemiBold';
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-family: 'Inter-Bold';
    `}

    ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

    ${({ ellipsis }) =>
    ellipsis &&
    css`
      // text-overflow: ellipsis;
      // overflow: hidden;
      // white-space: nowrap;
      width: '100%';
    `}
`

export default StyledText
