import styled from 'styled-components/native'

const StyledTopBox = styled.View`
  flex-direction: row;
`

const StyledRightBox = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 0.9}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 0.3}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 0.5}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.7}px;
  align-items: flex-end;
  // flex: 1;
  position: absolute;
  top: 0;
  right: 0;
`

const StyledCardWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 0.5}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 0.5}px;
  width: 100%;
`
const ButtonsWrapper = styled.View`
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1}px;
`

export { StyledTopBox, StyledRightBox, StyledCardWrapper, ButtonsWrapper }
