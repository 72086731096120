import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { useTheme } from 'styled-components/native'
import { PageTitle } from '../../../components'
import { Tour } from '../../../domain'
import StopCard from '../../../modules/StopCard/StopCard'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import CarrierTourDeliveryTopBox from './components/CarrierTourDeliveryTopBox'
import { ToursContentWrapper } from './CarrierTourDelivery.styles'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import Loader from '../../../components/Loader'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierTourDeliveryScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourDelivery'>>()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const { id } = route?.params ?? { id: '' }

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = TourCarrierService.getOne.query(id)

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  const stopsWithCart = tour?.stops?.filter(
    (stop) => !stop.hasCartWithNoOrder && (stop.nbOrders || 0) > 0,
  )

  const updatedStops = stopsWithCart?.map((stop, index) => {
    return {
      ...stop,
      ranking: index + 1,
    }
  })

  if (tourLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour) return null

  const onClickStep = (id: string) => {
    navigation.navigate('CarrierTourDeliveryStop', { id: id, tourId: tour.id })
  }
  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title={`Livraison #${getTourIdFormatted()}`} />
          <CarrierTourDeliveryTopBox tour={tour} />
          {updatedStops?.map((stop) => (
            <StopCard key={stop.id} stop={stop} hasRanking onClick={() => onClickStep(stop.id)} />
          ))}
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourDeliveryScreen
