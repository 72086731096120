import { ScrollView } from 'react-native-gesture-handler'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import {
  BackToListWrapper,
  PriceReportingAddProductTypeWrapper,
  StyledButtonWrapper,
} from './CarrierPriceAddReportingProductType.styles'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import { AddPriceStatement, PriceReporting, ProductType } from '../../../domain'
import ProductProducerService from '../../../services/producer/product'
import { useState } from 'react'
import { Button, Dropdown, PageTitle, Spacer, TextInput, Typography } from '../../../components'
import { View } from 'react-native'
import PriceStatementCard from '../../../modules/PriceReporting/PriceStatementCard'
import PriceReportingCarrierService from '../../../services/carrier/priceReporting'
import Loader from '../../../components/Loader'
import { queryClient } from '../../../utilities/queryClient'
import { Category } from '../../../domain/Category'
import ProductCarrierService from '../../../services/carrier/product'
import { ProductEnums } from '../../../../enums'

const newPriceStatementDefault = {
  id: undefined,
  minPrice: undefined,
  maxPrice: undefined,
  minPriceWholsalers: undefined,
  maxPriceWholsalers: undefined,
  mesureType: undefined,
  packing: 1,
  productType: undefined,
}
const CarrierPriceReportingAddProductType = () => {
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdatePriceReporting'>>()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [selectedProductType, setSelectedProductType] = useState<any | undefined>()
  const [selectedCategory, setSelectedCategory] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const [newPriceStatement, setNewPriceStatement] =
    useState<AddPriceStatement>(newPriceStatementDefault)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isAddingProductType, setIsAddingProductType] = useState(false)
  const [newProductTypeLabel, setNewProductTypeLabel] = useState<any>()
  const [selectedMesureType, setSelectedMesureType] = useState<any | undefined>(undefined)

  const { id } = route?.params ?? { id: '' }

  const {
    data: priceReporting,
    refetch: refetchPriceReporting,
    isInitialLoading: priceReportingLoading,
  } = useQuery<PriceReporting, Error>(
    ['sf_price_reporting', id],
    () => PriceReportingCarrierService.getOne(id),
    {
      keepPreviousData: true,
      enabled: id !== '',
    },
  )

  const {
    data: productTypes,
    refetch: refetchProductTypes,
    isInitialLoading: productTypesLoading,
  } = useQuery<ProductType[], Error>(
    ['c_product_types'],
    () => ProductCarrierService.getAllProductTypes(),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: categories,
    refetch: refetchCategories,
    isInitialLoading: categoriesLoading,
  } = useQuery<Category[], Error>(
    ['c_categories'],
    () => ProductCarrierService.getAllCategories(),
    {
      keepPreviousData: true,
    },
  )

  const onChangeProductType = (value: any) => {
    setSelectedProductType(value)
  }

  const onChangeCategory = (value: any) => {
    setSelectedCategory(value)
  }

  const onChangeMesureType = (value: any) => {
    setSelectedMesureType(value)
  }

  const onAddProductType = async () => {
    setErrorMessage('')

    if (!newProductTypeLabel) {
      setErrorMessage('Veuillez renseigner un nom de type de produit')
      return false
    }

    if (!selectedCategory) {
      setErrorMessage('Veuillez choisir une catégorie')
      return false
    }

    setIsLoading(true)
    const newProductType = {
      label: newProductTypeLabel,
      mesureType: selectedMesureType.value,
      categoryId: selectedCategory.id,
    }

    await ProductCarrierService.createProductType(newProductType)
      .then((createdProductType) => {
        queryClient.invalidateQueries(['c_product_types'])
        setSelectedProductType({
          ...createdProductType,
          value: createdProductType.id,
        })
        setSelectedCategory(undefined)
        setNewProductTypeLabel('')
        setIsAddingProductType(false)
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la création du type de produit')
      })

    setIsLoading(false)
  }

  const resetForm: () => void = async () => {
    setSelectedProductType(undefined)
    setNewProductTypeLabel('')
    setNewPriceStatement(newPriceStatementDefault)
  }

  const onAddPriceStatement = () => {
    queryClient.invalidateQueries(['sf_price_reporting', id])
    queryClient.invalidateQueries(['sf_product_types_to_statement', id])

    navigation.goBack()
  }

  const productTypeAlreadyInPriceReporting = () => {
    if (!selectedProductType || !priceReporting) {
      return false
    }

    return priceReporting.priceStatements?.find(
      (ps) => ps.productType.id === selectedProductType.id,
    )
  }

  if (!priceReporting) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ScreenSafeAreaWrapper>
      <ContentWrapper>
        <PriceReportingAddProductTypeWrapper>
          <PageTitle title="Ajouter un type de produit au relevé" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            {isAddingProductType ? (
              <>
                <Dropdown
                  label={'Catégorie'}
                  onChange={onChangeCategory}
                  multiple={false}
                  optionsDefault={
                    !categories
                      ? []
                      : categories.map((category) => ({
                          ...category,
                          label: category.label,
                          value: category.id,
                        }))
                  }
                  zIndex={1000}
                  zIndexInverse={4000}
                  itemKey="productType"
                />
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                {selectedCategory && (
                  <>
                    <TextInput
                      value={newProductTypeLabel || ''}
                      label="Nouveau type de produit"
                      field="text"
                      onChangeText={(text) => setNewProductTypeLabel(text)}
                    />
                    <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                    <Typography.BodyExtraSmall colorName="color-grey">
                      Ex: Fraise guariguette
                    </Typography.BodyExtraSmall>
                  </>
                )}
                {newProductTypeLabel && (
                  <>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <Dropdown
                      label={'Type de mesure'}
                      onChange={onChangeMesureType}
                      multiple={false}
                      defaultValue={selectedMesureType?.id}
                      optionsDefault={ProductEnums.MESURE_TYPES.map((mesureType) => ({
                        ...mesureType,
                      }))}
                      zIndex={2000}
                      zIndexInverse={40000}
                      itemKey="mesureType"
                    />
                  </>
                )}
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                {errorMessage ? (
                  <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                ) : null}
                <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                {newProductTypeLabel && selectedCategory && selectedMesureType ? (
                  <StyledButtonWrapper>
                    <Button
                      label={'Créer le type de produit'}
                      onPress={() => onAddProductType()}
                      loading={isLoading}
                    />
                  </StyledButtonWrapper>
                ) : null}
                <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
                <BackToListWrapper>
                  <Button.Text
                    label={'Revenir à la liste'}
                    onPress={() => {
                      resetForm()
                      setIsAddingProductType(false)
                    }}
                  />
                </BackToListWrapper>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              </>
            ) : (
              <View style={{ alignItems: 'flex-start' }}>
                <Dropdown
                  label={'Type de produit'}
                  onChange={onChangeProductType}
                  multiple={false}
                  isSearchable={true}
                  defaultValue={selectedProductType?.id}
                  optionsDefault={
                    !productTypes
                      ? []
                      : productTypes.map((productType) => ({
                          ...productType,
                          label: productType.label,
                          value: productType.id,
                        }))
                  }
                  zIndex={1000}
                  zIndexInverse={4000}
                  itemKey="productType"
                />
                <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                <Button.Text
                  label={'Proposer un nouveau type de produit'}
                  onPress={() => {
                    resetForm()
                    setIsAddingProductType(true)
                  }}
                />
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              </View>
            )}
            {productTypeAlreadyInPriceReporting() && (
              <Typography.Body colorName="color-danger">
                Ce type de produit est déjà dans le relevé de prix ou dans la liste des exclusions.
              </Typography.Body>
            )}
            {selectedProductType && !productTypeAlreadyInPriceReporting() ? (
              <PriceStatementCard
                key={selectedProductType.id}
                priceReporting={priceReporting}
                productTypePropositions={selectedProductType}
                onChangePriceStatement={() => onAddPriceStatement()}
              />
            ) : null}
          </ScrollableFormWrapper>
        </PriceReportingAddProductTypeWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierPriceReportingAddProductType
