import React from 'react'
import { View, useWindowDimensions } from 'react-native'
import { useTheme } from 'styled-components/native'
import { TourEnums } from '../../../../enums'
import {
  Button,
  Col,
  Icon,
  Icons,
  Row,
  Spacer,
  TouchableCard,
  Typography,
} from '../../../components'
import i18n from '../../../i18n'
import FormatUtils from '../../../utilities/utils/format'
import GeneralUtils from '../../../utilities/utils/general'
import { CartTourClientCardProps } from './CartTourClientCard.model'
import {
  StatusBox,
  StyleBoxWrapper,
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
  StyledSubCardContent,
  StyledSubCardWrapper,
  StyledTimeBox,
  StyledTimeBoxRight,
} from './CartTourClientCard.styles'
import DeviceUtil from '../../../utilities/utils/device'
import { Cart } from '../../../domain'
import StorageUtil from '../../../utilities/storage/storage'
import { useNavigation } from '@react-navigation/native'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import { StackNavigationProp } from '@react-navigation/stack'
import CartClientService from '../../../services/client/cart'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import TourUtil from '../../../utilities/utils/tour'

const CartTourClientCard = ({ tour, onClick, order }: CartTourClientCardProps) => {
  const theme = useTheme()
  const isMobileScreen = DeviceUtil.isMobileApp()
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()

  const onClickTour = () => {
    if (hasExistingOrder() && !isDemoMode()) {
      onClickCart()
      return
    }
    if (TourUtil.getTourStatus(tour) === TourEnums.STATUS.OPEN || isDemoMode()) {
      onClick(tour)
    }
  }

  if (!tour) return null

  const hasExistingOrder = () => {
    return order && order.id
  }

  const onClickCart = async () => {
    if (!order?.cart?.id) return
    const newCart = await CartClientService.createUpdateOrder(
      getAccessInfos()?.clientId,
      order?.cart?.id,
    )
    if (newCart && newCart.id) {
      await StorageUtil.setItem('c_cart_id', newCart.id)
      const now = new Date()
      now.setMinutes(now.getMinutes() + 60)
      await StorageUtil.setItem('c_cart_id_expiration_date', now)
      navigation.navigate('ClientCatalog', {})
      return
    }
  }

  const isDemoMode = () => {
    return (
      getAccessInfos().clientIsCarrier && TourUtil.getTourStatus(tour) === TourEnums.STATUS.PENDING
    )
  }

  const renderDemoModeCarrier = () => {
    if (!isDemoMode()) {
      return <></>
    }

    return (
      <StyledSubCardWrapper onPress={() => onClickTour()} activeOpacity={0.8} key={order?.cart?.id}>
        <TouchableCard>
          <StyledSubCardContent>
            <Row>
              <Col xs={7}>
                <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body>
                  Mode démo : visualisez le catalogue avant la tournée
                </Typography.Body>
              </Col>

              <Col xs={5} alignItems="flex-end">
                <Button
                  small
                  label="Afficher le catalogue"
                  onPress={() => onClickTour()}
                  colorName="color-secondary"
                />
              </Col>
            </Row>
          </StyledSubCardContent>
        </TouchableCard>
      </StyledSubCardWrapper>
    )
  }

  const renderExistingCart = () => {
    if (!hasExistingOrder() || TourUtil.getTourStatus(tour) !== TourEnums.STATUS.OPEN) {
      return <></>
    }
    return (
      <StyledSubCardWrapper onPress={() => onClickCart()} activeOpacity={0.8} key={order?.cart?.id}>
        <TouchableCard>
          <StyledSubCardContent>
            <Row>
              <Col xs={7}>
                <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body>Vous avez déjà une commande en cours</Typography.Body>
              </Col>

              <Col xs={5} alignItems="flex-end">
                <Button
                  small
                  label="Modifier ma commande"
                  onPress={() => onClickCart()}
                  colorName="color-secondary"
                />
              </Col>
            </Row>
          </StyledSubCardContent>
        </TouchableCard>
      </StyledSubCardWrapper>
    )
  }

  const renderTourDetailsStatus = () => {
    const tourStatus = TourUtil.getTourStatus(tour)

    if (tourStatus === TourEnums.STATUS.OPEN) {
      return (
        <Typography.BodySmall colorName={'text-dark-3'}>
          {isMobileScreen ? 'Fermeture le' : 'Fin des commandes le'}{' '}
          {FormatUtils.formatDate(tour?.close, 'PartialDate+StartTime')}
        </Typography.BodySmall>
      )
    } else if (tourStatus === TourEnums.STATUS.CLOSED) {
      return (
        <Typography.BodySmall colorName={'text-dark-3'}>
          {isMobileScreen ? 'Fermée le' : 'Commandes fermées le'}{' '}
          {FormatUtils.formatDate(tour?.close, 'PartialDate+StartTime')}
        </Typography.BodySmall>
      )
    } else if (tourStatus === TourEnums.STATUS.PENDING) {
      return (
        <Typography.BodySmall colorName={'color-warning'}>
          {isMobileScreen ? 'Ouverture le' : 'Ouverture le'}{' '}
          {FormatUtils.formatDate(tour?.open, 'PartialDate+StartTime')}
        </Typography.BodySmall>
      )
    }
  }

  const renderTourStatus = () => {
    const tourStatus = TourUtil.getTourStatus(tour)

    if (tourStatus === TourEnums.STATUS.OPEN) {
      return (
        <StatusBox backgroundColor={'color-secondary'}>
          <Typography.BodySmall bold>Voir le catalogue</Typography.BodySmall>
        </StatusBox>
      )
    } else if (tourStatus === TourEnums.STATUS.CLOSED) {
      return (
        <StatusBox backgroundColor={'color-grey'}>
          <Typography.BodySmall colorName={'text-light-1'} bold>
            Fermé
          </Typography.BodySmall>
        </StatusBox>
      )
    } else if (tourStatus === TourEnums.STATUS.PENDING) {
      return (
        <StatusBox backgroundColor={'text-dark-3'}>
          <Typography.BodySmall colorName={'text-light-1'} bold>
            À venir
          </Typography.BodySmall>
        </StatusBox>
      )
    }
  }

  return (
    <StyledCardWrapper
      onPress={!hasExistingOrder() ? onClickTour : () => null}
      activeOpacity={!hasExistingOrder() ? 0.8 : 1}
      key={`tourCard-${tour.id}`}
    >
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <StyledTimeBox>
              <Typography.CardTitle>
                {FormatUtils.capitalize(
                  FormatUtils.formatDate(tour.start, 'PartialDate', tour.end),
                )}
              </Typography.CardTitle>
              <Typography.BodySmall>
                {`de ${FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end)}`}
              </Typography.BodySmall>
            </StyledTimeBox>
            <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall>{tour.groupLabel}</Typography.BodySmall>
            <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
            {renderTourDetailsStatus()}
          </StyledLeftBox>
          <StyledRightBox>
            <StyleBoxWrapper>
              {renderTourStatus()}
              {TourUtil.getTourStatus(tour) === TourEnums.STATUS.OPEN && !hasExistingOrder() && (
                <Icon
                  type={Icons.Ionicons}
                  name="chevron-forward-outline"
                  size={theme.spacingUnit * 2.5}
                  color={theme.colors['color-grey']}
                  style={{ marginRight: theme.spacingUnit * -0.6 }}
                />
              )}
            </StyleBoxWrapper>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall align="right">
              {FormatUtils.pluralizeAmountLabel('producteur', tour.nbProducers || 0)}
            </Typography.BodySmall>
            <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
            {TourUtil.getTourStatus(tour) === TourEnums.STATUS.OPEN && (
              <Typography.BodySmall align="right">
                {`${FormatUtils.pluralizeAmountLabel(
                  'produit',
                  tour.nbProducts || 0,
                )} (${FormatUtils.moreCategories(tour.categories, isMobileScreen)})`}
              </Typography.BodySmall>
            )}
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
      {renderExistingCart()}
      {renderDemoModeCarrier()}
    </StyledCardWrapper>
  )
}

export default CartTourClientCard
