import React from 'react'
import { useTheme } from 'styled-components/native'
import i18n from '../../i18n'
import FormatUtils from '../../utilities/utils/format'
import Button from '../Button'
import Typography from '../Typography'
import { ButtonSeeMoreProps } from './ButtonSeeMore.model'
import { StyledButtonWrapper } from './ButtonSeeMore.styles'

function ButtonSeeMore({ seeMore, onClick, hide, color }: ButtonSeeMoreProps) {
  const theme = useTheme()

  if (hide) return null

  return (
    <StyledButtonWrapper>
      <Button
        label={
          seeMore
            ? FormatUtils.capitalize(i18n.t('displayLess'))
            : FormatUtils.capitalize(i18n.t('displayMore'))
        }
        onPress={() => onClick(!seeMore)}
        small
      />
    </StyledButtonWrapper>
  )
}

export default ButtonSeeMore
