import styled, { css } from 'styled-components/native'

const BoxWrapper = styled.View`
  flex-direction: row;
  gap: ${({ theme }) => theme.spacingUnit * 1}px;
  padding: ${({ theme }) => theme.spacingUnit * 0.4}px;
  align-items: center;
  flex-wrap: wrap;
`

export { BoxWrapper }
