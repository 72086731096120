import { TouchableOpacity } from 'react-native'
import { Card, Icon, Icons, Spacer, Typography } from '../../../../../../../components'
import { CardContentWrapper, LeftBoxWrapper, StyledCardWrapper } from './DeliveryNoteCard.style'
import { useTheme } from 'styled-components/native'
import { DeliveryNoteCardProps } from './DeliveryNoteCard.model'

const DeliveryNoteCard = ({
  title,
  nbPossibleDeliveryNotes,
  deliveryNotes,
  navigation,
  tourId,
  deliveryNoteType,
}: DeliveryNoteCardProps) => {
  const theme = useTheme()

  return (
    <StyledCardWrapper
      onPress={() =>
        navigation.navigate('CarrierTourDeliveryNotes', {
          id: tourId,
          type: deliveryNoteType,
        })
      }
    >
      <Card>
        <CardContentWrapper>
          <LeftBoxWrapper>
            <Typography.Body bold colorName="text-dark-1">
              {title}
            </Typography.Body>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.Body
              bold
              colorName={
                nbPossibleDeliveryNotes === (deliveryNotes || []).length
                  ? 'color-primary'
                  : 'color-danger'
              }
            >
              {(deliveryNotes || []).length | 0}/{nbPossibleDeliveryNotes}
            </Typography.Body>
          </LeftBoxWrapper>

          <TouchableOpacity
            onPress={() =>
              navigation.navigate('CarrierTourDeliveryNotes', {
                id: tourId,
                type: deliveryNoteType,
              })
            }
          >
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              size={theme.spacingUnit * 2.5}
              color={theme.colors['color-primary']}
            />
          </TouchableOpacity>
        </CardContentWrapper>
      </Card>
    </StyledCardWrapper>
  )
}

export default DeliveryNoteCard
