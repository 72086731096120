import { Product } from '../../../../../domain'
import { ProductInfosProps } from './ProductInfos.model'
import { Col, DynamicImage, Row, Spacer, Typography } from '../../../../../components'
import {
  DynamicImageWrapper,
  LeftBox,
  ProductInfosWrapper,
  RightBox,
  StyledProductLabel,
  StyledProductLabels,
} from './ProductInfos.styles'
import FormatUtils from '../../../../../utilities/utils/format'
import DirectusUtil from '../../../../../utilities/utils/directus'
import BatchUtil from '../../../../../utilities/utils/batch'
import { ProductEnums } from '../../../../../../enums'
import AccountUtil from '../../../../../utilities/utils/account'
import CarrierProducerTourService from '../../../../../services/carrier/producerTour'
import AddCartButton from '../../../../../modules/Cart/AddCartButton'

const ProductInfos = ({ cart, batch }: ProductInfosProps) => {
  const product = batch.product
  const producer = product?.producer

  const getAvailablePackingQuantity = () => {
    if (
      batch?.availableQuantity &&
      product?.packing &&
      batch?.availableQuantity >= product?.packing
    ) {
      return Math.round(batch?.availableQuantity / product?.packing)
    }
    return 0
  }

  const displayPricePerKilo = () => {
    if (product?.displayPricePerKilo && product?.unitWeight && product?.unitWeight > 0) {
      const pricePerKilo = batch.unitPrice / product?.unitWeight

      if (!pricePerKilo || pricePerKilo <= 0) return <></>
      return (
        <Typography.BodyExtraSmall colorName={'text-dark-3'}>
          {FormatUtils.formatPrice(pricePerKilo)}
          {FormatUtils.getPriceUnits('ht', producer)}
          {producer?.account && !AccountUtil.isProfileSubjectToVAT(producer.account) ? '*' : ''}/
          {FormatUtils.formatUnity(ProductEnums.MESURE_TYPE.KILO)}
        </Typography.BodyExtraSmall>
      )
    }
    return <></>
  }

  if (!cart?.tour?.id) return null

  const { data: producersTourData } = CarrierProducerTourService.useGetFromTour({
    tourId: cart.tour.id,
  }).query()

  const foundProducerTourData = producersTourData?.find(
    (pt) => pt?.producerId === batch?.product?.producer?.id,
  )

  return (
    <ProductInfosWrapper>
      <LeftBox>
        <DynamicImageWrapper>
          <DynamicImage
            imageUri={DirectusUtil.getProductImageUrlFromId(product as any as Product)?.uri}
            togglesModal
          />
        </DynamicImageWrapper>
      </LeftBox>
      <RightBox>
        <Typography.CardTitle>{product?.label}</Typography.CardTitle>
        <Typography.BodySmall ellipsis colorName="text-dark-3" bold>
          {product?.typeInfo}
        </Typography.BodySmall>

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
        <Typography.BodySmall colorName="text-dark-3">{producer?.location}</Typography.BodySmall>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
        {product?.productLabels && product?.productLabels.length > 0 ? (
          <StyledProductLabels>
            {product.productLabels.map((productLabel) => (
              <StyledProductLabel>
                <Typography.BodySmall colorName="text-light-1" align="center" bold>
                  {productLabel.label}
                </Typography.BodySmall>
              </StyledProductLabel>
            ))}
          </StyledProductLabels>
        ) : undefined}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
        {product?.description && (
          <>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <Typography.Body style={{ wordBreak: 'break-word' }}>
              {product?.description}
            </Typography.Body>
          </>
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <Row>
          <Col xs={6}>
            <Typography.Body colorName={'text-dark-1'} semiBold>
              par {FormatUtils.formatQuantity(product?.packing, product?.mesureType)}
            </Typography.Body>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            {getAvailablePackingQuantity() > 0 && (
              <Typography.BodyExtraSmall colorName="text-dark-3">
                Dispo. : {getAvailablePackingQuantity()} colis
              </Typography.BodyExtraSmall>
            )}
            {product?.container?.type === 2 && (
              <Typography.BodyExtraSmall colorName="text-dark-3">
                Cagette {batch.product?.container?.length}x{batch.product?.container?.width}
              </Typography.BodyExtraSmall>
            )}
            {batch.expiration && (
              <>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                <Typography.BodySmall>
                  DLC : {FormatUtils.formatDate(batch.expiration.toString(), 'DateFullYear')}
                </Typography.BodySmall>
              </>
            )}
          </Col>
          <Col xs={6} alignItems="flex-end">
            <Typography.Body colorName={'text-dark-1'} bold>
              {FormatUtils.formatPrice(batch.unitPrice, 'ht', producer)}/
              {FormatUtils.formatUnity(product?.mesureType)}
            </Typography.Body>
            {cart.tour?.group && BatchUtil.displayCrossedOutPrice(batch, cart.tour?.group) && (
              <Typography.BodySmall
                colorName={'color-danger'}
                style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}
              >
                {FormatUtils.formatPrice(batch.crossedOutPrice)}
                {FormatUtils.getPriceUnits('ht', producer)}
                {producer?.account && !AccountUtil.isProfileSubjectToVAT(producer.account)
                  ? '*'
                  : ''}
                /{FormatUtils.formatUnity(product?.mesureType)}
              </Typography.BodySmall>
            )}
            {displayPricePerKilo()}
          </Col>
        </Row>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.4} />
        {product?.chargedActualWeight && (
          <Typography.BodyExtraSmall colorName={'text-dark-3'}>
            Ce produit est facturé au poids exact (+/- 5%)
          </Typography.BodyExtraSmall>
        )}

        <AddCartButton cart={cart} batch={batch} />
      </RightBox>
    </ProductInfosWrapper>
  )
}

export default ProductInfos
