import { useQuery } from '@tanstack/react-query'
import {
  BatchExtended,
  Catalog,
  CatalogExtended,
  Producer,
  ProducerWithProducerTourData,
} from '../../../domain'
import ApiService from '../../api.service'
import CarrierProducerTourService, { extendProducerTour } from '../../carrier/producerTour'

export interface GetCatalogArgs {
  tourId: string
  cartId: string
  limit?: number
  pageNumber?: number
  categories?: string[]
  producers?: string[]
  search?: string
  productLabels?: string[]
}

async function getCatalogExtended({
  tourId,
  cartId,
  limit,
  pageNumber,
  categories,
  producers,
  search,
  productLabels,
}: GetCatalogArgs) {
  const catalog = await getAllBatchs(
    tourId,
    cartId,
    limit,
    pageNumber,
    categories,
    producers,
    search,
    productLabels,
  )
  const producersTour = await CarrierProducerTourService.getFromTour(tourId)
  const extendProducersTour = extendProducerTour(producersTour)

  catalog.batchs =
    catalog.batchs?.map((batch) => {
      const producerTour = extendProducersTour?.find(
        (pt) => pt.producerId === batch.product?.producer?.id,
      )

      const augmentedBatch = {
        ...batch,
        producerTourData: {
          ...producerTour,
        },
      }

      return augmentedBatch as BatchExtended
    }) ?? []

  catalog.producers =
    catalog.producers?.map((producer) => {
      const producerTour = extendProducersTour?.find((pt) => pt.producerId === producer.id)

      const augmentedProducer = {
        ...producer,
        producerTourData: {
          ...producerTour,
        },
      }
      return augmentedProducer as ProducerWithProducerTourData
    }) ?? []

  return catalog as CatalogExtended
}

const getAllBatchs = async (
  tourId?: string,
  cartId?: string,
  limit: number = 30,
  pageNumber: number = 1,
  selectedCategories?: string[],
  selectedProducers?: string[],
  search?: string,
  productLabelsIds?: string[],
): Promise<Catalog> => {
  if (!tourId || !cartId) return {}

  return await ApiService.get(`/batch/tour/${tourId}/cart/${cartId}`, {
    limit,
    page: pageNumber,
    categories: selectedCategories,
    selectedProducers: selectedProducers,
    search,
    productLabelsIds,
  })
}

const BatchClientService = {
  getAllBatchs,
  catalogExtended: (() => {
    function getQueryKey() {
      //@todo c_catalog is way too generic, specially with CarrierAddCartBatchScreen
      //@todo refactor ASAP (September 24)
      return ['c_catalog']
    }

    function get(args: GetCatalogArgs, enabled: boolean = true) {
      return useQuery<Catalog, Error, CatalogExtended>({
        queryKey: getQueryKey(),
        queryFn: () => getCatalogExtended(args),
        enabled,
      })
    }

    return {
      getQueryKey,
      get,
    }
  })(),
}

export default BatchClientService
