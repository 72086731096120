import { DeliveryNoteTypeEnum } from '../../../../enums'
import { DeliveryNote, Pagination, Tour } from '../../../domain'
import ApiService from '../../api.service'

const generate = async (tourId: string): Promise<void> => {
  const response = await ApiService.post(`delivery-note/tour/${tourId}`, {}).catch((error) => {
    throw error
  })
  return response
}

const generateOrders = async (tourId: string): Promise<void> => {
  const response = await ApiService.post(`delivery-note/tour/${tourId}/orders`, {}).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const generateProducers = async (tourId: string): Promise<void> => {
  const response = await ApiService.post(`delivery-note/tour/${tourId}/producers`, {}).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const getAllTour = async (
  tourId: string,
  type?: DeliveryNoteTypeEnum,
  clientId?: string,
  producerId?: string,
): Promise<DeliveryNote[]> => {
  // TODO: backend side it's a post method
  const response = await ApiService.get(`/delivery-note/tour/${tourId}`, {
    clientId: clientId || null,
    producerId: producerId || null,
    type: type || null,
  })
    .then((result) => result.data)
    .catch((error) => {
      throw error
    })
  return response
}

const getAll = async (
  groupId: string,
  type?: DeliveryNoteTypeEnum,
  tourId?: string,
  clientId?: string,
  producerId?: string,
  before?: Date,
  after?: Date,
  limit: number = 30,
  pageNumber: number = 1,
): Promise<{ data: DeliveryNote[]; pagination: Pagination; stats: any }> => {
  const response = await ApiService.get(`/delivery-note/group/${groupId}`, {
    clientId: clientId || undefined,
    tourId: tourId || undefined,
    producerId: producerId || undefined,
    type: type || undefined,
    before: before || undefined,
    after: after || undefined,
    limit,
    page: pageNumber,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOne = async (deliveryNoteId: string): Promise<DeliveryNote> => {
  const response = await ApiService.get(`/delivery-note/${deliveryNoteId}`)
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const update = async (deliveryNoteId: string, deliveryNote: any): Promise<DeliveryNote> => {
  const response = await ApiService.patch(`/delivery-note/${deliveryNoteId}`, deliveryNote).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const refresh = async (deliveryNoteId: string): Promise<DeliveryNote> => {
  const response = await ApiService.post(
    `/delivery-note/${deliveryNoteId}/refresh-document`,
    {},
  ).catch((error) => {
    throw error
  })
  return response
}

const deleteOne = async (deliveryNoteId: string): Promise<DeliveryNote> => {
  const response = await ApiService.remove(`/delivery-note/${deliveryNoteId}`).catch((error) => {
    throw error
  })
  return response
}

const sendMail = async (deliveryNoteId: string, body: any): Promise<Tour> => {
  const response = await ApiService.post(`/delivery-note/${deliveryNoteId}/send-mail`, body).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const DeliveryNoteCarrierService = {
  generate,
  generateOrders,
  generateProducers,
  getAllTour,
  getAll,
  getOne,
  update,
  refresh,
  deleteOne,
  sendMail,
}

export default DeliveryNoteCarrierService
