import { useState } from 'react'
import { Button, Col, PageTitle, Spacer, TextInput, Typography } from '../../../components'
import {
  ContentWrapper,
  FormKeyboardWrappers,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { AuthenticationStackParamList } from '../../../navigation/AuthenticationNavigationStack/AuthenticationNavigationStack.model'
import { StackNavigationProp } from '@react-navigation/stack'
import AuthenticationService from '../../../services/authentication'
import { PublicStackParamList } from '../../../navigation/PublicNavigationStack/PublicNavigationStack.model'

const ResetPasswordScreen = () => {
  const navigation = useNavigation<StackNavigationProp<PublicStackParamList>>()
  const route = useRoute<RouteProp<AuthenticationStackParamList, 'ResetPassword'>>()

  const { token, email, creation } = route?.params ?? { token: '', email: '' }

  const [resetToken, setResetToken] = useState<string>(token || '')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onResetPassword = async () => {
    if (!resetToken || !newPassword || !confirmPassword) {
      setErrorMessage('Veuillez remplir tous les champs')
      return
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage('Les mots de passe ne correspondent pas')
      return
    }

    if (newPassword.length < 8) {
      setErrorMessage('Le mot de passe doit contenir au moins 8 caractères')
      return
    }

    try {
      setErrorMessage('')
      setIsLoading(true)

      const response = await AuthenticationService.resetPassword(email, resetToken, newPassword)

      setIsLoading(false)
      if (response) {
        navigation.navigate('Authentication', {
          screen: 'Login',
          params: {},
        })
      }
    } catch (error) {
      setIsLoading(false)
      setErrorMessage('Les données saisies sont incorrectes')
    }
  }

  return (
    <ScreenSafeAreaWrapper>
      <FormKeyboardWrappers>
        <ContentWrapper alignCenter noPaddingLeft noHeader>
          <ScrollableFormWrapper>
            <Spacer size={4} />
            <PageTitle
              title={creation ? 'Créer mon mot de passe' : 'Réinitialiser mon mot de passe'}
            ></PageTitle>
            {!token ? (
              <Col xs={14} sm={14} md={14}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={resetToken}
                  label="Code reçu par email"
                  field="token"
                  onChangeText={(text) => setResetToken(text)}
                />
              </Col>
            ) : undefined}

            <Col xs={14} sm={14} md={14}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <TextInput
                value={newPassword}
                label="Nouveau mot de passe"
                field="password"
                secureTextEntry
                onChangeText={(text) => setNewPassword(text)}
              />
            </Col>
            <Col xs={14} sm={14} md={14}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <TextInput
                value={confirmPassword}
                label="Confirmation nouveau mot de passe"
                field="password"
                secureTextEntry
                onChangeText={(text) => setConfirmPassword(text)}
                onKeyPressCallBack={() => onResetPassword()}
              />
            </Col>
            <Spacer size={1} />
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer size={2} />
          </ScrollableFormWrapper>
          <Button label={'Valider'} onPress={onResetPassword} fullWidth loading={isLoading} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        </ContentWrapper>
      </FormKeyboardWrappers>
    </ScreenSafeAreaWrapper>
  )
}

export default ResetPasswordScreen
