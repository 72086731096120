import React from 'react'
import { Image } from 'react-native'
import styled from 'styled-components/native'
import { Button, Spacer, Typography } from '../../components'
import UpdateIllustration from '../../../assets/images/upgrade.png'

const StyledWrapper = styled.View`
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  padding: 10px;
  min-height: 300px;
`

const StyledImageWrapper = styled.View`
  flex: 1;
  width: 100%;
`

const StyledImage = styled.Image`
  width: 100%;
  height: 100%;
`

const SoftUpdateContent = ({
  onButtonPress,
  onCancelPress,
}: {
  onButtonPress: () => void
  onCancelPress: () => void
}) => {
  const updateIllustrationUri = Image.resolveAssetSource(UpdateIllustration).uri
  return (
    <StyledWrapper>
      <StyledImageWrapper>
        <StyledImage source={{ uri: updateIllustrationUri }} resizeMode="contain" />
      </StyledImageWrapper>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <Typography.PageTitle>Une mise à jour est disponible</Typography.PageTitle>
      <Typography.Body style={{ textAlign: 'center' }}>
        Afin de profiter des dernières fonctionnalités, pensez à mettre à jour votre application.
      </Typography.Body>
      <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
      <Button label="Mettre à jour" onPress={onButtonPress} fullWidth />
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <Button outlined label="Plus tard" onPress={onCancelPress} fullWidth />
    </StyledWrapper>
  )
}
export default SoftUpdateContent
