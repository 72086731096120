import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  padding: ${({ theme }) => theme.spacingUnit * 1.2}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 2}px;
  flex-direction: row;
  justify-content: space-between;
`

const StyledLeftBox = styled.View`
  flex-direction: row;
  gap: ${({ theme }) => theme.spacingUnit * 0.5}px;
`

const StyledRightBox = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacingUnit * 1}px;
`

const StyledTimeBox = styled.View`
  align-items: flex-start;
`

const StyleStatusBox = styled.View`
  gap: ${({ theme }) => theme.spacingUnit * 0.2}px;
  align-items: flex-end;
`

export {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledRightBox,
  StyledTimeBox,
  StyleStatusBox,
}
