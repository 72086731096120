import { TransitionPresets, createStackNavigator } from '@react-navigation/stack'
import React from 'react'

import ClientCatalogScreen from '../../../screens/Client/ClientCatalog'
import ClientCartScreen from '../../../screens/Client/ClientCart'
import Header from '../../components/Header'
import ClientCatalogToursScreen from '../../../screens/Client/ClientCatalogTours'
import ClientBatchPage from '../../../screens/Client/ClientBatchPage'
import { Dimensions } from 'react-native'
import HeaderHome from '../../components/HeaderHome'

const Stack = createStackNavigator()

const CatalogClientNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="ClientCatalogTours"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ClientCatalogTours"
        component={ClientCatalogToursScreen}
        options={{
          headerShown: true,
          title: 'Nouvelle commande',
          header: () => <HeaderHome />,
        }}
      />
      <Stack.Screen
        name="ClientCatalog"
        component={ClientCatalogScreen}
        options={{
          headerShown: false,
          title: 'Commander',
          header: () => <Header title="" leftButton={<></>} />,
        }}
      />
      <Stack.Screen
        name="ClientCart"
        component={ClientCartScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ClientCatalog" />,
          title: 'Valider la commande',
        }}
      />
      <Stack.Screen
        name="ClientBatchPage"
        component={ClientBatchPage}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ClientCatalog" />,
        }}
      />
    </Stack.Navigator>
  )
}

export default CatalogClientNavigation
