import { TourEnums } from '../../../enums'
import { Tour } from '../../domain'
import GeneralUtils from './general'

const getTourStatus = (tour: Tour): TourEnums.STATUS => {
  let tourStatus = TourEnums.STATUS.PENDING
  if (!tour.open || (tour.open && GeneralUtils.isDateBefore(new Date(tour.open), new Date()))) {
    tourStatus = TourEnums.STATUS.OPEN
  }
  if (tour.close && GeneralUtils.isDateBefore(new Date(tour.close), new Date())) {
    tourStatus = TourEnums.STATUS.CLOSED
  }
  if (tour.status === TourEnums.TourStatusEnum.COMPLETED) {
    tourStatus = TourEnums.STATUS.COMPLETED
  }

  return tourStatus
}

const isTourCompleted = (tour: Tour) => {
  if (!tour) return false

  if (getTourStatus(tour) === TourEnums.STATUS.COMPLETED) {
    return true
  }

  return false
}

const TourUtil = {
  getTourStatus,
  isTourCompleted,
}

export default TourUtil
