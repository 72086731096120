import styled from 'styled-components/native'

const StyledDrawerMenuSpace = styled.TouchableOpacity`
  flex: 1;
  padding: ${({ theme }) => theme.spacingUnit * 2}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 1}px;
  margin-top: 0;
`

const StyledDrawerMenu = styled.View`
  width: 100%;
  min-width: 200px;
  border-radius: 10px;
  min-height: 500px;
  background-color: ${({ theme }) => theme.colors['background-3']};
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
`

const StyledLogoBox = styled.View`
  width: 100%;
  height: ${({ theme }) => theme.spacingUnit * 5}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 2}px;
  align-items: center;
`

const StyledImage = styled.Image`
  width: 50%;
  height: 100%;
`

const StyledDrawerItems = styled.View`
  flex: 1;
  padding-top: ${({ theme }) => theme.spacingUnit * 2}px;
`

export { StyledDrawerMenu, StyledDrawerMenuSpace, StyledLogoBox, StyledImage, StyledDrawerItems }
