import React from 'react'
import {
  BottomBoxWrapper,
  DynamicImageWrapper,
  GalleryImageWrapper,
  GalleryWrapper,
  TopBoxWrapper,
} from './ProducerGallery.styles'
import { ProducerGalleryProps } from './ProducerGallery.model'
import { DocumentPickerInput, Icon, Icons, DynamicImage, Card } from '../../../../../components'
import { TouchableOpacity } from 'react-native'

function ProducerGallery({
  mode,
  galleryImagesIds,
  onChangeProducerGalleryImage,
  onDeleteGalleryImage,
}: ProducerGalleryProps) {
  return (
    <GalleryWrapper>
      <Card>
        <TopBoxWrapper>
          {galleryImagesIds?.map((imageUri) => (
            <GalleryImageWrapper key={imageUri}>
              {mode === 'edit' && onDeleteGalleryImage ? (
                <DynamicImageWrapper>
                  <DynamicImage
                    imageUri={imageUri}
                    closable={true}
                    onClose={() => onDeleteGalleryImage!(imageUri)}
                    togglesModal
                  />
                </DynamicImageWrapper>
              ) : (
                <DynamicImageWrapper>
                  <DynamicImage imageUri={imageUri} togglesModal />
                </DynamicImageWrapper>
              )}
            </GalleryImageWrapper>
          ))}
        </TopBoxWrapper>
        {mode === 'edit' && (
          <BottomBoxWrapper>
            <DocumentPickerInput
              customAcceptedFileExtensions={['png', 'jpg', 'jpeg']}
              onAddFiles={onChangeProducerGalleryImage}
            />
          </BottomBoxWrapper>
        )}
      </Card>
    </GalleryWrapper>
  )
}

export default ProducerGallery
