import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const ToursContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: 0;
`

const AddProductContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const BackToListWrapper = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`

export { AddProductContentWrapper, StyledButtonWrapper, BackToListWrapper, ToursContentWrapper }
