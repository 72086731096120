import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import i18n from '../../../i18n'
import FormatUtils from '../../../utilities/utils/format'
import { UserAdminCardProps } from './UserAdminCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
} from './UserAdminCard.styles'
import { ProducerBillingTypeEnum } from '../../../../enums'
import { CarrierGroup, User, UserCarrier } from '../../../domain'
import { LABELS_TYPES } from '../../../../enums/label'

const UserAdminCard = ({ group, user, onClick }: UserAdminCardProps) => {
  const theme = useTheme()

  const OnClickUser = () => {
    if (onClick) {
      onClick(user)
    }
  }

  const getCarrierLabel = () => {
    if (!user?.userRole) return undefined
    return user?.userRole[0]?.carrier?.name
  }

  return (
    <StyledCardWrapper onPress={OnClickUser} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.CardTitle>
              {user.firstName} {user.lastName}
            </Typography.CardTitle>
            <Typography.BodySmall colorName="color-primary" bold>
              {getCarrierLabel()}
            </Typography.BodySmall>
            <Typography.BodySmall colorName="text-dark-3" bold>
              {user.email}
            </Typography.BodySmall>
            <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-1" bold>
              Roles :
            </Typography.BodySmall>
            {user.userRole?.map((userRole) => (
              <Typography.BodySmall colorName="text-dark-1">
                - {userRole.role?.label}
              </Typography.BodySmall>
            ))}
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          </StyledLeftBox>
          <StyledRightBox>
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              size={theme.spacingUnit * 2}
              color={theme.colors['text-dark-3']}
            />
            <View style={{ alignItems: 'flex-end' }}>
              <Typography.BodySmall colorName="text-dark-3" style={{ alignSelf: 'flex-end' }}>
                Créé le
              </Typography.BodySmall>
              <Typography.BodySmall>
                {FormatUtils.formatDate(user.createdDate, 'Date')}
              </Typography.BodySmall>
              <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall colorName="text-dark-3" style={{ alignSelf: 'flex-end' }}>
                Dernière Connexion
              </Typography.BodySmall>
              <Typography.BodySmall>
                {FormatUtils.formatDate(user.updatedDate, 'Date')}
              </Typography.BodySmall>
            </View>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default UserAdminCard
