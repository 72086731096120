import React from 'react'
import { useTheme } from 'styled-components/native'
import { StyledFeesWrapper, StyledPriceRuleBox, StyledTopBox } from './CarrierGroupTopBox.styles'
import { CarrierGroupTopBoxProps } from './CarrierGroupTopBox.model'
import { CardParam, Typography } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import i18n from '../../../../../i18n'
import { View } from 'react-native'

function CarrierGroupTopBox({ group }: CarrierGroupTopBoxProps) {
  const theme = useTheme()

  return (
    <>
      <StyledTopBox>
        <CardParam
          label={FormatUtils.capitalize(i18n.t('groupName'))}
          value={group?.label || 'chargement...'}
          flex={7}
          isStart
        />
        <CardParam
          label={'Commission logistique'}
          value={FormatUtils.formatPercentage(parseFloat(group?.carrierFees || '') / 100)}
          flex={5}
        />
      </StyledTopBox>
      <StyledTopBox>
        <CardParam
          label={'Commission Harvy'}
          value={
            (group?.platformFeesRules?.priceRules || []).length > 0 ? (
              <StyledFeesWrapper>
                {(group?.platformFeesRules?.priceRules || []).map((priceRule) => (
                  <StyledPriceRuleBox>
                    <Typography.BodySmall colorName="text-dark-3" bold>
                      {FormatUtils.formatPrice(priceRule.min)} à{'\n'}
                      {FormatUtils.formatPrice(priceRule.max)}
                    </Typography.BodySmall>
                    <Typography.BodySmall colorName="text-dark-1" bold>
                      {priceRule.fees ? FormatUtils.formatPercentage(priceRule.fees / 100) : 'NA'}
                    </Typography.BodySmall>
                  </StyledPriceRuleBox>
                ))}
                {(group?.platformFeesRules?.producerRules || []).map((producerRule) => {
                  const producerGroup = (group?.producerGroups || []).find(
                    (producerGroup) => producerGroup.producer?.id === producerRule.id,
                  )
                  return (
                    <StyledPriceRuleBox>
                      <Typography.BodySmall colorName="text-dark-3" bold>
                        {producerGroup?.producer?.label || 'NA'}
                      </Typography.BodySmall>
                      {producerRule.producerPriceRules.map((producerPriceRule) => (
                        <Typography.BodySmall colorName="text-dark-1">
                          {FormatUtils.formatPrice(producerPriceRule.min)} -{' '}
                          {FormatUtils.formatPrice(producerPriceRule.max)} :{' '}
                          {producerPriceRule.fees
                            ? FormatUtils.formatPercentage(producerPriceRule.fees / 100)
                            : 'NA'}
                        </Typography.BodySmall>
                      ))}
                    </StyledPriceRuleBox>
                  )
                })}
              </StyledFeesWrapper>
            ) : (
              `${group?.platformFees}%`
            )
          }
          flex={7}
          isStart
        />
      </StyledTopBox>
    </>
  )
}

export default CarrierGroupTopBox
