import styled from 'styled-components/native'
import { css } from 'styled-components/native'

const StyledPickerWrapper = styled.View<{ backgroundColor: string; small: boolean }>`
  position: relative;
  height: ${({ theme }) => theme.inputHeight}px;
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: ${({ theme }) => theme.colors['color-border']};
  border-width: 1px;
  ${({ small }) =>
    small &&
    css`
      height: ${({ theme }) => theme.inputHeight * 0.9}px;
    `}
`

const StyledDateValue = styled.View`
  flex: 1;
  justify-content: center;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 2}px;
  z-index: 1;
`

const StyledTextInput = styled.TextInput<{ paddingLeftValue?: number }>`
  flex: 1;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacingUnit * 2}px;
  padding-left: ${({ paddingLeftValue, theme }) => paddingLeftValue || theme.spacingUnit * 2}px;
  margin-top: ${({ theme, multiline }) => (multiline ? 0 : theme.spacingUnit * 2)}px;
  color: ${({ theme }) => theme.colors['text-dark-1']};
  font-size: ${({ theme }) => theme.fontSizes.BODY}px;
  z-index: 1;
  border: none !important;
`

const StyledWeekDayBox = styled.View`
  width: 80px;
  height: 29px;
  position: absolute;
  bottom: 0px;
  left: ${({ theme }) => theme.spacingUnit * 2}px;
  z-index: 0;
`
export { StyledPickerWrapper, StyledDateValue, StyledTextInput, StyledWeekDayBox }
