import React from 'react'
import Spacer from '../Spacer'
import { View } from 'react-native'
import Typography from '../Typography'
import { Product } from '../../domain'
import TouchableCard from '../TouchableCard'
import FormatUtils from '../../utilities/utils/format'
import DirectusUtil from '../../utilities/utils/directus'
import AddCartButton from '../../modules/Cart/AddCartButton'
import { BatchCardNewProps } from './BatchCardNew.model'
import {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledImage,
  StyledCenterBox,
  StyledValuesWrapper,
  StyledValueBox,
  StyledRightBox,
} from './BatchCardNew.styles'

const BatchCardNew = ({
  batch,
  cart,
  noButton,
  displayClientPrice,
  displayProducerLabel,
  displayMinPrice,
}: BatchCardNewProps) => {
  if (!batch || !cart) return <></>

  const getAvailablePackingQuantity = () => {
    if (
      batch?.availableQuantity &&
      batch.product?.packing &&
      batch?.availableQuantity >= batch.product?.packing
    ) {
      return Math.round(batch?.availableQuantity / batch.product?.packing)
    }
    return 0
  }
  return (
    <StyledCardWrapper activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <StyledImage
              source={DirectusUtil.getProductImageUrlFromId(batch?.product as any as Product)}
              resizeMode="cover"
            />
          </StyledLeftBox>
          <StyledCenterBox>
            <Typography.CardTitle colorName="text-dark-1" ellipsis>
              {batch?.product?.label}
            </Typography.CardTitle>
            {displayProducerLabel && (
              <Typography.BodyExtraSmall colorName="text-dark-3">
                {batch?.product?.producer?.label}
              </Typography.BodyExtraSmall>
            )}
            <StyledValuesWrapper>
              {displayMinPrice && (
                <StyledValueBox>
                  <Typography.BodySmall>Prix min.</Typography.BodySmall>
                  <Typography.BodySmall semiBold>
                    {FormatUtils.formatPrice(
                      batch?.unitPriceProducer,
                      undefined,
                      batch?.product?.producer,
                    )}
                    /{FormatUtils.formatUnity(batch?.product?.mesureType)}
                  </Typography.BodySmall>
                </StyledValueBox>
              )}
              {displayClientPrice && (
                <>
                  {displayMinPrice && <Spacer size={2.5} axis={Spacer.AxisEnum.HORIZONTAL} />}
                  <StyledValueBox>
                    <Typography.BodySmall>Prix client</Typography.BodySmall>
                    <Typography.BodySmall semiBold>
                      {FormatUtils.formatPrice(batch?.unitPrice)}/
                      {FormatUtils.formatUnity(batch?.product?.mesureType)}
                    </Typography.BodySmall>
                  </StyledValueBox>
                </>
              )}
              <Spacer size={2.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            </StyledValuesWrapper>
          </StyledCenterBox>
          <StyledRightBox>
            <View style={{ alignItems: 'flex-end' }}>
              <Typography.BodySmall colorName={'text-dark-3'}>
                par{' '}
                {FormatUtils.formatQuantity(batch?.product?.packing, batch?.product?.mesureType)}
              </Typography.BodySmall>
              {getAvailablePackingQuantity() > 0 && (
                <Typography.BodyExtraSmall colorName="text-dark-3">
                  Dispo. : {getAvailablePackingQuantity()} colis
                </Typography.BodyExtraSmall>
              )}
            </View>
            <View>{!noButton && <AddCartButton cart={cart} batch={batch} small />}</View>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default BatchCardNew
