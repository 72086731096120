import styled from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  flex-direction: row;
`

const StyledLeftBox = styled.View`
  flex: 5;
`

const StyledRightBox = styled.View`
  flex: 2;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`
export { StyledCardWrapper, StyledCardContent, StyledLeftBox, StyledRightBox }
