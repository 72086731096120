import React from 'react'
import { StyledTopBox } from './CarrierDeliveryFeesTopBox.styles'
import FormatUtils from '../../../../../utilities/utils/format'
import CardParam from '../CardParam'
import { CarrierDeliveryFeesTopBoxProps } from './CarrierDeliveryFeesTopBox.model'

function CarrierDeliveryFeesTopBox({ stats }: CarrierDeliveryFeesTopBoxProps) {
  return (
    <>
      <StyledTopBox>
        <CardParam
          label={'Frais dépensés'}
          value={FormatUtils.formatPrice(stats?.totalCarrierExpenses, 'ht')}
          flex={4}
          isStart
        />
        <CardParam
          label={'Commissionés'}
          value={FormatUtils.formatPrice(stats?.totalCarrierFeesCharged, 'ht')}
          flex={4}
          isStart
        />
        <CardParam
          label={'Résultat'}
          value={FormatUtils.formatPrice(stats?.balance, 'ht')}
          flex={4}
          isEnd
        />
      </StyledTopBox>
      <StyledTopBox>
        <CardParam
          label={'Déjà facturé'}
          value={FormatUtils.formatPrice(
            (stats?.totalCarrierFeesCharged || 0) - (stats?.totalPendingCarrierFeesCharged || 0),
            'ht',
          )}
          flex={4}
          isStart
        />
        <CardParam
          label={'À facturer'}
          value={FormatUtils.formatPrice(stats?.totalPendingCarrierFeesCharged, 'ht')}
          flex={4}
          isEnd
        />
      </StyledTopBox>
    </>
  )
}

export default CarrierDeliveryFeesTopBox
