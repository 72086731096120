import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Dimensions } from 'react-native'
import ForceUpgradeScreen from '../../screens/Authentification/ForceUpgrade'
import LockedScreen from '../../screens/Authentification/LockedScreen'
import ProducerMainNavigationStack from './ProducerMainNavigationStack'
import UserProducerConfigurationNavigation from './UserProducerNavigation/UserProducerConfigurationNavigation'
import ProfileProducerNavigation from './ProfileProducerNavigation/ProfileProducerNavigation'

const Stack = createStackNavigator()

const ProducerNavigationStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="ProducerMainNavigation"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="ProducerMainNavigation" component={ProducerMainNavigationStack} />

      <Stack.Screen
        name="ProfileProducerNav"
        component={ProfileProducerNavigation}
        options={{
          headerShown: false,
          presentation: 'transparentModal',
          ...TransitionPresets.ModalPresentationIOS,
          gestureResponseDistance: Dimensions.get('window').height,
        }}
      />

      <Stack.Screen
        name="UserProducerConfigurationNav"
        component={UserProducerConfigurationNavigation}
        options={{
          headerShown: false,
          presentation: 'transparentModal',
          ...TransitionPresets.ModalPresentationIOS,
          gestureResponseDistance: Dimensions.get('window').height,
        }}
      />

      <Stack.Screen
        name="ForceUpgradeScreen"
        component={ForceUpgradeScreen}
        options={{ gestureEnabled: false, headerShown: false, animationEnabled: false }}
      />

      <Stack.Screen
        name="LockedScreen"
        component={LockedScreen}
        options={{ gestureEnabled: false, headerShown: false, animationEnabled: false }}
      />
    </Stack.Navigator>
  )
}

export default ProducerNavigationStack
