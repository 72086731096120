import styled from 'styled-components/native'

const ProductInfosWrapper = styled.View`
  flex-direction: row;
  gap: ${({ theme }) => theme.spacingUnit * 4}px;
  width: 100%;
`

const DynamicImageWrapper = styled.View`
  width: 100%;
  height: 200px;
  align-items: end;
`
const LeftBox = styled.View`
  flex: 6;
`
const RightBox = styled.View`
  flex: 6;
  align-items: flex-start;
`

const StyledProductLabels = styled.View`
  flex-direction: row;
`

const StyledProductLabel = styled.View`
  justify-content: center;
  align-items: center;
  min-height: 17px;
  margin-right: ${({ theme }) => theme.spacingUnit * 0.6}px;
  width: fit-content;
  padding: ${({ theme }) => theme.spacingUnit * 0.3}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
  background-color: ${({ theme }) => theme.colors['color-primary']};
  border-radius: ${({ theme }) => theme.borderRadius * 0.5}px;
`

export {
  ProductInfosWrapper,
  RightBox,
  LeftBox,
  DynamicImageWrapper,
  StyledProductLabels,
  StyledProductLabel,
}
