import React from 'react'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import BadgeStatus from '../../../components/BadgeStatus'
import { ItemCardProps } from './ItemCard.model'
import {
  StyledArrowBox,
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
} from './ItemCard.styles'
import { TouchableOpacity } from 'react-native-gesture-handler'

const ItemCard = ({ title, description, onClick, iconType }: ItemCardProps) => {
  const theme = useTheme()

  const OnClickAction = () => {
    onClick()
  }

  return (
    <StyledCardWrapper>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.Body semiBold>{title}</Typography.Body>
            <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
            {description && <Typography.BodySmall>{description}</Typography.BodySmall>}
          </StyledLeftBox>
          <StyledRightBox>
            <TouchableOpacity onPress={OnClickAction}>
              <Icon
                type={Icons.Ionicons}
                name={iconType === 'DELETE' ? 'trash-bin' : 'chevron-forward-outline'}
                size={theme.spacingUnit * 2}
                color={theme.colors['text-dark-3']}
              />
            </TouchableOpacity>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default ItemCard
