import React from 'react'
import { useTheme } from 'styled-components/native'

const useCardTitleOptions = () => {
  const theme = useTheme()

  const headerStyle = {
    backgroundColor: theme.colors['background-1'],
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0,
  }

  const headerContainerPadding = theme.spacingUnit * 1

  const headerOptions = {
    headerShown: true,
    headerTitle: '',
    headerStyle,
    headerLeftContainerStyle: {
      paddingLeft: headerContainerPadding,
    },
    headerRightContainerStyle: {
      paddingRight: headerContainerPadding,
    },
  }

  return headerOptions
}

export default useCardTitleOptions
