import { Button, Card, Typography } from '../../../../../../components'
import FormatUtils from '../../../../../../utilities/utils/format'
import {
  StyledBox,
  StyledBoxWrapper,
  StyledButtonWrapper,
  StyledCardWrapperCart,
} from './ClientCatalogNavBarCartInfo.styles'
import { ClientCatalogNavBarCartInfoProps } from './ClientCatalogNavBarCartInfo.model'

const ClientCatalogNavBarCartInfo = ({
  cart,
  onClickEndOrder,
}: ClientCatalogNavBarCartInfoProps) => {
  return (
    <StyledCardWrapperCart onPress={() => null}>
      <Card>
        <StyledBoxWrapper>
          <StyledBox>
            <Typography.BodySmall colorName="color-primary" bold>
              {FormatUtils.formatPrice(cart.totalHTBatchs, 'ht')}
            </Typography.BodySmall>
            <Typography.BodyExtraSmall colorName="text-dark-3">
              {cart.nbCrates} colis
            </Typography.BodyExtraSmall>
            <Typography.BodyExtraSmall colorName="text-dark-3">
              {cart.producers?.length || 0} producteurs
            </Typography.BodyExtraSmall>
          </StyledBox>
          <StyledBox>
            <StyledButtonWrapper>
              <Button small label="Mon panier" onPress={() => onClickEndOrder()} />
            </StyledButtonWrapper>
          </StyledBox>
        </StyledBoxWrapper>
      </Card>
    </StyledCardWrapperCart>
  )
}

export default ClientCatalogNavBarCartInfo
