import { useRoute, RouteProp, useNavigation, CommonActions } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import { UserEnums } from '../../../../enums'
import { Spacer, SectionTitle, Button, Typography } from '../../../components'
import Loader from '../../../components/Loader'
import TourTopBox from '../../../components/TourTopBox'
import { Cart, CartBatch, Tour } from '../../../domain'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import TourProducerService from '../../../services/producer/tour'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ScreenSafeAreaWrapper, ContentWrapper } from '../../../utilities/styling/wrappers'
import { StyledButtonWrapper } from './ProducerUpdateCart.style'
import { useEffect, useState } from 'react'
import StorageUtil from '../../../utilities/storage/storage'
import GeneralUtils from '../../../utilities/utils/general'
import CartProducerService from '../../../services/producer/cart'
import FormatUtils from '../../../utilities/utils/format'
import { queryClient } from '../../../utilities/queryClient'
import { StackNavigationProp } from '@react-navigation/stack'
import { Alert } from 'react-native'
import OrderProducerService from '../../../services/producer/order'
import { GroupEnums } from '../../../../enums/group'
import CartUtil from '../../../utilities/utils/cart'
import CartBatchCardNew from '../../../components/CartBatchCardNew'
import TotalSummaryCard from '../../../components/TotalSummaryCard'

const ProducerUpdateCart = () => {
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerTourOrder'>>()
  const cartIdParam = route?.params?.cartId
  const tourId = route?.params?.tourId
  const [cartId, setCartId] = useState<string | undefined>(cartIdParam)
  const [isLoading, setIsLoading] = useState(false)
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [isNewOrder, setIsNewOrder] = useState(false)

  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: cart,
    refetch: refetchCart,
    isInitialLoading: cartLoading,
  } = useQuery<Cart | null, Error>(['p_cart'], () => CartProducerService.getOneCart(cartId), {
    keepPreviousData: true,
    enabled: cartId !== undefined,
  })

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = useQuery<Tour, Error>(
    ['p_tour_orders'],
    () => TourProducerService.getOneTourProducerOrders(tourId || '', getAccessInfos().producerId),
    {},
  )

  useEffect(() => {
    const getStoredCart = async () => {
      let storedCartId = await StorageUtil.getItem('p_cart_id')
      let isNewOrderStorage = await StorageUtil.getItem('p_cart_is_new_order')
      const storedCartIdExpirationDate = await StorageUtil.getItem('p_cart_id_expiration_date')

      if (storedCartIdExpirationDate) {
        const isToOld = GeneralUtils.isDateBefore(
          new Date(JSON.parse(storedCartIdExpirationDate)),
          new Date(),
        )
        if (isToOld) {
          storedCartId = null
          StorageUtil.deleteItem('p_cart_id')
          StorageUtil.deleteItem('p_cart_is_new_order')
          StorageUtil.deleteItem('p_cart_id_expiration_date')
        }
      }

      if (storedCartId) {
        await setCartId(JSON.parse(storedCartId))
        await setIsNewOrder(isNewOrderStorage !== null ? true : false)
        refetchCart()
      } else {
        navigation.navigate('ProducerTourOrder', { cartId: cart?.id, tourId: tour?.id })
      }
    }
    getStoredCart()
  }, [])

  const onValidateOrder = async () => {
    if (!cart || !cart.id) return
    setIsLoading(true)
    const isUpdatedOrder = cart.targetCart
    let order = null
    if (isUpdatedOrder && cart.order?.id) {
      order = await OrderProducerService.update({ cartId: cart.id })
    } else {
      order = await OrderProducerService.create({ cartId: cart.id })
    }
    if (order && order.id) {
      StorageUtil.deleteItem('p_cart_id')
      StorageUtil.deleteItem('p_cart_is_new_order')

      await queryClient.refetchQueries(['p_tour_orders'])

      navigation.dispatch(
        CommonActions.reset({
          index: 2,
          routes: [
            {
              name: 'ProducerTours',
              params: {},
            },
            {
              name: 'ProducerTour',
              params: { id: tourId },
            },
            {
              name: 'ProducerTourOrder',
              params: { cartId: order.cart?.id, tourId: tourId },
            },
          ],
        }),
      )

      setIsLoading(false)
      return
    } else {
      Alert.alert('Erreur lors de la création de la commande')
    }
    setIsLoading(false)
  }

  const onClickAddCartBatch = () => {
    if (!cartId || !tourId) return
    navigation.navigate('ProducerAddCartBatch', { cartId, tourId })
  }

  const groupIsDeliveryProducer = () => {
    return tour?.group?.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS
  }

  const producerCartBatchs =
    cart?.cartBatchs?.filter(
      (cb) => cb.batch?.product?.producer?.id === getAccessInfos().producerId,
    ) || []
  if (!cart) {
    return <Loader isLoading pageLoading />
  }

  const totals = CartUtil.getOrderTotals(cart, getAccessInfos().producerId)

  const cartWithProducerCartBatchs = { ...cart, cartBatchs: producerCartBatchs }

  const totalSummaryItems = [
    {
      totalHT: cart.totalHTBatchs || 0,
      totalVAT: (cart.totalVAT55 || 0) + (cart.totalVAT10 || 0) + (cart.totalVAT20 || 0),
    },
  ]

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <TourTopBox
        tour={tour}
        cart={cartWithProducerCartBatchs}
        userRole={UserEnums.RoleEnums.PRODUCER}
      />
      <ContentWrapper marginTopLarge>
        <Spacer fixedSize={50} axis={Spacer.AxisEnum.VERTICAL} />
        <SectionTitle title={!isNewOrder ? 'Contenu de la commande' : "Création d'une commande"} />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {cart?.targetCart && !isNewOrder && (
          <>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.CardTitle>
              Modification de la commande #{FormatUtils.formatId(cart.order?.id)}
            </Typography.CardTitle>
            <Typography.BodySmall colorName="text-dark-3">
              Validée initialement le{' '}
              {FormatUtils.capitalize(
                FormatUtils.formatDate(cart.order?.createdDate, 'FullDate+StartTime'),
              )}
            </Typography.BodySmall>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          </>
        )}
        {producerCartBatchs?.map((cartBatch) => (
          <CartBatchCardNew
            cartBatch={cartBatch}
            cart={cart}
            key={cartBatch.id}
            displayClientPrice={groupIsDeliveryProducer()}
            displayProducerPrice
            displayMinPrice
            displayGain
          />
        ))}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <StyledButtonWrapper>
          <Button label="Ajouter un lot" onPress={() => onClickAddCartBatch()} small />
        </StyledButtonWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        {producerCartBatchs.length > 0 && (
          <>
            <TotalSummaryCard items={totalSummaryItems} />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <StyledButtonWrapper>
              <Button
                label={isNewOrder ? 'Valider la commande' : 'Modifier la commande'}
                onPress={() => onValidateOrder()}
              />
            </StyledButtonWrapper>
          </>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerUpdateCart
