import styled from 'styled-components/native'

const StyledFlatlistItem = styled.View`
  flex-direction: row;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.5}px;
`

const StyledFirstBox = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`

const StyledIconBox = styled.View`
  align-items: center;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
`

const StyledCenterBox = styled.View`
  flex: 1;
  padding-left: ${({ theme }) => theme.spacingUnit * 3}px;
`

const StyledArrowBox = styled.View`
  justify-content: center;
  align-items: center;
`

export { StyledFlatlistItem, StyledArrowBox, StyledCenterBox, StyledIconBox, StyledFirstBox }
