import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Spacer, PageTitle, SectionTitle, Dropdown, Button } from '../../../components'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'

import { Producer, UpdateProducer } from '../../../domain'
import ProducerProducerService from '../../../services/producer/producer'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import CardSettings from '../../../components/CardSettings'
import { NotificationEnums } from '../../../../enums/notification'
import { StyledButtonWrapper } from './ProducerSettings.styles'
import FormatUtils from '../../../utilities/utils/format'
import i18n from '../../../i18n'
import { set } from 'date-fns'

const ProducerSettingsScreen = () => {
  const theme = useTheme()
  const [updatedProducer, setUpdatedProducer] = useState<UpdateProducer>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedNotificationSettingsClientOrder, setSelectedNotificationSettingsClientOrder] =
    useState<any | undefined>(undefined)

  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const {
    data: producerData,
    refetch: refetchProducer,
    isInitialLoading: producerLoading,
  } = useQuery<{ producer: Producer; stepsData: TutorialStepData[] }, Error>(
    ['p_producer', getAccessInfos().producerId],
    () => ProducerProducerService.getOne(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (!producerData?.producer) return

    setUpdatedProducer({
      ...producerData.producer,
    })
    const notificationType = NotificationEnums.PRODUCER_NOTIFICATION_SETTINGS_CLIENT_ORDER.find(
      (mt: any) => mt.value === producerData.producer?.notificationSettings?.clientOrder,
    )

    if (notificationType) {
      setSelectedNotificationSettingsClientOrder({
        ...notificationType,
      })
    } else {
      setSelectedNotificationSettingsClientOrder({
        label: 'Choisir une option',
        value: -1,
      })
    }
  }, [producerData?.producer, setUpdatedProducer])

  const onChangeSettings = async () => {
    setErrorMessage('')
    setIsLoading(true)

    const updatedProducerSettings = {
      notificationSettings: {
        ...updatedProducer?.notificationSettings,
        clientOrder: selectedNotificationSettingsClientOrder?.value,
      },
    }

    if (!updatedProducer) return null
    await ProducerProducerService.update(getAccessInfos().producerId, updatedProducerSettings)
      .then(() => {
        navigation.navigate('ProfileProducerNav', {
          screen: 'ProducerProfile',
          params: {},
        })
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la modification de vos paramètres')
      })
    setIsLoading(false)
  }

  const onChangeNotificationType = (value: any) => {
    setSelectedNotificationSettingsClientOrder(value)
  }

  return (
    <ContentWrapper noPaddingLeft>
      <PageTitle title={'Paramètres'} />
      <SectionTitle title={`Notifications`} />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      <CardSettings
        title="Nouvelles commandes"
        description="Recevoir un e-mail quand un client commande au moins un de vos produits"
      >
        <Dropdown
          label="Fréquence"
          onChange={onChangeNotificationType}
          multiple={false}
          optionsDefault={NotificationEnums.PRODUCER_NOTIFICATION_SETTINGS_CLIENT_ORDER.map(
            (element) => ({
              ...element,
            }),
          )}
          defaultValue={selectedNotificationSettingsClientOrder?.value}
        />
      </CardSettings>
      <StyledButtonWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
        <Button
          label={FormatUtils.capitalize(i18n.t('save'))}
          onPress={() => onChangeSettings()}
          loading={isLoading}
          hasDoubleValidation
          confirmationLabel="Êtes-vous sûr de vouloir modifier vos paramètres ?"
        />
      </StyledButtonWrapper>
    </ContentWrapper>
  )
}

export default ProducerSettingsScreen
