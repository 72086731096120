import React from 'react'
import { Keyboard, TouchableWithoutFeedback } from 'react-native'
import * as Device from 'expo-device'

const KeyboardDismissWrapper: React.FC = ({ children }) => (
  <TouchableWithoutFeedback
    onPress={Device.isDevice && Device.osInternalBuildId !== null ? Keyboard.dismiss : () => null}
    style={{ flex: 1 }}
  >
    {children}
  </TouchableWithoutFeedback>
)

export default KeyboardDismissWrapper
