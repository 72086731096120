import React from 'react'
import { Spacer, TitleWithTag, TouchableCard, Typography } from '../../../components'
import { Product } from '../../../domain'
import DirectusUtil from '../../../utilities/utils/directus'
import FormatUtils from '../../../utilities/utils/format'
import { ProductCardProps } from './ProductCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledImage,
  StyledLeftBox,
  StyledRightBox,
  StyledTypeInfoBox,
  StyledValueBox,
  StyledValuesWrapper,
} from './ProductCard.styles'

const ProductCard = ({ product, onClick }: ProductCardProps) => {
  const OnClickProduct = () => {
    onClick(product)
  }

  const getProductCategories = () => {
    const productTypeCategories = product.productType?.productTypeCategories
    if (!productTypeCategories || productTypeCategories.length < 1) {
      return '-'
    }

    const nbCategories = productTypeCategories.length
    if (nbCategories === 1) {
      return productTypeCategories[0]?.category?.label
    }
    return `${productTypeCategories[0]?.category?.label} (+${nbCategories - 1})`
  }

  return (
    <StyledCardWrapper onPress={OnClickProduct} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <StyledImage
              source={DirectusUtil.getProductImageUrlFromId(product as any as Product)}
              resizeMode="cover"
            />
          </StyledLeftBox>
          <StyledRightBox>
            <TitleWithTag tag={product.internalReference}>
              <Typography.CardTitle ellipsis>{product.label}</Typography.CardTitle>
            </TitleWithTag>
            <StyledTypeInfoBox>
              <Typography.BodySmall ellipsis colorName="text-dark-3" bold>
                {product.typeInfo}
              </Typography.BodySmall>
            </StyledTypeInfoBox>

            <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />

            <StyledValuesWrapper>
              <StyledValueBox>
                <Typography.BodySmall colorName="text-dark-3">Prix min.</Typography.BodySmall>
                <Typography.Body semiBold>
                  {FormatUtils.formatPrice(product.minUnitPrice, 'ht', product.producer)}/
                  {FormatUtils.formatUnity(product?.mesureType)}
                </Typography.Body>
              </StyledValueBox>
              <StyledValueBox>
                <Typography.BodySmall colorName="text-dark-3">Labels</Typography.BodySmall>
                <Typography.Body semiBold>{product.formattedProductLabels}</Typography.Body>
              </StyledValueBox>

              <StyledValueBox>
                <Typography.BodySmall colorName="text-dark-3">Catégorie</Typography.BodySmall>
                <Typography.Body semiBold>{getProductCategories()}</Typography.Body>
              </StyledValueBox>
            </StyledValuesWrapper>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default ProductCard
