import { TranslationObject } from './TranslationObject.model'

// eslint-disable-next-line import/prefer-default-export
export const FR: TranslationObject = {
  // GET STARTED
  getStartedTitleIntro1: "Le réseau social d'information",
  getStartedTitleIntro2: 'utile et efficace',
  getStartedDescriptionIntro:
    'Construisez votre arbre, rejoignez les forets de vos amis, partagez des astuces, lisez, sauvegardez, utilisez',
  getStartedCreateAccount: 'Créer un compte',
  getStartedLogin: 'Se Connecter',

  // COMMON
  greeting: 'bonjour',
  changeLanguage: 'Changer de langue',
  chooseLanguage: 'Choisir une langue',

  // ACTIONS
  add: 'Ajouter',
  added: 'Ajouté',
  remove: 'Retirer',
  update: 'modifier',
  save: 'enregistrer',
  download: 'télécharger',
  displayMore: 'Voir plus',
  displayLess: 'Voir moins',
  conclude: 'Clôturer',

  // MOTS
  destination: 'destination',
  orders: 'commandes',
  producers: 'producteurs',
  products: 'produits',
  product: 'produit',
  since: 'depuis le',
  steps: 'étapes',
  tours: 'Tournées',
  tour: 'Tournée',

  // PAGE TITLES
  pageTitleGroup: 'Groupe',
  pageTitleTours: 'Tournées',
  pageTitleToursProducer: 'Tournées',
  pageTitleTour: 'Tournée',
  pageTitleAddTour: 'Ajouter une tournée',
  pageTitleCatalog: 'Votre catalogue',
  pageTitleAddProduct: 'Ajouter un produit',
  pageTitleUpdateProduct: 'Modifier un produit',
  pageTitleProposition: 'Vos mercuriales',
  pageTitleAddBatch: 'Ajouter un lot à la mercuriale',
  pageTitleUpdateBatch: 'Modifier un lot',

  // FARMERS GROUP
  groupName: 'Nom du groupement',

  // TOURS GROUP
  nextTours: 'Prochaines Tournées',
  participantTours: 'Tournées auquelles vous participez',
  joinableTours: 'Tournées à rejoindre',
  finishedTours: 'Tournées Terminées',
  addTour: 'Ajouter une tournée',
  organiseSteps: 'Organiser les étapes',

  // CATALOG
  createProduct: 'Créer le produit',

  // PROPOSITION
  propositionProducts: 'Produits disponibles pour cette tournée',
  chooseTour: 'Choisir une tournée',
  seeProposition: 'Voir la mercuriale',

  // BATCH
  addBatch: 'Ajouter un lot',
  chooseProduct: 'Choisir un produit',
}
