import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledImage = styled.Image`
  width: ${({ theme }) => theme.spacingUnit * 10}px;
  max-width: 90%;
  height: ${({ theme }) => theme.spacingUnit * 10}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
`

const StyledImageRight = styled.View`
  flex: 1;
`

const StyledImageBox = styled.View`
  padding: ${({ theme }) => theme.spacingUnit * 1.5}px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const AddProductContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const BackToListWrapper = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`

const BottomBoxWrapper = styled.View`
  flex: 1;
`
const GalleryWrapper = styled.View`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  flex: 1;
  min-height: ${({ theme }) => theme.spacingUnit * 36}px;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  padding: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 2}px;
`

const GalleryImageWrapper = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 3}px;
  margin-left: ${({ theme }) => theme.spacingUnit * 3}px;
  flex-direction: row;
`

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const BoxWrapperProfile = styled.View`
  width: 100%;
`

const TopBoxWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 0.5}px;
  flex-direction: row;
`

const LeftBox = styled.View`
  flex: 1.25;
`
const RightBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 1.5}px;
  flex: 3.5;
`

const DynamicImageWrapper = styled.View`
  width: 90px;
  max-width: 90%;
  height: 90px;
`

export {
  AddProductContentWrapper,
  StyledButtonWrapper,
  BackToListWrapper,
  StyledImageBox,
  StyledImage,
  StyledImageRight,
  GalleryWrapper,
  GalleryImageWrapper,
  LeftBox,
  RightBox,
  BottomBoxWrapper,
  StyledCardWrapper,
  BoxWrapperProfile,
  TopBoxWrapper,
  DynamicImageWrapper,
}
