import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledButtonWrapper = styled.TouchableOpacity`
  width: 100%;
  align-items: center;
  justify-content: center;
`

export { StyledButtonWrapper }
