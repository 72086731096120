import styled from 'styled-components/native'

const FilterModuleWrapper = styled.View`
  padding: ${({ theme }) => theme.spacingUnit * 1.1}px;
  width: 100%;
  z-index: 1;
`

const FilterOptionsContainer = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const ButtonsContainer = styled.View`
  flex-direction: row;
  margin-left: ${({ theme }) => theme.spacingUnit * 1}px;
  gap: ${({ theme }) => theme.spacingUnit * 0.8}px;
`

const DropdownWrapper = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 1.1}px;
  background-color: ${({ theme }) => theme.colors['color-white']};
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  align-items: center;
`

const DropdownContainer = styled.View`
  align-items: center;
  width: 65%;
  padding: ${({ theme }) => theme.spacingUnit * 1.1}px;
  align-items: center;
`

const StyledButtonWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.1}px;
  width: 100%;
`

export {
  FilterModuleWrapper,
  FilterOptionsContainer,
  ButtonsContainer,
  DropdownWrapper,
  DropdownContainer,
  StyledButtonWrapper,
}
