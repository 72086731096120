import styled, { css } from 'styled-components/native'

const DynamicImageWrapper = styled.View`
  justify-content: center;
  flex-direction: row;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  width: 100%;
  height: 100%;
`
const StyledImage = styled.Image`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  width: 100%;
  height: 100%;
`

const StyledModalWrapper = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba(20, 20, 20, 0.35);
`

const StyleImageWrapper = styled.View`
  flex: 1;
  flex-direction: row;
`
const StyledImageModal = styled.Image`
  width: ${({ theme }) => theme.spacingUnit * 45}px;
  height: ${({ theme }) => theme.spacingUnit * 45}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
`
const StyledModal = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 3}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  flex-direction: row;
`
const ModalCloseButton = styled.TouchableOpacity`
  margin-left: ${({ theme }) => theme.spacingUnit * -4}px;
`
export {
  StyledImage,
  StyledImageModal,
  StyledModalWrapper,
  StyledModal,
  DynamicImageWrapper,
  ModalCloseButton,
  StyleImageWrapper,
}
