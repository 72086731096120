import Ionicons from '@expo/vector-icons/Ionicons'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import React from 'react'
import { useTheme } from 'styled-components/native'
import { IconProps } from './Icon.model'

export const Icons = {
  Ionicons,
  MaterialCommunityIcons,
}

const Icon = ({ type, name, color, size = 24, style, onPress }: IconProps) => {
  const theme = useTheme()
  const defaultColor = theme.colors['color-primary']
  const fontSize = 24
  const Tag: any = type
  return (
    <>
      {type && name && (
        <Tag
          testID={`icon-${name}`}
          name={name}
          size={size || fontSize}
          color={color ?? defaultColor}
          style={style}
          onPress={onPress}
        />
      )}
    </>
  )
}

export default Icon
