import { ScrollView } from 'react-native-gesture-handler'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../../utilities/styling/wrappers'
import {
  RegistrationLandingPageWrapper,
  StyledImageLogo,
  StyledImageLabel,
  StyledLogoBox,
  TextWrapper,
  ButtonWrapper,
  BottomPartWrapper,
} from './RegistrationLanding.styles'
import { Button, Spacer, Typography } from '../../../../../components'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { AuthenticationStackParamList } from '../../../../../navigation/AuthenticationNavigationStack/AuthenticationNavigationStack.model'
import { useQuery } from '@tanstack/react-query'
import { Client, Producer, User } from '../../../../../domain'
import AuthenticationService from '../../../../../services/authentication'
import { StackNavigationProp } from '@react-navigation/stack'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import { useEffect } from 'react'
import { View } from 'react-native'
import Loader from '../../../../../components/Loader'
import { UserEnums } from '../../../../../../enums'
import StorageUtil from '../../../../../utilities/storage/storage'
import { PublicStackParamList } from '../../../../../navigation/PublicNavigationStack/PublicNavigationStack.model'

const RegistrationLandingScreen = () => {
  const route = useRoute<RouteProp<AuthenticationStackParamList, 'RegistrationLanding'>>()
  const navigation = useNavigation<StackNavigationProp<PublicStackParamList>>()
  const { token } = route?.params ?? { token: '' }

  const { logout, isUserAuthenticated } = useAuthenticationContext()

  const {
    data: invitation,
    refetch: refetchInvitation,
    isInitialLoading: isInvitationLoading,
  } = useQuery<{ user: User; producer?: Producer; client?: Client }, Error>(
    ['check_invitation', token],
    () => AuthenticationService.checkInvitation(token),
    {},
  )

  useEffect(() => {
    if (isUserAuthenticated) {
      logout()
    }
  }, [])

  const onCreateAccount = () => {
    if (!invitation?.user?.email) {
      return
    }

    navigation.navigate('Authentication', {
      screen: 'RegistrationForm',
      params: {
        token,
        producerLabel: invitation?.producer?.label,
        clientLabel: invitation?.client?.label,
        email: invitation?.user?.email,
      },
    })
  }

  if (isInvitationLoading) {
    return <Loader isLoading pageLoading noHeader noMargin paddingLeft={false} />
  }

  const isExpired = () => {
    return invitation?.user?.status !== UserEnums.STATUS.INVITATION || !token
  }

  return (
    <ScreenSafeAreaWrapper noPaddingLeft>
      <ContentWrapper alignCenter noHeader noDrawer>
        <RegistrationLandingPageWrapper>
          <StyledLogoBox>
            <StyledImageLogo
              source={require('../../../../../../assets/logos/logo.png')}
              resizeMode="contain"
            />
            <StyledImageLabel
              source={require('../../../../../../assets/logos/logo_label.png')}
              resizeMode="contain"
            />
          </StyledLogoBox>
          <BottomPartWrapper>
            <TextWrapper>
              {isExpired() ? (
                <View style={{ alignItems: 'center' }}>
                  <Typography.PageTitle colorName="color-danger" bold align="center">
                    Lien expiré
                  </Typography.PageTitle>
                  <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                  <Typography.Body colorName="color-primary" align="center">
                    L'invitation que vous avez reçu a expirée
                  </Typography.Body>
                  <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
                  <Button
                    onPress={() =>
                      navigation.navigate('Authentication', {
                        screen: 'Login',
                        params: {},
                      })
                    }
                    label="Retour"
                    colorName="color-secondary"
                  />
                </View>
              ) : (
                <>
                  <Typography.Title colorName="color-primary">
                    {invitation?.user.creator?.firstName
                      ? `${invitation?.user.creator?.firstName} vous invite à rejoindre Harvy`
                      : 'Vous avez été invité à rejoindre Harvy'}
                  </Typography.Title>
                  <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                  <Typography.Body
                    colorName="color-primary"
                    style={{ fontSize: '20px', lineHeight: '26px' }}
                  >
                    Commencez à utiliser Harvy dès maintenant
                  </Typography.Body>
                </>
              )}
            </TextWrapper>
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            {!isExpired() && (
              <ButtonWrapper>
                <Button
                  onPress={() => onCreateAccount()}
                  label="Créer mon compte"
                  colorName="color-secondary"
                  fullWidth
                />
              </ButtonWrapper>
            )}
          </BottomPartWrapper>
        </RegistrationLandingPageWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default RegistrationLandingScreen
