import styled from 'styled-components/native'

const StyledButtonWrapper = styled.View`
  align-items: center;
  justify-content: center;
`

const StyledFieldWrapper = styled.View`
  flex-direction: row;
`

export { StyledButtonWrapper }
