import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Col,
  Dropdown,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import DatePicker from '../../../components/DatePicker'
import TextInput from '../../../components/inputs/TextInput'
import Loader from '../../../components/Loader'
import { City, Tour, UpdateTour } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../services/carrier/tour'
import { queryClient } from '../../../utilities/queryClient'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import {
  ToursContentWrapper,
  StyledButtonWrapper,
  StyledModeSwitchWrapper,
} from './CarrierUpdateTour.styles'
import { Switch } from 'react-native-gesture-handler'
import CityProducerService from '../../../services/producer/city'
import GeneralUtils from '../../../utilities/utils/general'
import TourUtil from '../../../utilities/utils/tour'
import { CarrierFeesEnums } from '../../../../enums/carrierFees'
import EnumLabelUtil from '../../../utilities/utils/enumLabel'
import CityForm from '../../../modules/City/CityForm'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { RightKeyEnum } from '../../../../enums'

const CarrierUpdateTourScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdateTour'>>()
  const { id } = route?.params ?? { id: '' }
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCity, setSelectedCity] = useState<any>()
  const [selectedDeliveryProducer, setSelectedDeliveryProducer] = useState<any>()
  const [selectedCarrierFeesSharingRule, setSelectedCarrierFeesSharingRule] = useState<any>()

  const [showDateDepositErrorMessage, setShowDateDepositErrorMessage] = useState(false)
  const [showDateDepositWarningMessage, setShowDateDepositWarningMessage] = useState('')

  const { carrierHasRight } = useAuthenticationContext()

  const theme = useTheme()

  const {
    data: oldTour,
    refetch: refetchOldTour,
    isInitialLoading: oldTourLoading,
  } = TourCarrierService.getOne.query(id)

  const {
    data: cities,
    refetch: refetchCities,
    isInitialLoading: citiesLoading,
  } = useQuery<City[], Error>(['cities'], () => CityProducerService.getAll(), {
    keepPreviousData: true,
  })

  if (oldTourLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!oldTour) return <></>

  useEffect(() => {
    if (oldTour.city?.id) {
      setSelectedCity({
        ...oldTour.city,
        value: oldTour?.city?.id,
      })
      setSelectedDeliveryProducer({
        ...oldTour?.carrier,
        value: oldTour?.carrier?.id,
      })
    }

    const type = CarrierFeesEnums.CARRIER_FEES_RULES.find(
      (cfr: any) => cfr.value === oldTour?.carrierFeesSharingRule,
    )

    if (type) {
      setSelectedCarrierFeesSharingRule({
        ...type,
      })
    } else {
      setSelectedCarrierFeesSharingRule({
        label: 'Choisir une option',
        value: 0,
      })
    }
  }, [oldTour])

  const [updatedTour, setUpdatedTour] = useState<UpdateTour>({
    id: oldTour.id,
    target: oldTour.target,
    start: new Date(oldTour.start),
    end: new Date(oldTour.end),
    deposit: new Date(oldTour.deposit),
    close: new Date(oldTour.close),
    open: oldTour.open ? new Date(oldTour.open) : undefined,
    capacity: oldTour.capacity ? (oldTour.capacity / 1000000).toString() : '',
    carrierFees: oldTour.carrierFees?.toString(),
    carrierExpenses: oldTour.carrierExpenses?.toString(),
    palletQuantity: oldTour.palletQuantity?.toString(),
    minOrderValueHT: oldTour.minOrderValueHT?.toString(),
    freeDeliveryOrderValueHT: oldTour.freeDeliveryOrderValueHT?.toString(),
    deliveryFeesHT: oldTour.deliveryFeesHT?.toString(),
    isPrivate: oldTour.isPrivate,
    carrierId: oldTour.carrier?.id,
    paymentConditions: oldTour.paymentConditions,
  })
  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in updatedTour)) return null

    const tourTemp = Object.assign({}, updatedTour)

    tourTemp[param as keyof typeof updatedTour] = value
    setUpdatedTour(tourTemp)
  }

  const onChangeDate = (value: any) => {
    setErrorMessage('')

    const tourTemp = Object.assign({}, updatedTour)
    tourTemp.start = value
    tourTemp.end = value

    setUpdatedTour(tourTemp)
  }

  const onChangeTimeStart = (value: any) => {
    setValue(value, 'start')
  }

  const onChangeTimeEnd = (value: any) => {
    setValue(value, 'end')
  }

  const onChangeDateDeposit = (value: any) => {
    setValue(value, 'deposit')

    const diffHours = updatedTour.start
      ? GeneralUtils.diffHours(new Date(value), new Date(updatedTour.start))
      : 0

    setShowDateDepositErrorMessage(diffHours > 24)
    setShowDateDepositWarningMessage(
      'La modification de la date de dépôt nécessitera la modification des mercuriales de chaque producteur.',
    )
  }

  const onChangeDateClose = (value: any) => {
    setValue(value, 'close')
  }

  const onChangeDateOpen = (value: any) => {
    setValue(value, 'open')
  }

  const onChangeIsPrivate = () => {
    if (updatedTour?.isPrivate) {
      setValue(false, 'isPrivate')
      return
    }
    setValue(true, 'isPrivate')
  }

  const checkMissingField = () => {}

  const onUpdateTour = async () => {
    setErrorMessage('')

    if (
      GeneralUtils.isDateBefore(
        new Date(updatedTour.end || ''),
        new Date(updatedTour.start || ''),
        -1,
      )
    ) {
      setErrorMessage("L'heure de début de la tournée doit être avant celle de fin")
      return false
    }

    if (
      GeneralUtils.isDateBefore(
        new Date(updatedTour.start || ''),
        new Date(updatedTour.deposit || ''),
        -1,
      )
    ) {
      setErrorMessage('La date de dépôt de la marchandise doit être avant celle de livraison')
      return false
    }

    if (
      GeneralUtils.isDateBefore(
        new Date(updatedTour.deposit || ''),
        new Date(updatedTour.close || ''),
        -1,
      )
    ) {
      setErrorMessage(
        'La date de fermeture des commandes doit être avant celle de dépôt de la marchandise',
      )
      return false
    }

    if (
      GeneralUtils.isDateBefore(
        new Date(updatedTour.close || ''),
        new Date(updatedTour.open || ''),
        -1,
      )
    ) {
      setErrorMessage("La date d'ouverture des commandes doit être avant celle de fermeture")
      return false
    }

    setIsLoading(true)

    const tour = {
      target: updatedTour.target,
      start: updatedTour.start,
      end: updatedTour.end,
      deposit: updatedTour.deposit,
      open: updatedTour.open,
      close: updatedTour.close,
      capacity: FormatUtils.stringToFloat(updatedTour.capacity) * 1000000,
      carrierFees: FormatUtils.stringToFloat(updatedTour.carrierFees),
      carrierExpenses: FormatUtils.stringToFloat(updatedTour.carrierExpenses),
      palletQuantity: FormatUtils.stringToFloat(updatedTour.palletQuantity),
      minOrderValueHT: FormatUtils.stringToFloat(updatedTour.minOrderValueHT),
      freeDeliveryOrderValueHT: FormatUtils.stringToFloat(updatedTour.freeDeliveryOrderValueHT),
      deliveryFeesHT: FormatUtils.stringToFloat(updatedTour.deliveryFeesHT),
      isPrivate: updatedTour.isPrivate,
      cityId: selectedCity.id,
      carrierId: selectedDeliveryProducer.id,
      carrierFeesSharingRule: selectedCarrierFeesSharingRule.value,
      paymentConditions: updatedTour.paymentConditions,
    }

    const updatedTourTemp = await TourCarrierService.update(oldTour.id, tour)
    if (updatedTourTemp && updatedTourTemp.id) {
      TourCarrierService.getOne.invalidate(id)

      navigation.navigate('CarrierTour', { id: updatedTourTemp.id })
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour de la tournée')
    }
    setIsLoading(false)
  }

  const getTourIdFormatted = () => {
    if (oldTour && oldTour.id && oldTour.id.length > 5) {
      return oldTour.id.slice(-5)
    }
    return 'NA'
  }

  const onChangeCity = (value: any) => {
    setSelectedCity(value)
  }
  const onChangeDeliveryProducer = (value: any) => {
    setSelectedDeliveryProducer(value)
  }

  const onChangeCarrierFeesSharingRule = (value: any) => {
    setSelectedCarrierFeesSharingRule(value)
  }

  const currentCarrierFeesSharingRuleDescription = () => {
    const rule = CarrierFeesEnums.CARRIER_FEES_RULES.find(
      (cfr: any) => cfr.value === selectedCarrierFeesSharingRule?.value,
    )
    if (rule && rule.description) {
      return rule.description
    }
    return ''
  }

  const hasSuperAdminAccesRight = carrierHasRight(RightKeyEnum.C_SUPER_ADMIN)

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title={`Mettre à jour la tournée #${getTourIdFormatted()}`} />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

          <Dropdown
            label={'Producteur-livreur'}
            onChange={onChangeDeliveryProducer}
            multiple={false}
            defaultValue={selectedDeliveryProducer?.id}
            optionsDefault={
              !oldTour.group?.carrierGroups
                ? []
                : oldTour.group.carrierGroups.map((cg) => ({
                    ...cg.carrier,
                    label: cg?.carrier?.name,
                    value: cg?.carrier?.id,
                  }))
            }
            zIndex={1000}
            zIndexInverse={4000}
            itemKey="carrier"
          />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <CityForm cities={cities || []} onChangeCity={onChangeCity} selectedCity={selectedCity} />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

          <TextInput
            value={updatedTour.target}
            label="Etapes (autres villes)"
            field="text"
            onChangeText={(text) => setValue(text, 'target')}
            autoFocus
          />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <SectionTitle title={`Livraison`} />
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="tourstartdate"
                label="Date de la tournée"
                mode="date"
                onChange={onChangeDate}
                value={updatedTour.start}
                withDay
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="heuredebut"
                label="Début"
                onChange={onChangeTimeStart}
                value={updatedTour.start}
                mode={'time'}
                outputFormat="date"
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="heurefin"
                label="Fin"
                onChange={onChangeTimeEnd}
                value={updatedTour.end}
                mode={'time'}
                outputFormat="date"
              />
            </Col>
          </Row>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <SectionTitle title={`Limite de dépot de la marchandise`} />
          <Row>
            <Col xs={8}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="datedepot"
                label="Limite de dépôt"
                onChange={onChangeDateDeposit}
                value={updatedTour.deposit}
                mode={'date'}
                outputFormat="date"
                withDay
              />
            </Col>
            <Col xs={4}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="datedepot"
                label="Heure"
                onChange={onChangeDateDeposit}
                value={updatedTour.deposit}
                mode={'time'}
                outputFormat="date"
              />
            </Col>
          </Row>
          {showDateDepositErrorMessage && (
            <>
              <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall colorName="color-danger" style={{ textAlign: 'center' }}>
                Attention, la date de dépôt de la marchandise est supérieure à 24h par rapport à la
                date de début de tournée.
              </Typography.BodySmall>
            </>
          )}
          {showDateDepositWarningMessage && (
            <>
              <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall colorName="color-warning" style={{ textAlign: 'center' }}>
                {showDateDepositWarningMessage}
              </Typography.BodySmall>
            </>
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <SectionTitle title={`Horaires des commandes`} />
          <Row>
            <Col xs={8}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="datedebutcommandes"
                label="Début des commandes"
                onChange={onChangeDateOpen}
                value={updatedTour.open}
                mode={'date'}
                outputFormat="date"
                withDay
              />
            </Col>
            <Col xs={4}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="heuredebutcommandes"
                label="Heure"
                onChange={onChangeDateOpen}
                value={updatedTour.open}
                mode={'time'}
                outputFormat="date"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="datefincommandes"
                label="Fin des commandes"
                onChange={onChangeDateClose}
                value={updatedTour.close}
                mode={'date'}
                outputFormat="date"
                withDay
              />
            </Col>
            <Col xs={4}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <DatePicker
                id="heurefincommandes"
                label="Heure"
                onChange={onChangeDateClose}
                value={updatedTour.close}
                mode={'time'}
                outputFormat="date"
              />
            </Col>
          </Row>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <SectionTitle title={`Paramètres`} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Row>
            <Col xs={6} sm={6}>
              <TextInput
                value={updatedTour.carrierExpenses?.toString()}
                label="Coût estimé (en €)"
                field="text"
                onChangeText={(text) => setValue(text, 'carrierExpenses')}
              />
            </Col>
            <Col xs={6} sm={6}>
              <TextInput
                value={updatedTour.carrierFees?.toString()}
                label="Frais (en %)"
                field="text"
                onChangeText={(text) => setValue(text, 'carrierFees')}
              />
            </Col>
          </Row>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Row>
            <Col xs={6} sm={6}>
              <TextInput
                value={updatedTour.capacity?.toString()}
                label="Capacité (m3)"
                field="text"
                onChangeText={(text) => setValue(text, 'capacity')}
              />
            </Col>
            <Col xs={6} sm={6}>
              <TextInput
                value={updatedTour.palletQuantity?.toString()}
                label="Palette(s)"
                field="text"
                onChangeText={(text) => setValue(text, 'palletQuantity')}
              />
            </Col>
          </Row>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          {updatedTour.carrierExpenses && (
            <Row>
              <Col xs={12} sm={12}>
                <SectionTitle title={`Répartition des frais`} />
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Dropdown
                  label={'Choisir une règle'}
                  onChange={onChangeCarrierFeesSharingRule}
                  multiple={false}
                  defaultValue={selectedCarrierFeesSharingRule?.value}
                  optionsDefault={EnumLabelUtil.getCarrierLabelFromGroupType(
                    oldTour.group?.type,
                  ).map((cfr) => ({
                    label: cfr.label,
                    value: cfr.value,
                  }))}
                  zIndex={1000}
                  zIndexInverse={4000}
                  itemKey="carrierFeesRule"
                />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                {currentCarrierFeesSharingRuleDescription() && (
                  <Typography.BodySmall colorName="text-dark-3">
                    {currentCarrierFeesSharingRuleDescription()}
                  </Typography.BodySmall>
                )}
              </Col>
            </Row>
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {hasSuperAdminAccesRight && (
            <>
              <SectionTitle title={`Conditions de commande et frais de livraison`} />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Row>
                <Col xs={6} sm={6}>
                  <TextInput
                    value={updatedTour.minOrderValueHT?.toString()}
                    label="Minimum de commande"
                    field="text"
                    onChangeText={(text) => setValue(text, 'minOrderValueHT')}
                    suffix="€"
                  />
                </Col>
                <Col xs={6} sm={6}>
                  <TextInput
                    value={updatedTour.freeDeliveryOrderValueHT?.toString()}
                    label="Franco de port"
                    field="text"
                    onChangeText={(text) => setValue(text, 'freeDeliveryOrderValueHT')}
                    suffix="€"
                  />
                </Col>
                <Col xs={6} sm={4}>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  <TextInput
                    value={updatedTour.deliveryFeesHT?.toString()}
                    label="Frais de livraison"
                    field="text"
                    onChangeText={(text) => setValue(text, 'deliveryFeesHT')}
                    suffix="€"
                  />
                </Col>
                <Col xs={6} sm={8}>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  <TextInput
                    value={updatedTour.paymentConditions}
                    label="Conditions de paiement"
                    field="text"
                    onChangeText={(text) => setValue(text, 'paymentConditions')}
                  />
                </Col>
              </Row>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <StyledModeSwitchWrapper>
                <Typography.Body>Tournée privée (invisible)</Typography.Body>
                <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
                <Switch
                  trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
                  onValueChange={() => onChangeIsPrivate()}
                  value={updatedTour.isPrivate}
                />
                <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
              </StyledModeSwitchWrapper>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            </>
          )}

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger" style={{ textAlign: 'center' }}>
                {errorMessage}
              </Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            {TourUtil.isTourCompleted(oldTour) && (
              <>
                <Typography.Body colorName="color-grey">
                  Cette tournée est terminée, vous ne pouvez plus la modifier.
                </Typography.Body>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              </>
            )}
            <Button
              label={FormatUtils.capitalize(i18n.t('update'))}
              onPress={() => onUpdateTour()}
              loading={isLoading}
              hasDoubleValidation
              confirmationLabel="Êtes-vous sûr de vouloir mettre à jour cette tournée ?"
              disabled={TourUtil.isTourCompleted(oldTour)}
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierUpdateTourScreen
