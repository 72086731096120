import React from 'react'
import { useTheme } from 'styled-components/native'
import { TutorialStepCardProps } from './TutorialStepCard.model'
import {
  StyledRightBox,
  StyledStepCard,
  StyledStepCardContent,
  StyledStepCardRanking,
  StyledLeftBox,
  StyledStepCardWrapper,
  CaretBox,
} from './TutorialStepCard.styles'
import Typography from '../Typography'
import Icon, { Icons } from '../Icon'
import Spacer from '../Spacer'
import { TutorialStepsEnums } from '../../../enums/tutorialStep'

const TutorialStepCard = ({ step, onPress, status }: TutorialStepCardProps) => {
  const theme = useTheme()
  return (
    <StyledStepCardWrapper
      onPress={onPress}
      disabled={status === TutorialStepsEnums.TutorialStepStatusEnum.DISABLED}
    >
      <CaretBox>
        {status === TutorialStepsEnums.TutorialStepStatusEnum.ACTIVE && (
          <Icon
            type={Icons.Ionicons}
            name={'caret-forward'}
            size={theme.spacingUnit * 2}
            color={theme.colors['color-primary']}
          />
        )}
      </CaretBox>
      <StyledStepCard completed={status === TutorialStepsEnums.TutorialStepStatusEnum.COMPLETED}>
        <StyledLeftBox completed={status === TutorialStepsEnums.TutorialStepStatusEnum.COMPLETED}>
          <StyledStepCardRanking>
            <Typography.Body
              colorName={
                status === TutorialStepsEnums.TutorialStepStatusEnum.COMPLETED
                  ? 'text-light-1'
                  : 'text-dark-3'
              }
            >
              {step.ranking}
            </Typography.Body>
          </StyledStepCardRanking>
        </StyledLeftBox>
        <StyledRightBox>
          <StyledStepCardContent>
            <Typography.CardTitle
              colorName={
                status === TutorialStepsEnums.TutorialStepStatusEnum.DISABLED
                  ? 'color-grey'
                  : 'color-primary'
              }
              bold
            >
              {step.title}
            </Typography.CardTitle>
            <Typography.Body colorName={'text-dark-3'}>{step.body}</Typography.Body>
          </StyledStepCardContent>
          {status === TutorialStepsEnums.TutorialStepStatusEnum.ACTIVE && (
            <Icon
              type={Icons.Ionicons}
              name={'chevron-forward-outline'}
              size={theme.spacingUnit * 4}
              color={theme.colors['text-dark-3']}
            />
          )}
          {status === TutorialStepsEnums.TutorialStepStatusEnum.COMPLETED && (
            <>
              <Icon
                type={Icons.Ionicons}
                name="checkmark-circle-outline"
                size={theme.spacingUnit * 3.5}
                color={theme.colors['color-primary']}
              />
              <Spacer size={theme.spacingUnit * 0.05} axis={Spacer.AxisEnum.HORIZONTAL} />
            </>
          )}
        </StyledRightBox>
      </StyledStepCard>
    </StyledStepCardWrapper>
  )
}

export default TutorialStepCard
