import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledList = styled.View`
  width: 100%;
`

const Styledloading = styled.View`
  margin-top: ${({ theme }) => theme.spacingUnit * 1.5}px;
  align-items: center;
`

export { StyledList, Styledloading }
