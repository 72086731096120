import { useMemo } from 'react'
import { useWindowDimensions } from 'react-native'
import { darkThemeColors, lightThemeColors } from './colors'
import { fontSizes, lineHeights } from './fontSizes'
import fontWeights from './fontWeights'
import { InterfaceFontSizes, InterfaceLineHeights } from './theme'

interface HarvyThemeProps {
  themeVariant?: 'dark' | 'light'
}

const useHarvyTheme = ({ themeVariant = 'light' }: HarvyThemeProps = {}) => {
  const { height, width } = useWindowDimensions()

  const smallDeviceHeight = 600
  const laptopDeviceWidth = 800
  const isSmallScreen = useMemo(() => height < smallDeviceHeight, [height])

  const fontScale = useMemo(() => {
    /*
    if (width > laptopDeviceWidth) {
      return 1.07
    }
    if (height < smallDeviceHeight) {
      return 0.9
    }
    */

    return 1
  }, [height, smallDeviceHeight])

  const scaledFontSizes = useMemo(() => {
    return Object.keys(fontSizes).reduce((acc, key) => {
      acc[key] = fontSizes[key] * fontScale
      return acc
    }, {} as InterfaceFontSizes)
  }, [fontScale])

  const scaledLineHeights = useMemo(() => {
    return Object.keys(lineHeights).reduce((acc, key) => {
      acc[key] = lineHeights[key] * fontScale

      return acc
    }, {} as InterfaceLineHeights)
  }, [fontScale])

  const spacingUnit = useMemo(() => {
    return isSmallScreen ? 6 : 8
  }, [isSmallScreen])

  const buttonHeight = useMemo(() => {
    return 36 * fontScale
  }, [fontScale])

  const inputHeight = useMemo(() => {
    return 64 * fontScale
  }, [fontScale])

  const bottomTabHeight = useMemo(() => {
    return 64 * fontScale
  }, [fontScale])

  const tabBarHeight = useMemo(() => {
    return 64 * fontScale
  }, [fontScale])

  const radioButtonHeight = useMemo(() => {
    return 48 * fontScale
  }, [fontScale])

  const headerBoxWidth = useMemo(() => {
    return 60 * fontScale
  }, [fontScale])

  const theme = useMemo(() => {
    return {
      colors: themeVariant === 'dark' ? darkThemeColors : lightThemeColors,
      fontWeights,
      fontSizes: scaledFontSizes,
      lineHeights: scaledLineHeights,
      spacingUnit,
      buttonHeight,
      inputHeight,
      bottomTabHeight,
      headerHeight: 100,
      borderRadius: 5,
      smallDeviceHeight,
      tabBarHeight,
      radioButtonHeight,
      headerBoxWidth,
      activeOpacity: 0.6,
    }
  }, [
    buttonHeight,
    inputHeight,
    scaledFontSizes,
    scaledLineHeights,
    spacingUnit,
    themeVariant,
    tabBarHeight,
    bottomTabHeight,
    radioButtonHeight,
    headerBoxWidth,
  ])

  return theme
}

export default useHarvyTheme
