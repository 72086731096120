import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledInfoCardWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.25}px;
`

const StyledInfoCard = styled.View`
  width: 94%;
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  border: 1.9px solid ${({ theme }) => theme.colors && theme.colors['color-border']};
  flex-direction: row;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacingUnit}px;
  margin-left: ${({ theme }) => theme.spacingUnit * 0.5}px;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.5}px;
  width: inherit;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.5}px;

  ${() =>
    Platform.OS === 'web' &&
    css`
      margin: ${({ theme }) => theme.spacingUnit * 1.8}px;
      margin-top: ${({ theme }) => theme.spacingUnit * 1.3}px;
      margin-bottom: ${({ theme }) => theme.spacingUnit * 1.3}px;
    `}
`

const StyledCircleContent = styled.View`
  background-color: ${({ theme }) => theme.colors && theme.colors['background-3']};
  border: ${({ theme }) => theme.spacingUnit * 0.2}px solid
    ${({ theme }) => theme.colors && theme.colors['color-border']};
  margin-left: -12px;
  padding-top: ${({ theme }) => theme.spacingUnit * 0.2}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 0.2}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.3}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.3}px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
`

export { StyledInfoCard, StyledCardContent, StyledCircleContent, StyledInfoCardWrapper }
