import styled from 'styled-components/native'

const StyledProfilBox = styled.View`
  width: 100%;
`

const StyledItemsView = styled.View`
  flex: 1;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 2}px;
`

export { StyledProfilBox, StyledItemsView }
