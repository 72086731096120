import { useState } from 'react'
import { View } from 'react-native'
import { Typography, Card, Button, Row, Col, Spacer, SectionTitle } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { ClientCartSummaryProps } from './ClientCartSummary.model'
import {
  BoxWrapper,
  StyledCardWrapperTour,
  StyledCardWrapperCart,
  StyledButtonWrapper,
  CartLine,
  CartLineLabel,
  CartLineValue,
} from './ClientCartSummary.styles'
import TourUtil from '../../../../../utilities/utils/tour'
import { TourEnums } from '../../../../../../enums'
import TotalSummaryCard from '../../../../../components/TotalSummaryCard'

const ClientCartSummary = ({
  cart,
  onValidateOrder,
  cartErrors,
  loading,
}: ClientCartSummaryProps) => {
  const [seeMore, setSeeMore] = useState<boolean | null>(false)

  const tourIsPending = () => {
    if (!cart || !cart.tour) return true
    return TourUtil.getTourStatus(cart.tour) === TourEnums.STATUS.PENDING
  }

  const oderValueToFreeDelivery = () => {
    const freeDeliveryOrderValueHT = cart.tour?.freeDeliveryOrderValueHT || 0

    if (
      cart.totalHTBatchs &&
      cart.totalHTBatchs > 0 &&
      freeDeliveryOrderValueHT > 0 &&
      freeDeliveryOrderValueHT > cart.totalHTBatchs
    ) {
      return FormatUtils.formatPrice(freeDeliveryOrderValueHT - cart.totalHTBatchs)
    }

    return undefined
  }

  const totalSummaryItems = [
    {
      totalHT: cart.totalHTBatchs || 0,
      totalVAT: (cart.totalVAT55 || 0) + (cart.totalVAT10 || 0) + (cart.totalVAT20 || 0),
    },
  ]
  return (
    <BoxWrapper>
      <StyledCardWrapperTour onPress={() => null}>
        <Card>
          <Typography.Body colorName="color-primary" bold>
            Livraison
          </Typography.Body>
          <Typography.Body>
            {FormatUtils.formatDate(cart.tour?.start, 'FullDate+StartTime+EndTime', cart.tour?.end)}
          </Typography.Body>
          <Typography.BodySmall colorName="text-dark-3">
            Fin des commandes {FormatUtils.formatDate(cart.tour?.close, 'FullDate+StartTime')}
          </Typography.BodySmall>
        </Card>
      </StyledCardWrapperTour>

      <StyledCardWrapperCart onPress={() => null}>
        <Typography.Body colorName="color-primary" bold>
          Totaux
        </Typography.Body>
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

        <TotalSummaryCard items={totalSummaryItems} />

        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {cart?.tour?.deliveryFeesHT && cart?.tour?.deliveryFeesHT > 0 ? (
          <>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <CartLine>
              <CartLineLabel>
                <View>
                  <Typography.Body>Frais de livraison</Typography.Body>
                </View>
              </CartLineLabel>
              <CartLineValue>
                <Typography.Body colorName="text-dark-3">
                  {cart.deliveryFeesHT && cart.deliveryFeesHT > 0
                    ? `+${FormatUtils.formatPrice(cart.deliveryFeesHT)}`
                    : 'Offerts'}
                </Typography.Body>
              </CartLineValue>
            </CartLine>
            <Spacer size={0.6} axis={Spacer.AxisEnum.VERTICAL} />
            {oderValueToFreeDelivery() !== undefined ? (
              <Typography.Body colorName="text-dark-1" bold>
                Encore {oderValueToFreeDelivery()} pour bénéficier d'une livraison gratuite.
              </Typography.Body>
            ) : null}
          </>
        ) : undefined}
      </StyledCardWrapperCart>
      {tourIsPending() && (
        <>
          <Typography.BodySmall colorName="color-danger">
            Les commandes ne sont pas encore ouvertes
          </Typography.BodySmall>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        </>
      )}

      {cartErrors?.length
        ? cartErrors?.map((error) => (
            <View style={{ maxWidth: '240px' }}>
              <Typography.BodySmall colorName="color-danger" align="center">
                {error.message}
              </Typography.BodySmall>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            </View>
          ))
        : null}
      <StyledButtonWrapper>
        <Button
          small
          label={cart.targetCart ? 'Valider la modification' : 'Valider la commande'}
          onPress={() => onValidateOrder()}
          loading={loading}
          disabled={tourIsPending() || (cartErrors?.length || 0) > 0}
        />
      </StyledButtonWrapper>
      <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
      {cart?.tour?.paymentConditions && (
        <StyledCardWrapperCart onPress={() => null}>
          <Card>
            <Typography.Body colorName="text-dark-1" bold>
              Conditions de paiement
            </Typography.Body>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3">
              {cart.tour.paymentConditions}
            </Typography.BodySmall>
          </Card>
        </StyledCardWrapperCart>
      )}
    </BoxWrapper>
  )
}

export default ClientCartSummary
