import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'
import { InterfaceColors } from '../../../utilities/styling/theme/theme'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
  justify-content: center;
`
const StyledSubCardWrapper = styled.TouchableOpacity`
  width: 94%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
  margin-left: auto;
  margin-right: auto;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.2}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.2}px;
  flex-direction: row;
`

const StyledSubCardContent = styled.View`
  padding: ${({ theme }) => theme.spacingUnit * 1.2}px;
  flex-direction: row;
  align-items: center;
`

const StyledLeftBox = styled.View``

const StyledRightBox = styled.View`
  flex: 3;
  align-items: flex-end;
`

const StyledTimeBox = styled.View`
  flex-direction: row;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacingUnit * 0.5}px;
`

const StyledTimeBoxRight = styled.View`
  margin-left: ${({ theme }) => theme.spacingUnit * 0.5}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 0.2}px;
`

const StyleBoxWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`

const StatusBox = styled.View<{ backgroundColor: keyof InterfaceColors }>`
  background-color: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
  padding: ${({ theme }) => theme.spacingUnit * 0.5}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.9}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.9}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
`

export {
  StyledCardWrapper,
  StyledSubCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledRightBox,
  StyledTimeBox,
  StyledTimeBoxRight,
  StyleBoxWrapper,
  StyledSubCardContent,
  StatusBox,
}
