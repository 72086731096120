import React from 'react'
import { AxisEnum, SpacerProps } from './Spacer.model'
import StyledSpacerView from './Spacer.styles'

// from : https://www.joshwcomeau.com/react/modern-spacer-gif/

const Spacer = ({ axis, size, fixedSize }: SpacerProps) => (
  <StyledSpacerView axis={axis} size={size} fixedSize={fixedSize} />
)

Spacer.defaultProps = {
  size: 1,
  axis: undefined,
  fixedSize: undefined,
}

Spacer.AxisEnum = AxisEnum

export default Spacer
