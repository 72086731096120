import { View } from 'react-native'
import { SectionTitle, Spacer, Typography } from '../../../../../components'
import { useQuery } from '@tanstack/react-query'
import { DeliveryNote, Pagination } from '../../../../../domain'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import DeliveryNoteProducerService from '../../../../../services/producer/deliveryNote'
import { ProducerTourDocumentProps } from './ProducerTourDocuments.model'
import CardListPaginated from '../../../../../components/CardListPaginated'
import TourDeliveryNoteCard from '../../../../../modules/DeliveryNote/TourDeliveryNoteCard'
import DirectusUtil from '../../../../../utilities/utils/directus'
import { useState } from 'react'
import { set } from 'date-fns'
import DeviceUtil from '../../../../../utilities/utils/device'

const ProducerTourDocuments = ({ tour }: ProducerTourDocumentProps) => {
  const { getAccessInfos } = useAuthenticationContext()

  const [pageSizeDeliveryNotes, setPageSizeDeliveryNotes] = useState(10)
  const [pageNumberDeliveryNotes, setPageNumberDeliveryNotes] = useState(1)

  const {
    data: deliveryNotes,
    refetch: refetchDeliveryNotes,
    isInitialLoading: deliveryNotesLoading,
    isLoading: isLoadingDeliveryNotes,
  } = useQuery<{ data: DeliveryNote[]; pagination: Pagination }, Error>(
    ['p_billing_delivery_notes', getAccessInfos().producerId],
    () =>
      DeliveryNoteProducerService.getAll(
        tour.id,
        undefined,
        getAccessInfos().producerId,
        undefined,
        undefined,
        pageSizeDeliveryNotes,
        pageNumberDeliveryNotes,
      ),
    {
      keepPreviousData: true,
    },
  )

  const onClickDeliveryNote = async (deliveryNote: DeliveryNote) => {
    if (!deliveryNote.id) return

    const deliveryNoteWithAccess = await DeliveryNoteProducerService.getOne(deliveryNote.id)
    if (!deliveryNoteWithAccess.documentId || !deliveryNoteWithAccess.accessToken) return

    window.open(
      DirectusUtil.getDocumentDownloadUrlFromId(
        deliveryNoteWithAccess.documentId,
        deliveryNoteWithAccess.accessToken,
      ),
      '_blank',
    )
  }

  const onChangePageDeliveryNotes = async (pageNumber: number) => {
    await setPageNumberDeliveryNotes(pageNumber)
  }

  const isMobile = DeviceUtil.isMobileApp()

  return (
    <>
      <SectionTitle title={'Bons de livraison'} />
      {isMobile && (
        <>
          <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
          <Typography.BodyExtraSmall colorName="text-dark-3" align="right">
            Cliquez sur un BL pour le télécharger.
          </Typography.BodyExtraSmall>
          <Spacer size={0.6} axis={Spacer.AxisEnum.HORIZONTAL} />
        </>
      )}
      <CardListPaginated
        data={deliveryNotes?.data}
        pagination={deliveryNotes?.pagination}
        element={function (deliveryNote: any) {
          return (
            <TourDeliveryNoteCard
              key={deliveryNote.id}
              deliveryNote={deliveryNote}
              onClick={onClickDeliveryNote}
              isCarrier={false}
            />
          )
        }}
        isLoading={deliveryNotesLoading}
        emptyMessage="Les documents n'ont pas encore été générés."
        onChangePage={onChangePageDeliveryNotes}
      />
    </>
  )
}

export default ProducerTourDocuments
