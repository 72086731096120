import styled from 'styled-components/native'

const StyledWrapper = styled.View`
  flex: 1;
  width: 100%;
`
const StyledContent = styled.View`
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const IMAGE_MAX_HEIGHT = 240

const StyledImageWrapper = styled.View`
  flex: 1;
  max-height: ${IMAGE_MAX_HEIGHT}px;
  width: 100%;
`

const StyledImage = styled.Image`
  width: 100%;
  height: 100%;
`

export { StyledWrapper, StyledContent, StyledImageWrapper, StyledImage }
