import MaskInput from 'react-native-mask-input'
import styled, { css } from 'styled-components/native'

const StyledQuantityInputWrapper = styled.View<{
  borderColor: string
  multiline?: boolean
  backgroundColor: string
  small?: boolean
}>`
  position: relative;
  height: ${({ theme, small }) => theme.inputHeight / (small ? 2.5 : 2)}px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors['color-input']};
  border-width: 1px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border-color: ${({ borderColor }) => borderColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`

const StyledTextInput = styled.TextInput<{
  small?: boolean
}>`
  flex: 1;
  width: 100%;
  padding: 0 ${({ theme, small }) => theme.spacingUnit * (small ? 0.5 : 2)}px;
  margin-top: ${({ theme, multiline, small }) =>
    multiline ? 0 : theme.spacingUnit * (small ? 0.2 : 2)}px;
  color: ${({ theme }) => theme.colors['text-dark-1']};
  font-size: ${({ theme }) => theme.fontSizes.BODY}px;
  z-index: 1;
  border: none !important;

  // outline: none !important;

  &:focus {
    // outline: none !important;
    background-color: red;
  }
  *:focus:not(.focus-visible) {
    // outline: none;
    background-color: red;
  }
  input:focus {
    // outline: none;
    background-color: red;
  }
`

const StyledMaskedInput = styled(MaskInput)<{ noLabel: boolean }>`
  flex: 1;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme, noLabel }) => (noLabel ? 0 : theme.spacingUnit * 2)}px;
  color: ${({ theme }) => theme.colors['text-dark-1']};
  font-size: ${({ theme }) => theme.fontSizes.BODY}px;
  z-index: 1;
`

const StyledInlineWrapper = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 200;
`

const StyledPrefixWrapper = styled.View`
  padding-left: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledSuffixWrapper = styled.View`
  position: absolute;
  right: 0;
  padding-top: 2px;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.2}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
  justify-content: center;
  height: 100%;
  width: 50%;
  z-index: 100;
`

export {
  StyledQuantityInputWrapper,
  StyledTextInput,
  StyledMaskedInput,
  StyledInlineWrapper,
  StyledPrefixWrapper,
  StyledSuffixWrapper,
}
