import { UserEnums } from '../../../enums'
import Env from '../../utilities/env'
import StorageUtil from '../../utilities/storage/storage'
import ApiService from '../api.service'
import { getLoginDataInformations } from './authentication.mapper'
import AuthenticationSeed from './authentication.seed'

interface ISignupBody {
  email: string
  pseudo: string
}

const login = async (email: string, password: string) => {
  const response = await ApiService.post('/auth/login', { email, password }).catch((error) => {
    throw error
  })
  return response
}

const loginToken = async (authToken: string, profileId?: string, groupId?: string) => {
  await StorageUtil.setItem('authenticationData', {
    token: authToken,
  })
  const response = await ApiService.post('/auth/login_auth_token', {
    profileId,
    groupId,
  }).catch((error) => {
    throw error
  })
  return response
}

const refresh = async (profileId?: string, groupId?: string) => {
  const response = await ApiService.post('/auth/refresh', {
    profileId: profileId,
    groupId: groupId,
  }).catch((error) => {
    throw error
  })

  return response
}

const switchProfile = async (profileId?: string, groupId?: string) => {
  const response = await ApiService.post('/auth/switch-profile', {
    profileId: profileId,
    groupId: groupId,
  }).catch((error) => {
    throw error
  })

  return response
}

const getUserProfiles = async (): Promise<any> => {
  const response = await ApiService.get(`/auth/profiles`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}
const getFilteredUserProfiles = async (
  limit: number = 4,
  pageNumber: number = 1,
  profileType: UserEnums.ViewTypeEnums,
  search: string,
): Promise<any> => {
  const response = await ApiService.get(`/auth/profiles-filtered`, {
    limit,
    page: pageNumber,
    profileType,
    search,
  })
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const logout = () => {
  return true
}

const signup = (body: ISignupBody): Promise<any> => {
  if (!body) throw new Error(`body is required.`)

  const response = AuthenticationSeed.signupSeed()
  return response
}

const sendResetPasswordEmail = async (email: string) => {
  const response = await ApiService.post('/user/request-reset-password', { email }).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const resetPassword = async (email: string, token: string, newPassword: string) => {
  const response = await ApiService.post('/user/reset-password', {
    email: email,
    resetToken: token,
    password: newPassword,
  }).catch((error) => {
    throw error
  })
  return response
}

const checkInvitation = async (token: string) => {
  const response = await ApiService.post(`/user/check-invitation`, { token: token }).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const validateInvitation = async (email: string, password: string) => {
  const response = await ApiService.post(`/user/validate-invitation`, {
    email,
    password,
  }).catch((error) => {
    throw error
  })
  return response
}

const AuthenticationService = {
  login,
  refresh,
  loginToken,
  switchProfile,
  getUserProfiles,
  getFilteredUserProfiles,
  logout,
  getLoginDataInformations,
  signup,
  sendResetPasswordEmail,
  resetPassword,
  checkInvitation,
  validateInvitation,
}

export default AuthenticationService
