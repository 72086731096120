import styled, { css } from 'styled-components/native'

const StyledCardWrapper = styled.View`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 1.3}px;
  flex-direction: row;
  min-height: ${({ theme }) => theme.spacingUnit * 4}px;
`
const StyledLeftBox = styled.View`
  flex: 4;
  align-items: flex-start;
  justify-content: flex-start;
`
const StyledMiddleBox = styled.View`
  // flex: 2;
  flex: 6;
`
const StyledRightBox = styled.View`
  flex: 3;
  align-items: flex-end;
  text-align: center;
  justify-content: center;
`

const StyledValuesWrapper = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-items: flex-end;
`

const StyledValueBox = styled.View`
  margin-right: ${({ theme }) => theme.spacingUnit * 0.3}px;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  align-content: center;
`

const CancelButtonWrapper = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.colors['text-dark-3']};
  border-radius: ${({ theme }) => theme.borderRadius * 0.5}px;
  padding: ${({ theme }) => theme.spacingUnit * 0.15}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.8}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.8}px;
  opacity: 0.7;
`

export {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRightBox,
  StyledValuesWrapper,
  StyledValueBox,
  CancelButtonWrapper,
}
