import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import HeaderHome from '../../components/HeaderHome'

import { Dimensions } from 'react-native'
import Header from '../../components/Header'

import ProducerPropositionsScreen from '../../../screens/Producer/ProducerPropositions'
import ProducerAddBatchScreen from '../../../screens/Producer/ProducerAddBatch'
import ProducerUpdateBatchScreen from '../../../screens/Producer/ProducerUpdateBatch'
import ProducerPropositionsV2Screen from '../../../screens/Producer/ProducerPropositionsV2'
import ProducerAddRuleScreen from '../../../screens/Producer/ProducerAddRule'

const Stack = createStackNavigator()

const BatchProducerNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="ProducerPropositions"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ProducerPropositions"
        component={ProducerPropositionsV2Screen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Mercuriales',
        }}
      />
      <Stack.Screen
        name="ProducerAddBatch"
        component={ProducerAddBatchScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
          title: 'Ajouter un lot',
        }}
      />

      <Stack.Screen
        name="ProducerAddRule"
        component={ProducerAddRuleScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
          title: 'Ajouter une règle',
        }}
      />
      <Stack.Screen
        name="ProducerUpdateBatch"
        component={ProducerUpdateBatchScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDashboard" />,
          title: 'Modifier un lot',
        }}
      />
    </Stack.Navigator>
  )
}

export default BatchProducerNavigation
