import React from 'react'
import AccountService from '../../services/billing/account'
import useAuthenticationContext from '../../utilities/hook/useAuthenticationContext'
import Dropdown from '../Dropdown'
import { AccountPickerProps } from './AccountPicker.model'

const AccountPicker = ({ label, onSelected, filter }: AccountPickerProps) => {
  const { getAccessInfos } = useAuthenticationContext()
  const profileId = getAccessInfos().carrierId || getAccessInfos().producerId
  const args = { profileId, ...filter }

  const { data: accounts } = AccountService.useGetManyAccounts(args).query()

  const onChangeAccount = (account: any) => {
    onSelected(account.value)
  }

  return (
    <Dropdown
      label={label}
      onChange={onChangeAccount}
      multiple={false}
      optionsDefault={
        accounts?.map((account) => ({
          label: ` ${account.legalName} (${account.unbilledDN} BL à facturer) `,
          value: account.id,
        })) ?? []
      }
      zIndex={3}
      zIndexInverse={3}
    />
  )
}

export default AccountPicker
