import { ProductEnums } from '../../../enums'
import { ProducerVatTypeEnum } from '../../../enums/producer'
import { Producer } from '../../domain'
import AccountUtil from './account'

const isEmpty = (arr: any) => {
  return arr === undefined || arr === null || arr.length === 0
}

const isNull = (obj: any) => {
  if (typeof obj === 'number') return false
  if (typeof obj === 'boolean') return false
  return obj === undefined || obj === null
}

const isEmail = (email: string) => {
  const REGEX_EMAIL = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
  return !isNull(email) && typeof email !== 'object' && REGEX_EMAIL.test(email)
}

const clone = (item: any) => {
  if (isNull(item)) return null
  return JSON.parse(JSON.stringify(item))
}

const isEmptyStr = (str: any) => {
  if (typeof str === 'number') return false
  const trim: any = str ? str.toString().trim() : ''
  return str === undefined || str === null || str === '' || trim === ''
}

function isDateBefore(dateTarget: Date, dateCompare: Date, minutes?: number) {
  const diff = dateCompare.getTime() - dateTarget.getTime()
  const diffMinutes = diff / 60000
  const minutesDiff = 0 + (minutes || 0)
  return diffMinutes > minutesDiff
}

function diffDays(start: Date, end: Date) {
  const diff = end.getTime() - start.getTime()
  const diffDays = Math.ceil((diff / (1000 * 3600 * 24)) * 10) / 10

  return diffDays
}

function diffHours(start: Date, end: Date) {
  const diff = end.getTime() - start.getTime()
  const diffHours = Math.ceil(diff / (1000 * 3600))

  return diffHours
}

function diffMinutes(start: Date, end: Date) {
  const diff = end.getTime() - start.getTime()
  const diffMinutes = Math.ceil((diff % 86400000) % 3600000) / 60000

  return diffMinutes
}

function remainingTime(targetDate: Date) {
  const diffHours = GeneralUtils.diffHours(new Date(), targetDate)
  const diffMinutes = GeneralUtils.diffMinutes(new Date(), targetDate)

  if (diffHours < 24 && diffHours > 1) {
    return `${Math.round(diffHours)}h`
  } else if (diffHours < 1) {
    return `${Math.round(diffMinutes)}min`
  }
}

function isDateBeforeCurrent(date: Date | undefined) {
  if (!date) return false

  const currentDate = new Date()
  return date < currentDate
}

function getVatFromHT(rate: ProductEnums.VAT_ENUM, valueHT: number, producer?: Producer) {
  if (producer?.account && !AccountUtil.isProfileSubjectToVAT(producer.account)) {
    return 0
  }
  if (rate == ProductEnums.VAT_ENUM.VAT55) {
    return getExactNumber(valueHT * 0.055, 4)
  }
  if (rate == ProductEnums.VAT_ENUM.VAT10) {
    return getExactNumber(valueHT * 0.1)
  }
  if (rate == ProductEnums.VAT_ENUM.VAT20) {
    return getExactNumber(valueHT * 0.2)
  }
  return 0
}

const stringContains = (searchTerm: string, targetString: string) => {
  const searchTermLowerCase = searchTerm
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
  const targetStringLowerCase = targetString
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  return targetStringLowerCase.includes(searchTermLowerCase)
}

function textToHtml(text?: string): string {
  if (!text) return ''
  // Remplacer les sauts de ligne par des balises <br>
  const htmlContent = text.replace(/\n/g, '<br>')
  return htmlContent
}

const GeneralUtils = {
  isEmpty,
  isNull,
  isEmail,
  clone,
  isEmptyStr,
  isDateBefore,
  diffDays,
  diffHours,
  diffMinutes,
  remainingTime,
  isDateBeforeCurrent,
  getVatFromHT,
  getExactNumber,
  stringContains,
  textToHtml,
}

export default GeneralUtils

export function getExactNumber(int: number, precision = 2) {
  const diviser = Math.pow(10, precision)
  return Math.round(int * diviser) / diviser
}
