import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Card,
  Col,
  Dropdown,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import Loader from '../../../components/Loader'
import { UpdateGroup } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import {
  ToursContentWrapper,
  StyledButtonWrapper,
  StyledModeExplainerButtonWrapper,
} from './CarrierUpdateGroupSettings.styles'
import { Switch } from 'react-native-gesture-handler'
import GroupCarrierService from '../../../services/carrier/group'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { GroupEnums } from '../../../../enums/group'
import { CarrierFeesEnums } from '../../../../enums/carrierFees'
import useGroupContext from '../../../utilities/hook/useGroupContext'

const CarrierUpdateGroupSettingsScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdateGroupSettings'>>()
  const [isLoading, setIsLoading] = useState(false)
  const [updatedGroup, setUpdatedGroup] = useState<UpdateGroup>({
    id: undefined,
    hasPricingModule: undefined,
    type: undefined,
    hasFeesSharingModule: undefined,
    hasCrossedPriceFeature: undefined,
  })
  const [selectedGroupType, setSelectedGroupType] = useState<any>()
  const [selectedCarrierFeesSharingRule, setSelectedCarrierFeesSharingRule] = useState<any>()
  const [selectedPublicStatus, setSelectedPublicStatus] = useState<any>()

  const onChangeGroupType = (value: any) => {
    setSelectedGroupType(value)
  }

  const [errorMessage, setErrorMessage] = useState<string>('')

  const theme = useTheme()

  const { group, updateGroup } = useGroupContext()

  useEffect(() => {
    if (group?.id) {
      setUpdatedGroup({
        id: group.id,
        hasPricingModule: group.hasPricingModule,
        type: group.type,
        hasFeesSharingModule: group.hasFeesSharingModule,
        hasCrossedPriceFeature: group.hasCrossedPriceFeature,
      })

      const type = GroupEnums.GROUP_TYPES.find((gt: any) => gt.value === group?.type)

      if (type) {
        setSelectedGroupType({
          ...type,
        })
      } else {
        setSelectedGroupType({
          label: 'Choisir une option',
          value: 0,
        })
      }

      const ruleType = CarrierFeesEnums.CARRIER_FEES_RULES.find(
        (cfr: any) => cfr.value === group?.carrierFeesSharingRule,
      )

      if (ruleType) {
        setSelectedCarrierFeesSharingRule({
          ...ruleType,
        })
      } else {
        setSelectedCarrierFeesSharingRule({
          label: 'Choisir une option',
          value: 0,
        })
      }

      const publicStatus = GroupEnums.GROUP_PUBLIC_STATUS.find(
        (gps: any) => gps.value === group?.publicStatus,
      )

      if (publicStatus) {
        setSelectedPublicStatus({
          ...publicStatus,
        })
      } else {
        setSelectedPublicStatus({
          label: 'Choisir une option',
          value: 0,
        })
      }
    }
  }, [group])

  if (!group) {
    return <Loader isLoading pageLoading />
  }

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in updatedGroup)) return null

    const groupTemp = Object.assign({}, updatedGroup)

    groupTemp[param as keyof typeof updatedGroup] = value
    setUpdatedGroup(groupTemp)
  }

  const onChangeIsPrivate = () => {
    if (updatedGroup?.hasPricingModule) {
      setValue(false, 'hasPricingModule')
      return
    }
    setValue(true, 'hasPricingModule')
  }
  const onChangeHasSharingFeesModule = () => {
    if (updatedGroup?.hasFeesSharingModule) {
      setValue(false, 'hasFeesSharingModule')
      return
    }
    setValue(true, 'hasFeesSharingModule')
  }

  const onChangeGroupCrossedPriceFeature = () => {
    setValue(!updatedGroup?.hasCrossedPriceFeature, 'hasCrossedPriceFeature')
  }

  const onChangeCarrierFeesSharingRule = (value: any) => {
    setSelectedCarrierFeesSharingRule(value)
  }

  const onChangePublicStatus = (value: any) => {
    setSelectedPublicStatus(value)
  }

  const onUpdateGroup = async () => {
    setErrorMessage('')
    setIsLoading(true)

    const groupToUpdate = {
      hasPricingModule: updatedGroup.hasPricingModule,
      type: selectedGroupType.value,
      hasFeesSharingModule: updatedGroup.hasFeesSharingModule,
      carrierFeesSharingRule: selectedCarrierFeesSharingRule.value,
      publicStatus: selectedPublicStatus.value,
      hasCrossedPriceFeature: updatedGroup.hasCrossedPriceFeature,
    }
    if (!isGroupTypeEditable()) {
      groupToUpdate.type = group.type
    }

    const updatedGroupTemp = await GroupCarrierService.update(group.id ?? '', groupToUpdate)
    if (updatedGroupTemp && updatedGroupTemp.id) {
      queryClient.invalidateQueries({ queryKey: ['sf_group', getAccessInfos().carrierGroupId] })
      updateGroup()
      navigation.goBack()
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour du groupe')
    }
    setIsLoading(false)
  }

  const isGroupTypeEditable = () => {
    if (
      group &&
      group.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS &&
      group.nbCarriers &&
      group.nbCarriers > 1
    ) {
      return false
    }
    return true
  }

  const currentCarrierFeesSharingRuleDescription = () => {
    const rule = CarrierFeesEnums.CARRIER_FEES_RULES.find(
      (cfr: any) => cfr.value === selectedCarrierFeesSharingRule?.value,
    )
    if (rule && rule.description) {
      return rule.description
    }
    return ''
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title={`Paramètres du groupe`} />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Card>
              <Typography.Body>Groupe</Typography.Body>
              <Typography.CardTitle>{group.label}</Typography.CardTitle>
            </Card>
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <Row>
              <Col xs={12}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Dropdown
                  label={'Mode du groupe'}
                  onChange={onChangeGroupType}
                  multiple={false}
                  defaultValue={selectedGroupType?.value}
                  optionsDefault={GroupEnums.GROUP_TYPES.map((groupType) => ({
                    ...groupType,
                  }))}
                  itemKey="groupType"
                  editable={false}
                />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                <StyledModeExplainerButtonWrapper>
                  <Button.Text
                    label={'Quels sont les différents modes?'}
                    onPress={() => {
                      navigation.navigate('CarrierGroupModeExplainer', {})
                    }}
                  />
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                </StyledModeExplainerButtonWrapper>
              </Col>
            </Row>
            <SectionTitle title={`Répartition des frais`} />
            <Spacer size={0.6} axis={Spacer.AxisEnum.VERTICAL} />
            <Dropdown
              label={'Choisir une règle'}
              onChange={onChangeCarrierFeesSharingRule}
              multiple={false}
              defaultValue={selectedCarrierFeesSharingRule?.value}
              optionsDefault={CarrierFeesEnums.CARRIER_FEES_RULES.map((cfr) => ({
                label: cfr.label,
                value: cfr.value,
              }))}
              zIndex={1000}
              zIndexInverse={4000}
              itemKey="carrierFeesRule"
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            {currentCarrierFeesSharingRuleDescription() && (
              <Typography.BodySmall colorName="text-dark-3">
                {currentCarrierFeesSharingRuleDescription()}
              </Typography.BodySmall>
            )}
            <Typography.BodySmall colorName="text-dark-3">
              Si vous modifiez ce paramètre, il ne s'appliquera qu'aux nouvelles tournées créées.
              Pensez à le modifier dans les tournées existantes.
            </Typography.BodySmall>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <SectionTitle title={`Demandes d'ajout de nouveaux producteurs ?`} />
            <Spacer size={0.6} axis={Spacer.AxisEnum.VERTICAL} />
            <Dropdown
              label={'Choisir un status'}
              onChange={onChangePublicStatus}
              multiple={false}
              defaultValue={selectedPublicStatus?.value}
              optionsDefault={GroupEnums.GROUP_PUBLIC_STATUS.map((gps) => ({
                label: gps.label,
                value: gps.value,
              }))}
              zIndex={1000}
              zIndexInverse={4000}
              itemKey="publicStatus"
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />

            <Typography.BodySmall colorName="text-dark-3">
              Si vous sélectionnez le mode "Invisible" ou "Public" vous recevrez des demandes de
              producteurs souhaitant rejoindre le groupe, que vous devrez ensuite accepter ou
              refuser.
            </Typography.BodySmall>

            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <SectionTitle title={`Modules`} />
            <Row>
              <Col xs={8}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body bold>Module de pricing</Typography.Body>
                <Typography.BodySmall>
                  En activant cette option, vous proposez des prix à vos producteurs. Si ceux-ci
                  sont supérieurs à leur prix minimum, ils sont directement appliqués.
                </Typography.BodySmall>
              </Col>
              <Col xs={4}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Switch
                  trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
                  onValueChange={() => onChangeIsPrivate()}
                  value={updatedGroup.hasPricingModule}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs={8}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body bold>Module de répartition de frais</Typography.Body>
                <Typography.BodySmall>
                  Suivez la répartition des frais logistiques entre les différents
                  producteurs-livreurs.
                </Typography.BodySmall>
              </Col>
              <Col xs={4}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Switch
                  trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
                  onValueChange={() => onChangeHasSharingFeesModule()}
                  value={updatedGroup.hasFeesSharingModule}
                  disabled
                />
              </Col>
            </Row>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <SectionTitle title={`Fonctionnalités`} />
            <Row>
              <Col xs={8}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body bold>Fonctionnalité de prix barré</Typography.Body>
                <Typography.BodySmall>
                  Autorisez les producteurs de votre groupe à proposer des prix barrés.
                </Typography.BodySmall>
              </Col>
              <Col xs={4}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Switch
                  trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
                  onValueChange={() => onChangeGroupCrossedPriceFeature()}
                  value={updatedGroup.hasCrossedPriceFeature}
                />
              </Col>
            </Row>
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Button
              label={FormatUtils.capitalize(i18n.t('update'))}
              onPress={() => onUpdateGroup()}
              loading={isLoading}
              hasDoubleValidation
              confirmationLabel="Êtes-vous sûr de vouloir modifier les paramètres de ce groupe ?"
            />
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierUpdateGroupSettingsScreen
