import React from 'react'
import { PageTitle, SectionTitle, Spacer, Typography } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { ToursContentWrapper } from './CarrierGroupModeExplainer.styles'

const CarrierGroupModeExplainerScreen = () => {
  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title={`Les modes de groupe`} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Typography.BodySmall colorName="color-grey">
            Sur Harvy, plusieurs modes de fonctionnement peuvent s'appliquer. Ils permettent de
            déléguer les responsabilités et livraisons différemment
          </Typography.BodySmall>
          <SectionTitle title={`Producteurs-livreurs`} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Typography.Body>
            Le mode Producteur-livreur permet une gestion répartie entre les producteurs du groupe,
            tant du point de vue de l'organisation que des livraisons.
          </Typography.Body>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <SectionTitle title={`Super-producteur`} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Typography.Body>
            Le mode Super-producteur permet de déléguer la gestion du groupe à un producteur : une
            entité qui peut aussi être en charge de jouer le rôle d'intermédiaire de paiement
          </Typography.Body>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierGroupModeExplainerScreen
